import styled from 'styled-components';

import { TextNormal } from '@features/core/styles/text.styled';

import { Icon } from '@ui/components/icon';

const getSelectionDesktopStyles = ({ theme }) => `
  position: relative;
  transform: translateZ(5px);
  padding-bottom: ${theme.space_15};
`;

export const Selection = styled.div`
  position: fixed;
  bottom: ${({ theme }) => theme.space_60};
  width: 100%;
  left: 0;
  z-index: 99;
  background: ${props => props.theme.highlightWrapperBackground};
  padding-bottom: ${({ theme }) => theme.space_30};

  ${({ theme }) => theme.isDesktop && getSelectionDesktopStyles}
`;

export const CloseIcon = styled(Icon)`
  position: absolute;
  top: ${({ theme }) => theme.space_10};
  right: ${({ theme }) => theme.space_10};
  cursor: pointer;
`;

export const SelectionText = styled.div`
  display: grid;
  grid-auto-flow: column;
  align-items: center;
  justify-content: space-between;
  padding: ${({ theme }) => theme.space_10} ${({ theme }) => theme.space_10}
    ${({ theme }) => theme.space_5};
  margin-bottom: ${({ theme }) => theme.space_5};
`;

export const SelectionSuccess = styled(TextNormal)`
  margin: 0;
  font-weight: ${({ theme }) => theme.fontBold};
  display: grid;
  grid-auto-flow: column;
  align-items: center;
  color: ${({ theme }) => theme.statusSuccess};
`;

export const SuccessIcon = styled(Icon)`
  margin-right: ${({ theme }) => theme.space_10};

  path {
    fill: ${({ theme }) => theme.statusSuccess};
  }
`;

export const ButtonWrapper = styled.div`
  display: grid;
  grid-auto-flow: row;
  margin: 0 ${({ theme }) => theme.space_10};
  grid-gap: ${({ theme }) => theme.space_10};
`;

import styled from 'styled-components';

export const ButtonWrapper = styled.div`
  display: grid;
  grid-auto-flow: column;
  padding: 0 ${({ theme }) => theme.space_10} ${({ theme }) => theme.space_10};
  width: 100%;
  box-sizing: border-box;
`;
export const MaxCashoutButton = styled.div`
  display: grid;
  align-items: center;
  width: 100%;
`;

import services from '@features/core/services';

import { DynamicCalls } from '@common/api/api';
import { apiHandler, MethodType } from '@common/api/apiHandler';
import { ICustomError, IFetchSubscriptionsResult } from '@common/interfaces';
import { ISubscriptionsRequest } from '@common/providers/account/types';

const { API_SUBSCRIPTIONS } = DynamicCalls;
const fetchSubscriptions = async ({
  method,
  payload,
}: ISubscriptionsRequest): Promise<IFetchSubscriptionsResult &
  ICustomError> => {
  let newsletterMethod;
  if (method === MethodType.Get) {
    newsletterMethod = 'newsletter.is_subscribed_sport';
  }
  if (method === MethodType.Post) {
    newsletterMethod = 'newsletter.subscribe_sport';
  }

  return apiHandler<IFetchSubscriptionsResult>(
    {
      method,
      params: {
        ...payload,
        method: newsletterMethod,
      },
    },
    {
      apiUrl: API_SUBSCRIPTIONS,
      apiData: {
        dynamicLanguage: services.domainLang,
      },
    },
  );
};

export default fetchSubscriptions;

import { useState, useEffect, useRef } from 'react';

import { IPrediction, TTimeout } from '@common/interfaces';
import deleteTimeout from '@common/helpers/timeoutHelper';
import usePrevious from '@common/hooks/usePrevious';

const useOddsDirection = (
  prediction: IPrediction = {} as IPrediction,
): { up: boolean; down: boolean } => {
  const timer1 = useRef<TTimeout>(null);
  const timer2 = useRef<TTimeout>(null);
  const [up, setUp] = useState(false);
  const [down, setDown] = useState(false);

  const prevPrediction: IPrediction = usePrevious(prediction) || prediction;

  const setOddsDown = (): void => {
    setUp(false);
    setDown(true);
  };

  const setOddsUp = (): void => {
    setUp(true);
    setDown(false);
  };

  useEffect(() => {
    if (prevPrediction.id === prediction.id) {
      if (parseFloat(prevPrediction.odds) > parseFloat(prediction.odds)) {
        timer1.current = setTimeout(setDown, 2500, false);
        setOddsDown();
      } else if (
        parseFloat(prevPrediction.odds) < parseFloat(prediction.odds)
      ) {
        timer2.current = setTimeout(setUp, 2500, false);
        setOddsUp();
      }
    }
  }, [prevPrediction.odds, prevPrediction.id, prediction.id, prediction.odds]);

  useEffect(() => {
    return () => deleteTimeout(timer1.current, timer2.current);
  }, []);

  return { up, down };
};

export default useOddsDirection;

import React, { useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import includes from 'lodash/includes';
import { useTheme } from 'styled-components';

import CustomLink from '@features/core/routing/CustomLink';
import { PageName } from '@features/core/routing/linkAliases';

import { TTimeout } from '@common/interfaces';
import deleteTimeout from '@common/helpers/timeoutHelper';
import {
  setLocation,
  goBack,
  isMatchWebRoutes,
} from '@common/providers/router/helper';
import { isEventDetailPages } from '@common/helpers/links';
import {
  ANIMATED_BET_DETAIL,
  PREVETN_ANIMATION,
} from '@common/helpers/appLayoutHelper';
import { toggleAnimatedComponent } from '@common/providers/application/useAppState';

import { ToggleCategoryHeadersButton } from '@ui/components/toggleCategoryHeadersButton';
import { Icon } from '@ui/components/icon';

import * as S from './PageHeader.styled';
import { ImageWrapper } from './PageHeader.styled';
import IPageHeader from './PageHeader.types';

const PageHeader = (props: IPageHeader): React.ReactElement => {
  const {
    src,
    path,
    title,
    isBackButton,
    isLightColorHeader,
    isCrossButton,
  } = props;
  const timeoutTabRef = useRef<TTimeout>(null);
  const { pathname } = useLocation();
  const { iconFillLight } = useTheme();
  const isOnCategories = includes(pathname, 'events/category');
  const isEventDetail = isEventDetailPages();
  const isBetDetail = isMatchWebRoutes(PageName.USER_BET_ITEM);
  const isSportHeaderStyle =
    isMatchWebRoutes(PageName.EVENTS_CATEGORY) ||
    isEventDetail ||
    !!isLightColorHeader ||
    isMatchWebRoutes(PageName.SPORT);

  const { t } = useTranslation();
  const isInboxMessagePage = includes(pathname, `inbox/`);

  useEffect(() => {
    return () => {
      deleteTimeout(timeoutTabRef.current);
    };
  });
  const onClickHandler = (): void | undefined => {
    const isInDepositPage = includes(pathname, 'payin/fail');
    const isInWithdrawalPage = includes(pathname, 'payout/fail');

    const onAnimateEl = (): void => {
      goBack();
      toggleAnimatedComponent(PREVETN_ANIMATION);
    };

    if (isInDepositPage) {
      setLocation(PageName.USER_PAYIN);
      return;
    }
    if (isInWithdrawalPage) {
      setLocation(PageName.USER_PAYOUT);
      return;
    }
    if (isBetDetail) {
      toggleAnimatedComponent(ANIMATED_BET_DETAIL);
      setTimeout(() => onAnimateEl(), 300);
      return;
    }
    goBack();
  };

  const renderBackButton = (): React.ReactElement => {
    return (
      <S.BackButton
        data-qa={isBackButton ? 'back-button' : null}
        aria-label={t('common.buttons.back')}
        onClick={onClickHandler}
      >
        <Icon width="12" height="12" name="arrow-left" fill={iconFillLight} />
      </S.BackButton>
    );
  };

  const renderIcon = (): React.ReactElement => {
    return (
      <ImageWrapper>
        <S.ImageStyled src={src} alt="back-image" height="100%" width="50%" />
      </ImageWrapper>
    );
  };

  const renderHideCategoryHeadersButton = (): React.ReactElement => {
    return <ToggleCategoryHeadersButton pageType={pathname} />;
  };

  const renderCrossIcon = (): JSX.Element => (
    <S.BackButton onClick={onClickHandler}>
      <Icon width="20" height="20" name="cross" fill={iconFillLight} />
    </S.BackButton>
  );

  return (
    <S.Header
      isSportHeaderStyle={isSportHeaderStyle}
      className="header-page"
      data-qa="header-page"
    >
      {isBackButton ? renderBackButton() : <S.EmptyBox />}
      {title ? (
        <S.Title>
          {path ? (
            <CustomLink href={path}>{title}</CustomLink>
          ) : (
            <span>{title}</span>
          )}
        </S.Title>
      ) : (
        <S.EmptyBox />
      )}
      {isOnCategories && !isEventDetail && renderHideCategoryHeadersButton()}
      {!!src && renderIcon()}
      {isInboxMessagePage && (
        <div>
          <Icon
            name="delete-message"
            width="16"
            height="16"
            fill={iconFillLight}
          />
        </div>
      )}
      {isCrossButton && renderCrossIcon()}
    </S.Header>
  );
};

export default PageHeader;

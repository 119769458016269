import React, { useEffect } from 'react';
import noop from 'lodash/noop';

import { toggleFooterNotification } from '@common/providers/application/useAppState';
import useCountDown from '@common/hooks/useCountDown';

import { GenericNotification } from '@ui/components/genericNotification';
import { INotificationStyle } from '@ui/components/genericNotification/GenericNotification.types';

import * as S from './FooterDynamicNotifier.styled';
import { IFooterNotifier } from './FooterDynamicNotifier.types';

const FooterDynamicNotifier = ({
  children,
  duration,
  dependency,
  needToShow,
  withArrowIcon = false,
}: IFooterNotifier): JSX.Element | null => {
  const { countdown, startCountDown } = useCountDown();

  useEffect(() => {
    if (needToShow) {
      startCountDown(() => noop, duration / 1000);
    }
  }, [dependency, needToShow]);

  useEffect(() => {
    toggleFooterNotification(!!countdown && needToShow);
  }, [countdown, needToShow]);

  return countdown && needToShow ? (
    <S.NotifierWrapper id="footer-notification">
      <S.AnimatedDiv>
        <GenericNotification
          withoutIcon
          styleType={INotificationStyle.footerDynamicNotification}
        >
          {children}
        </GenericNotification>
        {withArrowIcon && <S.ActiveArrow />}
      </S.AnimatedDiv>
    </S.NotifierWrapper>
  ) : null;
};

export default FooterDynamicNotifier;

import CustomError from '@features/core/error/error';
import i18n from '@features/core/translation';

import { DYNAMIC_NOTE_LAYER, WALLET_REQUEST } from '@common/constants/dialogs';
import { MethodType } from '@common/api/apiHandler';
import { needVerification } from '@common/helpers/paymentsHelper/paymentModel';
import { IUser } from '@common/interfaces';
import apiRpcRequest from '@common/api/common/apiCommonRequest';
import { openSidebar } from '@common/providers/application/useAppState';
import {
  setWalletsData,
  setWalletsError,
  setWalletsRequestData,
  setWalletsRequestError,
  setWalletsRequestSuccess,
  startGettingWallets,
  startWalletsRequestLoading,
} from '@common/providers/payments/wallets/useWallets';
import { IFetchWallets } from '@common/providers/payments/wallets/types';

export const fetchWallets = async (): Promise<IFetchWallets | CustomError> => {
  startGettingWallets();
  const params = {
    method: `user.get_wallets`,
  };

  return (await apiRpcRequest({
    method: MethodType.Get,
    params,
  })) as IFetchWallets;
};

export const getWallets = async (): Promise<void> => {
  const response = await fetchWallets();
  if (response instanceof CustomError) {
    return setWalletsError(response.message);
  }
  const wallets = response?.result?.wallets;

  return setWalletsData(wallets);
};

export const getWalletsRequest = async (user: IUser): Promise<void> => {
  startWalletsRequestLoading();
  const params = {
    method: `user.get_wallet_requests`,
  };

  const response = (await apiRpcRequest({
    method: MethodType.Get,
    params,
  })) as IFetchWallets;
  if (response instanceof CustomError) {
    return setWalletsRequestError(response.message);
  }
  const wallets = response?.result?.wallet_request_info;

  if (wallets?.length) {
    if (user && needVerification(user)) {
      openSidebar({
        type: DYNAMIC_NOTE_LAYER,
        data: {
          message: i18n.t('user.notifications.not_verified_wallet_request'),
          title: i18n.t('user.lugas.title.verification'),
        },
      });
    } else {
      openSidebar({ type: WALLET_REQUEST });
    }
  }

  return setWalletsRequestData(wallets);
};

export const acceptWalletsRequest = async (id: string): Promise<void> => {
  startWalletsRequestLoading();
  const params = {
    method: `user.accept_wallet_request`,
    wallet_id_request: id,
    jsonrpc: '2.0',
  };

  const response = (await apiRpcRequest({
    method: MethodType.Get,
    params,
  })) as IFetchWallets;
  if (response instanceof CustomError) {
    return setWalletsRequestError(response.message);
  }
  if (response.result?.error) {
    openSidebar({
      type: DYNAMIC_NOTE_LAYER,
      data: { message: response.result?.message },
    });
  }

  return setWalletsRequestSuccess();
};

export const rejectWalletsRequest = async (id: string): Promise<void> => {
  startWalletsRequestLoading();
  const params = {
    method: `user.reject_wallet_request`,
    wallet_id_request: id,
    jsonrpc: '2.0',
  };

  const response = (await apiRpcRequest({
    method: MethodType.Get,
    params,
  })) as IFetchWallets;
  if (response instanceof CustomError) {
    return setWalletsRequestError(response.message);
  }

  return setWalletsRequestSuccess();
};

import styled from 'styled-components';

const getMobileButtonsView = ({ theme }) => `
  grid-auto-flow: row;
  justify-content: stretch;
  margin-top: ${theme.space_20};

  .button {
    margin-bottom: ${theme.space_20};

  .abort-btn {
    grid-row: 2;
  }
`;

// eslint-disable-next-line import/prefer-default-export
export const FormButtons = styled.div`
  display: grid;
  grid-auto-flow: column;
  justify-content: space-between;
  margin-top: ${({ theme }) => theme.space_60};

  ${({ theme }) => !theme.isDesktop && getMobileButtonsView};
`;

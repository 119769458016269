import find from 'lodash/find';
import { RuleObject } from 'rc-field-form/es/interface';

import { ISubmitError } from '@common/interfaces';

/**
 * submitErrorsValidator
 *
 * @param {ISubmitError[]} submitErrors
 * @param {string} filedName
 * @returns {Function} validator
 */
const submitErrorsValidator = (
  submitErrors: ISubmitError[],
  filedName: string,
) => async (rule: RuleObject, value: string): Promise<void> => {
  const fieldError = find(submitErrors, ['fieldName', filedName]);
  if (fieldError && fieldError.fieldValue === value) {
    throw new Error(fieldError.message);
  }
};

export default submitErrorsValidator;

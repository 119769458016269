export interface IPaymentService {
  service: {
    id: IPaymentMethod;
    label: string;
    deposit: IOperation;
    withdrawal: IOperation;
  };
}

export interface IFields {
  name: string;
  requirement: string;
  type: string;
  label?: string;
  values?: Array<string>;
  options?: { label: string; value: string }[];
  value?: string;
  hint?: string;
}

export interface IOperation {
  fee: string;
  fields: Array<IFields> | [];
  limit: {
    available?: string | null;
    max: string | null;
    min: string;
  };
  status: string;
  term: string;
}

export enum IPaymentMethod {
  SHOP_MONEY = 'shop_money',
  PAYPAL = 'paypal',
  TRUSTLY = 'trustly',
  CARD = 'card',
  BANK = 'bank',
  SKRILL = 'skrill',
  AIRCASH = 'aircash',
  SOFORT = 'sofort',
  PAYSAFECARD = 'paysafecard',
  CARD_VISA = 'card_visa',
  MUCHBETTER = 'muchbetter',
}

import services from '@features/core/services';

import {
  IGetTransactionsPayload,
  ISetTransactionsPayload,
} from '@common/providers/transactions/types';
import { DynamicCalls } from '@common/api/api';
import { apiHandler, MethodType } from '@common/api/apiHandler';
import { ICustomError } from '@common/interfaces';

const { API_TRANSACTIONS } = DynamicCalls;

export const fetchTransactions = (
  payload: IGetTransactionsPayload,
): Promise<ISetTransactionsPayload & ICustomError> => {
  const {
    type: transaction_type,
    offset,
    rows,
    max_age_days,
    date_min,
    date_max,
  } = payload;
  const params = {
    xhr: 1,
    transaction_type,
    offset,
    rows,
    max_age_days,
    date_min,
    date_max,
  };

  return apiHandler<ISetTransactionsPayload>(
    { method: MethodType.Get, params },
    {
      apiUrl: API_TRANSACTIONS,
      apiData: {
        dynamicLanguage: services.domainLang,
      },
    },
  );
};

export default fetchTransactions;

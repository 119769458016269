/* eslint-disable @typescript-eslint/explicit-function-return-type */
import { create } from 'zustand';
import reduce from 'lodash/reduce';
import pick from 'lodash/pick';

import {
  ILiveCategories,
  ILiveCategoriesData,
  ILiveSportCategoriesTreeData,
  ICustomError,
  EventTimeFilters,
  ICategoryWeight,
  IMarketType,
} from '@common/interfaces';
import { defaultState } from '@common/providers/events/liveCategories/state';
import { createIdParentMap } from '@common/helpers/categories/categoriesModel';

export const useLiveCategories = create<ILiveCategories>(() => defaultState);

export const getSportCategoriesTree = () => {
  useLiveCategories.setState(state => ({
    sportCategoriesTree: {
      ...state.sportCategoriesTree,
      loading: false,
    },
  }));
};

export const setSportCategoriesTree = (payload: {
  categoriesTreeData: ILiveSportCategoriesTreeData[];
}) => {
  useLiveCategories.setState(state => {
    const liveCategories = reduce(
      payload.categoriesTreeData,
      (acc: ILiveCategoriesData[], currentObject) =>
        acc.concat(
          pick(currentObject, ['id', 'cid', 'path', 'label', 'count_live']),
        ),
      [] as ILiveCategoriesData[],
    );

    return {
      data: [...liveCategories],
      sportCategoriesTree: {
        ...state.sportCategoriesTree,
        loading: false,
        error: null,
        data: payload.categoriesTreeData,
      },
      flatSportCatTree: createIdParentMap(payload.categoriesTreeData),
    };
  });
};

export const setSportCategoriesTreeError = (payload: ICustomError) => {
  useLiveCategories.setState(state => ({
    sportCategoriesTree: {
      ...state.sportCategoriesTree,
      loading: false,
      error: payload,
    },
  }));
};

export const setSportCategoriesFilter = (payload: {
  filter: keyof typeof EventTimeFilters | '';
}) => {
  useLiveCategories.setState(state => ({
    sportCategoriesTree: {
      ...state.sportCategoriesTree,
      filter: payload.filter,
    },
  }));
};

export const toggleLiveCategory = (payload: { category: string }) => {
  useLiveCategories.setState(state => {
    const newState = { ...state };

    const { selectedCategories } = newState;
    const position = selectedCategories.indexOf(payload.category);

    if (position > -1) {
      newState.selectedCategories = [...[]];
    } else {
      newState.selectedCategories = [...[payload.category]];
    }

    return newState;
  });
};

export const toggleSportCategory = (payload: { category: string }) => {
  useLiveCategories.setState(() => ({
    selectedSportCategory: [payload.category],
  }));
};

export const toggleTestList = (payload: boolean) => {
  useLiveCategories.setState(() => ({
    showTestList: payload,
  }));
};

export const setDefaultLiveCategory = (payload: { category: string }) => {
  useLiveCategories.setState(() => ({
    selectedCategories: [payload.category],
  }));
};

export const selectMarketType = (payload: { market: IMarketType }) => {
  useLiveCategories.setState(() => ({
    marketTypeChecked: payload.market,
  }));
};

export const setSelectedCategoryEvents = (payload: {
  [key: string]: string[] | unknown;
}) => {
  useLiveCategories.setState(state => ({
    sportCategoriesTree: {
      ...state.sportCategoriesTree,
      selectedCategoryEvents: payload,
    },
  }));
};

export const startGettingHighlightedEvents = () => {
  useLiveCategories.setState(state => ({
    highlightsMeta: {
      ...state.highlightsMeta,
      highlightedEvents: {
        ...state.highlightsMeta.highlightedEvents,
        loading: true,
      },
      categoryWeight: { ...state.highlightsMeta.categoryWeight, loading: true },
    },
  }));
};

export const setHighlightedEvents = (payload: { events: string[] }) => {
  useLiveCategories.setState(state => ({
    highlightsMeta: {
      ...state.highlightsMeta,
      categoryWeight: {
        ...state.highlightsMeta.categoryWeight,
        loading: false,
      },
      highlightedEvents: {
        ...state.highlightsMeta.highlightedEvents,
        loading: false,
        data: payload,
        error: null,
      },
    },
  }));
};

export const setCategoryWeight = (payload: ICategoryWeight) => {
  useLiveCategories.setState(state => ({
    highlightsMeta: {
      ...state.highlightsMeta,
      categoryWeight: {
        ...state.highlightsMeta.categoryWeight,
        ...payload,
        loading: false,
      },
      highlightedEvents: {
        ...state.highlightsMeta.highlightedEvents,
        loading: false,
      },
    },
  }));
};

export const setHighlightedEventsError = (payload: ICustomError) => {
  useLiveCategories.setState(state => ({
    highlightsMeta: {
      ...state.highlightsMeta,
      categoryWeight: {
        ...state.highlightsMeta.categoryWeight,
        loading: false,
      },
      highlightedEvents: {
        ...state.highlightsMeta.highlightedEvents,
        loading: false,
        error: payload,
      },
    },
  }));
};

export const setSelectedCategoryTypeTab = (payload: {
  selectedCategoryTypeTab: string;
}) => {
  useLiveCategories.setState(() => ({
    selectedCategoryTypeTab: payload.selectedCategoryTypeTab,
  }));
};

import React from 'react';

import * as S from './Toggle.styled';
import IToggle from './Toggle.types';

const Toggle = (props: IToggle): React.ReactElement => {
  const { enabled = true, onClick, width, height, checkedState } = props;

  const handleClick = (): void => {
    if (enabled) {
      onClick();
    }
  };

  return (
    <S.Wrapper isDisabled={!enabled}>
      <S.Container isDisabled={!enabled} width={width} height={height}>
        <input
          className="cancel-mobile-focus"
          type="checkbox"
          onClick={(): void => handleClick()}
          checked={checkedState}
          readOnly
        />
        <span />
      </S.Container>
    </S.Wrapper>
  );
};

export default Toggle;

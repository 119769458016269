import styled from 'styled-components';

const realityStyles = ({ theme }): string => `
  color: ${theme.tertiaryBackground};
  left:   ${theme.isDesktop ? '0' : '8'}
`;

const activityStyles = ({ theme }): string => `
  color: ${theme.highlightWrapperBackground};
  & strong {
    color: ${theme.accent};
  }
`;

export default styled.span<{ timerType: string }>`
  position: relative;
  ${props => (props.timerType === 'activity' ? activityStyles : realityStyles)};
`;

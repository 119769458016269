import React from 'react';

import { ICommonScoreProps } from '@features/events/components/scoreboards/factoryScoreboards';
import { ScoreboardHoc } from '@features/events/components/scoreboards/scores';
import DefaultScore from '@features/events/components/scoreboards/scores/common/defaultScoreFactory/DefaultScoreFactory';

import {
  getScore,
  getQuarter,
  getOT,
  getLastPeriod,
} from '@common/helpers/eventsHelper/scoreboards/scoreBoardModel';

const HockeyScoreLarge = (props: ICommonScoreProps): React.ReactElement => {
  const { score } = props;

  return (
    <DefaultScore
      score={score}
      lastPeriod={getLastPeriod(score, getQuarter, 3)}
      periodsCount={3}
      className="score"
      getTotal={getScore}
      getPeriod={getQuarter}
      getOT={score.periods.OT ? getOT : undefined}
    />
  );
};

export default ScoreboardHoc(HockeyScoreLarge);

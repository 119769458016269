import React from 'react';

import { InputStateIcon } from '@features/core/form/components/inputStateIcon';

import { ValidateStatus } from '@common/interfaces';

import * as S from './SelectInput.styled';
import { ISelectInputProps } from './SelectInput.types';

const SelectInput: React.FC<ISelectInputProps> = props => {
  const {
    value,
    children,
    className,
    selectClassName,
    withoutBorder,
    validateStatus,
    errorIcon,
    successIcon,
    floatingLabel,
    ...selectProps
  } = props;
  const showFloatingLabel = value !== '';

  const getFloatingLabelText = (): string => {
    if (children) {
      return children[0].props.children;
    }
    return '';
  };

  return (
    // eslint-disable-next-line react/destructuring-assignment
    <S.Root disabled={props.disabled} className={className}>
      <S.Select
        value={value}
        withoutBorder={withoutBorder}
        className={selectClassName}
        withLabel={floatingLabel && showFloatingLabel}
        isError={validateStatus === ValidateStatus.error}
        {...selectProps}
      >
        {children}
      </S.Select>
      {floatingLabel && showFloatingLabel && (
        <S.FloatingLabel className="floatingLabel">
          {getFloatingLabelText()}
        </S.FloatingLabel>
      )}
      <S.DropIcon name="chevron-down" width="12" height="12" />
      {successIcon && validateStatus === ValidateStatus.success && (
        <InputStateIcon state={ValidateStatus.success} />
      )}
      {errorIcon && validateStatus === ValidateStatus.error && (
        <InputStateIcon state={ValidateStatus.error} />
      )}
    </S.Root>
  );
};

export default SelectInput;

import React from 'react';
import classNames from 'classnames';

import ITabTitle from './TabTitle.types';

const TabTitle: React.FC<ITabTitle> = ({
  id,
  title,
  isActive,
  onClick,
  className = '',
  activeClassName = '',
}) => {
  const handleClick = (): void => {
    onClick(id);
  };

  return (
    <div
      onClick={handleClick}
      className={classNames(className, {
        [activeClassName || 'active']: isActive,
      })}
      role="presentation"
    >
      {title}
    </div>
  );
};

export default TabTitle;

import { IBetDetail, IPagination, IUpdateBets } from '@common/interfaces';

const DEFAULT_FILTERS = {
  type: 'all',
  period: 7,
  offset: 0,
};

const defaultState = {
  bets: [] as IBetDetail[],
  betsLoading: false,
  betsFilter: DEFAULT_FILTERS,
  loading: false,
  detailLoading: false,
  error: null,
  betDetailError: null,
  pagination: {} as IPagination,
  bet_detail: {} as IBetDetail,
  updates: {} as Record<string, IUpdateBets>,
  socket: null,
  openBets: [] as IBetDetail[], // for cashout
  updatesReceived: false,
  selectedBetId: '',
  failedSocketReconnect: false,
};

export default defaultState;

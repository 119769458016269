import styled from 'styled-components';

// eslint-disable-next-line import/prefer-default-export
export const Wrapper = styled.div`
  display: grid;
  grid-auto-flow: column;
  height: 100%;

  @media screen and (max-width: 481px) {
    & .darts-scoreboard > div > div {
      min-width: 16px;
    }

    .darts-score {
      padding: 0;
      min-width: 22px !important;
    }
  }

  @media screen and (min-width: 481px) {
    .darts-score {
      padding: 0;
      min-width: 13px;

      &:last-child div {
        padding: ${({ theme }) => theme.space_1};
      }
    }
  }
`;

import styled from 'styled-components';

import CustomLink from '@features/core/routing/CustomLink';
import { TextNormal } from '@features/core/styles/text.styled';

import { hexToRgba } from '@common/helpers/deviceUtil';

import { Button } from '@ui/components/buttons';

export const SwitcherWrapper = styled.div`
  display: flex;
  background-color: ${({ theme }) => theme.textActive};
  color: ${({ theme }) => theme.highlightWrapperBackground};
  height: 20px;
  align-items: center;
  padding: ${({ theme }) => theme.space_5} ${({ theme }) => theme.space_10};
`;

export const UserLetter = styled.div`
  position: relative;
  box-sizing: border-box;
  display: grid;
  align-content: center;
  cursor: pointer;
  color: ${props => props.theme.textMain};
  text-transform: uppercase;

  svg {
    padding: ${({ theme }) => theme.space_5};
    border: solid 1px ${props => props.theme.accent};
    background-color: ${props => props.theme.accent};
    color: ${props => props.theme.inputDefaultText};
    border-radius: 50px;
  }
`;

export const DesktopLogoContainer = styled.div`
  display: grid;
  grid-auto-flow: column;
  align-items: center;
  height: 100%;
`;

export const LoginButtonsContainer = styled(Button)`
  border-radius: 0 0 0 35px;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 65px;
  height: 55px;
  box-shadow: 0 3px 3px 0 ${({ theme }) => hexToRgba(theme.main, 0.5)};
  padding: ${({ theme }) => theme.space_10} ${({ theme }) => theme.space_10}
    ${({ theme }) => theme.space_10} ${({ theme }) => theme.space_25};
  font-size: ${({ theme }) => theme.fontSM};

  & svg {
    padding-left: ${({ theme }) => theme.space_5};
  }

  @media (min-width: 1920px) {
    border-radius: 3px;
    height: 36px;
    padding: ${({ theme }) => theme.space_10};
    box-shadow: none;
    justify-content: center;

    #login-btn {
      display: none;
    }
  }
`;

export const LoginLabel = styled.span`
  align-self: center;
  color: ${props => props.theme.textActive};
  font-size: ${props => props.theme.fontMD};
  font-weight: ${({ theme }) => theme.fontBold};
  font-stretch: condensed;
  text-transform: uppercase;

  @media screen and (min-width: 1920px) {
    font-size: ${({ theme }) => theme.fontLG};
  }
`;

export const RightContentContainer = styled.div`
  height: 100%;
  display: grid;
  justify-content: end;
  align-content: center;
  min-width: 360px;

  .search {
    height: 90%;
    display: grid;
    grid-auto-flow: row;
    margin-right: ${({ theme }) => theme.space_25};
    min-width: 275px;
  }

  @media (max-width: 1520px) {
    .search {
      min-width: 250px;
    }
  }

  @media (max-width: 1439px) {
    .search {
      min-width: 130px;
      margin-right: 0;
    }
  }

  @media (min-width: 1920px) {
    justify-content: start;
  }
`;

export const RightContentLoggedInContainer = styled(RightContentContainer)`
  justify-content: end;
`;

export const ClockContainer = styled.div`
  display: none;
  align-items: center;
  margin: 0 ${({ theme }) => theme.space_20};
`;

export const ClockTimeIcon = styled.div`
  height: 18px;
  color: ${props => props.theme.highlightWrapperBackground};
`;

export const ClockTimeLabel = styled(TextNormal)`
  font-weight: ${({ theme }) => theme.fontBold};
  color: ${props => props.theme.highlightWrapperBackground};
  margin: 0 0 0 ${({ theme }) => theme.space_10};
`;

export const NavMenu = styled.ul`
  margin-left: ${({ theme }) => theme.space_15};
  padding: 0;
  display: grid;
  grid-auto-flow: column;
  align-content: center;
  justify-content: center;
  list-style: none;

  @media (max-width: 1520px) {
    margin-left: 0;
  }
`;

export const MenuItem = styled.li<{ isActive?: boolean }>`
  margin-right: ${({ theme }) => theme.space_30};
  font-size: ${props => props.theme.fontXL};
  font-weight: ${({ isActive, theme }) =>
    !isActive ? theme.fontRegular : theme.fontBold};
  font-stretch: condensed;
  color: ${({ isActive, theme }) => (isActive ? theme.accent : theme.textMain)};
  text-transform: uppercase;
  cursor: pointer;

  @media (max-width: 1520px) {
    margin-right: ${({ theme }) => theme.space_15};
  }

  @media (max-width: 1439px) {
    font-size: ${props => props.theme.fontMD};
  }

  &:last-child {
    margin-right: 0;
  }
`;

export const LogoContainer = styled(CustomLink)`
  width: 184px;
  height: 55px;
  cursor: pointer;

  & svg {
    width: 100%;
    height: 55px;
  }
`;

export const LoginBtn = styled.div`
  & svg {
    transform: rotate(180deg);

    path {
      fill: ${props => props.theme.main};
    }
  }
`;

export const LoginInfo = styled.div`
  display: grid;
  grid-auto-flow: column;
  align-items: center;
  justify-content: center;
`;

export const LoginContainer = styled.div`
  display: grid;
  grid-auto-flow: column;
  align-items: center;
  grid-gap: ${({ theme }) => theme.space_50};

  .register-btn {
    min-width: 225px;
    padding: 0 ${({ theme }) => theme.space_20};
  }

  @media screen and (min-width: 1920px) {
    grid-gap: ${({ theme }) => theme.space_20};
    padding-right: ${({ theme }) => theme.space_20};
  }
`;

export const UserContainer = styled.div`
  display: grid;
  grid-auto-flow: column;
  align-items: center;

  .user-btn {
    padding: ${({ theme }) => theme.space_10} ${({ theme }) => theme.space_15};
  }
`;

export const BetsButtonWrapper = styled.div`
  margin: 0 ${({ theme }) => theme.space_30} 0 ${({ theme }) => theme.space_50};
`;

export const Triangle = styled.div`
  position: absolute;
  bottom: -25px;
  right: 50%;
  border: 10px solid transparent;
  border-top: 10px solid ${({ theme }) => theme.highlightsHeaderBackground};
  transform: translateX(50%);

  @media screen and (min-width: 1920px) {
    right: 20%;
  }

  @media screen and (min-width: 2100px) {
    display: none;
  }
`;

import replace from 'lodash/replace';

import { fixDecimals } from '@common/helpers/deviceUtil';

/**
 * removeNumSeparators
 * remove separator for string with integer number
 *
 * @param {string} value
 * @returns {string} num string without separator
 */
export const removeNumSeparators = (value: string): string =>
  replace(value, /[.,]/g, '');

/**
 * addNumSeparators
 * DE: 1.234.567
 * EN: 1,234,567
 * add separator for string with integer number
 *
 * @param {string} value
 * @returns {string} num string with separator
 */
export const addNumSeparators = (value: string): string => {
  const limitWithoutSeparators = removeNumSeparators(value);

  return limitWithoutSeparators === ''
    ? ''
    : fixDecimals(limitWithoutSeparators, { precision: 0 });
};

import styled from 'styled-components';

export const Wrapper = styled.div<{ isDisabled: boolean }>`
  ${({ isDisabled }) => isDisabled && 'cursor: not-allowed; opacity: .4;'}
`;

const getDisabledStyles = ({ theme }) => `
  pointer-events: none;
  span, input:checked + span { background: ${theme.toggleDisabledBackground}};  
`;
export const Container = styled.label<{
  isDisabled: boolean;
  width: string;
  height: string;
}>`
  position: relative;
  display: inline-block;
  cursor: pointer;

  width: ${props => props.width};
  height: ${props => props.height};

  input {
    opacity: 0;
    width: 0;
    height: 0;
  }

  span {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: ${({ theme }) => theme.toggleBackground};
    transition: 0.3s;
    border-radius: 30px;
  }

  span::before {
    position: absolute;
    content: '';
    height: calc(${props => props.height} - 6px);
    width: calc(${props => props.height} - 6px);
    left: 3px;
    bottom: 3px;
    background-color: ${({ theme }) => theme.pointerBackground};
    border-radius: 50%;
    transition: 0.3s;
  }

  input:checked + span {
    background-color: ${({ theme }) => theme.toggleActiveBackground};
  }

  input:checked + span::before {
    width: calc(${props => props.height} - 6px);
    height: calc(${props => props.height} - 6px);
    background-color: ${({ theme }) => theme.pointerActiveBackground};
    transform: translateX(
      calc(${props => props.width} - ${props => props.height})
    );
  }

  ${({ isDisabled }) => isDisabled && getDisabledStyles}
`;

import styled from 'styled-components';

import { TextSM } from '@features/core/styles/text.styled';

export const WrapperEmpty = styled.div`
  display: grid;
  align-items: center;
  justify-content: start;
  grid-auto-flow: column;
  background: ${({ theme }) => theme.textMain};
  padding: ${({ theme }) => theme.space_10};
  color: ${({ theme }) => theme.textActive};
  margin-bottom: ${({ theme }) => theme.space_15};
`;

export const ErrorMessage = styled(TextSM)`
  font-weight: ${({ theme }) => theme.fontBold};
  padding-left: ${({ theme }) => theme.space_10};
  margin: 0;
`;

import styled from 'styled-components';

import CombiMatrixStatusCode from '@features/bets/components/combiMatrix/CombiMatrix.types';

export const TableContainer = styled.div``;

export const Table = styled.table`
  border-collapse: collapse;
  margin-bottom: ${({ theme }) => theme.space_10};
  text-align: center;
  width: 100%;
  padding: 0;
  table-layout: fixed;
`;

export const TableHeader = styled.thead``;

export const TableRow = styled.tr`
  width: 100%;
`;

export const TableBody = styled.tbody`
  width: 100%;
`;

export const TableTitle = styled.th<{ isStatus?: boolean }>`
  color: ${props => props.theme.textMain};
  font-weight: ${({ theme }) => theme.fontBold};
  text-align: center;
  border-bottom: 1px solid ${props => props.theme.secondary};

  &:first-child {
    width: 41px;
  }

  &:nth-child(2) {
    ${({ isStatus }) => isStatus && 'width: 48px;'}
  }
`;

export const bgColor = (type, theme) => {
  switch (type) {
    case CombiMatrixStatusCode.WON:
      return theme.statusWon;
    case CombiMatrixStatusCode.LOST:
      return theme.statusError;
    default:
      return theme.accent;
  }
};

export const TableCell = styled.td<{
  status?: number | boolean;
  isWon?: boolean;
}>`
  color: ${({ isWon, theme }) => (isWon ? theme.accent : theme.textMain)};
  border-bottom: 1px solid ${props => props.theme.secondary};
  background-color: ${props =>
    props.status ? bgColor(props.status, props.theme) : props.theme.textActive};
  padding: ${({ theme }) => theme.space_5} 0;

  &:nth-child(2n) {
    background-color: ${props =>
      props.status
        ? bgColor(props.status, props.theme)
        : props.theme.tertiaryBackground};
  }

  &:first-child {
    width: 41px;
    color: ${props => props.theme.textMain};
    font-weight: ${({ theme }) => theme.fontBold};
    border-right: 1px solid ${props => props.theme.secondary};
  }
`;

export const TableItemWrapper = styled.div`
  display: grid;
  grid-auto-flow: column;
  justify-content: center;
  align-items: center;
`;

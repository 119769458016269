import React from 'react';
import { useTranslation } from 'react-i18next';

import { formatBalance } from '@common/helpers/paymentsHelper/walletsHelper';

import * as S from './BetStats.styled';
import { IBetStats } from './BetStats.types';

const BetStats: React.FC<IBetStats> = props => {
  const { t } = useTranslation();
  const { totalStake, remainingRisk, current = true, betId } = props;

  return (
    <S.Wrapper className={`cashout-container cashout-el-bg-${betId}`}>
      <S.InfoLeft data-qa="total-stake">
        <S.BetStatLabel>
          {current ? t('cashout.current_bet') : t('cashout.new_current_bet')}
        </S.BetStatLabel>
        <S.StyledBoldText>{formatBalance(totalStake)}</S.StyledBoldText>
      </S.InfoLeft>
      <S.InfoRight data-qa="possible-winnings">
        <S.BetStatLabel>
          {current
            ? t('common.labels.possible_winnings')
            : t('cashout.new_possible_winnings')}
        </S.BetStatLabel>
        <S.StyledBoldText>{formatBalance(remainingRisk)}</S.StyledBoldText>
      </S.InfoRight>
    </S.Wrapper>
  );
};

export default BetStats;

import styled from 'styled-components';

import { TextInput } from '@features/core/form/components/textInput';

export const InputContainer = styled.div`
  position: relative;
  width: 100%;
`;

export const SearchIcon = styled.span<{ hasValue?: boolean }>`
  position: absolute;
  top: 2px;
  bottom: 2px;
  right: 3px;
  z-index: 11;
  padding: 0 ${({ theme }) => theme.space_10};
  display: grid;
  align-content: center;

  ${({ hasValue }) => !hasValue && 'pointer-events: none;'};

  & svg {
    color: ${props => props.theme.selectTextColor};
  }
`;

export const StyledTextInput = styled(TextInput)`
  width: 100%;
`;

import isArray from 'lodash/isArray';

import i18n from '@features/core/translation';
import services from '@features/core/services';
import CustomError from '@features/core/error/error';
import { getPageURL, PageName } from '@features/core/routing';

import {
  SESSION_ID,
  TOKEN,
  USER_ID,
  FWD_DEST_COOKIE,
} from '@common/constants/cookie';
import fetchRecoverPassword from '@common/api/account/fetchRecoverPassword';
import fetchChangePassword from '@common/api/account/fetchChangePassword';
import { ICustomError } from '@common/interfaces';
import {
  getDefaultRouteName,
  setLocation,
} from '@common/providers/router/helper';
import fetchForgetPassword from '@common/api/account/fetchForgetPassword';
import apiRpcRequest from '@common/api/common/apiCommonRequest';
import { MethodType, apiHandler } from '@common/api/apiHandler';
import { DynamicCalls, StaticCalls } from '@common/api/api';
import authUser from '@common/providers/user/actions/authUser';
import logoutUser from '@common/providers/user/actions/logoutUser';
import loginUser from '@common/providers/user/actions/loginUser';
import {
  IFetchAvsTokenResponse,
  IFetchChangePasswordResponse,
  IFetchConfirmTermsResponse,
  IFetchEmailResendResponse,
  IFetchGdprData,
  IFetchPostBonusCodesResponse,
  IGetForgetPasswordPayload,
  IGetIframeBetValidationPayload,
  IGetNewPasswordPayload,
  ISetRecoverPasswordSuccessPayload,
  ISetSubscriptionsPayload,
} from '@common/providers/account/types';
import * as accActions from '@common/providers/account/useAccount';
import fetchIframeBetValidation from '@common/api/account/fetchIframeBetValidation';
import fetchSubscriptions from '@common/api/account/fetchSubscriptions';
import { getWallets } from '@common/providers/payments/wallets/helpers';
import { getBonusList } from '@common/providers/bonus/helper';
import {
  postCancelBonusCodeError,
  postCancelBonusCodeSuccess,
  resetBonusCode,
} from '@common/providers/account/useAccount';

const { API_CONFIRM_TERMS, API_RESEND_EMAIL } = DynamicCalls;
const { API_BONUS_CODE } = StaticCalls;

export const setSubscriptions = async (
  payload: ISetSubscriptionsPayload,
): Promise<void> => {
  accActions.requestSubscriptions();
  const fetchData = await fetchSubscriptions({
    method: MethodType.Post,
    payload,
  });
  if (fetchData instanceof CustomError) {
    accActions.setSubscriptionsError(fetchData);
  } else {
    accActions.setSubscriptionsState({
      ...payload,
      success: fetchData.result?.success,
    });
  }
};
export const getSubscriptions = async (
  payload: ISetSubscriptionsPayload,
): Promise<void> => {
  const fetchData = await fetchSubscriptions({
    method: MethodType.Get,
    payload,
  });
  if (fetchData instanceof CustomError) {
    accActions.setSubscriptionsError(fetchData);
  } else {
    accActions.setSubscriptionsState({
      verified_email_flags: !!Number(fetchData?.result?.verified_email_flags),
    });
  }
};

export const getNewPassword = async (
  payload: IGetNewPasswordPayload,
): Promise<void> => {
  accActions.startSettingRecoverPassword();
  const handler = payload.recovery_token
    ? fetchRecoverPassword
    : fetchChangePassword;

  const response = (await handler(
    payload,
  )) as ISetRecoverPasswordSuccessPayload &
    IFetchChangePasswordResponse &
    ICustomError;
  if (
    response instanceof CustomError ||
    response?.result?.error ||
    response?.exceptionClass ||
    (response.message &&
      !new RegExp(
        [
          //   This is still needed for change password HTML endpoint
          'Ihr Passwort wurde geändert',
          'You password has been changed',
          'Indique su contraseña anterior correcta',
          'Ο κωδικός πρόσβασής σας έχει αλλάξει',
          'Votre mot de passe a été modifié',
          'Ваш пароль был изменен',
          'Parolanız değiştirildi',
        ].join('|'),
      ).test(response.message))
  ) {
    return accActions.setRecoverPasswordError(
      (response?.message || i18n.t('common.labels.error')) as ICustomError,
    );
  }

  if (payload.shouldLogout) {
    await logoutUser();
    setLocation(PageName.HOME);
  }

  if (payload.recovery_token) {
    setTimeout(() => {
      services.cookie.set(
        FWD_DEST_COOKIE,
        encodeURIComponent(getPageURL(getDefaultRouteName())),
      );
      loginUser({
        login: response.result.login as string,
        password: payload.password as string,
      });
    }, 2000);
  }

  return accActions.setRecoverPasswordSuccess();
};

export const getForgetPassword = async (
  payload: IGetForgetPasswordPayload,
): Promise<void> => {
  const response = await fetchForgetPassword(payload);
  if (response instanceof CustomError) {
    return accActions.setRecoverPasswordError(response);
  }
  return accActions.setRecoverPasswordSuccess();
};

export const getGdprData = async (): Promise<void> => {
  accActions.setGdprData();
  const params = {
    method: `user.get_gdpr_documents`,
  };

  const response = (await apiRpcRequest({
    method: MethodType.Get,
    params,
  })) as IFetchGdprData;

  if (response instanceof CustomError) {
    accActions.getGdprDataError(response);
  } else {
    accActions.getGdprDataSuccess(response.result?.files || []);
  }
};

export const getAvsToken = async (): Promise<void> => {
  accActions.startFetchingAvsToken();
  const { result } = (await apiRpcRequest({
    method: MethodType.Post,
    data: {
      method: 'user.avs_credentials',
    },
  })) as IFetchAvsTokenResponse;

  if (result) {
    accActions.setAvsToken(result);
  }
};

export const resendEmailConfirmation = (): void => {
  apiHandler<IFetchEmailResendResponse>(
    { method: MethodType.Get },
    {
      apiUrl: API_RESEND_EMAIL,
      apiData: {
        dynamicLanguage: services.domainLang,
      },
    },
  );
};

export const postBonuses = async (code: string): Promise<void> => {
  accActions.postBonusCode();
  const response = await apiHandler<IFetchPostBonusCodesResponse>(
    { method: MethodType.Post, data: { voucher: code } },
    {
      apiUrl: API_BONUS_CODE,
    },
  );

  if (response instanceof CustomError) {
    return accActions.postBonusCodeError(
      isArray(response.message) ? response.message[0] : response.message,
    );
  }

  if (response.oasis_check?.error_messg) {
    return accActions.postBonusCodeError('OasisCheckError');
  }
  setTimeout(() => {
    getWallets();
    getBonusList();
  }, 4000);
  return accActions.postBonusCodeSuccess(response.trigger_by);
};

export const postCancelBonus = async (bonusId: string): Promise<void> => {
  resetBonusCode();

  const response = await apiHandler<IFetchPostBonusCodesResponse>(
    { method: MethodType.Post, data: { bonusId } },
    {
      apiUrl: API_BONUS_CODE,
    },
  );

  if (response instanceof CustomError) {
    return postCancelBonusCodeError(
      isArray(response.message) ? response.message[0] : response.message,
    );
  }

  getWallets();

  return postCancelBonusCodeSuccess();
};

export const confirmTerms = async (): Promise<void> => {
  await apiHandler<IFetchConfirmTermsResponse>(
    { method: MethodType.Post },
    {
      apiUrl: API_CONFIRM_TERMS,
      apiData: {
        dynamicLanguage: services.domainLang,
      },
    },
  );
  authUser();
};

export const getIframeBetValidationAction = async (
  payload: IGetIframeBetValidationPayload,
): Promise<void> => {
  services.cookie.remove(TOKEN);
  services.cookie.remove(SESSION_ID);
  services.cookie.remove(USER_ID);
  const response = await fetchIframeBetValidation(payload);

  if (response instanceof CustomError) {
    throw Error(response?.message);
  }
  const {
    session_id: session,
    secret_token: token,
    user_id,
    login_success,
  } = response.result;

  services.cookie.set(TOKEN, token);
  services.cookie.set(SESSION_ID, session);
  services.cookie.set(USER_ID, user_id);

  if (login_success) {
    authUser();
  }
};

import styled from 'styled-components';

const ButtonWrapper = styled.div<{
  isLive: boolean;
  isInCategoryEventsPage: boolean;
  isHomePage: boolean;
}>`
${({ theme }) => theme.isDesktop && 'grid-column-end: 3;'}

${({ theme }) => theme.isDesktop && 'margin-left: auto !important;'}

  margin-right: ${({ isInCategoryEventsPage, theme }) =>
    isInCategoryEventsPage && theme.space_10};
  justify-content: end;
  cursor: pointer;
  justify-self: end;
  padding: ${({ isLive, theme }) => isLive && theme.space_10};

`;

export default ButtonWrapper;

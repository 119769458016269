import forEach from 'lodash/forEach';
import reduce from 'lodash/reduce';
import forOwn from 'lodash/forOwn';
import uniq from 'lodash/uniq';

import {
  ITopData,
  IQuickLinksItem,
  IQuickLinksDataRecord,
} from '@common/interfaces';

/**
 * parseHighlights
 *
 * @param {ITopData} payload
 * @returns {IQuickLinksItem[]} events
 */
export const parseHighlights = (payload: ITopData): IQuickLinksItem[] => {
  const result: IQuickLinksItem[] = [];
  if (payload.data_sports) {
    return uniq(
      reduce(
        payload.data_sports,
        (acc, sport) => {
          if ((sport as IQuickLinksDataRecord).data_events) {
            forEach(
              (sport as IQuickLinksDataRecord).data_events,
              sportEvents => {
                forOwn(sportEvents, (item, key) => {
                  const eventItem: IQuickLinksItem = {
                    event_id: item.event_id,
                    sort_index: key,
                  };
                  return acc.push(eventItem);
                });
              },
            );
          }
          return acc;
        },
        result,
      ),
    );
  }
  return result;
};

/**
 * getQuickLinksIDs
 *
 * @param {IQuickLinksItem[]} quickLinks
 * @returns {string[]} ids
 */
export const getQuickLinksIDs = (quickLinks: IQuickLinksItem[]): string[] => {
  return reduce(
    quickLinks,
    (acc, event: IQuickLinksItem) => acc.concat(event.event_id),
    [] as string[],
  );
};

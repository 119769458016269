import React from 'react';

import { RadioTitle } from '@features/registration/components/step4';

import * as S from './RadioButton.styled';
import { IRadioButtonProps } from './RadioButton.types';

const RadioButton: React.FC<IRadioButtonProps> = props => {
  const {
    validateStatus,
    value,
    title,
    checked,
    name,
    disabled,
    isBottomMargin,
    isDarkMode,
    ...inputProps
  } = props;

  return (
    <>
      <S.RadioField
        id={`radiobtn-${value}`}
        {...inputProps}
        type="radio"
        name={name || 'radiobtn'}
        value={value}
        checked={checked}
        disabled={disabled || false}
        isDarkMode={!!isDarkMode}
      />
      <S.Wrapper
        htmlFor={`radiobtn-${value}`}
        isBottomMargin={!!isBottomMargin}
        isDarkMode={!!isDarkMode}
      >
        <S.RadioLabel
          isError={validateStatus === 'error'}
          data-qa={`radiobtn-${value}`}
          isDarkMode={!!isDarkMode}
        />
        {title && (
          <RadioTitle
            id="radio-title"
            dangerouslySetInnerHTML={{ __html: title }}
          />
        )}
      </S.Wrapper>
    </>
  );
};

export default RadioButton;

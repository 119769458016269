import { shallow } from 'zustand/shallow';
import { createWithEqualityFn } from 'zustand/traditional';
import reduce from 'lodash/reduce';
import isArray from 'lodash/isArray';

import {
  IPushNotifications,
  IPushNotificationsReasons,
} from '@common/interfaces';
import defaultState from '@common/providers/pushNotifications/state';
import {
  ISetPushSubscriptionPayload,
  ISetPushSubsriptions,
  ISourceToUpdate,
} from '@common/providers/pushNotifications/types';

export const usePushNotificationsState = createWithEqualityFn<
  IPushNotifications
>(() => defaultState, shallow);

const updateNotification = (
  id: string,
  settings: IPushNotificationsReasons | null,
  state: IPushNotifications,
  sourceToUpdate: keyof typeof ISourceToUpdate,
): IPushNotifications => {
  if (!settings) {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { [id]: _, ...updatedEvents } = state.subscriptions[sourceToUpdate];
    return {
      ...state,
      subscriptions: {
        ...state.subscriptions,
        [sourceToUpdate]: updatedEvents,
      },
    };
  }

  return {
    ...state,
    subscriptions: {
      ...state.subscriptions,
      [sourceToUpdate]: {
        ...state.subscriptions[sourceToUpdate],
        [id]: settings,
      },
    },
  };
};

export const setPushNotificationLoading = (): void => {
  usePushNotificationsState.setState(() => ({
    loading: true,
  }));
};

export const setPushNotificationSuccess = (): void => {
  usePushNotificationsState.setState(() => ({
    loading: false,
    isEnabled: true,
  }));
};

export const setChoosedPushSubscription = ({
  eid,
  eids,
  cid,
  settings,
}: ISetPushSubscriptionPayload): void => {
  usePushNotificationsState.setState(state => {
    if (eid) {
      return updateNotification(eid, settings, state, ISourceToUpdate.events);
    }

    if (eids) {
      return reduce(
        eids,
        (acc, id) =>
          updateNotification(id, settings, acc, ISourceToUpdate.events),
        state,
      );
    }

    if (cid) {
      return updateNotification(
        cid,
        settings,
        state,
        ISourceToUpdate.categories,
      );
    }
    return state;
  });
};

export const setPushSubscriptions = ({
  type,
  list,
}: ISetPushSubsriptions): void => {
  usePushNotificationsState.setState(state => {
    if (isArray(list) && type === ISourceToUpdate.all) {
      const [event, category] = list;

      return {
        ...state,
        loading: false,
        subscriptions: {
          ...state.subscriptions,
          events: event,
          categories: category,
        },
      };
    }
    return {
      ...state,
      loading: false,
      subscriptions: {
        ...state.subscriptions,
        [type]: list as Record<string, IPushNotificationsReasons>,
      },
    };
  });
};

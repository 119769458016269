import styled from 'styled-components';

const getLabelDesktopStyles = theme => {
  return `
    & .floatingLabel {
    color: ${theme.inputDefaultText};
    font-size: ${props => props.theme.fontMD};
  }`;
};

// eslint-disable-next-line import/prefer-default-export
export const FormWrapper = styled.div<{ disabled: boolean }>`
  ${({ theme }) => theme.isDesktop && getLabelDesktopStyles(theme)};

  pointer-events: ${({ disabled }) => (disabled ? 'none' : 'auto')};
  opacity: ${({ theme, disabled }) => (disabled ? theme.disabledOption : 1)};
`;

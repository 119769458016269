import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import split from 'lodash/split';
import map from 'lodash/map';
import find from 'lodash/find';

import { DATE_TEMPLATE } from '@features/core/form/components/dateInput/DateTemplates';

import { BLOCK_ACCOUNT_SUBMIT_LAYER } from '@common/constants/dialogs';
import { SidebarSide, ButtonType } from '@common/interfaces';
import {
  selectBlockAccountReasons,
  selectItems,
} from '@common/helpers/blockAccountHelper';
import formatDate from '@common/helpers/timeHelper/localizedFormat';
import { closeSidebar } from '@common/providers/application/useAppState';
import {
  resetLockAccountData,
  useAccount,
} from '@common/providers/account/useAccount';
import { setSelfExclusion } from '@common/providers/limits/helper';

import { StyledButton } from '@ui/components/buttons';

import * as S from './BlockAccountSubmitLayer.styled';

const BlockAccountSubmitLayer = (): React.ReactElement | null => {
  const { t } = useTranslation();
  const lockAccount = useAccount(s => s.lockAccount);

  const getDurationPeriod = (): string => {
    if (lockAccount?.isCustom) {
      return `${t('limits.block_account_submit_modal_to_date')} ${formatDate(
        lockAccount.period,
        DATE_TEMPLATE,
      )}`;
    }
    const label = find(selectItems, ['value', lockAccount?.period]);
    return label?.short_label || '';
  };

  const handleClose = (): void => {
    closeSidebar(BLOCK_ACCOUNT_SUBMIT_LAYER);
    resetLockAccountData();
  };

  const onSubmitHandler = (): void => {
    setSelfExclusion({
      reason: lockAccount?.reason || '',
      period: lockAccount?.period || '',
    });
    closeSidebar(BLOCK_ACCOUNT_SUBMIT_LAYER);
  };

  useEffect(() => {
    return () => handleClose();
  }, []);

  return (
    <S.Content>
      <S.ContentIcon
        className="closeIcon"
        onClick={handleClose}
        name="close"
        fill="currentColor"
      />
      <S.TitleInner>
        <S.Title>{t('limits.block_account_submit_modal_title')}</S.Title>
      </S.TitleInner>
      <span>{t('limits.block_account_submit_modal_content')}</span>
      <br />
      {lockAccount?.reason && (
        <>
          {map(split(lockAccount.reason, ','), item => {
            const label = find(selectBlockAccountReasons, ['value', item]);
            return (
              <S.ReasonContentTitle key={item}>
                - {label?.label}
              </S.ReasonContentTitle>
            );
          })}
        </>
      )}
      <S.DurationTitle>
        {t('limits.block_account_submit_modal_duration')}
      </S.DurationTitle>
      <S.DurationPeriod>{getDurationPeriod()}</S.DurationPeriod>
      <S.ConfirmContent>
        {t('limits.block_account_submit_modal_confirm_title')}
      </S.ConfirmContent>
      <StyledButton
        onClick={onSubmitHandler}
        label="limits.block_account_submit_modal_title"
        buttonType={ButtonType.Secondary}
      />
      <StyledButton
        onClick={handleClose}
        label="common.labels.abort"
        buttonType={ButtonType.DarkGhost}
        className="block-acc-abort-btn"
      />
    </S.Content>
  );
};

BlockAccountSubmitLayer.meta = {
  side: SidebarSide.center,
  key: BLOCK_ACCOUNT_SUBMIT_LAYER,
};

export { BlockAccountSubmitLayer as Component };

export default BlockAccountSubmitLayer;

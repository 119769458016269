import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import {
  getStaticPageURL,
  PageName,
  StaticSlugPageName,
} from '@features/core/routing/linkAliases';
import { useServices } from '@features/core/services';
import { UserFormButtonsWrapper } from '@features/user/components/userForm';
import { CheckboxInput } from '@features/core/form/components/checkboxInput';

import { TERMS_LAYER } from '@common/constants/dialogs';
import { ACCEPT_TAC_LAYER_COOKIE } from '@common/constants/cookie';
import logoutUser from '@common/providers/user/actions/logoutUser';
import { setLocation } from '@common/providers/router/helper';
import { confirmTerms } from '@common/providers/account/helper';
import { closeSidebar } from '@common/providers/application/useAppState';

import * as S from './AcceptTermsForm.styled';

const AcceptTermsForm: React.FC = () => {
  const { t } = useTranslation();
  const { cookie } = useServices();
  const termsConditionsLink = getStaticPageURL(
    PageName.INFORMATION,
    StaticSlugPageName.TERMS,
  );
  const [formData, setFormData] = useState({
    terms_agree: false,
  });
  const isEnableSubmit: boolean = formData.terms_agree;

  const handleCheckbox = (e: React.ChangeEvent<HTMLInputElement>): void => {
    setFormData(prevState => ({
      ...prevState,
      [e.target.id]: !prevState[e.target.id],
    }));
  };

  const handleSubmit = (e: React.MouseEvent<HTMLElement>): void => {
    e.preventDefault();
    confirmTerms();
    closeSidebar(TERMS_LAYER);
    cookie.set(ACCEPT_TAC_LAYER_COOKIE, ACCEPT_TAC_LAYER_COOKIE);
  };

  const handleAbort = async (
    e: React.MouseEvent<HTMLElement>,
  ): Promise<void> => {
    e.preventDefault();
    await logoutUser();
    closeSidebar(TERMS_LAYER);
    setLocation(PageName.HOME);
  };

  return (
    <S.Form name="accept-terms">
      <S.FormGroup>
        <div className="group-title">
          {t('registration.step4.general_terms')}
        </div>
        <CheckboxInput
          label="user.terms.accept_terms_text"
          checked={formData.terms_agree}
          onChange={handleCheckbox}
          id="terms_agree"
          linkHref={termsConditionsLink}
          ariaLabel="common.links.terms_of_service"
        />
      </S.FormGroup>

      {/* <S.FormGroup>
        <div className="group-title">
          {t('registration.step4.privacy_agree')}
        </div>

        <UserFormCheckbox
          label="user.terms.accept_privacy_policy_text"
          isChecked={formData.privacy_policy}
          onChangeHandler={handleCheckbox}
          id="privacy_policy"
          linkHref={privacyPolicyLink}
          ariaLabel="common.links.privacy_policy"
        />
      </S.FormGroup> */}

      <UserFormButtonsWrapper
        onSubmit={handleSubmit}
        onAbort={handleAbort}
        disabled={!isEnableSubmit}
        submitTitle="common.labels.save_information"
        buttonsQa="terms"
      />
    </S.Form>
  );
};

export default AcceptTermsForm;

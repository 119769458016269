import React from 'react';

import { ScoreboardHoc } from '@features/events/components/scoreboards/scores';

import {
  getBestOfLegs,
  getBestOfSets,
  hasSets,
} from '@common/helpers/eventsHelper/scoreboards/darts';
import { IScore } from '@common/interfaces';

import * as S from './DartsTimer.styled';

const DartsTimer = ({ score }: { score: IScore }): React.ReactElement => {
  return (
    <S.TimerWrapper>
      Best
      <br />
      of {hasSets(score) ? getBestOfSets(score) : getBestOfLegs(score)}
    </S.TimerWrapper>
  );
};

export default ScoreboardHoc(DartsTimer);

import { IEvent, IScore, EventListTypes } from '@common/interfaces';

export interface IFactoryScoreboardsProps {
  id: string;
  event: IEvent;
  topCategoryId: string;
  type: keyof typeof ScoreboardTypes | string;
  listType: keyof typeof EventListTypes;
}

export interface ICommonScoreProps {
  id: string;
  detailView?: boolean;
  listType: keyof typeof EventListTypes;
  event: IEvent;
  score: IScore;
  period: number;
  sportName?: string;
}

export interface ICommonScoreHocProps {
  id: string;
  detailView?: boolean;
  listType: keyof typeof EventListTypes;
}

export enum ScoreboardTypes {
  'default' = 'default', // events list
  'large' = 'large', // events detail
  'small' = 'small', // BS/Cashout/BetDetails events
}

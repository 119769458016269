/* eslint-disable import/prefer-default-export */
import React from 'react';
import map from 'lodash/map';
import { TFunction } from 'react-i18next';

import { Selections } from '@features/events/components/selections';

import CombiMatrixModel from '@common/helpers/combinations/combiMatrixModel';
import { getCashoutSum, hasPartialCashout } from '@common/helpers/betsHelper';
import { IBetDetail } from '@common/interfaces';
import { getFormatDate } from '@common/helpers/betDetailHelper';
import { formatBalance } from '@common/helpers/paymentsHelper/walletsHelper';

import * as S from '../BetDetail.styled';

export const renderSelections = (bet): null | JSX.Element => {
  if (
    bet.selections &&
    bet.selections.length &&
    CombiMatrixModel.hasMatrix(bet)
  ) {
    return <Selections even letters selections={bet.selections} />;
  }
  if (bet.selections && bet.selections.length) {
    return <Selections even selections={bet.selections} />;
  }
  return null;
};

export const renderCashoutSum = (bet, t): boolean | JSX.Element => {
  return (
    hasPartialCashout(bet) && (
      <>
        <S.DetailListItem>
          <b>{t('cashout.cashout_sum')}</b>
          <b>{formatBalance(getCashoutSum(bet.cashouts))}</b>
        </S.DetailListItem>
        <S.DoubleDivider />
      </>
    )
  );
};

export const renderPartialCashout = (
  bet: IBetDetail,
  t: TFunction<'translation', undefined>,
): JSX.Element => {
  return (
    <S.DetailContainer className="cashout">
      <S.DetailHeader data-qa="cashout-history">
        {t('cashout.cashout_history')}
      </S.DetailHeader>
      <S.DetailList>
        {hasPartialCashout(bet) &&
          map(bet.cashouts, (cashout, idx) => (
            <S.DetailListItem
              key={cashout.cashout_id}
              withDivider={bet.cashouts.length - 1 !== idx}
            >
              <span>{getFormatDate(cashout.created_ts, true)}</span>
              <span>
                {t('cashout.cashout')}
                <b style={{ marginLeft: '5px' }}>
                  {formatBalance(cashout.amount)}
                </b>
              </span>
              {bet.cashouts.length - 1 !== idx && <S.DividerLine />}
            </S.DetailListItem>
          ))}
        {renderCashoutSum(bet, t)}
      </S.DetailList>
    </S.DetailContainer>
  );
};

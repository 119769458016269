import { create } from 'zustand';

import { IBonus, ICustomError } from '@common/interfaces';
import { IBonusState } from '@common/providers/bonus/types';
import defaultState from '@common/providers/bonus/state';

export const useBonusState = create<IBonusState>(() => defaultState);

export const setBonusLoading = (): void => {
  useBonusState.setState(() => ({
    loading: true,
  }));
};

export const setBonusList = (bonusList: IBonus[]): void => {
  useBonusState.setState(() => ({
    loading: false,
    bonusList,
  }));
};

export const setActiveBonus = (activeBonus: IBonus): void => {
  useBonusState.setState(() => ({
    loading: false,
    activeBonus,
  }));
};

export const setBonusError = (error: ICustomError): void => {
  useBonusState.setState(() => ({
    loading: false,
    error,
  }));
};

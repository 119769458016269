import take from 'lodash/take';

import CustomError, {
  convertResponseToError,
} from '@features/core/error/error';

import fetchSportCategoriesTree from '@common/api/events/fetchCategoriesTree';
import {
  ICategoriesTreeParams,
  ILiveSportCategoriesTreeData,
  IResponseError,
} from '@common/interfaces';
import {
  getSportCategoriesTree,
  setCategoryWeight,
  setHighlightedEvents,
  setHighlightedEventsError,
  setSelectedCategoryEvents,
  setSportCategoriesTree,
  setSportCategoriesTreeError,
  startGettingHighlightedEvents,
} from '@common/providers/events/liveCategories/useLiveCategories';
import fetchEventsList from '@common/api/events/fetchEventsList';
import { setEventsListError } from '@common/providers/events/eventList/useEventsList';
import fetchHighLights from '@common/api/events/fetchHighlightsMeta';
import {
  getQuickLinksIDs,
  parseHighlights,
} from '@common/helpers/highlightsHelper';

export const getCategoriesTree = async (
  params: ICategoriesTreeParams,
): Promise<void> => {
  getSportCategoriesTree();
  try {
    const response: ILiveSportCategoriesTreeData[] = await fetchSportCategoriesTree(
      params,
    );

    if (response instanceof CustomError) {
      setSportCategoriesTreeError(
        new CustomError({
          message: '',
        }),
      );
    } else {
      setSportCategoriesTree({ categoriesTreeData: response });
    }
  } catch (e) {
    setSportCategoriesTreeError(
      new CustomError({
        message: convertResponseToError(e as IResponseError),
      }),
    );
  }
};

export const getSelectedCategoryEvents = async (payload: {
  category: string;
}): Promise<{ [x: string]: string[] } | unknown> => {
  try {
    const { category } = payload;
    const result = await fetchEventsList({
      categories: [category],
      sync: true,
    });
    setSelectedCategoryEvents({ [category]: result.sync });

    return { [category]: result.sync };
  } catch (e) {
    return setEventsListError(
      new CustomError({
        message: convertResponseToError(e as IResponseError),
      }),
    );
  }
};

export const getHighlightedEvents = async (): Promise<void> => {
  startGettingHighlightedEvents();

  const response = await fetchHighLights();
  if (response instanceof CustomError) {
    return setHighlightedEventsError(response);
  }
  const events = parseHighlights(response);
  const homeAmountOfHighlights = response?.data_sports?.[1]?.n_events || 5;
  const eventsIDs = getQuickLinksIDs(events);

  setHighlightedEvents({
    events: take(eventsIDs, Number(homeAmountOfHighlights)),
  });

  return setCategoryWeight({ ...response.categoryweight });
};

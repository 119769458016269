import parseISO from 'date-fns/parseISO';
import isString from 'lodash/isString';

import services from '@features/core/services';

/**
 * convertDateToTimestamp
 * Function takes date object in format 'yyyy-MM HH:mm:ss' and convert to timestamp
 *
 * @param {Date | string | number} date
 * @returns {number} timestamp
 */
// Date objects do not throw an error if they are invalid
export default (date: Date | string | number): number => {
  const d = new Date(isString(date) ? parseISO(date) : date).getTime();
  if (Number.isNaN(d)) {
    services.logger.error('convertDateToTimestamp', 'Invalid Date' as string);
    return new Date().getTime();
  }
  return d;
};

import styled from 'styled-components';

export const Tabs = styled.ul`
  display: grid;
  grid-auto-flow: column;
  height: auto;
  grid-auto-columns: 1fr;
  box-sizing: border-box;
  padding: ${({ theme }) => theme.space_10};
  margin: 0;
  list-style: none;
  color: ${props => props.theme.textMain};
  text-transform: uppercase;
  background-color: ${props => props.theme.categoryTabBackground};
  font-size: ${props => props.theme.fontSM};
  cursor: pointer;

  &:empty {
    padding: 0;
  }
`;

export const activeTabStyles = theme => `
  background-color: ${theme.accent};
  border-color: ${theme.accent};
  font-weight: ${theme.fontBold};
  color: ${theme.elementNuanceColor};
`;

export const Tab = styled.li<{ isActive?: boolean }>`
  text-align: center;
  align-items: center;
  justify-content: center;
  padding: ${({ theme }) => theme.space_10} 0;
  border: 1px solid ${props => props.theme.textMain};

  &:first-child {
    border-top-left-radius: 2px;
    border-bottom-left-radius: 2px;
    border-right: 0;
  }

  &:last-child {
    border-top-right-radius: 2px;
    border-bottom-right-radius: 2px;
    border-right: 1px solid
      ${({ isActive, theme }) =>
        !isActive ? theme.textMain : theme.tertiaryBackground};
  }

  ${({ isActive, theme }) => isActive && activeTabStyles(theme)};
`;

export const TabTextWrapper = styled.span`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  user-select: none;
`;

export const TabText = styled.span`
  display: inline-block;
  font-size: ${({ theme }) => theme.fontXS};
`;

export const IconHolder = styled.span`
  position: relative;
  bottom: -2px;
  margin-right: ${({ theme }) => theme.space_10};
`;

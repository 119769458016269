import { RuleObject } from 'rc-field-form/lib/interface';

const requiredRule = async (rule: RuleObject, value: string): Promise<void> => {
  if (!value) {
    return Promise.reject(rule.message);
  }
  return Promise.resolve();
};

export default requiredRule;

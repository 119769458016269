import React from 'react';

export enum SidebarSide {
  center,
  left,
  right,
  bottom,
  top,
  stretch,
}

interface ISidebarFunction extends React.FunctionComponent {
  meta: ISidebarMeta;
}

interface ISidebarClass extends React.ComponentClass {
  meta: ISidebarMeta;
}

type ISidebar = ISidebarClass | ISidebarFunction;

interface ISidebarMeta {
  side: SidebarSide;
  key: string;
  onClose?(sidebar?: ISidebar): void;
  onOpen?(sidebar?: ISidebar): void;
  preventDarkening?: boolean;
  fullScreen?: boolean;
  props?: { type: string };
}

export interface SidebarStoriesProps {
  layer: string;
}

export default ISidebar;

import styled from 'styled-components';

export const CategoryList = styled.div`
  width: 100%;
`;

export const CategoryItems = styled.div`
  padding-left: ${props =>
    props.theme.isDesktop ? props.theme.space_20 : props.theme.space_30};
`;

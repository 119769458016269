import styled from 'styled-components';

import { TextNormal } from '@features/core/styles/text.styled';

import { hexToRgba } from '@common/helpers/deviceUtil';

export const Container = styled.div<{ isStatic?: boolean }>`
  box-sizing: border-box;
  position: absolute;
  left: 0;
  padding: ${({ theme }) => theme.space_15} ${({ theme }) => theme.space_10};
  width: 100%;
  box-shadow: ${({ theme }) => hexToRgba(theme.textTertiary, 0.45)} 0 5px 15px;
  color: ${props => props.theme.inputDefaultText};
  background-color: ${props => props.theme.highlightWrapperBackground};
  z-index: 999;
  border-radius: 2px;
  font-size: ${props => props.theme.fontMD};

  .text > div {
    margin: 0;
    color: ${props => props.theme.textActive};
  }
`;

export const IconWrapper = styled.div`
  margin-right: ${({ theme }) => theme.space_10};
`;

export const TitleWrapper = styled.div`
  display: grid;
  grid-auto-flow: column;
  justify-content: start;
  align-items: start;
`;

export const Title = styled(TextNormal)`
  margin: 0 0 ${({ theme }) => theme.space_10};
  font-weight: ${({ theme }) => theme.fontBold};
`;

export const ContentContainer = styled.div`
  display: grid;
  grid-auto-flow: column;
  justify-content: space-between;

  .close-icon {
    position: relative;
    top: ${({ theme }) => theme.space_5};
  }
`;

export const ContentMessage = styled.div`
  & a {
    text-decoration: underline;
  }
`;

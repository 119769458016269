import React, { useState, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import reduce from 'lodash/reduce';

import {
  MarketsLabel,
  SpecialValue,
} from '@features/events/components/markets';
import {
  Label,
  PredictionWrapper,
} from '@features/events/components/markets/mapMarket/MapMarket.styled';
import {
  Prediction,
  StyledButton,
} from '@features/events/components/prediction';

import { getType, getMarketLayout } from '@common/helpers/markets/marketModel';
import {
  sortPredictionByType,
  isEnabled as isPredictionEnabled,
} from '@common/helpers/eventsHelper/predictionModel';
import { useMarketPredictions } from '@common/hooks/useMarketPredictions';

import * as S from './CollapsableMarket.styled';
import { ICollapsableMarket } from './CollapsableMarket.types';

const CollapsableMarket: React.FC<ICollapsableMarket> = props => {
  const {
    market,
    hasLabel,
    hasSpecialValue,
    listType,
    pageType,
    eventId,
    predictionsMinNumber,
  } = props;

  const { t } = useTranslation();
  const predictions = useMarketPredictions(market, listType);
  const [extended, setExtended] = useState(false);

  const getPredictions = useCallback(() => {
    let i = 0;
    return reduce(
      sortPredictionByType(predictions, getType(market)),
      (children: React.ReactElement[], prediction) => {
        if (
          (extended || i <= predictionsMinNumber - 1) &&
          isPredictionEnabled(prediction)
        ) {
          i++;
          children.push(
            <Prediction
              key={prediction.id}
              listType={listType}
              pageType={pageType}
              eventId={eventId}
              marketId={market.id}
              predictionId={prediction.id}
              withLabel={false}
            />,
          );
        }
        return children;
      },
      [],
    );
  }, [market, predictions, extended]);

  const marketLayout = getMarketLayout(market, predictions);

  return (
    <S.Predictions>
      {(hasLabel || hasSpecialValue) && (
        <Label pageType={pageType}>
          <div>
            <MarketsLabel market={market} />
            <SpecialValue
              market={market}
              hasSpecialValue={hasSpecialValue}
              pageType={pageType}
            />
          </div>
        </Label>
      )}
      <PredictionWrapper className={marketLayout}>
        {getPredictions()}
        {market.predictions.length > predictionsMinNumber && (
          <StyledButton
            className="show_more"
            onClick={(): void => setExtended(e => !e)}
          >
            {extended
              ? `- ${t('common.labels.show_less')}`
              : `+ ${t('common.labels.show_more')}`}
          </StyledButton>
        )}
      </PredictionWrapper>
    </S.Predictions>
  );
};

export default CollapsableMarket;

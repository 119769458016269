import {
  setInboxLoading,
  setMessage,
  setMessages,
  setMessagesCount,
} from '@common/providers/inbox/useInbox';

export const getMessages = (): void => {
  setInboxLoading();
  const response = [
    // there will be request
    {
      tittle: 'Message 1',
      receivingTime: '24.11.22 - 14:00',
      messageText: 'Hi! It is test message number 1',
      messageId: 1,
      isRead: false,
    },
    {
      tittle: 'Message 2',
      receivingTime: '25.11.22 - 15:00',
      messageText: 'Hi! It is test message number 2',
      messageId: 2,
      isRead: false,
    },
    {
      tittle: 'Message 3',
      receivingTime: '26.11.22 - 16:00',
      messageText: 'Hi! It is test message number 3',
      messageId: 3,
      isRead: true,
    },
    {
      tittle: 'This is Message 4 dwads',
      receivingTime: '30.11.22 - 17:00',
      messageText:
        'Hi! It is test message number 4 Hi! It is test message number 4 Hi! It is test message number 4',
      messageId: 4,
      isRead: false,
    },
  ];

  setMessages(response);
};

export const getMessage = (): void => {
  setInboxLoading();
  const response = {
    // there will be request
    tittle: 'Message 1',
    receivingTime: '24.11.22 - 14:00',
    messageText: 'Hi! It is test message number 1',
    messageId: 1,
    isRead: false,
  };
  setMessage(response);
};

export const getMessageCount = (): void => {
  setInboxLoading();
  const count = 4; // there will be request
  setMessagesCount(count);
};

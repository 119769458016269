import styled from 'styled-components';

import { footerHeight } from '@features/app/layout/footer';

export const LayoutRoot = styled.div`
  position: relative;
`;
export const LayoutTop = styled.div`
  position: sticky;
  top: 0;
  z-index: ${({ theme }) => theme.zIndexHeader};
`;
export const LayoutCenter = styled.div`
  position: relative;
  padding-bottom: ${footerHeight}px;
`;

export const LayoutBottom = styled.div`
  position: fixed;
  width: 100%;
  bottom: 0;
  z-index: ${({ theme }) => theme.zIndexFooter};
`;

export const LayoutNotifications = styled.div`
  position: sticky;
`;

export const DownloadAppNotification = styled.div`
  height: 60px;
  width: 100%;
  z-index: 101;
`;

export const AppHeader = styled.div`
  position: relative;
`;

export const AppFooter = styled.div`
  position: relative;
`;

export const MainContent = styled.div`
  position: relative;

  #language-switcher-footer_mob {
    width: 100%;
  }
`;

export const EventDetailWrapperMob = styled.div<{
  topLayoutHeight: number;
}>`
  position: fixed;
  top: ${({ topLayoutHeight }) => topLayoutHeight}px;
  bottom: 68px;
  width: 100%;
  overflow-y: scroll;
  z-index: 99;
  background-color: ${({ theme }) => theme.wrapperBackground};
`;

import isEmpty from 'lodash/isEmpty';

import services from '@features/core/services';
import client from '@features/core/graphqlClient';
import CustomError, {
  convertResponseToError,
} from '@features/core/error/error';

import { DynamicCalls, getApiUrl } from '@common/api/api';
import { IEventData, ICustomError, IResponseError } from '@common/interfaces';

const { API_ENDED_EVENT_LIST } = DynamicCalls;

const getResultEventQuery = (eventId: string): string => `{
        resultEvent (id: "${eventId}"){
          id
          json {
            cancelled
            data {
              winner
              score_str
            }
          }
        }
    }`;

export const fetchEventResult = ({
  eventId,
}: {
  eventId: string;
}): Promise<{
  data?: { resultEvent: { json: { data: { score_str: string } } } };
}> => {
  return client.request({
    query: getResultEventQuery(eventId),
  });
};

export default async (
  eventId: string,
  lang = services.domainLang || 'de',
): Promise<IEventData> => {
  try {
    const url = getApiUrl(API_ENDED_EVENT_LIST, {
      dynamicLanguage: lang,
      dynamicEventId: eventId,
    });

    const { data } = await fetchEventResult({ eventId });
    const score = data?.resultEvent?.json?.data;

    const events = {};
    const result = await services.http(url);

    if (!isEmpty(result.data[0])) {
      events[eventId] = {
        ...result.data[0],
        id: eventId,
        endedEvent: true,
        score,
      };
    }

    return { events } as IEventData;
  } catch (error) {
    const customAxiosError = error as IResponseError;
    return new CustomError({
      message: convertResponseToError(customAxiosError.response?.data),
      data: customAxiosError.response?.data,
    }) as IEventData & ICustomError;
  }
};

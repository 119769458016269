import CustomError from '@features/core/error/error';
import services from '@features/core/services';

import { NOTIFICATION_ENDPOINT } from '@common/constants/config';
import { apiHandler, MethodType } from '@common/api/apiHandler';
import { IPushNotificationsReasons } from '@common/interfaces';
import { getApiUrl } from '@common/api/api';

interface IFetchSetPushSubscriptionPayload {
  username: string;
  status: boolean;
  eid?: string;
  cid?: string;
  settings?: IPushNotificationsReasons | null;
}

export interface IFetchSetPushSubscriptionResponse {
  data: boolean | 1;
}
const fetchSetPushSubscription = ({
  username,
  status,
  eid,
  cid,
  settings,
}: IFetchSetPushSubscriptionPayload): Promise<IFetchSetPushSubscriptionResponse &
  CustomError> => {
  const target = eid ? 'event' : 'category';
  const targetId = eid || cid || '';
  const action = status ? 'subscribe' : 'unsubscribe';

  const API_NOTIFICATIONS = `${services.config.get(
    NOTIFICATION_ENDPOINT,
  )}action/username/target/targetId`;

  const apiUrl = getApiUrl(API_NOTIFICATIONS, {
    action,
    username,
    target,
    targetId,
  });

  if (eid) {
    return apiHandler(
      { method: MethodType.Post, data: { ...settings } },
      { apiUrl },
    );
  }

  return apiHandler(
    { method: MethodType.Post, data: { ...settings } },
    { apiUrl },
  );
};

export default fetchSetPushSubscription;

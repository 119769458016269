import styled from 'styled-components';

import { Icon } from '@ui/components/icon';

export const Root = styled.div`
  background-color: ${({ theme }) => theme.secondary};
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: stretch;

  .categories-tabs {
    display: flex;
    flex-direction: column;
    position: relative;
  }
`;

export const FiltersWrap = styled.div<{
  topLayoutHeight: number;
}>`
  top: ${({ topLayoutHeight }) => topLayoutHeight}px;
  position: sticky;
  z-index: 5;
  width: 100%;
`;

export const mainFilterWrapBorder = color =>
  `border-bottom: 2px solid ${color}`;

export const MainFilterWrap = styled.div<{ timeFilter: boolean }>`
  display: grid;
  grid-auto-flow: column;
  align-items: center;
  justify-content: start;
  background-color: ${({ theme }) => theme.bodyBackground};

  ${({ timeFilter, theme }) =>
    timeFilter && !theme.isDesktop && mainFilterWrapBorder(theme.accent)};
`;

export const TimeSwitcherWrapper = styled.div`
  background-color: ${({ theme }) => theme.bodyBackground};
`;

export const TimeSwitcher = styled.button<{ timeFilter: boolean }>`
  display: grid;
  grid-auto-flow: row;
  justify-items: center;
  position: relative;
  align-items: center;
  align-self: stretch;
  justify-content: center;
  min-width: 47px;
  text-align: center;
  padding: ${({ theme }) => theme.space_15} 0 ${({ theme }) => theme.space_20};
  color: ${({ theme, timeFilter }) =>
    timeFilter ? theme.textActive : theme.accent};
  background: ${({ theme, timeFilter }) =>
    timeFilter ? theme.accent : theme.bodyBackground};
  transition: 0.3s;

  span {
    display: inline-block;
    margin-top: ${({ theme }) => theme.space_10};
  }

  svg.mi-chevron-down,
  svg.mi-chevron-up {
    position: absolute;
    bottom: ${({ theme }) => theme.space_5};
  }

  &::before {
    display: block;
    content: '';
    position: absolute;
    right: 0;
    top: ${({ theme }) => theme.space_10};
    bottom: ${({ theme }) => theme.space_10};
    height: calc(100% - 20px);
    width: ${({ theme }) => theme.space_1};
    background: ${({ theme }) => theme.textMain};
  }
`;

export const StyledIcon = styled(Icon)`
  margin-top: ${({ theme }) => theme.space_5};
`;

export const LongTermWrapper = styled.div`
  margin-top: ${({ theme }) => theme.space_10};
`;

export const CategoriesWrapper = styled.div`
  padding-bottom: ${({ theme }) => theme.space_60};
`;

export const LoadingWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
`;

import styled from 'styled-components';

import CustomLink from '@features/core/routing/CustomLink';

const StyledCustomLink = styled(CustomLink)`
  cursor: pointer;
  color: ${props => props.theme.textLink};
  text-decoration: underline;
`;

export default StyledCustomLink;

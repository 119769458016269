import { IOddsComparisonData } from '@common/interfaces';

export type IOddsComparisonState = {
  loading: boolean;
  error: string | null;
  oddsComparisonList: IOddsComparisonData[][];
  choosedSectionIndex: number | null;
  totalStake: number;
};

export const defaultState: IOddsComparisonState = {
  loading: false,
  error: null,
  oddsComparisonList: [],
  choosedSectionIndex: null,
  totalStake: 20,
};

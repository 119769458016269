import split from 'lodash/split';
import toUpper from 'lodash/toUpper';
import replace from 'lodash/replace';
import filter from 'lodash/filter';
import trim from 'lodash/trim';
import includes from 'lodash/includes';
import reduce from 'lodash/reduce';
import toLower from 'lodash/toLower';
import slice from 'lodash/slice';
import forEach from 'lodash/forEach';
import values from 'lodash/values';
import reverse from 'lodash/reverse';
import map from 'lodash/map';

import { PageName } from '@features/core/routing';
import services from '@features/core/services';
import i18n from '@features/core/translation';

import {
  HAS_LAST_MATCHES,
  HAS_SPORTRADAR_IFRAME_STATISTICS,
  HAS_SPORTRADAR_WIDGET_LMT,
  HAS_SPORTRADAR_WIDGET_TABLE,
  HAS_SPORTRADAR_WIDGET_LIGA_TABLE,
} from '@common/constants/config';
import {
  ICategory,
  IEvent,
  IMarket,
  IPeriods,
  IScore,
  IScoreKeyOrdered,
} from '@common/interfaces';
import {
  getLiveStatus,
  isEndedWithLmt,
  isWinner,
} from '@common/helpers/eventsHelper/eventStatusHelper';

import { getPath, getTopID } from '../categories/categoriesModel';
import { isEnabled } from '../markets/marketModel';

import {
  isAmericanFootball,
  isAussierules,
  isBadminton,
  isBaseBall,
  isBasketball,
  isDarts,
  isGolf,
  isHandball,
  isHockey,
  isRealFootball,
  isSnooker,
  isTableTennis,
  isTennis,
  isVolleyball,
} from './eventTypeHelper';
import { getPeriods, getScore } from './scoreboards/scoreBoardModel';

/**
 * getLabel
 *
 * @param {IEvent} event
 * @returns {string | undefined} label
 */
export const getLabel = (event: IEvent): string | undefined => {
  return event.label;
};

/**
 * getCompetitorLabel
 *
 * @param {string} label
 * @param {number} team
 * @returns {string} label
 */
export const getCompetitorLabel = (label: string, team: number): string => {
  const teams = label && split(label, /\s-\s/);
  return teams && teams.length ? teams[team] : '';
};

/**
 * getHomeLabel
 *
 * @param {string} label
 * @returns {string} homeLabel
 */
export const getHomeLabel = (label: string): string => {
  return getCompetitorLabel(label, 0);
};

/**
 * getAwayLabel
 *
 * @param {string} label
 * @returns {string} awayLabel
 */
export const getAwayLabel = (label: string): string => {
  return getCompetitorLabel(label, 1);
};

/**
 * getScoreType
 *
 * @param {IEvent} event
 * @returns {string} scoreType
 */
export const getScoreType = (event: IEvent): string => {
  return (event && event?.score_type) || '';
};

const shortenName = (name: string): string => {
  const splittedNames = split(name, /\s+/);
  const nameWithDot = includes(name, '.');

  return nameWithDot
    ? name
    : reduce(
        splittedNames,
        (acc, word) => {
          const formattedName =
            word !== splittedNames[splittedNames.length - 1]
              ? `${toUpper(word.substring(0, 1))}.`
              : word;
          return `${acc}${formattedName}`;
        },
        '',
      );
};

/**
 * getFormattedLabel
 * Get formatted shortening team-labels for some categories (Tennis): cut Firs- Second- Thirdnames
 *
 * @param {IEvent} event
 * @param {string} top_category
 * @returns {string} formattedLabel
 */
export const getFormattedLabel = (
  event: IEvent,
  top_category: string,
): string => {
  if (
    includes(event.label, '/') ||
    includes(event.label, ',') ||
    (!isTennis(event) &&
      !isDarts(event) &&
      !isBadminton(event, top_category) &&
      !isSnooker(event, top_category) &&
      !isGolf(event, top_category))
  ) {
    return event.label;
  }

  const divideLabels = split(event.label, /\s-\s/);
  return reduce(
    divideLabels,
    (acc, name) =>
      acc ? `${acc} - ${shortenName(name)}` : `${shortenName(name)}`,
    '',
  );
};

/**
 * getActiveMarkets
 * returns markets with playable statuses
 *
 * @param {IEvent} event
 * @param {IMarket[]} markets
 * @param {boolean} withSuspended
 * @returns {IMarket[]} activeMarkets
 */
export const getActiveMarkets = (
  event: IEvent,
  markets?: IMarket[],
  withSuspended = false,
): IMarket[] => {
  return filter(markets, market =>
    isEnabled(market, getLiveStatus(event), withSuspended),
  );
};

/**
 * shouldBeWrappedInCategoryHolder
 * checks if events should be wrapper in category holder
 *
 * @param {string} previousValue
 * @param {string} currentValue
 * @returns {boolean} shouldBeWrappedInCategoryHolder
 */
export const shouldBeWrappedInCategoryHolder = (
  previousValue: string,
  currentValue: string,
): boolean => {
  return !!currentValue && currentValue !== previousValue;
};

/**
 * getSuspendReasonLabel
 * convert suspend reson object to translated label
 *
 * @param {IEvent} event
 * @returns {string | boolean} suspendReasonLabel
 */
export const getSuspendReasonLabel = (event: IEvent): string | boolean => {
  const reasonId = event?.suspend_reason?.suspend_reason_id || 0;
  const content = i18n.t(`events.suspend_reasons.suspend_reason_${reasonId}`);
  return (
    content &&
    replace(
      replace(content, '##HOME', getHomeLabel(event.label || '')),
      '##AWAY',
      getAwayLabel(event.label || ''),
    )
  );
};

export const getSuspendReasonIconPath = (
  event: IEvent,
): { path: string; width: number } | null => {
  const reason = event.suspend_reason?.suspend_reason;

  const iconPathMap = {
    POSSIBLE_PENALTY: {
      path: '/images/suspend_reason/penalty-icon.svg',
      width: 20,
    },
    POSSIBLE_GOAL: {
      path: '/images/suspend_reason/soccer-icon.svg',
      width: 15,
    },
    POSSIBLE_RED_CARD: {
      path: '/images/suspend_reason/red-card-icon.svg',
      width: 12,
    },
    POSSIBLE_VIDEO_ASSISTANT_REFEREE: {
      path: '/images/suspend_reason/var-icon.svg',
      width: 20,
    },
  };

  let result: { path: string; width: number } | null = null;

  forEach(iconPathMap, (value, key) => {
    if (includes(reason, key)) {
      result = value;
    }
  });

  return result;
};

export const getEventShortName = (
  name: string,
  shouldShorten: boolean,
): string =>
  shouldShorten ? `${trim(name.substr(0, 8) || '', ' -')}..` : trim(name);

const isStatisticsEvent = (event, category): boolean => {
  if (
    !event?.betradar_id ||
    /langzeitwetten|outrights|beachsoccer/.test(toLower(getPath(category))) ||
    isWinner(event) ||
    isTableTennis(event, getTopID(category)) ||
    isBadminton(event, getTopID(category)) ||
    isAussierules(getTopID(category))
  ) {
    return false;
  }

  return (
    isRealFootball(event, getTopID(category)) ||
    isBasketball(event) ||
    isTennis(event) ||
    isHandball(event) ||
    isHockey(event) ||
    !!isEndedWithLmt(event) ||
    isVolleyball(event) ||
    isDarts(event) ||
    isAmericanFootball(event) ||
    isBaseBall(event, getTopID(category))
  );
};

/**
 * hasSportradarWidgetLmt
 * hasSportradarWidgetLmt checks if event has sportradar widget LMT
 *
 * @param {IEvent} event
 * @param {ICategory} category
 * @returns {boolean} hasSportradarWidgetLmt
 */
export const hasSportradarWidgetLmt = (
  event: IEvent,
  category: ICategory,
): boolean => {
  return (
    (services.config.get(HAS_SPORTRADAR_WIDGET_LMT) as boolean) &&
    isStatisticsEvent(event, category)
  );
};

/**
 * hasSportradarWidgetTable
 * hasSportradarWidgetTable checks if event has Sportradar widget Tabelle
 *
 * @param {IEvent} event
 * @param {ICategory} category
 * @param {PageName} page
 * @returns {boolean} hasSportradarWidgetTable
 */
export const hasSportradarWidgetTable = (
  event: IEvent,
  category: ICategory,
  page: PageName,
): boolean => {
  if (page === PageName.EVENTS_CATEGORY) {
    return (
      (services.config.get(HAS_SPORTRADAR_WIDGET_LIGA_TABLE) as boolean) &&
      isStatisticsEvent(event, category)
    );
  }
  return (
    (services.config.get(HAS_SPORTRADAR_WIDGET_TABLE) as boolean) &&
    isStatisticsEvent(event, category)
  );
};

/**
 * hasSportradarWidgetLastMatches
 * hasSportradarLastMatches checks if event has sportradar Last Matches h2h statistics
 *
 * @param {IEvent} event
 * @returns {boolean} hasSportradarWidgetLastMatches
 */
export const hasSportradarWidgetLastMatches = (event: IEvent): boolean => {
  return (
    (services.config.get(HAS_LAST_MATCHES) as boolean) && !!event?.betradar_id
  );
};

/**
 * hasSportradarIframeStatistics
 * hasSportradarIframeStatistics checks if event has Sportrader iframe statistics
 *
 * @param {IEvent} event
 * @param {ICategory} category
 * @returns {boolean} hasSportradarIframeStatistics
 */
export const hasSportradarIframeStatistics = (
  event: IEvent,
  category: ICategory,
): boolean => {
  return (
    (services.config.get(HAS_SPORTRADAR_IFRAME_STATISTICS) as boolean) &&
    !!event?.betradar_id &&
    isStatisticsEvent(event, category)
  );
};

/**
 * parseEndedScore
 *
 * @param {IScore} score
 * @param {boolean} includeLast
 * @returns {number[]} score
 */
export const parseEndedScore = (
  score?: IScore,
  includeLast = false, // ignore last record on true
): number[] => {
  const scoreResult = [0, 0];
  if (!score) {
    return scoreResult;
  }
  if (score && getScore(score).length) {
    return getScore(score) as number[];
  }

  const periods = getPeriods(score) as IPeriods;
  if (periods === null) {
    return scoreResult;
  }

  let periodsSorted: number[][] = [];
  forEach(values(IScoreKeyOrdered), key => {
    if (!periods[key]) {
      return;
    }

    periodsSorted.push(map(periods[key], Number));
  });

  if (!includeLast) {
    periodsSorted = slice(reverse(periodsSorted), 0, periodsSorted.length - 1);
  }

  forEach(periodsSorted, value => {
    if (value.length < 2) {
      return;
    }

    if (value[0] > value[1]) {
      scoreResult[0]++;
    } else if (value[1] > value[0]) {
      scoreResult[1]++;
    }
  });

  return scoreResult;
};

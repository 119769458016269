import React, { useRef } from 'react';
import compact from 'lodash/compact';
import { useTranslation } from 'react-i18next';
import { animated as a } from '@react-spring/web';
import { useLocation } from 'react-router-dom';

import { NavList, UserSection } from '@features/app/layout/menuTab';
import { PageName } from '@features/core/routing/linkAliases';
import services, { useServices } from '@features/core/services';

import { CommonEvents } from '@packages/events/appEvents';

import { LUGAS_INACTIVE, MENU_PANEL } from '@common/constants/dialogs';
import { LUGAS_TIMER, OMIT_FACE_ID_CHECK } from '@common/constants/cookie';
import {
  HAS_NEWS,
  HAS_PROMOTIONS,
  HAS_RESULTS,
  HAS_STATIC_PAGES,
} from '@common/constants/config';
import { ButtonSize, ButtonType, SidebarSide } from '@common/interfaces';
import { isInStandaloneMode } from '@common/helpers/deviceUtil';
import { isWebView } from '@common/helpers/links';
import {
  ANIMATED_MENU,
  ANIMATED_MENU_PANEL,
  hasHeader,
  useAnimatedX,
} from '@common/helpers/appLayoutHelper';
import logoutUser from '@common/providers/user/actions/logoutUser';
import { setLocation } from '@common/providers/router/helper';
import {
  closeSidebar,
  openSidebar,
  toggleAnimatedComponent,
} from '@common/providers/application/useAppState';
import { useUserState } from '@common/providers/user/useUserState';
import useOnClickOutside from '@common/hooks/useOnClickOutside';

import { Icon } from '@ui/components/icon';
import { StyledButton } from '@ui/components/buttons';

import * as S from './MenuTab.styled';

const MenuTab = (): React.ReactElement => {
  const isLoggedIn = useUserState(s => s.loadingState);
  const menuRef = useRef<HTMLDivElement>(null);
  const { t } = useTranslation();
  const { domainLang, cookie } = useServices();
  const { search } = useLocation();

  const hasLugasTimer = cookie.get(LUGAS_TIMER);
  const logout = async (): Promise<void> => {
    if (hasLugasTimer) {
      openSidebar({ type: LUGAS_INACTIVE });
    } else {
      await logoutUser();
      setLocation(PageName.HOME);
    }
  };

  const navigation = compact([
    {
      label: t('home_page.navigation_keys.sportwetten'),
      location: PageName.SPORT,
      key: 'sports',
      iconName: 'soccer',
    },
    {
      label: t('home_page.navigation_keys.live'),
      location: PageName.LIVE,
      key: 'livebetting',
      iconName: 'thunderbolt',
    },
    services.config.get(HAS_RESULTS) && {
      label: t('app-layout.menu.results'),
      key: 'sonderwetten',
      iconName: 'ordered-list',
    },
    services.config.get(HAS_PROMOTIONS) && services.config.get(HAS_STATIC_PAGES)
      ? {
          label: t('app-layout.menu.promotions'),
          location: PageName.PROMOTIONS,
          key: 'promotions',
          iconName: 'sound',
        }
      : null,
    domainLang === 'de' && services.config.get(HAS_NEWS)
      ? {
          label: t('app-layout.menu.news'),
          location: PageName.NEWS,
          key: 'news',
          iconName: 'sound',
        }
      : null,
    {
      label: t('app-layout.menu.support'),
      location: PageName.SUPPORT,
      key: 'support',
      iconName: 'sound',
    },
    (isInStandaloneMode() || isWebView(search)) && {
      label: t('app-layout.menu.reload'),
      location: PageName.HOME,
      key: 'reload',
      iconName: 'reload',
      onClick: (): void => {
        services.events.emitEvent(CommonEvents.SET_NATIVE_COOKIE, {
          [OMIT_FACE_ID_CHECK]: true,
        });
        window.location.reload();
      },
    },
  ]);

  const onCloseMenuHandler = (): void => {
    toggleAnimatedComponent(ANIMATED_MENU_PANEL);
    setTimeout(() => {
      closeSidebar(MENU_PANEL);
      toggleAnimatedComponent(ANIMATED_MENU);
    }, 300);
  };

  useOnClickOutside(menuRef, onCloseMenuHandler);

  return (
    <a.div
      style={{ ...useAnimatedX(ANIMATED_MENU_PANEL), width: '100%' }}
      ref={menuRef}
    >
      <S.Root id="menu" data-qa="menu">
        <S.CloseIcon onClick={onCloseMenuHandler} data-qa="menu-btn-close">
          <Icon width="14" height="13" name="close" />
        </S.CloseIcon>
        <S.ContentWrapper>
          {isLoggedIn && <UserSection />}
          <NavList items={navigation} />
          <S.StyledLanguagesList />
          {isLoggedIn && (
            <StyledButton
              size={ButtonSize.Big}
              buttonType={ButtonType.LightGhost}
              onClick={logout}
              dataQa="menu-btn-logout"
              label="app-layout.user-menu.logout"
              className="menu-btn-logout"
            />
          )}
        </S.ContentWrapper>
      </S.Root>
    </a.div>
  );
};

MenuTab.meta = {
  side: SidebarSide.stretch,
  key: MENU_PANEL,
  preventDarkening: true,
  fullScreen: !hasHeader(),
};

export default MenuTab;

import styled from 'styled-components';

export const Root = styled.div``;

export const Item = styled.div<{
  isOdd: boolean;
}>`
  padding: ${({ theme }) => theme.space_10};
  margin-bottom: ${({ theme }) => theme.space_5};
  background: ${({ isOdd, theme }) =>
    isOdd ? theme.tertiaryBackground : theme.textActive};

  .item-top {
    display: flex;
    justify-content: space-between;
    margin-bottom: ${({ theme }) => theme.space_10};

    .top-left {
      width: 65%;

      .top-left-1,
      .top-left-2 {
        height: 10px;
      }

      .top-left-1 {
        width: 100%;
        margin-bottom: ${({ theme }) => theme.space_5};
      }

      .top-left-2 {
        width: 50%;
      }
    }

    .top-right {
      flex-grow: 1;

      .top-right-1 {
        height: 10px;
        width: 35px;
        margin-left: auto;
      }
    }
  }

  .item-bottom {
    display: flex;
    justify-content: space-between;

    .bottom-left {
      display: flex;
      width: 65%;

      .bottom-left-1 {
        width: 20%;
        height: 25px;
        margin-right: ${({ theme }) => theme.space_10};
      }

      .bottom-left-2 {
        position: relative;
        width: 80%;
        height: 25px;
      }
    }

    .bottom-right {
      flex-grow: 1;
      max-width: 100px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-left: ${({ theme }) => theme.space_20};

      .bottom-right-1 {
        width: 100%;
        height: 10px;
      }

      .bottom-right-2 {
        flex-shrink: 0;
        width: 35px;
        height: 25px;
        margin-left: ${({ theme }) => theme.space_15};
      }
    }
  }

  .triangle {
    position: absolute;
    top: 0;
    right: -15px;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 12.5px 0 12.5px 15px;
    border-color: transparent transparent transparent
      ${({ isOdd, theme }) =>
        isOdd ? theme.textActive : theme.tertiaryBackground};
  }

  .element {
    background: ${({ isOdd, theme }) =>
      isOdd ? theme.textActive : theme.tertiaryBackground};
  }
`;

import { animated, useSpring } from '@react-spring/web';
import React, { useEffect, useState } from 'react';

const AnimatedScoreValue: React.FC<{
  score: string | number;
  isPeriod?: boolean;
}> = React.memo(
  ({ score, isPeriod }) => {
    const [displayScore, setDisplayScore] = useState(score);
    const [animation, setAnimation] = useState({ from: '0%', to: '0%' });
    const props = useSpring({
      from: { transform: `translateY(${animation.from})` },
      to: { transform: `translateY(${animation.to})` },
      reset: true,
      onRest: () => {
        if (animation.to === '-100%') {
          // Reset to show new score coming in from below
          setAnimation({ from: '100%', to: '0%' });
          setDisplayScore(score); // Update displayScore to the new score after animation
        }
      },
    });

    useEffect(() => {
      if (score !== displayScore && displayScore !== undefined) {
        // Only trigger animation if score changes and is not the initial render
        setAnimation({ from: '0%', to: '-100%' });
      }
    }, [score, displayScore]);

    return (
      <div style={{ position: 'relative', overflow: 'hidden' }}>
        <animated.div style={props}>
          {isPeriod ? '(' : ''}
          {displayScore}
          {isPeriod ? ')' : ''}
        </animated.div>
      </div>
    );
  },
  (prevProps, nextProps) => {
    return prevProps.score === nextProps.score;
  },
);

AnimatedScoreValue.displayName = 'AnimatedScoreValue';

export default React.memo(AnimatedScoreValue);

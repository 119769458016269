import { IPushNotificationsReasons } from '@common/interfaces';

export interface ISetPushSubscriptionPayload {
  eid?: string;
  eids?: string[];
  cid?: string;
  settings: IPushNotificationsReasons | null;
}

export enum ISourceToUpdate {
  events = 'events',
  categories = 'categories',
  all = 'all',
}

export interface ISetPushSubsriptions {
  type: keyof typeof ISourceToUpdate;
  list:
    | Record<string, IPushNotificationsReasons>
    | Record<string, IPushNotificationsReasons>[];
}

export interface ISavePushNotifications {
  username: string;
  status: boolean;
  eid?: string;
  cid?: string;
  settings: IPushNotificationsReasons | null;
}

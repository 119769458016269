import services from '@features/core/services';

import { IUser, ICustomError } from '@common/interfaces';
import { DynamicCalls } from '@common/api/api';
import { apiHandler, MethodType } from '@common/api/apiHandler';

const { API_COMPLEX_USER_AUTH } = DynamicCalls;

const fetchComplexUserAuth = async (
  version?: boolean,
): Promise<IUser & ICustomError> => {
  const query = `?version=${Date.now()}`;
  return apiHandler<IUser>(
    { method: MethodType.Get },
    {
      apiUrl: `${API_COMPLEX_USER_AUTH}${version ? query : ''}`,
      apiData: {
        dynamicLanguage: services.domainLang,
      },
    },
  );
};

export default fetchComplexUserAuth;

import styled from 'styled-components';

import { TextXLarge } from '@features/core/styles/text.styled';

const activeContainerStyles = theme => `
  box-shadow: 0 0 5px 3px ${theme.textActive};
  border: 2px solid ${theme.accent};
`;

const disabledContainerStyles = theme => `
  background: ${theme.buttonMainTextColor};
  pointer-events: none;

  input {
    pointer-events: none;
    background:  ${theme.buttonMainTextColor};
  }
`;

const errorContainerStyles = theme => `
  border: 1px solid ${theme.statusError};
  input {
    border: ${theme.border} !important;
  }
`;

export const InputContainer = styled.div<{
  disabled: boolean;
  active: boolean;
  isError?: boolean;
}>`
  display: grid;
  grid-auto-flow: column;
  position: relative;
  width: 100%;
  height: 40px;
  border: 2px solid transparent;
  border-radius: 3px;
  box-sizing: border-box;
  padding: 0 ${({ theme }) => theme.space_10};
  background: ${props => props.theme.highlightWrapperBackground};
  transition: all 0.25s;
  box-shadow: none;
  justify-content: end;
  cursor: pointer;

  ${({ active, theme }) => active && activeContainerStyles(theme)};
  ${({ disabled, active, theme }) =>
    disabled && !active && disabledContainerStyles(theme)};

  ${({ isError, theme }) => isError && errorContainerStyles(theme)};
`;

export const ActiveArrow = styled.div`
  position: absolute;
  bottom: -50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 0 10px 10px;
  border-color: transparent transparent
    ${({ theme }) => theme.primaryBackground} transparent;
`;

export const InputPlaceholder = styled.div`
  width: 100%;
  display: grid;
  grid-auto-flow: column;
  align-items: center;
`;

export const InputPlaceholderCurrency = styled(TextXLarge)`
  color: ${({ theme }) => theme.inputDefaultText};
  margin-left: ${({ theme }) => theme.space_5};
  font-weight: ${({ theme }) => theme.fontBold};
`;

export const Input = styled.input`
  width: 100%;
  border: ${({ theme }) => theme.border};
  text-align: right;
  cursor: pointer;
  font-size: ${props => props.theme.fontXL};
  font-weight: ${({ theme }) => theme.fontBold};
  color: ${({ theme }) => theme.inputDefaultText};
  padding: 0;
  ${props => !props.theme.isDesktop && 'user-select:none;'} ::selection {
    background: ${props => !props.theme.isDesktop && 'transparent'};
  }

  &:disabled {
    opacity: 1;
  }

  &:focus {
    outline: none;
  }
`;

import isEmpty from 'lodash/isEmpty';

import { IEvent, IEventState } from '@common/interfaces';
import { isEndedEvent } from '@common/helpers/eventsHelper/eventStatusHelper';

export enum EventDetailStage {
  'LOADING' = 'LOADING',
  'NOT_FOUND' = 'NOT_FOUND',
  'ENDED' = 'ENDED',
  'LIVE' = 'LIVE',
}
export const getDetailEventStage = (
  event: IEvent,
  eventDetailState: IEventState,
): keyof typeof EventDetailStage => {
  const { loading, selectedEvent, loadedEvent } = eventDetailState;
  if (loading || selectedEvent?.eventId !== loadedEvent) {
    return EventDetailStage.LOADING;
  }
  if (!loading && selectedEvent?.eventId === loadedEvent && isEmpty(event)) {
    return EventDetailStage.NOT_FOUND;
  }
  if (isEndedEvent(event)) {
    return EventDetailStage.ENDED;
  }
  return EventDetailStage.LIVE;
};

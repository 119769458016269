import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import reduce from 'lodash/reduce';
import includes from 'lodash/includes';
import { useTheme } from 'styled-components';

import CustomLink from '@features/core/routing/CustomLink';
import { PageName } from '@features/core/routing';
import { footerItems } from '@features/app/layout/footer/FooterWrapper';

import { MENU_PANEL } from '@common/constants/dialogs';
import { isEventDetailPages } from '@common/helpers/links';
import {
  ANIMATED_BET_DETAIL,
  ANIMATED_MENU,
  ANIMATED_MENU_PANEL,
} from '@common/helpers/appLayoutHelper';
import { isOpenDialog } from '@common/helpers/appState/dialogs';
import { useUserState } from '@common/providers/user/useUserState';
import { toggleAnimatedComponent } from '@common/providers/application/useAppState';
import { closeEventDetail } from '@common/helpers/eventsHelper/eventRouteHelper';
import { isMatchWebRoutes } from '@common/providers/router/helper';

import { Icon } from '@ui/components/icon';

import * as S from './Footer.styled';
import { IFooterProps, INavItem } from './Footer.types';

export const footerHeight = 68;

export const Footer: React.FC<IFooterProps> = ({ items }) => {
  const { t } = useTranslation();
  const [prevItem, setPrevItem] = useState('');
  const animated = [footerItems.bettingSlip, footerItems.cashout];
  const allowedToClick = [
    footerItems.sport,
    footerItems.menuPanel,
    footerItems.live,
  ];
  const isBetDetail = isMatchWebRoutes(PageName.USER_BET_ITEM);
  const { textTertiary } = useTheme();

  const switchAccLoading = useUserState(state => state.switchAccountLoading);

  const handleClick = (
    onClick: (item: INavItem) => void,
    item: INavItem,
  ): void => {
    const onAnimatedBtn = (): void => {
      onClick(item);
      if (includes(animated, item.key)) {
        toggleAnimatedComponent(ANIMATED_MENU);
      }
    };

    if (item.key !== MENU_PANEL && item.active && isEventDetailPages()) {
      onClick(item);
      closeEventDetail(true);
      return;
    }
    if (
      switchAccLoading ||
      (prevItem === item.key &&
        item.active &&
        !includes(allowedToClick, item.key))
    ) {
      return;
    }

    if (isBetDetail) {
      toggleAnimatedComponent(ANIMATED_BET_DETAIL);
      setTimeout(() => onClick(item), 300);
      return;
    }

    if (isOpenDialog(MENU_PANEL) && !item.active) {
      toggleAnimatedComponent(ANIMATED_MENU_PANEL);
      setTimeout(() => {
        onClick(item);
        toggleAnimatedComponent(ANIMATED_MENU);
      }, 300);
      return;
    }

    if (includes(animated, prevItem)) {
      setPrevItem(item.key);
      toggleAnimatedComponent('');
      setTimeout(() => onAnimatedBtn());
      return;
    }
    if (includes(animated, item.key)) {
      toggleAnimatedComponent(ANIMATED_MENU);
    }

    setPrevItem(item.key);
    onClick(item);
  };

  const onClickHandler = (item: INavItem): void => {
    if (item.onClick) {
      handleClick(item.onClick, item);
    }
  };

  return (
    <S.Root data-qa="footer">
      {reduce(
        items,
        (acc, item) => {
          return acc.concat(
            <S.ItemContainer
              key={item.key}
              className={`footer-item${item.active ? ' active' : ''} ${
                item.key
              }`}
              data-qa={`footer-btn-${item.key}`}
              isDisabled={!!item.disabled}
              onClick={(): void => onClickHandler(item)}
            >
              <CustomLink dataQa={item.dataQa}>
                <S.IconContainer className="footer-icon">
                  {!!item.badge && (
                    <S.Badge
                      className="badge"
                      data-qa={`${item.dataQa}-counter`}
                    >
                      {item.badge}
                    </S.Badge>
                  )}

                  <Icon
                    width={item.icon.width || '18'}
                    height={item.icon.height || '18'}
                    name={item.icon.name}
                    fill={textTertiary}
                    viewBox={item.icon.viewBox}
                    isMultiplyAttrs={item.icon.isMultiplyAttrs}
                  />
                </S.IconContainer>

                <S.Label>{t(`footer.${item.key}`)}</S.Label>
              </CustomLink>
            </S.ItemContainer>,
          );
        },
        [] as JSX.Element[],
      )}
    </S.Root>
  );
};

export default Footer;

import styled from 'styled-components';

// eslint-disable-next-line import/prefer-default-export
export const TagItem = styled.div`
  background-color: ${({ theme }) => theme.tagBackground};
  display: block;
  width: fit-content;
  border-radius: 9999px;
  color: ${({ theme }) => theme.inputDefaultText};
  white-space: nowrap;
  padding: ${({ theme }) => theme.space_5} ${({ theme }) => theme.space_10};
  font-size: ${({ theme }) => theme.fontSM};
  margin: ${({ theme }) => theme.space_5};
`;

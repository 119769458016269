import { CSSProperties } from 'react';

import {
  IBetSelection,
  IBetDetailCashout,
  IBetDetail,
} from '@common/interfaces';

export interface IBetItem {
  itemStyle?: CSSProperties | null;
  id?: string | null;
  index?: number | null;
  status_code?: number;
  created_ts?: number;
  total_paid_amount?: string;
  initial_total_stake?: string;
  initial_total_cost?: string;
  initial_user_risk?: string;
  remaining_risk?: string;
  currency_code?: string;
  type: string;
  selections?: IBetSelection[];
  isBetDetail?: boolean;
  bet: IBetDetail;
  hasReuseBet?: boolean;
  cashouts?: Array<IBetDetailCashout>;
  isBetDetailView?: boolean;
}

export enum BetItemStatusCode {
  OPEN,
  LOST,
  WON,
  CANCELLED,
}

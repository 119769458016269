import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import map from 'lodash/map';
import filter from 'lodash/filter';
import includes from 'lodash/includes';
import { useTranslation } from 'react-i18next';
import { useTheme } from 'styled-components';

import { EventMarketsFilter } from '@features/events/components/eventMarketsFilter';
import {
  FootballCards,
  TeamLabel,
} from '@features/events/components/scoreboards/scores';
import {
  FactoryScoreboards,
  FactoryTimers,
  ScoreboardTypes,
} from '@features/events/components/scoreboards';
import { EventDetailTabs } from '@features/detail/components/eventDetailTabs';
import MapMarket from '@features/events/components/markets/mapMarket/MapMarket';
import services from '@features/core/services';
import { useEventsModel } from '@features/events/useEventsModel';

import { MarketingEvents } from '@packages/events/appEvents';

import {
  HAS_BET_BUILDER,
  HAS_EVENT_DETAIL_POPUP,
  HAS_EVENT_MARKETS,
  SHOW_NUMERIC_EVENT_CODE_IN_EVENT_DETAIL,
} from '@common/constants/config';
import {
  DEFAULT_EVENT_MARKET_TYPE,
  DEFAULT_MARKET_TYPE,
} from '@common/providers/events/liveCategories/state';
import { isPeriodNotStarted } from '@common/helpers/eventsHelper/scoreboards/timerModel';
import { goBack } from '@common/providers/router/helper';
import { isEventDetailPages } from '@common/helpers/links';
import {
  getActiveMarkets,
  getAwayLabel,
  getHomeLabel,
} from '@common/helpers/eventsHelper/eventLabelHelper';
import {
  detailedEventMarketGroups,
  getType,
  hasSpecialValueInLabel,
  isUnivMarketNotFitForPeriod,
  mapMarketKeyToMarketType,
  sortExtraMarkets,
  getMarkets,
} from '@common/helpers/markets/marketModel';
import { getSportName } from '@common/helpers/categories/categoriesModel';
import { PageType, TTimeout } from '@common/interfaces';
import { copyToClipBoard, isDesktopView } from '@common/helpers/deviceUtil';
import { BetView } from '@common/providers/bettingslip/types';
import hasEventMarkets from '@common/helpers/eventsHelper/hasEventMarkets';
import {
  isEndedEvent,
  isLiveCurrent,
  isWinner,
} from '@common/helpers/eventsHelper/eventStatusHelper';
import {
  isFootball,
  isHandball,
} from '@common/helpers/eventsHelper/eventTypeHelper';
import isBetPackerMarketActive from '@common/helpers/bettingSlipHelper/betPackerHelper';
import { excludeMarkets } from '@common/helpers/markets/marketTypes';
import { useEventsListState } from '@common/providers/events/eventList/useEventsList';
import {
  getBetPackerOdds,
  resetBetPackerData,
  useBettingSlip,
} from '@common/providers/bettingslip/useBettingSlip';
import { getMarketHeight } from '@common/helpers/appLayoutHelper';
import { getEventGameId } from '@common/helpers/eventsHelper/eventDataHelper';
import { closeEventDetail } from '@common/helpers/eventsHelper/eventRouteHelper';
import deleteTimeout from '@common/helpers/timeoutHelper';
import { useUserState } from '@common/providers/user/useUserState';
import { widgetsStateHandler } from '@common/helpers/eventsHelper/eventDetailWidgetTabHelper';

import { DualRingLoader } from '@ui/components/loaders';
import {
  INotificationStyle,
  INotificationIcon,
} from '@ui/components/genericNotification/GenericNotification.types';
import { GenericNotification } from '@ui/components/genericNotification';
import { Icon } from '@ui/components/icon';

import { BetPacker } from '../betPacker';

import * as S from './DetailEvent.styled';
import { IDetailEvent } from './DetailEvent.types';

const isDesktop = isDesktopView();

const DetailEvent: React.FC<IDetailEvent> = props => {
  const { t } = useTranslation();
  const { iconFillLight } = useTheme();
  const { listType, eventId } = props;
  const filterMarketKey = useEventsListState(
    state => state[listType].filterMarketKey,
  );
  const predictions = useEventsListState(s => s[listType].data.predictions);
  const user = useUserState(state => state.data);
  const isLoggedIn = useUserState(s => s.loadingState);
  const { event, category } = useEventsModel(eventId, listType);
  const bsMode = useBettingSlip(state => state.bsMode);
  const betPackerSelections = useBettingSlip(s => s.betPackerSelections);
  const betPackerPredictions = useBettingSlip(s => s.betPackerPredictions);
  const loading = useBettingSlip(state => state.loading);
  const eventMarkets = useEventsListState(
    s => s[listType].data.events[eventId]?.markets,
  );
  const hasBetPacker = services.config.get(HAS_BET_BUILDER);
  const markets = useEventsListState(s =>
    getMarkets(s[listType].data.markets, eventMarkets),
  );
  const [isLastMatchesWidget, setIsLastMatchesWidget] = useState(true);
  const [isLMTWidget, setIsLMTWidget] = useState(true);
  const sportType = getSportName(category.top_category_id);
  const filterGroup = detailedEventMarketGroups[sportType]?.[filterMarketKey];
  const hasActiveBetPacker =
    hasBetPacker && isLoggedIn && !isLiveCurrent(event);
  const [isCopyNotification, setIsCopyNotification] = useState(false);
  const timeoutRef = useRef<TTimeout>(null);

  const onClickGameIdHandler = async (): Promise<void> => {
    setIsCopyNotification(true);
    try {
      await copyToClipBoard(getEventGameId(event));
      clearTimeout(timeoutRef.current as number);
      timeoutRef.current = setTimeout(() => {
        setIsCopyNotification(false);
      }, 5000);
    } catch (e) {
      services.logger.error(e as string);
    }
  };

  useEffect(() => {
    if (hasBetPacker && bsMode === BetView.BETPACKER) {
      const pids = map(betPackerSelections, prd => parseFloat(prd.id));
      getBetPackerOdds({
        event_id: event.id,
        prediction_ids: pids,
      });
    }
  }, [bsMode, betPackerSelections]);

  useEffect(
    () => () => {
      if (isDesktop) {
        resetBetPackerData();
      }
      deleteTimeout(timeoutRef.current);
    },
    [],
  );

  useEffect(() => {
    services.events.emitEvent(MarketingEvents.DETAIL_EVENT_VIEW, {
      listType,
      event,
      category,
    });
  }, [event, category]);

  let count = 0;

  window.LMTStateHandler = (state: string, data): void =>
    widgetsStateHandler(state, data, setIsLMTWidget, setIsLastMatchesWidget);

  const onCloseHandler = (): void => {
    closeEventDetail();
    if (isEventDetailPages() && !services.config.get(HAS_EVENT_DETAIL_POPUP)) {
      goBack();
    }
  };

  const marketsToRender = useMemo(() => {
    const eventType = isLiveCurrent(event) ? 'live' : 'sport';
    const allMarketsToRender = sortExtraMarkets(
      getActiveMarkets(event, markets),
      event,
      category.top_category_id,
      predictions,
    );

    return excludeMarkets(allMarketsToRender, user, eventType);
  }, [event, category, markets, predictions]);

  const filteredMarkets = useMemo(() => {
    return filterMarketKey === DEFAULT_EVENT_MARKET_TYPE
      ? marketsToRender
      : filter(marketsToRender, ({ type, period_type, label }) => {
          if (isUnivMarketNotFitForPeriod(filterMarketKey, period_type, type)) {
            return false;
          }
          if (filterMarketKey === 'FILTER_SCORERS') {
            return (
              includes(filterGroup, type) ||
              /(Torschütze|goalscorer)/.test(label)
            );
          }
          return includes(filterGroup, type);
        });
  }, [marketsToRender, filterMarketKey]);

  const hasGenericScore = isFootball(event) || isHandball(event);

  const defaultDisabled =
    mapMarketKeyToMarketType[sportType]?.[DEFAULT_MARKET_TYPE] ||
    DEFAULT_MARKET_TYPE;

  const genericScoreHeader = useCallback(
    (): React.ReactElement => (
      <>
        <S.LeftSection>
          <S.Title data-qa="team-home-label">
            {getHomeLabel(event.label || '')}
          </S.Title>
          <FootballCards
            side="left"
            yellow={event.cards?.yellow?.[0]}
            red={event.cards?.red?.[0]}
            isDetailEvent
          />
        </S.LeftSection>

        <S.CenterSection className="score">
          <FactoryTimers
            id={event.id}
            topCategoryId={category.top_category_id}
            listType={listType}
            shortTimer={false}
          />
          <FactoryScoreboards
            id={event.id}
            event={event}
            topCategoryId={category.top_category_id}
            type={ScoreboardTypes.large}
            listType={listType}
          />
        </S.CenterSection>

        <S.RightSection className="timer">
          <S.RightSectionCell>
            <S.Title data-qa="team-away-label">
              {getAwayLabel(event.label || '')}
            </S.Title>
            <FootballCards
              side="right"
              yellow={event.cards?.yellow?.[1]}
              red={event.cards?.red?.[1]}
              isDetailEvent
            />
          </S.RightSectionCell>
        </S.RightSection>
      </>
    ),
    [event, category, listType],
  );

  const renderEventHeader = useCallback((): React.ReactElement => {
    if (isPeriodNotStarted(event.timer)) {
      return (
        <S.EventHeader>
          <TeamLabel event={event} category={category} listType={listType} />
          <FactoryTimers
            id={event.id}
            topCategoryId={category.top_category_id}
            listType={listType}
            shortTimer={false}
          />
        </S.EventHeader>
      );
    }
    return (
      <>
        {!isWinner(event) && (
          <S.EventHeader hasGenericScore={hasGenericScore}>
            {hasGenericScore ? (
              genericScoreHeader()
            ) : (
              <>
                <TeamLabel
                  event={event}
                  category={category}
                  listType={listType}
                />
                <S.ScoreboardWrapper>
                  <FactoryTimers
                    id={event.id}
                    topCategoryId={category.top_category_id}
                    listType={listType}
                    shortTimer={false}
                  />
                  <FactoryScoreboards
                    id={event.id}
                    event={event}
                    topCategoryId={category.top_category_id}
                    type={ScoreboardTypes.large}
                    listType={listType}
                  />
                </S.ScoreboardWrapper>
              </>
            )}
          </S.EventHeader>
        )}

        {isWinner(event) && (
          <S.EventHeader>
            <S.LeftSection>
              <S.Title>{getHomeLabel(event.label || '')}</S.Title>
            </S.LeftSection>

            <S.RightSection>
              <S.RightSectionCell>
                <FactoryTimers
                  id={event.id}
                  topCategoryId={category.top_category_id}
                  listType={listType}
                  shortTimer={false}
                />
              </S.RightSectionCell>
            </S.RightSection>
          </S.EventHeader>
        )}
      </>
    );
  }, [event, category, listType]);

  return (
    <S.DetailEventContent
      id="DetailEventContent"
      data-qa="detail-event-content"
    >
      {isDesktop && (
        <S.IconWrapper>
          <Icon
            name="close"
            fill={iconFillLight}
            onClick={(): void => onCloseHandler()}
          />
        </S.IconWrapper>
      )}
      <S.HeaderWrapper>
        {renderEventHeader()}
        {!!getEventGameId(event) &&
          services.config.get(SHOW_NUMERIC_EVENT_CODE_IN_EVENT_DETAIL) && (
            <S.GameId
              isLiveFootball={isLiveCurrent(event) && isFootball(event)}
              onClick={onClickGameIdHandler}
            >
              <span>{`${t('events.game_id')}${getEventGameId(event)}`}</span>
              <Icon name="copy" fill={iconFillLight} height="12" width="12" />
            </S.GameId>
          )}
        {isCopyNotification && (
          <GenericNotification
            iconType={INotificationIcon.base}
            styleType={INotificationStyle.detailEventNotification}
          >
            {t('events.game_id_copy_notification')}
          </GenericNotification>
        )}
      </S.HeaderWrapper>
      <EventDetailTabs
        event={event}
        category={category}
        isLMTWidgetAvailable={isLMTWidget}
        isLastMatchesWidgetAvailable={isLastMatchesWidget}
      />
      {hasActiveBetPacker && <BetPacker />}

      {hasEventMarkets(sportType) &&
        services.config.get(HAS_EVENT_MARKETS) &&
        !isEndedEvent(event) && (
          <EventMarketsFilter
            sportType={sportType}
            listType={listType}
            marketsToRender={marketsToRender}
          />
        )}
      <S.MarketsWrapper>
        {bsMode === BetView.BETPACKER && hasActiveBetPacker && loading ? (
          <DualRingLoader isLight />
        ) : (
          <>
            {marketsToRender.length === 0 && !isEndedEvent(event) && (
              <S.Market hasWinner={!!isWinner(event)} isEven>
                <MapMarket
                  market={null}
                  sportName={sportType}
                  eventId={event.id}
                  type={defaultDisabled}
                  hasLabel
                  hasSpecialValue={false}
                  listType={listType}
                  pageType={PageType.DETAIL}
                  disabled
                />
              </S.Market>
            )}

            {filteredMarkets.length !== 0 &&
              map(filteredMarkets, market => {
                const type = getType(market);
                const isMarketShown =
                  bsMode === BetView.NORMAL ||
                  isBetPackerMarketActive(
                    betPackerSelections,
                    betPackerPredictions,
                    market,
                  );
                return (
                  <S.MarketAnimationWrapper
                    className={`market-wrapper-${market.id}`}
                    maxHeight={getMarketHeight(market.id)}
                    isBetPackerMarketActive={isMarketShown}
                    key={market.id}
                  >
                    {bsMode === BetView.NORMAL || isMarketShown ? (
                      <S.Market
                        hasWinner={!!isWinner(event)}
                        isEven={isMarketShown && ++count % 2 === 0}
                        data-qa="market"
                      >
                        <MapMarket
                          market={market}
                          sportName={sportType}
                          eventId={event.id}
                          type={type}
                          hasLabel
                          hasSpecialValue={!hasSpecialValueInLabel(type)}
                          listType={listType}
                          pageType={PageType.DETAIL}
                          disabled={false}
                        />
                      </S.Market>
                    ) : null}
                  </S.MarketAnimationWrapper>
                );
              })}
          </>
        )}
      </S.MarketsWrapper>
    </S.DetailEventContent>
  );
};

export default DetailEvent;

import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Form from 'rc-field-form';
import replace from 'lodash/replace';
import find from 'lodash/find';
import { useTheme } from 'styled-components';

import TextInput from '@features/core/form/components/textInput/TextInput';
import { RadioButton } from '@features/core/form/components/radioButton';
import FormItem from '@features/core/form/components/FormItem';

import {
  LUGAS_ACCEPT_GLOBAL10,
  LUGAS_ACCEPT_GLOBAL30,
} from '@common/constants/dialogs';
import makeLimitPerMonthPreset from '@common/helpers/formsHelpers/validation/limitPerMonthPreset';
import { ButtonType, SidebarSide } from '@common/interfaces';
import fetchPocketTags from '@common/api/account/fetchPocketTags';
import { MethodType } from '@common/api/apiHandler';
import { usePaymentsState } from '@common/providers/payments/usePayments';
import { DEPOSIT_LIMIT } from '@common/helpers/limitsHelper/limitTypes';
import { closeSidebar } from '@common/providers/application/useAppState';
import { useLimitsState } from '@common/providers/limits/useLimits';
import { getLimit } from '@common/providers/limits/helper';
import { useUserState } from '@common/providers/user/useUserState';
import { UserLoadingState } from '@common/providers/user/actions/actionTypes';

import { StyledButton } from '@ui/components/buttons';
import { Icon } from '@ui/components/icon';

import * as S from './LugasAcceptGlobalLayer.styled';

const LugasAcceptGlobalLayer = (): React.ReactElement => {
  const isLoggedIn =
    useUserState(s => s.loadingState) >= UserLoadingState.PROCESSING;
  const { iconFillDark } = useTheme();
  const { props } = LugasAcceptGlobalLayer.meta;
  const { type } = props;
  const [limitsOpen, setLimitsOpen] = useState<boolean>(false);
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const [radio, setRadio] = useState(0);
  const limitValuePreset = makeLimitPerMonthPreset({
    min: 1,
    max: type === '10' ? 10000 : 30000,
    globalErrLimitMsg: `global_${type}_`,
  });
  const data = useLimitsState(s => s[DEPOSIT_LIMIT]?.data);
  const account = usePaymentsState(s => s.account);
  const currentLimit = find(
    data,
    e =>
      e?.data?.status === 'granted' &&
      e?.data?.attribute === 'sys.deposit.self_limit.monthly',
  );
  const currentLimitvalue = currentLimit?.data?.value || '1000';

  const handleClose = async (): Promise<void> => {
    closeSidebar(type === '10' ? LUGAS_ACCEPT_GLOBAL10 : LUGAS_ACCEPT_GLOBAL30);
    await fetchPocketTags({
      method: MethodType.Post,
      params: {
        tag: {
          key: 'usr.deposit.global_limit.monthly',
          value: form.getFieldValue('limit_monthly'),
        },
      },
    });
  };

  useEffect(() => {
    form.setFieldValue('limit_monthly', '');
    if (radio === 2) {
      form.setFieldValue('limit_monthly', currentLimitvalue);
    }
    setLimitsOpen(radio === 3);
  }, [radio]);

  useEffect(() => {
    if (isLoggedIn) {
      getLimit({
        limitType: DEPOSIT_LIMIT,
        accountId: account.id,
        token: account.token,
      });
    }
  }, []);

  return (
    <S.Backdrop className="info-limit">
      <S.Content>
        <S.LimitsDialogLink data-qa="register-step4-btn-showLimits">
          <Icon
            name="info-circle-solid"
            fill={iconFillDark}
            width="28px"
            height="28px"
          />
          <S.LimitTitle>
            {t('user.lugas_accept_global.lugas_accept_global_title')}
          </S.LimitTitle>
        </S.LimitsDialogLink>

        <S.LimitSubTitle
          dangerouslySetInnerHTML={{
            __html: t('user.lugas_accept_global.lugas_accept_global_body'),
          }}
        />
        <br />
        <S.LimitSubTitle
          dangerouslySetInnerHTML={{
            __html: t('user.lugas_accept_global.lugas_accept_global_options'),
          }}
        />

        <S.RadioButtonsWrapper>
          <S.RadioWrapper
            className="radiobtn"
            onClick={(): void => setRadio(1)}
          >
            <RadioButton
              value="provider_limit"
              title={t('user.lugas_accept_global.lugas_accept_global_option_1')}
              checked={radio === 1}
              isDarkMode
              isBottomMargin
            />
          </S.RadioWrapper>

          <S.RadioWrapper
            className="radiobtn"
            onClick={(): void => setRadio(2)}
          >
            <RadioButton
              value="max_limit"
              title={replace(
                t('user.lugas_accept_global.lugas_accept_global_option_2'),
                'x.xxx',
                currentLimitvalue,
              )}
              checked={radio === 2}
              isDarkMode
              isBottomMargin
            />
          </S.RadioWrapper>

          <S.RadioWrapper
            className="radiobtn"
            onClick={(): void => setRadio(3)}
          >
            <RadioButton
              value="individual_limit"
              title={replace(
                t('user.lugas_accept_global.lugas_accept_global_option_3'),
                'XX',
                type,
              )}
              checked={radio === 3}
              isDarkMode
              isBottomMargin
            />
          </S.RadioWrapper>
        </S.RadioButtonsWrapper>

        {limitsOpen && (
          <S.LimitsContainer id="limit-container">
            <S.LimitRow>
              <Form form={form} className="form-container">
                <FormItem name="limit_monthly" rules={limitValuePreset}>
                  <TextInput
                    disabled={false}
                    data-qa="register-step4-input-limitPerMonth"
                    id="deposit_monthly_limit"
                    type="number"
                    step="10"
                  />
                </FormItem>
              </Form>
              <S.InputInfoWrapper>
                <Icon name="info-circle" width="17" height="17" />
                <span>{t('registration.step4.limits_open_info_section')}</span>
              </S.InputInfoWrapper>
            </S.LimitRow>
          </S.LimitsContainer>
        )}
        <StyledButton
          onClick={handleClose}
          disabled={!radio}
          label="user.lugas_accept_global.lugas_accept_global_submit"
          buttonType={ButtonType.Secondary}
        />
      </S.Content>
    </S.Backdrop>
  );
};

LugasAcceptGlobalLayer.meta = {
  side: SidebarSide.center,
  key: LUGAS_ACCEPT_GLOBAL10,
  props: { type: '' },
};

export { LugasAcceptGlobalLayer as Component };

export default LugasAcceptGlobalLayer;

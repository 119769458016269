import styled from 'styled-components';

import { TextLarge, TextNormal } from '@features/core/styles/text.styled';

export const Root = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.space_10};
  background-color: ${({ theme }) => theme.highlightWrapperBackground};
  color: ${({ theme }) => theme.tertiaryBackground};
  min-width: 300px;
  max-width: 500px;
  padding: ${({ theme }) => theme.space_20};
`;

export const HeaderWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: ${({ theme }) => theme.space_10};
`;

export const Header = styled(TextLarge)`
  color: ${({ theme }) => theme.tertiaryBackground};
  font-weight: ${({ theme }) => theme.fontBold};
`;

export const WarningMessage = styled(TextNormal)`
  margin-bottom: ${({ theme }) => theme.space_10};
`;

import React from 'react';
import isEmpty from 'lodash/isEmpty';
import reduce from 'lodash/reduce';

import { ScoreboardHoc } from '@features/events/components/scoreboards/scores';
import {
  ItemDiv,
  ScoreboardDiv,
  ScoreDiv,
  ICommonScoreProps,
} from '@features/events/components/scoreboards/factoryScoreboards';
import AnimatedScoreValue from '@features/events/components/scoreboards/scores/common/AnimatedScoreValue';

import {
  getHomeInning,
  getAwayInning,
  getLastInning,
  getScore,
} from '@common/helpers/eventsHelper/scoreboards/scoreBoardModel';

export const BaseballScore = (
  props: ICommonScoreProps,
): React.ReactElement | null => {
  const { score } = props;

  if (isEmpty(score.score_str)) {
    return null;
  }
  const inning = getLastInning(score);

  return (
    <ScoreboardDiv className="baseball-scoreboard">
      <ItemDiv>
        <ScoreDiv total>
          <AnimatedScoreValue score={getScore(score)[0]} />
        </ScoreDiv>
        <ScoreDiv total>
          <AnimatedScoreValue score={getScore(score)[1]} />
        </ScoreDiv>
      </ItemDiv>
      {reduce(
        Array.from({ length: 1 }),
        (acc: JSX.Element[]) =>
          acc.concat(
            <ItemDiv key={String(1)}>
              <ScoreDiv>
                <AnimatedScoreValue
                  score={getHomeInning(score, inning) as string}
                />
              </ScoreDiv>
              <ScoreDiv>
                <AnimatedScoreValue
                  score={getAwayInning(score, inning) as string}
                />
              </ScoreDiv>
            </ItemDiv>,
          ),
        [],
      )}
    </ScoreboardDiv>
  );
};

export default ScoreboardHoc(BaseballScore);

import { useEffect, useState } from 'react';

import { useAppState } from '@common/providers/application/useAppState';

const useNotification = (checkFooter?: boolean): number => {
  const { notifications, hasFooterNotification } = useAppState.getState();
  const notificationType = checkFooter
    ? 'footer-notification'
    : 'top-notification';
  const [notification, setNotification] = useState(
    document?.getElementById(notificationType)?.offsetHeight,
  );

  useEffect(() => {
    setNotification(document?.getElementById(notificationType)?.offsetHeight);
  }, [notifications, hasFooterNotification]);

  return notification || 0;
};

export default useNotification;

import styled from 'styled-components';

export const Root = styled.div`
  color: ${props => props.theme.textMain};
  background: ${props => props.theme.elementNuanceColor};
`;

export const LanguagesContainer = styled.div`
  padding: ${({ theme }) => theme.space_20} ${({ theme }) => theme.space_50};
  display: grid;
  grid-auto-flow: column;
  justify-content: start;
`;

export const LanguageItem = styled.label`
  display: grid;
  grid-auto-flow: column;
  align-items: center;
  margin-right: ${({ theme }) => theme.space_50};
  font-size: ${props => props.theme.fontLG};
  color: ${props => props.theme.textMain};
  text-transform: uppercase;
  cursor: pointer;

  &:last-child {
    margin-right: 0;
  }
`;

export const LanguageInput = styled.input<{ active: boolean }>`
  -webkit-appearance: none;
  margin: 0 ${({ theme }) => theme.space_10} 0 0;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  border: 1px solid
    ${({ active, theme }) => (active ? theme.accent : theme.textMain)};

  &::before {
    content: '';
    display: inline-block;
    position: relative;
    top: 2px;
    left: 2px;
    width: 14px;
    height: 14px;
    border-radius: 50%;
    background-color: ${({ active, theme }) => active && theme.accent};
  }
`;

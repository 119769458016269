import includes from 'lodash/includes';

import { DATE_TIME_TEMPLATE } from '@features/core/form/components/dateInput/DateTemplates';

import { StaticCalls } from '@common/api/api';
import { ICustomError, IFetchLimitResponse } from '@common/interfaces';
import { IGetLimitPayload } from '@common/providers/limits/types';
import fetchPocketTags from '@common/api/account/fetchPocketTags';
import hasLugasRestriction from '@common/helpers/userHelper/hasLugasRestrictions';
import { apiHandler, MethodType } from '@common/api/apiHandler';
import formatDate from '@common/helpers/timeHelper/localizedFormat';

const { API_PLAYER_LIMITS } = StaticCalls;

const fetchLimit = async ({
  accountId,
  token,
  limitType,
}: IGetLimitPayload): Promise<IFetchLimitResponse & ICustomError> => {
  if (includes(limitType, 'global') && hasLugasRestriction()) {
    const data = await fetchPocketTags({
      method: MethodType.Get,
      params: { tag: { key: 'usr.deposit.global_limit.monthly' } },
    });

    return {
      data: [
        {
          data: {
            attribute: data?.tag?.key || 'usr.deposit.global_limit.monthly',
            value: data?.tag?.value || '',
            status: data?.tag?.status || '',
            modified: data?.tag?.created || '',
            activation: formatDate(
              data?.tag?.activation || '',
              DATE_TIME_TEMPLATE,
            ),
          },
        },
      ],
    } as IFetchLimitResponse & ICustomError;
  }

  return apiHandler<IFetchLimitResponse>(
    { method: MethodType.Get, params: { token, accountId, limitType } },
    {
      apiUrl: API_PLAYER_LIMITS,
    },
  );
};

export default fetchLimit;

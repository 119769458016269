import DefaultTimerFactory from '@features/events/components/scoreboards/timers/defaultTimerFactory/DefaultTimerFactory';
import services from '@features/core/services';

import { SHOW_REMAINING_TIMERS } from '@common/constants/config';
import { getCurrentPeriod } from '@common/helpers/eventsHelper/scoreboards/timerModel';
import msToMM from '@common/helpers/timeHelper/msToMM';

const options = [
  ({ timer, shortTimer, category }): string =>
    getCurrentPeriod(timer, shortTimer, category),
  ({ remainingTime, showTime }): string =>
    showTime && services.config.get(SHOW_REMAINING_TIMERS)
      ? `${msToMM(remainingTime)}'`
      : '',
];

const GenericPeriods = DefaultTimerFactory(options);

export default GenericPeriods;

import styled from 'styled-components';

export const Form = styled.form`
  position: relative;

  .btns-wrapper {
    margin-top: ${({ theme }) => theme.space_20};
    grid-auto-columns: 1fr;
    grid-gap: ${({ theme }) => theme.isDesktop && theme.space_20};
  }
`;

export const FormGroup = styled.div`
  max-width: 600px;
`;

import React from 'react';
import { useTranslation } from 'react-i18next';
import { useTheme } from 'styled-components';

import { SidebarSide } from '@features/app/layout/sidebar/Sidebar.types';

import { AUTOMATIC_DEPOSIT_CONFIRM_LAYER } from '@common/constants/dialogs';
import { closeSidebar } from '@common/providers/application/useAppState';
import { useLimitsState } from '@common/providers/limits/useLimits';

import { StyledButton } from '@ui/components/buttons';

import * as S from './AutomaticDepositConfirmLayer.styled';

const AutomaticDepositConfirmLayer = (): React.ReactElement => {
  const { t } = useTranslation();
  const amount = useLimitsState(s => s.auto_withdraw?.data?.[0]);
  const { statusSuccess } = useTheme();
  const handleClose = async (): Promise<void> => {
    closeSidebar(AUTOMATIC_DEPOSIT_CONFIRM_LAYER);
  };

  return (
    <S.Wrapper>
      <S.CloseIcon onClick={handleClose} name="close" fill="currentColor" />

      <S.Title>
        <S.CheckIcon name="check-circle" fill={statusSuccess} />

        <S.TitleText>{t('limits.auto_withdraw.pop_up_title')}</S.TitleText>
      </S.Title>

      <S.Text
        dangerouslySetInnerHTML={{
          __html: t('limits.auto_withdraw.pop_up_text', {
            amount: amount || 'XX',
          }),
        }}
      />
      <StyledButton
        onClick={handleClose}
        label="limits.auto_withdraw.pop_up_close"
        className="auto-withdrowal-btn"
      />
    </S.Wrapper>
  );
};

AutomaticDepositConfirmLayer.meta = {
  side: SidebarSide.center,
  key: AUTOMATIC_DEPOSIT_CONFIRM_LAYER,
};

export { AutomaticDepositConfirmLayer as Component };

export default AutomaticDepositConfirmLayer;

import services from '@features/core/services';

import { ONLINE_ACCOUNT_NOTICE_REMOVE } from '@common/constants/cookie';
import {
  EMAIL_NOTICE,
  ONLINE_ACCOUNT_NOTICE,
  VERIFICATION_NOTICE,
  YEARLY_VERIFICATION_NOTICE,
} from '@common/constants/notifications';
import { IUser } from '@common/interfaces';
import {
  needVerification,
  needYealryVerification,
} from '@common/helpers/paymentsHelper/paymentModel';
import { addNotification } from '@common/providers/application/useAppState';
import mustCreateOnlineAccount from '@common/helpers/userHelper/mustCreateOnlineAccount';

import hasResendLink from '../userHelper/hasResendLink';

/**
 * setNotifications
 *
 * @param {IUser} user
 */
export const setNotifications = (user: IUser): void => {
  if (hasResendLink(user)) {
    addNotification({ type: EMAIL_NOTICE });
  } else if (needVerification(user)) {
    const type = needYealryVerification(user)
      ? YEARLY_VERIFICATION_NOTICE
      : VERIFICATION_NOTICE;
    addNotification({ type });
  }
  if (
    mustCreateOnlineAccount(user) &&
    !services.cookie.get(ONLINE_ACCOUNT_NOTICE_REMOVE)
  ) {
    addNotification({ type: ONLINE_ACCOUNT_NOTICE });
  }
};

export default setNotifications;

import reduce from 'lodash/reduce';
import keys from 'lodash/keys';
import replaceAll from 'lodash/replace';

export enum StaticCalls {
  API_LOGS = '/api/logs',
  API_LOGIN = '/api/loginRpc',
  API_LOGIN_APP = '/api/loginRpcApp',
  API_TOKENS = '/api/tokens',
  API_JSONRPC = '/api/jsonrpc',
  API_CASINO = '/api/rest/casino',
  API_TOP_NEWS = '/api/rest/topnews',
  API_PAYMENT_ACCOUNT = '/api/accounts',
  API_FAVORITES = '/api/user/favorites',
  API_NEWS_CATEGORIES = '/api/rest/news',
  API_NEWS_PRESS = '/api/rest/news_press',
  API_UPLOAD_DOCUMENT = '/api/upload-document',
  API_FAKE_EVENTS = '/api/events/static/get-fake-events',
  API_CASINO_SEARCH = '/api/de/ajax/casino/search',
  API_PLAYER_LIMITS = '/api/accounts/player/limits',
  API_POCKETS_TAGS = '/api/accounts/pockets/tags',
  API_COMBINED_EVENTS = '/api/rest/combined_events',
  API_LIMITS_HISTORY = '/api/accounts/player/limits/history',
  API_DISABLED_MARKETS = '/api/static/disabled_markets',
  API_ODDS_COMPARISON_LIST = '/api/cdn2/bestodds.json',
  API_IN_MEMORY_QR = '/api/accounts/payments/in-memory-qr-code',
  API_BONUS_CODE = '/api/accounts/payments/voucher',
  API_TEST_EVENTS_LIST = '/api/events/get-test-events-list',
}

export enum DynamicCalls {
  API_NEWS_DETAILS = '/api/rest/dynamicType',
  API_SUPPORT = '/api/dynamicLanguage/support',
  API_NEWS = '/api/rest/news/dynamicNewsCategories',
  API_LOGOUT = '/api/ajax/dynamicLanguage/logout.html',
  API_MY_FAVORITO_TOKEN = '/api/ajax/dynamicLanguage/my-favorito',
  API_BETS = '/api/dynamicLanguage/ajax/bets.json.html',
  API_COMPLEX_USER_AUTH = '/api/dynamicLanguage/user-auth',
  API_JSONRPC = '/api/dynamicLanguage/user/ajax/jsonrpc',
  API_SUBSCRIPTIONS = '/api/ajax/dynamicLanguage/subscriptions',
  API_QUICK_LINKS = '/api/rest/quick_links?lang=dynamicLanguage',
  API_USERNAME = '/api/dynamicLanguage/ajax/check_login.json.html',
  API_BET_DETAILS = '/api/dynamicLanguage/ajax/bet_detail.json.html',
  API_SCANNED_BET_DETAILS = '/api/dynamicLanguage/ajax/scan_bets',
  API_SEO_CONTEXT = '/api/static/dynamicLanguage/seo?slug=dynamicType',
  API_CONFIRM_TERMS = '/api/dynamicLanguage/ajax/accept_tac.json.html',
  API_STATIC_PAGE_CONTENT = '/api/static/dynamicLanguage/static_pages?slug=dynamicType',
  API_PAYMENT_SERVICES = '/api/accounts/dynamicAccountId/payments/services',
  API_REGISTRATION = '/api/ajax/dynamicLanguage/user_registration.json.html',
  API_RESEND_EMAIL = '/api/dynamicLanguage/ajax/re_send_verify_email.json.html',
  API_TRANSACTIONS = '/api/dynamicLanguage/ajax/account_transactions.json.html',
  API_FAKE_BETS_UNWRAPED = '/api/static/get-fake-bets?testlist={dynamicTestList}',
  API_CHECK_EMAIL = '/api/dynamicLanguage/ajax/check_email.json.html',
  API_CHANGE_PASSWORD = `/api/html/dynamicLanguage/myaccount_password_confirm.html`,
  API_USER_PROFILE = '/api/dynamicToken/html/dynamicLanguage/myaccount_userprofile.html',
  API_ENDED_EVENT_LIST = '/api/api2/events/dynamicLanguage/dynamicEventId',
  API_SUBMIT_PAYMENT = '/api/accounts/dynamicAccountId/payments/dynamicOperation?dynamicQuery',
  API_FAKE_BETS = '/api/static/get-fake-bets?testlist={dynamicTestList}&bet_id={dynamicBetId}',
  API_DUPLICATE_CUSTOMERS = '/api/dynamicLanguage/ajax/check_duplicated_customer.json.html',
  API_SAVE_EVENTS_LIST = '/api/events/dynamicLanguage/save-event-list',
  API_DELETE_EVENTS_LIST = '/api/events/delete-test-events-list/dynamicName',
  API_EDIT_EVENTS_LIST = '/api/events/edit-test-events-list/dynamicOldFileName',
}

export const getApiUrl = (api, data?: Record<string, string>): string => {
  return reduce(
    keys(data),
    (acc, key) => replaceAll(acc, key, data?.[key] || ''),
    api,
  );
};

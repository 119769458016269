import toNumber from 'lodash/toNumber';

import {
  getInfo,
  getPeriods,
} from '@common/helpers/eventsHelper/scoreboards/scoreBoardModel';
import { IScore } from '@common/interfaces/events/IScore';
import { IScoreKeyOrdered } from '@common/interfaces/events/ITimer';

export const getSets = (score: IScore): (number | string)[] => {
  const periods = getPeriods(score);
  return periods
    ? periods[IScoreKeyOrdered.IN_PROGRESS] || ['-', '-']
    : ['-', '-'];
};

/**
 * getLegs
 *
 * @param {IScore} score
 * @returns {string[]} legs
 */
export const getLegs = (score: IScore): string[] =>
  getInfo(score)?.legs || ['-', '-'];

/**
 * getLegScore
 *
 * @param {IScore} score
 * @returns {string[]} legScore
 */
export const getLegScore = (score: IScore): string[] => {
  if (
    getInfo(score)?.legscore &&
    (getInfo(score)?.legscore?.[0] || getInfo(score)?.legscore?.[1])
  ) {
    return getInfo(score)?.legscore || ['-', '-'];
  }
  return ['-', '-'];
};

/**
 * getBestOfSets
 *
 * @param {IScore} score
 * @returns {number} bestOfSets
 */
export const getBestOfSets = (score: IScore): number =>
  parseInt(getInfo(score)?.bestOf || '1', 10);

/**
 * hasSets
 *
 * @param {IScore} score
 * @returns {boolean} hasSets
 */
export const hasSets = (score: IScore): boolean => getBestOfSets(score) > 1;

/**
 * getBestOfLegs
 *
 * @param {IScore} score
 * @returns {number} bestOfLegs
 */
export const getBestOfLegs = (score: IScore): number =>
  parseInt(getInfo(score)?.bestOfLegs || '1', 10);

/**
 * getMarkedScore
 *
 * @param {IScore} score
 * @param {number} current
 * @param {number} next
 * @returns {boolean} isMarked
 */
export const getMarkedScore = (
  score: IScore,
  current: number,
  next: number,
): boolean =>
  toNumber(getLegScore(score)?.[current]) >
  toNumber(getLegScore(score)?.[next]);

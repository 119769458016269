import React from 'react';

import { Icon } from '@ui/components/icon';

import * as S from './FootballCards.styled';
import { IFootballCards } from './FootballCards.types';

const FootballCards: React.FC<IFootballCards> = props => {
  const { yellow = null, red = null, side, isDetailEvent } = props;
  const yellowCount = yellow ? parseInt(yellow, 10) : 0;
  const redCount = red ? parseInt(red, 10) : 0;

  return (
    <S.Root>
      {side === 'left' && !isDetailEvent && (
        <Icon name="team-jersey" height="40px" width="40px" />
      )}
      <S.Cards isDetailEvent>
        <S.CardContainer isDetailEvent hasCard={yellowCount > 0}>
          <S.Card type="yellow" />
          {yellowCount}
        </S.CardContainer>
        <S.CardContainer isDetailEvent hasCard={redCount > 0}>
          <S.Card type="red" />
          {redCount}
        </S.CardContainer>
      </S.Cards>
      {side === 'right' && !isDetailEvent && (
        <Icon name="team-jersey" height="40px" width="40px" />
      )}
    </S.Root>
  );
};

export default FootballCards;

import React from 'react';
import map from 'lodash/map';

import AnimatedScoreValue from '@features/events/components/scoreboards/scores/common/AnimatedScoreValue';
import { IDefaultScoreProps } from '@features/events/components/scoreboards/scores/common/defaultScoreFactory/defaultScoreFactory.types';

import * as S from './defaultScoreFactory.styled';

const mapPeriodToCells = (
  period: (string | number | null)[] | string,
  isFirst: boolean,
  isLast: boolean,
  fontWeightNormal?: boolean,
): JSX.Element[] =>
  map(period, (value, i) => (
    <S.Cell
      isFirst={isFirst}
      fontWeightNormal={fontWeightNormal}
      isLast={isLast}
      key={`cell-${i}-${isFirst}-${isLast}`}
    >
      <AnimatedScoreValue score={value || 0} />
    </S.Cell>
  ));

const DefaultScore: React.FC<IDefaultScoreProps> = ({
  score,
  lastPeriod,
  periodsCount = 5,
  className = '',
  getTotal,
  getPeriod,
  getOT,
  fontWeightNormal = false,
}) => {
  return (
    <S.Scoreboard className={className}>
      {getTotal && (
        <S.Period isFirst>
          {mapPeriodToCells(getTotal(score), true, false, fontWeightNormal)}
        </S.Period>
      )}
      {Array.from(
        { length: periodsCount },
        (_, i) =>
          lastPeriod >= i + 1 && (
            <S.Period isFirst={i === 0} key={`period-${i}`}>
              {mapPeriodToCells(
                getPeriod(score, i + 1),
                false,
                false,
                fontWeightNormal,
              )}
            </S.Period>
          ),
      )}
      {getOT && (
        <S.Period>
          {mapPeriodToCells(getOT(score), false, true, fontWeightNormal)}
        </S.Period>
      )}
    </S.Scoreboard>
  );
};

export default DefaultScore;

import find from 'lodash/find';
import isEmpty from 'lodash/isEmpty';

import { IEvent, ICategory, EventListTypes } from '@common/interfaces';
import { useEventsListState } from '@common/providers/events/eventList/useEventsList';
import { useBettingSlip } from '@common/providers/bettingslip/useBettingSlip';

const useEventsModel = (
  id: string,
  listType: keyof typeof EventListTypes,
): {
  event: IEvent;
  category: ICategory;
} => {
  const categories = useEventsListState(
    s => s[listType]?.data?.categories || {},
  );
  let event = useEventsListState(s => s[listType]?.data?.events[id] || {});

  let category = (event && categories[event.category_id]) || {};
  if (listType === EventListTypes.bettingslip && isEmpty(event)) {
    const { selections } = useBettingSlip.getState();
    const selection = find(selections, ['event.id', id]) || {
      event: {},
      category: {},
    };
    event = selection.event;
    category = selection.category;
  }

  return {
    event,
    category,
  };
};

export default useEventsModel;

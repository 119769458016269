import reduce from 'lodash/reduce';
import assign from 'lodash/assign';
import mapKeys from 'lodash/mapKeys';
import get from 'lodash/get';
import some from 'lodash/some';
import values from 'lodash/values';

import services from '@features/core/services';
import {
  IConsentStatusEventData,
  IConfig,
  IToggleValues,
} from '@features/app/layout/acceptCookieLayer/AcceptCookie.types';

import { ACCEPTED_COOKIE } from '@common/constants/cookie';

export const getNextYear = (): Date => {
  const now = new Date();
  return new Date(now.setFullYear(now.getFullYear() + 1));
};

export const saveCookieHandler = (value: IConsentStatusEventData): void => {
  services.cookie.set(ACCEPTED_COOKIE, JSON.stringify(value), {
    expires: getNextYear(),
  });
};

export const initialToggle: IToggleValues = {
  essential: true,
  functional: false,
  marketing: false,
};

export const getToggleData = (content, acceptCookie): IToggleValues => {
  try {
    const cookieData = JSON.parse(acceptCookie);
    const checkValue = (key: string): boolean => {
      return some(
        content.groups?.[key]?.info,
        value => get(cookieData, value) === true,
      );
    };

    return {
      ...initialToggle,
      functional: checkValue('functional'),
      marketing: checkValue('marketing'),
    };
  } catch (e) {
    return initialToggle;
  }
};

export const getParsedText = (text: string): string => {
  const parser = new DOMParser();

  return parser.parseFromString(text, 'text/html').body.textContent || '';
};

export const getAddTagEventDataCookie = (
  content,
  checkedOptions,
): IConsentStatusEventData => {
  const resultData = {
    event: 'consent_status',
    ...reduce(
      content.groups,
      (acc, group) =>
        reduce(
          group.info,
          (sum, tool) => assign(acc, { [tool]: !!checkedOptions[group.id] }),
          acc,
        ),
      {},
    ),
  };

  return {
    ...mapKeys(resultData, (value, key) => getParsedText(key)),
    ...checkedOptions,
  } as IConsentStatusEventData;
};

export const getCookieConfig = (groups): IConfig => {
  return {
    policies: values(groups),
    permissionLabels: {
      save: {
        title: 'cookie_banner.save_btn',
        id: 'save',
      },
      decline: {
        title: 'cookie_banner.decline_btn',
        id: 'decline',
      },
      acceptAll: {
        title: 'cookie_banner.accept_btn',
        id: 'acceptAll',
      },
    },
  };
};

import React, { ReactPortal, useEffect, useState } from 'react';
import forEach from 'lodash/forEach';
import styled, { useTheme } from 'styled-components';
import { useLocation } from 'react-router-dom';
import { createPortal } from 'react-dom';

import softLinksRedirect from '@features/core/routing/SoftLinksRedirect';
import services from '@features/core/services';
import { PageName } from '@features/core/routing/linkAliases';
import { LanguageSwitcher } from '@features/app/layout/languageSwitcher';

import {
  HAS_COOKIE_CONSENT_TYPE,
  HAS_PARTNERS_BOX,
} from '@common/constants/config';
import { ACCEPT_COOKIE_LAYER } from '@common/constants/dialogs';
import {
  openSidebar,
  useAppState,
} from '@common/providers/application/useAppState';
import { isDesktopView } from '@common/helpers/deviceUtil';
import { isMatchWebRoutes } from '@common/providers/router/helper';
import usePortal from '@common/hooks/usePortal';

const desktopFooterStyles = (props): string => `
  z-index:99;
  padding-top: 15px;
  background: ${
    props.isPaymentMethods ? 'none' : props.theme.categoryTabBackground
  };
  width: 100%;

  #footer-section {
    width: 100%;
    max-width: 1100px;
    margin: 0 auto;
    z-index: 99;
    ${props.isPaymentMethods && 'background: none'};
    #combined-section {
      ${props.isLogin || props.isForgotPassword ? 'display: none;' : ''}
    }
    #combined-section #language-switcher-footer_mob{
      display:none;
    }
  }

  #footer-section #official-partners,
  #footer-section #disclaimer {
    background: ${
      props.isPaymentMethods ? 'none' : props.theme.categoryTabBackground
    };
    color: ${props.isPaymentMethods ? '' : props.theme.textMain};
    font-size: ${props.theme.fontMD};
  }

  #official-partners {
    display: none;
  }

  #disclaimer .inner {
    text-align: left;
  }

  #combined-section .inner {
    display: none;
    padding: 0;
    background: ${props.isPaymentMethods ? 'none' : props.theme.bodyBackground};
  }
`;

const mobileFooterStyles = (props): string => `
  #footer-section #combined-section {
    background: ${props.theme.secondary};
    color: ${props.theme.textMain};
    
    a {
    color: ${props.theme.textMain};
    }
    
    a:hover {
    color: ${props.theme.textLink};
    }
  }
  
   ${props.hasPartnersSection &&
     '#official-partners .image-list img { display: inline-block;}'}
     
  #footer-section #disclaimer {
    margin-bottom: ${props.theme.space_60}
  }
  #footer-section #payment-logos svg {
    @media (max-width: 480px) {
      width: 20%;
  }
`;

const Portal = ({ id, children }): ReactPortal => {
  const target = usePortal(id);
  return createPortal(children, target);
};

const Footer = styled.div<{
  isLogin?: boolean;
  isForgotPassword?: boolean;
  isPaymentMethods?: boolean;
  hasPartnersSection?: boolean;
}>`
  ${(props): string =>
    props.theme.isDesktop
      ? desktopFooterStyles(props)
      : mobileFooterStyles(props)}
`;

const StaticFooter: React.FC = () => {
  const staticPageContent = useAppState(state => state.staticPageContent);
  const { pathname } = useLocation();
  const isLogin = isMatchWebRoutes(PageName.LOGIN);
  const isForgotPassword = isMatchWebRoutes(PageName.FORGOT_PASSWORD);
  const isPaymentMethods = isMatchWebRoutes(PageName.PAYMENT_METHODS);
  const { accent, iconFillLight } = useTheme();

  const isDesktop = isDesktopView();
  const hasPartnersSection =
    services.config.get(HAS_PARTNERS_BOX) && !isDesktop;

  const footerLink = document.querySelector(`.section a[href="${pathname}"]`);
  const allFooterLinks = document.querySelectorAll(`.section a`);

  const [readyForLangSwitcher, setReadyForLangSwitcher] = useState(false);
  useEffect(() => {
    if (services.config.get(HAS_COOKIE_CONSENT_TYPE) === 'internal') {
      window.UC_UI = {
        showSecondLayer: (): void => openSidebar({ type: ACCEPT_COOKIE_LAYER }),
      };
    }
    const timeout = setTimeout(() => {
      setReadyForLangSwitcher(true);
    }, 1000);
    return () => clearTimeout(timeout);
  }, []);

  useEffect(() => {
    forEach(allFooterLinks, link => {
      link?.setAttribute('style', `color: ${iconFillLight};`);
    });
    footerLink?.setAttribute('style', `color: ${accent};`);
  }, [pathname, footerLink]);

  useEffect(() => {
    return () => footerLink?.setAttribute('style', `color: ${iconFillLight};`);
  }, []);

  return (
    <>
      <Footer
        dangerouslySetInnerHTML={{
          __html: `${
            hasPartnersSection ? staticPageContent?.partners?.body || '' : ''
          }${staticPageContent?.footer?.body || ''}`,
        }}
        onClick={softLinksRedirect}
        role="presentation"
        isLogin={isLogin}
        isForgotPassword={isForgotPassword}
        isPaymentMethods={isPaymentMethods}
        hasPartnersSection={!!hasPartnersSection}
      />
      {readyForLangSwitcher && (
        <Portal id={`language-switcher-footer${!isDesktop ? '_mob' : ''}`}>
          <LanguageSwitcher />
        </Portal>
      )}
    </>
  );
};

export default StaticFooter;

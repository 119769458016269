import { useState, useEffect } from 'react';

// this version will cause component to rerender when mounted
// if you dont need rerender, create new version of this hook, dont modify this, in some cases rerender also needed
const useIsMounted = (): boolean => {
  const [isMounted, setIsMounted] = useState(false);

  useEffect(() => {
    setIsMounted(true);
  }, []);

  return isMounted;
};

export default useIsMounted;

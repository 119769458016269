import { ISearchState } from '@common/providers/search/types';

const defaultState: ISearchState = {
  events: {},
  eventCategories: {},
  categories: [],
  eventsLoading: false,
  categoriesLoading: false,
  casinoLoading: false,
  casinoGames: [],
  error: null,
  searchStatus: false,
};

export default defaultState;

import services from '@features/core/services';

import { StaticCalls } from '@common/api/api';
import { ITopData, ICustomError } from '@common/interfaces';
import { apiHandler, MethodType } from '@common/api/apiHandler';

const { API_COMBINED_EVENTS } = StaticCalls;

export const fetchHighLights = (): Promise<ITopData & ICustomError> => {
  const locale = services.domainLang;

  return apiHandler<ITopData>(
    {
      method: MethodType.Get,
      params: {
        lang: locale,
        slug: 'home',
        domain: 'tb.exxs.net',
        list: 'combined_events',
      },
    },
    {
      apiUrl: API_COMBINED_EVENTS,
    },
  );
};

export default fetchHighLights;

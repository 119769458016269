import styled from 'styled-components';

import { hexToRgba } from '@common/helpers/deviceUtil';

export const Root = styled.div`
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: ${({ theme }) => hexToRgba(theme.primaryBackground, 0.1)};
  padding: ${({ theme }) => theme.space_10} ${({ theme }) => theme.space_15};
  border-bottom-left-radius: ${({ theme }) => theme.space_5};
  border-bottom-right-radius: ${({ theme }) => theme.space_5};
`;

export const Item = styled.div`
  display: flex;
  align-items: center;
`;

export const IconBackground = styled.div<{ isDenyIcon: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${({ theme, isDenyIcon }) =>
    isDenyIcon && theme.statusError};
  border-radius: ${({ theme, isDenyIcon }) => isDenyIcon && theme.space_10};
  width: 16px;
  height: 16px;
  margin-right: ${({ theme }) => theme.space_5};
`;

export const Text = styled.div`
  font-size: ${({ theme }) => theme.fontSM};
  color: ${({ theme }) => theme.elementNuanceColor};
`;

import React, { useRef, useEffect, useState } from 'react';
import reduce from 'lodash/reduce';
import includes from 'lodash/includes';

import { PageName } from '@features/core/routing/linkAliases';

import { elementScrollIntoView } from '@common/helpers/scrollHelper';
import { setLocation } from '@common/providers/router/helper';
import { PageType, TTimeout } from '@common/interfaces';
import {
  DEFAULT_MARKET_TYPE,
  DEFAULT_SELECTED_CATEGORY_TYPE_TAB,
} from '@common/providers/events/liveCategories/state';
import {
  addCategoryAbbreviations,
  deUmlautNormal,
  getSportName,
} from '@common/helpers/categories/categoriesModel';
import deleteTimeout from '@common/helpers/timeoutHelper';
import ScrollTopElement from '@common/helpers/scrollTopElement';
import { resetEventsList } from '@common/providers/events/eventList/useEventsList';
import {
  selectMarketType,
  toggleLiveCategory,
  toggleSportCategory,
  useLiveCategories,
} from '@common/providers/events/liveCategories/useLiveCategories';

import * as S from './CategoriesSwitcherMobile.styled';
import ICategoriesSwitcherMobileProps from './CategoriesSwitcherMobile.types';

const CategoriesSwitcherMobile: React.FC<ICategoriesSwitcherMobileProps> = props => {
  const { items, selectedCategoryId, pageType } = props;
  const selectedCategoryTypeTab = useLiveCategories(
    s => s.selectedCategoryTypeTab,
  );
  const refActiveItem = useRef<HTMLDivElement>(null);
  const timeoutScrollRef = useRef<TTimeout>(null);
  const isCategoriesPage = PageType.CATEGORIES === pageType;
  const isLivePage = PageType.LIVE === pageType;

  const [activeItem, setActiveItem] = useState('');

  const onItemClick = (categoryId: string): void => {
    setActiveItem(categoryId);

    if (isCategoriesPage && selectedCategoryId[0] !== categoryId) {
      toggleSportCategory({ category: categoryId });
      ScrollTopElement('tabs-content');
    }

    if (isLivePage) {
      resetEventsList('live');
      toggleLiveCategory({ category: categoryId });
      selectMarketType({ market: DEFAULT_MARKET_TYPE });
      setLocation(PageName.LIVE);
    }
  };

  useEffect(() => {
    return () => {
      toggleLiveCategory({ category: '' });
      deleteTimeout(timeoutScrollRef.current);
    };
  }, []);

  useEffect(() => {
    const currentActiveItem = refActiveItem?.current;

    if (currentActiveItem) {
      timeoutScrollRef.current = setTimeout(
        elementScrollIntoView,
        400,
        currentActiveItem,
        {
          behavior: 'smooth',
          block: 'end',
          inline: 'nearest',
        },
      );
    }
  }, [activeItem, selectedCategoryTypeTab]);

  return (
    <S.Root data-qa="category-filter-wrapper">
      {reduce(
        items,
        (acc, item) => {
          const active = includes(selectedCategoryId, item.id);
          const sportName = addCategoryAbbreviations(item.detail);

          if (
            (selectedCategoryTypeTab !== DEFAULT_SELECTED_CATEGORY_TYPE_TAB &&
              item.is_only_regular) ||
            (selectedCategoryTypeTab === DEFAULT_SELECTED_CATEGORY_TYPE_TAB &&
              item.is_only_longterm)
          ) {
            return acc;
          }

          acc.push(
            <S.Item ref={active ? refActiveItem : null} key={item.id}>
              <S.ItemButton
                onClick={(): void => onItemClick(item.id)}
                data-qa={`category-filter-btn-${item.id}`}
                className={active ? 'selected' : 'not-selected'}
              >
                {isLivePage && (
                  <S.EventCount>{item.count_live_now}</S.EventCount>
                )}
                <S.ItemIcon
                  name={getSportName(item.id)}
                  width="18"
                  height="18"
                />

                <S.ItemTitle active={active}>
                  {deUmlautNormal(sportName)}
                </S.ItemTitle>
              </S.ItemButton>
            </S.Item>,
          );

          return acc;
        },
        [] as JSX.Element[],
      )}
    </S.Root>
  );
};

export default CategoriesSwitcherMobile;

import styled from 'styled-components';

export const ActiveArrow = styled.div`
  position: absolute;
  bottom: -10px;
  left: calc(50% - 10px);
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 0 10px 10px;
  border-color: transparent transparent
    ${({ theme }) => theme.highlightWrapperBackground};
  transform: rotate(180deg);
`;

export const AnimatedDiv = styled.div`
  animation-name: breath-animation;
  animation-duration: 0.5s;

  @keyframes breath-animation {
    0% {
      opacity: 0;
    }

    100% {
      opacity: 1;
    }
  }
`;

export const NotifierWrapper = styled.div`
  bottom: 68px;
  position: absolute;
  width: 100%;
`;

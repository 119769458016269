import React, { Children, useEffect, useState } from 'react';
import map from 'lodash/map';
import find from 'lodash/find';
import filter from 'lodash/filter';
import noop from 'lodash/noop';
import isString from 'lodash/isString';
import isNumber from 'lodash/isNumber';

import { useServices } from '@features/core/services';

import { getAppDefaultValue } from '@common/helpers/deviceUtil';
import { ITabsFilteredChild } from '@common/interfaces';

import TabTitle from '@ui/components/tabs/tabTitle/TabTitle';

import * as S from './Tabs.styled';
import { ITabs } from './Tabs.types';

const Tabs: React.FC<ITabs> = ({
  defaultActiveTab,
  className,
  navClassName,
  tabClassName,
  activeTabClassName,
  contentClassName,
  cookieTabActive,
  children,
  TabTitleComponent = TabTitle,
  onChange = noop,
  stickyTopHeight = 0,
}) => {
  const { cookie } = useServices();
  const [activeTab, setActiveTab] = useState('');

  const selectTab = (tabId): void => {
    setActiveTab(tabId);
  };

  useEffect(() => {
    setActiveTab(defaultActiveTab);
    const tab = getAppDefaultValue({
      defaultValue: cookie.get(cookieTabActive) || defaultActiveTab,
    });
    selectTab(tab);
  }, [defaultActiveTab]);

  const filteredChildren = filter(
    Children.toArray(children),
    child => !isString(child) && !isNumber(child),
  );

  const content = find(
    filteredChildren,
    child =>
      !isString(child) &&
      !isNumber(child) &&
      activeTab === (child as ITabsFilteredChild).props.tabIndex,
  );

  useEffect(() => {
    if (onChange && activeTab) {
      onChange(activeTab);
    }
  }, [activeTab]);

  const toggleTab = (idxKey: string): void => {
    setActiveTab(idxKey);
  };

  return (
    <S.Wrapper className={`${className}`} stickyTopHeight={stickyTopHeight}>
      <div className={`${navClassName} tabs-nav`} data-qa="tabs-nav">
        {map(filteredChildren, (child: ITabsFilteredChild, i) => {
          const tabIndex = child.props.tabIndex as string;
          return (
            TabTitleComponent && (
              <TabTitleComponent
                index={i}
                id={tabIndex}
                key={tabIndex}
                title={child.props.title as string}
                dataQa={child.props.dataQa}
                isActive={activeTab === tabIndex}
                onClick={(): void => toggleTab(tabIndex)}
                tabsCount={filteredChildren.length}
                className={tabClassName}
                activeClassName={`${activeTabClassName}`}
              />
            )
          );
        })}
      </div>

      <div className={`${contentClassName}`} data-qa={contentClassName}>
        {(content as ITabsFilteredChild)?.props?.children}
      </div>
    </S.Wrapper>
  );
};

export default Tabs;

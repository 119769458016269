import styled from 'styled-components';

import { BalanceBadge } from '@features/payments/components/balanceBadge';

import { Icon } from '@ui/components/icon';

export const WalletUserInfo = styled.div`
  height: ${({ theme }) => (theme.isDesktop ? '36' : '24')}px;
  border-radius: ${({ theme }) => (theme.isDesktop ? '3' : '50')}px;
  background-color: ${({ theme }) => theme.highlightWrapperBackground};
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
  margin-right: ${({ theme }) => theme.space_10};
  color: ${props => props.theme.inputDefaultText};
  cursor: pointer;

  & .wallet-info-icon {
    padding: 0 ${({ theme }) => theme.space_5};

    @media (max-width: 321px) {
      width: 13px;
      height: 13px;
    }

    @media (max-width: 295px) {
      width: 10px;
      height: 10px;
    }
  }
`;

export const SwitcherIcon = styled(Icon)``;

export const WalletInfoIcon = styled(Icon)`
  position: relative;
`;

export const SwitcherWrapper = styled.div<{ isSwitcherOpened: boolean }>`
  display: flex;
  margin-left: ${({ theme }) => theme.space_5};
  background-color: ${({ theme }) => theme.inputDefaultText};
  color: ${({ theme }) => theme.highlightWrapperBackground};
  height: ${({ theme }) => (theme.isDesktop ? '36' : '24')}px;
  width: 30px;
  align-items: center;
  border-radius: ${({ theme }) =>
    theme.isDesktop ? '0 3px 3px 0' : '0 50px 50px 0'};
  justify-content: center;

  svg {
    ${({ isSwitcherOpened }) =>
      isSwitcherOpened ? 'transform: rotate(180deg)' : ''};
  }
`;

export const StyledBalancedBadge = styled(BalanceBadge)`
  position: relative;
  font-weight: ${({ theme }) => theme.fontBold};
  font-stretch: normal;
  font-style: normal;
  display: inline-block;
  border-radius: ${({ theme }) => (theme.isDesktop ? '3' : '10')}px;
  text-align: center;
  padding: 0 ${({ theme }) => theme.space_5} 0 ${({ theme }) => theme.space_5};
  color: ${(props): string => props.theme.inputDefaultText};

  @media screen and (max-width: 350px) {
    font-size: ${(props): string => props.theme.fontXS};
    padding: 0 ${({ theme }) => theme.space_5};
  }
`;

import React from 'react';

import * as S from './TabItem.styled';
import { ITabItem } from './TabItem.types';

const TabItem: React.FC<ITabItem> = ({
  id,
  title,
  isActive,
  isDisabled,
  onClick,
  className,
  dataQa,
}: ITabItem) => {
  const handleClick = (): void => {
    onClick(id);
  };

  return (
    <S.Tab
      onClick={handleClick}
      className={`tab-item ${className}`}
      role="presentation"
      isActive={isActive}
      isDisabled={isDisabled}
      data-qa={dataQa}
    >
      {title}
    </S.Tab>
  );
};

export default TabItem;

import padStart from 'lodash/padStart';

/**
 *
 * @param {number} ms
 * @returns {string} formatted
 */
const msToMM = (ms = 0): string => {
  const minutes = Math.round((ms > 0 ? ms : 0) / 60000);
  return `${padStart(String(minutes), 2, '0')}`;
};

export default msToMM;

import styled from 'styled-components';

const LegalAgeWrapper = styled.div<{ iconSize: string }>`
  width: ${props => props.iconSize};
  height: ${props => props.iconSize};
  margin-left: ${({ theme }) => theme.isDesktop && theme.space_60};
  margin-right: ${({ theme }) => theme.isDesktop && theme.space_60};
`;

export default LegalAgeWrapper;

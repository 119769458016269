import map from 'lodash/map';
import reject from 'lodash/reject';
import { createWithEqualityFn } from 'zustand/traditional';
import { shallow } from 'zustand/shallow';

import {
  IBetDetail,
  IBetSelection,
  ICustomError,
  IGetBetsFilterProps,
} from '@common/interfaces';

import {
  IBetsSocket,
  IBetsState,
  IBetsUpdateData,
  IBetsUpdateStatus,
  ISetBetsPayload,
} from './types';
import defaultState from './state';

export const useBets = createWithEqualityFn<IBetsState>(
  () => defaultState,
  shallow,
);

const setSelectionStatusCode = (
  selections: IBetSelection[],
  targetId: string,
  status: string,
): IBetSelection[] =>
  map(selections, selection => {
    if (selection.id !== targetId) {
      return selection;
    }

    const statusCode = status === 'lost' ? 1 : 2;
    return {
      ...selection,
      status_code: statusCode,
    };
  });

export const clearBetDetail = (): void => {
  useBets.setState(() => ({
    bet_detail: {} as IBetDetail,
  }));
};

export const setBetDetail = (bet_detail: IBetDetail): void => {
  useBets.setState(() => ({
    detailLoading: false,
    betDetailError: null,
    bet_detail,
  }));
};

export const setBetDetailError = (
  betDetailError: ICustomError | null,
): void => {
  useBets.setState(() => ({
    detailLoading: false,
    loading: false,
    betDetailError,
  }));
};

export const setBetsLoading = (): void => {
  useBets.setState(() => ({
    loading: true,
  }));
};

export const setMyBetsLoading = (payload: boolean): void => {
  useBets.setState(() => ({
    betsLoading: payload,
  }));
};

export const getBetsFilter = (payload: IGetBetsFilterProps): void => {
  useBets.setState(() => ({
    betsFilter: { ...payload, offset: 0 },
  }));
};

export const setBetsDetailLoading = (): void => {
  useBets.setState(() => ({
    detailLoading: true,
  }));
};

export const setBets = (payload: ISetBetsPayload): void => {
  useBets.setState(state => {
    let bets: IBetDetail[];

    if (payload.pagination && payload.pagination.offset > 0) {
      bets = { ...state }.bets.concat(payload.user_bets || []);
    } else {
      bets = payload.user_bets || [];
    }

    return {
      loading: false,
      betsError: null,
      pagination: payload && payload.pagination,
      [payload.list]: bets,
    };
  });
};

export const setBetsError = (error: ICustomError): void => {
  useBets.setState(() => ({
    loading: false,
    error,
  }));
};

export const resetOpenBets = (): void => {
  useBets.setState(() => ({
    openBets: defaultState.openBets,
  }));
};

export const removeOpenBetsItem = (payload: string): void => {
  useBets.setState(state => ({
    openBets: reject(state.openBets, ['id', payload]),
  }));
};

export const resetBets = (): void => {
  useBets.setState(() => ({
    bets: defaultState.bets,
    pagination: defaultState.pagination,
    selectedBetId: '',
  }));
};

export const resetUpdates = (): void => {
  useBets.setState(() => ({
    updatesReceived: defaultState.updatesReceived,
    updates: defaultState.updates,
  }));
};

export const setBetsSocketConnection = (socket: IBetsSocket): void => {
  useBets.setState(() => ({
    socket,
  }));
};

export const setBetsSocketData = (payload: IBetsUpdateData): void => {
  const { bet_id } = payload;
  const update = {
    [bet_id]: payload,
  };

  useBets.setState(
    state =>
      <IBetsState>{
        updates: {
          ...state.updates,
          ...update,
        },
        updatesReceived: true,
      },
  );
};

export const setOpenBetStatus = ({
  bet_id,
  selection_id,
  result,
}: IBetsUpdateStatus): void => {
  useBets.setState(state => ({
    ...state,
    openBets: map(state.openBets, bet => {
      if (bet.id === bet_id) {
        return {
          ...bet,
          selections: setSelectionStatusCode(
            bet.selections,
            selection_id,
            result,
          ),
        };
      }

      return bet;
    }),
  }));
};

export const setSelectedBetId = (selectedBetId: string): void => {
  useBets.setState(() => ({
    selectedBetId,
  }));
};

export const setSocketReconnect = (failedSocketReconnect: boolean): void => {
  useBets.setState(() => ({
    failedSocketReconnect,
  }));
};

import React, { useMemo } from 'react';

import { DefaultTimerView } from '@features/events/components/scoreboards/timers';

import {
  getDate,
  getTime,
} from '@common/helpers/eventsHelper/scoreboards/timerModel';

import NotStartedInner from './NotStartedTimer.styled';
import { Props } from './NotStartedTimer.types';

export const NotStartedTimer = (props: Props): React.ReactElement => {
  const { event, shortTimer, listType } = props;

  const rows = useMemo(
    () => [`${getDate(event, shortTimer)} ${getTime(event)}`],
    [event.date, event.expires_ts, event.expires],
  );

  return (
    <NotStartedInner data-qa="not-started">
      <DefaultTimerView
        rows={rows}
        listType={listType}
        className="not-started-timer"
      />
    </NotStartedInner>
  );
};

export default NotStartedTimer;

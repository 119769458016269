import React from 'react';

import * as S from './Header.styled';
import { IHeaderProps } from './Header.types';

const Header: React.FC<IHeaderProps> = ({
  leftContent,
  rightContent,
  centerContent,
  loggedIn,
}): JSX.Element => {
  return (
    <S.Root data-qa="header" className="header-root-container">
      <S.LeftSection>{leftContent}</S.LeftSection>
      <S.CenterSection>{centerContent}</S.CenterSection>
      <S.RightSection loggedIn={loggedIn}>{rightContent}</S.RightSection>
    </S.Root>
  );
};

export default Header;

import styled from 'styled-components';

export const Wrapper = styled.div`
  & div {
    border: ${({ theme }) => theme.border};
  }

  &.active-item p,
  & .active-item div {
    color: ${({ theme }) => theme.accent};
  }
`;

export const IconWrapper = styled.div`
  position: relative;
  width: 100%;
  cursor: pointer;

  & svg {
    position: absolute;
    right: -20px;
    top: -21px;
  }
`;

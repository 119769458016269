import services from '@features/core/services';

import { FWD_DEST_COOKIE } from '@common/constants/cookie';
import { redirectToSpecificRoute } from '@common/providers/router/helper';

export const forwardDestination = (): void => {
  try {
    if (services.cookie.get(FWD_DEST_COOKIE)) {
      redirectToSpecificRoute(
        decodeURIComponent(services.cookie.get(FWD_DEST_COOKIE)),
      );
      services.cookie.remove(FWD_DEST_COOKIE);
    }
  } catch (e) {
    services.logger.log(
      `Not able to redirect to fwd location ${services.cookie.get(
        FWD_DEST_COOKIE,
      )}`,
    );
  }
};

export default forwardDestination;

import i18n from '@features/core/translation';

export const selectBlockAccountReasons = [
  { value: '01', label: i18n.t('limits.reason_addiction') },
  { value: '02', label: i18n.t('limits.reason_finance') },
  { value: '03', label: i18n.t('limits.reason_debt') },
  { value: '04', label: i18n.t('limits.reason_money') },
  { value: '99', label: i18n.t('limits.no_reason') },
];

export const selectItems = [
  {
    value: '3M',
    label: i18n.t('limits.months_3'),
    short_label: i18n.t('events.filters.keys.3M'),
    disabled_for: [],
    //  disabled_for: ['gambling_addiction', 'financial_problems'],
  },
  {
    value: '6M',
    label: i18n.t('limits.months_6'),
    short_label: i18n.t('events.filters.keys.6M'),
    disabled_for: [],
    //  disabled_for: ['gambling_addiction', 'financial_problems'],
  },
  {
    value: '12M',
    label: i18n.t('limits.months_12'),
    short_label: i18n.t('events.filters.keys.12M'),
  },
  { value: 'custom', label: i18n.t('limits.custom') },
  {
    value: '9999Y',
    label: i18n.t('limits.indefinitely'),
    short_label: i18n.t('limits.indefinitely_short'),
  },
];

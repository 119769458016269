import keys from 'lodash/keys';
import includes from 'lodash/includes';

import { detailedEventMarketGroups } from '../markets/marketModel';

/**
 * hasEventMarkets
 *
 * @param {string} sportType
 * @returns {boolean} hasEventMarkets
 */
const hasEventMarkets = (sportType: string): boolean => {
  const sportTypesWithMarkets = keys(detailedEventMarketGroups);

  return includes(sportTypesWithMarkets, sportType);
};

export default hasEventMarkets;

import { IAccount, IPayment } from '@common/interfaces';
import { IPaymentProviderState } from '@common/providers/payments/types';

const defaultState: IPaymentProviderState = {
  loading: false,
  error: null,
  account: {} as IAccount,
  deposit: {} as IPayment,
  withdrawal: {} as IPayment,
  services: [],
  hasPaymentInfoPopup: false,
};

export default defaultState;

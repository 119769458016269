import io from 'socket.io-client';

import services from '@features/core/services';

import { SOCKET_URL } from '@common/constants/config';
import { parseSocketUpdates } from '@common/helpers/updatesHelper';
import { IEventUpdates } from '@common/interfaces';
import { ISocket } from '@common/providers/application/types';
import { enqueInGlobalQueue } from '@common/helpers/eventsHelper/globalSocketData';
import { setEventsSocketData } from '@common/providers/events/eventList/useEventsList';
import { setBSsocketData } from '@common/providers/bettingslip/useBettingSlip';
import { setOpenAppVersion } from '@common/providers/application/useAppState';

let reconnectionDelay = 1000; // Initial reconnection delay
const reconnectionDelayMax = 30000; // Maximum reconnection delay
let isRepeatConnection = false;

export default (): ISocket => {
  const uid = Math.random() * 1000000; // anything, but should be different for every connection, so different backends receive the connection.
  const socket = io(services.config.get(SOCKET_URL), {
    timeout: 10000,
    path: `/en/socket.io/?uid=${uid}`,
    reconnection: true,
    transports: ['websocket'],
    autoConnect: false,
    reconnectionDelay,
    reconnectionDelayMax,
  });
  socket.connect();

  socket.on('connect', () => {
    services.logger.log('open', new Date().toString());
    if (isRepeatConnection) {
      setOpenAppVersion();
    }
    isRepeatConnection = true;
  });

  socket.on('error', err => {
    services.logger.log('error', new Date().toString(), err);
  });

  socket.on('disconnect', () => {
    services.logger.log('close', new Date().toString());
  });

  // handle the event sent with socket.send()
  socket.on('events', message => {
    const data = parseSocketUpdates([{ body: message }]) as IEventUpdates;
    enqueInGlobalQueue(data);
    if (window.socketData) {
      data.push(window.socketData);
    }
    setEventsSocketData(data);
    setBSsocketData(data);
  });

  socket.on('reconnect', () => {
    services.logger.log('Socket reconnected');
    setOpenAppVersion();
  });

  socket.on('reconnect_attempt', attemptNumber => {
    services.logger.log(`Attempting to reconnect (attempt ${attemptNumber})`);
  });

  socket.on('reconnect_error', error => {
    services.logger.log('Socket reconnection error:', error);
    reconnectionDelay = Math.min(reconnectionDelay * 2, reconnectionDelayMax);
    socket.io.opts.reconnectionDelay = reconnectionDelay;
    socket.io.opts.reconnectionDelayMax = reconnectionDelayMax;
    services.logger.log(
      'reconnect error. Increasing reconnection delay to',
      String(reconnectionDelay),
    );
  });

  socket.on('reconnect_failed', () => {
    services.logger.log('Socket reconnection failed');
  });
  return socket;
};

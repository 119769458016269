import debounce from 'lodash/debounce';
import { FormInstance, Rule } from 'rc-field-form/lib/interface';

import i18next from '@features/core/translation';
import Patterns from '@features/core/form/utils/patterns';

import { usedLoginValidator } from '@common/helpers/registrationHelper/registrationHelper';

const loginPreset = (form: FormInstance): Rule[] => [
  {
    required: true,
    message: `${i18next.t('registration.step1.login_required')}`,
  },
  {
    pattern: Patterns.LOGIN,
    message: i18next.t('registration.step1.login_incorrect'),
  },
  { min: 6, message: i18next.t('registration.step1.login_too_short') },
  {
    validator: debounce(usedLoginValidator(form), 500),
  },
];

export default loginPreset;

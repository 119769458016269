export const HOME_NOTICE = 'homeNotice';
export const RETRY_MESSAGE = 'retryMessage';
export const VERIFICATION_NOTICE = 'verificationNotice';
export const YEARLY_VERIFICATION_NOTICE = 'yearlyVerificationNotice';
export const RESEND_SUCCESS = 'resendSuccess';
export const EMAIL_NOTICE = 'emailNotice';
export const ONLINE_ACCOUNT_NOTICE = 'onlineAccountNotice';

export const notificationsForTest = [
  RETRY_MESSAGE,
  VERIFICATION_NOTICE,
  YEARLY_VERIFICATION_NOTICE,
  RESEND_SUCCESS,
  EMAIL_NOTICE,
  ONLINE_ACCOUNT_NOTICE,
];

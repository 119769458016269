import services from '@features/core/services';

import {
  IGetBetDetailsPayload,
  ISetCashoutBetDetailPayload,
} from '@common/providers/bets/types';
import apiRpcRequest from '@common/api/common/apiCommonRequest';
import { MethodType } from '@common/api/apiHandler';

const fetchCashoutBetDetail = async ({
  barcode,
}: IGetBetDetailsPayload): Promise<ISetCashoutBetDetailPayload> => {
  return (await apiRpcRequest({
    method: MethodType.Post,
    data: {
      jsonrpc: '2.0',
      method: 'accountless_bet.detail',
      params: {
        lang: services.domainLang,
        barcode,
      },
    },
  })) as ISetCashoutBetDetailPayload;
};

export default fetchCashoutBetDetail;

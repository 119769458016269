import React, { useCallback, useMemo } from 'react';
import split from 'lodash/split';
import reduce from 'lodash/reduce';
import filter from 'lodash/filter';

import {
  MarketsLabel,
  SpecialValue,
} from '@features/events/components/markets';
import {
  Label,
  PredictionWrapper,
} from '@features/events/components/markets/mapMarket/MapMarket.styled';
import { Prediction } from '@features/events/components/prediction';

import { isEnabled as isEnabledPrediction } from '@common/helpers/eventsHelper/predictionModel';
import {
  IPrediction,
  IPredictionType,
  PredictionType,
} from '@common/interfaces';
import { useMarketPredictions } from '@common/hooks/useMarketPredictions';

import * as S from './ExtraCorrectScore.styled';
import { IExtraCorrectScore } from './ExtraCorrectScore.types';

const ExtraCorrectScore: React.FC<IExtraCorrectScore> = props => {
  const {
    market,
    hasLabel,
    hasSpecialValue,
    listType,
    pageType,
    eventId,
  } = props;

  const predictions = useMarketPredictions(market, listType);

  const reducePredictions = useCallback(
    p => {
      return reduce(
        p,
        (acc: JSX.Element[], prediction) => {
          acc.push(
            <Prediction
              key={prediction.id}
              listType={listType}
              pageType={pageType}
              eventId={eventId}
              marketId={market.id}
              predictionId={prediction.id}
              withLabel={false}
            />,
          );
          return acc;
        },
        [] as JSX.Element[],
      );
    },
    [predictions, market],
  );

  const getPrediction = useCallback(
    (type: IPredictionType): IPrediction[] =>
      filter(predictions, prediction => {
        const splitted = prediction.label
          ? split(prediction.label, ':')
          : ['', ''];
        if (type === PredictionType.HOME) {
          return splitted[0] > splitted[1] && isEnabledPrediction(prediction);
        }
        if (type === PredictionType.VISITOR) {
          return splitted[0] < splitted[1] && isEnabledPrediction(prediction);
        }
        return splitted[0] === splitted[1] && isEnabledPrediction(prediction);
      }),
    [predictions],
  );

  const currentPredictions = useMemo(() => {
    const home = getPrediction(PredictionType.HOME);
    const draw = getPrediction(PredictionType.DRAW);
    const away = getPrediction(PredictionType.VISITOR);
    return (
      <>
        {home?.length > 0 && <div>{reducePredictions(home)}</div>}
        {draw?.length > 0 && <div>{reducePredictions(draw)}</div>}
        {away?.length > 0 && <div>{reducePredictions(away)}</div>}
      </>
    );
  }, [market, predictions]);

  return (
    <S.Predictions>
      {(hasLabel || hasSpecialValue) && (
        <Label pageType={pageType}>
          <div>
            <MarketsLabel market={market} />
            <SpecialValue
              market={market}
              hasSpecialValue={hasSpecialValue}
              pageType={pageType}
            />
          </div>
        </Label>
      )}
      <PredictionWrapper className="correct-score">
        {currentPredictions}
      </PredictionWrapper>
    </S.Predictions>
  );
};

export default ExtraCorrectScore;

import React, { useState, useEffect, useRef } from 'react';
import reduce from 'lodash/reduce';
import { Trans, useTranslation } from 'react-i18next';
import reject from 'lodash/reject';
import filter from 'lodash/filter';
import find from 'lodash/find';
import includes from 'lodash/includes';

import services from '@features/core/services';
import { CheckboxInput } from '@features/core/form/components/checkboxInput';
import WalletSwitcherItem from '@features/wallets/walletSwitcher/walletSwitcherItem/WalletSwitcherItem';
import { useThemeScheme } from '@features/core/theming/contexTheme';

import { WALLET_SWITCHER } from '@common/constants/dialogs';
import {
  DEFAULT_WALLET_ID,
  SWITCHER_POPUP_CHECKED,
  SUMMARY_LAYER_COOKIE,
} from '@common/constants/cookie';
import { IWallet, SidebarSide } from '@common/interfaces';
import switchUserAccount from '@common/providers/user/actions/switchUserAccount';
import { isDesktopView } from '@common/helpers/deviceUtil';
import { useUserState } from '@common/providers/user/useUserState';
import { closeSidebar } from '@common/providers/application/useAppState';
import { IUserWalletType } from '@common/interfaces/user/IUser';
import { getActiveWallet } from '@common/helpers/paymentsHelper/walletsHelper';
import {
  setWalletView,
  useWalletsState,
} from '@common/providers/payments/wallets/useWallets';
import usePrevious from '@common/hooks/usePrevious';
import useOnClickOutside from '@common/hooks/useOnClickOutside';
import { HAS_LUGAS } from '@common/constants/config';

import { BlockLoader } from '@ui/components/loaders';
import { Icon } from '@ui/components/icon';

import * as S from './WalletSwitcher.styled';
import { IWalletView } from './WalletSwitcher.types';

const WalletSwitcher = (): React.ReactElement => {
  const { t } = useTranslation();
  const isDesktop = isDesktopView();
  const rightContentWidth = services.domainLang === 'de' ? 400 : 328;
  const [isChecked, setIsChecked] = useState(false);
  const wallets = useWalletsState(state => state.data);
  const view = useWalletsState(state => state.view);
  const isSwitcherPopup = view === IWalletView.POPUP_VIEW;
  const activeWallet = getActiveWallet();
  const switchAccLoading = useUserState(state => state.switchAccountLoading);
  const sidebarRef = useRef<HTMLDivElement>(null);
  const { theme } = useThemeScheme();
  const onlineWallet = find(wallets, ['type', IUserWalletType.www]);
  const hasLugas = services.config.get(HAS_LUGAS);

  const firstWallet = filter(wallets, ['type', IUserWalletType.www]);
  const restWallets = filter(
    wallets,
    el =>
      !includes(
        [
          IUserWalletType.www,
          IUserWalletType.bonus,
          IUserWalletType.shop_emoney,
        ],
        el.type as IUserWalletType,
      ),
  );

  const otherWallets = reject([...firstWallet, ...restWallets], activeWallet);

  const sortedWallets = isSwitcherPopup
    ? [...firstWallet, ...restWallets]
    : reject([...firstWallet, ...restWallets], activeWallet);

  const prevSwitchAccountLoading = usePrevious(switchAccLoading);

  useEffect(() => {
    if (prevSwitchAccountLoading && !switchAccLoading) {
      closeSidebar(WALLET_SWITCHER);
    }
  }, [switchAccLoading]);

  useEffect(
    () => () => {
      if (view === IWalletView.POPUP_VIEW) {
        setWalletView(IWalletView.SMALL_VIEW);
        services.cookie.set(SWITCHER_POPUP_CHECKED, SWITCHER_POPUP_CHECKED);
      }
    },
    [view],
  );

  const switchAccount = (wallet: IWallet): void => {
    const { account_id } = wallet;

    services.cookie.remove(SUMMARY_LAYER_COOKIE);
    switchUserAccount(account_id);
    if (isDesktop) {
      closeSidebar(WALLET_SWITCHER);
    }
    if (isSwitcherPopup && isChecked) {
      services.cookie.set(DEFAULT_WALLET_ID, account_id, {
        expires: null,
      });
    }
  };

  useOnClickOutside(
    sidebarRef,
    event => {
      event.preventDefault();
      closeSidebar(WALLET_SWITCHER);
    },
    true,
  );

  return (
    <BlockLoader childShouldHaveOpacity={false} showLoader={switchAccLoading}>
      <S.SidebarWrapper
        ref={sidebarRef}
        isSwitcherPopup={isSwitcherPopup}
        isSingleWallet={!otherWallets.length}
        rightContentWidth={rightContentWidth}
      >
        <S.ContentWrapper
          id="wallet-wrapper"
          data-qa="wallet-wrapper"
          isSwitcherPopup={isSwitcherPopup}
          isSingleWallet={!otherWallets.length}
        >
          {isSwitcherPopup && (
            <>
              <S.SectionHeader isSwitcherPopup={isSwitcherPopup}>
                {t('wallet_switcher.choose_wallet_header')}
              </S.SectionHeader>

              {onlineWallet && !parseFloat(onlineWallet.balance) && hasLugas && (
                <S.SectionInfo>
                  <S.SectionInfoIcon>
                    <Icon
                      name="info-filled"
                      width="20"
                      height="20"
                      fill={theme.accent}
                    />
                  </S.SectionInfoIcon>

                  <S.SectionInfoText>
                    <Trans i18nKey="wallet_switcher.info_text">
                      <strong />
                    </Trans>
                  </S.SectionInfoText>
                </S.SectionInfo>
              )}

              <S.HeaderWrapper>
                <CheckboxInput
                  id="login_agree"
                  name="login_agree"
                  checked={isChecked}
                  onClick={(): void => setIsChecked(!isChecked)}
                />
                <S.SectionTitle isSwitcherPopup={isSwitcherPopup}>
                  <b>{t('wallet_switcher.login_text')}</b>
                  <br />
                  {t('wallet_switcher.login_text2')}
                </S.SectionTitle>
              </S.HeaderWrapper>
            </>
          )}
          {activeWallet && !isSwitcherPopup && (
            <WalletSwitcherItem
              isSingleWallet={!otherWallets.length}
              wallet={activeWallet}
              isActive
            />
          )}
          {!isSwitcherPopup && sortedWallets.length ? (
            <S.OtherWalletsHeader>
              {t('wallet_switcher.other_accounts')}
            </S.OtherWalletsHeader>
          ) : null}
          {sortedWallets.length ? (
            <S.WalletsList
              className="wallets"
              isSwitcherPopup={isSwitcherPopup}
            >
              {reduce(
                sortedWallets,
                (acc, wallet, index): Array<React.ReactElement> => {
                  return acc.concat(
                    <WalletSwitcherItem
                      key={index}
                      switchAccount={switchAccount}
                      isSwitcherPopup={isSwitcherPopup}
                      wallet={wallet}
                    />,
                  );
                },
                [] as JSX.Element[],
              )}
            </S.WalletsList>
          ) : null}
        </S.ContentWrapper>
      </S.SidebarWrapper>
    </BlockLoader>
  );
};

WalletSwitcher.meta = {
  side: SidebarSide.stretch,
  key: WALLET_SWITCHER,
};

export default WalletSwitcher;

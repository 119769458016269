import services from '@features/core/services';

import { UserEvents } from '@packages/events/appEvents';

import { TOKEN } from '@common/constants/cookie';
import { MethodType } from '@common/api/apiHandler';
import apiRpcRequest from '@common/api/common/apiCommonRequest';
import { authUserSuccess } from '@common/providers/user/useUserState';
import { IUser } from '@common/interfaces/user/IUser';
import { setWalletsData } from '@common/providers/payments/wallets/useWallets';
import { IFetchWallets } from '@common/providers/payments/wallets/types';
import { closeSidebars } from '@common/providers/application/useAppState';

const authIframeUser = async (user: IUser): Promise<void> => {
  closeSidebars();

  const wallets = (await apiRpcRequest({
    method: MethodType.Get,
    params: { method: 'user.get_wallets' },
  })) as IFetchWallets;

  if (services.cookie.get(TOKEN)) {
    setWalletsData(wallets?.result?.wallets);

    authUserSuccess({
      ...user,
    });

    services.events.emitEvent(UserEvents.AUTH, user);
  }
};

export default authIframeUser;

import styled from 'styled-components';

// eslint-disable-next-line import/prefer-default-export
export const LiveMatchTracker = styled.div`
  min-height: 160px;

  .sr-bb {
    background: ${({ theme }) => theme.textMain};
  }
`;

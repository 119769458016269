import { shallow } from 'zustand/shallow';
import { createWithEqualityFn } from 'zustand/traditional';

import { IProfileInfo } from '@common/interfaces';
import defaultState from '@common/providers/profile/state';
import { IProfileState } from '@common/providers/profile/types';

export const useProfile = createWithEqualityFn<IProfileState>(
  () => defaultState,
  shallow,
);

export const startFetchingUserProfile = (): void => {
  useProfile.setState(state => ({
    profile: {
      ...state.profile,
      loading: true,
    },
  }));
};

export const setUserProfileSuccess = (payload: IProfileInfo): void => {
  useProfile.setState(() => ({
    profile: {
      loading: false,
      success: true,
      info: payload,
      errors: [],
    },
  }));
};

export const setUserProfileError = (payload: string[]): void => {
  useProfile.setState(state => ({
    profile: {
      ...state.profile,
      loading: false,
      errors: payload,
    },
  }));
};

export const uploadDocument = (): void => {
  useProfile.setState(state => ({
    uploadDocument: {
      ...state.uploadDocument,
      loading: true,
    },
  }));
};

export const uploadDocumentError = (): void => {
  useProfile.setState(() => ({
    uploadDocument: {
      loading: false,
      error: true,
      success: false,
    },
  }));
};

export const uploadDocumentSuccess = (): void => {
  useProfile.setState(() => ({
    uploadDocument: {
      loading: false,
      error: false,
      success: true,
    },
  }));
};

export const resetUploadDocument = (): void => {
  useProfile.setState(() => ({
    uploadDocument: defaultState.uploadDocument,
  }));
};

export const resetProfileState = (): void => {
  useProfile.setState(() => defaultState);
};

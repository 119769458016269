import React from 'react';

import { ScoreboardHoc } from '@features/events/components/scoreboards/scores';
import DefaultScore from '@features/events/components/scoreboards/scores/common/defaultScoreFactory/DefaultScoreFactory';

import {
  getQuarter,
  getHalf,
  getScore,
  getOT,
  getLastPeriod,
} from '@common/helpers/eventsHelper/scoreboards/scoreBoardModel';
import { isNcaaBasketball } from '@common/helpers/eventsHelper/eventTypeHelper';

import { IBasketballScoreProps } from './BasketballScore.types';

const BasketballScore = (
  props: IBasketballScoreProps,
): React.ReactElement | null => {
  const { event, score } = props;

  const getPeriodFunction = isNcaaBasketball(event) ? getHalf : getQuarter;
  const periodsCount = isNcaaBasketball(event) ? 2 : 4; // NCAA uses halves, others use quarters

  return (
    <DefaultScore
      score={score}
      lastPeriod={getLastPeriod(score, getPeriodFunction, periodsCount)}
      periodsCount={periodsCount}
      className="score"
      getTotal={getScore}
      getPeriod={getPeriodFunction}
      getOT={score.periods.OT ? getOT : undefined}
    />
  );
};

export default ScoreboardHoc(BasketballScore);

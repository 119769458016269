import bindAll from 'lodash/bindAll';
import isUndefined from 'lodash/isUndefined';
import includes from 'lodash/includes';
import intersection from 'lodash/intersection';
import forEach from 'lodash/forEach';
import map from 'lodash/map';
import reduce from 'lodash/reduce';
import groupBy from 'lodash/groupBy';
import values from 'lodash/values';
import clone from 'lodash/clone';
import flatMap from 'lodash/flatMap';
import toLower from 'lodash/toLower';
import split from 'lodash/split';
import replace from 'lodash/replace';

import CombiMatrixStatusCode from '@features/bets/components/combiMatrix/CombiMatrix.types';

import {
  IBetDetail,
  IBetSelection,
  IBettingslipType,
} from '@common/interfaces';
import { expandLeg } from '@common/helpers/bettingSlipHelper/bettingSlipModel';
import Combinations from '@common/helpers/combinations/combinations';

const SingleMultyTypes =
  // eslint-disable-next-line max-len
  'Singles multiway|Einzelwetten Mehrweg|Μονά Διπλή παραλλαγή|Singles Multi-way|Tekliler Çok yollu|shumë mundësi|Simples multiway';
const MultyTypes =
  // eslint-disable-next-line max-len
  'Kombiwette Mehrweg|Multiple multiway|Combinada multiway|Παρολί Διπλή παραλλαγή|Multiple Multi-way|Развернутая многоходовая|Të shumta me shumë mundësi';

export class CombiMatrixModel {
  static code = 64;

  private matrixLength: number;

  private bet: IBetDetail;

  //  Return letter for event,
  static getNextLetter(): string {
    CombiMatrixModel.code++;
    if (CombiMatrixModel.code > 90) {
      //  Form string Aa, Ab, Ac, etc for events after Z letter
      return `A${String.fromCharCode(CombiMatrixModel.code + 7)}`;
    }
    return String.fromCharCode(CombiMatrixModel.code);
  }

  static resetLetter(): void {
    CombiMatrixModel.code = 64;
  }

  /**
   * hasMatrix
   * check if it's possible to render matrix for ticket
   *
   * @param {IBetDetail} bet
   * @returns {boolean} hasMatrix
   */
  static hasMatrix(bet: IBetDetail): boolean {
    return parseFloat(bet.leg_count) > 1;
  }

  constructor(options: { bet: IBetDetail }) {
    this.matrixLength = 15;
    this.bet = options.bet;
    bindAll(
      this,
      'getProfitForLegGroup',
      'getTotalQuoteForLegGroup',
      'getSingleQuote',
    );
  }

  /**
   * getLegGroupStatus,
   * calculates class for specific
   * cell(1st matrix) or row(last matrix)
   *
   * @param {number[]} legGroup
   * @param {number} row
   * @returns {string} status
   */
  getLegGroupStatus(legGroup: number[], row?: number): number {
    const size = legGroup.length;
    const winners = this.getByStatus(2);
    const lost = this.getByStatus(1);
    if (!isUndefined(row)) {
      //  First table, no rows, only 1 event(cell)
      if (includes(lost, row)) {
        return CombiMatrixStatusCode.LOST;
      }
      if (includes(winners, row)) {
        return CombiMatrixStatusCode.WON;
      }
    } else if (intersection(winners, legGroup).length === size) {
      return CombiMatrixStatusCode.WON;
    } else if (intersection(lost, legGroup).length) {
      return CombiMatrixStatusCode.LOST;
    }
    return CombiMatrixStatusCode.OPEN;
  }

  /**
   * getByStatus
   * return events with specific status from matrix
   *
   * @param {number} status
   * @returns {number[]} events
   */
  getByStatus(status: number): number[] {
    const selections = this.getBets();
    const events: number[] = [];
    forEach(selections, (event, index) => {
      if (event.status_code === status) {
        events.push(index);
      }
    });
    return events;
  }

  getMatrixLength(): number {
    return this.matrixLength;
  }

  /**
   * getTotalQuoteForLegGroup
   * Accumulates total quote of the ticket
   *
   * @param {number[]} legGroup
   * @returns {number} total
   */
  getTotalQuoteForLegGroup(legGroup: number[]): number {
    return reduce(
      map(legGroup, this.getSingleQuote),
      (first, second) => first * second,
      1,
    );
  }

  //  Fetches quote for specific market
  getSingleQuote(key: number): number {
    return parseFloat(replace(this.bet.selections[key]?.odds, ',', '.'));
  }

  //  Fetches profit for a singe leg group
  getProfitForLegGroup(quote: number): number {
    return parseFloat(replace(this.bet.stake, ',', '.')) * quote;
  }

  getTicket(): IBetDetail {
    return this.bet;
  }

  /**
   * getGroupedEvents
   * returns events grouped by event id
   * e.g { A : [selection], B: [selection, se],  }
   *
   * @returns {IBetSelection[][]}  object of events value contain array of selections
   */
  getGroupedEvents(): IBetSelection[][] {
    return values(groupBy(this.getBets(), 'event_id')).sort(
      (betA, betB) =>
        Number(new Date(String(betA[0].event_date_safe_server))) -
        Number(new Date(String(betB[0].event_date_safe_server))),
    );
  }

  /**
   * getBets
   * returns all selections
   * e.g [{}, {}, {}]
   *
   * @returns { IBetSelection[] } flat array of all selections
   */
  getBets(): IBetSelection[] {
    return this.bet.selections;
  }

  //  Parse system string e.g System 7/10, 8/10, 9/10
  //  to array ['7/10', '8/10']
  parseSystems(): string[] {
    const systemRegex = /(\d\d?\/\d\d?)/;
    const systemMultyRegex = new RegExp(
      `${SingleMultyTypes}|${MultyTypes}`,
      'i',
    );
    let string = this.bet.type;
    const systems: string[] = [];
    let result;
    if (/system|sistem|système|Σύστημα|Система|sistemi/i.test(this.bet.type)) {
      /*eslint-disable*/
      while ((result = systemRegex.exec(string))) {
        systems.push(result[0]);
        string = string.substr(
          result.index + result[0].length,
        ) as IBettingslipType;
      }
      /* eslint-enable */
    } else if (systemMultyRegex.test(this.bet.type)) {
      const { length } = this.getGroupedEvents();
      const system = `${length}/${length}`;
      systems.push(system);
    }

    return systems;
  }

  //  Creates array of leg combination for each system
  //  Nested array is flattened
  getSystemCombination(): number[][] {
    const systems = this.parseSystems();
    let baseLeg: number[][] = [];
    const banks = this.getBanks();
    const betsByEvent: number[][] = this.getBetsByEvent();

    if (banks.length) {
      baseLeg = this.getBanks();
    }

    if (this.isSingle()) {
      const events = this.getBets();
      return map(events, (event, index) => [index]);
    }

    return flatMap(
      map(systems, (system: string) => {
        //  ['7/10', '8/10'] => [[[]]], [[[]]] => [[], []]
        let allLegs: number[][] = [];
        //  Since we have system parsed from server response, we can omit banks
        //  count here, server will take care of it anyway
        const combinations = Combinations.get(
          Number(split(system, '/')[0]),
          Number(split(system, '/')[1]),
        );

        for (let x = 0; x < combinations.length; x++) {
          const combi = combinations[x];
          const eventLeg: number[][] = clone(baseLeg);

          for (let y = 0; y < combi.length; y++) {
            const idx = combi[y];
            eventLeg.push(betsByEvent[idx]);
          }

          allLegs = [...allLegs, ...expandLeg(eventLeg)];
        }
        return allLegs;
      }),
    );
  }

  /**
   * getBetsByEvent
   * returns selections of events without banks
   *
   * @returns {number[][]} betsByEvent
   */
  getBetsByEvent(): number[][] {
    const events = this.getGroupedEvents();
    let index = 0;
    const eventsWithoutBanks: number[][] = [];
    forEach(events, event => {
      const eventIndexes: number[] = [];
      forEach(event, selection => {
        if (!selection.bank) {
          eventIndexes.push(index);
        }
        index++;
      });

      if (eventIndexes.length) {
        eventsWithoutBanks.push(eventIndexes);
      }
    });
    return eventsWithoutBanks;
  }

  /**
   * isSingle
   * checks if bet was single
   *
   * @returns {boolean} isSingle
   */
  isSingle(): boolean {
    return (
      this.bet && includes(toLower(this.bet.raw_type), IBettingslipType.single)
    );
  }

  isSystem(): boolean {
    return (
      this.bet &&
      (includes(toLower(this.bet.raw_type), IBettingslipType.system) ||
        includes(toLower(this.bet.raw_type), IBettingslipType.combi))
    );
  }

  /**
   * getBanks
   * returns selections with banks
   *
   * @returns {number[][]} banks
   */
  getBanks(): number[][] {
    const events = this.getGroupedEvents();
    const banks: number[][] = [];
    let index = 0;
    forEach(events, event => {
      const eventBanks: number[] = [];

      forEach(event, selection => {
        if (selection.bank) {
          eventBanks.push(index);
        }
        ++index;
      });
      if (eventBanks.length) {
        banks.push(eventBanks);
      }
    });
    return banks;
  }
}

export default CombiMatrixModel;

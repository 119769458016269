import styled from 'styled-components';

export const Container = styled.div`
  position: absolute;
  right: ${({ theme }) => theme.space_10};
  top: 50%;
  transform: translate(0, -50%);
`;

export const Loader = styled.i`
  position: relative;
  height: 20px;
  width: 20px;
  display: inline-block;
  animation: around 5.4s infinite;
  margin: 0;

  @keyframes around {
    0% {
      transform: rotate(0deg);
    }

    100% {
      transform: rotate(360deg);
    }
  }

  &::after,
  &::before {
    content: '';
    background: transparent;
    position: absolute;
    display: inline-block;
    width: 100%;
    height: 100%;
    border-width: 2px;
    border-color: ${props => props.theme.textActive}
      ${props => props.theme.textActive} transparent transparent;
    border-style: solid;
    border-radius: 20px;
    box-sizing: border-box;
    top: 0;
    left: 0;
    animation: around 0.7s ease-in-out infinite;
  }

  &::after {
    animation: around 0.7s ease-in-out 0.1s infinite;
    background: transparent;
  }
`;

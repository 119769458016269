import styled from 'styled-components';

export const LiveChatContent = styled.div`
  display: grid;
  align-items: center;
  cursor: pointer;
`;

export const Block = styled.div`
  cursor: pointer;

  .live-chat-content {
    color: ${({ theme }) => theme.highlightWrapperBackground};
    padding: ${({ theme }) => theme.space_10};

    & svg {
      width: 32px;
      height: 32px;
      position: relative;
    }
  }

  &#live-chat-button-menu {
    width: 100%;
    margin: 0;
    padding: ${({ theme }) => theme.space_10};
  }
`;

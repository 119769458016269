/* eslint-disable no-param-reassign */
import { InternalAxiosRequestConfig } from 'axios';
import startsWith from 'lodash/startsWith';

import services from '@features/core/services';

import { SESSION_ID, TOKEN } from '@common/constants/cookie';
import { PROXY_URL } from '@common/constants/config';

const addAuthHeaders = (
  config: InternalAxiosRequestConfig,
): InternalAxiosRequestConfig => {
  if (
    startsWith(config.url, services.config.get(PROXY_URL) as string) ||
    !startsWith(config.url, 'http')
  ) {
    if (services.cookie.get(TOKEN)) {
      config.headers.TOKEN = services.cookie.get(TOKEN);
    }
    if (services.cookie.get(SESSION_ID)) {
      config.headers.SESSION_ID = services.cookie.get(SESSION_ID);
    }
  }

  return config;
};

export default addAuthHeaders;

import { Rule } from 'rc-field-form/lib/interface';

import i18next from '@features/core/translation';
import CustomError from '@features/core/error/error';
import Patterns from '@features/core/form/utils/patterns';

import checkEmail from '@common/api/registration/checkEmail';

const usedEmailValidator = (rule, value): Promise<void> => {
  return checkEmail(value).then(res => {
    if (res instanceof CustomError || res?.email_exists) {
      return Promise.reject(rule.message);
    }
    return Promise.resolve();
  });
};

const emailPreset = (checkUsedEmail = true): Rule[] => [
  {
    required: true,
    message: `${i18next.t('registration.step1.email_required')}`,
  },
  {
    pattern: Patterns.EMAIL,
    message: i18next.t('registration.step1.invalid_email_sign_Incomplete'),
  },
  ...(checkUsedEmail
    ? [
        {
          validator: usedEmailValidator,
          message: i18next.t('registration.step1.email_taken'),
        },
      ]
    : []),
];

export default emailPreset;

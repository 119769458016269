import styled from 'styled-components';

import { Button } from '@ui/components/buttons/index';

// eslint-disable-next-line import/prefer-default-export
export const StyledButton = styled(Button)`
  display: flex;
  align-content: center;
  justify-content: center;
  align-items: center;

  .loader {
    width: 20px;
    height: 20px;
    border: 2px solid ${({ theme }) => theme.loaderShadow};
    border-bottom-color: transparent;
    border-radius: 50%;
    display: inline-block;
    box-sizing: border-box;
    -webkit-animation: rotation 1s linear infinite;
    animation: rotation 1s linear infinite;
    margin: 0;

    @keyframes rotation {
      0% {
        transform: rotate(0deg);
      }

      100% {
        transform: rotate(360deg);
      }
    }
  }

  &.with-icon {
    display: grid;
    grid-auto-flow: column;
    grid-auto-columns: auto;
    justify-content: center;
    align-items: center;
    justify-items: center;

    & svg {
      margin-left: ${({ theme }) => theme.space_5};
    }
  }

  &.with-icon.single-icon {
    min-width: 42px;

    & svg {
      margin: 0;
    }
  }
`;

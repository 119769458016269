import React from 'react';
import map from 'lodash/map';
import includes from 'lodash/includes';
import { useTranslation } from 'react-i18next';

import BetStatusSquare from '@features/bets/components/betStatusSquare/BetStatusSquare';

import CombiMatrixModel from '@common/helpers/combinations/combiMatrixModel';
import { IBetDetail } from '@common/interfaces';

import * as S from './CombiMatrix.styled';

const MatrixItem: React.FC<{
  matrix: number[][];
  iteration: number;
  bet: IBetDetail;
}> = ({ matrix, iteration, bet }) => {
  const { t } = useTranslation();
  const model = new CombiMatrixModel({ bet });
  CombiMatrixModel.resetLetter();
  let start = iteration ? model.getMatrixLength() * iteration : 0;
  const bets = model.getBets();

  return (
    <S.Table key={iteration}>
      <S.TableHeader>
        <S.TableRow>
          <S.TableTitle>{t('bets.selection_short')}</S.TableTitle>

          {map(matrix, (leg, index: number) => (
            <S.TableTitle key={index}>{++start}</S.TableTitle>
          ))}
        </S.TableRow>
      </S.TableHeader>

      <S.TableBody>
        {map(bets, (selection, index) => (
          <S.TableRow key={index}>
            <S.TableCell>{CombiMatrixModel.getNextLetter()}</S.TableCell>

            {map(matrix, (legs, key) => (
              <S.TableCell
                key={key}
                status={
                  includes(legs, index) && model.getLegGroupStatus(legs, index)
                }
              >
                <S.TableItemWrapper>
                  {includes(legs, index) && (
                    <BetStatusSquare
                      status={model.getLegGroupStatus(legs, index)}
                      size="16px"
                    />
                  )}
                </S.TableItemWrapper>
              </S.TableCell>
            ))}
          </S.TableRow>
        ))}
      </S.TableBody>
    </S.Table>
  );
};

export default MatrixItem;

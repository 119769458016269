import React from 'react';
import { useTranslation } from 'react-i18next';
import { matchPath, useLocation } from 'react-router-dom';
import toUpper from 'lodash/toUpper';

import { PageName } from '@features/core/routing/linkAliases';

import { appendLanguage, setLocation } from '@common/providers/router/helper';
import { isDesktopView, isLScreenWidth } from '@common/helpers/deviceUtil';
import { DEFAULT_SELECTED_SPORT_CATEGORY } from '@common/providers/events/liveCategories/state';
import { getItemFilterQuery } from '@common/helpers/categories/categoriesModel';
import { setSportCategoriesFilter } from '@common/providers/events/liveCategories/useLiveCategories';

import * as S from './TimeFilters.styled';

const isDesktop = isDesktopView();

const TimeFilters = (props: {
  currentFilter: string;
  onChange?: () => void;
}): React.ReactElement => {
  const { t } = useTranslation();
  const { currentFilter, onChange } = props;
  const location = useLocation();

  const handleClick = (e): void => {
    const filter = e.currentTarget.value || e.currentTarget.dataset.value;
    setSportCategoriesFilter({ filter });

    if (isDesktop) {
      const match = matchPath<{ categoryId: 'string' }>(location.pathname, {
        path: appendLanguage('/events/category/:categoryId'),
      });
      const categoryId: string =
        match?.params.categoryId || DEFAULT_SELECTED_SPORT_CATEGORY[0];
      const urlParams: URLSearchParams = new URLSearchParams(
        window.location.search,
      );
      const categoriesQuery: string = urlParams.get('categories') || '';
      const filterQuery: string = getItemFilterQuery(filter);
      const search: string = categoriesQuery
        ? `?categories=${categoriesQuery}`
        : ``;

      setLocation(
        PageName.EVENTS_CATEGORY,
        { categoryId, filter: filterQuery },
        { search },
      );
    }

    if (onChange) {
      onChange();
    }
  };

  return (
    <S.ButtonsWrapper
      data-qa="time-filter-wrapper"
      isBigScreen={isLScreenWidth}
    >
      {!isLScreenWidth ? (
        <>
          <S.StyledButton
            active={currentFilter === ''}
            data-value=""
            onClick={handleClick}
            data-qa="time-filter-all"
          >
            {t('events.filters.all')}
          </S.StyledButton>
          <S.StyledButton
            active={currentFilter === '24h'}
            data-value="24h"
            onClick={handleClick}
            data-qa="time-filter-24h"
          >
            <span>{toUpper(t('events.filters.24h'))}</span>
          </S.StyledButton>
          <S.StyledButton
            active={currentFilter === '3h'}
            data-value="3h"
            onClick={handleClick}
            data-qa="time-filter-3h"
          >
            <span>{toUpper(t('events.filters.3h'))}</span>
          </S.StyledButton>
        </>
      ) : (
        <S.SelectInner>
          <S.StyledSelect onChange={handleClick}>
            <option value="" data-qa="time-filter-all">
              {t('events.filters.all')}
            </option>
            <option value="24h" data-qa="time-filter-24h">
              {toUpper(t('events.filters.24h'))}
            </option>
            <option value="3h" data-qa="time-filter-3h">
              {toUpper(t('events.filters.3h'))}
            </option>
          </S.StyledSelect>
          <S.StyledArrow />
        </S.SelectInner>
      )}
    </S.ButtonsWrapper>
  );
};

TimeFilters.defaultProps = {
  onChange: undefined,
};

export default TimeFilters;

import styled from 'styled-components';

export const TextNormal = styled.p`
  font-size: ${props => props.theme.fontMD};
  margin: 0;
`;

export const TextSM = styled.p`
  font-size: ${props => props.theme.fontSM};
  margin: 0;
`;

export const TextLarge = styled.p`
  font-size: ${props => props.theme.fontLG};
  margin: 0;
`;

export const TextXLarge = styled.p`
  font-size: ${props => props.theme.fontXL};
  margin: 0;
`;

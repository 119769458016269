/* eslint-disable dot-notation */
/* eslint-disable no-param-reassign */
import axios from 'axios';
import qs from 'qs';
import has from 'lodash/has';
import get from 'lodash/get';
import isString from 'lodash/isString';
import includes from 'lodash/includes';

import services from '@features/core/services';
import isExternalLink from '@features/core/routing/isExternalLink';

import { ENV, PROXY_PORT, PROXY_URL } from '@common/constants/config';

let globalAbortController: AbortController | null = new AbortController();
const baseUrl = `${services.config.get(PROXY_URL)}:${services.config.get(
  PROXY_PORT,
)}`;

axios.defaults.timeout = 30000;
axios.defaults.responseType = 'json';
axios.defaults.headers.common['Content-Type'] = 'application/json';
axios.defaults.withCredentials = false;
axios.defaults.paramsSerializer = (params): string =>
  qs.stringify(params, { arrayFormat: 'repeat' });

if (
  window.location.hostname === 'localhost' ||
  window.location.hostname === '127.0.0.1' ||
  includes(window.location.hostname, '192.168.')
) {
  axios.defaults.baseURL = baseUrl;
}

const globalCancelTokenSource = axios.CancelToken.source();

window.addEventListener('load', () => {
  globalAbortController = new AbortController();
});

// this event is triggered if a user tries to open the link with 'tel:', it leads to bugs
// window.addEventListener('beforeunload', () => {
//   globalAbortController?.abort();
//   globalCancelTokenSource.cancel('Cansel');
// });

axios.interceptors.request.use(
  config => {
    const hasToken = has(config.params, 'token');
    const requireToken = get(config.params, 'token');

    config['signal'] = globalAbortController?.signal;
    config['metadata'] = { startTime: new Date() };
    config.cancelToken = globalCancelTokenSource.token;

    config.withCredentials =
      services.config.get(ENV) === 'development' &&
      !isExternalLink(config.url as string);

    if (hasToken && !requireToken) {
      services.logger.log(`Api ${config.url} detected without param {token}`);
      return config;
    }

    return config;
  },
  error => {
    return Promise.reject(error);
  },
);
axios.interceptors.response.use(
  async response => {
    response.config['metadata'].endTime = new Date();
    response['duration'] =
      response.config['metadata'].endTime -
      response.config['metadata'].startTime;
    return response;
  },
  error => {
    if (error?.config) {
      error.config.metadata.endTime = new Date();
      error.duration =
        error.config.metadata.endTime - error.config.metadata.startTime;
    }
    return Promise.reject(error);
  },
);

axios.defaults.transformResponse = [
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  (data, responseHeaders): any => {
    const contentType = responseHeaders && responseHeaders['content-type'];
    if (
      isString(data) &&
      (!contentType ||
        (includes(contentType, 'text/') &&
          includes(contentType, 'application/xhtml+xml') &&
          contentType !== 'application/xml' &&
          contentType !== 'application/octet-stream' &&
          contentType !== 'application/javascript') ||
        includes(contentType, 'application/json'))
    ) {
      try {
        return JSON.parse(data);
      } catch (e) {
        services.logger.log(`Not able to parse response ${e}`);
      }
      return data;
    }
    return data;
  },
];

export default axios;

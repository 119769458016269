import services from '@features/core/services';

import { RESEND_CONFIRMATION } from '@common/constants/cookie';
import { hasNoVerifiedEmail } from '@common/helpers/paymentsHelper/paymentModel';
import { IUser } from '@common/interfaces';

/**
 * hasResendLink
 *
 * @param {IUser | null} user
 * @returns {Promise<boolean>} hasResendLink
 */
export const hasResendLink = (user: IUser): boolean =>
  hasNoVerifiedEmail(user) && !services.cookie.get(RESEND_CONFIRMATION);

export default hasResendLink;

import { IGame, ICustomError, ICasinoSearchParams } from '@common/interfaces';
import { StaticCalls } from '@common/api/api';
import { apiHandler, MethodType } from '@common/api/apiHandler';

const { API_CASINO_SEARCH } = StaticCalls;

export const searchCasino = async (
  params: ICasinoSearchParams,
): Promise<IGame[] & ICustomError> =>
  apiHandler<IGame[]>(
    { method: MethodType.Get, params },
    {
      apiUrl: API_CASINO_SEARCH,
    },
  );

export default searchCasino;

import { IFetchStatisticalData } from '@common/interfaces';
import apiRpcRequest from '@common/api/common/apiCommonRequest';
import { MethodType } from '@common/api/apiHandler';

const fetchStatisticalData = (): Promise<IFetchStatisticalData> => {
  const params = {
    method: 'user.information_statistical_data',
  };

  return apiRpcRequest({ method: MethodType.Get, params }) as Promise<
    IFetchStatisticalData
  >;
};

export default fetchStatisticalData;

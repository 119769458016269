import uniq from 'lodash/uniq';
import concat from 'lodash/concat';
import includes from 'lodash/includes';
import difference from 'lodash/difference';
import intersection from 'lodash/intersection';

import { PageName } from '@features/core/routing';

import { isDesktopView } from '@common/helpers/deviceUtil';
import { setLocation } from '@common/providers/router/helper';
import {
  EventListSort,
  EventListTypes,
  EventTimeFilters,
  ILiveSportCategoriesTreeData,
  LiveStatus,
} from '@common/interfaces';
import { IGetEventsListPayload } from '@common/providers/events/eventList/types';
import {
  extendRequestCategories,
  getAllChildrenIds,
  getIdsPathToCategory,
  getItemFilterQuery,
} from '@common/helpers/categories/categoriesModel';
import { filterToTimestamp } from '@common/helpers/eventsHelper/scoreboards/timerModel';
import { useLiveCategories } from '@common/providers/events/liveCategories/useLiveCategories';

const isDesktop = isDesktopView();

/**
 * redirectToCheckedItems
 *
 * @param {string[]} newCheckedCategories
 * @param {ILiveSportCategoriesTreeData} item
 * @param {any} timeFilter
 * @param {any} sportCategoriesTree
 * @returns {void}
 */
const redirectToCheckedItems = (
  newCheckedCategories: string[],
  item: ILiveSportCategoriesTreeData,
  timeFilter,
  sportCategoriesTree,
): void => {
  const search: string = newCheckedCategories.length
    ? `?categories=${newCheckedCategories.join(',')}`
    : ``;

  const rootItem = getIdsPathToCategory(sportCategoriesTree, item.id)[0];

  if (!newCheckedCategories.length) {
    setLocation(PageName.HOME, {}, { forceReload: true });
  } else {
    setLocation(
      PageName.EVENTS_CATEGORY,
      { categoryId: rootItem, filter: getItemFilterQuery(timeFilter) },
      { forceReload: true, search },
    );
  }
};

/**
 *
 * @param {ILiveSportCategoriesTreeData} item
 * @param {any} checkedCategories
 * @param {any} timeFilter
 * @param {any} sportCategoriesTree
 * @returns {void}
 */
const checkItem = (
  item: ILiveSportCategoriesTreeData,
  checkedCategories,
  timeFilter,
  sportCategoriesTree,
): void => {
  if (isDesktop) {
    // if click on title item

    if (item.children?.length) {
      const childrenCategories: string[] = getAllChildrenIds(item);

      const hasCheckedChildren = intersection(
        checkedCategories,
        childrenCategories,
      ).length;

      const newCheckedCategories: string[] = hasCheckedChildren
        ? difference(checkedCategories, childrenCategories)
        : uniq(concat(childrenCategories, checkedCategories));

      redirectToCheckedItems(
        newCheckedCategories,
        item,
        timeFilter,
        sportCategoriesTree,
      );
    } else {
      const hasCheckedCategories = includes(checkedCategories, item.id);

      const newCheckedCategories: string[] = hasCheckedCategories
        ? difference(checkedCategories, [item.id])
        : uniq(concat([item.id], checkedCategories));

      redirectToCheckedItems(
        newCheckedCategories,
        item,
        timeFilter,
        sportCategoriesTree,
      );
    }
  }
};

export const getOptions = ({
  listType = EventListTypes.live,
  offset,
  filter = 'next24hrs',
}: {
  listType?: keyof typeof EventListTypes;
  offset: string;
  filter?: keyof typeof EventTimeFilters;
}): IGetEventsListPayload => {
  const liveCategoriesSelected = useLiveCategories.getState()
    .selectedCategories;
  const date_to = filterToTimestamp(filter);
  const categoriesToFetch = extendRequestCategories(liveCategoriesSelected);
  return {
    categories: categoriesToFetch,
    date_to,
    offset: parseInt(offset, 10) || 0,
    live_status: [LiveStatus.open, LiveStatus.suspended, LiveStatus.future],
    listType,
    sort: EventListSort.liveHighlights,
  };
};

export default checkItem;

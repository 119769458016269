import keys from 'lodash/keys';
import forEach from 'lodash/forEach';
import { createWithEqualityFn } from 'zustand/traditional';
import { shallow } from 'zustand/shallow';

import services from '@features/core/services';

import {
  FAVORITES_CATEGORIES_COOKIE,
  FAVORITES_EVENTS_COOKIE,
} from '@common/constants/cookie';
import { createExpiresStamp } from '@common/helpers/deviceUtil';
import { checkChildrenOfParent } from '@common/helpers/categories/categoriesModel';
import { useLiveCategories } from '@common/providers/events/liveCategories/useLiveCategories';
import { IToggleFavorites } from '@common/interfaces';

import defaultState from './state';

export const useFavoritesState = createWithEqualityFn(
  () => defaultState,
  shallow,
);

export const toggleFavorites = ({ id, type }: IToggleFavorites): void => {
  useFavoritesState.setState(state => {
    const key = type === 'event' ? 'Events' : 'Categories';

    const updatedFavorites = { ...state[`favorites${key}`] };

    const existInFavorites = updatedFavorites[id] !== undefined;
    const idParentMap = useLiveCategories.getState().flatSportCatTree;

    const catChildrenExist = checkChildrenOfParent(
      id,
      updatedFavorites,
      idParentMap,
    );

    if (catChildrenExist.length) {
      forEach(catChildrenExist, childId => {
        delete updatedFavorites[childId];
      });
    }

    if (!existInFavorites) {
      updatedFavorites[id] = id;
    } else {
      delete updatedFavorites[id];
    }

    services.cookie.set(
      key === 'Events' ? FAVORITES_EVENTS_COOKIE : FAVORITES_CATEGORIES_COOKIE,
      JSON.stringify(keys(updatedFavorites)),
      { expires: createExpiresStamp(60 * 60 * 24 * 365) },
    );

    return { [`favorites${key}`]: updatedFavorites };
  });
};

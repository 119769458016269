import styled from 'styled-components';

export const Scoreboard = styled.div`
  display: grid;
  grid-auto-flow: column;
  font-weight: ${({ theme }) => theme.fontBold};
  height: 100%;

  & > div {
    align-items: center;
  }

  @media screen and (min-width: 481px) {
    margin-right: ${({ theme }) => theme.space_5};

    & > div {
      min-width: 8px;
    }
  }

  & > div:first-child {
    border-left: ${({ theme }) => theme.border};
  }

  &.tennis {
    & > div {
      &:last-child {
        font-weight: ${({ theme }) => theme.fontRegular};
      }
    }
  }

  @media screen and (min-width: 768px) and (max-width: 1200px) {
    font-size: ${({ theme }) => theme.fontSM};
    padding-right: ${({ theme }) => theme.space_5};

    & > div {
      max-width: 16px;
    }
  }
`;

export const getPeriodExtra = (isFirst, color) => {
  if (!isFirst) {
    return '';
  }
  return `color: ${color};`;
};

export const Period = styled.div<{ isFirst?: boolean }>`
  justify-content: center;
  display: grid;
  grid-auto-flow: row;
  ${({ isFirst, theme }) => getPeriodExtra(isFirst, theme.textMain)}
`;

export const getCellExtra = (isFirst, isLast, firstColor, lastColor, theme) => {
  if (isFirst) {
    return `
    font-weight: ${theme.fontBold};
    color: ${firstColor};
  `;
  }

  if (isLast) {
    return `color: ${lastColor};`;
  }

  return '';
};

export const Cell = styled.div<{
  isFirst: boolean;
  isLast?: boolean;
  fontWeightNormal?: boolean;
}>`
  height: 19px;
  display: grid;
  grid-auto-flow: column;
  justify-content: center;
  align-items: center;
  color: ${props => props.theme.textMain};
  ${({ isFirst, isLast, theme }) =>
    getCellExtra(isFirst, isLast, theme.accent, theme.textMain, theme)};

  @media screen and (min-width: 481px) {
    min-width: ${({ theme }) => (!theme.isDesktop ? '18px' : '22px')};
  }

  @media screen and (max-width: 481px) {
    min-width: 16px;
  }
`;

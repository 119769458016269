import styled from 'styled-components';

export const Root = styled.div`
  display: grid;
  grid-template-columns: min-content;
  margin-bottom: ${({ theme }) => theme.space_5};
`;

const getCardsMargin = ({ theme }) => `margin: 0 ${theme.space_10};`;

export const Cards = styled.div<{ isDetailEvent: boolean }>`
  display: grid;
  grid-auto-flow: column;
  ${({ isDetailEvent }) => !isDetailEvent && getCardsMargin};
`;

export const CardContainer = styled.div<{
  hasCard: boolean;
  isDetailEvent: boolean;
}>`
  display: grid;
  grid-auto-flow: column;
  align-items: center;
  opacity: ${({ hasCard }) => (hasCard ? '1' : '0')};
  /* stylelint-disable-next-line declaration-block-no-duplicate-properties */
  ${({ hasCard, isDetailEvent }) =>
    !hasCard && isDetailEvent ? 'display: none' : ''};
  height: max-content;

  &:first-child {
    margin-right: ${({ theme }) => theme.space_5};
  }
`;

export const Card = styled.div<{ type: 'yellow' | 'red' }>`
  height: 12px;
  width: 8px;
  background-color: ${({ type, theme }) =>
    type === 'yellow' ? theme.accent : theme.statusError};
  position: relative;
  right: 2px;
  border-radius: 2px;
`;

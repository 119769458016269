import styled from 'styled-components';

import { StatusCode } from '@features/bets/components/betStatusSquare/BetStatusSquare.types';

const StatusSquare = styled.div<{ colorSquare?: number }>`
  display: grid;
  grid-auto-flow: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 20px;
  color: ${({ theme }) => theme.textMain};
  background: ${({ colorSquare, theme }) => {
    switch (colorSquare) {
      case StatusCode.WON:
        return theme.statusWon;
      case StatusCode.CANCELLED:
        return theme.statusClosed;
      case StatusCode.OPEN:
        return theme.statusOpen;
      case StatusCode.LOST:
        return theme.statusLost;
      default:
        return '';
    }
  }};
`;
export default StatusSquare;

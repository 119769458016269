import styled from 'styled-components';

// eslint-disable-next-line import/prefer-default-export
export const SwitcherWrapper = styled.div`
  & select {
    min-width: 150px;
    border-color: ${({ theme }) => theme.textMain};
    color: ${({ theme }) => theme.inputDefaultText};
    font-weight: ${({ theme }) => theme.fontBold};
    text-align: center;
  }
`;

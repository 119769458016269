import DefaultTimerFactory from '@features/events/components/scoreboards/timers/defaultTimerFactory/DefaultTimerFactory';

import {
  getCurrentPeriod,
  formatTimeWithAdditionMinutes,
  getElapsedTime,
  getDuration,
} from '@common/helpers/eventsHelper/scoreboards/timerModel';

const options = [
  ({ timer, showTime, shortTimer, category }): string =>
    showTime && getCurrentPeriod(timer, shortTimer, category),
  ({ timer, showTime }): string =>
    showTime &&
    formatTimeWithAdditionMinutes(getElapsedTime(timer), getDuration(timer)),
];

const VirtualTimer = DefaultTimerFactory(options);

export default VirtualTimer;

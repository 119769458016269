import styled from 'styled-components';

const BettingslipWrapperContainter = styled.div`
  &.is_terminal {
    display: flex;
    flex-direction: column;
    height: 100%;

    &:has(> div:empty) {
      height: auto;
    }
  }
`;
export default BettingslipWrapperContainter;

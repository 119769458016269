import includes from 'lodash/includes';

import services from '@features/core/services';

import { HAS_CREATE_ONLINE_ACCOUNT } from '@common/constants/config';
import { IUser } from '@common/interfaces';

/**
 * mustCreateOnlineAccount
 *
 * @param {IUser} user
 * @returns {boolean} mustCreateOnlineAccount
 */
export const mustCreateOnlineAccount = (user: IUser): boolean => {
  return (
    user.hasOnlineAccount === '0' &&
    (services.config.get(HAS_CREATE_ONLINE_ACCOUNT) as boolean) &&
    !includes(user.lineage, '19087') &&
    (user.countryCode === 'DE' ||
      user.countryCode === 'AT' ||
      user.countryCode === 'CH')
  );
};

export default mustCreateOnlineAccount;

import styled from 'styled-components';

export const Wrapper = styled.div`
  display: grid;
  grid-auto-flow: column;
  padding: ${({ theme }) => theme.space_10} 0;

  .digits {
    font-size: ${props => props.theme.fontLG};
  }
`;

export const StyledBoldText = styled.b`
  font-size: ${props => props.theme.fontLG};
`;

export const InfoLeft = styled.div`
  display: grid;
  justify-items: start;
`;

export const InfoRight = styled.div`
  display: grid;
  justify-items: end;
`;

export const BetStatLabel = styled.div`
  margin-bottom: ${props => props.theme.space_5};
`;

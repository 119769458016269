import {
  IHighlightsState,
  ICustomError,
  IMarketType,
} from '@common/interfaces';

import { ICategory } from './ICategories';
import { IPagination } from './IPagination';

export enum EventTimeFilters {
  '1w' = '1w',
  '3d' = '3d',
  '24h' = '24h',
  'next24hrs' = 'next24hrs',
  '3h' = '3h',
  'next3hrs' = 'next3hrs',
  'today' = 'today',
}

export interface ILiveSportCategoriesTreeData {
  count_3d?: string | null;
  level: string;
  path?: string;
  count?: string | null;
  count_today?: string | null;
  detail: string;
  location: string;
  top_category_id?: string | null;
  count_24h?: string | null;
  id: string;
  parent_id?: string;
  count_today_coming_live?: string | null;
  count_live?: string | null;
  children?: ILiveSportCategoriesTreeData[] | null;
  cid: string;
  label: string;
  is_only_longterm?: boolean;
  is_only_regular?: boolean;
  count_live_now?: string;
}

export interface IdParentMap {
  [id: string]: {
    parent_id: string | null;
  };
}

export interface IChildNParentIds {
  incomeId: string;
  parentIds: string[];
}

export interface ILiveSportCategoriesTree {
  selectedPath: string[];
  showPanel: boolean;
  data: ILiveSportCategoriesTreeData[];
  selectedCategoryEvents: { [key: string]: string[] | unknown };
  filterCategory: string;
  results: string[];
  schedule: {
    dates: string[];
  };
  filter: keyof typeof EventTimeFilters | '';
  loading: boolean;
  error: ICustomError | null;
}

export interface ILiveCategoriesData {
  id: string;
  cid: string;
  path?: string;
  label: string;
  count_live?: string | null;
}

export interface ILiveCategories {
  data: ILiveCategoriesData[];
  showTestList: boolean;
  selectedCategories: string[];
  selectedSportCategory: string[];
  marketTypeChecked: IMarketType;
  sportCategoriesTree: ILiveSportCategoriesTree;
  highlightsMeta: IHighlightsState;
  selectedCategoryTypeTab: string;
  flatSportCatTree: IdParentMap | object;
}

export interface ILiveUnwrappedProps {
  sortedEventIds: string[];
  categories: Record<string, ICategory>;
  pagination?: IPagination;
  loading: boolean;
  openAppVersion: number;
}

import { IBonusState } from '@common/providers/bonus/types';

const defaultState: IBonusState = {
  bonusList: [],
  activeBonus: null,
  loading: false,
  error: null,
};

export default defaultState;

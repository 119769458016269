import styled from 'styled-components';

export const NoFavoriteTextWrapper = styled.div`
  div {
    background-color: ${({ theme }) => theme.elementNuanceColor};
    font-size: ${props => props.theme.fontMD};
  }

  p {
    color: ${props => props.theme.textMain};
    margin: ${({ theme }) => theme.space_10} 0;
  }
`;

export const NoFavoriteText = styled.div`
  margin: 0 ${({ theme }) => theme.space_10};
`;

import services from '@features/core/services';

import { MarketingEvents } from '@packages/events/appEvents';

import {
  onRegistrationStep,
  onPageView,
  onLogin,
  onBet,
  onDeposit,
  onWithdrawal,
  onSelection,
  onDetailEventView,
} from '@common/helpers/marketing';

export const marketingEventHandler = (): void => {
  services.events.addEventListener(
    MarketingEvents.REGISTRATION_STEP,
    onRegistrationStep,
  );
  services.events.addEventListener(MarketingEvents.PAGE_VIEW, onPageView);
  services.events.addEventListener(MarketingEvents.USER_LOGIN, onLogin);
  services.events.addEventListener(MarketingEvents.BET, onBet);
  services.events.addEventListener(MarketingEvents.DEPOSIT, onDeposit);
  services.events.addEventListener(MarketingEvents.WITHDRAWAL, onWithdrawal);
  services.events.addEventListener(MarketingEvents.SELECTION, onSelection);
  services.events.addEventListener(
    MarketingEvents.DETAIL_EVENT_VIEW,
    onDetailEventView,
  );
};

export default marketingEventHandler;

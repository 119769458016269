import map from 'lodash/map';

import CustomError from '@features/core/error/error';

import { IGetTransactionsPayload } from '@common/providers/transactions/types';
import fetchTransactions from '@common/api/transactions/fetchTransactions';
import { loadPendingCashouts } from '@common/helpers/betsHelper';
import {
  setTransactions,
  setTransactionsLoading,
} from '@common/providers/transactions/useTransactions';
import { ITransaction } from '@common/interfaces';
import addLocaleTimeFromDateString from '@common/helpers/timeHelper/addLocaleTimeFromDateString';
import filterTransactionsByDate from '@common/helpers/transactions/filterTransactionsByDate';

const getTransactionsAction = async (
  payload: IGetTransactionsPayload,
): Promise<void> => {
  setTransactionsLoading(true);
  const response = await fetchTransactions(payload);
  let pendingCashouts = [] as ITransaction[];

  const initialLoad = payload.offset === 0;
  if (initialLoad) {
    pendingCashouts = await loadPendingCashouts();
  }

  if (response instanceof CustomError) {
    return setTransactions({
      ...payload,
      transactions: [],
      cnt_rows: '',
    });
  }

  let transactionsWithLocaleTime = map(response.transactions, it => ({
    ...it,
    ...addLocaleTimeFromDateString(it.timestamp),
  }));

  if (payload.date_min && payload.date_max) {
    pendingCashouts = filterTransactionsByDate(
      pendingCashouts,
      payload.date_min,
      payload.date_max,
    );
    transactionsWithLocaleTime = filterTransactionsByDate(
      transactionsWithLocaleTime,
      payload.date_min,
      payload.date_max,
    );
  }

  return setTransactions({
    ...payload,
    ...response,
    transactions: [...pendingCashouts, ...transactionsWithLocaleTime],
  });
};

export default getTransactionsAction;

import React from 'react';

import ITab from './Tab.types';

/* eslint-disable */
const Tab: React.FC<ITab> = ({ title, tabIndex, children, dataQa = null }) => {
  return <>{children}</>;
};
/* eslint-enable */

export default Tab;

import React from 'react';

import {
  ScoreboardHoc,
  TotalScore,
} from '@features/events/components/scoreboards/scores';
import {
  ItemDiv,
  ScoreboardDiv,
  ScoreDiv,
  ICommonScoreProps,
} from '@features/events/components/scoreboards/factoryScoreboards';
import AnimatedScoreValue from '@features/events/components/scoreboards/scores/common/AnimatedScoreValue';

import {
  getLegScore,
  getLegs,
  getMarkedScore,
} from '@common/helpers/eventsHelper/scoreboards/darts';

import * as S from './DartsScore.styled';

const WORLD_CUP_CATEGORY_ID = '24503';

export const DartsScore = (props: ICommonScoreProps): React.ReactElement => {
  const { score, event } = props;

  return (
    <S.Wrapper>
      <ScoreboardDiv className="darts-scoreboard">
        <TotalScore {...score} />
        {event.category_id === WORLD_CUP_CATEGORY_ID && (
          <ItemDiv className="darts-score">
            <ScoreDiv>
              <AnimatedScoreValue score={getLegs(score)[0]} />
            </ScoreDiv>
            <ScoreDiv>
              <AnimatedScoreValue score={getLegs(score)[1]} />
            </ScoreDiv>
          </ItemDiv>
        )}

        <ItemDiv className="darts-score">
          <ScoreDiv isMarked={getMarkedScore(score, 0, 1)}>
            <AnimatedScoreValue score={getLegScore(score)?.[0]} />
          </ScoreDiv>
          <ScoreDiv isMarked={getMarkedScore(score, 1, 0)}>
            <AnimatedScoreValue score={getLegScore(score)?.[1]} />
          </ScoreDiv>
        </ItemDiv>
      </ScoreboardDiv>
    </S.Wrapper>
  );
};

export default ScoreboardHoc(DartsScore);

import React from 'react';
import isString from 'lodash/isString';

import { useThemeScheme } from '@features/core/theming/contexTheme';
import { useEventsModel } from '@features/events/useEventsModel';

import {
  getSuspendReasonIconPath,
  getSuspendReasonLabel,
} from '@common/helpers/eventsHelper/eventLabelHelper';
import { EventListTypes } from '@common/interfaces';
import isEventSuspended from '@common/helpers/eventsHelper/isEventSuspended';
import getImageBasePath from '@common/helpers/getImageBasePath';

import { Icon } from '@ui/components/icon';

import * as S from './SuspendReason.styled';
import { ISuspendReason } from './SuspendReason.types';

const SuspendReason = ({
  eventId,
  reasonType,
  marketKeylistCount = 0,
}: ISuspendReason): React.ReactElement | null => {
  const { event } = useEventsModel(eventId, reasonType);
  const isSuspended = isEventSuspended(event);
  const { theme } = useThemeScheme();

  if (!isSuspended) {
    return null;
  }

  const text = getSuspendReasonLabel(event);
  const reasonIconPath = getSuspendReasonIconPath(event);
  const isBettingSlip = reasonType === EventListTypes.bettingslip;
  const isCashout = reasonType === EventListTypes.cashout;

  return (
    <S.Wrapper
      isBettingSlip={isBettingSlip}
      isCashout={isCashout}
      marketKeylistCount={marketKeylistCount}
    >
      <S.Content
        isBettingSlip={isBettingSlip}
        isDefault={!reasonIconPath}
        isCashout={isCashout}
      >
        {reasonIconPath ? (
          <>
            <S.IconWrapper isBettingSlip={isBettingSlip}>
              <S.IconImage
                alt="overlay"
                src={getImageBasePath(reasonIconPath.path)}
                width={reasonIconPath.width}
              />
            </S.IconWrapper>

            <S.TextWrapper isBettingSlip={isBettingSlip}>
              <S.Text
                className="text-wrapper"
                isBettingSlip={isBettingSlip}
                isCashout={isCashout}
                dangerouslySetInnerHTML={{
                  __html: isString(text) ? text : '',
                }}
              />
            </S.TextWrapper>
          </>
        ) : (
          <S.DefaultIconWrapper>
            <S.IconWrapper isBettingSlip={isBettingSlip}>
              <Icon
                name="lock-outlined"
                width="20px"
                height="17"
                fill={theme.statusError}
              />
            </S.IconWrapper>
          </S.DefaultIconWrapper>
        )}
      </S.Content>
    </S.Wrapper>
  );
};

export default SuspendReason;

import replace from 'lodash/replace';
import sortBy from 'lodash/sortBy';
import split from 'lodash/split';
import includes from 'lodash/includes';
import filter from 'lodash/filter';
import toLower from 'lodash/toLower';
import head from 'lodash/head';
import reduce from 'lodash/reduce';
import take from 'lodash/take';

import services from '@features/core/services';
import i18n from '@features/core/translation';

import {
  getLiveStatus as getEventLiveStatus,
  isOvertime,
  isLiveFuture,
  isLiveDisabled,
  isWinner,
  isLiveCurrent,
} from '@common/helpers/eventsHelper/eventStatusHelper';
import { getSportName } from '@common/helpers/categories/categoriesModel';
import { hasBreakPeriod } from '@common/helpers/eventsHelper/scoreboards/timerModel';
import {
  IEvent,
  IMarket,
  IMarketType,
  IPrediction,
  LiveStatus,
  MarketType,
} from '@common/interfaces';
import { excludeMarkets } from '@common/helpers/markets/marketTypes';
import { useUserState } from '@common/providers/user/useUserState';

import {
  isBadminton,
  isBeachVolleyball,
  isTableTennis,
  isTennis,
  isVolleyball,
} from '../eventsHelper/eventTypeHelper';

// take numbers from market.period_type and turn them into names of real match periods
const periodTypesMap = {
  1: 'PERIOD_FULLTIME',
  2: 'PERIOD_HALFTIME',
  3: 'PERIOD_OVERTIME',
  4: 'PERIOD_PENALTIES',
  5: 'PERIOD_QUARTER',
  6: 'PERIOD_SET',
  7: 'PERIOD_GAME',
  8: 'PERIOD_HOLE',
  9: 'PERIOD_LAP',
  10: 'PERIOD_FT_INC_OT',
  11: 'PERIOD_THIRD',
  12: 'PERIOD_FT_INC_EI',
  13: 'PERIOD_INNING',
  14: 'PERIOD_FT_INC_OT_PEN',
};

const marketTypeMap = {
  [MarketType['3WAY_LIVE']]: MarketType['N-WAY'],
  [MarketType.HALFTIME]: MarketType['N-WAY'],
  [MarketType.FIRST_HALFTIME]: MarketType['N-WAY'],
  [MarketType['2WAY_LIVE']]: MarketType['N-WAY'],
  [MarketType.EUROPEAN_HANDICAP]: MarketType.HANDICAP,
  [MarketType.HANDICAP_LIVE]: MarketType.HANDICAP,
  [MarketType.OVER_UNDER_LIVE]: MarketType.OVER_UNDER,
  [MarketType.OVER_UNDER_FIRST_HALF]: MarketType.OVER_UNDER,
  [MarketType.OVER_UNDER_HT]: MarketType.OVER_UNDER,
  [MarketType.OVER_UNDER_OT]: MarketType.OVER_UNDER,
  [MarketType.OVER_UNDER_1_5]: MarketType.OVER_UNDER,
  [MarketType.OVER_UNDER_3_5]: MarketType.OVER_UNDER,
  [MarketType.OVER_UNDER_4_5]: MarketType.OVER_UNDER,
  [MarketType.FIRST_TEAM_TO_SCORE]: MarketType.XTH_GOAL,
  [MarketType.FIRST_TEAM_TO_SCORE_HT]: MarketType.XTH_GOAL,
  [MarketType.PERIOD_BASKETBALL]: MarketType.PERIOD,
  [MarketType.THREE_WAY_PERIOD]: MarketType.PERIOD,
  [MarketType.DOUBLE_CHANCE_LIVE]: MarketType.DOUBLE_CHANCE,
};

// special value map
const marketTypeLiveMapSV = {
  [MarketType.OVER_UNDER]: true,
  [MarketType.OVER_UNDER_HT]: true,
  [MarketType.OVER_UNDER_LIVE]: true,
  [MarketType.HANDICAP]: true,
  [MarketType.HANDICAP_2WAY]: true,
  [MarketType.HANDICAP_LIVE]: true,
  [MarketType.OVER_UNDER_FIRST_HALF]: true,
  [MarketType.HANDICAP_HT]: true,
  [MarketType.SET]: true,
  [MarketType.OVER_UNDER_THIRD]: true,
  [MarketType['3WAY_LIVE']]: true,
  [MarketType.TOTAL_LEGS]: true,
  [MarketType.WHO_WINS_IN_LEG]: true,
  [MarketType.FIRST_SET]: true,
  [MarketType.POINTS_SPREADS]: true,
  [MarketType.OVER_UNDER_QUARTER]: true,
  [MarketType.TOTAL_SPREADS_LIVE]: true,
  [MarketType.TOTAL_SPREADS]: true,
  [MarketType.OVER_UNDER_INNING]: true,
};

export const mapMarketKeyToMarketType = {
  football: {
    [MarketType['3WAY_LIVE']]: MarketType['3WAY'],
    [MarketType.XTH_GOAL_HT]: MarketType.XTH_GOAL,
    [MarketType.REST_OF_MATCH_HT]: MarketType.REST_OF_MATCH,
    [MarketType.OVER_UNDER_HT]: MarketType.OVER_UNDER_FIRST_HALF,
    [MarketType.HANDICAP]: MarketType.EUROPEAN_HANDICAP,
    [MarketType.HANDICAP_HT]: MarketType.EUROPEAN_HANDICAP_HT,
  },
  american: {
    [MarketType.HANDICAP_2WAY]: MarketType.POINTS_SPREADS,
    [MarketType.PERIOD_QUARTER]: MarketType.THREE_WAY_PERIOD,
    [MarketType.OVER_UNDER]: MarketType.TOTAL_SPREADS,
    [MarketType.OVER_UNDER_HT]: MarketType.OVER_UNDER,
  },
  icehockey: {
    [MarketType.HANDICAP]: MarketType.EUROPEAN_HANDICAP,
    [MarketType.PERIOD_THIRD]: MarketType.THREE_WAY_PERIOD,
    [MarketType.OVER_UNDER_HT]: MarketType.OVER_UNDER,
    [MarketType.OVER_UNDER_THIRD]: MarketType.OVER_UNDER,
  },
  fieldshockey: {
    [MarketType.HANDICAP]: MarketType.POINTS_SPREADS,
    [MarketType.HANDICAP_HT]: MarketType.EUROPEAN_HANDICAP_HT,
    [MarketType.OVER_UNDER_HT]: MarketType.OVER_UNDER,
    [MarketType.XTH_GOAL_HT]: MarketType.XTH_GOAL,
  },
  darts: {
    [MarketType.OVER_UNDER]: MarketType.TOTAL_LEGS,
    [MarketType.TOTAL_LEGS]: MarketType.WHO_WINS_IN_LEG,
  },
  handball: {
    [MarketType.POINTS_SPREADS]: MarketType.WHICH_WINS_X_POINTS,
    [MarketType['3WAY_LIVE']]: MarketType.FIRST_HALFTIME,
    [MarketType.HANDICAP_2WAY]: MarketType.POINTS_SPREADS,
  },
  rugby: {
    [MarketType.HANDICAP]: MarketType.EUROPEAN_HANDICAP,
    [MarketType.HANDICAP_HT]: MarketType.EUROPEAN_HANDICAP,
    [MarketType.REST_OF_MATCH_HT]: MarketType.REST_OF_MATCH,
  },
  basketball: {
    [MarketType.HANDICAP]: MarketType.POINTS_SPREADS,
    [MarketType.OVER_UNDER]: MarketType.TOTAL_SPREADS,
    [MarketType.PERIOD_QUARTER]: MarketType.THREE_WAY_PERIOD,
    [MarketType.OVER_UNDER_QUARTER]: MarketType.OVER_UNDER,
    [MarketType['3WAY']]: MarketType['2WAY'],
  },
  aussierules: {
    [MarketType.HANDICAP_2WAY]: MarketType.POINTS_SPREADS,
  },
  spreads: {
    [MarketType.OVER_UNDER]: MarketType.TOTAL_SPREADS,
    [MarketType.HANDICAP]: MarketType.POINTS_SPREADS,
    [MarketType.PERIOD_QUARTER]: MarketType.THREE_WAY_PERIOD,
    [MarketType.OVER_UNDER_HT]: MarketType.OVER_UNDER,
    [MarketType.OVER_UNDER_QUARTER]: MarketType.OVER_UNDER,
  },
  generic2way: {
    [MarketType.HANDICAP]: MarketType.POINTS_SPREADS,
  },
};

export const universalHTMarkets = {
  [MarketType.DOUBLE_CHANCE]: MarketType.DOUBLE_CHANCE,
  [MarketType.XTH_GOAL]: MarketType.XTH_GOAL,
  [MarketType.TOTALS_HOME_TEAM]: MarketType.TOTALS_HOME_TEAM,
  [MarketType.TOTALS_AWAY_TEAM]: MarketType.TOTALS_AWAY_TEAM,
  [MarketType.THREE_WAY_PERIOD]: MarketType.THREE_WAY_PERIOD,
  [MarketType.OVER_UNDER]: MarketType.OVER_UNDER,
};

export const detailedEventMarketGroups = {
  football: {
    FILTER_FULLTIME: {
      [MarketType['3WAY']]: MarketType['3WAY'],
      [MarketType.XTH_GOAL]: MarketType.XTH_GOAL,
      [MarketType.EUROPEAN_HANDICAP]: MarketType.EUROPEAN_HANDICAP,
      [MarketType.DOUBLE_CHANCE]: MarketType.DOUBLE_CHANCE,
      [MarketType.GOAL_NO_GOAL]: MarketType.GOAL_NO_GOAL,
      [MarketType.DRAW_NO_BET]: MarketType.DRAW_NO_BET,
      [MarketType.TOTAL_GOALS_AGGREGATED]: MarketType.TOTAL_GOALS_AGGREGATED,
      [MarketType.CORRECT_SCORE]: MarketType.CORRECT_SCORE,
      [MarketType['3WAY_OVER_UNDER']]: MarketType['3WAY_OVER_UNDER'],
      [MarketType.THREE_WAY_GOAL_NO_GOAL]: MarketType.THREE_WAY_GOAL_NO_GOAL,
      [MarketType.DOUBLE_CHANCE_GOAL_NO_GOAL]:
        MarketType.DOUBLE_CHANCE_GOAL_NO_GOAL,
      [MarketType.DOUBLE_CHANCE_OVER_UNDER]:
        MarketType.DOUBLE_CHANCE_OVER_UNDER,
    },
    FILTER_GOALS: {
      [MarketType.OVER_UNDER]: MarketType.OVER_UNDER,
      [MarketType.OVER_UNDER_FIRST_HALF]: MarketType.OVER_UNDER_FIRST_HALF,
      [MarketType.EXACT_NUMBER_OF_GOALS_HT]:
        MarketType.EXACT_NUMBER_OF_GOALS_HT,
      [MarketType.TOTAL_GOALS_AGGREGATED]: MarketType.TOTAL_GOALS_AGGREGATED,
      [MarketType.GOALS_HOME_TEAM]: MarketType.GOALS_HOME_TEAM,
      [MarketType.GOALS_AWAY_TEAM]: MarketType.GOALS_AWAY_TEAM,
      [MarketType.TOTALS_HOME_TEAM]: MarketType.TOTALS_HOME_TEAM,
      [MarketType.TOTALS_AWAY_TEAM]: MarketType.TOTALS_AWAY_TEAM,
      [MarketType.DOUBLE_CHANCE_OVER_UNDER]:
        MarketType.DOUBLE_CHANCE_OVER_UNDER,
    },
    HANDICAP: {
      [MarketType.HANDICAP]: MarketType.EUROPEAN_HANDICAP,
      [MarketType.EUROPEAN_HANDICAP_HT]: MarketType.EUROPEAN_HANDICAP_HT,
    },
    FILTER_NO_GOAL: {
      [MarketType.GOAL_NO_GOAL]: MarketType.GOAL_NO_GOAL,
      [MarketType.GOAL_NO_GOAL_HT]: MarketType.GOAL_NO_GOAL_HT,
      [MarketType.THREE_WAY_GOAL_NO_GOAL]: MarketType.THREE_WAY_GOAL_NO_GOAL,
      [MarketType.DOUBLE_CHANCE_GOAL_NO_GOAL]:
        MarketType.DOUBLE_CHANCE_GOAL_NO_GOAL,
    },
    HALFTIME_SHORT: {
      [MarketType.XTH_GOAL]: MarketType.XTH_GOAL,
      [MarketType.FIRST_HALFTIME]: MarketType.FIRST_HALFTIME,
      [MarketType.OVER_UNDER_FIRST_HALF]: MarketType.OVER_UNDER_FIRST_HALF,
      [MarketType.OVER_UNDER]: MarketType.OVER_UNDER,
      [MarketType.EUROPEAN_HANDICAP_HT]: MarketType.EUROPEAN_HANDICAP_HT,
      [MarketType.DOUBLE_CHANCE]: MarketType.DOUBLE_CHANCE,
      [MarketType.GOAL_NO_GOAL_HT]: MarketType.GOAL_NO_GOAL_HT,
      [MarketType.TOTALS_HOME_TEAM]: MarketType.TOTALS_HOME_TEAM,
      [MarketType.TOTALS_AWAY_TEAM]: MarketType.TOTALS_AWAY_TEAM,
      [MarketType.EXACT_NUMBER_OF_GOALS_HT]:
        MarketType.EXACT_NUMBER_OF_GOALS_HT,
      [MarketType.DRAW_NO_BET_HT]: MarketType.DRAW_NO_BET_HT,
      [MarketType.THREE_WAY_PERIOD]: MarketType.THREE_WAY_PERIOD,
    },
    FILTER_SCORERS: {
      [MarketType.GOALSCORER_HOME_TEAM]: MarketType.GOALSCORER_HOME_TEAM,
      [MarketType.GOALSCORER_AWAY_TEAM]: MarketType.GOALSCORER_AWAY_TEAM,
      [MarketType.GOALSCORER]: MarketType.GOALSCORER,
    },
  },
  icehockey: {
    FILTER_FULLTIME: {
      [MarketType['3WAY']]: MarketType['3WAY'],
      [MarketType.XTH_GOAL]: MarketType.XTH_GOAL,
      [MarketType.TWO_WAY_INCL_OT_PEN]: MarketType.TWO_WAY_INCL_OT_PEN,
      [MarketType.EUROPEAN_HANDICAP]: MarketType.EUROPEAN_HANDICAP,
      [MarketType.DOUBLE_CHANCE]: MarketType.DOUBLE_CHANCE,
      [MarketType.GOAL_NO_GOAL]: MarketType.GOAL_NO_GOAL,
      [MarketType.DRAW_NO_BET]: MarketType.DRAW_NO_BET,
      [MarketType['3WAY_OVER_UNDER']]: MarketType['3WAY_OVER_UNDER'],
    },
    FILTER_GOALS: {
      [MarketType.OVER_UNDER]: MarketType.OVER_UNDER,
      [MarketType.OVER_UNDER_FIRST_HALF]: MarketType.OVER_UNDER_FIRST_HALF,
      [MarketType.TOTALS_HOME_TEAM]: MarketType.TOTALS_HOME_TEAM,
      [MarketType.TOTALS_AWAY_TEAM]: MarketType.TOTALS_AWAY_TEAM,
    },
    HANDICAP: { [MarketType.HANDICAP]: MarketType.EUROPEAN_HANDICAP },
    FILTER_NO_GOAL: { [MarketType.GOAL_NO_GOAL]: MarketType.GOAL_NO_GOAL },
    PERIOD_FIRST: {
      [MarketType.THREE_WAY_PERIOD]: MarketType.THREE_WAY_PERIOD,
    },
  },
  basketball: {
    FILTER_FULLTIME: {
      [MarketType['2WAY']]: MarketType['2WAY'],
      [MarketType.POINTS_SPREADS]: MarketType.POINTS_SPREADS,
    },
    FILTER_POINTS: {
      [MarketType.TOTAL_SPREADS]: MarketType.TOTAL_SPREADS,
      [MarketType.OVER_UNDER]: MarketType.OVER_UNDER,
      [MarketType.OVER_UNDER_FIRST_HALF]: MarketType.OVER_UNDER_FIRST_HALF,
      [MarketType.TOTALS_HOME_TEAM]: MarketType.TOTALS_HOME_TEAM,
      [MarketType.TOTALS_AWAY_TEAM]: MarketType.TOTALS_AWAY_TEAM,
    },
    HANDICAP: { [MarketType.POINTS_SPREADS]: MarketType.POINTS_SPREADS },
    HALFTIME_SHORT: {
      [MarketType.FIRST_HALFTIME]: MarketType.FIRST_HALFTIME,
      [MarketType.DRAW_NO_BET_HT]: MarketType.DRAW_NO_BET_HT,
      [MarketType.OVER_UNDER_FIRST_HALF]: MarketType.OVER_UNDER_FIRST_HALF,
    },
  },
  handball: {
    FILTER_FULLTIME: {
      [MarketType['3WAY']]: MarketType['3WAY'],
      [MarketType.TWO_WAY_INCL_OT_PEN]: MarketType.TWO_WAY_INCL_OT_PEN,
      [MarketType.DOUBLE_CHANCE]: MarketType.DOUBLE_CHANCE,
      [MarketType.DRAW_NO_BET]: MarketType.DRAW_NO_BET,
      [MarketType.POINTS_SPREADS]: MarketType.POINTS_SPREADS,
      [MarketType['3WAY_OVER_UNDER']]: MarketType['3WAY_OVER_UNDER'],
    },
    FILTER_GOALS: {
      [MarketType.OVER_UNDER]: MarketType.OVER_UNDER,
      [MarketType.OVER_UNDER_FIRST_HALF]: MarketType.OVER_UNDER_FIRST_HALF,
      [MarketType.TOTALS_HOME_TEAM]: MarketType.TOTALS_HOME_TEAM,
      [MarketType.TOTALS_AWAY_TEAM]: MarketType.TOTALS_AWAY_TEAM,
    },
    HANDICAP: { [MarketType.POINTS_SPREADS]: MarketType.POINTS_SPREADS },
    HALFTIME_SHORT: {
      [MarketType.FIRST_HALFTIME]: MarketType.FIRST_HALFTIME,
      [MarketType.DRAW_NO_BET_HT]: MarketType.DRAW_NO_BET_HT,
      [MarketType.OVER_UNDER_FIRST_HALF]: MarketType.OVER_UNDER_FIRST_HALF,
    },
  },
  american: {
    FILTER_FULLTIME: {
      [MarketType['2WAY']]: MarketType['2WAY'],
      [MarketType.POINTS_SPREADS]: MarketType.POINTS_SPREADS,
    },
    FILTER_POINTS: {
      [MarketType.TOTAL_SPREADS]: MarketType.TOTAL_SPREADS,
      [MarketType.OVER_UNDER]: MarketType.OVER_UNDER,
      [MarketType.OVER_UNDER_FIRST_HALF]: MarketType.OVER_UNDER_FIRST_HALF,
      [MarketType.TOTALS_HOME_TEAM]: MarketType.TOTALS_HOME_TEAM,
      [MarketType.TOTALS_AWAY_TEAM]: MarketType.TOTALS_AWAY_TEAM,
    },
    HANDICAP: { [MarketType.POINTS_SPREADS]: MarketType.POINTS_SPREADS },
    HALFTIME_SHORT: {
      [MarketType.FIRST_HALFTIME]: MarketType.FIRST_HALFTIME,
      [MarketType.DRAW_NO_BET_HT]: MarketType.DRAW_NO_BET_HT,
      [MarketType.OVER_UNDER_FIRST_HALF]: MarketType.OVER_UNDER_FIRST_HALF,
    },
  },
};

const nWaysMarkets = {
  [MarketType['N-WAY']]: true,
  // FIRST_HALFTIME: true,
  PERIOD_THIRD: true,
  // PERIOD_QUARTER: true,
  PERIOD_INNING: true,
};

const threeWayTipMarkets = [
  'football',
  'icehockey',
  'fieldshockey',
  'handball',
  'rugby',
  'spreads',
  'futsal',
  'waterball',
  'floorball',
  'bandy',
  'aussierules',
];

const setTipTypeToMarkets = (): typeof mapMarketKeyToMarketType =>
  reduce(
    threeWayTipMarkets,
    (acc, name) => ({ ...acc, [name]: { ...(acc[name] || {}), NWAY: '3WAY' } }),
    mapMarketKeyToMarketType,
  );

export const is2Way = (sportName, type: IMarketType): boolean => {
  const newMarketsKeys = setTipTypeToMarkets();
  return newMarketsKeys[sportName]?.NWAY !== '3WAY' && nWaysMarkets[type];
};

export const marketTypePredictionsMap = {
  [MarketType['2WAY']]: 'dual',
  [MarketType.OVER_UNDER]: 'dual',
  [MarketType.SET]: 'dual',
  [MarketType.OVER_UNDER_HT]: 'dual',
  [MarketType.OVER_UNDER_LIVE]: 'dual',
  [MarketType.OVER_UNDER_FIRST_HALF]: 'dual',
  [MarketType.OVER_UNDER_THIRD]: 'dual',
  [MarketType.OVER_UNDER_QUARTER]: 'dual',
  [MarketType.OVER_UNDER_OT]: 'dual',
  [MarketType.PENALTY_SHOOTOUT]: 'dual',
  [MarketType.ODD_EVEN]: 'dual',
  [MarketType.WHICH_WINS_X_POINTS]: 'dual',
  [MarketType['2WAY_LIVE']]: 'dual',
  [MarketType.GOAL_NO_GOAL]: 'dual',
  [MarketType.GOAL_NO_GOAL_HT]: 'dual',
  [MarketType.DRAW_NO_BET]: 'dual',
  [MarketType.DRAW_NO_BET_HT]: 'dual',
  [MarketType.ODD_EVEN_GOALS]: 'dual',
  [MarketType.OVER_UNDER_1_5]: 'dual',
  [MarketType.OVER_UNDER_3_5]: 'dual',
  [MarketType.OVER_UNDER_4_5]: 'dual',
  [MarketType.FIRST_SET]: 'dual',
  [MarketType.SECOND_SET]: 'dual',
  [MarketType.TOTAL_SETS_BEST_OF_3]: 'dual',
  [MarketType.OVER_UNDER_GAMES_LIVE]: 'dual',
  [MarketType.OVER_UNDER_GAMES]: 'dual',
  [MarketType.TOTAL_SPREADS]: 'dual',
  [MarketType.TOTALS_HOME_TEAM]: 'dual',
  [MarketType.TOTALS_AWAY_TEAM]: 'dual',
  [MarketType.GOAL_NO_GOAL_LIVE]: 'dual',
  [MarketType.TOTALS_HOME_TEAM_LIVE]: 'dual',
  [MarketType.TOTALS_AWAY_TEAM_LIVE]: 'dual',
  [MarketType.POINTS_SPREADS]: 'dual',
  [MarketType.POINTS_SPREADS_AM_FOOTBALL]: 'dual',
  [MarketType.TOTAL_SETS]: 'dual',
  [MarketType.TOTAL_LEGS]: 'dual',
  [MarketType.WHO_WINS_IN_LEG]: 'dual',
  [MarketType.TOTAL_180S_LIVE]: 'dual',
  [MarketType.TOTAL_180S_LIVE_PLAYER1]: 'dual',
  [MarketType.TOTAL_180S_LIVE_PLAYER2]: 'dual',
  [MarketType.ANY_PLAYER_SCORE_180]: 'dual',
  [MarketType.PLAYER1_SCORE_180]: 'dual',
  [MarketType.PLAYER2_SCORE_180]: 'dual',
  [MarketType.CHECKOUT_COLOR_IN_LEG]: 'dual',
  [MarketType.CHECKOUT_SCORE_MORE_40_IN_LEG]: 'dual',
  [MarketType.OVERTIME]: 'dual',
  [MarketType.OVERTIME_YES_NO_LIVE]: 'dual',
  [MarketType.POINTS_SPREADS_LIVE]: 'dual',
  [MarketType.GOAL_NO_GOAL_HT_LIVE]: 'dual',
  [MarketType.DRAW_NO_BET_LIVE]: 'dual',
  [MarketType.DRAW_NO_BET_HT_LIVE]: 'dual',
  [MarketType.TO_QUALIFY]: 'dual',
  [MarketType.TO_QUALIFY_LIVE]: 'dual',
  [MarketType.TO_WIN_FINAL_LIVE]: 'dual',
  [MarketType.TO_WIN_BRONZE_LIVE]: 'dual',
  [MarketType.TOTAL_SETS_BEST_OF_3_LIVE]: 'dual',
  [MarketType.INTERVAL_FIFTEEN_TOTAL_LIVE]: 'dual',
  [MarketType.HANDICAP_2WAY]: 'dual',

  [MarketType['3WAY']]: 'triple',
  [MarketType['N-WAY_LIVE']]: 'triple',
  [MarketType.HALFTIME]: 'triple',
  [MarketType.REST_OF_MATCH]: 'triple',
  [MarketType.REST_OF_MATCH_HT]: 'triple',
  [MarketType.GAMES_OF_SET]: 'triple',
  [MarketType.XTH_GOAL]: 'triple',
  [MarketType.XTH_GOAL_HT]: 'triple',
  [MarketType.NEXT_CORNER]: 'triple',
  [MarketType.NEXT_FREE_KICK]: 'triple',
  [MarketType.NEXT_THROW_IN]: 'triple',
  [MarketType.PERIOD]: 'triple',
  [MarketType.THREE_WAY_PERIOD]: 'triple',
  [MarketType['3WAY_LIVE']]: 'triple',
  [MarketType.DOUBLE_CHANCE]: 'triple',
  [MarketType.FIRST_HALFTIME]: 'triple',
  [MarketType.HIGHEST_SCORING_HALF]: 'triple',
  [MarketType.FIRST_CORNER]: 'triple',
  [MarketType.CORNER_BET]: 'triple',
  [MarketType.FIRST_TEAM_TO_SCORE]: 'triple',
  [MarketType.FIRST_TEAM_TO_SCORE_HT]: 'triple',
  [MarketType.LAST_TEAM_TO_SCORE]: 'triple',
  [MarketType.FIRST_TEN_MINUTES]: 'triple',
  [MarketType.DOUBLE_CHANCE_LIVE]: 'triple',
  [MarketType.CORRECT_SCORE_SETS]: 'triple',
  [MarketType.CORRECT_SCORE_LEGS_SET]: 'triple',
  [MarketType.MOST_180S]: 'triple',
  [MarketType.NEXT_POINTS_TEAM]: 'triple',
  [MarketType.PERIOD_BASKETBALL]: 'triple',
  [MarketType.INTERVAL_FIFTEEN_LIVE]: 'triple',

  [MarketType.WINNER]: 'multiple',
  [MarketType.WINNER_CHAMPIONSHIP]: 'multiple',
  [MarketType.WINNER_EVENT]: 'multiple',
  [MarketType.WINNING_METHOD]: 'multiple',
  [MarketType.TOP2]: 'multiple',
  [MarketType.TOP_2]: 'multiple',
  [MarketType.TOP3]: 'multiple',
  [MarketType.TOP_3]: 'multiple',
  [MarketType.TOP4]: 'multiple',
  [MarketType.TOP_4]: 'multiple',
  [MarketType.NUMBER_OF_GOALS]: 'multiple',
  [MarketType.GAME_CORRECT_SCORE]: 'multiple',
  [MarketType.GOALS_HOME_TEAM]: 'multiple',
  [MarketType.GOALS_AWAY_TEAM]: 'multiple',
  [MarketType.HALFTIME_FULLTIME]: 'multiple',
  [MarketType.EXACT_NUMBER_OF_GOALS]: 'multiple',
  [MarketType.EXACT_NUMBER_OF_GOALS_HT]: 'multiple',
  [MarketType.EXACT_NUMBER_OF_GOALS_HT_PM]: 'multiple',
  [MarketType.TOTAL_GOALS_AGGREGATED]: 'multiple',
  [MarketType['3WAY_OVER_UNDER']]: 'multiple',
  [MarketType.CORRECT_3SET_SCORE]: 'multiple',
  [MarketType.CORRECT_5SET_SCORE]: 'multiple',
  [MarketType.CORRECT_7SET_SCORE]: 'multiple',
  [MarketType.TOTAL_SETS_BEST_OF_5]: 'multiple',
  [MarketType.GOALSCORER]: 'multiple',
  [MarketType.GOALSCORER_AWAY_TEAM]: 'multiple',
  [MarketType.GOALSCORER_HOME_TEAM]: 'multiple',
  [MarketType.NEXT_POINTS_KIND]: 'multiple',
  [MarketType.WINNING_MARGINS]: 'multiple',
  [MarketType.CORRECT_SCORE_LIVE]: 'multiple',
  [MarketType.GOALS_HOME_TEAM_LIVE]: 'multiple',
  [MarketType.GOALS_AWAY_TEAM_LIVE]: 'multiple',
  [MarketType['3WAY_OVER_UNDER_LIVE']]: 'multiple',
  [MarketType.CORRECT_3SET_SCORE_LIVE]: 'multiple',
  [MarketType.CORRECT_5SET_SCORE_LIVE]: 'multiple',
  [MarketType.TOTAL_SETS_BEST_OF_5_LIVE]: 'multiple',
  [MarketType.THREE_WAY_GOAL_NO_GOAL]: 'multiple',
  [MarketType.DOUBLE_CHANCE_OVER_UNDER]: 'multiple',
  [MarketType.DOUBLE_CHANCE_GOAL_NO_GOAL]: 'multiple',
};

const sortBySpecialValueOrder = [
  MarketType.OVER_UNDER,
  MarketType.OVER_UNDER_GAMES,
  MarketType.OVER_UNDER_GAMES_LIVE,
  MarketType.OVER_UNDER_HT,
  MarketType.TOTAL_SPREADS,
  MarketType.TOTALS_HOME_TEAM,
  MarketType.TOTALS_AWAY_TEAM,
  MarketType.TOTALS_HOME_TEAM_LIVE,
  MarketType.TOTALS_AWAY_TEAM_LIVE,
  MarketType['3WAY_OVER_UNDER'],
  MarketType.WHICH_WINS_X_POINTS,
  MarketType.SET,
  MarketType.TOTAL_LEGS,
];
const sortByLongSpecialValue = [
  MarketType.HANDICAP,
  MarketType.HANDICAP_2WAY,
  MarketType.POINTS_SPREADS,
  MarketType.POINTS_SPREADS_LIVE,
  MarketType.EUROPEAN_HANDICAP,
  MarketType.POINTS_SPREADS_AM_FOOTBALL,
  MarketType.HANDICAP_GAMES_LIVE,
];

//  const sortByPMostBalancePredictions = ['TOTAL_LEGS'];

const sortByPMostBalancePredictions = [];

export const sortingOrder: IMarketType[] = [
  MarketType['3WAY'],
  MarketType['3WAY_LIVE'],
  MarketType.BET_WON_WINNER,
  MarketType['2WAY'],
  MarketType.TOP_2,
  MarketType.TOP_3,
  MarketType.TOP_4,
  MarketType.REST_OF_MATCH,
  MarketType.WHO_WINS_IN_LEG_FIRST_LEG,
  MarketType.OVER_UNDER,
  MarketType.OVER_UNDER_LIVE,
  MarketType.OVER_UNDER_OT,
  MarketType.OVER_UNDER_GAMES_LIVE_05,
  MarketType.OVER_UNDER_GAMES,
  MarketType.THREE_WAY_PERIOD,
  MarketType.TOTAL_SETS,
  MarketType.TOTAL_SPREADS,
  MarketType.XTH_GOAL,
  MarketType.NEXT_POINTS_TEAM,
  MarketType.HANDICAP,
  MarketType.HANDICAP_2WAY,
  MarketType.EUROPEAN_HANDICAP,
  MarketType.HANDICAP_LIVE,
  MarketType.POINTS_SPREADS_LIVE,
  MarketType.POINTS_SPREADS_SETS,
  MarketType.SET_HANDICAP_LIVE,
  MarketType.GOAL_SPREADS,
  MarketType.POINTS_SPREADS,
  MarketType.POINTS_SPREADS_AM_FOOTBALL,
  MarketType.POINT_SPREADS_LIVE,
  MarketType.WINNING_MARGINS,
  MarketType.TO_QUALIFY,
  MarketType.TO_QUALIFY_LIVE,
  MarketType.TO_WIN_FINAL_LIVE,
  MarketType.TO_WIN_BRONZE_LIVE,
  MarketType.DOUBLE_CHANCE,
  MarketType.DOUBLE_CHANCE_LIVE,
  MarketType.GOAL_NO_GOAL,
  MarketType.GOAL_NO_GOAL_LIVE,
  MarketType.DRAW_NO_BET,
  MarketType.DRAW_NO_BET_LIVE,
  MarketType.FIRST_HALFTIME,
  MarketType.HALFTIME,
  MarketType.REST_OF_MATCH_HT,
  MarketType.FIRST_PERIOD,
  MarketType.OVER_UNDER_FIRST_HALF,
  MarketType.OVER_UNDER_HT,
  MarketType.FIRST_TEAM_TO_SCORE_HT,
  MarketType.XTH_GOAL_HT,
  MarketType.NEXT_POINTS_TEAM_HT,
  MarketType.EUROPEAN_HANDICAP_HT,
  MarketType.HANDICAP_HT_LIVE,
  MarketType.DOUBLE_CHANCE_HT,
  MarketType.GOAL_NO_GOAL_HT,
  MarketType.GOAL_NO_GOAL_HT_LIVE,
  MarketType.EXACT_NUMBER_OF_GOALS_HT_PM,
  MarketType.EXACT_NUMBER_OF_GOALS_HT,
  MarketType.DRAW_NO_BET_HT,
  MarketType.DRAW_NO_BET_HT_LIVE,
  MarketType.INTERVAL_FIFTEEN_LIVE,
  MarketType.INTERVAL_FIFTEEN_TOTAL_LIVE,
  MarketType.PERIOD,
  MarketType.PERIOD_BASKETBALL,
  MarketType.OVER_UNDER_FIRST_PERIOD,
  MarketType.OVER_UNDER_LIVE_HT,
  MarketType.GOALSCORER,
  MarketType.GOALSCORER_HOME_TEAM,
  MarketType.GOALSCORER_AWAY_TEAM,
  MarketType.FIRST_TEAM_TO_SCORE,
  MarketType.TOTAL_GOALS_AGGREGATED,
  MarketType.EXACT_NUMBER_OF_GOALS,
  MarketType.EXACT_NUMBER_OF_GOALS_HT_PM,
  MarketType.EXACT_NUMBER_OF_GOALS_HT,
  MarketType.GOALS_HOME_TEAM,
  MarketType.GOALS_HOME_TEAM_LIVE,
  MarketType.GOALS_AWAY_TEAM,
  MarketType.GOALS_AWAY_TEAM_LIVE,
  MarketType.TOTALS_HOME_TEAM,
  MarketType.TOTALS_HOME_TEAM_LIVE,
  MarketType.TOTALS_HOME_TEAM_LIVE_INCL_OT,
  MarketType.TOTALS_AWAY_TEAM,
  MarketType.TOTALS_AWAY_TEAM_LIVE,
  MarketType.TOTALS_AWAY_TEAM_LIVE_INCL_OT,
  MarketType.TOTALS_HOME_TEAM_HT,
  MarketType.TOTALS_AWAY_TEAM_HT,
  MarketType.CORRECT_SCORE,
  MarketType.CORRECT_SCORE_LIVE,
  MarketType.HALFTIME_FULLTIME,
  MarketType.WINNING_METHOD,
  MarketType.HIGHEST_SCORING_HALF,
  MarketType.FIRST_TEN_MINUTES,
  MarketType.WINNER,
  MarketType.TOP3,
  MarketType.TOP4,
  MarketType.WINNER_CHAMPIONSHIP,
  MarketType.WINNER_EVENT,
  MarketType['3WAY_OVER_UNDER'],
  MarketType['3WAY_OVER_UNDER_LIVE'],
  MarketType.THREE_WAY_GOAL_NO_GOAL,
  MarketType.DOUBLE_CHANCE_OVER_UNDER,
  MarketType.DOUBLE_CHANCE_GOAL_NO_GOAL,
  MarketType.THREE_WAY_PERIOD_2,
  MarketType.GOAL_NO_GOAL_HT_2,
  MarketType.OVER_UNDER_2,
  MarketType.NEXT_POINTS_KIND,
  MarketType.OVERTIME,
  MarketType.OVERTIME_YES_NO,
  MarketType.OVERTIME_YES_NO_LIVE,
  MarketType.ODD_EVEN_GOALS,
  MarketType.NEXT_CORNER,
  MarketType.FIRST_CORNER,
  MarketType.CORNER_BET,
  MarketType.SET,
  MarketType.FIRST_SET,
  MarketType.SET_1,
  MarketType.OVER_UNDER_GAMES_LIVE_1,
  MarketType.GAMES_OF_SET_1,
  MarketType.SECOND_SET,
  MarketType.SET_2,
  MarketType.OVER_UNDER_GAMES_LIVE_2,
  MarketType.GAMES_OF_SET_2,
  MarketType.SET_3,
  MarketType.OVER_UNDER_GAMES_LIVE_3,
  MarketType.GAMES_OF_SET_3,
  MarketType.SET_4,
  MarketType.OVER_UNDER_GAMES_LIVE_4,
  MarketType.GAMES_OF_SET_4,
  MarketType.SET_5,
  MarketType.OVER_UNDER_GAMES_LIVE_5,
  MarketType.GAMES_OF_SET_5,
  MarketType.SET_6,
  MarketType.OVER_UNDER_GAMES_LIVE_6,
  MarketType.GAMES_OF_SET_6,
  MarketType.WHICH_WINS_X_POINTS,
  MarketType.HANDICAP_GAMES_LIVE,
  MarketType.OVER_UNDER_GAMES_LIVE,
  MarketType.CORRECT_3SET_SCORE,
  MarketType.CORRECT_3SET_SCORE_LIVE,
  MarketType.CORRECT_5SET_SCORE,
  MarketType.CORRECT_5SET_SCORE_LIVE,
  MarketType.TOTAL_SETS_BEST_OF_3,
  MarketType.TOTAL_SETS_BEST_OF_3_LIVE,
  MarketType.TOTAL_SETS_BEST_OF_5,
  MarketType.TOTAL_SETS_BEST_OF_5_LIVE,
  MarketType.ODD_EVEN,
  MarketType.TOTAL_LEGS,
  MarketType.WHO_WINS_IN_LEG,
  MarketType.CORRECT_SCORE_SETS,
  MarketType.CORRECT_SCORE_LEGS_SET,
  MarketType.MOST_180S,
  MarketType.ANY_PLAYER_SCORE_180,
  MarketType.PLAYER1_SCORE_180,
  MarketType.PLAYER2_SCORE_180,
  MarketType.TOTAL_180S_LIVE,
  MarketType.TOTAL_180S_LIVE_PLAYER1,
  MarketType.TOTAL_180S_LIVE_PLAYER2,
  MarketType.CHECKOUT_SCORE_MORE_40_IN_LEG,
  MarketType.CHECKOUT_COLOR_IN_LEG,
  MarketType.PENALTY_SHOOTOUT,
  // 'THREE_WAY_GOAL_NO_GOAL'
];

const withSpecialValueInLabel = [
  MarketType.WINNER,
  MarketType['2WAY'],
  MarketType['3WAY'],
  MarketType.GAMES_OF_SET,
  MarketType.TOTAL_SETS,
  MarketType.SET,
  MarketType.PERIOD,
  MarketType.OVER_UNDER_GAMES,
  MarketType.OVER_UNDER_GAMES_LIVE,
  MarketType.WHO_WINS_IN_LEG,
  MarketType.CORRECT_3SET_SCORE,
  MarketType.CORRECT_5SET_SCORE,
  MarketType.INTERVAL_FIFTEEN_LIVE,
  MarketType.INTERVAL_FIFTEEN_TOTAL_LIVE,
  MarketType.WHICH_WINS_X_POINTS,
  MarketType.TOTAL_SETS,
  MarketType.TOTAL_LEGS,
  MarketType.TOTAL_180S_LIVE,
  MarketType.MOST_180S,
  MarketType.TOTAL_180S_LIVE_PLAYER1,
  MarketType.TOTAL_180S_LIVE_PLAYER2,
  MarketType.CORRECT_SCORE_LEGS_SET,
  MarketType.CORRECT_SCORE,
];

export const getLiveStatus = (market?: IMarket): string => {
  return market?.live_status || '';
};

/**
 * isEnabled
 * checks if market is enabled
 *
 * @param {IMarket} market
 * @param {string} eventStatus
 * @param {boolean} withSuspended
 * @returns {boolean} isEnabled
 */
export const isEnabled = (
  market: IMarket,
  eventStatus: string,
  withSuspended?: boolean,
): boolean => {
  const marketStatus = getLiveStatus(market);
  if (
    eventStatus === LiveStatus.closed ||
    eventStatus === LiveStatus.suspended
  ) {
    return false;
  }
  // The market is pre-match: then we check for tradingStatus
  if (
    marketStatus === LiveStatus.future ||
    marketStatus === LiveStatus.disabled
  ) {
    const marketTradingStatus = toLower(market.trading_status);
    if (
      marketTradingStatus === LiveStatus.open ||
      marketTradingStatus === 'unknown'
    ) {
      return true;
    }
    return false;
  }

  // The market is live. Enable only if status is open
  return withSuspended
    ? marketStatus === LiveStatus.open ||
        marketStatus === LiveStatus.smart_suspended
    : marketStatus === LiveStatus.open;
};

/**
 * getMarketMinimumSelections
 * returns minimum selection
 *
 * @param {IMarket} market
 * @param {IEvent} event
 * @returns {number} minSelection
 */
export const getMarketMinimumSelections = (
  market?: IMarket,
  event?: IEvent,
): number => {
  let min;
  if (isLiveFuture(event) || isLiveDisabled(event)) {
    min = parseInt(market?.minimum_selections || '', 10);
  } else {
    min = market?.live_minimum_selections;
  }
  return Number.isNaN(min) ? 1 : min;
};

/**
 * getType
 * returns market type
 *
 * @param {IMarket} market
 * @returns {string} type
 */
export const getType = (market: IMarket): IMarketType => {
  const { type } = market;
  return marketTypeMap[type] || type || '';
};

/**
 * getLabel
 *
 * @param {IMarket} market
 * @returns {string} label
 */
export const getLabel = (market: IMarket): string => {
  const OVERTIME_DE = 'Verlängerung';
  const MINUTE_KEY = 'short.minute';

  const type = getType(market);
  if (
    includes(
      [
        MarketType.HANDICAP,
        MarketType.HANDICAP_2WAY,
        MarketType.POINTS_SPREADS_AM_FOOTBALL,
      ],
      type,
    )
  ) {
    return i18n.t('header_market_keys.HANDICAP');
  }

  if (
    includes(
      [
        MarketType.OVER_UNDER,
        MarketType.TOTALS_HOME_TEAM,
        MarketType.TOTALS_AWAY_TEAM,
        MarketType.TOTALS_AWAY_TEAM_LIVE,
        MarketType.TOTALS_HOME_TEAM_LIVE,
      ],
      type,
    )
  ) {
    if (includes(market.label, OVERTIME_DE)) {
      return `${replace(
        market.label,
        OVERTIME_DE,
        i18n.t('helpers.overtime'),
      )}`;
    }
    return `${market.label}`;
  }
  if (type === MarketType.HALFTIME_FULLTIME) {
    return split(market.label, '/').join(' / ');
  }
  if (type === MarketType.WINNING_MARGINS) {
    return i18n.t('header_market_keys.WINNING_MARGINS');
  }
  if (type === MarketType.DRAW_NO_BET) {
    return i18n.t('header_market_keys.DRAW_NO_BET');
  }
  if (type === MarketType.DRAW_NO_BET_HT) {
    return i18n.t('header_market_keys.DRAW_NO_BET_HT');
  }
  if (getType(market) === MarketType.INTERVAL_FIFTEEN_LIVE) {
    /* label_de: Wer erzielt mehr Tore? HZ - 60:00 Min. */
    /* label_en:  */
    const splitted = split(market.label, /\?/)[0];
    const { special_value } = market;
    if (/46\/60/.test(special_value)) {
      return `${splitted}? HZ - 60. ${i18n.t(MINUTE_KEY)}`;
    }
    if (/61\/75/.test(special_value)) {
      return `${splitted}? 61. - 75. ${i18n.t(MINUTE_KEY)}`;
    }
    if (/1\/15/.test(special_value)) {
      return `${splitted}? 1. - 15. ${i18n.t(MINUTE_KEY)}`;
    }
    if (/16\/30/.test(special_value)) {
      return `${splitted}? 16. - 30. ${i18n.t(MINUTE_KEY)}`;
    }
  } else if (getType(market) === MarketType.INTERVAL_FIFTEEN_TOTAL_LIVE) {
    /* label_de: Über/Unter (0.5) HZ - 60:00 Min. */
    /* label_en:  */
    const splitted = split(market.label, /\) /)[0];
    const { special_value } = market;
    if (/46\/60/.test(special_value)) {
      return `${splitted}) HZ - 60. ${i18n.t(MINUTE_KEY)}`;
    }
    if (/61\/75/.test(special_value)) {
      return `${splitted}) 61. - 75. ${i18n.t(MINUTE_KEY)}`;
    }
    if (/1\/15/.test(special_value)) {
      return `${splitted}) 1. - 15. ${i18n.t(MINUTE_KEY)}`;
    }
    if (/16\/30/.test(special_value)) {
      return `${splitted}) 16. - 30. ${i18n.t(MINUTE_KEY)}`;
    }
  } else if (market?.label?.indexOf(OVERTIME_DE) !== -1) {
    return replace(market?.label, OVERTIME_DE, i18n.t('helpers.overtime'));
  } else if (type === MarketType.WHICH_WINS_X_POINTS) {
    const { special_value } = market;
    return replace(market.label, ' X ', ` ${special_value} `);
  }
  return market.label;
};

/**
 * getSpecialValue
 * returns special value for prediction
 *
 * @param {IMarket} market
 * @param {IEvent} event
 * @returns {string} specialValue
 */
export const getSpecialValue = (market: IMarket, event?: IEvent): string => {
  const { type } = market;
  if (type === MarketType.OVER_UNDER_1_5) {
    return '1.5';
  }
  if (type === MarketType.OVER_UNDER_3_5) {
    return '3.5';
  }
  if (type === MarketType.OVER_UNDER_4_5) {
    return '4.5';
  }
  if (type === MarketType.XTH_GOAL) {
    if (services.domainLang === 'de') {
      return `[${market.special_value}. ${i18n.t('helpers.GOAL')}]`;
    }
    return `[${i18n.t('helpers.GOAL')} ${market.special_value}]`;
  }
  if (event && isOvertime(event) && type === MarketType.OVER_UNDER) {
    return `${market.special_value} <br> ${i18n.t('helpers.only_OT')}`;
  }
  return market.special_value || '';
};

/**
 * periodTypeMap
 * maps period types
 *
 * @param {number | string} period_id
 * @returns {string} periodType
 */
export const periodTypeMap = (period_id: string): string => {
  const type = periodTypesMap[period_id];
  return type === 'PERIOD_FT_INC_OT' ? 'PERIOD_FULLTIME' : type;
};

/**
 * getPeriodType
 * returns "standard" period type
 *
 * @param {IMarket} market
 * @returns {string} periodType
 */
export const getPeriodType = (market: IMarket): string => {
  return periodTypeMap(market.period_type);
};

/**
 * getSortingOrder
 * returns sorting order for market type
 *
 * @param {string} type
 * @returns {number} order
 */
export const getSortingOrder = (type: IMarketType): number => {
  if (!includes(sortingOrder, type)) {
    services.logger.log('MISSING SORTRING ORDER:', type);
    sortingOrder.push(type);
  }
  return sortingOrder.indexOf(type);
};

/**
 * shouldSortBySpecialValue
 * check if market should be sorted by special value
 *
 * @param {string} type
 * @returns {boolean} shouldSortBySpecialValue
 */
export const shouldSortBySpecialValue = (type: IMarketType): boolean => {
  return includes(sortBySpecialValueOrder, type);
};

/**
 * shouldSortByLongSpecialValue
 * sort markets by long special value e.g
 * Handicap 0:1.5
 * Handicap 0:3.5
 * Handicap 2.5:0
 *
 * @param {string} type
 * @returns {boolean} shouldSortByLongSpecialValue
 */
export const shouldSortByLongSpecialValue = (type: IMarketType): boolean => {
  return includes(sortByLongSpecialValue, type);
};

/**
 * shouldSortByMostBalance
 * sort markets based on mostbalance logic
 * TOTAL_LEGS with 1,4 : 1,5
 * TOTAL_LEGS with 1,3 : 1,5
 * TOTAL_LEGS with 1,2 : 1,5
 *
 * @param {string} type
 * @returns {boolean} shouldSortByMostBalance
 */
export const shouldSortByMostBalance = (type: IMarketType): boolean => {
  return includes(sortByPMostBalancePredictions, type);
};

export const getBalancedValue = (
  market: IMarket,
  predictions?: Record<number, IPrediction>,
): number => {
  if (!market || !predictions) {
    return 0;
  }

  if (
    predictions[market.predictions[0]] &&
    predictions[market.predictions[1]]
  ) {
    const nthOdds = (n): number => predictions[market.predictions[n]].odds;
    return Math.abs(nthOdds(1) - nthOdds(0));
  }
  return 0;
};

/**
 * sortBySpecialValue
 * sort markets by special value
 *
 * @param {IMarket[]} enabledMarkets
 * @param {Record<string, IPrediction>} predictions
 * @returns {IMarket[]} sorted
 */
export const sortBySpecialValue = (
  enabledMarkets: IMarket[],
  predictions?: Record<string, IPrediction>,
): IMarket[] => {
  return sortBy(enabledMarkets, market => {
    if (shouldSortByMostBalance(getType(market))) {
      return getBalancedValue(market, predictions);
    }
    if (shouldSortBySpecialValue(getType(market))) {
      return parseFloat(getSpecialValue(market));
    }
    if (shouldSortByLongSpecialValue(getType(market))) {
      const splited = split(getSpecialValue(market), ':');
      if (splited && splited.length) {
        if (includes(getSpecialValue(market), '.')) {
          // expected order
          // 0:1.5
          // 0:2.5
          // 1.5:0
          // 2.5:0
          // 3.5:0
          // 4.5:0
          return includes(splited[0], '.')
            ? parseFloat(splited[0]) * 10
            : parseFloat(splited[1]);
        }
        // expected order
        // 0:1
        // 0:2
        // 1:0
        // 2:0
        return parseFloat(splited[0]) * 10 + parseFloat(splited[1]);
      }
    }
    if (getType(market) === MarketType.GAMES_OF_SET) {
      return parseFloat(replace(getSpecialValue(market), /[s,g-]/gi, ''));
    }
    return false;
  });
};

/**
 * sortExtraMarkets
 * sorts markets for extra market container
 *
 * @param {IMarket[]} enabledMarkets
 * @param {IEvent} event
 * @param {string} top_category
 * @param {Record<string, IPrediction>} predictions
 * @returns {IMarket[]} sorted
 */
export const sortExtraMarkets = (
  enabledMarkets: IMarket[],
  event: IEvent,
  top_category: string,
  predictions: Record<string, IPrediction>,
): IMarket[] => {
  const sortedBySpecialValue = sortBySpecialValue(enabledMarkets, predictions);

  if (
    isTennis(event) &&
    !isBadminton(event, top_category) &&
    !isTableTennis(event, top_category) &&
    !isBeachVolleyball(event, top_category) &&
    !isVolleyball(event)
  ) {
    return sortBy(sortedBySpecialValue, market => {
      const type = getType(market);
      const period = parseInt(market.period_type, 10);
      if (
        type === MarketType.SET ||
        type === MarketType.OVER_UNDER_GAMES_LIVE ||
        type === MarketType.GAMES_OF_SET
      ) {
        return getSortingOrder(`${type}_${period}` as IMarketType); //  SET_1, OVER_UNDER_GAMES_LIVE_1, GAMES_OF_SET_1
      }
      return getSortingOrder(type) + period / 10; // markets should not jump more than 1 position because of set
    });
  }
  if (
    isBadminton(event, top_category) ||
    isTableTennis(event, top_category) ||
    isBeachVolleyball(event, top_category) ||
    isVolleyball(event)
  ) {
    sortBy(sortedBySpecialValue, market => {
      const type = getType(market);
      const period = parseInt(market.period_type, 10);
      if (
        type === MarketType.OVER_UNDER &&
        (periodTypesMap[period] === 'PERIOD_GAME' ||
          periodTypesMap[period] === 'PERIOD_SET')
      ) {
        return getSortingOrder(
          `${type}_GAMES_LIVE_${market.period}` as IMarketType,
        ); // OVER_UNDER_GAMES_LIVE_1
      }
      return getSortingOrder(type) + period / 10; // markets should not jump more than 1 position because of set
    });
  }
  return sortBy(sortedBySpecialValue, market => {
    const { type } = market;
    const period = parseInt(market.period_type, 10);
    if (
      (type === MarketType.REST_OF_MATCH ||
        type === MarketType.XTH_GOAL ||
        type === MarketType.NEXT_POINTS_TEAM ||
        type === MarketType.TOTALS_HOME_TEAM ||
        type === MarketType.TOTALS_AWAY_TEAM ||
        type === MarketType.DOUBLE_CHANCE ||
        type === MarketType.OVER_UNDER_LIVE) &&
      period === 2
    ) {
      return getSortingOrder(`${type}_HT`); //  XTH_GOAL_HT, REST_OF_MATCH_HT, NEXT_POINTS_TEAM_HT
    }
    if (type === MarketType.OVER_UNDER && (period === 11 || period === 5)) {
      return (
        getSortingOrder(`${type}_FIRST_PERIOD`) + Number(market.period) / 10
      ); // OVER_UNDER_FIRST_PERIOD
    }
    if (
      type === MarketType.WINNER &&
      includes(
        market.label,
        i18n.t(`header_market_keys.${MarketType.BET_WON_WINNER}`),
      )
    ) {
      return getSortingOrder(MarketType.BET_WON_WINNER);
    }
    if (
      type === MarketType.WHO_WINS_IN_LEG &&
      (period === 11 || period === 5)
    ) {
      return getSortingOrder(`${type}_FIRST_LEG`); // WHO_WINS_IN_LEG_FIRST_LEG
    }
    if (
      (type === MarketType.THREE_WAY_PERIOD ||
        type === MarketType.GOAL_NO_GOAL_HT ||
        type === MarketType.OVER_UNDER) &&
      period === 2 &&
      market.period === '2'
    ) {
      return getSortingOrder(`${type}_2`);
    }
    return getSortingOrder(type);
  });
};

/**
 * hasSpecialValueInLabel
 * check if market has special value in label
 *
 * @param {string} type
 * @returns {boolean} hasSpecialValueInLabel
 */
export const hasSpecialValueInLabel = (type: string): boolean => {
  return includes(withSpecialValueInLabel, type);
};

const checkPeriodType = (market: IMarket, periodType: string): boolean => {
  const notNeededCheckSamePeriod = periodType === 'PERIOD_OVERTIME';
  return notNeededCheckSamePeriod || getPeriodType(market) === periodType;
};

const checkIsSamePeriod = (
  event: IEvent,
  market: IMarket,
  periodType,
): boolean => {
  const period_id = event.timer?.period_id || '';
  const notNeededCheckSamePeriod =
    !event.timer ||
    period_id === 'NOT_STARTED' ||
    periodType === 'PERIOD_FULLTIME' ||
    periodType === 'PERIOD_OVERTIME' ||
    periodType === 'PERIOD_PENALTIES' ||
    hasBreakPeriod(event.timer);

  return notNeededCheckSamePeriod || head(period_id) === market.period;
};
const getMarketAccordingToSportAndPeriod = (
  type: string,
  market: IMarket,
  periodType: string,
  event: IEvent,
): boolean => {
  const isSameMarketType =
    type === market.type ||
    (type === MarketType['N-WAY'] &&
      (market.type === MarketType['2WAY'] ||
        market.type === MarketType['3WAY']));
  const isSamePeriodType = checkPeriodType(market, periodType);
  const isSamePeriod = checkIsSamePeriod(event, market, periodType);

  if (isSameMarketType && isSamePeriodType && isSamePeriod) {
    return true;
  }
  return false;
};

/**
 * queryAll
 * fetches all markets of chosen type
 *
 * @param {{
 *   type: string;
 *   periodType: string;
 *   eventStatus: string;
 *   markets: IMarket[];
 *   event: IEvent;
 * }} params
 * @returns {IMarket[]} markets
 */
export const queryAll = ({
  type,
  periodType,
  markets,
  event,
}: {
  type: IMarketType;
  periodType: string;
  eventStatus: string;
  markets: IMarket[];
  event: IEvent;
}): IMarket[] => {
  return sortBySpecialValue(
    filter(markets, (currentMarket: IMarket) => {
      if (
        getMarketAccordingToSportAndPeriod(
          type,
          currentMarket,
          periodType,
          event,
        )
      ) {
        return !!currentMarket;
      }
      return false;
    }),
  );
};

/**
 * isMultiMarketType
 * defines markets groups on event list page
 *
 * @param {string} type
 * @returns {boolean} isMultiMarketType
 */
export const isMultiMarketType = (type: IMarketType): boolean => {
  return includes(
    [
      MarketType.OVER_UNDER,
      MarketType.OVER_UNDER_FIRST_HALF,
      MarketType.OVER_UNDER_HT,
      MarketType.OVER_UNDER_THIRD,
      MarketType.OVER_UNDER_QUARTER,
      MarketType.HANDICAP,
      MarketType.HANDICAP_2WAY,
      MarketType.EUROPEAN_HANDICAP,
      MarketType.EUROPEAN_HANDICAP_HT,
      MarketType.TOTAL_LEGS,
      MarketType.TOTAL_SETS,
      MarketType.WHO_WINS_IN_LEG,
      MarketType.POINTS_SPREADS_AM_FOOTBALL,
      MarketType.POINTS_SPREADS,
      MarketType.TOTAL_SPREADS,
      MarketType.REST_OF_MATCH,
      MarketType.XTH_GOAL,
      MarketType.GOAL_NO_GOAL_HT,
    ],
    type,
  );
};

/**
 * getMostBalanced
 * calculate best viewd markets for over under and handicap
 *
 * @param {IMarket[]} markets
 * @param {Record<number, IPrediction>} predictions
 * @returns {IMarket[]} markets
 */
export const getMostBalanced = (
  markets: IMarket[],
  predictions: Record<number, IPrediction>,
): IMarket[] => {
  return sortBy(markets, market => {
    if (
      predictions[market.predictions[0]] &&
      predictions[market.predictions[1]]
    ) {
      return Math.abs(
        predictions[market.predictions[0]].odds -
          predictions[market.predictions[1]].odds,
      );
    }
    return 999;
  });
};

/**
 * query
 * return all markets suitable for sport and period
 * all the params of market e.g specialValue, period should match
 *
 * @param {{
 *   type: string;
 *   event: IEvent;
 *   markets: IMarket[];
 *   periodType: string;
 *   predictions: Record<string, IPrediction>;
 *   withMostBalance?: boolean;
 *   singleMarket?: boolean;
 * }} params
 * @returns {(IMarket | null)[]} markets
 */
export const query = ({
  type,
  event,
  markets,
  periodType,
}: {
  type: IMarketType;
  event: IEvent;
  markets: IMarket[];
  periodType: string;
}): IMarket[] => {
  const eventStatus = getEventLiveStatus(event);
  const eventType = isLiveCurrent(event) ? 'live' : 'sport';

  if (isWinner(event)) {
    return markets;
  }

  if (isMultiMarketType(type)) {
    return queryAll({
      type,
      periodType,
      eventStatus,
      markets,
      event,
    });
  }

  const suitableMarkets = filter(markets, (currentMarket: IMarket) =>
    getMarketAccordingToSportAndPeriod(type, currentMarket, periodType, event),
  );
  const { data: user } = useUserState.getState();

  return suitableMarkets.length
    ? excludeMarkets(suitableMarkets, user, eventType)
    : [];
};

/**
 * filterMarkets
 * return enabled markets or []
 * for mobile return all enabled and sorted by balance markets
 * for desktop if single and enabled return it, or []
 * for desktop if multiple and enabled - sort by balance and return
 *
 * @param {{
 *   markets: IMarket[];
 *   liveStatus: string;
 *   type: string;
 *   predictions: Record<string, IPrediction>;
 *   isDesktop: boolean;
 * }} params
 * @returns {IMarket[] | []} markets
 */
export const filterMarkets = ({
  markets,
  liveStatus,
  type,
  predictions,
  isDesktop,
}: {
  markets: IMarket[];
  liveStatus: string;
  type: IMarketType;
  predictions: IPrediction[] | null;
  isDesktop: boolean;
}): IMarket[] => {
  const enabledMarkets = filter(markets, (currentMarket: IMarket) => {
    if (isEnabled(currentMarket, liveStatus)) {
      return !!currentMarket;
    }
    return false;
  });

  if (isMultiMarketType(type) && predictions && isDesktop) {
    const balanced = getMostBalanced(enabledMarkets, predictions);
    return isDesktop ? take(balanced, 1) : balanced;
  }
  return enabledMarkets;
};

export const getMarkets = (
  marketsData: Record<string, IMarket>,
  markets?: string[],
): IMarket[] =>
  reduce(
    markets,
    (acc, mid) => (marketsData[mid] ? acc.concat(marketsData[mid]) : acc),
    [] as IMarket[],
  );

export const withoutEmpty = {
  [MarketType.WINNER]: MarketType.WINNER,
  [MarketType.WINNING_MARGINS]: MarketType.WINNING_MARGINS,
  [MarketType.WINNER_CHAMPIONSHIP]: MarketType.WINNER_CHAMPIONSHIP,
  [MarketType.WINNER_EVENT]: MarketType.WINNER_EVENT,
  [MarketType.TOP_2]: MarketType.TOP_2,
  [MarketType.TOP3]: MarketType.TOP3,
  [MarketType.TOP_3]: MarketType.TOP_3,
  [MarketType.TOP4]: MarketType.TOP4,
  [MarketType.TOP_4]: MarketType.TOP_4,
};

/**
 * containsDisabledPredictions
 * checks if market contain disabled predictions
 * some market doesn't show disabled predictions
 *
 * @param {IMarket} market
 * @returns {boolean} containsDisabledPredictions
 */
export const containsDisabledPredictions = (market: IMarket): boolean => {
  const type = getType(market);
  return !withoutEmpty[type];
};

// market predictions default layout ( how much pred. should be shown in one  market line )
export const definedTypes = {
  [MarketType.GOALSCORER]: 'single',
  [MarketType.WINNING_MARGINS]: 'single',
  [MarketType.WINNING_METHOD]: 'single',
  [MarketType.WINNER]: 'quarter',
  [MarketType.WINNER_CHAMPIONSHIP]: 'quarter',
  [MarketType.WINNER_EVENT]: 'quarter',
  [MarketType.TOP3]: 'quarter',
  [MarketType.TOP_3]: 'quarter',
  [MarketType.TOP4]: 'quarter',
  [MarketType.TOP_4]: 'quarter',
  [MarketType.TOP_2]: 'quarter',
  [MarketType.GOALSCORER_HOME_TEAM]: 'single',
  [MarketType.GOALSCORER_AWAY_TEAM]: 'single',
  [MarketType.CORRECT_3SET_SCORE]: 'dual',
  [MarketType.CORRECT_5SET_SCORE]: 'dual',
};

export const typesToPredictionHeader = {
  [MarketType.PENALTY_SHOOTOUT]: ['1', '2'],
  [MarketType['3WAY']]: ['1', 'X', '2'],
  [MarketType['N-WAY_LIVE']]: ['1', 'X', '2'],
  [MarketType['N-WAY']]: ['1', 'X', '2'],
  [MarketType.XTH_GOAL]: ['1', 'X', '2'],
  [MarketType.REST_OF_MATCH]: ['1', 'X', '2'],
  [MarketType.POINTS_SPREADS_AM_FOOTBALL]: ['1', '2'],
  [MarketType.POINTS_SPREADS]: ['1', '2'],
  [MarketType.HANDICAP]: ['1', 'X', '2'],
  [MarketType.HANDICAP_2WAY]: ['1', '2'],
  [MarketType.HANDICAP_LIVE]: ['1', 'X', '2'],
  [MarketType['3WAY_LIVE']]: ['1', 'X', '2'],
  [MarketType.XTH_GOAL_HT]: ['1', 'X', '2'],
  [MarketType.REST_OF_MATCH_HT]: ['1', 'X', '2'],
  [MarketType.DOUBLE_CHANCE]: ['1/X', '1/2', 'X/2'],
  [MarketType.FIRST_HALFTIME]: ['1', 'X', '2'],
  [MarketType.CORRECT_3SET_SCORE]: [i18n.t('events.result')],
  [MarketType.PERIOD]: ['1', 'X', '2'],
  [MarketType.PERIOD_THIRD]: ['1', 'X', '2'],
  [MarketType.PERIOD_QUARTER]: ['1', 'X', '2'],
  [MarketType.HALFTIME]: ['1', 'X', '2'],
  [MarketType['2WAY']]: ['1', '2'],
  [MarketType.FIRST_SET]: ['1', '2'],
  [MarketType.SET]: ['1', '2'],
  [MarketType.WHO_WINS_IN_LEG]: ['1', '2'],
  [MarketType.WHICH_WINS_X_POINTS]: ['1', '2'],
  [MarketType.OVER_UNDER]: ['+', '-'],
  [MarketType.OVER_UNDER_HT]: ['+', '-'],
  [MarketType.OVER_UNDER_GAMES_LIVE]: ['+', '-'],
  [MarketType.OVER_UNDER_FIRST_HALF]: ['+', '-'],
  [MarketType.OVER_UNDER_LIVE]: ['+', '-'],
  [MarketType.OVER_UNDER_THIRD]: ['+', '-'],
  [MarketType.OVER_UNDER_QUARTER]: ['+', '-'],
  [MarketType.GOAL_NO_GOAL]: [i18n.t('helpers.yes'), i18n.t('helpers.no')],
  [MarketType.TOTAL_LEGS]: ['+', '-'],
  [MarketType.TOTAL_SPREADS]: ['+', '-'],
  [MarketType.TOTAL_SPREADS_LIVE]: ['1', 'X', '2'],
  PLACEHOLDER: new Array(3),
};

export const getTypesToPredictionHeader = (
  categoryId: string,
  type: IMarketType,
): string[] => {
  return (
    typesToPredictionHeader[
      is2Way(getSportName(categoryId), type) ? MarketType['2WAY'] : type
    ] || []
  );
};

/**
 * getMarketLayout
 * check market layout based on market type and prediction quantity
 * it might be single/dual/triple
 * triple is a default one if market is not single and not possible to devide by 2 without a reminder
 *
 * @param {IMarket} market
 * @param {IPrediction[]} predictions
 * @returns {*|string} marketLayout
 */
export const getMarketLayout = (
  market: IMarket,
  predictions: IPrediction[],
): string => {
  const type = getType(market);
  if (predictions.length === 3 && !definedTypes[type]) {
    return 'triple one-row';
  }
  if (predictions.length === 2 && !definedTypes[type]) {
    return 'dual one-row';
  }
  return (
    definedTypes[type] || (predictions.length % 3 === 0 ? 'triple' : 'dual')
  );
};

/**
 * hasSpecialValueOnList
 * check if market type has a special value
 *
 * @param {string} marketType
 * @returns {boolean} hasSpecialValueOnList
 */
export const hasSpecialValueOnList = (marketType: IMarketType): boolean => {
  return !!marketTypeLiveMapSV[marketType];
};

/**
 * isUnivMarketNotFitForPeriod
 * check if universal market for HALFTIME_SHORT of FILTER_FULLTIME inside detailedEventMarketGroups is fit for their period
 *
 * @param {string} filterMarketKey
 * @param {string} period_type
 * @param {string} type
 * @returns {boolean} isUnivMarketNotFitForPeriod
 */

// PERIOD_FULLTIME  => 1
// PERIOD_HALFTIME  => 2
export const isUnivMarketNotFitForPeriod = (
  filterMarketKey,
  period_type,
  type: IMarketType,
): boolean => {
  return (
    universalHTMarkets[type] &&
    ((filterMarketKey === 'HALFTIME_SHORT' && period_type === '1') ||
      (filterMarketKey === 'FILTER_FULLTIME' && period_type === '2'))
  );
};

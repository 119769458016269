// Allowed unicode symbols as per LUGAS:
//  - Basic Latin        (0020–007F)
//  - Latin-1 Supplement (00A1–00FF)
//  - Latin Extended-A   (0100–017F)
// RegExp: /^[\u0020-\u007F\u00A1-\u00FF\u0100-\u017F]+$/
// eslint-disable-next-line max-len
// Allowed symbols: " !"#$%&'()*+,-./0123456789:;<=>?@ABCDEFGHIJKLMNOPQRSTUVWXYZ[\]^_`abcdefghijklmnopqrstuvwxyz{|}~¡¢£¤¥¦§¨©ª«¬®¯°±²³´µ¶·¸¹º»¼½¾¿ÀÁÂÃÄÅÆÇÈÉÊËÌÍÎÏÐÑÒÓÔÕÖ×ØÙÚÛÜÝÞßàáâãäåæçèéêëìíîïðñòóôõö÷øùúûüýþÿĀāĂăĄąĆćĈĉĊċČčĎďĐđĒēĔĕĖėĘęĚěĜĝĞğĠġĢģĤĥĦħĨĩĪīĬĭĮįİıĲĳĴĵĶķĸĹĺĻļĽľĿŀŁłŃńŅņŇňŉŊŋŌōŎŏŐőŒœŔŕŖŗŘřŚśŜŝŞşŠšŢţŤťŦŧŨũŪūŬŭŮůŰűŲųŴŵŶŷŸŹźŻżŽžſ"

export default {
  ONLY_NUMBERS: /^\d*\.?\d*$/,
  EMAIL: /^[\w-.]+@([\w-]+\.)+[a-zA-Z]{2,6}$/,
  LOGIN: /^[a-zA-Z]+[a-zA-Z0-9_]*$/,
  PASSWORD: /^(?=.*[A-Za-z])(?=.*[A-Z])(?=.*\d)(?=.*[_!.$§%&?/()=+*#-])[0-9a-zA-Z_!.$§%&?/()=+*#-]{8,}/g,
  PHONE: /^[+]?[(]?[0-9]{3}[)]?[-\s]?[0-9]{3}[-\s]?[0-9]{4,10}$/,
  CONTAIN_LETTERS: /[\p{L}]/u,
  FIRST_LETTER: /^[\p{L}]/u,
  FIRST_LETTER_NUMBER: /^[0-9\p{L}]/u,

  INCORRECT_PASSWORD: /[^a-zA-Z0-9_!.$§%&?"/()=+*#-]/g,
  INCORRECT_POST_CODE: /[^A-Z0-9-]+/g,
  INCORRECT_SYMBOLS: /[^\u0020-\u007F\u00A1-\u00FF\u0100-\u017F]/g,
};

import { MouseEvent } from 'react';
import includes from 'lodash/includes';

import isExternalLink from '@features/core/routing/isExternalLink';

import {
  redirectToSpecificRoute,
  setLocation,
} from '@common/providers/router/helper';

import { PageName } from './linkAliases';

export default (e: MouseEvent<HTMLElement>): boolean => {
  const targetLink = e.target as Element;
  const linkHref: string = targetLink.closest('a')?.getAttribute('href') || '';

  if (!targetLink || targetLink.id === 'portal') {
    return false;
  }

  if (
    targetLink.closest('img')?.parentElement?.className === 'image-list-items'
  ) {
    setLocation(PageName.SPONSORING);
  }

  if (
    linkHref &&
    !isExternalLink(linkHref) &&
    !includes(targetLink.className, 'static')
  ) {
    e.preventDefault();
    redirectToSpecificRoute(linkHref);
    return true;
  }
  return true;
};

import services from '@features/core/services';

import { DynamicCalls } from '@common/api/api';
import { apiHandler, MethodType } from '@common/api/apiHandler';
import { ICustomError } from '@common/interfaces';
import {
  IFetchChangePasswordResponse,
  IGetNewPasswordPayload,
} from '@common/providers/account/types';

const { API_CHANGE_PASSWORD } = DynamicCalls;

const fetchChangePassword = (
  payload: IGetNewPasswordPayload,
): Promise<IFetchChangePasswordResponse & ICustomError> =>
  apiHandler<IFetchChangePasswordResponse>(
    { method: MethodType.Post, data: payload },
    {
      apiUrl: API_CHANGE_PASSWORD,
      apiData: {
        dynamicLanguage: services.domainLang,
      },
    },
  );

export default fetchChangePassword;

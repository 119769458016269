import styled from 'styled-components';

import { TextNormal } from '@features/core/styles/text.styled';

import { hexToRgba } from '@common/helpers/deviceUtil';

export const Root = styled.div`
  height: 60px;
  background-color: ${({ theme }) => theme.textMain};
  display: flex;
  align-items: center;
  padding: ${({ theme }) => theme.space_5};
  box-sizing: border-box;
  box-shadow: 0 1px 1px -2px ${({ theme }) => hexToRgba(theme.textTertiary, 0.2)};

  .closeIcon {
    opacity: 0.8;
    min-width: 30px;
  }

  @media screen and (max-width: 300px) {
    grid-template-columns: 30px 1fr 75px;
  }
`;

export const CenterSection = styled.div`
  display: flex;
  align-items: center;
  flex-grow: 1;
`;

export const TextWrapper = styled.div`
  font-stretch: normal;
  font-style: normal;
  padding: 0 ${({ theme }) => theme.space_5} 0 ${({ theme }) => theme.space_10};

  p:first-child {
    font-weight: ${({ theme }) => theme.fontBold};
  }

  @media screen and (max-width: 300px) {
    font-size: ${(props): string => props.theme.fontXS};
  }
`;

export const TextContent = styled(TextNormal)`
  color: ${(props): string => props.theme.inputDefaultText};
`;

export const RightSection = styled.div`
  padding-right: ${({ theme }): string =>
    theme.isDesktop ? theme.space_20 : '0'};

  .adv-banner-btn {
    padding: ${({ theme }) => theme.space_10};
    min-width: 70px;
    background-color: ${({ theme }) => theme.primaryBackground};
    color: ${({ theme }) => theme.textMain};
  }

  @media screen and (max-width: 300px) {
    button {
      font-size: ${(props): string => props.theme.fontXS};
    }
  }
`;

export const ImageStyled = styled.img`
  display: block;
  border-radius: 10%;
  width: 32px;
  height: 32px;
`;

import React from 'react';
import includes from 'lodash/includes';

import ExtraMarketMultiple from '@features/events/components/markets/extraMarketMultiple/ExtraMarketMultiple';
import ExtraMarketTriple from '@features/events/components/markets/extraMarketTriple/ExtraMarketTriple';
import {
  CollapsableMarket,
  DisabledMarketDual,
  DisabledMarketTriple,
  ExtraCorrectScore,
  ExtraMarketDual,
} from '@features/events/components/markets';

import {
  marketTypePredictionsMap,
  is2Way,
} from '@common/helpers/markets/marketModel';
import { MarketType } from '@common/interfaces';

import { IMapMarket } from './MapMarket.types';

const MapMarket: React.FC<IMapMarket> = props => {
  const {
    disabled,
    type,
    market,
    hasLabel,
    sportName,
    eventId,
    listType,
    pageType,
    hasSpecialValue = false,
  } = props;

  const isDualMarket =
    market?.predictions?.length === 2 || is2Way(sportName, type);
  const isTripleMarket = market?.predictions?.length === 3;

  const isExtraMarketMultiple =
    !disabled && marketTypePredictionsMap[type] === 'multiple';

  const isExtraMarketTriple =
    (!disabled && marketTypePredictionsMap[type] === 'triple') ||
    isTripleMarket;

  const isExtraMarketDual =
    (!disabled && marketTypePredictionsMap[type] === 'dual') || isDualMarket;

  const isCollapsableMarket =
    !disabled && includes([MarketType.GOALSCORER], type);

  const isExtraCorrectScore =
    !disabled &&
    includes(
      [
        MarketType.CORRECT_SCORE,
        MarketType.CORRECT_SCORE_SETS,
        MarketType.CORRECT_SCORE_LEGS_SET,
      ],
      type,
    );

  if (market) {
    if (isExtraMarketMultiple) {
      return (
        <ExtraMarketMultiple
          market={market}
          hasLabel={hasLabel}
          hasSpecialValue={hasSpecialValue}
          listType={listType}
          pageType={pageType}
          eventId={eventId}
        />
      );
    }

    if (isExtraCorrectScore) {
      return (
        <ExtraCorrectScore
          market={market}
          hasLabel={hasLabel}
          hasSpecialValue={hasSpecialValue}
          listType={listType}
          pageType={pageType}
          eventId={eventId}
        />
      );
    }
    if (isCollapsableMarket) {
      return (
        <CollapsableMarket
          market={market}
          hasLabel={hasLabel}
          hasSpecialValue={hasSpecialValue}
          listType={listType}
          pageType={pageType}
          eventId={eventId}
          predictionsMinNumber={8}
        />
      );
    }
    if (isExtraMarketTriple) {
      return (
        <ExtraMarketTriple
          market={market}
          hasLabel={hasLabel}
          hasSpecialValue={hasSpecialValue}
          listType={listType}
          pageType={pageType}
          eventId={eventId}
        />
      );
    }

    if (isExtraMarketDual) {
      return (
        <ExtraMarketDual
          market={market}
          hasLabel={hasLabel}
          hasSpecialValue={hasSpecialValue}
          listType={listType}
          pageType={pageType}
          eventId={eventId}
        />
      );
    }
  }

  return marketTypePredictionsMap[type] === 'dual' || isDualMarket ? (
    <DisabledMarketDual
      hasLabel={hasLabel}
      pageType={pageType}
      hasSpecialValue={hasSpecialValue}
    />
  ) : (
    <DisabledMarketTriple
      hasLabel={hasLabel}
      pageType={pageType}
      hasSpecialValue={hasSpecialValue}
    />
  );
};

export default MapMarket;

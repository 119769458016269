import { generatePath, matchPath } from 'react-router-dom';
import includes from 'lodash/includes';

import { ROUTERS_MAPPER } from '@features/core/routing/routerMapper';
import services from '@features/core/services';

export enum PageName {
  SPORT = 'SPORT',
  HOME = 'HOME',
  LIVE = 'LIVE',
  SUPPORT = 'SUPPORT',
  PRESSE = 'PRESSE',
  UNTERNEHMEN_PRESSE_DETAIL = 'UNTERNEHMEN_PRESSE_DETAIL',
  UNTERNEHMEN_PRESSE = 'UNTERNEHMEN_PRESSE',
  NEWS = 'NEWS',
  EM_NEWS = 'EM_NEWS',
  NEWS_CATEGORY = 'NEWS_CATEGORY',
  NEWS_ITEM = 'NEWS_ITEM',
  LOGIN = 'LOGIN',
  REGISTER = 'REGISTER',
  FULL_REGISTER = 'FULL_REGISTER',
  FORGOT_PASSWORD = 'FORGOT_PASSWORD',
  BETTING_SLIP = 'BETTING_SLIP',
  INFORMATION = 'INFORMATION',
  COMPANY = 'COMPANY',
  RESPONSIBILITY = 'RESPONSIBILITY',
  PROMOTIONS = 'PROMOTIONS',
  PROMOTIONS_PAGES = 'PROMOTIONS_PAGES',
  ODDS_COMPARISON = 'ODDS_COMPARISON',
  REGISTRATION_BONUS = 'REGISTRATION_BONUS',
  REGISTRATION_SUCCESS = 'REGISTRATION_SUCCESS',

  USER_BETS = 'USER_BETS',
  USER_BET_ITEM = 'USER_BET_ITEM',
  USER_OVERVIEW = 'USER_OVERVIEW',
  TICKET_SCANNER = 'TICKET_SCANNER',
  SCANNER_RESULT = 'SCANNER_RESULT',
  CASHOUT_SCANNER_RESULT = 'CASHOUT_SCANNER_RESULT',
  MANUAL_BARCODE_ENTRY = 'MANUAL_BARCODE_ENTRY',

  USER = 'USER',
  USER_PAYMENTS = 'USER_PAYMENTS',
  USER_PAYIN = 'USER_PAYIN',
  USER_PAYOUT = 'USER_PAYOUT',
  USER_PAYIN_INFO_BANK = 'USER_PAYIN_INFO_BANK',
  USER_PAYIN_INFO_METHOD = 'USER_PAYIN_INFO_METHOD',
  USER_PAYIN_INFO_SKRILL = 'USER_PAYIN_INFO_SKRILL',
  USER_PAYIN_CARD_AMOUNT = 'USER_PAYIN_CARD_AMOUNT',
  USER_PAYIN_QR = 'USER_PAYIN_QR',
  USER_PAYIN_SUCCESS = 'USER_PAYIN_SUCCESS',
  USER_PAYIN_FAILURE = 'USER_PAYIN_FAILURE',
  USER_PAYIN_PREPARED = 'USER_PAYIN_PREPARED',
  PAYMENT_METHODS = 'PAYMENT_METHODS',

  USER_PAYOUT_BANK_AMOUNT = 'USER_PAYOUT_BANK_AMOUNT',
  USER_PAYOUT_SUCCESS = 'USER_PAYOUT_SUCCESS',
  USER_PAYOUT_FAILURE = 'USER_PAYOUT_FAILURE',
  USER_PAYOUT_PREPARED = 'USER_PAYOUT_PREPARED',

  USER_LIMITS = 'USER_LIMITS',
  USER_LIMITS_HISTORY = 'USER_LIMITS_HISTORY',
  USER_LIMITS_LOCK = 'USER_LIMITS_LOCK',
  USER_NOTIFICATIONS = 'USER_NOTIFICATIONS',
  USER_PROFILE = 'USER_PROFILE',
  USER_CHANGE_PASSWORD = 'USER_CHANGE_PASSWORD',
  USER_VERIFICATION = 'USER_VERIFICATION',
  USER_VERIFICATION_YEARLY = 'USER_VERIFICATION_YEARLY',
  USER_EMAIL_VERIFICATION_SUCCESS = 'USER_EMAIL_VERIFICATION_SUCCESS',
  USER_DELETE_ACCOUNT = 'USER_DELETE_ACCOUNT',
  USER_CASHOUT = 'USER_CASHOUT',
  USER_CASHOUT_ITEM = 'USER_CASHOUT_ITEM',
  USER_BONUSES = 'USER_BONUSES',
  USER_GDPR = 'USER_GDPR',
  USER_UPLOADS = 'USER_UPLOADS',
  USER_TAB = 'USER_TAB',
  USER_INBOX = 'USER_INBOX',
  USER_INBOX_MESSAGE = 'USER_INBOX_MESSAGE',
  MY_FAVORITO = 'MY_FAVORITO',
  EVENTS = 'EVENTS',
  EVENTS_ITEM = 'EVENTS_ITEM',
  EVENTS_ITEM_LMT = 'EVENTS_ITEM_LMT',
  EVENTS_CATEGORY = 'EVENTS_CATEGORY',

  MIGRATION = 'MIGRATION',
  SPONSORING = 'SPONSORING',
  FAQ_LUGAS = 'FAQ_LUGAS',
  REGISTRATION_DISABLED = 'REGISTRATION_DISABLED',
  CREATE_ONLINE_ACCOUNT = 'CREATE_ONLINE_ACCOUNT',
  UPDATE_ONLINE_ACCOUNT = 'UPDATE_ONLINE_ACCOUNT',
  YEARLY_DATA_CHECK = 'YEARLY_DATA_CHECK',
  CASINO = 'CASINO',
  STATIC = 'STATIC',
  NOT_FOUND = 'NOT_FOUND',
  TEST_PAGE = 'TEST_PAGE',
  LITTLE_CASHOUT = 'LITTLE_CASHOUT',
}

export enum StaticSlugPageName {
  PRIVACY_POLICY = 'PRIVACY_POLICY',
  STATISTICS = 'STATISTICS',
  APP_LINKS = 'APP_LINKS',
  TERMS = 'TERMS',
  AGE_WARNING = 'AGE_WARNING',
  RESPONSIBLE_GAMING = 'RESPONSIBLE_GAMING',
}

export const StaticPages = {
  [PageName.SUPPORT]: true,
  [PageName.SPONSORING]: true,
  [PageName.INFORMATION]: true,
  [PageName.COMPANY]: true,
  [PageName.RESPONSIBILITY]: true,
  [PageName.FAQ_LUGAS]: true,
  [PageName.PROMOTIONS_PAGES]: true,
};

export const StaticSlug = {
  [StaticSlugPageName.PRIVACY_POLICY]: {
    en: 'privacypolicy',
    de: 'datenschutz',
  },
  [StaticSlugPageName.STATISTICS]: {
    en: 'statistics',
    de: 'statistiken',
  },
  [StaticSlugPageName.APP_LINKS]: {
    en: 'app',
    de: 'app',
  },
  [StaticSlugPageName.TERMS]: {
    en: 'terms-conditions',
    de: 'allgemeine-geschftsbedingungen',
  },
  [StaticSlugPageName.AGE_WARNING]: {
    en: 'youth-protection',
    de: 'jugendschutz',
  },
  [StaticSlugPageName.RESPONSIBLE_GAMING]: {
    en: 'responsible-gaming',
    de: 'responsible-gaming',
  },
};

export const getPageURL = (
  p: PageName,
  query?: Record<string, string>,
  lang?: string,
): string => {
  const { domainLang } = services;
  return generatePath(ROUTERS_MAPPER[p][lang || domainLang || 'de'], query);
};

export const getStaticPageURL = (
  route: PageName,
  id?: StaticSlugPageName,
): string => {
  const { domainLang } = services;

  if (id && includes(StaticSlugPageName, id)) {
    return getPageURL(route, {
      id: StaticSlug[id][domainLang || 'de'],
    });
  }

  return getPageURL(route);
};

export const createFullLink = (
  route: PageName,
  staticSlug?: StaticSlugPageName,
): string => {
  return `${window.location.protocol}//${window.location.host}${
    staticSlug ? getStaticPageURL(route, staticSlug) : getPageURL(route)
  }`;
};

export const comparePageWithCurrentLocation = (pageName: PageName): boolean =>
  !!matchPath(window.location.pathname, {
    path: ROUTERS_MAPPER[pageName][services.domainLang],
    exact: true,
    strict: false,
  });

import React from 'react';

import {
  ItemDiv,
  ScoreDiv,
} from '@features/events/components/scoreboards/factoryScoreboards';
import AnimatedScoreValue from '@features/events/components/scoreboards/scores/common/AnimatedScoreValue';

import {
  getAwayQuarter,
  getHomeQuarter,
  getTotalScoreHome,
  getTotalScoreAway,
} from '@common/helpers/eventsHelper/scoreboards/scoreBoardModel';
import { IScore } from '@common/interfaces';
import { parseEndedScore } from '@common/helpers/eventsHelper/eventLabelHelper';

const getTotalScore = (score: IScore): React.ReactElement => {
  if (score.score) {
    return (
      <ItemDiv>
        <ScoreDiv total>
          <AnimatedScoreValue score={getHomeQuarter(score) as string} />
        </ScoreDiv>
        <ScoreDiv total>
          <AnimatedScoreValue score={getAwayQuarter(score) as string} />
        </ScoreDiv>
      </ItemDiv>
    );
  }
  if (score.match_score) {
    return (
      <ItemDiv>
        <ScoreDiv total>
          <AnimatedScoreValue score={getTotalScoreHome(score)} />
        </ScoreDiv>
        <ScoreDiv total>
          <AnimatedScoreValue score={getTotalScoreAway(score)} />
        </ScoreDiv>
      </ItemDiv>
    );
  }
  return (
    <ItemDiv>
      <ScoreDiv total>
        <AnimatedScoreValue score={parseEndedScore(score)[0]} />
      </ScoreDiv>
      <ScoreDiv total>
        <AnimatedScoreValue score={parseEndedScore(score)[1]} />
      </ScoreDiv>
    </ItemDiv>
  );
};

export default getTotalScore;

import replace from 'lodash/replace';
import find from 'lodash/find';
import includes from 'lodash/includes';

import services from '@features/core/services';
import i18n from '@features/core/translation';
import CustomError from '@features/core/error/error';

import { LUGAS_BLOCK_LAYER, LUGAS_COOLDOWN } from '@common/constants/dialogs';
import { LUGAS_TIMER, REALITY_TIMER } from '@common/constants/cookie';
import { HAS_LUGAS_STRICT } from '@common/constants/config';
import fetchBets from '@common/api/bets/fetchBets';
import { StaticCalls } from '@common/api/api';
import fetchPocketTags from '@common/api/account/fetchPocketTags';
import {
  csrfTokenResponse,
  IBettingslipResponse,
  ICustomError,
} from '@common/interfaces';
import hasLugasRestriction from '@common/helpers/userHelper/hasLugasRestrictions';
import { apiHandler, MethodType } from '@common/api/apiHandler';
import apiRpcRequest from '@common/api/common/apiCommonRequest';
import { openSidebar } from '@common/providers/application/useAppState';
import {
  hasQuotenboostSelections,
  isQuotenboost,
} from '@common/helpers/eventsHelper/eventStatusHelper';
import { useBettingSlip } from '@common/providers/bettingslip/useBettingSlip';

const { API_TOKENS } = StaticCalls;
type apiResponse = IBettingslipResponse & ICustomError;

export const postBettingSlip = async (payload): Promise<apiResponse> => {
  const body = payload?.amount
    ? {
        id: 1,
        method: 'sportsbook.place_bets_v2',
        params: payload,
      }
    : {
        id: 2,
        method: 'sportsbook.get_bet_queue_status',
        params: payload,
      };

  const message = i18n.t('common.labels.defaultError');
  if (
    hasQuotenboostSelections(useBettingSlip.getState().selections) &&
    !payload.bet_id
  ) {
    const bets = await fetchBets({
      offset: 0,
      items: 1000,
      maxAgeDays: 14,
      settled: 'all',
      list: 'boost',
    });
    const hasQuotenboost = find(bets.user_bets, e =>
      find(e.selections, event => isQuotenboost(event.event_name as string)),
    );
    if (hasQuotenboost) {
      return new CustomError({
        message: i18n.t('bettingslip.err_boost_already_played'),
      }) as apiResponse;
    }
  }
  const { csrfSec, csrfToken } = await apiHandler<csrfTokenResponse>(
    { method: MethodType.Get },
    { apiUrl: API_TOKENS },
  );

  if (
    hasLugasRestriction() &&
    payload.amount &&
    !services.cookie.get(LUGAS_TIMER)
  ) {
    const lugasActive = await fetchPocketTags({
      method: MethodType.Post,
      params: { tag: { key: 'usr.activity', value: 'on' } },
    });

    if (
      services.config.get(HAS_LUGAS_STRICT) &&
      find(lugasActive.chain, e => e.tag?.value === 'F1022') &&
      lugasActive?.tag?.status === 'rejected'
    ) {
      openSidebar({ type: LUGAS_COOLDOWN });
      return new CustomError({
        message,
        code: '49100',
      }) as apiResponse;
    }
    if (
      !services.config.get(HAS_LUGAS_STRICT) &&
      lugasActive?.tag?.value !== 'on'
    ) {
      openSidebar({ type: LUGAS_BLOCK_LAYER });
      return new CustomError({
        message,
        code: '49100',
      }) as apiResponse;
    }

    if (
      services.config.get(HAS_LUGAS_STRICT) &&
      !find(lugasActive.chain, e => e.tag?.value === 'F0002') &&
      !find(lugasActive.chain, e =>
        includes(e.tag?.value, 'no authority id for player'),
      ) &&
      lugasActive?.tag?.status === 'rejected'
    ) {
      openSidebar({ type: LUGAS_BLOCK_LAYER });
      return new CustomError({
        message,
        code: '49100',
      }) as apiResponse;
    }
  }

  const headers = {
    'CSRF-TOKEN': replace(`${csrfToken}-${btoa('55')}`, '=', ''),
    'CSRF-SEC': csrfSec,
  };

  const response = (await apiRpcRequest({
    method: MethodType.Post,
    headers,
    data: body,
  })) as apiResponse;

  if (response instanceof CustomError) {
    return response;
  }
  // return ({
  //   result: {
  //     bet_id: '7209226',
  //     is_accepted: 0,
  //     is_queued: 0,
  //     is_rejected: 1,
  //     queue_delay: 0,
  //   },
  // } as unknown) as apiResponse;

  if (response?.result?.is_accepted) {
    if (!services.cookie.get(LUGAS_TIMER) && hasLugasRestriction()) {
      await fetchPocketTags({
        method: MethodType.Post,
        params: {
          tag: { key: 'usr.front.activitystart', value: String(Date.now()) },
        },
      });
      services.cookie.set(LUGAS_TIMER, String(Date.now()), { expires: null });
    }
    if (!services.cookie.get(REALITY_TIMER)) {
      services.cookie.set(REALITY_TIMER, String(Date.now()), {
        expires: null,
      });
    }
  }

  return response;
};

export default postBettingSlip;

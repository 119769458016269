import { ICustomError, IUser } from '@common/interfaces';

export enum UserLoadingState {
  LOGGED_OUT = 0,
  VERIFYING = 1,
  LOADING = 2,
  PROCESSING = 3,
  LOGGED_IN = 4,
}

export interface IUserState {
  data: IUser | null;
  error: ICustomError | null;
  cashoutStatus?: string;
  switchAccountLoading: boolean;
  onlineAccountError: string;
  onlineAccountSuccess: boolean;
  onlineAccountLoading: boolean;
  loadingState: UserLoadingState;
}

export const userDefaultState: IUserState = {
  error: null,
  data: null,
  cashoutStatus: '',
  switchAccountLoading: false,
  onlineAccountError: '',
  onlineAccountSuccess: false,
  onlineAccountLoading: false,
  loadingState: UserLoadingState.LOGGED_OUT,
};

export type IFetchSwitchAccount = {
  result: {
    secret_token: string;
    session_id: string;
    user_id: string;
  };
};

export type ICreateOnlineAccount = {
  result?: {
    user_id?: string;
    account_id: string;
    description: string;
    error?: number;
    message?: string;
  };
};

export type IUpdateOnlineAccountPayload = {
  email: string;
  newsletter_agree: boolean;
  maidenname?: string;
  country_code?: string;
  country_of_birth?: string;
  place_of_birth?: string;
  nationality?: string;
  address_street_name?: string;
  address_street_number?: string;
  address_zip_code?: string;
  address_city?: string;
  region?: string;
  deposit_monthly_limit?: string;
};

export type ICreateOnlineAccountPayload = {
  email: string;
  newsletter_agree: boolean;
  deposit_monthly_limit?: string;
};

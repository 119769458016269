import React from 'react';
import { useTranslation } from 'react-i18next';
import split from 'lodash/split';

import * as S from './Static.styled';
import { IStatic } from './Static.types';

const Static: React.FC<IStatic> = props => {
  const { t } = useTranslation();
  const { result, type } = props;
  const score = result ? split(result, ':') : '';

  return (
    <>
      {type === 'finished' && (
        <span className="scoreboard-time">{t('bets.finished')}</span>
      )}
      {type === 'started' && (
        <span className="scoreboard-time">{t('bets.already_started')}</span>
      )}
      {type === 'cancelled' && (
        <span className="scoreboard-time">
          {t('bets.filters_keys.cancelled')}
        </span>
      )}
      {type === 'live' && (
        <span className="scoreboard-time">{t('bets.live_short')}</span>
      )}
      {score.length > 1 ? (
        <S.Scoreboard className="team-scoreboard">
          <span className="team">{score[0]}</span>
          <span className="team">{score[1]}</span>
        </S.Scoreboard>
      ) : null}
    </>
  );
};

export default Static;

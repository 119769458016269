import React, { useMemo } from 'react';

import {
  getSpecialValue,
  getType,
  hasSpecialValueInLabel,
} from '@common/helpers/markets/marketModel';
import { PageType } from '@common/interfaces';
import { useEventsListState } from '@common/providers/events/eventList/useEventsList';

import * as S from './SpecialValue.styled';
import { Props } from './SpecialValue.types';

const SpecialValue = (props: Props): React.ReactElement => {
  const { hasSpecialValue, market, pageType, event } = props;
  const selectedEvent = useEventsListState(state => state.detail.selectedEvent);

  let labelIsNotVisibleWithSV = true;
  const SV = useMemo(() => getSpecialValue(market, event), [
    event,
    market.special_value,
  ]);
  const hasSVInLabel = useMemo(() => hasSpecialValueInLabel(getType(market)), [
    market,
  ]);
  const value = SV ? `${SV}` : '';

  const isLongSpecialValue = value.length > 6;
  if (pageType === PageType.DETAIL) {
    labelIsNotVisibleWithSV = !!(!hasSVInLabel && getSpecialValue(market));
  }
  if (
    (!!value.length && hasSpecialValue) ||
    (labelIsNotVisibleWithSV && pageType === PageType.DETAIL)
  ) {
    return (
      <S.SpecialValueDiv
        isLongSpecialValue={isLongSpecialValue && !!selectedEvent}
        dangerouslySetInnerHTML={{ __html: value }}
      />
    );
  }
  return <></>;
};

export default SpecialValue;

import services from '@features/core/services';

import { MAIN_USER_ID, USER_ID } from '@common/constants/cookie';

/**
 * isMainUserId
 * checks if user id is the id which user logged in with (id can be switched by wallets);
 *
 * @returns {boolean} isMainUserId
 */
export default (): boolean => {
  const mainUserId = services.cookie.get(MAIN_USER_ID);
  const userId = services.cookie.get(USER_ID);

  return userId === mainUserId;
};

import { scrollTo } from '@common/helpers/scrollHelper';

const ScrollTopElement = (elementClassName: string): void => {
  const element: HTMLElement | null = document.querySelector(
    `.${elementClassName}`,
  );

  if (element) {
    scrollTo(element, {
      top: 0,
      behavior: 'smooth',
    });
  }
};

export default ScrollTopElement;

import { IBetPackerResponse } from '@common/interfaces';
import { ICustomError } from '@common/interfaces/responses/IError';
import { IBetPackerOdds } from '@common/providers/bettingslip/types';
import apiRpcRequest from '@common/api/common/apiCommonRequest';
import { MethodType } from '@common/api/apiHandler';

type apiResponse = Promise<IBetPackerResponse & ICustomError>;

const fetchBetPackerData = (payload: IBetPackerOdds): apiResponse => {
  const body = {
    method: 'betbuilder.get_odds',
    params: payload,
  };

  return apiRpcRequest({ method: MethodType.Post, data: body }) as apiResponse;
};

export default fetchBetPackerData;

import assign from 'lodash/assign';
import indexOf from 'lodash/indexOf';
import toString from 'lodash/toString';
import reduce from 'lodash/reduce';
import split from 'lodash/split';

import services from '@features/core/services';

import { PageType } from '@common/interfaces/events/IEventList';
import { ICategory, IEvent, IEventData } from '@common/interfaces';
import { extendCollectionsWithIDs } from '@common/helpers/deviceUtil';
import { addSuspendedReason } from '@common/helpers/betstopReasonHelper';

import {
  getPaddedId,
  getSportWeightString,
} from '../categories/categoriesModel';
import { isCategoryFavorite, isEventFavorite } from '../favoritesHelper';

import { isLiveCurrent, isLiveOrSoon } from './eventStatusHelper';
import { startTimers } from './scoreboards/timerModel';

/**
 * parseEventList
 * parses event list and adds ids to all entities
 * so we can omit this calculation later
 *
 * @param {IEventData} unparsedList
 * @param {boolean} short
 * @returns {IEventData} parsedEventList
 */
export const parseEventList = (
  unparsedList: IEventData,
  short = false,
): IEventData => {
  return assign(
    unparsedList,
    {
      categories: extendCollectionsWithIDs(unparsedList.categories || {}),

      events: extendCollectionsWithIDs(
        unparsedList.events
          ? addSuspendedReason(startTimers(unparsedList.events))
          : {},
      ),
    },
    !short
      ? {
          markets: extendCollectionsWithIDs(unparsedList.markets || {}),
          predictions: extendCollectionsWithIDs(unparsedList.predictions || {}),
        }
      : {},
  );
};

/**
 * sortEvents
 * sort events
 * sort events for highlights
 * sort events for specific categories
 * sort events for live and prematches(live and highlight live, after show prematches)
 * after user click filters
 *
 * @param {{
 *   event: IEvent;
 *   category: ICategory;
 *   highlightedCategoryWeights: Record<string, number>;
 *   highlightedEvents: string[];
 *   categories: string[];
 *   favoritesEvents: string[];
 *   pageType: string;
 * }} params
 * @returns {string | number} sortedEvents
 */
export const sortEvents = ({
  event,
  category,
  highlightedCategoryWeights,
  highlightedEvents,
  categories = [],
  pageType,
  favoritesEvents,
  favoritesCategories,
  sortEventsByTime,
}: {
  event: IEvent;
  category: ICategory;
  highlightedCategoryWeights: Record<string, number>;
  highlightedEvents: string[];
  categories: string[];
  pageType: string;
  favoritesEvents: Record<string, string>;
  favoritesCategories: Record<string, string>;
  sortEventsByTime: boolean;
}): string | number => {
  const isDesktop = services.isDesktopView;
  const sportWeight = getSportWeightString(category) || '999';
  const comparatorExpires = `${event.expires_ts}-${getPaddedId(
    category?.id,
  )}-${getPaddedId(event.id, 7)}`;

  if (categories?.length > 1 && isDesktop && sortEventsByTime) {
    return comparatorExpires;
  }

  if (categories?.length) {
    if (!isDesktop) {
      return `${comparatorExpires}`;
    }
    const categoryIndex = indexOf(categories, event.category_id);
    const categoryPriority = categoryIndex !== -1 ? categoryIndex : 999;
    return `${categoryPriority}-${comparatorExpires}`;
  }

  if (pageType === PageType.HOME) {
    return isLiveCurrent(event)
      ? indexOf(highlightedEvents, event.id) - highlightedEvents.length
      : indexOf(highlightedEvents, event.id);
  }

  /*
   * we show all live events in next 24hours, ordered into group A (Live + LiveSoon) and group B (for Prematch).
   * Group A has events grouped by sports, whereas for each sport group
   * we show first their live + livesoon Highlights and then the remaining live+livesoon Live Events.
   * Group B shows only prematch events for next 24hours, also grouped by sports. First all Fußball events, then all tennis events, etc.
   * Fußball Highlights Live
   * Fußball Live
   *
   * Tennis Highlights Live
   * Tennis Live
   * --- separator with text ---
   * Fußball Prematch
   * Tennis Prematch
   */
  const isHighlighted = !!highlightedCategoryWeights[event.category_id];
  const isStartingTime = isLiveOrSoon(event);

  const isFavorite =
    isStartingTime &&
    (isEventFavorite(event.id, favoritesEvents) ||
      isCategoryFavorite(event.category_id, favoritesCategories));

  if (isFavorite) {
    if (isHighlighted) {
      return `A-${sportWeight}-A-${comparatorExpires}`;
    }
    return `A-${sportWeight}-B-${comparatorExpires}`;
  }
  if (isStartingTime) {
    // live+live soon section
    if (isHighlighted) {
      return `B-${sportWeight}-B-${comparatorExpires}`; // highlights within a live sport section
    }
    return `B-${sportWeight}-C-${comparatorExpires}`; // no highlight within a live sport section
  }
  return `D-${sportWeight}-A-${comparatorExpires}`; // prematch section with no separate highlight section
};

/**
 * convertEventLiveCodeToNumeric
 * For Live codes "event_code_live" aka "short codes" for showing Event Detail Event Id
 * Directly convert the short code from hex to dec. Max short code is "DEF"
 *
 * @param {string} eventLiveCode
 * @returns {string} convertEventLiveCodeToNumeric
 */
export const convertEventLiveCodeToNumeric = (
  eventLiveCode: string,
): string => {
  return toString(parseInt(eventLiveCode, 16));
};

/**
 * convertEventCodeToNumeric
 * For long codes "event_code" for showing Event Detail Event Id if event_code_live is not present
 * Consider each alphanum character as a bit flag and use the resulting number
 * Add the maximum shortcode (DEF) so there's no collision
 *
 * @param {string} eventCode
 * @returns {string} convertEventCodeToNumeric
 */
export const convertEventCodeToNumeric = (eventCode: string): string => {
  return toString(
    reduce(
      split(eventCode, ''),
      (sum, c) => {
        // eslint-disable-next-line no-bitwise
        return (2 ** (parseInt(c, 16) - 1)) | sum;
      },
      0,
    ) + 0xdef,
  );
};

/**
 * getEventGameId
 * Get event game id for Event Detail header from event_code_live or event.event_code
 *
 * @param {IEvent} event
 * @returns {string} getEventGameId
 */

export const getEventGameId = (event: IEvent): string => {
  const useEventCode =
    event?.event_code && event?.event_code !== '0'
      ? convertEventCodeToNumeric(event.event_code)
      : '';

  return event?.event_code_live && event?.event_code_live !== '0'
    ? convertEventLiveCodeToNumeric(event.event_code_live)
    : useEventCode;
};

import apiRpcRequest from '@common/api/common/apiCommonRequest';
import { MethodType } from '@common/api/apiHandler';
import { IBonus } from '@common/interfaces';

const fetchActiveBonus = (): Promise<{
  result: { bonus: IBonus };
}> => {
  const params = {
    method: 'user.get_bonus',
  };

  return apiRpcRequest({ method: MethodType.Get, params }) as Promise<{
    result: { bonus: IBonus };
  }>;
};

export default fetchActiveBonus;

import styled from 'styled-components';

import { TextSM } from '@features/core/styles/text.styled';

export const WrapperInfo = styled.div`
  background: ${({ theme }) => theme.textMain};
  padding: ${({ theme }) => theme.space_10} ${({ theme }) => theme.space_15};
  color: ${({ theme }) => theme.statusSuccess};

  svg path {
    fill: ${({ theme }) => theme.statusSuccess};
  }
`;

export const Header = styled(TextSM)`
  font-weight: ${({ theme }) => theme.fontBold};
  display: grid;
  grid-auto-flow: column;
  align-items: center;
  justify-items: start;
  justify-content: start;
`;

export const Title = styled.span`
  margin-left: ${({ theme }) => theme.space_10};
`;

import React from 'react';
import { useTranslation } from 'react-i18next';
import map from 'lodash/map';
import { useTheme } from 'styled-components';

import { SingleIcon } from '@ui/components/icon';

import * as S from './Description.styled';

const Description: React.FC<{ isOnline: boolean }> = ({ isOnline }) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const allowIcon = 'allow-filled';
  const denyIcon = 'switcher-cross';

  const items = [
    {
      icon: allowIcon,
      text: t('wallet_switcher.cashout'),
      fill: [theme.statusSuccess, theme.textMain],
    },
    {
      icon: isOnline ? allowIcon : denyIcon,
      text: t('wallet_switcher.betting'),
      fill: isOnline ? [theme.statusSuccess, theme.textMain] : theme.textMain,
    },
    {
      icon: isOnline ? allowIcon : denyIcon,
      text: t('wallet_switcher.deposit_withdraw'),
      fill: isOnline ? [theme.statusSuccess, theme.textMain] : theme.textMain,
    },
  ];

  return (
    <S.Root>
      {map(items, item => {
        const isDenyIcon = item.icon === denyIcon;
        return (
          <S.Item>
            <S.IconBackground isDenyIcon={isDenyIcon}>
              <SingleIcon
                name={item.icon}
                width={isDenyIcon ? '7px' : '16px'}
                height={isDenyIcon ? '7px' : '16px'}
                fill={item.fill}
              />
            </S.IconBackground>

            <S.Text>{item.text}</S.Text>
          </S.Item>
        );
      })}
    </S.Root>
  );
};

export default Description;

import React from 'react';

const SwLogoFlat: React.FC = () => (
  <svg
    version="1.1"
    id="Ebene_1"
    data-qa="go-to-the-home-page"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 2248.9 544"
    xmlSpace="preserve"
  >
    <style type="text/css">{`.logo0{fill:#FFFFFF;} .logo1{fill:#FFED00;}`}</style>
    <g>
      <path
        className="logo0"
        d="M536.9,333.8v-26.6c14.1,5.6,30.3,8.4,48.8,8.4c13.8,0,20.6-4.4,20.6-13.3c0-8.4-4.6-12.7-13.9-12.7h-22.9
			c-25.8,0-38.6-12.7-38.6-38c0-26.6,18.8-39.8,56.4-39.8c15.9,0,30.9,2.4,45,7.2v26.6c-14.1-5.6-29.4-8.4-45.9-8.4
			c-17.3,0-26,4.4-26,13.3c0,8.4,5,12.7,15.1,12.7H596c28.2,0,42.2,12.7,42.2,38c0,26.6-17.9,39.8-53.8,39.8
			C566.8,341,551,338.6,536.9,333.8"
      />
      <path
        className="logo0"
        d="M655.7,218.3c16.8-3.5,34.6-5.2,53.4-5.2c43.1,0,64.6,20.1,64.6,60.2c0,44.3-20,66.5-60.1,66.5c-8.5,0-17.1-2-25.8-6
		v51.9h-32V218.3z M687.7,306.8c8,4.3,16.5,6.5,25.7,6.5c19.1,0,28.6-13.4,28.6-40.2c0-22.8-10.5-34.2-31.6-34.2c-9.1,0-16.7,0.6-22.7,1.7V306.8z"
      />
      <path
        className="logo0"
        d="M786.8,276.5c0-42.5,20.9-63.7,62.8-63.7c41.8,0,62.8,21.2,62.8,63.7c0,42.4-20.9,63.6-62.8,63.6
			C807.9,340.1,786.9,318.9,786.8,276.5 M849.6,315.7c20.5,0,30.8-13.2,30.8-39.7c0-25.8-10.3-38.7-30.8-38.7
			c-20.5,0-30.8,12.9-30.8,38.7C818.8,302.4,829,315.7,849.6,315.7"
      />
      <path
        className="logo0"
        d="M931.5,339.8V213.1h24.7l4,16.2c11.1-10.8,22.9-16.2,35.2-16.2v25.8c-11.9,0-22.6,4.9-32,14.8v86.1H931.5z"
      />
      <path
        className="logo0"
        d="M1016.7,192.6h23.7l4.8,20.5h31.9v25.3h-28.4v58.3c0,11.8,5.1,17.7,15.3,17.7h13v25.3h-28.5
			c-21.2,0-31.9-11.4-31.9-34.2V192.6z"
      />
      <polygon
        className="logo1"
        points="1088,213.1 1120.2,213.1 1136.6,301.3 1165.9,213.1 1193.3,213.1 1225,301.3 1239.2,213.1 
			1272.5,213.1 1239.5,339.8 1209.4,339.8 1179.6,249.9 1149.4,339.8 1118.9,339.8 	"
      />
      <path
        className="logo1"
        d="M1340.4,213.1c38.5,0,57.7,19.6,57.7,58.9c0,5.2-0.4,10.5-1.1,15.7h-84.1c0,17.9,13.1,26.8,39.4,26.8
			c12.8,0,25.6-1.2,38.4-3.6v25.3c-11.2,2.4-24.8,3.6-40.8,3.6c-46,0-68.9-21.6-68.9-64.8C1280.9,233.7,1300.7,213.1,1340.4,213.1
			M1312.9,265.5h54.1v-1c0-17.5-8.9-26.3-26.6-26.3C1323.5,238.2,1314.3,247.3,1312.9,265.5"
      />
      <path
        className="logo1"
        d="M1420.3,192.6h23.7l4.8,20.5h31.9v25.3h-28.4v58.3c0,11.8,5.1,17.7,15.3,17.7h13v25.3h-28.5
			c-21.2,0-31.9-11.4-31.9-34.2V192.6z"
      />
      <path
        className="logo1"
        d="M1500.6,192.6h23.7l4.8,20.5h31.9v25.3h-28.4v58.3c0,11.8,5.1,17.7,15.3,17.7h13v25.3h-28.5
			c-21.2,0-31.9-11.4-31.9-34.2V192.6z"
      />
      <path
        className="logo1"
        d="M1636.1,213.1c38.5,0,57.7,19.6,57.7,58.9c0,5.2-0.4,10.5-1.1,15.7h-84.1c0,17.9,13.1,26.8,39.4,26.8
			c12.8,0,25.6-1.2,38.4-3.6v25.3c-11.2,2.4-24.8,3.6-40.8,3.6c-46,0-68.9-21.6-68.9-64.8C1576.6,233.7,1596.4,213.1,1636.1,213.1
			M1608.6,265.5h54.1v-1c0-17.5-8.9-26.3-26.6-26.3C1619.2,238.2,1610,247.3,1608.6,265.5"
      />
      <path
        className="logo1"
        d="M1717.8,339.8V213.1h24.7l4,18.6c12.2-12.4,26-18.6,41.5-18.6c30,0,45,15.6,45,46.8v79.9h-32v-79.9
			c0-14-7-21-20.9-21c-10.4,0-20.5,4.1-30.4,12.4v88.5H1717.8z"
      />
      <rect x="1855.3" y="310.3" className="logo0" width="32" height="29.6" />
      <path
        className="logo0"
        d="M2016.9,332.2c-19.4,5.1-38.3,7.6-56.9,7.6c-41,0-61.4-22-61.4-65.9c0-40.6,20-60.8,60.1-60.8
			c8.5,0,17.3,2,26.2,6v-51.9h32V332.2z M1984.9,246.2c-6.6-4.3-14.9-6.5-24.9-6.5c-19.9,0-29.8,11.1-29.8,33.4
			c0,26.5,10.3,39.7,31,39.7c8.4,0,16.3-1.2,23.7-3.5V246.2z"
      />
      <path
        className="logo0"
        d="M2100.5,213.1c38.5,0,57.7,19.6,57.7,58.9c0,5.2-0.4,10.5-1.1,15.7H2073c0,17.9,13.1,26.8,39.4,26.8
			c12.8,0,25.6-1.2,38.4-3.6v25.3c-11.2,2.4-24.8,3.6-40.8,3.6c-46,0-68.9-21.6-68.9-64.8C2041,233.7,2060.8,213.1,2100.5,213.1
			M2073,265.5h54.1v-1c0-17.5-8.9-26.3-26.6-26.3C2083.6,238.2,2074.4,247.3,2073,265.5"
      />
      <path
        className="logo0"
        d="M453.4,270.2c0-0.3,0-0.6,0-0.8c0-0.4,0-0.9,0-1.3c0-0.3,0-0.5,0-0.8c0-0.5,0-0.9,0-1.4c0-0.2,0-0.5,0-0.7
			c0-0.5,0-1-0.1-1.5c0-0.2,0-0.4,0-0.5c0-0.7-0.1-1.3-0.1-2c-0.1-1.2-0.2-2.4-0.2-3.5c-0.2-2.3-0.4-4.5-0.6-6.5
			c-0.1-0.5-0.1-1-0.2-1.5c-0.1-0.6-0.2-1.2-0.2-1.8c0-0.1,0-0.3-0.1-0.4c-0.1-0.5-0.1-1-0.2-1.4c0-0.1,0-0.2,0-0.3
			c-0.1-0.6-0.2-1.1-0.3-1.6c0-0.1,0-0.1,0-0.2c-0.1-0.5-0.2-0.9-0.2-1.3c0-0.1,0-0.2-0.1-0.4c-0.1-0.4-0.2-0.8-0.2-1.2
			c0-0.1,0-0.1,0-0.2c-0.1-0.5-0.2-0.9-0.3-1.3c0-0.1,0-0.2-0.1-0.2c-0.1-0.3-0.1-0.7-0.2-1c0-0.1,0-0.2-0.1-0.3
			c-0.2-0.8-0.3-1.5-0.5-2.1c0-0.1,0-0.1,0-0.2c-0.1-0.3-0.1-0.5-0.2-0.7c0-0.1,0-0.1,0-0.2c-0.1-0.3-0.1-0.5-0.2-0.8c0,0,0,0,0-0.1
			c-0.1-0.2-0.1-0.4-0.2-0.6c0,0,0-0.1,0-0.1c-0.1-0.4-0.2-0.7-0.3-1c0,0,0,0,0,0c-0.1-0.3-0.1-0.4-0.2-0.5c0-0.1-0.1-0.2-0.1-0.3
			c0,0,0,0,0-0.1c-0.1-0.2-0.2-0.3-0.3-0.5c0,0,0,0,0-0.1c-0.1-0.1-0.1-0.2-0.2-0.2c0,0,0,0,0,0c-0.1-0.1-0.2-0.2-0.4-0.3
			c-0.1-0.1-0.2-0.2-0.4-0.2c0,0,0,0,0,0c-0.3-0.2-0.5-0.3-0.9-0.4c0,0,0,0,0,0c-0.3-0.1-0.7-0.3-1.1-0.4c0,0,0,0-0.1,0
			c-0.4-0.1-0.9-0.2-1.4-0.3c0,0,0,0-0.1,0c-0.3,0-0.5-0.1-0.8-0.1c0,0-0.1,0-0.1,0c-0.4,0-0.7-0.1-1.1-0.1c-0.3,0-0.6,0-1,0
			c-0.1,0-0.1,0-0.2,0c-0.3,0-0.7,0-1,0c-0.3,0-0.6,0-0.8,0c-0.4,0-0.8,0-1.2,0c-0.1,0-0.1,0-0.2,0c-0.8,0-1.6,0.1-2.5,0.2
			c-0.1,0-0.2,0-0.3,0c-0.4,0-0.9,0.1-1.4,0.2c-1,0.1-2,0.3-3,0.4c0,0,0,0,0,0c-1,0.1-2,0.3-3,0.5c0,0,0,0,0,0c-2,0.3-4.1,0.7-6,1
			c-0.8,0.2-1.7,0.3-2.5,0.4c-0.5,0.1-1,0.2-1.4,0.3c-0.8,0.1-1.5,0.3-2.2,0.4c-0.1,0-0.3,0.1-0.4,0.1c-2.6,0.5-4.6,1-5.6,1.2l0,0
			c-0.1,0-0.1,0-0.2,0.1c0,0,0,0-0.1,0c-0.1,0-0.1,0-0.1,0c0,0,0,0-0.1,0c0,0-0.1,0-0.1,0c0,0,0,0-0.1,0c0,0,0,0,0,0c0,0,0,0,0,0l0,0
			c-3.1,29-8.5,55.6-13.7,76.7c-7.2,28.9-14,47.4-14,47.4c-0.1-1.2-0.2-2.4-0.4-3.7c-0.2-1.6-0.3-3.1-0.5-4.7
			c-0.1-0.5-0.1-0.9-0.2-1.4c-0.1-1.2-0.3-2.5-0.4-3.7l0-0.3c-0.2-1.2-0.3-2.4-0.5-3.6c0-0.3-0.1-0.6-0.1-0.9
			c-0.4-3.1-0.9-6.2-1.3-9.3l0-0.3c-0.5-3-0.9-6-1.4-8.9c-0.1-0.3-0.1-0.6-0.2-0.9c-0.2-1.5-0.5-2.9-0.8-4.3
			c-0.1-0.3-0.1-0.6-0.2-0.9c-0.3-1.4-0.5-2.8-0.8-4.2c-0.1-0.3-0.1-0.6-0.2-0.9c-0.6-3.2-1.2-6.3-1.8-9.3c-0.2-0.8-0.3-1.6-0.5-2.3
			c-0.2-1.1-0.4-2.1-0.7-3.1c0-0.2-0.1-0.4-0.1-0.6c-0.2-1-0.4-2-0.7-3c-0.2-1-0.4-2-0.7-3c0-0.2-0.1-0.4-0.1-0.6
			c-0.2-1-0.4-1.9-0.7-2.9c-0.7-2.7-1.3-5.4-1.9-7.9c-0.3-1.3-0.6-2.5-0.9-3.7c-0.2-0.7-0.4-1.3-0.5-2c0-0.1-0.1-0.3-0.1-0.4
			c-0.1-0.5-0.3-1-0.4-1.5c0-0.2-0.1-0.3-0.1-0.5c-0.1-0.5-0.3-1-0.4-1.4c0-0.1-0.1-0.3-0.1-0.4c-0.2-0.5-0.3-1.1-0.4-1.6
			c0,0,0-0.1,0-0.1c-0.7-2.4-1.3-4.7-1.9-6.7c-0.1-0.4-0.2-0.8-0.4-1.2c-0.1-0.3-0.2-0.6-0.3-0.9c-0.3-0.9-0.5-1.7-0.8-2.5
			c-0.2-0.7-0.4-1.3-0.6-1.9c-0.1-0.3-0.2-0.7-0.3-1c-0.2-0.6-0.4-1.2-0.6-1.7c-0.2-0.7-0.4-1.3-0.6-1.8c-0.2-0.5-0.3-0.9-0.4-1.2
			c-0.3-0.9-0.5-1.4-0.5-1.4l-60.3,18l-0.2,0.3l0,0.1l-0.3,0.5c-0.1,0.9-0.3,1.9-0.4,2.8c-0.1,0.3-0.1,0.6-0.2,1
			c-0.1,0.6-0.2,1.3-0.3,1.9c-0.1,0.4-0.1,0.8-0.2,1.2c-0.1,0.6-0.2,1.2-0.3,1.7c-0.1,0.4-0.2,0.8-0.2,1.3c-0.1,0.6-0.2,1.1-0.3,1.7
			c-0.1,0.4-0.2,0.9-0.2,1.3c-0.1,0.5-0.2,1.1-0.3,1.6c-0.1,0.4-0.2,0.9-0.3,1.3c-0.1,0.5-0.2,1.1-0.3,1.6c-0.1,0.4-0.2,0.9-0.3,1.3
			c-0.1,0.5-0.2,1.1-0.3,1.6c-0.1,0.5-0.2,0.9-0.3,1.4c-0.1,0.5-0.2,1.1-0.4,1.6c-0.1,0.4-0.2,0.9-0.3,1.3c-0.1,0.6-0.3,1.1-0.4,1.7
			c-0.1,0.4-0.2,0.9-0.3,1.3c-0.1,0.6-0.3,1.2-0.4,1.8c-0.1,0.4-0.2,0.8-0.3,1.2c-0.2,0.7-0.3,1.3-0.5,2c-0.1,0.4-0.2,0.7-0.2,1.1
			c-0.2,1-0.5,2-0.7,3c0,0.1,0,0.1,0,0.2c-0.2,1-0.5,1.9-0.7,2.9c-0.1,0.3-0.2,0.7-0.3,1c-0.2,0.7-0.3,1.3-0.5,2
			c-0.1,0.4-0.2,0.8-0.3,1.2c-0.2,0.6-0.3,1.2-0.5,1.9c-0.1,0.4-0.2,0.8-0.3,1.2c-0.2,0.6-0.3,1.2-0.5,1.8c-0.1,0.4-0.2,0.8-0.3,1.2
			c-0.2,0.6-0.3,1.2-0.5,1.8c-0.1,0.4-0.2,0.8-0.3,1.2c-0.2,0.6-0.3,1.2-0.5,1.8c-0.1,0.4-0.2,0.8-0.3,1.2c-0.2,0.6-0.3,1.2-0.5,1.8
			c-0.1,0.4-0.2,0.7-0.3,1.1c-0.2,0.6-0.4,1.3-0.5,1.9c-0.1,0.3-0.2,0.7-0.3,1c-0.2,0.7-0.4,1.4-0.6,2c-0.1,0.3-0.2,0.6-0.3,0.9
			c-0.2,0.8-0.4,1.5-0.7,2.3c-0.1,0.2-0.1,0.4-0.2,0.6c-1.1,3.9-2.3,7.8-3.5,11.6c0,0.1,0,0.2-0.1,0.2c-0.3,0.9-0.5,1.7-0.8,2.5
			c0,0.1-0.1,0.3-0.1,0.4c-0.2,0.8-0.5,1.6-0.7,2.4c0,0.1-0.1,0.3-0.1,0.4c-0.2,0.8-0.5,1.5-0.7,2.3c0,0.1-0.1,0.3-0.1,0.4
			c-0.2,0.8-0.5,1.5-0.7,2.3c0,0.1-0.1,0.2-0.1,0.4c-0.2,0.8-0.5,1.6-0.7,2.3c0,0.1-0.1,0.2-0.1,0.3c-0.3,0.8-0.5,1.6-0.8,2.4
			c0,0,0,0,0,0c0,0,0,0,0,0c-1,3-1.9,5.9-2.9,8.7l0,0c0,0,0,0,0,0c-4.6,13.8-8.4,24.2-10,28.5c0,0,0,0.1,0,0.1
			c-0.1,0.3-0.2,0.7-0.3,0.9c0,0,0,0,0,0.1c-0.1,0.2-0.1,0.3-0.2,0.5c0,0.1-0.1,0.2-0.1,0.2c0,0,0,0,0,0c-0.1,0.3-0.2,0.5-0.2,0.5
			c-0.2-1-0.3-2.1-0.5-3.1c0-0.2-0.1-0.5-0.1-0.7c-0.2-1-0.3-2.1-0.5-3.1c0,0,0,0,0,0c-0.2-1.1-0.4-2.2-0.6-3.3
			c0-0.2-0.1-0.4-0.1-0.5c-0.4-2.2-0.8-4.4-1.3-6.6c0-0.1,0-0.2-0.1-0.3c-0.5-2.3-0.9-4.6-1.4-6.9c0,0,0-0.1,0-0.1
			c-0.2-1.2-0.5-2.4-0.8-3.5c-0.1-0.4-0.2-0.7-0.2-1.1c-0.4-2-0.9-4-1.4-6.1c-0.1-0.5-0.2-1-0.3-1.4c-0.3-1.1-0.5-2.3-0.8-3.4
			c-9-38.1-20.3-73.1-20.3-73.1c-1.5,0.5-2.9,1-4.4,1.5c-0.3,0.1-0.6,0.2-0.9,0.3c-1.1,0.4-2.2,0.8-3.3,1.1c-0.4,0.1-0.8,0.3-1.2,0.4
			c-1,0.3-1.9,0.7-2.8,1c-0.5,0.2-0.9,0.3-1.4,0.5c-0.9,0.3-1.8,0.6-2.6,0.9c-0.5,0.2-0.9,0.3-1.4,0.5c-0.9,0.3-1.7,0.6-2.5,0.9
			c-0.4,0.2-0.9,0.3-1.3,0.5c-0.9,0.3-1.7,0.6-2.6,0.9c-0.4,0.1-0.8,0.3-1.2,0.4c-0.9,0.3-1.9,0.7-2.8,1c-0.3,0.1-0.6,0.2-0.9,0.3
			c-1.2,0.4-2.4,0.9-3.6,1.3c-0.3,0.1-0.5,0.2-0.8,0.3c-0.7,0.3-1.5,0.6-2.2,0.8c-0.4,0.2-0.9,0.3-1.3,0.5c-0.6,0.2-1.1,0.4-1.7,0.6
			c-0.5,0.2-0.9,0.3-1.4,0.5c-0.5,0.2-1,0.4-1.6,0.6c-0.4,0.2-0.9,0.4-1.3,0.5c-0.5,0.2-1,0.4-1.5,0.6c-0.4,0.2-0.9,0.4-1.3,0.5
			c-0.5,0.2-1,0.4-1.5,0.6c-0.4,0.2-0.9,0.4-1.3,0.5c-0.5,0.2-1,0.4-1.5,0.6c-0.4,0.2-0.8,0.3-1.2,0.5c-0.6,0.2-1.1,0.5-1.7,0.7
			c-0.3,0.1-0.6,0.2-0.9,0.4c-14.9,6.2-27.3,12.2-39,18.5c0,0,0,0,0,0c-20.9,11.3-29.4,20.1-29.2,23.7c0,0.1,0,0.2,0.1,0.3
			c0.1,0.2,0.1,0.4,0.1,0.4c0.8,1.4,1.5,2.9,2.3,4.3c0.2,0.4,0.4,0.8,0.6,1.1c0.6,1,1.1,2,1.7,3c0.3,0.5,0.6,1,0.9,1.5
			c0.5,0.8,1,1.7,1.5,2.5c0.3,0.5,0.6,1.1,1,1.6c0.5,0.8,0.9,1.6,1.4,2.3c0.3,0.5,0.7,1.1,1,1.6c0.5,0.7,0.9,1.5,1.4,2.2
			c0.4,0.5,0.7,1.1,1.1,1.6c0.5,0.7,0.9,1.4,1.4,2.1c0.4,0.5,0.7,1.1,1.1,1.6c0.5,0.7,0.9,1.3,1.4,2c0.4,0.5,0.8,1,1.1,1.6
			c0.5,0.6,0.9,1.3,1.4,1.9c0.4,0.5,0.8,1,1.1,1.5c0.5,0.6,0.9,1.2,1.4,1.8c0.4,0.5,0.8,1,1.2,1.5c0.5,0.6,0.9,1.2,1.4,1.8
			c0.4,0.5,0.8,1,1.2,1.4c0.5,0.6,1,1.2,1.4,1.7c0.4,0.5,0.8,0.9,1.2,1.4c0.5,0.6,1,1.1,1.5,1.7c0.4,0.5,0.8,0.9,1.2,1.3
			c0.5,0.5,1,1.1,1.5,1.6c0.4,0.4,0.8,0.9,1.2,1.3c0.5,0.5,1,1.1,1.5,1.6c0.4,0.4,0.8,0.8,1.2,1.2c0.5,0.5,1,1,1.5,1.6
			c0.4,0.4,0.8,0.8,1.2,1.2c0.5,0.5,1,1,1.6,1.5c0.4,0.4,0.8,0.7,1.2,1.1c0.5,0.5,1.1,1,1.6,1.5c0.4,0.3,0.7,0.7,1.1,1
			c0.6,0.5,1.1,1,1.7,1.5c0.3,0.3,0.7,0.6,1,0.9c0.6,0.6,1.3,1.1,1.9,1.6c0.3,0.2,0.6,0.5,0.9,0.7c0.8,0.7,1.6,1.4,2.5,2
			c0.1,0.1,0.2,0.2,0.3,0.3c0.9,0.8,1.9,1.5,2.8,2.2c0.2,0.1,0.4,0.3,0.6,0.4c0.8,0.6,1.5,1.1,2.3,1.7c0.3,0.2,0.6,0.5,0.9,0.7
			c0.6,0.5,1.2,0.9,1.9,1.4c0.4,0.3,0.7,0.5,1.1,0.8c0.6,0.4,1.2,0.8,1.7,1.2c0.4,0.3,0.8,0.5,1.2,0.8c0.6,0.4,1.1,0.7,1.7,1.1
			c0.4,0.3,0.8,0.5,1.2,0.8c0.5,0.3,1.1,0.7,1.6,1c0.4,0.3,0.8,0.5,1.2,0.8c0.5,0.3,1.1,0.7,1.6,1c0.4,0.2,0.8,0.5,1.2,0.7
			c0.5,0.3,1.1,0.6,1.6,0.9c0.4,0.2,0.8,0.5,1.2,0.7c0.5,0.3,1,0.6,1.6,0.9c0.4,0.2,0.8,0.5,1.2,0.7c0.5,0.3,1,0.6,1.6,0.8
			c0.4,0.2,0.8,0.4,1.2,0.6c0.5,0.3,1,0.5,1.6,0.8c0.4,0.2,0.8,0.4,1.2,0.6c0.5,0.3,1,0.5,1.6,0.8c0.4,0.2,0.8,0.4,1.2,0.6
			c0.5,0.2,1,0.5,1.6,0.7c0.4,0.2,0.8,0.4,1.2,0.6c0.5,0.2,1.1,0.5,1.6,0.7c0.4,0.2,0.8,0.3,1.2,0.5c0.5,0.2,1.1,0.4,1.6,0.7
			c0.4,0.2,0.8,0.3,1.2,0.5c0.5,0.2,1.1,0.4,1.6,0.6c0.4,0.1,0.7,0.3,1.1,0.4c0.6,0.2,1.1,0.4,1.7,0.6c0.4,0.1,0.7,0.3,1.1,0.4
			c0.6,0.2,1.2,0.4,1.7,0.6c0.3,0.1,0.6,0.2,1,0.3c0.6,0.2,1.2,0.4,1.9,0.6c0.3,0.1,0.5,0.2,0.8,0.3c0.8,0.3,1.5,0.5,2.3,0.7
			c0.1,0,0.2,0.1,0.4,0.1c1.8,0.6,3.6,1.1,5.3,1.6c0.2,0.1,0.5,0.1,0.7,0.2c0.6,0.2,1.3,0.4,1.9,0.5c0.3,0.1,0.6,0.2,0.9,0.2
			c0.6,0.1,1.1,0.3,1.7,0.4c0.3,0.1,0.6,0.2,1,0.2c0.5,0.1,1.1,0.3,1.6,0.4c0.3,0.1,0.7,0.2,1,0.2c0.5,0.1,1,0.2,1.5,0.3
			c0.3,0.1,0.7,0.1,1,0.2c0.5,0.1,1,0.2,1.5,0.3c0.3,0.1,0.7,0.1,1,0.2c0.5,0.1,0.9,0.2,1.4,0.3c0.3,0.1,0.7,0.1,1,0.2
			c0.5,0.1,0.9,0.2,1.4,0.3c0.3,0.1,0.7,0.1,1,0.2c0.5,0.1,0.9,0.2,1.4,0.2c0.3,0.1,0.7,0.1,1,0.2c0.4,0.1,0.9,0.1,1.3,0.2
			c0.3,0,0.6,0.1,1,0.1c0.4,0.1,0.9,0.1,1.3,0.2c0.3,0,0.6,0.1,0.9,0.1c0.4,0.1,0.9,0.1,1.3,0.2c0.3,0,0.6,0.1,0.9,0.1
			c0.4,0.1,0.9,0.1,1.3,0.1c0.3,0,0.6,0.1,0.9,0.1c0.4,0,0.9,0.1,1.3,0.1c0.3,0,0.6,0.1,0.8,0.1c0.4,0,0.9,0.1,1.3,0.1
			c0.3,0,0.5,0,0.8,0.1c0.4,0,0.9,0.1,1.3,0.1c0.2,0,0.5,0,0.7,0.1c0.4,0,0.9,0.1,1.3,0.1c0.2,0,0.4,0,0.6,0c0.5,0,1,0.1,1.4,0.1
			c0.2,0,0.3,0,0.5,0c0.6,0,1.1,0.1,1.7,0.1c0.1,0,0.1,0,0.2,0c1.2,0,2.4,0.1,3.6,0.1c0.3,0,0.5,0,0.8,0c0.3,0,0.6,0,0.9,0
			c0.1,0,0.1,0,0.2,0c0.5,0,1.1,0,1.6,0c0.7,0,1.3,0,1.9,0c0,0,0,0,0,0c0.6,0,1.2,0,1.7,0c0,0,0.1,0,0.1,0c0.6,0,1.1,0,1.6-0.1
			c0,0,0.1,0,0.1,0c0.5,0,1,0,1.5-0.1c0,0,0.1,0,0.1,0c0.5,0,1-0.1,1.4-0.1c0,0,0.1,0,0.1,0c0.5,0,0.9-0.1,1.3-0.1c0,0,0,0,0.1,0
			c3.6-0.3,6-0.6,6.7-0.7c0,0,0.1,0,0.3-0.1c0.1,0,0.2-0.1,0.3-0.1c0.2-0.1,0.4-0.2,0.6-0.4c0.2-0.1,0.3-0.2,0.5-0.4
			c0.2-0.1,0.4-0.3,0.6-0.4c0.2-0.1,0.3-0.3,0.5-0.4c0.2-0.1,0.4-0.3,0.6-0.5c0.2-0.1,0.3-0.3,0.5-0.4c0.2-0.2,0.4-0.3,0.6-0.5
			c0.2-0.2,0.3-0.3,0.5-0.5c0.2-0.2,0.4-0.3,0.5-0.5c0.2-0.2,0.3-0.3,0.5-0.5c0.2-0.2,0.4-0.4,0.5-0.6c0.2-0.2,0.3-0.4,0.5-0.5
			c0.2-0.2,0.4-0.4,0.5-0.6c0.2-0.2,0.3-0.4,0.5-0.6c0.2-0.2,0.4-0.4,0.5-0.6c0.2-0.2,0.3-0.4,0.5-0.6c0.2-0.2,0.4-0.4,0.5-0.7
			c0.2-0.2,0.3-0.4,0.5-0.6c0.2-0.2,0.4-0.5,0.5-0.7c0.2-0.2,0.3-0.4,0.5-0.7c0.2-0.2,0.4-0.5,0.5-0.7c0.2-0.2,0.3-0.5,0.5-0.7
			c0.2-0.2,0.4-0.5,0.5-0.8c0.2-0.2,0.3-0.5,0.5-0.7c0.2-0.3,0.4-0.5,0.5-0.8c0.2-0.2,0.3-0.5,0.5-0.7c0.2-0.3,0.4-0.5,0.5-0.8
			c0.2-0.3,0.3-0.5,0.5-0.8c0.2-0.3,0.3-0.5,0.5-0.8c0.2-0.3,0.3-0.5,0.5-0.8c0.2-0.3,0.3-0.6,0.5-0.8c0.2-0.3,0.3-0.5,0.5-0.8
			c0.2-0.3,0.3-0.6,0.5-0.9c0.2-0.3,0.3-0.5,0.5-0.8c0.2-0.3,0.3-0.6,0.5-0.9c0.2-0.3,0.3-0.6,0.5-0.8c0.2-0.3,0.3-0.6,0.5-0.9
			c0.2-0.3,0.3-0.6,0.5-0.9c0.2-0.3,0.3-0.6,0.5-0.9c0.2-0.3,0.3-0.6,0.5-0.9c0.2-0.3,0.3-0.6,0.5-0.9c0.2-0.3,0.3-0.6,0.5-0.9
			c0.2-0.3,0.3-0.6,0.5-0.9c0.2-0.3,0.3-0.6,0.5-0.9c0.2-0.3,0.3-0.6,0.5-1c0.2-0.3,0.3-0.6,0.5-0.9c0.2-0.3,0.3-0.6,0.5-1
			c0.1-0.3,0.3-0.6,0.4-0.9c0.2-0.3,0.3-0.6,0.5-1c0.1-0.3,0.3-0.6,0.4-0.9c0.2-0.3,0.3-0.6,0.5-1c0.1-0.3,0.3-0.6,0.4-0.9
			c0.2-0.3,0.3-0.7,0.5-1c0.1-0.3,0.3-0.6,0.4-0.9c0.2-0.3,0.3-0.7,0.5-1c0.1-0.3,0.3-0.6,0.4-0.9c0.1-0.3,0.3-0.7,0.4-1
			c0.1-0.3,0.3-0.6,0.4-0.9c0.1-0.3,0.3-0.7,0.4-1c0.1-0.3,0.3-0.6,0.4-0.9c0.1-0.3,0.3-0.7,0.4-1c0.1-0.3,0.3-0.6,0.4-0.9
			c0.1-0.3,0.3-0.7,0.4-1c0.1-0.3,0.3-0.6,0.4-0.9c0.1-0.3,0.3-0.7,0.4-1c0.1-0.3,0.2-0.6,0.4-0.9c0.1-0.3,0.3-0.7,0.4-1
			c0.1-0.3,0.2-0.6,0.4-0.9c0.1-0.3,0.3-0.6,0.4-1c0.1-0.3,0.2-0.6,0.4-0.9c0.1-0.3,0.3-0.6,0.4-0.9c0.1-0.3,0.2-0.6,0.4-0.9
			c0.1-0.3,0.3-0.6,0.4-0.9c0.1-0.3,0.2-0.6,0.3-0.9c0.1-0.3,0.3-0.6,0.4-1c0.1-0.3,0.2-0.5,0.3-0.8c0.1-0.3,0.2-0.6,0.4-0.9
			c0.1-0.3,0.2-0.5,0.3-0.8c0.1-0.3,0.2-0.6,0.4-0.9c0.1-0.3,0.2-0.5,0.3-0.8c0.1-0.3,0.2-0.6,0.3-0.9c0.1-0.3,0.2-0.5,0.3-0.8
			c0.1-0.3,0.2-0.6,0.3-0.9c0.1-0.3,0.2-0.5,0.3-0.8c0.1-0.3,0.2-0.6,0.3-0.9c0.1-0.2,0.2-0.5,0.3-0.7c0.1-0.3,0.2-0.6,0.3-0.9
			c0.1-0.2,0.2-0.5,0.2-0.7c0.1-0.3,0.2-0.6,0.3-0.9c0.1-0.2,0.2-0.4,0.2-0.6c0.1-0.3,0.2-0.6,0.3-0.8c0.1-0.2,0.2-0.4,0.2-0.6
			c0.1-0.3,0.2-0.5,0.3-0.8c0.1-0.2,0.1-0.4,0.2-0.6c0.1-0.3,0.2-0.5,0.3-0.8c0.1-0.2,0.1-0.4,0.2-0.6c0.1-0.2,0.2-0.5,0.3-0.7
			c0.1-0.2,0.1-0.4,0.2-0.6c0.1-0.2,0.2-0.5,0.3-0.7c0.1-0.2,0.1-0.3,0.2-0.5c0.1-0.3,0.2-0.5,0.3-0.7c0.1-0.1,0.1-0.3,0.2-0.4
			c0.1-0.3,0.2-0.5,0.3-0.8c0-0.1,0.1-0.2,0.1-0.3c0.1-0.2,0.1-0.4,0.2-0.6c0-0.1,0.1-0.3,0.1-0.4c0.1-0.2,0.1-0.3,0.2-0.5
			c0.1-0.2,0.1-0.3,0.2-0.5c0-0.1,0.1-0.3,0.1-0.4c0.1-0.2,0.1-0.3,0.2-0.5c0.1-0.2,0.1-0.3,0.2-0.5c0-0.1,0.1-0.2,0.1-0.3
			c0.1-0.2,0.1-0.4,0.2-0.6c0-0.1,0-0.1,0.1-0.2c0.1-0.2,0.1-0.4,0.2-0.6c0,0,0,0,0-0.1c0.1-0.2,0.1-0.3,0.2-0.5c0,0,0,0,0-0.1
			c0.3-0.8,0.4-1.3,0.4-1.3c0,0.1,0,0.1,0,0.2c0.1,0.9,0.2,1.7,0.4,2.6c0,0.3,0.1,0.7,0.1,1c0.1,0.6,0.2,1.2,0.2,1.8
			c0,0.4,0.1,0.7,0.1,1.1c0.1,0.6,0.1,1.2,0.2,1.7c0,0.3,0.1,0.7,0.1,1c0.1,0.7,0.2,1.3,0.2,2c0,0.2,0.1,0.4,0.1,0.6
			c0.1,0.8,0.2,1.7,0.3,2.5c0,0.3,0.1,0.5,0.1,0.8c0.1,0.6,0.1,1.2,0.2,1.7c0,0.3,0.1,0.6,0.1,0.9c0.1,0.5,0.1,1,0.1,1.5
			c0,0.3,0.1,0.6,0.1,0.9c0.1,0.6,0.1,1.1,0.1,1.6c0,0.2,0,0.5,0.1,0.7c0.1,0.8,0.1,1.5,0.2,2.3c0,0.2,0,0.4,0,0.5
			c0,0.6,0.1,1.1,0.1,1.7c0,0.3,0,0.5,0.1,0.8c0,0.5,0.1,0.9,0.1,1.4c0,0.3,0,0.5,0,0.8c0,0.5,0.1,0.9,0.1,1.4c0,0.2,0,0.5,0,0.7
			c0,0.6,0.1,1.2,0.1,1.8c0,0.1,0,0.2,0,0.3c0,0.6,0,1.2,0.1,1.8c0,0.2,0,0.5,0,0.7c0,0.4,0,0.8,0,1.2c0,0.2,0,0.5,0,0.7
			c0,0.4,0,0.8,0,1.2c0,0.2,0,0.4,0,0.7c0,0.5,0,0.9,0,1.4c0,0.1,0,0.3,0,0.4c0,0.6,0,1.1,0,1.7c0,0.2,0,0.4,0,0.5c0,0.4,0,0.8,0,1.1
			c0,0.2,0,0.4,0,0.6c0,0.3,0,0.7,0,1c0,0.2,0,0.4,0,0.6c0,0.4,0,0.7,0,1.1c0,0.2,0,0.3,0,0.5c0,0.5,0,1-0.1,1.5c0,0.1,0,0.2,0,0.4
			c0,0.4,0,0.7-0.1,1.1c0,0.2,0,0.4,0,0.5c0,0.3,0,0.6-0.1,0.9c0,0.2,0,0.4,0,0.5c0,0.3-0.1,0.6-0.1,0.9c0,0.2,0,0.3,0,0.5
			c0,0.4-0.1,0.8-0.1,1.2c-0.1,0.6-0.1,1.1-0.2,1.6c0,0.4-0.1,0.7-0.1,1.1c0,0.4-0.1,0.7-0.2,1.1c0,0,0,0.1,0,0.1
			c-0.2,1.4-0.5,2.8-0.7,4c0,0.1,0,0.2-0.1,0.2c-0.4,1.6-0.8,3-1.2,4.3l0,0c-0.1,0.3-0.2,0.6-0.3,0.9c0,0,0,0,0,0
			c-0.1,0.3-0.2,0.5-0.3,0.8c0,0,0,0,0,0c-0.4,0.9-0.7,1.8-1.1,2.5c0,0.1-0.1,0.1-0.1,0.2c-0.1,0.3-0.3,0.5-0.4,0.7c0,0,0,0,0,0
			c-0.1,0.1-0.1,0.2-0.2,0.3c-0.2,0.4-0.5,0.8-0.7,1.1c0,0,0,0,0,0c-0.2,0.3-0.4,0.6-0.6,0.8c0,0,0,0,0,0c-0.2,0.2-0.4,0.5-0.6,0.7
			c0,0,0,0,0,0c-0.2,0.2-0.4,0.4-0.6,0.6c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c-0.2,0.2-0.4,0.4-0.6,0.5c0,0,0,0,0,0
			c-0.2,0.1-0.4,0.3-0.5,0.4c0,0,0,0,0,0c-0.2,0.1-0.3,0.2-0.5,0.3c0,0,0,0,0,0c-0.2,0.1-0.3,0.2-0.5,0.2c0,0,0,0,0,0c0,0,0,0,0,0
			c0,0,0,0-0.1,0c0,0,0,0,0,0c-0.1,0-0.1,0-0.2,0.1l0,0c0,0,0,0,0.1,0c0.5-0.1,1-0.3,1.4-0.4c0.1,0,0.3-0.1,0.4-0.1
			c0.5-0.1,1-0.3,1.6-0.5c0.1,0,0.2-0.1,0.3-0.1c0.5-0.2,1-0.3,1.5-0.5c0.1,0,0.2-0.1,0.4-0.1c0.6-0.2,1.2-0.4,1.8-0.6
			c0.2-0.1,0.3-0.1,0.5-0.2c0.6-0.2,1.2-0.4,1.8-0.7c0.1,0,0.1,0,0.2-0.1c0.7-0.3,1.3-0.5,2-0.8c0.2-0.1,0.3-0.1,0.5-0.2
			c0.7-0.3,1.4-0.6,2.1-0.9c0.1-0.1,0.3-0.1,0.4-0.2c0.6-0.3,1.3-0.6,1.9-0.9c0.2-0.1,0.3-0.1,0.5-0.2c0.7-0.3,1.5-0.7,2.2-1
			c0.2-0.1,0.4-0.2,0.5-0.3c0.7-0.3,1.4-0.7,2.2-1.1c0.1,0,0.2-0.1,0.2-0.1c0.8-0.4,1.6-0.8,2.4-1.2c0.2-0.1,0.4-0.2,0.6-0.3
			c0.8-0.4,1.6-0.8,2.5-1.3c0.1-0.1,0.2-0.1,0.4-0.2c0.8-0.4,1.5-0.8,2.3-1.3c0.2-0.1,0.4-0.2,0.5-0.3c0.8-0.5,1.7-1,2.5-1.5
			c0.2-0.1,0.4-0.2,0.6-0.3c0.8-0.5,1.6-1,2.4-1.4c0.1-0.1,0.2-0.1,0.3-0.2c0.9-0.5,1.8-1.1,2.7-1.6c0.2-0.1,0.4-0.3,0.6-0.4
			c0.9-0.6,1.8-1.1,2.7-1.7c0.1-0.1,0.2-0.1,0.3-0.2c0.8-0.6,1.7-1.1,2.5-1.7c0.2-0.1,0.4-0.3,0.6-0.4c0.9-0.6,1.8-1.3,2.7-1.9
			c0.2-0.1,0.4-0.3,0.6-0.4c0.8-0.6,1.7-1.2,2.5-1.8c0.1-0.1,0.2-0.2,0.4-0.3c0.9-0.7,1.8-1.4,2.8-2.1c0.2-0.2,0.4-0.3,0.6-0.5
			c0.9-0.7,1.9-1.5,2.8-2.2c0.1-0.1,0.1-0.1,0.2-0.2c0.9-0.7,1.8-1.4,2.6-2.2c0.2-0.2,0.4-0.3,0.6-0.5c0.9-0.8,1.8-1.6,2.7-2.4
			c0.2-0.2,0.3-0.3,0.5-0.5c0.8-0.7,1.7-1.5,2.5-2.3c0.1-0.1,0.3-0.2,0.4-0.4c0.9-0.8,1.8-1.7,2.7-2.6c0.2-0.2,0.4-0.4,0.6-0.6
			c0.9-0.9,1.8-1.8,2.8-2.7c0,0,0.1-0.1,0.1-0.1c0.9-0.9,1.8-1.8,2.6-2.7c0.2-0.2,0.4-0.4,0.6-0.6c0.9-0.9,1.8-1.9,2.6-2.8
			c0.2-0.2,0.3-0.3,0.5-0.5c0.8-0.9,1.6-1.8,2.4-2.7c0.1-0.2,0.3-0.3,0.4-0.5c0.9-1,1.7-2,2.5-3c0.2-0.2,0.4-0.5,0.6-0.7
			c0.9-1.1,1.7-2.1,2.6-3.2c0.5-0.6,1-1.3,1.5-1.9c0.1-0.1,0.2-0.3,0.3-0.4c0.6-0.8,1.2-1.6,1.7-2.4c0.2-0.2,0.3-0.4,0.5-0.7
			c6.1-8.6,11.7-18,16.3-28.4c0,0,0,0,0,0c0.6-1.3,1.2-2.6,1.7-3.9c0.1-0.3,0.3-0.7,0.4-1c1.1-2.6,2.1-5.2,3.1-7.8
			c0.1-0.3,0.2-0.6,0.3-0.9c0.9-2.6,1.8-5.1,2.6-7.6c0.1-0.3,0.2-0.6,0.3-0.9c0.8-2.5,1.5-5,2.1-7.4c0.1-0.3,0.2-0.6,0.2-0.9
			c0.3-1.2,0.6-2.4,0.9-3.6c0.2-0.9,0.4-1.8,0.6-2.7c0.3-1.2,0.5-2.4,0.8-3.5c0.1-0.3,0.1-0.6,0.2-0.9c0.5-2.3,0.9-4.6,1.3-6.9
			c0-0.3,0.1-0.5,0.1-0.8c0.2-1.1,0.3-2.2,0.5-3.3c0.2-1.6,0.4-3.3,0.6-4.9c0.1-0.9,0.2-1.7,0.3-2.6c0.1-1,0.2-2,0.3-3
			c0-0.2,0-0.4,0-0.5c0.1-1,0.1-2,0.2-3c0-0.8,0.1-1.6,0.1-2.4c0-0.8,0.1-1.6,0.1-2.3c0-0.2,0-0.5,0-0.7c0-0.5,0-1.1,0-1.6
			c0-0.3,0-0.5,0-0.8c0-0.5,0-1,0-1.4c0-0.3,0-0.6,0-0.9C453.4,271.1,453.4,270.6,453.4,270.2"
      />
      <path
        className="logo1"
        d="M441.4,206.9c-1.7-4.4-7.7-16.7-14.2-28.6c-6.6-11.9-21.7-17.5-21.7-17.5c-62.1-26.9-177.3-14.5-177.3-14.5
			c89-32.7,131.9-26.2,146.1-21.9c0.7,0.2,1.3,0.3,1.9,0.5c0.8,0.3,1.4,0.5,2,0.7c0.9,0.4,1.4,0.7,1.7,0.8
			c-56.4-39.6-102.7-38.5-140.5-32.8c-37.9,5.8-78.8,28.5-107.2,62.6c-24.3,29.2-34.6,64.4-37,73.5c-0.1,0.4,0.3-0.3,0.2,0
			c0.1-0.3,0.3-0.7,0.5-1c0.3-0.7,0.7-1.4,1.1-1.9c26.9-38.5,189.2-41.1,189.2-41.1c-77.8,21.8-133.4,48.9-163.1,66.5l0,0
			c-0.8,0.5-1.7,1-2.5,1.4c-2.5,1.5-4.8,2.9-6.9,4.2c-15.8,10.3-20.7,17.2-21.8,19.9c0,0.1,0,0.1-0.1,0.2c-0.1,0.3-0.2,0.5-0.3,0.8
			c0,0,0,0,0,0.1c-0.1,0.3-0.2,0.7-0.3,1l0,0c-0.8,3.4-0.4,7.7,0.6,15c2.2,16,8.3,34.1,8.3,34.1s-7.7-22.1,174-82.6
			c168.1-52.7,169.5-33.3,169.2-30.9C443.5,213.8,443,211.1,441.4,206.9"
      />
    </g>
  </svg>
);

export default SwLogoFlat;

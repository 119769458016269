import services from '@features/core/services';

import { NOTIFICATION_ENDPOINT } from '@common/constants/config';
import { apiHandler, MethodType } from '@common/api/apiHandler';
import { IPushNotificationsReasons } from '@common/interfaces';

interface IFetchNotificationSubscriptions {
  event: Record<string, IPushNotificationsReasons>;
  category: Record<string, IPushNotificationsReasons>;
}

const fetchNotificationSubscriptions = ({
  username,
  target,
}): Promise<IFetchNotificationSubscriptions> => {
  const apiUrl = `${services.config.get(NOTIFICATION_ENDPOINT)}${
    // eslint-disable-next-line sonarjs/no-nested-template-literals
    target ? `${target}/` : ''
  }${username}`;

  return apiHandler(
    { method: MethodType.Get },
    {
      apiUrl,
    },
  );
};

export default fetchNotificationSubscriptions;

import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { TextSM } from '@features/core/styles/text.styled';
import services from '@features/core/services';
import { PageName } from '@features/core/routing/linkAliases';

import { YEAR_VERIFICATION_NOTIFICATION_REMOVE } from '@common/constants/cookie';
import { YEARLY_VERIFICATION_NOTICE } from '@common/constants/notifications';
import { ButtonType, IUser } from '@common/interfaces';
import { isMatchWebRoutes, setLocation } from '@common/providers/router/helper';
import {
  getVerificationLink,
  hasFrozenAcccount,
} from '@common/helpers/paymentsHelper/paymentModel';
import { isAccountPages } from '@common/helpers/links';
import { isDesktopView } from '@common/helpers/deviceUtil';
import { useUserState } from '@common/providers/user/useUserState';
import {
  removeNotification,
  useAppState,
} from '@common/providers/application/useAppState';

import { StyledButton } from '@ui/components/buttons';
import { Icon } from '@ui/components/icon';

import * as S from '../TopNotification.styled';

const isDesktop = isDesktopView();

const Verification: React.FC = (): React.ReactElement => {
  const { t } = useTranslation();
  const { pathname } = useLocation();
  const user = useUserState(state => state.data);
  const notifications = useAppState(state => state.notifications);
  const accountVerification = isMatchWebRoutes(PageName.USER_VERIFICATION);
  const emailVerification = isMatchWebRoutes(
    PageName.USER_EMAIL_VERIFICATION_SUCCESS,
  );
  const yearlyVerification = isMatchWebRoutes(PageName.YEARLY_DATA_CHECK);
  const isMyAccount = isAccountPages(pathname);
  const hasRightCol = isMyAccount && isDesktop;

  useEffect(() => {
    if (services.cookie.get(YEAR_VERIFICATION_NOTIFICATION_REMOVE)) {
      removeNotification(YEARLY_VERIFICATION_NOTICE);
    }
  }, [notifications]);

  if (accountVerification || emailVerification || yearlyVerification) {
    return <></>;
  }

  const onClose = (): void => {
    removeNotification(YEARLY_VERIFICATION_NOTICE);
    services.cookie.set(YEAR_VERIFICATION_NOTIFICATION_REMOVE, '1', {
      expires: null,
    });
  };

  return (
    <S.NotificationWrapper id="yearly-verification" hasRightCol={hasRightCol}>
      <S.NotificationHeader>
        <Icon width="20" height="20" name="info-circle" />
        <S.HighlightBoldTitle className="title">
          {t('user.yearly_data_check.title')}
        </S.HighlightBoldTitle>
        {!hasFrozenAcccount(user as IUser) && (
          <S.IconWrapper>
            <Icon width="14" height="13" name="close" onClick={onClose} />
          </S.IconWrapper>
        )}
      </S.NotificationHeader>
      <S.FlexWrapper>
        <TextSM>
          <span
            dangerouslySetInnerHTML={{
              __html: t('user.yearly_data_check.description'),
            }}
          />
        </TextSM>
        <StyledButton
          onClick={(): void => {
            setLocation(getVerificationLink(user as IUser));
            if (!hasFrozenAcccount(user as IUser)) {
              removeNotification(YEARLY_VERIFICATION_NOTICE);
            }
          }}
          label="user.notifications.check_data"
          buttonType={ButtonType.Secondary}
        />
      </S.FlexWrapper>
    </S.NotificationWrapper>
  );
};

export default Verification;

/* eslint-disable import/prefer-default-export */
import reduce from 'lodash/reduce';

import { IMarket, IPrediction } from '@common/interfaces';
import { useEventsListState } from '@common/providers/events/eventList/useEventsList';

/**
 * useMarketPredictions
 *
 * @param {IMarket} market
 * @param {string} listType
 * @returns {IPrediction[]} predictions
 */
export const useMarketPredictions = (
  market: IMarket,
  listType: string,
): IPrediction[] => {
  return useEventsListState(s =>
    reduce(
      market.predictions,
      (acc, pid) => {
        if (s[listType].data.predictions[pid]) {
          acc.push(s[listType].data.predictions[pid]);
        }
        return acc;
      },
      [] as IPrediction[],
    ),
  );
};

import React, { useState } from 'react';

import { UserFormButtonsWrapper } from '@features/user/components/userForm';
import { CheckboxInput } from '@features/core/form/components/checkboxInput';

import { WALLET_REQUEST } from '@common/constants/dialogs';
import { closeSidebar } from '@common/providers/application/useAppState';
import {
  acceptWalletsRequest,
  getWallets,
  rejectWalletsRequest,
} from '@common/providers/payments/wallets/helpers';
import { useWalletsState } from '@common/providers/payments/wallets/useWallets';

import * as S from './WalletConfirmForm.styled';

const WalletConfirmForm: React.FC = () => {
  const [check, setCheck] = useState(false);
  const request = useWalletsState(state => state.request);

  const handleAbort = (e: React.MouseEvent<HTMLElement>): void => {
    e.preventDefault();
    rejectWalletsRequest(request?.data[0]?.wallet_id_request);
    closeSidebar(WALLET_REQUEST);
    getWallets();
  };

  const handleSubmit = (e: React.MouseEvent<HTMLElement>): void => {
    e.preventDefault();
    acceptWalletsRequest(request?.data[0]?.wallet_id_request);
    // example response comes as result
    //  login: "PhillippHofmann_47636"
    //  registration_success: 1
    //  shop_online_wallet_id: "106787"
    //  shop_online_wallet_login: "so53418_PhillippHofmann_4763"
    //  user_id: "106786"
    closeSidebar(WALLET_REQUEST);
    getWallets();
  };

  return (
    <S.Form name="wallet-confirm">
      <S.FormGroup>
        <CheckboxInput
          checked={check}
          onClick={(): void => setCheck(!check)}
          label="user.walletConfirm.accept_wallets_text"
          isLabelClickable
        />
      </S.FormGroup>
      <UserFormButtonsWrapper
        disabled={!check}
        submitTitle="user.walletConfirm.submit_btn_text"
        cancelTitle="common.buttons.cancel"
        onSubmit={handleSubmit}
        onAbort={handleAbort}
        buttonsQa="wallet"
        className="btns-wrapper"
      />
    </S.Form>
  );
};

export default WalletConfirmForm;

import { IGameData } from '@common/interfaces';
import { ICasinoState, ISportsSummary } from '@common/providers/casino/types';

const defaultState: ICasinoState = {
  sports_summary: {
    data: {} as ISportsSummary,
    loading: false,
    error: null,
  },
  slots_summary: {
    data: {} as ISportsSummary,
    loading: false,
    error: null,
  },
  casino_games: {
    data: {} as IGameData,
    loading: false,
    error: null,
  },
};

export default defaultState;

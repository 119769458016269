import includes from 'lodash/includes';
import forEach from 'lodash/forEach';
import keys from 'lodash/keys';
import split from 'lodash/split';

import services from '@features/core/services';
import {
  comparePageWithCurrentLocation,
  getPageURL,
  PageName,
} from '@features/core/routing/linkAliases';

import {
  BetEvents,
  CommonEvents,
  PaymentsEvents,
  UserEvents,
} from '@packages/events/appEvents';

import { IUser } from '@common/interfaces';
import { removeRequestedCashout } from '@common/providers/cashout/useCashout';
import { setLocation } from '@common/providers/router/helper';
import logoutUser from '@common/providers/user/actions/logoutUser';
import authUser from '@common/providers/user/actions/authUser';
import { setBettingSlipData } from '@common/providers/bettingslip/useBettingSlip';
import { getBetDetails, getBets } from '@common/providers/bets/helper';
import { removeOpenBetsItem, useBets } from '@common/providers/bets/useBets';
import { getWallets } from '@common/providers/payments/wallets/helpers';

const globalEventHandler = (): void => {
  let timeout: ReturnType<typeof setTimeout> | null = null;
  let refreshTimeout: ReturnType<typeof setTimeout> | null = null;

  const onGetBets = (): Promise<void> =>
    getBets({
      offset: 0,
      items: 1000,
      maxAgeDays: 365,
      settled: 'unsettled',
      list: 'openBets',
      withEvents:
        !comparePageWithCurrentLocation(PageName.USER_BET_ITEM) &&
        !comparePageWithCurrentLocation(PageName.CASHOUT_SCANNER_RESULT),
    });

  services.events.addEventListener(CommonEvents.SET_COOKIE, (cookies): void => {
    forEach(keys(cookies), key => {
      services.cookie.set(key, cookies?.[key]);
    });
  });

  services.events.addEventListener(UserEvents.NATIVE_LOGOUT, async () => {
    await logoutUser();
    setLocation(PageName.HOME);
  });

  services.events.addEventListener(UserEvents.AUTH, (user: IUser) => {
    setBettingSlipData({ user });
    if (
      !includes(window.location.pathname, getPageURL(PageName.USER_CASHOUT))
    ) {
      onGetBets();
    }
  });

  services.events.addEventListener(UserEvents.REQUEST_WEB_AUTH, authUser);

  services.events.addEventListener(PaymentsEvents.ACCOUNT_UPDATE, getWallets);

  services.events.addEventListener(BetEvents.REMOVE_OPEN_BETS, getWallets);

  services.events.addEventListener(BetEvents.REMOVE_BET, payload => {
    if (payload) {
      setTimeout(() => {
        removeOpenBetsItem(String(payload));
      }, 5000);
    }
    const isAnonymousCashout = comparePageWithCurrentLocation(
      PageName.CASHOUT_SCANNER_RESULT,
    );

    if (isAnonymousCashout) {
      const barcode = split(window.location.href, '/').pop() as string;
      setTimeout(() => getBetDetails({ barcode }), 7000);
    }
  });
  services.events.addEventListener(BetEvents.PLACE_CASHOUT, () => {
    const isAnonymousCashout = comparePageWithCurrentLocation(
      PageName.CASHOUT_SCANNER_RESULT,
    );

    if (isAnonymousCashout) {
      timeout = setTimeout(() => removeRequestedCashout(), 7000);
    } else {
      const { selectedBetId, bet_detail } = useBets.getState();

      getWallets();

      if (timeout) {
        clearTimeout(timeout);
      }

      if (
        selectedBetId ||
        comparePageWithCurrentLocation(PageName.USER_BET_ITEM)
      ) {
        if (refreshTimeout) {
          clearTimeout(refreshTimeout);
        }
        refreshTimeout = setTimeout(() => {
          const betId = selectedBetId || bet_detail?.id;
          getBetDetails({ bet_id: betId });
          removeRequestedCashout(betId);
        }, 5000);
      } else if (
        comparePageWithCurrentLocation(PageName.USER_BETS) ||
        comparePageWithCurrentLocation(PageName.SCANNER_RESULT)
      ) {
        if (refreshTimeout) {
          clearTimeout(refreshTimeout);
        }

        refreshTimeout = setTimeout(() => window.location.reload(), 5000);
      }
      timeout = setTimeout(onGetBets, 5000);
    }
  });

  services.events.addEventListener(BetEvents.PLACE_BET, () => {
    getWallets();
    onGetBets();
  });
};

export default globalEventHandler;

import { createWithEqualityFn } from 'zustand/traditional';
import { shallow } from 'zustand/shallow';

import { IOddsComparisonData } from '@common/interfaces';
import {
  IOddsComparisonState,
  defaultState,
} from '@common/providers/oddsComparison/state';

export const useOddsComparison = createWithEqualityFn<IOddsComparisonState>(
  () => defaultState,
  shallow,
);

export const startFetchingOddsComparisonData = (): void => {
  useOddsComparison.setState(() => ({
    loading: true,
    error: '',
  }));
};

export const setOddsComparisonData = (
  payload: IOddsComparisonData[][],
): void => {
  useOddsComparison.setState(() => ({
    oddsComparisonList: payload,
    loading: false,
    error: '',
  }));
};

export const setOddsComparisonDataError = (payload: string): void => {
  useOddsComparison.setState(() => ({
    loading: false,
    error: payload,
  }));
};

export const setChoosedSection = (payload: number | null): void => {
  useOddsComparison.setState(() => ({
    choosedSectionIndex: payload,
  }));
};

import React from 'react';

import { EventDetailLabel } from '@features/detail/components/eventDetailLabel';

import { IEvent, ICategory, EventListTypes } from '@common/interfaces';

// tmc - team label component
const TeamLabel = (props: {
  event: IEvent;
  category: ICategory;
  listType: keyof typeof EventListTypes;
}): React.ReactElement => <EventDetailLabel {...props} />;

export default TeamLabel;

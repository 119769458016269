import { pathToRegexp } from 'path-to-regexp';
import split from 'lodash/split';
import reduce from 'lodash/reduce';
import keys from 'lodash/keys';
import forEach from 'lodash/forEach';

import { PageName, ROUTERS_MAPPER } from '@features/core/routing';
import services from '@features/core/services';

import { matchedWebRouteType } from '@common/providers/router/types';

export const matchWebRoutes = (
  routePath: string,
  queryParamsString: string,
): matchedWebRouteType | null => {
  const { domainLang } = services;

  return reduce(
    keys(ROUTERS_MAPPER) as PageName[],
    (acc: matchedWebRouteType | null, route) => {
      const routeUrl = ROUTERS_MAPPER[route][domainLang];
      const paramKeys = [];
      const regexp = pathToRegexp(routeUrl, paramKeys);
      const result = regexp.exec(routePath);
      const queryParams = {};

      if (queryParamsString) {
        forEach(split(queryParamsString, '&'), param => {
          const [key, value] = split(param, '=');
          queryParams[key] = value;
        });
      }

      if (result) {
        const params = {};
        forEach(paramKeys, (key: { name: string }, index) => {
          params[key.name] = result[index + 1];
        });

        return {
          route,
          params,
          queryParams,
        };
      }

      return acc;
    },
    null,
  );
};

export default matchWebRoutes;

import uniq from 'lodash/uniq';
import { onlyEmoji, withoutEmoji } from 'emoji-aware';
import replace from 'lodash/replace';
import map from 'lodash/map';
import { Rule } from 'rc-field-form/lib/interface';

const incorrectCharactersValidator = (
  characters: RegExp,
  message: string,
  emptyStrMessage?: string,
) => async (rule: Rule, value: string): Promise<void> => {
  let match = [];
  let emoji = [];
  if (value) {
    match =
      withoutEmoji(value)
        .join('')
        .match(characters) || [];
    emoji = onlyEmoji(value);
  }
  if (match.length || emoji.length) {
    throw new Error(
      replace(
        message,
        '{incorrect}',
        uniq(
          map([].concat(match, emoji), (v: string) =>
            /\s/.test(v) && emptyStrMessage ? `${emptyStrMessage}` : `"${v}"`,
          ),
        ).join(', '),
      ),
    );
  }
};

export default incorrectCharactersValidator;

import { useRef, useEffect } from 'react';

/**
 * Hook to create a React Portal.
 * Automatically handles creating and tearing-down the root elements (no SRR
 * makes this trivial), so there is no need to ensure the parent target already
 * exists.
 * @example
 * const target = usePortal(id, [id]);
 * return createPortal(children, target);
 * @param {String} id The id of the target container, e.g 'modal' or 'spotlight'
 * @returns {HTMLElement} The DOM node to use as the Portal target.
 */
const usePortal = (id: string): HTMLElement => {
  const existingParent = document.querySelector(`#${id}`);
  const rootElemRef = useRef<HTMLDivElement | null>(null);

  useEffect((): void => {
    // Look for existing target dom element to append to
    // Parent is either a new root or the existing dom element
    const parentElem = existingParent;

    // If there is no existing DOM element, add a new one.
    if (!parentElem) {
      return;
    }

    // Add the detached element to the parent
    if (rootElemRef.current) {
      parentElem.appendChild(rootElemRef.current);
    }
  }, [id, existingParent]);

  useEffect((): (() => void) => {
    return () => {
      rootElemRef?.current?.remove();
      if (!existingParent?.childElementCount) {
        existingParent?.remove();
      }
    };
  }, []);

  /**
   * It's important we evaluate this lazily:
   * - We need first render to contain the DOM element, so it shouldn't happen
   *   in useEffect. We would normally put this in the constructor().
   * - We can't do 'const rootElemRef = useRef(document.createElement('div))',
   *   since this will run every single render (that's a lot).
   * - We want the ref to consistently point to the same DOM element and only
   *   ever run once.
   * @link https://reactjs.org/docs/hooks-faq.html#how-to-create-expensive-objects-lazily
   */
  const getRootElem = (): HTMLElement => {
    if (!rootElemRef.current) {
      rootElemRef.current = document.createElement('div');
    }
    return rootElemRef.current;
  };

  return getRootElem();
};

export default usePortal;

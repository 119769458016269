import { createWithEqualityFn } from 'zustand/traditional';
import assign from 'lodash/assign';
import reduce from 'lodash/reduce';
import keys from 'lodash/keys';
import filter from 'lodash/filter';
import { shallow } from 'zustand/shallow';

import services from '@features/core/services';
import CustomError, {
  convertResponseToError,
} from '@features/core/error/error';
import i18n from '@features/core/translation';

import * as cookies from '@common/constants/cookie';
import { createExpiresStamp } from '@common/helpers/deviceUtil';
import {
  ICashoutRequest,
  ICashoutStates,
  IQueudCashout,
} from '@common/interfaces';
import postCashout from '@common/api/cashout/postCashout';
import {
  STATE_ACCEPTED,
  STATE_ERROR,
  STATE_OPENED,
  STATE_QUEUED,
  STATE_REJECTED,
  defaultState,
} from '@common/providers/cashout/state';
import {
  ICashoutRequestError,
  ICashoutState,
  IPostCashoutPayload,
  ISetCashout,
  ISetCashoutSate,
} from '@common/providers/cashout/types';

export const useCashoutState = createWithEqualityFn<ICashoutState>(
  () => defaultState,
  shallow,
);

export const submitCashoutRequest = (): void => {
  useCashoutState.setState(() => ({
    loading: true,
  }));
};

const setCashoutRequestAccepted = (payload: ISetCashout): void => {
  const { cashout_detail, detail, paid_status } = payload.result;
  useCashoutState.setState(state => ({
    ...state,
    requested: {
      ...state.requested,
      [payload.bet_id]: {
        state: payload.cashoutState,
        bet_detail: detail,
        cashout_detail,
        paid_status,
      },
    },
    loading: false,
  }));
};

const setCashoutRequestQueued = (payload: ISetCashout): void => {
  const { cashout_detail, queue_delay } = payload.result;
  useCashoutState.setState(state => ({
    requested: {
      ...state.requested,
      [payload.bet_id]: {
        state: payload.cashoutState,
        queue_delay: parseFloat(queue_delay || '1'),
        cashout_detail,
      },
    },
    loading: false,
  }));
};

const setCashoutRequestRejected = (payload: ISetCashout): void => {
  const { rejected_msg } = payload.result;

  useCashoutState.setState(state => ({
    requested: {
      ...state.requested,
      [payload.bet_id]: {
        state: payload.cashoutState,
        rejected_msg,
      },
    },
    loading: false,
  }));
};

const setCashoutRequestState = (payload: ISetCashoutSate): void => {
  const { bet_id, cashoutState } = payload;
  useCashoutState.setState(state => ({
    requested: {
      ...state.requested,
      [bet_id]: {
        state: cashoutState,
      },
    },
    loading: false,
  }));
};

export const postCashoutRequestSuccess = (
  payload: IPostCashoutPayload,
): void => {
  let cashoutState:
    | 'opened'
    | 'accepted'
    | 'queued'
    | 'rejected' = STATE_OPENED;

  if (payload.result && payload.result.is_accepted) {
    cashoutState = STATE_ACCEPTED;
    setCashoutRequestAccepted({ ...payload, cashoutState });
    return;
  }

  if (payload.result && payload.result.is_queued) {
    cashoutState = STATE_QUEUED;
    setCashoutRequestQueued({ ...payload, cashoutState });
    return;
  }

  if (payload.result && payload.result.is_rejected) {
    cashoutState = STATE_REJECTED;

    setCashoutRequestRejected({ ...payload, cashoutState });
    return;
  }
  setCashoutRequestState({ bet_id: payload.bet_id, cashoutState });
};

export const setCashoutRequestError = (payload: ICashoutRequestError): void => {
  const cashoutState = STATE_ERROR;
  const { data } = payload;
  useCashoutState.setState(state => ({
    ...state,
    loading: false,
    requested: {
      ...state.requested,
      [data?.bet_id as string]: {
        ...state.requested[data?.bet_id as string],
        state: cashoutState,
        error: payload,
      },
    },
  }));
};

export const changeCashoutState = (payload: {
  bet_id: string;
  state: keyof typeof ICashoutStates;
}): void => {
  useCashoutState.setState(state => ({
    ...state,
    requested: {
      ...state.requested,
      [payload.bet_id]: {
        state: payload.state,
      },
    },
  }));
};

export const addCashoutFavorites = (payload: string): void => {
  useCashoutState.setState(state => {
    const updated = [...state.favorites, payload];
    services.cookie.set(
      cookies.FAVORITES_CASHOUT_COOKIE,
      JSON.stringify(updated),
      {
        expires: createExpiresStamp(1209600),
      },
    );
    return {
      ...state,
      favorites: updated,
    };
  });
};

export const removeCashoutFavorites = (payload: string): void => {
  useCashoutState.setState(state => {
    let updated = [...state.favorites];
    updated = filter(updated, item => item !== payload);
    services.cookie.set(
      cookies.FAVORITES_CASHOUT_COOKIE,
      JSON.stringify(updated),
      {
        expires: createExpiresStamp(1209600),
      },
    );
    return {
      favorites: updated,
    };
  });
};

export const setCashoutFavorites = (favorites: Array<string>): void => {
  useCashoutState.setState(() => ({
    favorites,
  }));
};

export const removeRequestedCashout = (payload?: string): void => {
  useCashoutState.setState(state => ({
    requested: payload
      ? reduce(
          keys(state.requested),
          (acc, key) => {
            if (key === payload) {
              return acc;
            }
            return assign(acc, { [key]: state.requested[key] });
          },
          {},
        )
      : {},
  }));
};

export const submitCashoutRequestAction = async (
  payload: ICashoutRequest | IQueudCashout,
): Promise<boolean> => {
  submitCashoutRequest();
  const adaptedPayload = {
    ...payload,
    bet_id: payload?.barcode || payload.bet_id, // Use barcode if available, otherwise use bet_id
  };
  delete adaptedPayload.barcode;
  const response = await postCashout(adaptedPayload);
  if (response instanceof CustomError) {
    if (parseFloat(response.code as string) === 2310) {
      const responseError = new CustomError({
        message: i18n.t('cashout.cashout_not_allowed_for_shops'),
        data: {
          bet_id: payload.bet_id,
        },
      });
      setCashoutRequestError(responseError as ICashoutRequestError);
    } else {
      const responseError = new CustomError({
        message: convertResponseToError(response),
        data: {
          bet_id: payload.bet_id,
        },
      });
      setCashoutRequestError(responseError as ICashoutRequestError);
    }
  } else {
    const cashoutResponse = {
      ...(response as IPostCashoutPayload),
      bet_id: payload.bet_id,
    };
    postCashoutRequestSuccess(cashoutResponse);
  }
  return true;
};

import styled from 'styled-components';

import { TextLarge } from '@features/core/styles/text.styled';

export const Container = styled.div`
  height: 40px;
  display: grid;
  grid-auto-flow: column;
  justify-content: space-between;
  margin-top: -5px;
  color: ${props => props.theme.textMain};
  position: relative;
  z-index: 98;
  align-items: center;
  align-content: center;
  text-align: center;
  width: 100%;
`;

export const IconContainer = styled.span`
  width: 40px;
  height: 40px;
  display: grid;
  grid-auto-flow: column;
  justify-content: center;
  align-items: center;
  color: ${props => props.theme.textMain};
`;

export const Center = styled(TextLarge)`
  text-align: center;
`;

export const Side = styled.p`
  margin: 0;
  height: 40px;
  width: 40px;
  font-size: ${props => props.theme.fontH1};

  & a {
    color: ${props => props.theme.textTertiary};
  }
`;

export const SideLeft = styled(Side)``;

export const SideRight = styled(Side)``;

import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import head from 'lodash/head';

import CategoriesPanel from '@pages/categoriesPanel/CategoriesPanel';

import { Sidebar } from '@features/app/layout/sidebar';
import { GradientArea } from '@features/app/layout/desktop';
import { StaticFooter } from '@features/app/layout/footer';
import { PageName } from '@features/core/routing/linkAliases';
import { SearchResults } from '@features/home/components/searchResults';
import { AccountMenu } from '@features/app/layout/leftSide';
import { TabCashoutSide } from '@features/app/layout/tabSide';
import { Search } from '@features/home/components/search';
import { TopNotification } from '@features/app/layout/topNotification';
import { AccountHeader } from '@features/app/layout/desktop/components/accountHeader';
import { ApplicationLinksSection } from '@features/app/layout/rightSide/index';
import services from '@features/core/services';
import { RegistrationMessage } from '@features/home/components/registrationMessage';

import {
  HAS_LUGAS,
  HAS_PARTNERS_BOX,
  HAS_REGISTRATION,
} from '@common/constants/config';
import { scrollTo } from '@common/helpers/scrollHelper';
import { isMatchWebRoutes, setLocation } from '@common/providers/router/helper';
import { eventCategorySearch } from '@common/helpers/searchHelper';
import { isAccountPages } from '@common/helpers/links';
import { useUserState } from '@common/providers/user/useUserState';
import { useEventsListState } from '@common/providers/events/eventList/useEventsList';
import { useAppState } from '@common/providers/application/useAppState';
import {
  hasFooter,
  hasHeader,
  isIframeView,
} from '@common/helpers/appLayoutHelper';
import useNotification from '@common/hooks/useNotification';

import { LiveChatButton } from '@ui/components/liveChat';
import { BlockLoader } from '@ui/components/loaders';

import * as S from './AppLayout.styled';
import { IAppLayoutProps } from './AppLayout.types';

const AppLayout: React.FC<IAppLayoutProps> = ({
  header,
  center,
  footer = null,
}) => {
  const hasRegistration = services.config.get(HAS_REGISTRATION);
  const { pathname } = useLocation();
  const { t } = useTranslation();
  const currentKey = useAppState(state => head(state.openedSidebars)?.type);
  const staticPageContent = useAppState(state => state.staticPageContent);
  const isShowFooter = useAppState(state => state.showFooter);
  const isLoggedIn = useUserState(s => s.loadingState);
  const selectedEvent = useEventsListState(state => state.detail.selectedEvent);
  const switchAccLoading = useUserState(state => state.switchAccountLoading);
  const isCategory = isMatchWebRoutes(PageName.EVENTS_CATEGORY);
  const isMyAccount = isAccountPages(pathname);
  const isHome = isMatchWebRoutes(PageName.HOME);
  const isLive = isMatchWebRoutes(PageName.LIVE);
  const isSport = isMatchWebRoutes(PageName.SPORT);
  const isBets = isMatchWebRoutes(PageName.USER_BETS);
  const isChangePassword = isMatchWebRoutes(PageName.USER_CHANGE_PASSWORD);
  const isTransactions = isMatchWebRoutes(PageName.USER_OVERVIEW);
  const isLimits = isMatchWebRoutes(PageName.USER_LIMITS);
  const isAccountLockPage = isMatchWebRoutes(PageName.USER_LIMITS_LOCK);
  const removeMaxHeight =
    isChangePassword ||
    isMatchWebRoutes(PageName.USER_PROFILE) ||
    isMatchWebRoutes(PageName.USER_BONUSES) ||
    isLimits ||
    isMatchWebRoutes(PageName.MY_FAVORITO);

  const [searchValue, setSearchValue] = useState('');
  const searchRef = useRef<HTMLDivElement>(null);
  const mainContentRef = useRef<HTMLDivElement>(null);
  const isTopNotification = useNotification();

  const hasLeftSide = isCategory || isHome || isSport;
  const hasRightSide = isHome || isLive || isCategory || isSport;
  const isFooter = hasFooter(isShowFooter) && Boolean(footer);

  useEffect(() => {
    if (mainContentRef?.current && !isCategory) {
      scrollTo(mainContentRef?.current, {
        top: 0,
        behavior: 'smooth',
      });
    }

    scrollTo(window, {
      top: 0,
      behavior: 'smooth',
    });
  }, []);

  const redirectToSponsors = (e): void => {
    const targetLink = (e.target as Element).closest('img');
    if (!targetLink) {
      return;
    }

    setLocation(PageName.SPONSORING);
  };

  return (
    <S.Root
      className="content-holder"
      hideHeader={!hasHeader()}
      removeMaxHeight={removeMaxHeight}
    >
      {hasHeader() && (
        <S.AppHeader data-qa="layout-header">{header}</S.AppHeader>
      )}

      <GradientArea />

      <BlockLoader showLoader={switchAccLoading}>
        <S.MainContent
          ref={mainContentRef}
          isMyAccount={isMyAccount}
          isLeftMargin={isLive}
          className="main-container"
          isThreeCol={isHome || isCategory || isSport}
          isMarginTop={!!selectedEvent}
          id="main-container-desktop"
        >
          {hasLeftSide && (
            <S.LeftSide id="left-container">
              <S.SearchWrapper>
                <Search
                  title="home_page.search.title"
                  searchResultRef={searchRef}
                  value={searchValue}
                  setValue={setSearchValue}
                  onSearch={eventCategorySearch}
                />

                <SearchResults
                  searchResultRef={searchRef}
                  value={searchValue}
                  onClose={(): void => setSearchValue('')}
                />
              </S.SearchWrapper>
              <CategoriesPanel />
            </S.LeftSide>
          )}

          <S.CenterWrapper
            isFullHeight={isChangePassword || isTransactions || isBets}
          >
            <TopNotification />
            {isMyAccount ? (
              <>
                <AccountHeader />

                <S.CenterPage
                  className="pageContainer"
                  isBets={isBets && !/\d/.test(pathname)}
                  isChangePassword={isChangePassword}
                  isLimits={isLimits}
                  limitsLock={isAccountLockPage}
                  removeMaxHeight={removeMaxHeight}
                  topNotificationHeight={isTopNotification}
                >
                  {center}
                </S.CenterPage>
              </>
            ) : (
              <>
                {center}

                <StaticFooter />
              </>
            )}
            {currentKey && <Sidebar />}
          </S.CenterWrapper>

          {hasRightSide && (
            <S.RightSide data-qa="right-container" id="right-layout">
              <TabCashoutSide />

              {!isLoggedIn && hasRegistration && <RegistrationMessage />}
              {services.config.get(HAS_LUGAS) && <ApplicationLinksSection />}

              {services.config.get(HAS_PARTNERS_BOX) && (
                <S.PartnersStaticContentWrapper>
                  <S.PartnersHeader>
                    {t('common.labels.partners')}
                  </S.PartnersHeader>

                  <S.PartnersStaticContent
                    dangerouslySetInnerHTML={{
                      __html: staticPageContent?.partners?.body as string,
                    }}
                    onClick={redirectToSponsors}
                    role="presentation"
                  />
                </S.PartnersStaticContentWrapper>
              )}
            </S.RightSide>
          )}

          {!isIframeView() && (
            <>
              <S.LiveChatButtonWrapper>
                <LiveChatButton />
              </S.LiveChatButtonWrapper>

              {isMyAccount && (
                <S.AccountSidebar className="right-col-desktop">
                  <AccountMenu />
                </S.AccountSidebar>
              )}
            </>
          )}
        </S.MainContent>
      </BlockLoader>
      {isFooter && footer}
    </S.Root>
  );
};

export default AppLayout;

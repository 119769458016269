import React, { useCallback, useEffect } from 'react';
import map from 'lodash/map';
import every from 'lodash/every';
import keys from 'lodash/keys';

import {
  BallPossession,
  FactoryCards,
  FactoryScoreboards,
  ScoreboardTypes,
  FactoryTimers,
} from '@features/events/components/scoreboards';
import { Static } from '@features/events/components/scoreboards/timers';
import { CashoutSelection } from '@features/cashout/components/cashoutSelection';
import { useEventsModel } from '@features/events/useEventsModel';

import { setEventBackground } from '@common/helpers/appLayoutHelper';
import { getTopID } from '@common/helpers/categories/categoriesModel';
import { EventListTypes, StatusCode } from '@common/interfaces';
import { openEventDetail } from '@common/helpers/eventsHelper/eventRouteHelper';

import * as S from './CashoutEvent.styled';
import { ICashoutEvent } from './CashoutEvent.types';

const CashoutEvent: React.FC<ICashoutEvent> = props => {
  const {
    listType,
    status_code,
    result,
    home,
    away,
    selections,
    eventId,
    evenItem,
    bank = 0,
    betId,
    unavailableSelectionId,
  } = props;
  const { event, category } = useEventsModel(eventId, listType);
  useEffect(() => {
    setEventBackground(`.cashout-el-bg-${betId}`, evenItem);
  }, []);

  const handleClick = (): void => {
    if (status_code === StatusCode.OPEN) {
      openEventDetail({
        eventId,
        categoryId: event.category_id,
        topCategoryId: getTopID(category),
      });
    }
  };

  const renderScoreboard = useCallback(() => {
    if (!keys(event).length && status_code === 3) {
      return <Static result={result} type="cancelled" />;
    }
    if (!keys(event).length) {
      return <Static result={result} type="finished" />;
    }
    return (
      <FactoryScoreboards
        id={event.id}
        event={event}
        topCategoryId={getTopID(category)}
        type={ScoreboardTypes.small}
        listType={EventListTypes.cashout}
      />
    );
  }, [event, category]);

  const renderButtonBank = (): React.ReactElement => {
    return (
      <S.BankButtonContainer>
        <S.BankButton isActiveBank>
          <S.BankButtonLabel>B</S.BankButtonLabel>
        </S.BankButton>
      </S.BankButtonContainer>
    );
  };

  const isAllSelectionsCancelled = every(selections, [
    'status_code',
    StatusCode.CANCELLED,
  ]);

  return (
    <S.Containers className={`placed-bet cashout-el-bg-${betId}`}>
      {map(selections, selection => (
        <CashoutSelection
          market_name={selection.market_name}
          base_predictions_detail={selection.base_predictions_detail}
          user_pick={selection.user_pick}
          odds={selection.odds}
          status={selection.status_code}
          key={selection.id}
          letter={selection.letter}
          isLocked={unavailableSelectionId === selection.id}
          listType={listType}
          eventId={eventId}
        />
      ))}

      <S.RivalsContainer
        onClick={handleClick}
        isCancelled={isAllSelectionsCancelled}
      >
        {bank === 1 ? renderButtonBank() : <S.EmptyLeftSpace />}
        <S.RivalsContainerLabel className="label">
          <div>
            <S.TeamWrapper data-qa="home-team-wrapper">
              {category?.top_category_id && (
                <BallPossession
                  id={event.id}
                  listType={listType}
                  team="home"
                  iconSize={10}
                  topCategoryId={category.top_category_id}
                />
              )}
              <FactoryCards event_id={event.id} listType={listType} isHome />
              {home}
            </S.TeamWrapper>
            <S.TeamWrapper data-qa="away-team-wrapper">
              {category?.top_category_id && (
                <BallPossession
                  id={event.id}
                  listType={listType}
                  team="away"
                  iconSize={10}
                  topCategoryId={category.top_category_id}
                />
              )}
              <FactoryCards
                event_id={event.id}
                listType={listType}
                isHome={false}
              />
              {away}
            </S.TeamWrapper>
          </div>
        </S.RivalsContainerLabel>
        <S.RivalsContainerTimeScore>
          <S.RivalsContainerTimerWrapper className="time-container">
            {(event || away) && (
              <FactoryTimers
                id={event.id}
                topCategoryId={category.top_category_id}
                listType={EventListTypes.cashout}
                shortTimer
              />
            )}
          </S.RivalsContainerTimerWrapper>
          <S.RivalsContainerScoreWrapper className="score-container">
            {(event || away) && renderScoreboard()}
          </S.RivalsContainerScoreWrapper>
        </S.RivalsContainerTimeScore>
      </S.RivalsContainer>
    </S.Containers>
  );
};

export default CashoutEvent;

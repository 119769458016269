import React from 'react';

import * as S from './Tag.styled';
import ITag from './Tag.types';

const Tag = (props: ITag): React.ReactElement => {
  const { element } = props;

  return (
    <S.TagItem
      key={element}
      dangerouslySetInnerHTML={{
        __html: element,
      }}
    />
  );
};

export default Tag;

import React, { useEffect, useLayoutEffect } from 'react';

import { useServices } from '@features/core/services';

import {
  evalScript,
  removeWidget,
  sportRadarClientId,
} from '@common/helpers/eventsHelper/eventDetailWidgetTabHelper';

import * as S from './LMTTab.styled';
import ILMTTab from './LMTTab.types';

const LMTTab: React.FC<ILMTTab> = props => {
  const { betradarId, scriptId } = props;
  const { domainLang } = useServices();

  useEffect(() => {
    evalScript(scriptId);
  }, [domainLang]);

  useLayoutEffect(() => {
    return () => {
      removeWidget(`.livematchtracker_${scriptId}`);
    };
  }, []);

  const getScript = (lang: string): React.ReactElement<HTMLScriptElement> => {
    return (
      <script
        id={scriptId}
        dangerouslySetInnerHTML={{
          __html: `
                 (function(a,b,c,d,e,f,g,h,i){a[e]||(i=a[e]=function(){(a[e].q=a[e].q||[]).push(arguments)},i.l=1*new Date,i.o=f,
                    g=b.createElement(c),h=b.getElementsByTagName(c)[0],g.async=1,g.src=d,g.setAttribute("n",e),h.parentNode.insertBefore(g,h)
                )})(window,document,"script","https://widgets.sir.sportradar.com/${sportRadarClientId}/widgetloader","SIR", {
                language: '${lang}',
                theme: "neutral",
                disableAds: true,
                showOdds: false,
                adsFrequency: false,
            });
            SIR("addWidget", ".livematchtracker", "match.lmtPlus", {
              matchId: ${betradarId},
              onTrack:  window.LMTStateHandler,
              scoreboard: "disable",
            }
          );`,
        }}
      />
    );
  };

  return (
    <div data-qa="livematchtracker-tab">
      {getScript(domainLang)}
      <S.LiveMatchTracker className="livematchtracker" />
    </div>
  );
};

export default LMTTab;

import styled from 'styled-components';

import { TextNormal } from '@features/core/styles/text.styled';

export const Root = styled.div`
  padding: ${({ theme }) => theme.space_10} ${({ theme }) => theme.space_20};
  background-color: ${({ theme }) => theme.textPrimary};
  margin-bottom: ${({ theme }) => theme.space_5};
`;

export const ExpandContainer = styled.div<{ expanded: boolean }>`
  display: grid;
  grid-auto-flow: column;
  align-items: center;
  justify-content: space-between;
  height: 35px;
  width: 100%;
  color: ${({ theme }) => theme.textMain};
  margin-bottom: ${({ expanded, theme }) => expanded && theme.space_5};
  align-content: center;
`;

export const GridBox = styled.div`
  display: grid;
  grid-auto-flow: column;
  align-items: center;
  grid-gap: ${({ theme }) => theme.space_10};
`;

export const transactionContainerDesktopMedia = `
  @media (max-height: 740px) {
    height: 150px;
  }
`;

export const TransactionsContainer = styled.div`
  width: 100%;
  max-height: 250px;
  white-space: nowrap;
  overflow-y: scroll;
  overflow-x: hidden;
  color: ${({ theme }) => theme.textTertiary};

  @media (max-height: 670px) {
    height: 150px;
  }

  @media (max-height: 600px) {
    height: 100px;
  }

  ${({ theme }) => theme.isDesktop && transactionContainerDesktopMedia}
`;

export const Title = styled(TextNormal)`
  text-align: left;
  color: ${({ theme }) => theme.tertiaryBackground};
  font-stretch: condensed;
  position: relative;
`;

export const Loader = styled.div`
  padding: ${({ theme }) => theme.space_10};
  text-align: center;
  font-weight: ${({ theme }) => theme.fontBold};
  color: ${({ theme }) => theme.highlightWrapperBackground};
`;

export const IconContainer = styled.div`
  color: ${({ theme }) => theme.tertiaryBackground};
`;

export const EmptyContainer = styled(TextNormal)`
  width: 100%;
  text-align: center;
  margin-bottom: ${({ theme }) => theme.space_5};
  color: ${({ theme }) => theme.textActive};
  font-style: italic;
  font-weight: ${({ theme }) => theme.fontBold};
`;

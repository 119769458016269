import React, { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import includes from 'lodash/includes';
import { useTheme } from 'styled-components';

import { IBetDetailContent } from '@pages/betDetail/BetDetail.types';

import { Rejected } from '@features/cashout/components/states/rejected';
import { Accepted } from '@features/cashout/components/states/accepted';
import { CashoutInputs } from '@features/cashout/components/cashoutInputs';
import { CombiMatrix } from '@features/bets/components/combiMatrix';
import { BetItem } from '@features/bets/components/betItem';
import services, { useServices } from '@features/core/services';
import {
  comparePageWithCurrentLocation,
  PageName,
} from '@features/core/routing/linkAliases';
import BetDetailList from '@features/bets/components/betDetail/BetDetailList';
import {
  renderPartialCashout,
  renderSelections,
} from '@features/bets/components/betDetail/components/BetDetailsComponents';

import { HAS_CASHOUT, USE_NEW_SCAN_BET_API } from '@common/constants/config';
import { goBack, isMatchWebRoutes } from '@common/providers/router/helper';
import CombiMatrixModel from '@common/helpers/combinations/combiMatrixModel';
import { hasPartialCashout } from '@common/helpers/betsHelper';
import { sortByTime } from '@common/helpers/cashoutHelper';
import { StatusBet, StatusCode } from '@common/interfaces';
import { isDesktopView } from '@common/helpers/deviceUtil';
import { setSelectedTransactionId } from '@common/providers/transactions/useTransactions';
import { setSelectedBetId, useBets } from '@common/providers/bets/useBets';
import {
  removeRequestedCashout,
  useCashoutState,
} from '@common/providers/cashout/useCashout';
import {
  STATE_ACCEPTED,
  STATE_ERROR,
  STATE_QUEUED,
} from '@common/providers/cashout/state';
import { elementScrollIntoView } from '@common/helpers/scrollHelper';

import { Icon } from '@ui/components/icon';
import { PageHeader } from '@ui/components/pageHeader';

import * as S from './BetDetail.styled';

const isDesktop = isDesktopView();

const BetDetailContent: React.FC<IBetDetailContent> = ({
  bet,
  betId,
  barcode,
}) => {
  const { t } = useTranslation();
  const { iconFillLight } = useTheme();
  const [stateStatus, setStateStatus] = useState<StatusBet>(StatusBet.DEFAULT);
  const [partCashout, setPartCashout] = useState<boolean>(false);
  const { domainLang } = useServices();
  const loading = useBets(s => s.detailLoading);
  const requestedCashout = useCashoutState(s => s.requested[betId]);
  const state = requestedCashout && requestedCashout.state;
  const isScannerResultPage = includes(window.location.href || '', 'scanbet');
  const useAnonymousScanApi = !!(
    services.config.get(USE_NEW_SCAN_BET_API) &&
    comparePageWithCurrentLocation(PageName.CASHOUT_SCANNER_RESULT)
  );
  const isDetailPage = isMatchWebRoutes(PageName.USER_BET_ITEM);
  const shouldRedirectToDesk = isDesktop && isDetailPage;
  const shouldRenderCashoutInputs =
    services.config.get(HAS_CASHOUT) &&
    (!isScannerResultPage || useAnonymousScanApi) &&
    state !== STATE_ACCEPTED &&
    bet.status_code === StatusCode.OPEN;
  const betSelections = bet.selections;
  const cashoutInputsRef = useRef<HTMLDivElement>(null);

  const onCloseHandler = (): void => {
    setSelectedBetId('');
    setSelectedTransactionId('');
    if (!isDesktop) {
      goBack();
    }
  };

  const scrollToElem = (): void => {
    if (cashoutInputsRef.current && !isDesktop) {
      elementScrollIntoView(cashoutInputsRef.current, {
        behavior: 'smooth',
        block: 'end',
      });
    }
  };

  return (
    <div id="acc_content">
      {isDesktop && !shouldRedirectToDesk && !loading ? (
        <S.PageHeaderWrapper>
          <span>
            {t('app-layout.navigation.bet_mobile')} #{bet.id}
          </span>
          <S.CloseButton
            onClick={(): void => onCloseHandler()}
            data-qa="btn-close-bet-detail"
          >
            <Icon
              name="close"
              width="13px"
              height="13px"
              fill={iconFillLight}
            />
          </S.CloseButton>
        </S.PageHeaderWrapper>
      ) : (
        <PageHeader
          title={`${t('app-layout.navigation.bet_mobile')} #${bet.id}`}
          isBackButton={!isDesktop && !shouldRedirectToDesk}
        />
      )}

      <BetItem
        bet={bet}
        status_code={bet.status_code}
        created_ts={bet.created_ts}
        total_paid_amount={bet.total_paid_amount}
        initial_total_stake={bet.initial_total_stake}
        initial_total_cost={bet.initial_total_cost}
        remaining_risk={bet.remaining_risk}
        type={bet.type}
        selections={bet.selections}
        isBetDetail
        hasReuseBet
        cashouts={bet.cashouts}
        isBetDetailView
      />

      {renderSelections(bet)}
      {shouldRenderCashoutInputs && (
        <S.CashoutInputsWrapper
          className="extended-view part-cashout"
          ref={cashoutInputsRef}
        >
          <CashoutInputs
            offerToken={bet.offer_token}
            remainingRisk={bet.remaining_risk}
            totalStake={bet.total_stake}
            isQueued={state === STATE_QUEUED}
            state={state}
            stateStatus={stateStatus}
            setStateStatus={setStateStatus}
            removeRequestedCashout={removeRequestedCashout}
            setPartCashout={setPartCashout}
            betId={betId}
            domainLang={domainLang}
            scrollToElem={scrollToElem}
            barcode={barcode}
          />
        </S.CashoutInputsWrapper>
      )}

      {state === STATE_ERROR && (
        <Rejected
          onClose={(): void => {
            setStateStatus(StatusBet.DEFAULT);
          }}
          message={requestedCashout?.error?.message}
          isAnonymousCashout={useAnonymousScanApi}
        />
      )}

      {state === STATE_ACCEPTED && (
        <Accepted
          partCashout={partCashout}
          paidStatus={requestedCashout?.paid_status}
          isAnonymousCashout={useAnonymousScanApi}
        />
      )}

      <BetDetailList bet={bet} />

      {hasPartialCashout(bet) && renderPartialCashout(bet, t)}

      {CombiMatrixModel.hasMatrix(bet) && (
        <S.DetailContainer withoutPadding>
          <S.DetailHeader>{t('bets.combinations')}</S.DetailHeader>

          <CombiMatrix
            bet={{
              ...bet,
              selections: sortByTime(betSelections),
            }}
          />
        </S.DetailContainer>
      )}

      {!betId && (
        <S.DetailClose>
          <Icon
            onClick={(): void => onCloseHandler()}
            name="close"
            width="15"
            height="15"
          />
          <span>{t('modal.close')}</span>
        </S.DetailClose>
      )}
    </div>
  );
};

export default BetDetailContent;

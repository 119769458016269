/* eslint-disable lodash/prefer-filter */
import reduce from 'lodash/reduce';
import some from 'lodash/some';
import i18next from 'i18next';
import filter from 'lodash/filter';
import map from 'lodash/map';

import i18n from '@features/core/translation';
import services from '@features/core/services';

import {
  IBetDetail,
  IBetDetailCashout,
  ITransaction,
} from '@common/interfaces';
import fetchBets from '@common/api/bets/fetchBets';
import addLocaleTimeFromTimestamp from '@common/helpers/timeHelper/addLocaleTimeFromTimestamp';
import { getCurrencyFromCode } from '@common/helpers/paymentsHelper/walletsHelper';
import { getBetLink } from '@common/helpers/eventHelper';
import { copyToClipBoard } from '@common/helpers/deviceUtil';
import { isWebView } from '@common/helpers/links';
import {
  getAffiliateParamsByUsername,
  getAffiliateParamsData,
} from '@common/helpers/userHelper/affiliate/affiliateParams';

/**
 * isMaxCashout
 *
 * @param {Array<IBetDetailCashout>} cashoutsArr
 * @returns {boolean} isMaxCashout
 */
export const isMaxCashout = (
  cashoutsArr: Array<IBetDetailCashout> = [],
): boolean => {
  return (
    cashoutsArr.length === 1 && some(cashoutsArr, ['remaining_stake', '0.00'])
  );
};

/**
 * getCashoutSum
 *
 * @param {Array<IBetDetailCashout>} cashoutsArr
 * @returns {number} cashoutSum
 */
export const getCashoutSum = (
  cashoutsArr: Array<IBetDetailCashout> = [],
): number => {
  return reduce(
    cashoutsArr,
    (sum, cashout) => sum + parseFloat(cashout.amount),
    0,
  );
};

export const betsTypes = {
  // eslint-disable-next-line sonarjs/no-duplicate-string
  all: 'bets.filters_keys.all',
  unsettled: 'bets.filters_keys.unsettled',
  settled: 'bets.filters_keys.settled',
  won: 'bets.filters_keys.won',
  lost: 'bets.filters_keys.lost',
  cancelled: 'bets.filters_keys.cancelled',
  pending: 'bets.pending',
};

/**
 * hasPartialCashout
 *
 * @param {IBetDetail} bet
 * @returns {boolean} hasPartialCashout
 */
export const hasPartialCashout = (bet: IBetDetail): boolean => {
  return bet.total_stake !== bet.initial_total_stake;
};

export const handleCopyBetLink = async (bet: IBetDetail): Promise<void> => {
  try {
    await getAffiliateParamsData();
    const preparedAffiliateData = getAffiliateParamsByUsername();
    const betLink = getBetLink(bet, preparedAffiliateData);
    await copyToClipBoard(betLink);

    if (!isWebView(window.location.search)) {
      alert(i18n.t('bets.URL_copied'));
    }
  } catch (e) {
    if (!isWebView(window.location.search)) {
      services.logger.log(`Not able to copy to clipboard ${e}`);
      console.log('window.navigator: ', JSON.stringify(window.navigator));
    }
  }
};

export const getQuote = (
  initial_user_risk?: string,
  initial_total_stake?: string,
): string => {
  if (!initial_user_risk || !initial_total_stake) {
    return '';
  }
  const quote = +initial_user_risk / +initial_total_stake;
  return `${quote.toFixed(2)}`;
};

export const loadPendingCashouts = async (): Promise<ITransaction[]> => {
  let bets = [] as IBetDetail[];

  try {
    const result = await fetchBets({
      offset: 0,
      items: 100,
      maxAgeDays: 365,
      settled: 'all',
      list: 'bets',
    });
    bets = result?.user_bets || [];
  } catch (e) {
    bets = [];
  }

  return reduce<IBetDetail, ITransaction[]>(
    bets,
    (acc, bet) =>
      acc.concat(
        map(filter(bet?.cashouts || [], ['is_payout_done', 0]), cashout => ({
          transaction_id: `pending-${cashout.created}`,
          description: i18next.t('cashout.pending_cashout'),
          amount: cashout.amount,
          timestamp: cashout.created,
          bet_id: bet.id,
          balance: '',
          canceled: '',
          description_detail: '',
          currency_code: getCurrencyFromCode(),
          ...addLocaleTimeFromTimestamp(cashout.created_ts * 1000),
        })),
      ),
    [],
  );
};

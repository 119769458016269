import styled from 'styled-components';

// eslint-disable-next-line import/prefer-default-export
export const InputContainer = styled.div<{
  isArrow: boolean;
  noMargins?: boolean;
}>`
  position: relative;

  &.with-errors {
    margin-bottom: ${({ isArrow, theme }) =>
      isArrow ? theme.space_30 : theme.space_50};

    ${({ noMargins }) => noMargins && 'margin-bottom: 0;'}

    input,
    select,
    .sufix {
      color: ${props => props.theme.statusError};
      border-color: ${props => props.theme.statusError};
      border-bottom-right-radius: 0;
      border-bottom-left-radius: 0;

      &::placeholder {
        color: ${props => props.theme.statusError};
      }
    }

    input {
      color: ${({ theme }) => theme.inputDefaultText};
      border-radius: 2px;
    }
  }
`;

/**
 * Hook to set input focus by condition
 * @example
 * Already used for <TextInput />
 * in component <S.TextInputStyled shouldFocus={isActiveLimit()} ...some props />
 * css export const TextInputStyled = styled(TextInput)`...some css`
 *
 * For new inputs also pass props shouldFocus
 *
 * in <input ref={inputRef} ...some props />
 *
 *  const {inputRef, setFocus} = useFocus();
 *
 *  useEffect(() => {
 *     if (shouldFocus) {
 *       setFocus();
 *     }
 *   },[shouldFocus]);
 */

import React, { useRef } from 'react';

type useFocus = {
  inputRef: React.RefObject<HTMLInputElement>;
  setFocus: () => void;
};

const useFocus = (): useFocus => {
  const inputRef = useRef<HTMLInputElement>(null);

  const setFocus = (): void => {
    if (inputRef?.current) {
      inputRef.current.focus();
    }
  };

  return { inputRef, setFocus };
};

export default useFocus;

import isNumber from 'lodash/isNumber';

import services from '@features/core/services';

/**
 *
 * @param {number} argumentDate
 * @returns {Date} date
 */
const convertUnixToDate = (argumentDate: number): Date => {
  if (!isNumber(argumentDate)) {
    services.logger.log(`Date is not string ${argumentDate}`);
    return new Date();
  }

  return new Date(argumentDate * 1000);
};

export default convertUnixToDate;

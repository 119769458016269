import React, { useEffect } from 'react';
import includes from 'lodash/includes';
import toUpper from 'lodash/toUpper';
import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { PageName } from '@features/core/routing/linkAliases';
import { Footer } from '@features/app/layout/footer';
import services, { useServices } from '@features/core/services';
import { FooterDynamicNotifier } from '@features/app/layout/footer/footerDynamicNotifier';

import { MENU_PANEL, WALLET_SWITCHER } from '@common/constants/dialogs';
import { HAS_CASHOUT, HAS_TOTAL_ODDS_NOTIFIER } from '@common/constants/config';
import { ANIMATED_MENU, isIframeView } from '@common/helpers/appLayoutHelper';
import { isOpenDialog, getOpenDialog } from '@common/helpers/appState/dialogs';
import { isMatchWebRoutes, setLocation } from '@common/providers/router/helper';
import { isEventDetailPages } from '@common/helpers/links';
import {
  getSelections,
  hasSelections,
} from '@common/helpers/bettingSlipHelper/bettingSlipModel';
import { useUserState } from '@common/providers/user/useUserState';
import { useBettingSlip } from '@common/providers/bettingslip/useBettingSlip';
import { STATE_ACCEPTED } from '@common/providers/bettingslip/state';
import {
  closeSidebar,
  openSidebar,
  setStaticPageContent,
  toggleAnimatedComponent,
} from '@common/providers/application/useAppState';
import { useBets } from '@common/providers/bets/useBets';
import { IBettingslipType } from '@common/interfaces';
import useQuotas from '@common/hooks/useQuotas';
import usePreviousPath from '@common/hooks/usePreviousPath';

export enum footerItems {
  sport = 'sport',
  live = 'live',
  bettingSlip = 'bettingSlip',
  cashout = 'cashout',
  menuPanel = 'menuPanel',
}
const FooterWrapper: React.FC = () => {
  const { t } = useTranslation();
  const openBets = useBets(s => s.openBets);
  const user = useUserState(state => state.data);
  const isLoggedIn = useUserState(s => s.loadingState);
  const { domainLang, isDesktopView } = useServices();
  const location = useLocation();
  const currentKey = getOpenDialog();
  const { pathname } = location;
  const isBettingSlip = isMatchWebRoutes(PageName.BETTING_SLIP);
  const isMenuOpen = isOpenDialog(MENU_PANEL);
  const isWalletSwitcherOpen = isOpenDialog(WALLET_SWITCHER);

  const prevPath = usePreviousPath(location);

  const bettingslip = useBettingSlip();
  const { selections, betPackerOdds, state, type } = bettingslip;
  const betPackerSelections = useBettingSlip(s => s.betPackerSelections);
  const needToShow =
    !isBettingSlip && !!selections.length && type === IBettingslipType.combi;
  const totalOdds = useQuotas(bettingslip, needToShow);

  useEffect(() => {
    setStaticPageContent({
      lang: domainLang,
      types: ['footer', 'partners', 'home_notifications'],
    });
  }, []);

  useEffect(() => {
    if (!currentKey && includes(pathname, 'cashout')) {
      toggleAnimatedComponent(ANIMATED_MENU);
    }
  }, [pathname, currentKey]);

  const isMenuActivePath = (): boolean => {
    return (
      (includes(pathname, 'user') ||
        isMatchWebRoutes(PageName.SUPPORT) ||
        isMatchWebRoutes(PageName.PROMOTIONS)) &&
      !includes(pathname, 'cashout')
    );
  };

  const isActiveItem = (page: string): boolean => {
    const isPrevLive = includes(prevPath, 'live');
    const mainActiveRules = (
      mainRule: boolean,
      additionalRule?: boolean,
    ): boolean => (mainRule || !!additionalRule) && !isMenuOpen;

    switch (page) {
      case footerItems.live:
        return mainActiveRules(
          isMatchWebRoutes(PageName.LIVE),
          isEventDetailPages() &&
            isPrevLive &&
            !isMatchWebRoutes(PageName.BETTING_SLIP),
        );
      case footerItems.bettingSlip:
        return mainActiveRules(isMatchWebRoutes(PageName.BETTING_SLIP));
      case footerItems.menuPanel:
        return isMenuActivePath() || isMenuOpen;
      case footerItems.sport:
        return mainActiveRules(
          isMatchWebRoutes(PageName.SPORT),
          isMatchWebRoutes(PageName.EVENTS_CATEGORY),
        );
      default:
        return mainActiveRules(includes(pathname, page));
    }
  };

  if (!isDesktopView) {
    const currentTotalOdds =
      betPackerSelections.length > 1 ? betPackerOdds : totalOdds;

    const currentSelections = getSelections(selections, betPackerSelections);

    return (
      <>
        {services.config.get(HAS_TOTAL_ODDS_NOTIFIER) &&
          state !== STATE_ACCEPTED && (
            <FooterDynamicNotifier
              withArrowIcon
              duration={3000}
              needToShow={needToShow}
              dependency={currentSelections.length}
            >
              <b>{`${toUpper(
                t('bettingslip.totalQuotas'),
              )}:  ${currentTotalOdds}`}</b>
            </FooterDynamicNotifier>
          )}
        <Footer
          items={[
            {
              key: footerItems.sport,
              icon: {
                width: '18',
                height: '18',
                name: 'soccer',
              },
              onClick: (): void => {
                setLocation(PageName.SPORT);
              },
              active: isActiveItem(footerItems.sport),
              dataQa: 'footer-btn-sport',
            },
            {
              key: footerItems.live,
              icon: {
                width: '18',
                height: '18',
                name: 'thunderbolt',
              },
              onClick: (): void => {
                setLocation(PageName.LIVE, {}, { forceReload: true });
              },
              active: isActiveItem(footerItems.live),
              dataQa: 'footer-btn-live',
            },
            {
              key: footerItems.bettingSlip,
              icon: {
                height: '18',
                width: '18',
                name: 'ticket',
                isMultiplyAttrs: true,
              },
              onClick: (): void => {
                setLocation(PageName.BETTING_SLIP);
              },
              badge: betPackerSelections?.length ? 1 : selections?.length || 0,
              disabled: !hasSelections(selections, betPackerSelections),
              active: isActiveItem(footerItems.bettingSlip),
              dataQa: 'footer-btn-bettingSlip',
            },
            ...(services.config.get(HAS_CASHOUT)
              ? [
                  {
                    key: footerItems.cashout,
                    icon: {
                      width: '20',
                      height: '20',
                      name: 'euro-circle',
                    },
                    onClick: (): void => {
                      setLocation(PageName.USER_CASHOUT);
                    },
                    badge: !user?.username ? 0 : openBets.length || 0,
                    disabled: isIframeView() && !isLoggedIn,
                    active: isActiveItem(footerItems.cashout),
                    dataQa: 'footer-btn-cashout',
                  },
                ]
              : []),
            ...(!isIframeView()
              ? [
                  {
                    key: footerItems.menuPanel,
                    icon: {
                      width: '15',
                      height: '15',
                      name: 'hamburger-menu',
                      viewBox: '0 -53 384 384',
                    },
                    onClick: (): void => {
                      if (isWalletSwitcherOpen) {
                        closeSidebar(WALLET_SWITCHER);
                      }
                      openSidebar({ type: MENU_PANEL });
                    },
                    active: isActiveItem(footerItems.menuPanel),
                    dataQa: 'footer-btn-menu',
                  },
                ]
              : []),
          ]}
        />
      </>
    );
  }

  return null;
};

export default FooterWrapper;

export default {
  Afghanistan: 'af',
  Albanien: 'al',
  Algerien: 'dz',
  'Amerikanisch-Samoa': 'as',
  'Amerikanische Jungferninseln': 'vi',
  Andorra: 'ad',
  Angola: 'ao',
  Anguilla: 'ai',
  'Antarktis (Sonderstatus durch Antarktisvertrag)': 'aq',
  'Antigua & Barbuda': 'ag',
  'Antigua und Barbuda': 'ag',
  Argentinien: 'ar',
  Armenien: 'am',
  Aruba: 'aw',
  Aserbaidschan: 'az',
  Australien: 'au',
  Bahamas: 'bs',
  Bahrain: 'bh',
  Bangladesch: 'bd',
  Barbados: 'bb',
  Belgien: 'be',
  Belize: 'bz',
  Benin: 'bj',
  Bermuda: 'bm',
  Bhutan: 'bt',
  Bolivien: 'bo',
  'Bonaire, Saba, Sint Eustatius': 'bq',
  'Bosnien & Herzegowina': 'ba',
  'Bosnien und Herzegowina': 'ba',
  Botswana: 'bw',
  Brasilien: 'br',
  'Britische Jungferninseln': 'vg',
  'Britisches Territorium im Indischen Ozean': 'io',
  Brunei: 'bn',
  Bulgarien: 'bg',
  'Burkina Faso': 'bf',
  Burundi: 'bi',
  Chile: 'cl',
  Cookinseln: 'ck',
  'Costa Rica': 'cr',
  Curaçao: 'cw',
  Deutschland: 'de',
  Dominica: 'dm',
  'Dominikanische Republik': 'do',
  Dschibuti: 'dj',
  Dänemark: 'dk',
  Ecuador: 'ec',
  'El Salvador': 'sv',
  Elfenbeinküste: 'ci',
  'Ivory Coast': 'ci',
  Eritrea: 'er',
  Estland: 'ee',
  Falklandinseln: 'fk',
  Fidschi: 'fj',
  Finnland: 'fi',
  Frankreich: 'fr',
  'Französisch-Guayana': 'fr',
  'Französisch-Polynesien': 'pf',
  Färöer: 'fo',
  Gabun: 'ga',
  Gambia: 'gm',
  Georgien: 'ge',
  Ghana: 'gh',
  Gibraltar: 'gi',
  Grenada: 'gd',
  Griechenland: 'gr',
  Grönland: 'gl',
  Guadeloupe: 'fr',
  Guam: 'gu',
  Guatemala: 'gt',
  'Guernsey (Kanalinsel)': 'gg',
  Guinea: 'gn',
  'Guinea-Bissau': 'gw',
  Guyana: 'gy',
  Haiti: 'ht',
  Honduras: 'hn',
  Hongkong: 'hk',
  Indien: 'in',
  Indonesien: 'id',
  Irak: 'iq',
  Iran: 'ir',
  Irland: 'ie',
  Island: 'is',
  Israel: 'il',
  Italien: 'it',
  Jamaika: 'jm',
  Japan: 'jp',
  Jemen: 'ye',
  Jordanien: 'jo',
  Kaimaninseln: 'ky',
  Kambodscha: 'kh',
  Kamerun: 'cm',
  Kanada: 'ca',
  'Kap Verde': 'cv',
  Kasachstan: 'kz',
  Katar: 'qa',
  Kenia: 'ke',
  Kirgisistan: 'kg',
  Kiribati: 'ki',
  Kokosinseln: 'cc',
  Kolumbien: 'co',
  Komoren: 'km',
  'Kongo, Demokratische Republik': 'cd',
  'Kongo, Republik': 'cd',
  Kosovo: 'xk',
  Nordkorea: 'kp',
  'North Korea': 'kp',
  Südkorea: 'kr',
  'South Korea': 'kr',
  Kroatien: 'hr',
  Kuba: 'cu',
  Kuwait: 'kw',
  Laos: 'la',
  Lesotho: 'ls',
  Lettland: 'lv',
  Libanon: 'lb',
  Liberia: 'lr',
  Libyen: 'ly',
  Liechtenstein: 'li',
  Litauen: 'ltu',
  Luxemburg: 'lu',
  Macau: 'mo',
  Madagaskar: 'mg',
  Malawi: 'mw',
  Malaysia: 'my',
  Malediven: 'mv',
  Mali: 'ml',
  Malta: 'mt',
  Marokko: 'ma',
  Marshallinseln: 'mh',
  Martinique: 'fr',
  Mauretanien: 'mr',
  Mauritius: 'mu',
  Mayotte: 'fr',
  Mexiko: 'mx',
  Mikronesien: 'fm',
  Moldau: 'md',
  Moldawien: 'mda',
  Monaco: 'mc',
  Mongolei: 'mn',
  Montenegro: 'me',
  Montserrat: 'ms',
  Mosambik: 'mz',
  Myanmar: 'mm',
  Namibia: 'na',
  Nauru: 'nr',
  Nepal: 'np',
  Neukaledonien: 'fr',
  Neuseeland: 'nz',
  Nicaragua: 'ni',
  Niederlande: 'nl',
  Niger: 'ne',
  Nigeria: 'ng',
  Niue: 'nu',
  Nordmazedonien: 'mk',
  Nordirland: 'noi',
  'Northern Ireland': 'noi',
  Norfolkinsel: 'nf',
  Norwegen: 'no',
  'Nördliche Marianen': 'mp',
  Oman: 'om',
  Osttimor: 'tl',
  Pakistan: 'pk',
  Palau: 'pw',
  Palästina: 'ps',
  Panama: 'pa',
  'Papua-Neuguinea': 'pg',
  Paraguay: 'py',
  Peru: 'pe',
  Philippinen: 'ph',
  Pitcairninseln: 'pn',
  Polen: 'pl',
  Portugal: 'pt',
  'Puerto Rico': 'pr',
  'Republik China': 'tw',
  Ruanda: 'rw',
  Rumänien: 'ro',
  Russland: 'ru',
  Russia: 'ru',
  Réunion: 'fr',
  'Saint-Barthélemy': 'fr',
  'Saint-Martin (französischer Teil)': 'fr',
  'Saint-Pierre & Miquelon': 'fr',
  'Saint-Pierre und Miquelon': 'fr',
  Salomonen: 'sb',
  'Salomon Inseln': 'sb',
  Sambia: 'zm',
  Samoa: 'ws',
  'San Marino': 'sm',
  'Saudi-Arabien': 'sa',
  Schweden: 'se',
  Schweiz: 'ch',
  Senegal: 'sn',
  Serbien: 'rs',
  Seychellen: 'sc',
  'Sierra Leone': 'sl',
  Simbabwe: 'zw',
  Singapur: 'sg',
  'Sint Maarten': 'sx',
  Slowakei: 'sk',
  Slowenien: 'si',
  Somalia: 'so',
  Spanien: 'es',
  'Sri Lanka': 'lk',
  'St. Kitts & Nevis': 'kn',
  'St. Kitts und Nevis': 'kn',
  'St. Lucia': 'lc',
  'St. Vincent & die Grenadinen': 'vc',
  'St. Vincent und die Grenadinen': 'vc',
  Sudan: 'sd',
  Suriname: 'sr',
  Syrien: 'sy',
  'São Tomé & Príncipe': 'st',
  'São Tomé und Príncipe': 'st',
  Südafrika: 'za',
  Südsudan: 'ss',
  Tadschikistan: 'tj',
  Tansania: 'tz',
  Thailand: 'th',
  Togo: 'tg',
  Tokelau: 'tk',
  Tonga: 'to',
  'Trinidad & Tobago': 'tt',
  'Trinidad und Tobago': 'tt',
  Tschad: 'td',
  Tschechien: 'cz',
  Tunesien: 'tn',
  Turkmenistan: 'tm',
  Tuvalu: 'tv',
  Türkei: 'tr',
  Uganda: 'ug',
  Ukraine: 'ua',
  Ungarn: 'hu',
  Uruguay: 'uy',
  Usbekistan: 'uz',
  Vanuatu: 'vu',
  Vatikanstadt: 'va',
  Venezuela: 've',
  'Vereinigte Arabische Emirate': 'ae',
  'Vereinigte Staaten': 'us',
  USA: 'us',
  'Vereinigtes Königreich': 'gb',
  England: 'en',
  Vietnam: 'vn',
  'Volksrepublik China': 'cn',
  China: 'cn',
  Weihnachtsinsel: 'cx',
  Weißrussland: 'by',
  'Zentral­afrikanische Republik': 'cf',
  Zypern: 'cy',
  Ägypten: 'eg',
  Äthiopien: 'et',
  Österreich: 'at',
  Schottland: 'sco',
  Wales: 'wal',
  Palestina: 'pal',
  Taiwan: 'taiw',
  Südamerika: 'cont_southamerica',
  Nordamerika: 'cont_northamerica',
  Asien: 'cont_asia',
  Europa: 'cont_europe',
  Afrika: 'cont_africa',
  // EN
  'Aland Islands': 'ax',
  Albania: 'al',
  Algeria: 'dz',
  'American Samoa': 'as',
  Antarctica: 'aq',
  'Antigua And Barbuda': 'ag',
  Argentina: 'ar',
  Armenia: 'am',
  Australia: 'au',
  Austria: 'at',
  Azerbaijan: 'az',
  Bangladesh: 'bd',
  Belarus: 'by',
  Belgium: 'be',
  Bolivia: 'bo',
  'Bosnia And Herzegovina': 'ba',
  'Bouvet Island': 'bv',
  Brazil: 'br',
  'British Indian Ocean Territory': 'io',
  'Brunei Darussalam': 'bn',
  Bulgaria: 'bg',
  Cambodia: 'kh',
  Cameroon: 'cm',
  Canada: 'ca',
  'Cape Verde': 'cv',
  'Cayman Islands': 'ky',
  'Central African Republic': 'cf',
  Chad: 'td',
  'Christmas Island': 'cx',
  'Cocos (Keeling) Islands': 'cc',
  Colombia: 'co',
  Comoros: 'km',
  Congo: 'cg',
  'Congo, Democratic Republic': 'cd',
  'Cook Islands': 'ck',
  "Cote D'Ivoire": 'ci',
  Croatia: 'hr',
  Cuba: 'cu',
  Cyprus: 'cy',
  Czech: 'cz',
  'Czech Republic': 'cz',
  Denmark: 'dk',
  Djibouti: 'dj',
  'Dominican Republic': 'do',
  Egypt: 'eg',
  'Equatorial Guinea': 'gq',
  Estonia: 'ee',
  Ethiopia: 'et',
  'Falkland Islands (Malvinas)': 'fk',
  'Faroe Islands': 'fo',
  Fiji: 'fj',
  Finland: 'fi',
  France: 'fr',
  'French Guiana': 'gf',
  'French Polynesia': 'pf',
  'French Southern Territories': 'tf',
  Gabon: 'ga',
  Georgia: 'ge',
  Germany: 'de',
  Greece: 'gr',
  Greenland: 'gl',
  Guernsey: 'gg',
  'Heard Island & Mcdonald Islands': 'hm',
  'Holy See (Vatican City State)': 'va',
  'Hong Kong': 'hk',
  Hungary: 'hu',
  Iceland: 'is',
  India: 'in',
  Indonesia: 'id',
  'Iran, Islamic Republic Of': 'ir',
  Iraq: 'iq',
  Ireland: 'ie',
  'Isle Of Man': 'im',
  Italy: 'it',
  Jamaica: 'jm',
  Jersey: 'je',
  Jordan: 'jo',
  Kazakhstan: 'kz',
  Kenya: 'ke',
  Kyrgyzstan: 'kg',
  "Lao People's Democratic Republic": 'la',
  Latvia: 'lv',
  Lebanon: 'lb',
  'Libyan Arab Jamahiriya': 'ly',
  Lithuania: 'lt',
  Luxembourg: 'lu',
  Macao: 'mo',
  Macedonia: 'mk',
  Madagascar: 'mg',
  Maldives: 'mv',
  'Marshall Islands': 'mh',
  Mauritania: 'mr',
  Mexico: 'mx',
  'Micronesia, Federated States Of': 'fm',
  Moldova: 'md',
  Mongolia: 'mn',
  Morocco: 'ma',
  Mozambique: 'mz',
  Netherlands: 'nl',
  'Netherlands Antilles': 'an',
  'New Caledonia': 'nc',
  'New Zealand': 'nz',
  'Norfolk Island': 'nf',
  'Northern Mariana Islands': 'mp',
  Norway: 'no',
  'Palestinian Territory, Occupied': 'ps',
  'Papua New Guinea': 'pg',
  Philippines: 'ph',
  Pitcairn: 'pn',
  Poland: 'pl',
  Qatar: 'qa',
  Reunion: 're',
  Romania: 'ro',
  Rwanda: 'rw',
  'Saint Barthelemy': 'bl',
  'Saint Helena': 'sh',
  'Saint Kitts And Nevis': 'kn',
  'Saint Lucia': 'lc',
  'Saint Martin': 'mf',
  'Saint Pierre And Miquelon': 'pm',
  'Saint Vincent And Grenadines': 'vc',
  'Sao Tome And Principe': 'st',
  'Saudi Arabia': 'sa',
  Scotland: 'sco',
  Serbia: 'rs',
  Seychelles: 'sc',
  Singapore: 'sg',
  Slovakia: 'sk',
  Slovenia: 'si',
  'Solomon Islands': 'sb',
  'South Africa': 'za',
  'South Georgia And Sandwich Isl.': 'gs',
  Spain: 'es',
  'Svalbard And Jan Mayen': 'sj',
  Swaziland: 'sz',
  Sweden: 'se',
  Switzerland: 'ch',
  'Syrian Arab Republic': 'sy',
  Tajikistan: 'tj',
  Tanzania: 'tz',
  'Timor-Leste': 'tl',
  'Trinidad And Tobago': 'tt',
  Tunisia: 'tn',
  Turkey: 'tr',
  'Turks And Caicos Islands': 'tc',
  'United Arab Emirates': 'ae',
  'United Kingdom': 'gb',
  'United States Outlying Islands': 'um',
  Uzbekistan: 'uz',
  'Viet Nam': 'vn',
  'Virgin Islands, British': 'vg',
  'Virgin Islands, U.S.': 'vi',
  'Wallis And Futuna': 'wf',
  'Western Sahara': 'eh',
  Yemen: 'ye',
  Zambia: 'zm',
  Zimbabwe: 'zw',
  UEFA: 'eu',
};

import { IPushNotifications } from '@common/interfaces';

export const defaultState: IPushNotifications = {
  loading: false,
  isEnabled: false, // activation status
  token: '',
  activeTargetTab: 'events',
  subscriptions: { categories: {}, events: {} },
  queue: [],
  reasons: {
    alarms_reasons_gate: false,
    alarms_reasons_finalResult: false,
    alarms_reasons_wasBreak: false,
    alarms_reasons_gameStart: false,
    alarms_reasons_penalty: false,
    alarms_reasons_redCard: false,
  },
};

export default defaultState;

import styled from 'styled-components';

import { TextSM } from '@features/core/styles/text.styled';

const getPadding = theme => {
  return theme.isDesktop
    ? `${theme.space_15} ${theme.space_10} ${theme.space_5} ${theme.space_10}`
    : theme.space_10;
};

export const ScrollContainer = styled.div`
  display: flex;
  position: relative;
  flex-direction: row;
  justify-items: start;
  justify-content: start;
  box-sizing: border-box;
  overflow-x: scroll;
  background-color: ${props => props.theme.categoryTabBackground};
  padding: ${({ theme }) => getPadding(theme)};
  margin-bottom: ${({ theme }) => theme.space_5};
  -ms-overflow-style: none;
  scrollbar-width: none;

  &:empty {
    padding: 0;
    margin-bottom: 0;
  }

  &::-webkit-scrollbar {
    display: none;
  }
`;

export const MarketsLabel = styled(TextSM)`
  white-space: nowrap;
  min-width: 34px;
  text-align: center;
  margin: auto;
`;

export const StyledButton = styled.button`
  display: flex;
  justify-content: center;
  background-color: ${props => props.theme.highlightsHeaderBackground};
  outline: none;
  text-align: center;
  color: ${props => props.theme.textMain};
  padding: ${({ theme }) => theme.space_10} ${({ theme }) => theme.space_5};
  width: max-content;
  margin-right: ${({ theme }) => theme.space_5};
  cursor: pointer;
  border-radius: 3px;

  &.selected {
    background-color: ${props => props.theme.accent};
    color: ${props => props.theme.textActive};

    p {
      font-weight: ${({ theme }) => theme.fontBold};
    }
  }
`;

import reduce from 'lodash/reduce';
import concat from 'lodash/concat';

import { ISubmitError } from '@common/interfaces';
import FieldsConfig from '@common/helpers/registrationHelper/fieldsConfig';

/**
 * getMinStepWithSubmitError
 *
 * @param {ISubmitError[]} errors
 * @returns {number} newStep
 */
const getMinStepWithSubmitError = (errors: ISubmitError[]): number => {
  return Math.min(
    ...reduce(
      errors,
      (acc, error) =>
        error.fieldName
          ? concat(acc, FieldsConfig[error.fieldName]?.step)
          : acc,
      [] as number[],
    ),
  );
};

export default getMinStepWithSubmitError;

import React from 'react';
import assign from 'lodash/assign';

import ICookie from '@features/core/services/ICookie';
import IHttp from '@features/core/services/IHttp';

import ILogger from '@packages/logger/core/ILogger';
import { IAppEvents } from '@packages/events/AppEvents.types';

import Config from '../../../packages/config';

const config = new Config();

export interface IServices {
  cookie: ICookie;
  logger: ILogger;
  domainLang: string;
  http: IHttp;
  events: IAppEvents;

  updateService(key: keyof IServices, value: IServices[typeof key]): void;

  isDesktopView: boolean;
  config: typeof config;
}

export const ServicesContext = React.createContext<IServices>({} as IServices);

export const useServices = (): IServices => React.useContext(ServicesContext);

const servicesInstance: IServices = { config } as IServices;

export const setServices = (services: IServices): void => {
  assign(servicesInstance, services);
};

export default servicesInstance;

import React from 'react';
import times from 'lodash/times';

import * as S from './SkeletonCashout.styled';

const SkeletonCashout: React.FC<{
  skeletonsCount: number;
}> = ({ skeletonsCount }) => {
  return (
    <S.Root>
      {times(skeletonsCount, skeletonIndex => (
        <S.Item key={skeletonIndex} isOdd={skeletonIndex % 2 === 0}>
          <div className="item-top">
            <div className="top-left">
              <div className="top-left-1 element" />
              <div className="top-left-2 element" />
            </div>
            <div className="top-right">
              <div className="top-right-1 element" />
            </div>
          </div>

          <div className="item-bottom">
            <div className="bottom-left">
              <div className="bottom-left-1 element" />
              <div className="bottom-left-2 element">
                <div className="triangle" />
              </div>
            </div>
            <div className="bottom-right">
              <div className="bottom-right-1 element" />
              <div className="bottom-right-2 element" />
            </div>
          </div>
        </S.Item>
      ))}
    </S.Root>
  );
};

export default SkeletonCashout;

import styled from 'styled-components';

import { TextNormal } from '@features/core/styles/text.styled';

const getTimerMargin = (isDesktop, isZeroMargin) => {
  if (isZeroMargin) {
    return '0';
  }

  return isDesktop ? '5' : '10';
};

export const Timer = styled(TextNormal)<{
  isZeroMargin: boolean;
}>`
  display: grid;
  grid-auto-flow: row;
  justify-content: center;
  align-items: center;
  justify-items: center;
  box-sizing: border-box;
  color: ${props => props.theme.textMain};

  @media screen and (max-width: 481px) {
    margin-right: 0;
  }

  @media screen and (min-width: 481px) {
    margin-right: ${({ theme, isZeroMargin }) =>
      getTimerMargin(theme.isDesktop, isZeroMargin)}px;
  }

  @media screen and (max-width: 481px) {
    min-width: 20px;
    font-size: ${({ theme }) => theme.fontSM};

    &.handball,
    &.icehockey,
    &.fieldshockey,
    &.american,
    &.aussierules,
    &.basketball {
      min-width: 30px;
    }

    &.baseball {
      min-width: 44px;
    }
  }
`;

export const TimerRow = styled.span`
  display: block;
  text-align: left;

  @media screen and (min-width: 1280px) and (max-width: 1335px) {
    min-width: 35px;
  }
`;

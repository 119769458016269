export interface ICashoutItem {
  betId: string;
  parentScrollRef: HTMLDivElement | null;
  even: boolean;
}

export enum StatusBet {
  DEFAULT = 'DEFAULT',
  CONFIRM = 'CONFIRM',
  DISABLED = 'DISABLED',
}

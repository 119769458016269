import React from 'react';

import { useServices } from '@features/core/services';
import WidgetsTabWrapper from '@features/detail/components/widgetsTabWrapper/WidgetsTabWrapper';

import { sportRadarClientId } from '@common/helpers/eventsHelper/eventDetailWidgetTabHelper';

import ILastMatches from './LastMatchesTab.types';

const LastMatchesTab = (props: ILastMatches): React.ReactElement => {
  const { betradarId, scriptId } = props;
  const { domainLang } = useServices();
  const src = `https://widgets.sir.sportradar.com/${sportRadarClientId}/widgetloader`;

  return (
    <WidgetsTabWrapper
      currentTab="last_matches"
      key={betradarId}
      qa="last-matches-tab"
      scriptId={scriptId}
    >
      <>
        <div
          id="sr-widget"
          data-sr-widget="headToHead.standalone"
          data-sr-match-id={betradarId}
        />
        <script type="application/javascript" src={src} async />
        <script
          id={scriptId}
          dangerouslySetInnerHTML={{
            __html: `
                        (function(a,b,c,d,e,f,g,h,i){a[e]||(i=a[e]=function(){(a[e].q=a[e].q||[]).push(arguments)},i.l=1*new Date,i.o=f,
                           g=b.createElement(c),h=b.getElementsByTagName(c)[0],g.async=1,g.src=d,g.setAttribute("n",e),h.parentNode.insertBefore(g,h)
                       )})(window,document,"script","https://widgets.sir.sportradar.com/${sportRadarClientId}/widgetloader","SIR", {
                       language: '${domainLang}',
                       theme: "neutral",
                       disableAds: true,
                       adsFrequency: false,
                   });
                   SIR("addWidget", "#sr-widget", "headToHead.standalone", {
                     matchId: ${betradarId},
                     onTrack: window.LMTStateHandler,
                     disableScoreboard: true,
                     components: ["lastmatches"],
                     gamePulseBtnEnable: true,
                   }
                 );`,
          }}
        />
        <div id="sr-widget" />
      </>
    </WidgetsTabWrapper>
  );
};

export default LastMatchesTab;

import React from 'react';

import {
  DartsTimer,
  NotStartedTimer,
  GenericPeriods,
  GenericPeriodsRemaining,
  GenericTimer,
  InterruptedTimer,
  VirtualTimer,
} from '@features/events/components/scoreboards/timers';
import { useEventsModel } from '@features/events/useEventsModel';

import {
  isHockey,
  isBasketball,
  isTennis,
  isVolleyball,
  isDarts,
  isAmericanFootball,
  isHandball,
  isEsport,
  isBeachVolleyball,
  isBaseBall,
} from '@common/helpers/eventsHelper/eventTypeHelper';
import {
  isPeriodInterrupted,
  isPeriodNotStarted,
} from '@common/helpers/eventsHelper/scoreboards/timerModel';
import { ITimer } from '@common/interfaces';
import { isLiveDisabled } from '@common/helpers/eventsHelper/eventStatusHelper';

import { IFactoryTimerProps } from './FactoryTimers.types';

const FactoryTimers = (props: IFactoryTimerProps): React.ReactElement => {
  const { topCategoryId, listType, id, shortTimer } = props;
  const { event } = useEventsModel(id, listType);
  const timer = event ? event.timer : ({} as ITimer);

  if (timer && isPeriodInterrupted(timer)) {
    return <InterruptedTimer />;
  }

  if (isLiveDisabled(event) || (timer && isPeriodNotStarted(timer))) {
    return (
      <NotStartedTimer
        event={event}
        shortTimer={shortTimer}
        listType={listType}
      />
    );
  }

  if (
    isTennis(event) ||
    isVolleyball(event) ||
    isBeachVolleyball(event, topCategoryId)
  ) {
    return (
      <GenericPeriods shortTimer={shortTimer} id={id} listType={listType} />
    );
  }
  if (isBaseBall(event, topCategoryId)) {
    return <GenericPeriods shortTimer={false} id={id} listType={listType} />;
  }

  if (isEsport(event)) {
    return <VirtualTimer id={id} listType={listType} shortTimer={shortTimer} />;
  }

  if (
    isHandball(event) ||
    isHockey(event) ||
    isBasketball(event) ||
    isAmericanFootball(event)
  ) {
    return (
      <GenericPeriodsRemaining
        id={id}
        listType={listType}
        shortTimer={shortTimer}
      />
    );
  }

  if (isDarts(event)) {
    return <DartsTimer id={id} listType={listType} />;
  }

  return <GenericTimer id={id} listType={listType} shortTimer={shortTimer} />;
};

export default FactoryTimers;

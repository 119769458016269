import styled from 'styled-components';

import { TextLarge } from '@features/core/styles/text.styled';

const mobileStyles = theme => `
  z-index: ${theme.zIndexSidebar + 25};
  max-width: ${window.innerWidth}px;
`;

export const Root = styled.div<{
  isMyAccount?: boolean;
  isNotificationVisible: boolean;
}>`
  margin-bottom: ${({ theme }) => theme.isDesktop && theme.space_15};
  width: 100%;
  ${({ theme }) => !theme.isDesktop && mobileStyles(theme)};
  ${({ isMyAccount }) => (isMyAccount ? 'z-index: 0;' : 'z-index: 99;')};
  ${({ isNotificationVisible }) => isNotificationVisible && 'display: none;'};

  & > p div {
    ${({ isMyAccount, theme }) =>
      theme.isDesktop && isMyAccount && 'max-width: calc(100vw - 270px);'};
  }
`;

export const NotificationHeader = styled.div`
  display: grid;
  grid-auto-flow: column;
  justify-content: start;
  grid-auto-columns: auto auto 1fr;
  justify-items: center;
  margin-bottom: ${({ theme }) => theme.space_10};
  align-items: center;
  grid-gap: ${({ theme }) => theme.space_10};

  div {
    font-size: ${({ theme }) => theme.fontLG};
  }

  .close-icon {
    margin-left: auto;
    cursor: pointer;
  }
`;

const getNotificationWrapperPadding = ({ theme }) => {
  return theme.isDesktop
    ? `${theme.space_15} ${theme.space_20} ${theme.space_15} ${theme.space_10}`
    : `${theme.space_15} ${theme.space_25}`;
};

export const NotificationWrapper = styled(TextLarge)<{
  hasRightCol?: boolean;
}>`
  color: ${({ theme }) => theme.textActive};
  background-color: ${({ theme }) => theme.textPrimary};
  padding: ${getNotificationWrapperPadding};
  box-shadow: 0 5px 10px 0 ${({ theme }) => theme.boxShadow};
  ${({ hasRightCol }) => hasRightCol && 'width: calc(100% - 271px)'};

  p {
    font-size: ${({ theme }) => theme.fontMD};
  }

  button {
    grid-gap: ${({ theme }) => theme.space_10};
  }
`;

const getDesktopBlockStyles = ({ theme }) => `
  div {
    margin-bottom: 0;
  }

  p {
    padding-left: ${theme.space_30};
  }

  button {
    max-width: 240px;
  }
  
  .emailBtn {
  justify-self: end;
  align-self: end;
  }
  
  #retryTitle {
  padding-left: 0;
  }
`;

export const GridWrapper = styled.div`
  display: grid;
  justify-content: space-between;
  align-items: start;
  grid-auto-flow: ${({ theme }) => (!theme.isDesktop ? 'row' : 'column')};
  grid-auto-columns: 3fr 2fr;
  grid-gap: ${({ theme }) => theme.space_20};

  ${({ theme }) => theme.isDesktop && getDesktopBlockStyles};
`;

export const FlexWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: ${({ theme }) => theme.space_15};

  ${({ theme }) =>
    theme.isDesktop ? getDesktopBlockStyles : 'flex-direction: column'};
`;

export const IconWrapper = styled.div`
  justify-self: flex-end;
`;

const getDeskBtnContainerStyles = ({ theme }) => `
    height: 100%;
    width: 100%;
    display: grid;
    justify-content: space-around;
    grid-gap: ${theme.space_10};
    justify-self: end;
    justify-content: end;
`;

export const ButtonContainer = styled.div`
  ${({ theme }) => theme.isDesktop && getDeskBtnContainerStyles}

  .verify-later {
    margin-top: ${({ theme }) => !theme.isDesktop && theme.space_10};
    width: 100%;
  }
`;

export const HighlightBoldTitle = styled(TextLarge)`
  font-weight: ${({ theme }) => theme.fontBold};
`;

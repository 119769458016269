import styled from 'styled-components';

const getTranslateByListType = (listType: string, theme): string => {
  switch (listType) {
    case 'cashout':
      return '0';
    case 'detail':
      return '-70';
    default:
      return theme.isDesktop ? '-110' : '-100';
  }
};

export const Possession = styled.div<{ listType: string }>`
  justify-content: center;
  align-items: center;
  display: grid;
  grid-auto-flow: column;
  position: absolute;
  transform: translate(
    ${({ listType, theme }) => getTranslateByListType(listType, theme)}%,
    -50%
  );
  top: 50%;
`;

export const dartsStyles = `
  svg {
    transform: rotate(135deg);
    width: 10px;
    height: 10px;
  }
`;

export const Serving = styled.span<{
  darts?: boolean;
  americanFootball?: boolean;
  volleyball?: boolean;
}>`
  color: ${props => props.theme.accent};
  justify-content: center;
  align-items: center;
  display: grid;
  grid-auto-flow: column;

  svg {
    width: 8px;
    height: ${({ volleyball }) => (volleyball ? 8 : 7)}px;
    position: relative;

    path {
      fill: ${props => props.theme.accent};
    }
  }

  ${({ darts }) => darts && dartsStyles};
  padding-left: ${({ americanFootball, theme }) =>
    americanFootball && theme.space_5};
`;

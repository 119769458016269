import styled from 'styled-components';

import { hexToRgba } from '@common/helpers/deviceUtil';

export const MainInfoContentItem = styled.div`
  border: 1px solid ${({ theme }) => theme.accordionBorderColor};
  border-radius: 5px;
  transition: background-color 0.25s linear, color 0.25s linear,
    border-color 0.25s linear;

  & .border {
    width: 100%;
    height: 0.5px;
    background-color: ${({ theme }) => hexToRgba(theme.secondary, 0.2)};
  }

  & .info-content {
    overflow: hidden;
    transition: all 0.5s ease-in-out;
    box-sizing: border-box;
  }

  & .info-content.info-down {
    max-height: 0;
  }

  & .info-content.info-up {
    max-height: 100px;
  }
`;
export const ItemArrow = styled.div`
  position: relative;
  display: grid;
  justify-content: end;
  align-items: center;
  align-content: center;
  width: 100%;
  height: 100%;

  i {
    border: solid ${({ theme }) => theme.main};
    border-width: 0 2px 2px 0;
    display: block;
    width: 7px;
    height: 7px;
    cursor: pointer;
  }

  .arrow-up {
    transform: rotate(-135deg);
    -webkit-transform: rotate(-135deg);
  }

  .arrow-down {
    transform: rotate(45deg);
    -webkit-transform: rotate(45deg);
  }
`;
export const ItemContentArrowInfo = styled.div`
  display: flex;
  align-items: center;
  max-width: 100%;
  flex-wrap: wrap;
  padding: ${({ theme }) => theme.space_10};
`;

export const ItemContentWrapper = styled.div`
  display: grid;
  grid-auto-flow: column;
  grid-auto-columns: 1fr 50px 10px;
  align-items: center;
  grid-gap: ${({ theme }) => theme.space_15};

  @media screen and (min-width: 481px) {
    padding: ${({ theme }) => theme.space_15} ${({ theme }) => theme.space_25}
      ${({ theme }) => theme.space_15} ${({ theme }) => theme.space_15};
  }

  @media screen and (max-width: 481px) {
    padding: ${({ theme }) => theme.space_15};
  }
`;

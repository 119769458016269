import React from 'react';
import isUndefined from 'lodash/isUndefined';
import some from 'lodash/some';

import {
  CurrentScoreLarge,
  PeriodScoreLarge,
  ItemDiv,
  ICommonScoreProps,
} from '@features/events/components/scoreboards/factoryScoreboards';
import { ScoreboardHoc } from '@features/events/components/scoreboards/scores';
import AnimatedScoreValue from '@features/events/components/scoreboards/scores/common/AnimatedScoreValue';

import {
  getHome,
  getAway,
} from '@common/helpers/eventsHelper/scoreboards/scoreBoardModel';
import { isOvertime } from '@common/helpers/eventsHelper/eventStatusHelper';

export const FootballDetail = (
  props: ICommonScoreProps,
): React.ReactElement => {
  const { score, period, event } = props;
  const periods = score.periods['1H'] ? score.periods['1H'] : [];
  const hasPenalty = Boolean(score?.periods?.PT);
  const isHTvalid = !!periods?.length && !some(periods, el => isUndefined(el));

  const renderPenaltyScore = (): JSX.Element | null => {
    if (score?.periods?.PT) {
      return (
        <ItemDiv isRow>
          <AnimatedScoreValue score={score.periods.PT[0]} /> :
          <AnimatedScoreValue score={score.periods.PT[1]} />
        </ItemDiv>
      );
    }
    return null;
  };

  const renderRegularScore = (): JSX.Element => {
    return (
      <ItemDiv isRow>
        <AnimatedScoreValue score={getHome(score)} /> :
        <AnimatedScoreValue score={getAway(score)} />
      </ItemDiv>
    );
  };

  return (
    <CurrentScoreLarge className="score">
      {hasPenalty && renderPenaltyScore()}
      {!hasPenalty && renderRegularScore()}
      {period >= 2 && !isOvertime(event) && !hasPenalty && isHTvalid && (
        <PeriodScoreLarge>
          &#40;
          <AnimatedScoreValue score={periods[0]} /> :
          <AnimatedScoreValue score={periods[1]} />
          &#41;
        </PeriodScoreLarge>
      )}
    </CurrentScoreLarge>
  );
};

export default ScoreboardHoc(FootballDetail);

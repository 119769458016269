import styled from 'styled-components';

import { TextNormal } from '@features/core/styles/text.styled';

export const LabelWrapper = styled.div`
  display: grid;
  grid-auto-flow: row;
  padding: 0 ${({ theme }) => theme.space_15};
  background: none;
  font-weight: ${({ theme }) => theme.fontBold};
  text-align: center;
  color: ${props => props.theme.textMain};
  font-size: ${props => props.theme.fontMD};
`;

export const WinnerLabel = styled(TextNormal)`
  text-shadow: none;
  width: 100%;
  align-self: start;
  text-align: center;
  padding: ${({ theme }) => theme.space_10};
`;

export const Label = styled.div`
  position: relative;
  text-shadow: none;
  padding: 0;
  font-weight: ${({ theme }) => theme.fontBold};
  display: grid;
  grid-auto-flow: column;
  justify-content: start;
  width: 100%;
`;

export const Title = styled.span`
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  word-break: break-word;
`;

export const BallPossessionWrapper = styled.div`
  div {
    left: -6px;
  }
`;

import forEach from 'lodash/forEach';

import { TTimeout } from '@common/interfaces';

const deleteTimeout = (...timeList: TTimeout[]): void => {
  // eslint-disable-next-line
  forEach(timeList, (timeout: TTimeout) => {
    if (timeout) {
      clearTimeout(timeout as NodeJS.Timeout);
    }
  });
};

export default deleteTimeout;

import React from 'react';

import { useServices } from '@features/core/services';
import WidgetsTabWrapper from '@features/detail/components/widgetsTabWrapper/WidgetsTabWrapper';

import { sportRadarClientId } from '@common/helpers/eventsHelper/eventDetailWidgetTabHelper';

import ISportTable from './SportTable.types';

const SportTable: React.FC<ISportTable> = props => {
  const { betradarId, scriptId } = props;
  const { domainLang } = useServices();

  return (
    <WidgetsTabWrapper
      currentTab="table"
      qa="sport-table-tab"
      scriptId={scriptId}
    >
      <script
        id={scriptId}
        dangerouslySetInnerHTML={{
          __html: `
                 (function(a,b,c,d,e,f,g,h,i){a[e]||(i=a[e]=function(){(a[e].q=a[e].q||[]).push(arguments)},i.l=1*new Date,i.o=f,
                    g=b.createElement(c),h=b.getElementsByTagName(c)[0],g.async=1,g.src=d,g.setAttribute("n",e),h.parentNode.insertBefore(g,h)
                )})(window,document,"script","https://widgets.sir.sportradar.com/${sportRadarClientId}/widgetloader","SIR", {
                language: '${domainLang}',
                theme: "neutral",
                disableAds: true,
                showOdds: false,
                adsFrequency: false,
            });
            SIR("addWidget", ".livematchtracker_${scriptId}", "match.lmtPlus", {
              matchId: ${betradarId},
              onTrack:  window.LMTStateHandler,
            }
          );`,
        }}
      />
    </WidgetsTabWrapper>
  );
};

export default SportTable;

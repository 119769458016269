import React from 'react';
import { useTranslation } from 'react-i18next';

import { WalletConfirmForm } from '@features/user/components/walletConfirm';
import SupportLink from '@features/core/routing/SupportLink';

import { WALLET_REQUEST } from '@common/constants/dialogs';
import { SidebarSide } from '@common/interfaces';
import { isDesktopView } from '@common/helpers/deviceUtil';
import { useWalletsState } from '@common/providers/payments/wallets/useWallets';
import getImageBasePath from '@common/helpers/getImageBasePath';

import * as S from './WalletConfirm.styled';

const WalletConfirm = (): React.ReactElement => {
  const { t } = useTranslation();
  const request = useWalletsState(state => state.request);

  return (
    <S.Root>
      <S.ContentWrapper>
        <S.ContentTitle>{t('user.walletConfirm.content_title')}</S.ContentTitle>
        <S.ImageWrapper>
          <S.ImageStyled
            width="100%"
            src={getImageBasePath('/images/shop.svg')}
            alt="shop"
          />
        </S.ImageWrapper>
        {request?.data[0]?.affiliate_name && (
          <S.ContentAddress>{request.data[0].affiliate_name}</S.ContentAddress>
        )}
      </S.ContentWrapper>
      <S.FormWrap>
        <WalletConfirmForm />
        <S.SupportContent>
          <span>{t('user.walletConfirm.support_text_1')}</span>
          {!isDesktopView() && <br />}
          <span>{t('user.walletConfirm.support_text_2')}</span>
          <SupportLink label="app-layout.menu.support" className="link" />.
        </S.SupportContent>
      </S.FormWrap>
    </S.Root>
  );
};

WalletConfirm.meta = {
  side: isDesktopView() ? SidebarSide.center : SidebarSide.stretch,
  key: WALLET_REQUEST,
};

export default WalletConfirm;

import apiRpcRequest from '@common/api/common/apiCommonRequest';
import { ICustomError, IFetchForgetPasswordResponse } from '@common/interfaces';
import { MethodType } from '@common/api/apiHandler';

type apiResponse = Promise<IFetchForgetPasswordResponse & ICustomError>;

const fetchForgetPassword = (payload: {
  email: string;
  captcha: string;
}): apiResponse => {
  const { email, captcha } = payload;

  const body = {
    captcha,
    params: {
      email,
    },
    method: 'user.account_recovery_instructions',
  };

  return apiRpcRequest({ method: MethodType.Post, data: body }) as apiResponse;
};

export default fetchForgetPassword;

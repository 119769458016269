import React from 'react';
import { Router } from 'react-router-dom';
import { I18nextProvider } from 'react-i18next';
import { ThemeProvider } from 'styled-components';
import split from 'lodash/split';

import history from '@features/core/router/history';
import { useThemeScheme } from '@features/core/theming/contexTheme';
import addAuthHeaders from '@features/user/axiosInterceptors/addAuthHeaders';
import i18n from '@features/core/translation';
import axios from '@features/core/axios';
import servicesInstance, {
  ServicesContext,
  setServices,
  IServices,
} from '@features/core/services';
import { langCodes } from '@features/core/router';

import { Logger } from '@packages/logger/core';
import Cookie from '@packages/cookie';
import createAppEvents from '@packages/events';

import appEventsHandler from '@common/listeners';
import { marketingEventHandler } from '@common/listeners/marketing';
import { isDesktopView, isAndroid } from '@common/helpers/deviceUtil';

import HeadApp from '../../features/app/HeadApp';

import IMainProviderProps from './MainProvider.types';

const cookie = new Cookie();

const langValidate = new RegExp(`^${langCodes}$`, 'gm');

const domain_lang = split(window.location.pathname, '/')[1];

const { config } = servicesInstance;

let services: IServices = {
  config,
  cookie,
  domainLang: langValidate.test(domain_lang) ? domain_lang : 'de',
  logger: new Logger({
    logToConsole:
      (process.env.NODE_ENV && process.env.NODE_ENV === 'development') || false,
  }),
  http: axios,
  events: createAppEvents(),
  updateService: (name: keyof IServices, value: IServices[typeof name]) => {
    services = {
      ...services,
      [name]: value,
    };
  },
  isDesktopView: isDesktopView(),
};

setServices(services);

appEventsHandler();
marketingEventHandler();

axios.interceptors.request.use(addAuthHeaders);

const MainProvider: React.FC<IMainProviderProps> = ({ children }) => {
  const { theme } = useThemeScheme();

  return (
    <>
      <ThemeProvider
        theme={{
          ...theme,
          isDesktop: services.isDesktopView,
          isAndroid: isAndroid(),
        }}
      >
        <Router history={history}>
          <HeadApp />
          <ServicesContext.Provider value={services}>
            <I18nextProvider i18n={i18n}>{children}</I18nextProvider>
          </ServicesContext.Provider>
        </Router>
      </ThemeProvider>
    </>
  );
};

export default MainProvider;

/**
 * isDST
 * Function check Daylight Saving Time for the current time and locale
 *
 * @returns {boolean} isDST
 */
export default (): boolean => {
  const jan = new Date(2019, 0, 1).getTimezoneOffset();
  const jul = new Date(2019, 6, 1).getTimezoneOffset();
  const currentTimeZoneOffset = new Date().getTimezoneOffset();

  return jul === currentTimeZoneOffset && jan !== jul;
};

export const STATE_OPENED = 'opened';
export const STATE_ACCEPTED = 'accepted';
export const STATE_QUEUED = 'queued';
export const STATE_REJECTED = 'rejected';
export const STATE_ERROR = 'error';

const MIN_CASHOUT = 0.1;

export const defaultState = {
  loading: false,
  requested: {},
  favorites: [] as Array<string>,
  sorting: '',
  priority: '',
  extendedLayout: false,
  min_cashout: MIN_CASHOUT,
};

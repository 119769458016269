import services from '@features/core/services';

import { DynamicCalls } from '@common/api/api';
import fetchPocketTags from '@common/api/account/fetchPocketTags';
import hasLugasRestriction from '@common/helpers/userHelper/hasLugasRestrictions';
import { apiHandler, MethodType } from '@common/api/apiHandler';

const { API_LOGOUT } = DynamicCalls;

const fetchLogout = async (): Promise<void> => {
  if (hasLugasRestriction()) {
    await fetchPocketTags({
      method: MethodType.Post,
      params: { tag: { key: 'usr.activity', value: 'off' } },
    });
    await fetchPocketTags({
      method: MethodType.Post,
      params: {
        tag: { key: 'usr.front.activitystart', value: '' },
      },
    });
  }

  return apiHandler<void>(
    { method: MethodType.Get },
    {
      apiUrl: API_LOGOUT,
      apiData: {
        dynamicLanguage: services.domainLang,
      },
    },
  );
};

export default fetchLogout;

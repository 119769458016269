import React from 'react';
import Helmet from 'react-helmet';

const LiveChatScript = (): JSX.Element => (
  <Helmet>
    <script type="text/javascript">
      {`
        (function(d, src, c) {
          var t = d.scripts[d.scripts.length - 1],s = d.createElement('script');
          s.id = 'la_x2s6df8d';
          s.async = true;
          s.src = src;
          s.onload = s.onreadystatechange = function() {
            var rs=this.readyState;
            if (rs && (rs!='complete') && (rs!='loaded')) {
              return;
            }

            c(this);
          };
          t.parentElement.insertBefore(s,t.nextSibling);
        })(document,
        'https://pferdewetten.ladesk.com/scripts/track.js',
        function(e) {
          const internalButtonId = 'mi2dwvzh';
          window.LiveAgent = LiveAgent;
          var button = LiveAgent.createButton(internalButtonId, e);
          button.buttonDiv.style.height = '0';
          button.buttonDiv.style.overflow = 'hidden';

          setTimeout(() => {
            window.liveChatFunction = function () {
              // TODO::: need to check with live chat provider
              if(LiveAgent.instance && LiveAgent.instance.elements.entries().next().value[0]) {
                const tempButton = LiveAgent.instance.elements.entries().next().value[0];
                
                internalButton = document.getElementById(tempButton);

                if (
                  internalButton &&
                  !LiveAgent.instance.openedWidget
                ) {
                  return internalButton.click();
                }

                if (LiveAgent.instance.openedWidget) {
                  return LiveAgent.instance.openedWidget.startChat();
                }

                if (internalButton) {
                  return internalButton.click();
                }
              }
            }
            liveChatFunction();
          }, 1000);
        });
      `}
    </script>
  </Helmet>
);

export default LiveChatScript;

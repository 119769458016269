import { FieldData, FormInstance } from 'rc-field-form/lib/interface';
import find from 'lodash/find';
import keys from 'lodash/keys';
import { RuleObject } from 'rc-field-form/es/interface';
import trim from 'lodash/trim';
import isBoolean from 'lodash/isBoolean';
import map from 'lodash/map';

import { IRulesConfig } from '@features/profile/components/forgotPasswordForm/makePasswordRecoveryConfig';

export const REQUIRED_TEXT = 'common.presets.is_required';

/**
 * isAllRequiredFieldsFilled
 *
 * @param {FormInstance} form
 * @param {IRulesConfig} rules
 * @returns {boolean} isAllRequiredFieldsFilled
 */
export const isAllRequiredFieldsFilled = (
  form: FormInstance,
  rules: IRulesConfig,
): boolean =>
  !find(keys(form.getFieldsValue()), (item: string) => {
    if (!rules[item]) {
      return false;
    }
    return !!find(rules[item].rules, (element: RuleObject):
      | boolean
      | undefined => {
      const initialVal = rules[item]?.initialValue || '';
      const fieldVal = trim(form.getFieldValue(item));
      return element.required && (!fieldVal || fieldVal === initialVal);
    });
  });

/**
 * setTrimmedField
 *
 * @param {FormInstance} form
 * @param {string} type
 * @param {string} value
 * @returns {void}
 */
export const setTrimmedField = (
  form: FormInstance,
  type: string,
  value: string,
): void => {
  return form.setFieldsValue({
    [type]: isBoolean(value) ? value : trim(value),
  });
};

/**
 * resetFieldErrors
 *
 * @param {string} name
 * @param {FormInstance} form
 */
export const resetFieldErrors = (name: string, form: FormInstance): void => {
  form.setFields([{ name, errors: [] }]);
};

/**
 * getIsDisableFormButton
 *
 * @param {FieldData[]} fields
 * @param {FormInstance} form
 * @param {IRulesConfig} config
 * @param {boolean} loading
 * @returns {boolean} isDisableFormButton
 */
export const getIsDisableFormButton = (
  fields: FieldData[],
  form: FormInstance,
  config: IRulesConfig,
  loading?: boolean,
): boolean => {
  return (
    !!find(fields, 'errors.length') ||
    !isAllRequiredFieldsFilled(form, config) ||
    !!loading
  );
};

/**
 * Helps to split address string from autofill
 *
 * @param {string} address
 * @returns {object | null} address components
 */
export const splitAddress = (
  address: string,
): Record<string, string> | null => {
  const regex = /^(.*?[\D])(\d[\w/-]*)(?:, (?!.*\d[\w/-]+,).*?(\d{5,10})\s+([^,]+))?.*$/;
  const match = address.match(regex);

  if (match) {
    // We skip first element because its original address string
    const [, street, houseNumber, postcode, city, country] = map(match, trim);
    return { street, houseNumber, postcode, city, country };
  }

  return null;
};

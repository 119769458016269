import styled from 'styled-components';

import { OddsComparisonSectionItem } from '../oddsComparisonSectionItem';

export const OddsComparisonWinChance = styled(OddsComparisonSectionItem)`
  background-color: ${props => props.theme.tertiaryBackground};

  & .sw-odds {
    color: ${props => props.theme.accent};
  }
`;

export default OddsComparisonWinChance;

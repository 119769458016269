import { IBetDetail, IBetSelection } from '@common/interfaces';
import { ICashoutRequestError } from '@common/providers/cashout/types';

export interface ICashoutRequest {
  amount: string;
  offer: string;
  bet_id: string;
  lang: string;
  offer_token?: string;
  barcode?: string;
}

export interface ICashoutSerialize {
  amount: number;
  offer: number;
  bet_id: string;
  lang: string;
  offer_token?: string;
  barcode?: string;
}

export interface IQueudCashoutSerialize {
  bet_id: string;
  cashout_id: string | undefined;
  lang: string;
  queued?: boolean;
  offer_token?: string;
  barcode?: string;
}

export interface IQueudCashout {
  bet_id: string;
  cashout_id: string | undefined;
  lang: string;
  queued?: boolean;
  offerToken?: string;
  barcode?: string;
}

export enum ICashoutStates {
  'opened',
  'accepted',
  'queued',
  'rejected',
}

export enum StatusBet {
  DEFAULT = 'DEFAULT',
  CONFIRM = 'CONFIRM',
  DISABLED = 'DISABLED',
}

export interface ICashoutRequested {
  state: string;
  bet_detail?: Partial<IBetDetail>;
  cashout_detail?: Record<string, string>;
  rejected_msg?: string;
  error?: ICashoutRequestError;
  queue_delay?: number;
  paid_status?: number;
}

export interface ICashoutButtonProps {
  betId: string;
  stateStatus: string;
  partCashout: boolean;
  setStateStatus: (string) => void;
  submit: (boolean, number, withCookies) => void;
  onSubmitCashoutQueued: () => void;
  setPartCashout: (bet: boolean) => void;
  serialize: (
    cashout: ICashoutSerialize,
  ) => Record<keyof ICashoutRequest, string>;
  barcode?: string;
}

export interface ICashoutInputProps {
  betId: string;
  cashoutOfferQueued: string;
  initialTotalCost: string;
  stateStatus: string;
  partCashout: boolean;
  setStateStatus: (string) => void;
  setPartCashout: (bet: boolean) => void;
  serialize: (
    cashout: ICashoutSerialize,
  ) => Record<keyof ICashoutRequest, string>;
  submit: (boolean, number) => void;
  onSubmitCashoutQueued: (requestedCashout?: ICashoutRequested) => void;
  scrollToElem: () => void;
  barcode?: string;
}

export interface ICashoutModel {
  offerToken: string;
  isFavorite: boolean;
  cashoutOffer: string;
  remainingRisk: string;
  requestedCashout: ICashoutRequested;
  selections: IBetSelection[];
  totalStake: string;
  type: string;
  isLive: boolean;
  cashoutDisabled: boolean;
  unavailableSelectionId: string | null;
  isBonus?: string;
  isShopEMoney?: string;
}

import services from '@features/core/services';

import { StaticCalls, DynamicCalls } from '@common/api/api';
import { apiHandler, MethodType } from '@common/api/apiHandler';
import {
  FetchOptions,
  IApiRpcRequestsResponse,
} from '@common/api/common/apiCommonTypes';

const { API_JSONRPC: API_Static } = StaticCalls;
const { API_JSONRPC: API_DYNAMIC } = DynamicCalls;

const apiRpcRequest = async (
  options: FetchOptions,
): Promise<IApiRpcRequestsResponse> => {
  const isGet = options.method === MethodType.Get;
  const apiUrl = isGet ? API_DYNAMIC : API_Static;

  const modifiedOptions: FetchOptions = { ...options };

  if (!isGet && modifiedOptions.data) {
    modifiedOptions.data.jsonrpc = '2.0';
  }

  return apiHandler<FetchOptions>(modifiedOptions, {
    apiUrl,
    ...(isGet
      ? {
          apiData: {
            dynamicLanguage: services.domainLang,
          },
        }
      : {}),
  });
};

export default apiRpcRequest;

/* eslint-disable no-nested-ternary */
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import split from 'lodash/split';
import last from 'lodash/last';
import head from 'lodash/head';
import { useTheme } from 'styled-components';

import * as S from '@pages/login/Login.styled';

import { InfoBlock } from '@features/profile/components/infoBlock';
import { CheckboxInput } from '@features/core/form/components/checkboxInput';

import {
  LIMIT_LAYER_CREDIT_CHECK,
  LIMIT_LAYERS,
} from '@common/constants/dialogs';
import numeral from '@common/helpers/numeralHelper';
import { setLocation } from '@common/providers/router/helper';
import { ButtonType, IUser, SidebarSide } from '@common/interfaces';
import {
  getVerificationLink,
  needVerification,
} from '@common/helpers/paymentsHelper/paymentModel';
import { useUserState } from '@common/providers/user/useUserState';
import {
  closeSidebar,
  useAppState,
} from '@common/providers/application/useAppState';
import repeatedCreditCheck from '@common/api/limits/fetchCreditCheck';
import { ISidebarData } from '@common/providers/application/types';

import { StyledButton } from '@ui/components/buttons';
import { Icon } from '@ui/components/icon';

const formatActivation = (data): ISidebarData => {
  let newData = { ...data };

  if (newData.activation) {
    const [date, time] = split(newData.activation, ' ');
    newData = { ...newData, date, time };
    delete newData.activation;
  }

  if (newData.value) {
    newData.value = numeral(newData.value).format(`0,0`);
  }

  return newData;
};

const LimitLayer = (): React.ReactElement => {
  const { t } = useTranslation();
  const openedSidebars = useAppState(s => s.openedSidebars);
  const [isChecked, setIsChecked] = useState(false);
  const { statusSuccess, statusError, main } = useTheme();
  const sidebar = head(openedSidebars);
  const type = last(split(sidebar?.type, '_'));
  const isVerification = type === 'verification';
  const isCreditCheck = type === 'check';
  const isApproved = type === 'approved';
  const isDisabled = isCreditCheck && !isChecked;
  const rejectedStyles = type === 'reject' ? statusError : main;
  const colorStyle = {
    color: isApproved ? statusSuccess : rejectedStyles,
  };
  const icon = isApproved ? 'check-circle' : 'info-circle';
  const dynamicBtnText = isVerification || isCreditCheck;
  const user = useUserState(state => state.data);

  const handleClose = async (): Promise<void> => {
    if (isDisabled) {
      return;
    }
    if (needVerification(user as IUser)) {
      setLocation(getVerificationLink(user as IUser));
    }
    if (isCreditCheck && sidebar) {
      const { data } = sidebar;
      repeatedCreditCheck(
        data?.value,
        data?.prevValue,
        data?.limitType,
        user?.username,
      );
      closeSidebar(LIMIT_LAYER_CREDIT_CHECK);
      return;
    }
    closeSidebar(sidebar?.type as string);
  };

  return (
    <S.Content className="info-limit">
      <S.ContentInner className="limit-inner">
        <S.ContentIcon
          className="closeIcon"
          onClick={(): void => closeSidebar(sidebar?.type as string)}
          name="close"
          fill="currentColor"
        />
        <S.DescWrapper>
          <S.TitleWrapper>
            <S.IconWrap style={colorStyle}>
              <Icon name={icon} width="28px" height="28px" />
            </S.IconWrap>
            <S.Header style={colorStyle}>
              {t(`user.lugas.title.${type}`)}
            </S.Header>
          </S.TitleWrapper>
          <InfoBlock
            message={t(
              `user.lugas.text.${type}`,
              formatActivation(sidebar?.data),
            )}
          />

          {isCreditCheck && (
            <S.ConfirmWrapper>
              <CheckboxInput
                name="credit_check"
                checked={isChecked}
                onChange={(): void => setIsChecked(!isChecked)}
                isDarkTheme
              />
              <div>
                <InfoBlock
                  message={t(
                    `user.lugas.confirm_credit_check`,
                    formatActivation(sidebar?.data),
                  )}
                />
              </div>
            </S.ConfirmWrapper>
          )}
        </S.DescWrapper>
      </S.ContentInner>
      <S.ButtonInner bgColor={colorStyle.color}>
        <StyledButton
          disabled={isDisabled}
          className="limit-layer-btn"
          onClick={(e): void => {
            e.preventDefault();
            handleClose();
          }}
          label={`user.lugas.btn_text.${dynamicBtnText ? type : 'close'}`}
          buttonType={ButtonType.Secondary}
        />
      </S.ButtonInner>
    </S.Content>
  );
};

LimitLayer.meta = {
  side: SidebarSide.center,
  key: LIMIT_LAYERS,
  props: { type: '' },
};

export { LimitLayer as Component };

export default LimitLayer;

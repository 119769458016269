import React from 'react';
import { useTranslation } from 'react-i18next';

import { DefaultTimerView } from '@features/events/components/scoreboards/timers/';

export const InterruptedTimer = (): React.ReactElement => {
  const { t } = useTranslation();

  return <DefaultTimerView rows={[t('events.interrupted')]} />;
};

export default InterruptedTimer;

import { ICustomError, IDisabledMarkets } from '@common/interfaces';
import { StaticCalls } from '@common/api/api';
import { apiHandler, MethodType } from '@common/api/apiHandler';

const { API_DISABLED_MARKETS } = StaticCalls;

export const fetchDisabledMarkets = async (): Promise<IDisabledMarkets &
  ICustomError> =>
  apiHandler<IDisabledMarkets>(
    { method: MethodType.Get },
    {
      apiUrl: API_DISABLED_MARKETS,
    },
  );

export default fetchDisabledMarkets;

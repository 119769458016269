import styled from 'styled-components';

import { TextLarge, TextSM } from '@features/core/styles/text.styled';

import { StatusCode } from '@common/interfaces';
import { hexToRgba } from '@common/helpers/deviceUtil';

import { Icon } from '@ui/components/icon';

const getGridStylesDesktop = ({ theme }) => `
  display: grid;
  grid-template-columns: 20px 85px minmax(135px, 1fr) minmax(140px, 1fr) 50px 90px 60px 20px;
  grid-gap: ${theme.space_5};
  > div{
    max-width:125px;
   } 
  
  @media (max-width: 1450px) {
  grid-template-columns: 20px 85px minmax(100px,1fr) minmax(100px,1fr) 50px 90px 60px 20px;
  }
  
  @media (min-width: 1600px) {
      grid-template-columns: 20px 85px minmax(150px, 1fr) minmax(140px, 1fr) 50px 90px 60px 20px;
      grid-gap: ${theme.space_10};
  }

  @media (min-width: 1920px) {
   grid-template-columns: 20px 85px 192px minmax(150px, 192px) 50px 90px 60px 20px;
   grid-gap: ${theme.space_10};
  }
`;

const getGridStylesMobile = betDetail => `
    display: grid;
    grid-auto-flow: column;
    justify-content: start;
    grid-auto-columns: ${betDetail ? '1fr' : 'min-content 1fr min-content'};
`;

export const ItemContainer = styled.div<{ isEven?: boolean }>`
  background: ${({ theme, isEven }) =>
    !isEven ? theme.textActive : theme.elementNuanceColor};

  &.active {
    border-left: 3px solid ${({ theme }) => theme.accent};
    padding-left: ${({ theme }) => theme.space_5};
    box-shadow: 1px 2px 5px 5px
      ${({ theme }) => hexToRgba(theme.textTertiary, 0.4)};
    width: 99% !important;
    height: 95%;
  }
`;

export const Container = styled.div<{ isBetDetail?: boolean }>`
  width: 100%;
  ${({ theme, isBetDetail }) =>
    theme.isDesktop && !isBetDetail
      ? getGridStylesDesktop
      : getGridStylesMobile(isBetDetail)};
  cursor: pointer;
`;

export const Col = styled.div`
  padding: ${({ theme }) => theme.space_5} ${({ theme }) => theme.space_10};
  color: ${props => props.theme.textMain};

  p:last-child {
    margin-bottom: 0;
  }
`;

export const ColInfo = styled(Col)`
  overflow: hidden;
`;

export const ColMore = styled(Col)`
  display: grid;
  justify-content: center;
  align-items: center;
  padding: 0;
`;

export const NoWrap = styled(TextLarge)`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  padding: 0;
  font-weight: ${({ theme }) => theme.fontBold};
`;

export const infoTextDesktop = theme => `
  .cash {
    color: ${theme.textMain};
    font-weight: ${theme.fontRegular};
    font-size: ${theme.fontMD};
  }
`;

const getInfoTextStyles = ({ theme }) => {
  return !theme.isDesktop ? `margin: ${theme.space_5} 0;` : '';
};

export const InfoText = styled(TextSM)`
  padding: 0;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  width: 100%;

  &.description {
    font-size: ${({ theme }) => theme.fontMD};
    margin-bottom: ${({ theme }) => theme.space_5};
    ${getInfoTextStyles};
    display: flex;
    justify-content: space-between;

    .info-block {
      padding-right: ${({ theme }) => theme.space_5};
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }

  .cash {
    display: grid;
    grid-auto-flow: column;
    align-items: center;
  }

  ${({ theme }) => theme.isDesktop && infoTextDesktop(theme)};
`;

export const PossibleWinnings = styled(InfoText)`
  display: grid;
  grid-auto-flow: column;
  font-size: ${({ theme }) => (theme.isDesktop ? theme.fontSM : theme.fontMD)};
  width: auto;
  text-align: ${({ theme }) => theme.isDesktop && 'right'};
  justify-content: end;
  align-items: baseline;

  & > svg {
    margin-right: ${({ theme }) => theme.space_10};
    align-self: normal;
    ${({ theme }) => theme.isDesktop && 'height: 12px;'};
  }
`;

const getStatusSquareMarginStyles = ({ theme }) => {
  return theme.isDesktop
    ? `${theme.space_5} ${theme.space_10} 0 0`
    : `${theme.space_5} 0`;
};

export const StatusSquare = styled.div<{ colorSquare?: number }>`
  display: grid;
  align-items: center;
  justify-content: center;
  height: 20px;
  width: 20px;
  margin: ${getStatusSquareMarginStyles};
  color: ${({ theme }) => theme.textMain};
  background: ${({ colorSquare, theme }) => {
    switch (colorSquare) {
      case StatusCode.WON:
        return theme.statusWon;
      case StatusCode.CANCELLED:
        return theme.statusClosed;
      case StatusCode.OPEN:
        return theme.statusOpen;
      case StatusCode.LOST:
        return theme.statusLost;
      default:
        return '';
    }
  }};
`;

export const IconRight = styled(Icon)`
  align-self: center;
  margin-right: ${({ theme }) => theme.space_5};
`;

export const GridContainer = styled.div<{
  isBetDetail?: boolean;
  colorByStatus?: boolean;
}>`
  display: grid;
  grid-auto-flow: column;
  justify-content: ${props =>
    props.theme.isDesktop && !props.isBetDetail ? 'flex-end' : 'space-between'};
  align-items: center;
  color: ${({ colorByStatus, theme }) =>
    colorByStatus ? theme.accent : theme.textMain};
  margin-top: ${({ theme }) => theme.space_5};
`;

export const DeskTitleWrapper = styled.div`
  display: flex;
  justify-content: flex-start;
`;

export const TextLG = styled.span`
  font-size: ${({ theme }) => theme.fontLG};
`;

export const ReUseBetWrapper = styled.div`
  padding: ${({ theme }) => theme.space_15} ${({ theme }) => theme.space_10};
  display: grid;
  grid-gap: ${({ theme }) => theme.space_10};
`;

export const ItemHeader = styled.div`
  ${getGridStylesDesktop};

  align-items: center;
  background-color: ${({ theme }) => theme.secondary};
  color: ${({ theme }) => theme.textMain};
  height: 30px;
  text-align: right;

  & > div {
    padding-top: 0;
  }
`;

export const DeskCol = styled.div`
  color: ${({ theme }) => theme.textMain};
  font-size: ${({ theme }) => theme.fontMD};
`;

export const DeskHead = styled.div`
  color: ${({ theme }) => theme.textMain};
  font-size: ${({ theme }) => theme.fontMD};
`;

export const DeskIcon = styled(DeskCol)``;

export const DeskData = styled(DeskCol)`
  text-align: left;
  padding-top: ${({ theme }) => theme.space_5};
`;
export const DeskDataHeader = styled(DeskHead)`
  text-align: left;
  padding-top: ${({ theme }) => theme.space_5};
`;

export const DeskTitle = styled(DeskCol)`
  max-width: 175px !important;
  padding-left: ${({ theme }) => theme.space_25};
  text-align: left;

  p {
    font-size: ${({ theme }) => theme.fontMD};
  }

  &.active * {
    color: ${({ theme }) => theme.accent};
  }
`;

export const DeskTitleHeader = styled(DeskHead)`
  max-width: 175px !important;
  padding-left: ${({ theme }) => theme.space_25};
  text-align: left;

  p {
    font-size: ${({ theme }) => theme.fontMD};
  }

  &.active * {
    color: ${({ theme }) => theme.accent};
  }
`;

export const WrapperEventsNames = styled(TextSM)<{ isLongName: boolean }>`
  margin-top: ${({ theme }) => theme.space_5};
  ${props => !props.isLongName && 'max-width: 125px;'}

  overflow: hidden;
  text-overflow: ellipsis;

  @media (min-width: 1600px) {
    max-width: 192px;
  }
`;

export const DeskBetDetails = styled(DeskCol)`
  text-align: right;
`;
export const DeskBetDetailsHeader = styled(DeskHead)`
  text-align: right;
`;

export const DeskBetDetailsWin = styled.div`
  display: flex;
  justify-content: end;
  margin: ${({ theme }) => theme.space_5} 0;
  text-align: right;
  font-weight: ${({ theme }) => theme.fontBold};
`;

export const DeskQuote = styled(DeskCol)`
  padding-top: ${({ theme }) => theme.space_5};
  text-align: right;
`;

export const DeskQuoteHeader = styled(DeskHead)`
  padding-top: ${({ theme }) => theme.space_5};
  text-align: right;
`;

export const DeskProfit = styled(DeskCol)`
  text-align: right;
`;

export const DeskProfitHeader = styled(DeskHead)`
  text-align: right;
`;

const getBoldStyles = ({ theme }) => `${theme.fontBold};`;

export const WinSum = styled.div<{ colorByStatus?: boolean }>`
  margin: ${({ theme }) => theme.space_5} 0;
  text-align: right;
  font-weight: ${({ colorByStatus }) => colorByStatus && getBoldStyles};
  color: ${({ colorByStatus, theme }) =>
    colorByStatus ? theme.accent : theme.textMain};

  * {
    font-size: ${({ theme }) => theme.fontMD};
  }
`;

export const DeskStatus = styled(DeskCol)`
  text-align: right;
`;
export const DeskStatusHeader = styled(DeskHead)`
  text-align: right;
`;

export const DeskArrow = styled(DeskCol)``;

export const GiftIconDesktop = styled(Icon)`
  margin-right: ${({ theme }) => theme.space_10};
`;

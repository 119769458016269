import React from 'react';
import { useTranslation } from 'react-i18next';

import { formatCashoutOffer } from '@common/helpers/cashoutHelper';

import { StyledButton } from '@ui/components/buttons';
import { ButtonSize, ButtonType } from '@ui/components/buttons/Button.styled';

import * as S from './Confirm.styled';
import { IConfirmProps } from './Confirm.types';

const Confirm: React.FC<IConfirmProps> = ({
  cashoutOffer,
  submit,
  closeSubmit,
  cashoutDisabled,
}) => {
  const { t } = useTranslation();

  return (
    <S.ConfirmWrapper>
      <StyledButton
        onClick={submit}
        dataQa="btn-cashout-confirm"
        label={formatCashoutOffer(cashoutOffer, cashoutDisabled, true)}
        size={ButtonSize.Big}
      />
      <StyledButton
        onClick={closeSubmit}
        label=""
        aria-label={t('common.buttons.close')}
        icon={{
          name: 'close',
          width: 10,
          height: 10,
          isSingle: true,
          className: 'icon-close',
        }}
        buttonType={ButtonType.LightGhost}
        size={ButtonSize.Big}
      />
    </S.ConfirmWrapper>
  );
};

export default Confirm;

import { create } from 'zustand';

import { IInboxState, IMessage } from '@common/providers/inbox/types';
import defaultState from '@common/providers/inbox/state';

export const useInboxState = create<IInboxState>(() => defaultState);

export const setInboxLoading = (): void => {
  useInboxState.setState(() => ({
    loading: true,
  }));
};

export const setMessages = (messages: IMessage[]): void => {
  useInboxState.setState(() => ({
    loading: false,
    messages,
  }));
};

export const setMessage = (message: IMessage | null): void => {
  useInboxState.setState(() => ({
    loading: false,
    message,
  }));
};

export const setMessagesCount = (unreadMessages: number): void => {
  useInboxState.setState(() => ({
    loading: false,
    unreadMessages,
  }));
};

export const readMessage = (): void => {
  useInboxState.setState(state => {
    const count = state.unreadMessages;
    return {
      unreadMessages: count - 1,
    };
  });
};

export const setMessageError = (error: string): void => {
  useInboxState.setState(() => ({
    error,
  }));
};

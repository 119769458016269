import isArray from 'lodash/isArray';
import replace from 'lodash/replace';
import join from 'lodash/join';
import includes from 'lodash/includes';

import services from '@features/core/services';

import { EVENT_SERVICE_URL } from '@common/constants/config';
import { DynamicCalls, getApiUrl, StaticCalls } from '@common/api/api';
import { getTestList } from '@common/helpers/deviceUtil';
import {
  ICustomError,
  IEventData,
  IEventSearchParams,
  IFetchEventListParams,
  SearchEventsPromiseType,
} from '@common/interfaces';
import { apiHandler, MethodType } from '@common/api/apiHandler';

const { API_FAKE_EVENTS, API_TEST_EVENTS_LIST } = StaticCalls;
const {
  API_SAVE_EVENTS_LIST,
  API_DELETE_EVENTS_LIST,
  API_EDIT_EVENTS_LIST,
} = DynamicCalls;

export const fetchTestEventList = async (
  testlist: string,
): Promise<IEventData> => {
  const url = getApiUrl(API_FAKE_EVENTS);
  services.logger.info('EVENTS - USING TESTING LIST', testlist);
  const { data } = await services.http.get(url, {
    params: {
      testlist,
    },
  });
  return JSON.parse(data);
};

export const fetchTestEventsList = async (): Promise<string[]> => {
  const url = getApiUrl(API_TEST_EVENTS_LIST);
  const { data } = await services.http.get(url);
  return data;
};

const generateEventProps = ({
  categories,
  date_to,
  highlights_date_to,
  date_from,
  events,
  sort_favorite_id,
  sort_favorite_category_id,
  live_status = [],
  offset = 0,
  rows = 60,
  sort = 'liveWWW',
  sportsbook_id = 0,
}): IFetchEventListParams => {
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const event_ids = isArray(events!) ? events.join(',') : events;
  const category_id = replace(
    isArray(categories) ? categories.join(',') : categories || '',
    /-/g,
    ',',
  );
  return {
    sportsbook_id,
    event_ids,
    category_id,
    offset,
    rows,
    sort,
    sort_favorite_id: isArray(sort_favorite_id)
      ? sort_favorite_id.join(',')
      : sort_favorite_id,
    sort_favorite_category_id: isArray(sort_favorite_category_id)
      ? sort_favorite_category_id.join(',')
      : sort_favorite_category_id,
    live_status: join(live_status, ','),
    highlights_date_to,
    date_to,
    date_from,
  };
};

export const saveTestEventsList = async (
  props,
): Promise<{ dumpName: string } & ICustomError> => {
  const params = generateEventProps(props);

  return apiHandler(
    { method: MethodType.Post, data: params },
    {
      apiUrl: API_SAVE_EVENTS_LIST,
      apiData: {
        dynamicLanguage: services.domainLang,
      },
    },
  );
};
export const deleteTestEventsList = async (props): Promise<void> => {
  const url = getApiUrl(API_DELETE_EVENTS_LIST, { dynamicName: props });
  await services.http.delete(url);
};

export const editTestEventsList = async (
  props,
): Promise<{ message: string } & ICustomError> => {
  const url = getApiUrl(API_EDIT_EVENTS_LIST, {
    dynamicOldFileName: props.params,
  });
  return services.http.request({
    url,
    ...{ method: MethodType.Post, data: props.body },
  });
};

const getUrlWithLocale = (url: string): string => {
  const locale = services.domainLang;
  if (locale === 'de') {
    return url;
  }

  if (includes(url, 'de-')) {
    return replace(url, 'de-', `${locale}-`);
  }
  if (includes(url, '/de/')) {
    return replace(url, '/de/', `/${locale}/`);
  }
  return url;
};

export const searchEvents = async (
  params: IEventSearchParams,
): Promise<SearchEventsPromiseType> => {
  const url = getUrlWithLocale(
    services.config.get(EVENT_SERVICE_URL) as string,
  );
  const { data } = await services.http.get(url, { params });

  return data;
};

export default async (props): Promise<IEventData> => {
  const testlist = getTestList();
  if (testlist) {
    return fetchTestEventList(testlist);
  }

  const params = generateEventProps(props);

  let url = getUrlWithLocale(services.config.get(EVENT_SERVICE_URL) as string);

  if (props?.sync) {
    url = replace(url, '/v1/events', '/v2/sync');
  }

  const { data } = await services.http.get(url, {
    params,
  });

  return data;
};

import React, { MouseEvent } from 'react';
import includes from 'lodash/includes';
import styled from 'styled-components';
import endsWith from 'lodash/endsWith';
import { Link, useLocation } from 'react-router-dom';

import isExternalLink from '@features/core/routing/isExternalLink';
import { getPageURL, PageName } from '@features/core/routing/linkAliases';

import { appendLanguage } from '@common/providers/router/helper';
import { isDesktopView } from '@common/helpers/deviceUtil';

import { ICustomLinkProps } from './CustomLink.types';

const StyledSpan = styled.span`
  display: grid;
  grid-auto-flow: column;
  position: relative;
  padding-left: ${({ theme }): string => theme.space_10};
  margin-left: -10px;
`;

const CustomLink: React.FC<ICustomLinkProps> = ({
  className,
  href,
  activeClassName,
  onClick,
  children,
  dataQa = null,
  disabled,
  isNewBlank,
  ariaLabel,
  isSoftCompare,
  ...rest
}) => {
  const currentLocation = useLocation().pathname;

  const isDepositAndWithdrawalPage =
    !isDesktopView() &&
    endsWith(currentLocation, getPageURL(PageName.USER_PAYOUT)) &&
    href === PageName.USER_PAYIN;
  let location;
  if (href && href in PageName) {
    location = getPageURL(PageName[href]);
  } else {
    location = appendLanguage(href || '');
  }

  let isActive;
  if (isSoftCompare) {
    isActive =
      isDepositAndWithdrawalPage || includes(currentLocation, location);
  } else {
    isActive = endsWith(currentLocation, location);
  }

  if (disabled) {
    return <StyledSpan className="disabled-custom-link">{children}</StyledSpan>;
  }

  const handleClick = (
    event: MouseEvent<HTMLAnchorElement> | MouseEvent<HTMLDivElement>,
  ): void => {
    if (onClick) {
      onClick(event);
    }
  };

  if (!href) {
    return (
      <div
        onClick={handleClick}
        className={className}
        role="presentation"
        data-qa={dataQa}
      >
        {children}
      </div>
    );
  }

  if ((location && isExternalLink(location)) || isNewBlank) {
    return (
      <a
        {...rest}
        className={`${className} ${isActive ? activeClassName : ''}`}
        href={location}
        target="_blank"
        rel="noopener noreferrer"
        onClick={handleClick}
        data-qa={dataQa}
      >
        {children}
      </a>
    );
  }
  return (
    <Link
      className={`${className} ${isActive ? activeClassName : ''}`}
      to={location}
      onClick={handleClick}
      data-qa={dataQa}
      aria-label={ariaLabel}
    >
      {children}
    </Link>
  );
};

export default CustomLink;

import CustomError, {
  convertResponseToError,
} from '@features/core/error/error';

import { IResponseError } from '@common/interfaces';
import { searchEvents } from '@common/api/events/fetchEventsList';
import { searchCasino } from '@common/api/events/fetchCasino';
import searchCategories from '@common/api/events/searchCategories';
import {
  searchCategoriesSuccess,
  searchEventSuccess,
  setSearchDefineLoading,
  setSearchDefineError,
} from '@common/providers/search/useSearch';

export const searchEventsAction = async (label: string): Promise<void> => {
  setSearchDefineLoading('events');
  try {
    const { events, categories } = await searchEvents({ label });
    searchEventSuccess({ events, eventCategories: categories });
  } catch (error) {
    setSearchDefineError(
      'error',
      new CustomError({
        message: convertResponseToError(error as IResponseError),
      }),
    );
  }
};

export const searchCasinoAction = async (search: string): Promise<void> => {
  setSearchDefineLoading('casino');
  try {
    const response = await searchCasino({ search });
    if (response instanceof CustomError) {
      throw Error(response.message);
    }
  } catch (error) {
    setSearchDefineError(
      'casino',
      new CustomError({
        message: convertResponseToError(error as IResponseError),
      }),
    );
  }
};

export const searchCategoriesAction = async (label: string): Promise<void> => {
  setSearchDefineLoading('categories');
  try {
    const categories = await searchCategories({ label });
    if (categories instanceof CustomError) {
      throw Error(categories.message);
    }
    searchCategoriesSuccess(categories);
  } catch (error) {
    setSearchDefineError(
      'categories',
      new CustomError({
        message: convertResponseToError(error as IResponseError),
      }),
    );
  }
};

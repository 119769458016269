import styled from 'styled-components';

import { TextNormal } from '@features/core/styles/text.styled';

export const ScoreboardDiv = styled.div`
  display: grid;
  grid-auto-flow: column;
  justify-content: center;
  min-width: ${props => props.theme.isDesktop && '45px'};
  align-items: center;

  &.generic-scoreboard,
  &.baseball-scoreboard,
  &.generic-last-basketball-scoreboard,
  &.generic-last-scoreboard,
  &.generic-last-list-scoreboard {
    @media screen and (min-width: 481px) {
      padding-right: ${({ theme }) => theme.space_5};
      margin-left: ${({ theme }) => !theme.isDesktop && theme.space_5};
    }
  }

  @media screen and (min-width: 768px) and (max-width: 1200px) {
    font-size: ${({ theme }) => theme.fontSM};
  }

  @media screen and (max-width: 481px) {
    &.generic-scoreboard,
    .scoreboard {
      div {
        min-width: 20px;
      }
    }

    &.handball {
      div {
        min-width: 30px;
      }
    }

    &.generic-last-scoreboard.baseball {
      div {
        min-width: 16px;
      }
    }
  }
`;

export const ItemDiv = styled.div<{ isRow?: boolean }>`
  height: 100%;
  display: grid;
  grid-auto-flow: row;
  justify-content: center;
  text-align: center;
  align-items: center;
  ${({ isRow }) =>
    isRow &&
    'display: flex; min-width: 52px; width: fit-content; justify-content: space-between;'}

  & > * {
    margin: ${({ theme }) => theme.space_1} 0;
  }

  &:last-of-type {
    border: ${({ theme }) => theme.border};
  }
`;

export const getScoreDivTotal = (color, theme) => `
  font-weight: ${theme.fontBold};
  color: ${color};
  
  @media screen and (min-width: 481px) {
    min-width: 13px;
  }
`;

export const getScoreDivMarket = theme => `
  border: 1px solid ${theme.accent};
  padding: 0;
  padding: ${theme.space_1};
  border-radius: 3px; 
  color: ${theme.accent};
  font-size: smaller;
  
   @media screen and (min-width: 481px) {
      min-width: 25px;
  }
`;

export const ScoreDiv = styled.div<{ total?: boolean; isMarked?: boolean }>`
  font-weight: ${({ theme }) => theme.fontRegular};
  text-align: center;
  color: ${props => props.theme.textMain};
  ${({ total, theme }) => total && getScoreDivTotal(theme.accent, theme)}
  ${({ isMarked, theme }) => isMarked && getScoreDivMarket(theme)};

  @media screen and (min-width: 481px) {
    min-width: 13px;
    padding-right: ${({ theme }) => theme.space_5};

    &.period {
      position: relative;
      left: 4px;
    }
  }
`;

export const CurrentScoreLarge = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  color: ${props => props.theme.accent};
  font-size: ${({ theme }) => theme.fontH1};
  font-weight: ${({ theme }) => theme.fontBold};
  text-align: center;
  padding: ${({ theme }) => theme.space_10} 0;
`;

export const PeriodScoreLarge = styled(TextNormal)`
  display: flex;
  justify-content: space-between;
  text-align: center;
  min-width: 32px;
  width: fit-content;
  font-weight: ${({ theme }) => theme.fontRegular};
  color: ${props => props.theme.textMain};
`;

const scoreActiveDivTotal = ({ theme }) => `
  font-weight: ${theme.fontBold};
  
  @media screen and (min-width: 481px) {
    min-width: 13px;
    padding-right: ${theme.space_5};
  }
`;

export const ScoreActiveDiv = styled.div<{ total?: boolean }>`
  color: ${props => props.theme.accent};
  ${({ total }) => total && scoreActiveDivTotal};
`;

import styled from 'styled-components';

export const Form = styled.form`
  position: relative;

  .info-limit {
    position: absolute;
    top: 0;
  }

  button {
    min-width: 200px;
  }
`;

export const FormGroup = styled.div`
  max-width: 600px;

  &:not(:first-child) {
    margin-top: ${({ theme }) => theme.space_20};
  }

  .group-title {
    display: grid;
    grid-auto-flow: column;
    margin-bottom: ${({ theme }) => theme.space_15};
    font-weight: ${({ theme }) => theme.fontBold};
  }

  .info-icon {
    margin-top: -10px;
    cursor: pointer;
  }
`;

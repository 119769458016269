import { createWithEqualityFn } from 'zustand/traditional';
import { shallow } from 'zustand/shallow';

import defaultState from './state';
import { ISetTransactionsPayload, ITransactionsState } from './types';

export const useTransactions = createWithEqualityFn<ITransactionsState>(
  () => defaultState,
  shallow,
);

const DEFAULT_PAGE_SIZE = 30;

export const setTransactions = ({
  offset,
  cnt_rows: totalRecords,
  transactions = [],
  type,
  max_age_days,
}: ISetTransactionsPayload): void => {
  const totalPages = parseInt(totalRecords, 10)
    ? Math.ceil(parseInt(totalRecords, 10) / DEFAULT_PAGE_SIZE)
    : 0;
  const currentPage = (offset ? offset / DEFAULT_PAGE_SIZE : 0) + 1;

  useTransactions.setState(state => {
    const transactionsData =
      currentPage > 1 ? state.data.concat(transactions) : transactions;

    return {
      ...state,
      type,
      totalPages,
      currentPage,
      data: currentPage > 1 ? state.data.concat(transactions) : transactions,
      loading: false,
      offset: transactionsData.length,
      max_age_days,
    };
  });
};

export const setTransactionsType = (type: string): void => {
  useTransactions.setState(() => ({
    type,
    totalPages: defaultState.totalPages,
    currentPage: defaultState.currentPage,
    data: defaultState.data,
    offset: defaultState.offset,
    max_age_days: defaultState.max_age_days,
  }));
};

export const resetTransactions = (): void =>
  useTransactions.setState(defaultState);

export const setSelectedTransactionId = (transactionId: string): void => {
  useTransactions.setState(() => ({ selectedTransactionId: transactionId }));
};

export const setTransactionsLoading = (state: boolean): void =>
  useTransactions.setState(() => ({
    loading: state,
  }));

import styled from 'styled-components';

import { TextSM } from '@features/core/styles/text.styled';

export const SpecialValueDiv = styled(TextSM)<{
  isEven?: boolean;
  isLongSpecialValue: boolean;
}>`
  position: relative;
  color: ${props => props.theme.textMain};
  display: inline-block;
  text-align: center;

  @media screen and (min-width: 481px) {
    width: ${({ isLongSpecialValue }) =>
      isLongSpecialValue ? '50px' : '36px'};
    min-width: ${({ theme }) => !theme.isDesktop && '37px'};
  }

  @media screen and (max-width: 481px) {
    grid-gap: ${({ theme }) => theme.space_10};
  }
`;

export const EmptySpecialValue = styled(TextSM)`
  position: relative;
  display: grid;
  grid-auto-flow: column;
  height: 33px;
  justify-content: space-around;
  align-items: center;
  color: ${props => props.theme.textMain};

  @media screen and (min-width: 481px) {
    width: 36px;
    min-width: ${({ theme }) => !theme.isDesktop && '37px'};
  }
`;

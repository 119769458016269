import styled from 'styled-components';

import { Icon } from '@ui/components/icon';

// eslint-disable-next-line import/prefer-default-export
export const StateIcon = styled(Icon)`
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 10px;
`;

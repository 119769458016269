import React from 'react';
import map from 'lodash/map';

import BetPackerIcon from '@features/detail/components/betPacker/BetPackerIcon';
import { IBetPackerIconSize } from '@features/detail/components/betPacker/BetPacker.types';
import BetStatusSquare from '@features/bets/components/betStatusSquare/BetStatusSquare';

import * as S from './CashoutBetPacker.styled';
import ICashoutBetPackerProps from './CashoutBetPacker.types';

const CashoutBetPacker: React.FC<ICashoutBetPackerProps> = ({
  base_predictions_detail,
  status,
  odds,
}) => {
  return (
    <S.Container className="cashout_betPacker">
      <S.BetPackerLeftSideWrapper>
        <BetStatusSquare status={status} />

        <S.TextWrapper>
          {map(
            base_predictions_detail,
            ({ competitor_name, market_type, market_special_value }, key) => {
              return (
                <S.MarketWrapper key={key}>
                  <S.LabelWrapper>
                    <S.MarketType>{market_type} - </S.MarketType>
                    {market_special_value && (
                      <S.BetSpecialValue>
                        {`${market_special_value} - `}
                      </S.BetSpecialValue>
                    )}
                    &nbsp;
                    <S.MarketName>{competitor_name}</S.MarketName>
                  </S.LabelWrapper>
                </S.MarketWrapper>
              );
            },
          )}
        </S.TextWrapper>
      </S.BetPackerLeftSideWrapper>
      <S.BetpackerIconWrapper>
        <BetPackerIcon size={IBetPackerIconSize.small} />
        <S.BetpackerWrapper>{odds}</S.BetpackerWrapper>
      </S.BetpackerIconWrapper>
    </S.Container>
  );
};

export default CashoutBetPacker;

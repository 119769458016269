import React from 'react';

import { DisabledMarkets } from '@features/events/components/markets';

import { IDisabledMarketDual } from './DisabledMarketDual.types';

const DisabledMarketDual: React.FC<IDisabledMarketDual> = props => {
  const { pageType, hasSpecialValue, hasLabel } = props;

  return (
    <DisabledMarkets
      hasLabel={hasLabel}
      pageType={pageType}
      hasSpecialValue={hasSpecialValue}
      predictionType="dual"
    />
  );
};

export default DisabledMarketDual;

import { ISO_DATE_TEMPLATE } from '@features/core/form/components/dateInput/DateTemplates';

import formatDate from '@common/helpers/timeHelper/localizedFormat';

const addLocaleTimeFromTimestamp = (
  timestamp: number,
): { localeZoneTimestamp: string; localeZoneDate: Date } => {
  const localeZoneTimestamp = formatDate(timestamp, ISO_DATE_TEMPLATE);
  const localeZoneDate = new Date(timestamp);

  return {
    localeZoneTimestamp,
    localeZoneDate,
  };
};

export default addLocaleTimeFromTimestamp;

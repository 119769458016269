import services from '@features/core/services';
import CustomError from '@features/core/error/error';
import { PageName } from '@features/core/routing';

import { TOKEN, SESSION_ID, USER_ID } from '@common/constants/cookie';
import authUser from '@common/providers/user/actions/authUser';
import logoutUser from '@common/providers/user/actions/logoutUser';
import { IFetchSwitchAccount } from '@common/providers/user/actions/actionTypes';
import { MethodType } from '@common/api/apiHandler';
import { setLocation } from '@common/providers/router/helper';
import apiRpcRequest from '@common/api/common/apiCommonRequest';
import { startSwitchingAccount } from '@common/providers/user/useUserState';
import {
  closeSidebars,
  removeNotifications,
  useAppState,
} from '@common/providers/application/useAppState';

const switchUserAccount = async (payload: string): Promise<void> => {
  const { notifications } = useAppState.getState();
  closeSidebars();
  startSwitchingAccount();
  if (notifications.length) {
    removeNotifications();
  }
  const params = {
    account_id: payload,
    method: 'user.switch_session',
  };

  const response = (await apiRpcRequest({
    method: MethodType.Get,
    params,
  })) as IFetchSwitchAccount;

  if (response instanceof CustomError) {
    await logoutUser();
    setLocation(PageName.HOME);
  } else {
    const {
      session_id: session,
      secret_token: token,
      user_id,
    } = response.result;

    services.cookie.set(TOKEN, token);
    services.cookie.set(SESSION_ID, session);
    services.cookie.set(USER_ID, user_id);
    authUser();
  }
};

export default switchUserAccount;

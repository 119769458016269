import { RuleObject } from 'rc-field-form/es/interface';

import i18next from '@features/core/translation';
import Patterns from '@features/core/form/utils/patterns';

const firstLetterRule = async (
  rule: RuleObject,
  value: string,
): Promise<void> => {
  const pattern = Patterns.FIRST_LETTER;
  const message = rule.message || i18next.t('common.presets.first_character');

  if (pattern.test(value)) {
    return Promise.resolve();
  }
  return Promise.reject(new Error(`${message}`));
};

export default firstLetterRule;

import { FormInstance } from 'rc-field-form/lib/interface';
import replace from 'lodash/replace';

import i18next from '@features/core/translation';
import { IReturnMakeConfigOptions } from '@features/payments/components/paymentForm';
import Patterns from '@features/core/form/utils/patterns';

import incorrectCharactersValidator from '@common/helpers/formsHelpers/validation/incorrectCharacters';
import notOnlyLetters from '@common/helpers/formsHelpers/validation/notOnlyLetters';
import notOnlyNumbers from '@common/helpers/formsHelpers/validation/notOnlyNumbers';
import phonePreset from '@common/helpers/formsHelpers/validation/phonePreset';
import samePasswordPreset from '@common/helpers/formsHelpers/validation/samePasswordPreset';
import passwordPreset from '@common/helpers/formsHelpers/validation/passwordPreset';
import userNamePreset from '@common/helpers/formsHelpers/validation/userNamePreset';
import emailPreset from '@common/helpers/formsHelpers/validation/emailPreset';
import requiredRule from '@common/helpers/formsHelpers/validation/requiredRule';
import loginPreset from '@common/helpers/formsHelpers/validation/loginPreset';
import birthPlacePreset from '@common/helpers/formsHelpers/validation/birthPlacePreset';
import firstLetterNumberRule from '@common/helpers/formsHelpers/validation/firstLetterNumberRule';

export type IRulesConfig = Record<string, IReturnMakeConfigOptions>;

const makeRegistrationStepConfig = (
  form: FormInstance,
  countryCode = '',
): IRulesConfig => ({
  // step 1
  email: {
    rules: emailPreset(),
  },
  login: {
    rules: loginPreset(form),
  },
  password: {
    rules: passwordPreset(),
  },
  password2: {
    rules: samePasswordPreset(form),
  },
  country_code: {
    rules: [
      {
        required: true,
        message: `${i18next.t('registration.step1.country_required')}`,
      },
    ],
  },
  // step 2
  sex: {
    rules: [
      {
        required: true,
        message: `${i18next.t('registration.step2.choose_salutation')}`,
      },
    ],
  },
  firstname: {
    rules: userNamePreset('registration.step2.first_name_required'),
  },
  lastname: {
    rules: userNamePreset('registration.step2.last_name_required'),
  },
  maidenname: {
    rules: userNamePreset('registration.step2.birthname_required'),
  },
  country_of_birth: {
    rules: [
      {
        required: true,
        message: `${i18next.t(
          'registration.step2.choose_country_of_birth_required',
        )}`,
      },
    ],
  },
  place_of_birth: {
    rules: birthPlacePreset(),
  },
  nationality: {
    rules: [
      {
        required: true,
        message: `${i18next.t('registration.step2.nationality_required')}`,
      },
    ],
  },
  // step 3
  address_street_name: {
    rules: [
      {
        required: true,
        message: `${i18next.t('registration.step3.street_required')}`,
      },
      {
        validator: incorrectCharactersValidator(
          Patterns.INCORRECT_SYMBOLS,
          i18next.t('registration.step3.incorrect_characters_3'),
        ),
      },
      {
        max: 64,
        message: replace(
          i18next.t('common.presets.max_length_characters'),
          '@',
          '64',
        ),
      },
      {
        validator: firstLetterNumberRule,
      },
      {
        validator: notOnlyNumbers,
        message: i18next.t('registration.step3.incorrect_street'),
      },
    ],
  },
  address_street_number: {
    rules: [
      {
        required: true,
        message: `${i18next.t('registration.step3.streetNumber_required')}`,
      },
      {
        validator: incorrectCharactersValidator(
          Patterns.INCORRECT_SYMBOLS,
          i18next.t('registration.step3.incorrect_characters_3'),
        ),
      },
      {
        max: 12,
        message: replace(
          i18next.t('common.presets.max_length_characters_small'),
          '@',
          '12',
        ),
      },
      {
        validator: firstLetterNumberRule,
      },
      {
        validator: notOnlyLetters,
        message: i18next.t('registration.step3.incorrect_street'),
      },
    ],
  },
  address_zip: {
    rules: [
      {
        required: true,
        message: `${i18next.t('registration.step3.postCode_required')}`,
      },
      {
        validator: incorrectCharactersValidator(
          Patterns.INCORRECT_POST_CODE,
          i18next.t('registration.incorrect_characters'),
        ),
      },
    ],
  },
  address_city: {
    rules: [
      {
        required: true,
        message: `${i18next.t('registration.step3.city_required')}`,
      },
      {
        pattern: Patterns.CONTAIN_LETTERS,
        message: i18next.t('registration.step3.one_letter_character'),
      },
      {
        min: 2,
        message: replace(
          i18next.t('common.presets.min_length_characters'),
          '@',
          '2',
        ),
      },
      {
        validator: firstLetterNumberRule,
      },
    ],
  },
  region: {
    rules:
      countryCode === 'DE'
        ? [
            {
              required: true,
              message: `${i18next.t('registration.step3.region_required')}`,
            },
          ]
        : [],
  },
  phone: {
    rules: phonePreset('registration.step3.phone_required'),
  },
  // step 4
  terms_agree: {
    rules: [
      { required: true },
      {
        validator: requiredRule,
      },
    ],
    initialValue: 'false',
  },
  privacy_policy_accepted: {
    rules: [
      { required: true },
      {
        validator: requiredRule,
      },
    ],
    initialValue: 'false',
  },
  radiobtn: {
    rules: [
      { required: true },
      {
        validator: requiredRule,
      },
    ],
    initialValue: '',
  },
});

export default makeRegistrationStepConfig;

export interface IBonus {
  amount: string;
  fulfilled: string;
  can_be_confirmed: number;
  winnings: string;
  rollover_x: string;
  is_confirmed: number;
  remaining: string;
  balance: string;
  rollover: string;
  created: string;
  is_cancelled: number;
  can_be_cancelled: number;
  status: IBonusExpiredStatusEnum | IBonusSectionTabsEnum.ACTIVE;
  confirmed: string;
  type_label: string;
  cancelled: string;
  id: string;
  is_potential: number;
  expires: string;
  min_odds: string;
  transferred: string;
}

export enum IBonusSectionTabsEnum {
  ACTIVE = 'active',
  EXPIRED = 'expired',
}

export enum IBonusExpiredStatusEnum {
  EXPIRED = 'expired',
  CANCELLED = 'cancelled',
  TRANSFERRED = 'transferred',
  CONSUMED = 'consumed',
}

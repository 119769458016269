import styled from 'styled-components';

import { Icon } from '@ui/components/icon';

export const IconsWrapper = styled.div`
  display: flex;
  align-items: center;
`;

export const EuroIcon = styled.span<{ iconColor: string }>`
  font-size: ${({ theme }) => theme.fontLG};
  font-weight: ${({ theme }) => theme.fontRegular};
  color: ${({ iconColor }) => iconColor};
`;

export const TransactionIcon = styled(Icon)<{
  iconColor: string;
  isTicketIcon: boolean;
}>`
  &.mi-ticket {
    stroke-width: 2;

    & path {
      stroke: ${({ iconColor, isTicketIcon }) => !isTicketIcon && iconColor};
      fill: ${({ isTicketIcon, iconColor }) =>
        isTicketIcon ? iconColor : 'none'};
      stroke-linecap: round;
      stroke-linejoin: round;
    }
  }

  &:not(.mi-ticket) {
    color: ${({ iconColor }) => iconColor};
  }

  &.mi-down-arrow {
    position: absolute;
    left: 14px;
    margin-top: ${({ theme }) => theme.space_5};
  }

  &.mi-up-arrow {
    position: absolute;
    left: 14px;
    margin-top: -5px;
  }
`;

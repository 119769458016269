import { IProfileInfo } from '@common/interfaces';

const defaultState = {
  profile: {
    loading: false,
    success: true,
    errors: [],
    info: {} as IProfileInfo,
  },
  uploadDocument: {
    loading: false,
    error: false,
    success: false,
  },
};

export default defaultState;

import { createWithEqualityFn } from 'zustand/traditional';
import { shallow } from 'zustand/shallow';

import { ICustomError, IGame, ISearchCategories } from '@common/interfaces';
import defaultState from '@common/providers/search/state';
import { ISearchEventsSuccess } from '@common/providers/search/types';

export const useSearchState = createWithEqualityFn(() => defaultState, shallow);

export const setSearchDefineLoading = (defineState: string): void => {
  const defineStateLoading = `${defineState}Loading`;
  useSearchState.setState(() => ({
    [defineStateLoading]: true,
  }));
};

export const searchEventSuccess = (result: ISearchEventsSuccess): void => {
  useSearchState.setState(() => ({
    eventsLoading: false,
    events: result.events,
    eventCategories: result.eventCategories,
  }));
};

export const setSearchDefineError = (
  defineState: string,
  error: ICustomError,
): void => {
  const defineStateLoading = `${defineState}Loading`;
  useSearchState.setState(() => ({
    [defineStateLoading]: false,
    error,
  }));
};

export const searchCategoriesSuccess = (
  categories: ISearchCategories,
): void => {
  useSearchState.setState(() => ({
    categoriesLoading: false,
    categories,
  }));
};

export const searchCasinoSuccess = (casinoGames: IGame[]): void => {
  useSearchState.setState(() => ({
    categoriesLoading: false,
    casinoGames,
  }));
};

export const resetSearchResult = (): void => {
  useSearchState.setState(defaultState);
};

export const setSearchStatus = (searchStatus: boolean): void => {
  useSearchState.setState(() => ({
    categoriesLoading: false,
    searchStatus,
  }));
};

import { useEffect } from 'react';

const useOnClickOutside = (ref, handler, isOnClick?): void => {
  const listener = (event): void | undefined => {
    if (!ref.current || ref.current.contains(event.target)) {
      return;
    }
    handler(event);
  };

  useEffect((): void => {
    if (isOnClick) {
      document.addEventListener('click', listener, { passive: false });
    } else {
      document.addEventListener('mousedown', listener, { passive: false });
      document.addEventListener('touchstart', listener, { passive: false });
    }
  }, [ref, handler]);

  useEffect(() => {
    return () => {
      if (isOnClick) {
        document.removeEventListener('click', listener);
      }
      document.removeEventListener('mousedown', listener);
      document.removeEventListener('touchstart', listener);
    };
  }, []);
};

export default useOnClickOutside;

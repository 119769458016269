/**
 * Hook to return previous path
 * @example
 * const location = useLocation();
 * const prevPath = usePreviousPath(location);
 * @param location: Location
 */

import { useEffect, useState } from 'react';
import { Location } from 'history';

const usePreviousPath = (location: Location): string => {
  const [currentPath, setCurrentPath] = useState('');
  const [previousPath, setPreviousPath] = useState('');

  useEffect(() => {
    if (location.pathname !== currentPath) {
      setPreviousPath(currentPath);
      setCurrentPath(location.pathname);
    }
  }, [location.pathname]);

  return previousPath;
};

export default usePreviousPath;

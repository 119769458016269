import find from 'lodash/find';

import services from '@features/core/services';

import { HAS_ACCEPTED_GLOBAL } from '@common/constants/config';
import {
  HAS_ACCEPTED_GLOBAL_LIMITS,
  LUGAS_TIMER,
} from '@common/constants/cookie';
import fetchLimit from '@common/api/limits/fetchLimit';
import { MethodType } from '@common/api/apiHandler';
import fetchPocketTags from '@common/api/account/fetchPocketTags';
import hasLugasRestriction from '@common/helpers/userHelper/hasLugasRestrictions';

const authTags = async (
  paymentAccount,
): Promise<{
  depositLayer;
  monthlyLimit;
  acceptedLimits;
  hasBoostedLimit;
  lugasActivityTimer;
}> => {
  const depositLayer = await fetchPocketTags({
    method: MethodType.Get,
    params: {
      tag: { key: 'usr.frontend.shoponline_depositlimit_layer_seen' },
    },
  });

  const limits = await fetchLimit({
    token: paymentAccount.account.token,
    accountId: paymentAccount.account.id,
    limitType: 'sys.deposit.self_limit',
  });

  const monthlyLimit = find(limits, [
    'data.attribute',
    'sys.deposit.self_limit.monthly',
  ]);

  const acceptedLimits =
    services.config.get(HAS_ACCEPTED_GLOBAL) &&
    !services.cookie.get(HAS_ACCEPTED_GLOBAL_LIMITS)
      ? await fetchPocketTags({
          method: MethodType.Get,
          params: { tag: { key: 'sys.deposit.global_limit.monthly.status' } },
        })
      : undefined;

  const hasBoostedLimit =
    acceptedLimits?.tag?.value === 'pending'
      ? await fetchPocketTags({
          method: MethodType.Get,
          params: { tag: { key: 'sys.income.boost.status' } },
        })
      : undefined;

  const lugasActivityTimer =
    hasLugasRestriction() && !services.cookie.get(LUGAS_TIMER)
      ? await fetchPocketTags({
          method: MethodType.Get,
          params: { tag: { key: 'usr.front.activitystart' } },
        })
      : undefined;
  return {
    depositLayer,
    monthlyLimit,
    acceptedLimits,
    hasBoostedLimit,
    lugasActivityTimer,
  };
};

export default authTags;

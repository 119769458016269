import React, { useEffect, useState, useRef } from 'react';
import { intervalToDuration } from 'date-fns';
import padStart from 'lodash/padStart';
import { useTranslation } from 'react-i18next';

import { useServices } from '@features/core/services';

import { LUGAS_TIMER } from '@common/constants/cookie';
import { TTimeout } from '@common/interfaces';
import deleteTimeout from '@common/helpers/timeoutHelper';
import hasLugasRestriction from '@common/helpers/userHelper/hasLugasRestrictions';
import { useUserState } from '@common/providers/user/useUserState';

import LugasWrapper from './LugasTimer.styled';

const LugasTimer = ({
  type = 'activity',
}: {
  type: 'activity' | 'reality';
}): React.ReactElement | null => {
  const { t } = useTranslation();
  const { cookie } = useServices();
  const isLoggedIn = useUserState(s => s.loadingState);
  const [remainingTime, setRemainingTime] = useState<string>('');
  const intervalRef = useRef<TTimeout>(null);

  const formRemaining = (timer): string => {
    return `${padStart(
      String(timer.minutes + timer.hours * 60),
      2,
      '0',
    )}:${padStart(String(timer.seconds), 2, '0')}`;
  };

  const checkActivity = (value: number): void => {
    if (!value) {
      setRemainingTime('');
    } else {
      const activityDuration = intervalToDuration({
        start: value,
        end: Date.now(),
      });
      setRemainingTime(formRemaining(activityDuration));
    }
  };

  useEffect(() => {
    const value = Number(cookie.get(LUGAS_TIMER));
    checkActivity(value);
    intervalRef.current = setInterval(() => {
      const updatedValue = Number(cookie.get(LUGAS_TIMER));
      checkActivity(updatedValue);
    }, 1000);

    return () => {
      deleteTimeout(intervalRef.current);
    };
  }, []);

  const renderTimer = (): string => {
    if (remainingTime && hasLugasRestriction() && isLoggedIn) {
      return t('app-layout.menu.lugasTimer', {
        time: remainingTime,
      });
    }
    return '';
  };

  return (
    <LugasWrapper
      timerType={type}
      dangerouslySetInnerHTML={{
        __html: renderTimer() as string,
      }}
    />
  );
};

export default LugasTimer;

import { RuleObject } from 'rc-field-form/es/interface';

const notOnlyNumbers = async (
  rule: RuleObject,
  value: string,
): Promise<void> => {
  // eslint-disable-next-line
  const pattern = /^[0-9]+$/;
  if (pattern.test(value)) {
    return Promise.reject(rule.message);
  }
  return Promise.resolve();
};

export default notOnlyNumbers;

import React from 'react';
import map from 'lodash/map';

import { EventListTypes } from '@common/interfaces';

import * as S from './DefaultTimerView.styled';
import { Props } from './DefaultTimerView.types';

export const DefaultTimerView = (props: Props): React.ReactElement => {
  const { rows, listType, className } = props;
  return (
    <S.Timer
      data-qa="timer"
      isZeroMargin={listType === EventListTypes.detail}
      className={className || ''}
    >
      {map(rows, item => (
        <S.TimerRow key={item}>{item}</S.TimerRow>
      ))}
    </S.Timer>
  );
};

export default DefaultTimerView;

import { useEffect } from 'react';
import throttle from 'lodash/throttle';

import services from '@features/core/services';
import { PageName } from '@features/core/routing';

import {
  TOKEN,
  IN_ACTIVITY_TIMER,
  REALITY_TIMER,
} from '@common/constants/cookie';
import { REALITY_LAYER } from '@common/constants/dialogs';
import { REALITY_PERIOD } from '@common/constants/config';
import logoutUser from '@common/providers/user/actions/logoutUser';
import hasLugasRestriction from '@common/helpers/userHelper/hasLugasRestrictions';
import { setLocation } from '@common/providers/router/helper';
import { openSidebar } from '@common/providers/application/useAppState';
import { isWebView } from '@common/helpers/links';

export const IN_ACTIVITY_PERIOD = 30 * 60 * 1000;
export const realityPeriod = services.config.get(REALITY_PERIOD) as number;

const useLugasActiveTimer = (): void => {
  let interval;

  useEffect(() => {
    document.addEventListener(
      'click',
      throttle(() => {
        if (services.cookie.get(TOKEN)) {
          services.cookie.set(IN_ACTIVITY_TIMER, String(Date.now()), {
            expires: null,
          });
        } else {
          services.cookie.remove(IN_ACTIVITY_TIMER);
        }
      }, 10 * 1000),
      true,
    );
    interval = setInterval(async () => {
      if (hasLugasRestriction()) {
        const reality = Number(services.cookie.get(REALITY_TIMER));
        const inactivity = Number(services.cookie.get(IN_ACTIVITY_TIMER));

        const current = Date.now();
        if (inactivity > 0 && inactivity + IN_ACTIVITY_PERIOD < current) {
          if (!isWebView(window.location.search)) {
            await logoutUser();
            setLocation(PageName.HOME);
          }
        } else if (reality > 0 && reality + realityPeriod < current) {
          openSidebar({ type: REALITY_LAYER });
        }
      }
    }, 1000);
    return () => {
      clearInterval(interval);
    };
  }, []);
};

export default useLugasActiveTimer;

export enum MarketType {
  'N-WAY' = 'N-WAY', // this one is artificial type, we mark 2-3 ways with n-way
  '3WAY' = '3WAY',
  '3WAY_LIVE' = '3WAY_LIVE',
  'BET_WON_WINNER' = 'BET_WON_WINNER',
  '2WAY' = '2WAY',
  'TOP_2' = 'TOP_2',
  'TOP_3' = 'TOP_3',
  'TOP_4' = 'TOP_4',
  'TOP2' = 'TOP2',
  'TOP3' = 'TOP3',
  'TOP4' = 'TOP4',
  'REST_OF_MATCH' = 'REST_OF_MATCH',
  'WHO_WINS_IN_LEG_FIRST_LEG' = 'WHO_WINS_IN_LEG_FIRST_LEG',
  'OVER_UNDER' = 'OVER_UNDER',
  'OVER_UNDER_LIVE' = 'OVER_UNDER_LIVE',
  'OVER_UNDER_OT' = 'OVER_UNDER_OT',
  'OVER_UNDER_GAMES_LIVE_0.5' = 'OVER_UNDER_GAMES_LIVE_0.5',
  'OVER_UNDER_GAMES' = 'OVER_UNDER_GAMES',
  'OVER_UNDER_THIRD' = 'OVER_UNDER_THIRD', // check if exists
  'OVER_UNDER_QUARTER' = 'OVER_UNDER_QUARTER', // check if exists
  'OVER_UNDER_1_5' = 'OVER_UNDER_1_5', // check if exists
  'OVER_UNDER_3_5' = 'OVER_UNDER_3_5', // check if exists
  'OVER_UNDER_4_5' = 'OVER_UNDER_4_5', // check if exists
  'NEXT_FREE_KICK' = 'NEXT_FREE_KICK', // check if exists
  'NEXT_THROW_IN' = 'NEXT_THROW_IN', // check if exists
  'LAST_TEAM_TO_SCORE' = 'LAST_TEAM_TO_SCORE', // check if exists
  'NUMBER_OF_GOALS' = 'NUMBER_OF_GOALS', // check if exists
  'GAME_CORRECT_SCORE' = 'GAME_CORRECT_SCORE', // check if exists
  'CORRECT_7SET_SCORE' = 'CORRECT_7SET_SCORE', // check if exists
  'PERIOD_THIRD' = 'PERIOD_THIRD', // check if exists
  'PERIOD_QUARTER' = 'PERIOD_QUARTER', // check if exists
  'TOTAL_SPREADS_LIVE' = 'TOTAL_SPREADS_LIVE', // check if exists
  'OVER_UNDER_2' = 'OVER_UNDER_2', // check if exists
  'OVER_UNDER_GAMES_LIVE_05' = 'OVER_UNDER_GAMES_LIVE_05', // check if exists
  'TWO_WAY_INCL_OT_PEN' = 'TWO_WAY_INCL_OT_PEN', // check if exists
  'HANDICAP_HT' = 'HANDICAP_HT', // check if exists
  'OVER_UNDER_INNING' = 'OVER_UNDER_INNING', // check if exists
  'THREE_WAY_PERIOD' = 'THREE_WAY_PERIOD',
  'TOTAL_SETS' = 'TOTAL_SETS',
  'TOTAL_SPREADS' = 'TOTAL_SPREADS',
  'XTH_GOAL' = 'XTH_GOAL',
  'NEXT_POINTS_TEAM' = 'NEXT_POINTS_TEAM',
  'HANDICAP' = 'HANDICAP',
  'HANDICAP_2WAY' = 'HANDICAP_2WAY',
  'EUROPEAN_HANDICAP' = 'EUROPEAN_HANDICAP',
  'HANDICAP_LIVE' = 'HANDICAP_LIVE',
  'POINTS_SPREADS_LIVE' = 'POINTS_SPREADS_LIVE',
  'POINTS_SPREADS_SETS' = 'POINTS_SPREADS_SETS',
  'SET_HANDICAP_LIVE' = 'SET_HANDICAP_LIVE',
  'GOAL_SPREADS' = 'GOAL_SPREADS',
  'POINTS_SPREADS' = 'POINTS_SPREADS',
  'POINTS_SPREADS_AM_FOOTBALL' = 'POINTS_SPREADS_AM_FOOTBALL',
  'POINT_SPREADS_LIVE' = 'POINT_SPREADS_LIVE',
  'WINNING_MARGINS' = 'WINNING_MARGINS',
  'TO_QUALIFY' = 'TO_QUALIFY',
  'TO_QUALIFY_LIVE' = 'TO_QUALIFY_LIVE',
  'TO_WIN_FINAL_LIVE' = 'TO_WIN_FINAL_LIVE',
  'TO_WIN_BRONZE_LIVE' = 'TO_WIN_BRONZE_LIVE',
  'DOUBLE_CHANCE' = 'DOUBLE_CHANCE',
  'DOUBLE_CHANCE_LIVE' = 'DOUBLE_CHANCE_LIVE',
  'GOAL_NO_GOAL' = 'GOAL_NO_GOAL',
  'GOAL_NO_GOAL_LIVE' = 'GOAL_NO_GOAL_LIVE',
  'DRAW_NO_BET' = 'DRAW_NO_BET',
  'DRAW_NO_BET_LIVE' = 'DRAW_NO_BET_LIVE',
  'FIRST_HALFTIME' = 'FIRST_HALFTIME',
  'HALFTIME' = 'HALFTIME',
  'REST_OF_MATCH_HT' = 'REST_OF_MATCH_HT',
  'FIRST_PERIOD' = 'FIRST_PERIOD',
  'OVER_UNDER_FIRST_HALF' = 'OVER_UNDER_FIRST_HALF',
  'OVER_UNDER_HT' = 'OVER_UNDER_HT',
  'FIRST_TEAM_TO_SCORE_HT' = 'FIRST_TEAM_TO_SCORE_HT',
  'XTH_GOAL_HT' = 'XTH_GOAL_HT',
  'NEXT_POINTS_TEAM_HT' = 'NEXT_POINTS_TEAM_HT',
  'EUROPEAN_HANDICAP_HT' = 'EUROPEAN_HANDICAP_HT',
  'HANDICAP_HT_LIVE' = 'HANDICAP_HT_LIVE',
  'DOUBLE_CHANCE_HT' = 'DOUBLE_CHANCE_HT',
  'GOAL_NO_GOAL_HT' = 'GOAL_NO_GOAL_HT',
  'GOAL_NO_GOAL_HT_LIVE' = 'GOAL_NO_GOAL_HT_LIVE',
  'DRAW_NO_BET_HT' = 'DRAW_NO_BET_HT',
  'DRAW_NO_BET_HT_LIVE' = 'DRAW_NO_BET_HT_LIVE',
  'INTERVAL_FIFTEEN_LIVE' = 'INTERVAL_FIFTEEN_LIVE',
  'INTERVAL_FIFTEEN_TOTAL_LIVE' = 'INTERVAL_FIFTEEN_TOTAL_LIVE',
  'PERIOD' = 'PERIOD',
  'PERIOD_BASKETBALL' = 'PERIOD_BASKETBALL',
  'OVER_UNDER_FIRST_PERIOD' = 'OVER_UNDER_FIRST_PERIOD',
  'OVER_UNDER_LIVE_HT' = 'OVER_UNDER_LIVE_HT',
  'GOALSCORER' = 'GOALSCORER',
  'GOALSCORER_HOME_TEAM' = 'GOALSCORER_HOME_TEAM',
  'GOALSCORER_AWAY_TEAM' = 'GOALSCORER_AWAY_TEAM',
  'FIRST_TEAM_TO_SCORE' = 'FIRST_TEAM_TO_SCORE',
  'TOTAL_GOALS_AGGREGATED' = 'TOTAL_GOALS_AGGREGATED',
  'EXACT_NUMBER_OF_GOALS' = 'EXACT_NUMBER_OF_GOALS',
  'EXACT_NUMBER_OF_GOALS_HT_PM' = 'EXACT_NUMBER_OF_GOALS_HT_PM',
  'EXACT_NUMBER_OF_GOALS_HT' = 'EXACT_NUMBER_OF_GOALS_HT',
  'GOALS_HOME_TEAM' = 'GOALS_HOME_TEAM',
  'GOALS_HOME_TEAM_LIVE' = 'GOALS_HOME_TEAM_LIVE',
  'GOALS_AWAY_TEAM' = 'GOALS_AWAY_TEAM',
  'GOALS_AWAY_TEAM_LIVE' = 'GOALS_AWAY_TEAM_LIVE',
  'TOTALS_HOME_TEAM' = 'TOTALS_HOME_TEAM',
  'TOTALS_HOME_TEAM_LIVE' = 'TOTALS_HOME_TEAM_LIVE',
  'TOTALS_HOME_TEAM_LIVE_INCL_OT' = 'TOTALS_HOME_TEAM_LIVE_INCL_OT',
  'TOTALS_AWAY_TEAM' = 'TOTALS_AWAY_TEAM',
  'TOTALS_AWAY_TEAM_LIVE' = 'TOTALS_AWAY_TEAM_LIVE',
  'TOTALS_AWAY_TEAM_LIVE_INCL_OT' = 'TOTALS_AWAY_TEAM_LIVE_INCL_OT',
  'TOTALS_HOME_TEAM_HT' = 'TOTALS_HOME_TEAM_HT',
  'TOTALS_AWAY_TEAM_HT' = 'TOTALS_AWAY_TEAM_HT',
  'CORRECT_SCORE' = 'CORRECT_SCORE',
  'CORRECT_SCORE_LIVE' = 'CORRECT_SCORE_LIVE',
  'HALFTIME_FULLTIME' = 'HALFTIME_FULLTIME',
  'WINNING_METHOD' = 'WINNING_METHOD',
  'HIGHEST_SCORING_HALF' = 'HIGHEST_SCORING_HALF',
  'FIRST_TEN_MINUTES' = 'FIRST_TEN_MINUTES',
  'WINNER' = 'WINNER',
  'WINNER_CHAMPIONSHIP' = 'WINNER_CHAMPIONSHIP',
  'WINNER_EVENT' = 'WINNER_EVENT',
  '3WAY_OVER_UNDER' = '3WAY_OVER_UNDER',
  '3WAY_OVER_UNDER_LIVE' = '3WAY_OVER_UNDER_LIVE',
  'THREE_WAY_GOAL_NO_GOAL' = 'THREE_WAY_GOAL_NO_GOAL',
  'DOUBLE_CHANCE_OVER_UNDER' = 'DOUBLE_CHANCE_OVER_UNDER',
  'DOUBLE_CHANCE_GOAL_NO_GOAL' = 'DOUBLE_CHANCE_GOAL_NO_GOAL',
  'THREE_WAY_PERIOD_2' = 'THREE_WAY_PERIOD_2',
  'GOAL_NO_GOAL_HT_2' = 'GOAL_NO_GOAL_HT_2',
  'NEXT_POINTS_KIND' = 'NEXT_POINTS_KIND',
  'OVERTIME' = 'OVERTIME',
  'OVERTIME_YES_NO' = 'OVERTIME_YES_NO',
  'OVERTIME_YES_NO_LIVE' = 'OVERTIME_YES_NO_LIVE',
  'ODD_EVEN_GOALS' = 'ODD_EVEN_GOALS',
  'NEXT_CORNER' = 'NEXT_CORNER',
  'FIRST_CORNER' = 'FIRST_CORNER',
  'CORNER_BET' = 'CORNER_BET',
  'SET' = 'SET',
  'FIRST_SET' = 'FIRST_SET',
  'SET_1' = 'SET_1',
  'OVER_UNDER_GAMES_LIVE_1' = 'OVER_UNDER_GAMES_LIVE_1',
  'GAMES_OF_SET' = 'GAMES_OF_SET',
  'GAMES_OF_SET_1' = 'GAMES_OF_SET_1',
  'SECOND_SET' = 'SECOND_SET',
  'SET_2' = 'SET_2',
  'OVER_UNDER_GAMES_LIVE_2' = 'OVER_UNDER_GAMES_LIVE_2',
  'GAMES_OF_SET_2' = 'GAMES_OF_SET_2',
  'SET_3' = 'SET_3',
  'OVER_UNDER_GAMES_LIVE_3' = 'OVER_UNDER_GAMES_LIVE_3',
  'GAMES_OF_SET_3' = 'GAMES_OF_SET_3',
  'SET_4' = 'SET_4',
  'OVER_UNDER_GAMES_LIVE_4' = 'OVER_UNDER_GAMES_LIVE_4',
  'GAMES_OF_SET_4' = 'GAMES_OF_SET_4',
  'SET_5' = 'SET_5',
  'OVER_UNDER_GAMES_LIVE_5' = 'OVER_UNDER_GAMES_LIVE_5',
  'GAMES_OF_SET_5' = 'GAMES_OF_SET_5',
  'SET_6' = 'SET_6',
  'OVER_UNDER_GAMES_LIVE_6' = 'OVER_UNDER_GAMES_LIVE_6',
  'GAMES_OF_SET_6' = 'GAMES_OF_SET_6',
  'WHICH_WINS_X_POINTS' = 'WHICH_WINS_X_POINTS',
  'HANDICAP_GAMES_LIVE' = 'HANDICAP_GAMES_LIVE',
  'OVER_UNDER_GAMES_LIVE' = 'OVER_UNDER_GAMES_LIVE',
  'CORRECT_3SET_SCORE' = 'CORRECT_3SET_SCORE',
  'CORRECT_3SET_SCORE_LIVE' = 'CORRECT_3SET_SCORE_LIVE',
  'CORRECT_5SET_SCORE' = 'CORRECT_5SET_SCORE',
  'CORRECT_5SET_SCORE_LIVE' = 'CORRECT_5SET_SCORE_LIVE',
  'TOTAL_SETS_BEST_OF_3' = 'TOTAL_SETS_BEST_OF_3',
  'TOTAL_SETS_BEST_OF_3_LIVE' = 'TOTAL_SETS_BEST_OF_3_LIVE',
  'TOTAL_SETS_BEST_OF_5' = 'TOTAL_SETS_BEST_OF_5',
  'TOTAL_SETS_BEST_OF_5_LIVE' = 'TOTAL_SETS_BEST_OF_5_LIVE',
  'ODD_EVEN' = 'ODD_EVEN',
  'TOTAL_LEGS' = 'TOTAL_LEGS',
  'WHO_WINS_IN_LEG' = 'WHO_WINS_IN_LEG',
  'CORRECT_SCORE_SETS' = 'CORRECT_SCORE_SETS',
  'CORRECT_SCORE_LEGS_SET' = 'CORRECT_SCORE_LEGS_SET',
  'MOST_180S' = 'MOST_180S',
  'ANY_PLAYER_SCORE_180' = 'ANY_PLAYER_SCORE_180',
  'PLAYER1_SCORE_180' = 'PLAYER1_SCORE_180',
  'PLAYER2_SCORE_180' = 'PLAYER2_SCORE_180',
  'TOTAL_180S_LIVE' = 'TOTAL_180S_LIVE',
  'TOTAL_180S_LIVE_PLAYER1' = 'TOTAL_180S_LIVE_PLAYER1',
  'TOTAL_180S_LIVE_PLAYER2' = 'TOTAL_180S_LIVE_PLAYER2',
  'CHECKOUT_SCORE_MORE_40_IN_LEG' = 'CHECKOUT_SCORE_MORE_40_IN_LEG',
  'CHECKOUT_COLOR_IN_LEG' = 'CHECKOUT_COLOR_IN_LEG',
  'PENALTY_SHOOTOUT' = 'PENALTY_SHOOTOUT',
}

export type IMarketType = keyof typeof MarketType;

import includes from 'lodash/includes';
import toLower from 'lodash/toLower';
import split from 'lodash/split';

import services from '@features/core/services';

import { TEST_USERS } from '@common/constants/config';
import { useUserState } from '@common/providers/user/useUserState';

/**
 * isTestUser
 * checks if user is test user;
 *
 * @returns {boolean} isTestUser
 */
export default (): boolean => {
  const testUsers = services.config.get(TEST_USERS) as string;

  const user = useUserState.getState().data;

  return user?.username
    ? includes(split(testUsers, '|'), toLower(user?.username))
    : false;
};

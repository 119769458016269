import services from '@features/core/services';

import { DynamicCalls } from '@common/api/api';
import { apiHandler, MethodType } from '@common/api/apiHandler';
import {
  ICustomError,
  IDuplicatedCustomerParams,
  IDuplicatedCustomerResponse,
} from '@common/interfaces';

const { API_DUPLICATE_CUSTOMERS } = DynamicCalls;

const checkDuplicatedCustomer = (
  data: IDuplicatedCustomerParams,
): Promise<IDuplicatedCustomerResponse & ICustomError> => {
  const { firstname, lastname, birthday: { day, month, year } = {} } = data;
  const checkMonth = data.birthday.month < 10 ? `0${month}` : month;
  const checkday = data.birthday.day < 10 ? `0${day}` : day;

  return apiHandler<IDuplicatedCustomerResponse>(
    {
      method: MethodType.Get,
      params: {
        firstname,
        lastname,
        birthday: `${year}-${checkMonth}-${checkday}`,
      },
    },
    {
      apiUrl: API_DUPLICATE_CUSTOMERS,
      apiData: {
        dynamicLanguage: services.domainLang,
      },
    },
  );
};

export default checkDuplicatedCustomer;

import styled from 'styled-components';

import { TextNormal } from '@features/core/styles/text.styled';

import { Button } from '@ui/components/buttons';
import { INotificationStyle } from '@ui/components/genericNotification/GenericNotification.types';

const additionalTextStyles = (isResultSuccess: boolean, theme): string => `
b, span {
  color: ${isResultSuccess ? theme.statusSuccess : theme.statusError}
};
`;

const getBonusNotifierStyle = (): string => `
margin-bottom: 0;

& > div {
  display: grid;
  grid-template-columns: auto 1fr;
  align-items: center;
  margin-bottom: 0;
}


& svg {
  align-self: flex-start;
  width: 20px;
  height: 20px;
}
`;

const getRowStyle = (styleType: keyof typeof INotificationStyle) => {
  switch (styleType) {
    case INotificationStyle.noFavoriteNotification:
      return `
        justify-content: center;
          margin-bottom: 0;   
      `;
    case INotificationStyle.footerDynamicNotification:
      return `
        justify-content: center;  
        margin-bottom: 0;  
        height: 24px     
      `;
    case INotificationStyle.detailEventNotification:
      return `
        justify-content: left;
        margin-bottom: 0;           
      `;
    case INotificationStyle.forgotPasswordNotification:
      return `
        justify-content: start;
          margin-bottom: 0;         
      `;
    case INotificationStyle.bonusSectionNotification:
      return `
        justify-content: left;
        margin-bottom: 0;
      `;
    case INotificationStyle.bonusNotifierNotification:
      return getBonusNotifierStyle();
    case INotificationStyle.selfExclusion:
    case INotificationStyle.lugasHigherLimitInfo:
      return `
        margin-bottom: 0;
      `;
    default:
      return '';
  }
};

const getMessageMargin = theme => {
  return theme.isDesktop ? `${theme.space_25} 0;` : `${theme.space_20} 0;`;
};
const getMessageStyle = (styleType: keyof typeof INotificationStyle, theme) => {
  switch (styleType) {
    case INotificationStyle.noFavoriteNotification:
      return `
        padding: 0 5px;        
      `;
    case INotificationStyle.footerDynamicNotification:
      return `
        padding: ${theme.space_5};
      `;
    case INotificationStyle.registrationSingleNotification:
    case INotificationStyle.paymentFormNotification:
    case INotificationStyle.limitLugasNotification:
    case INotificationStyle.documentUploadNotification:
    case INotificationStyle.registrationFormNotification:
      return `
        margin: ${getMessageMargin(theme)};
      `;
    case INotificationStyle.paymentApiNotification:
      return `
          margin: ${theme.space_25} 0 ${theme.space_40};
        `;
    default:
      return '';
  }
};

export const Row = styled.div<{
  isIcon: boolean;
  styleType?: keyof typeof INotificationStyle;
}>`
  display: grid;
  grid-auto-flow: column;
  align-items: center;
  justify-content: space-between;
  margin-bottom: ${({ theme }) => theme.space_5};
  grid-gap: ${({ theme, isIcon }) => isIcon && theme.space_10};

  svg {
    align-self: start;
  }
  ${({ styleType }) => styleType && getRowStyle(styleType)};
`;

export const Message = styled.div<{
  styleType?: keyof typeof INotificationStyle;
}>`
  background-color: ${({ theme }) => theme.textMain};
  margin: 0;
  height: fit-content;
  padding: ${({ theme }) => theme.space_10};
  ${({ styleType, theme }) => styleType && getMessageStyle(styleType, theme)};
`;

const severalChildrenStyles = ({ theme }) => `
      display: flex;
      
      & svg {
        margin-right: ${theme.space_10};
        }
`;

export const NotificationText = styled.div<{
  type?: string;
  severalChildren?: boolean;
  isAdditional?: boolean;
  styleType?: keyof typeof INotificationStyle;
}>`
  width: 100%;
  color: ${({ theme }) => theme.main};
  margin-top: 0;
  margin-bottom: 0;
  text-align: left;
  ${({ severalChildren }) => severalChildren && severalChildrenStyles};

  a {
    text-decoration: underline;
  }
  ${({ styleType, type, theme }) =>
    styleType === INotificationStyle.paymentScreenNotification &&
    additionalTextStyles(type === 'success', theme)};
`;

export const Content = styled.div<{ iconType?: string }>`
  color: ${({ theme, iconType = '' }) => theme[iconType] ?? theme.textActive};
`;

export const ContentText = styled(TextNormal)<{
  styleType?: keyof typeof INotificationStyle;
  type?: string;
}>`
  margin: 0 0 0
    ${({ theme, styleType }) =>
      styleType === INotificationStyle.depositLimitNotification ||
      styleType === INotificationStyle.shopWalletNotification ||
      styleType === INotificationStyle.forgotPasswordNotification ||
      styleType === INotificationStyle.selfExclusion ||
      styleType === INotificationStyle.lugasHigherLimitInfo
        ? '0'
        : theme.space_10};

  ${({ styleType, type, theme }) =>
    (styleType === INotificationStyle.forgotPasswordNotification ||
      styleType === INotificationStyle.selfExclusion) &&
    additionalTextStyles(type === 'success', theme)};
`;

export const Title = styled.span`
  font-weight: ${({ theme }) => theme.fontBold};
`;

export const ButtonClose = styled(Button)`
  background: transparent;
  padding: 0;
  width: 20px;
  margin-left: auto;

  &:hover {
    background-color: transparent;
  }
`;

export const CloseHandleWrap = styled.div`
  margin-top: ${({ theme }) => theme.space_20};
`;

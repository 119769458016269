import React, { createContext, useContext, useEffect, useState } from 'react';
import { DefaultTheme } from 'styled-components';
import noop from 'lodash/noop';
import keys from 'lodash/keys';
import reduce from 'lodash/reduce';
import assign from 'lodash/assign';
import forEach from 'lodash/forEach';
import { Helmet } from 'react-helmet';

import services from '@features/core/services';

import Cookie from '@packages/cookie';

import {
  APP_THEME,
  APP_THEME_COLORS,
  APP_FONT,
} from '@common/constants/cookie';
import { THEME, IS_EMBEDDED_SOLUTION } from '@common/constants/config';

export const SPORTWETTEN_THEME_NAME = 'Sportwetten-blue';

const cookie = new Cookie();

const getThemeName = (): string => {
  const urlParams = new URLSearchParams(window.location.search);
  const themeFromUrl = urlParams.get('theme');
  const themeFromConfig = services.config.get(THEME) as string;
  const themeFromCookie = cookie.get(APP_THEME);

  if (services.config.get(IS_EMBEDDED_SOLUTION)) {
    if (themeFromUrl) {
      cookie.set(APP_THEME, themeFromUrl);
    }
    return (
      themeFromUrl ||
      themeFromCookie ||
      themeFromConfig ||
      SPORTWETTEN_THEME_NAME
    );
  }

  return themeFromConfig || SPORTWETTEN_THEME_NAME;
};

export const getFontFamily = (): string | null => {
  const urlParams = new URLSearchParams(window.location.search);
  const fontFromUrl = decodeURIComponent(urlParams.get('font') || '');
  const fontFromCookie = cookie.get(APP_FONT);

  if (services.config.get(IS_EMBEDDED_SOLUTION)) {
    if (fontFromUrl) {
      cookie.set(APP_THEME, fontFromUrl);
    }
    return fontFromUrl || fontFromCookie;
  }
  return null;
};

const defaultThemeName = getThemeName();

let selectedSchema;
let Logo;
let themeProps;
/* eslint-disable global-require,import/no-dynamic-require,@typescript-eslint/no-var-requires */
try {
  selectedSchema = require(`@features/core/theming/colorSchema-${defaultThemeName}`);
} catch (e) {
  selectedSchema = require(`@features/core/theming/colorSchema-${SPORTWETTEN_THEME_NAME}`);
}
try {
  themeProps = require(`@features/core/theming/themeProps-${defaultThemeName}`)
    .default;
} catch (e) {
  themeProps = require('@features/core/theming/themeProps-base').default;
}
try {
  Logo = require(`@features/app/layout/logos/${defaultThemeName}`).default;
} catch (e) {
  Logo = require(`@features/app/layout/logos/${SPORTWETTEN_THEME_NAME}`)
    .default;
}
/* eslint-enable global-require,import/no-dynamic-require,@typescript-eslint/no-var-requires */

export const colorSchema = selectedSchema;

const getFonts = (isStoryBook = false): JSX.Element | null => {
  if (isStoryBook) {
    return (
      <Helmet>
        <link rel="preload" as="style" href="fonts/roboto2024.css" />
        <link rel="stylesheet" href="fonts/roboto2024.css" />
      </Helmet>
    );
  }
  if (getFontFamily()) {
    return (
      <Helmet>
        <link
          rel="stylesheet"
          href={`https://fonts.googleapis.com/css?family=${getFontFamily()}`}
        />
        <style>
          {`
              * {
                font-family: '${getFontFamily()}', sans-serif;
              }
           `}
        </style>
      </Helmet>
    );
  }
  return null;
};

export const HeaderLogo = Logo;

export const getTheme = (): DefaultTheme => {
  if (services.config.get(IS_EMBEDDED_SOLUTION)) {
    const urlParams = new URLSearchParams(window.location.search);
    let colors = {};
    try {
      const savedColors = cookie.get(APP_THEME_COLORS);
      if (savedColors) {
        colors = JSON.parse(savedColors);
      }
    } catch (e) {
      services.logger?.log(`Not able to parse colors`);
      cookie.remove(APP_THEME_COLORS);
    }

    const theme = { ...themeProps, ...colorSchema };
    colors = reduce(
      keys(theme),
      (acc, key) =>
        urlParams.get(key)
          ? assign(acc, { [key]: `#${urlParams.get(key)}` })
          : acc,
      colors,
    );
    if (keys(colors).length) {
      cookie.set(APP_THEME_COLORS, JSON.stringify(colors));
    }

    return { ...theme, ...colors };
  }
  return { ...themeProps, ...colorSchema };
};

interface IThemeContext {
  theme: DefaultTheme;
  toggleTheme: (name: string) => void;
}

interface IContextTheme {
  children: React.ReactNode;
  isStoryBook?: boolean;
}

const ThemeContext = createContext({
  theme: getTheme(),
  toggleTheme: noop,
});

export const useThemeScheme = (): IThemeContext => useContext(ThemeContext);

const ContextThemeScheme: React.FC<IContextTheme> = ({
  children,
  //  eslint-disable-next-line
  isStoryBook,
}) => {
  const [theme, setTheme] = useState<DefaultTheme>(getTheme());
  const [themeName, setThemeName] = useState<string>(defaultThemeName);
  useEffect(() => {
    setTheme(getTheme());
  }, [themeName]);

  const toggleTheme = (name: string): void => {
    setThemeName(name);
  };

  forEach(keys(theme), key => {
    if (/^#/.test(theme[key])) {
      document.documentElement.style.setProperty(`--${key}`, theme[key]);
    }
  });
  return (
    <ThemeContext.Provider value={{ theme, toggleTheme }}>
      {getFonts(isStoryBook)}
      {children}
    </ThemeContext.Provider>
  );
};

ContextThemeScheme.defaultProps = {
  isStoryBook: false,
};

export default ContextThemeScheme;

import { IWallet } from '@common/interfaces';
import {
  IGetWalletsRequestPayload,
  IWalletsState,
} from '@common/providers/payments/wallets/types';

export const defaultState: IWalletsState = {
  error: undefined,
  view: null,
  data: [] as IWallet[],
  loading: false,
  request: {
    error: undefined,
    loading: false,
    success: false,
    data: [] as IGetWalletsRequestPayload,
  },
};

export enum WalletsActions {
  GET_WALLETS = 'GET_WALLETS',
}

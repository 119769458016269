import React from 'react';
import { useTranslation } from 'react-i18next';

import { getPageURL, PageName } from '@features/core/routing';
import { InfoBlock } from '@features/profile/components/infoBlock';

import { LUGAS_BLOCK_LAYER } from '@common/constants/dialogs';
import { ButtonType, SidebarSide } from '@common/interfaces';
import { closeSidebar } from '@common/providers/application/useAppState';

import { StyledButton } from '@ui/components/buttons';
import { Icon } from '@ui/components/icon';

import * as S from './LugasBlockLayer.styled';

const LugasBlockLayer = (): React.ReactElement => {
  const { t } = useTranslation();

  const handleClose = (): void => {
    closeSidebar(LUGAS_BLOCK_LAYER);
  };

  return (
    <S.Backdrop className="info-limit">
      <S.Content>
        <S.ContentIcon
          className="closeIcon"
          onClick={handleClose}
          name="close"
          fill="currentColor"
        />
        <S.TitleInner>
          <Icon name="info-circle" width="30px" height="30px" />
          <S.Title>{t('user.lugas.block_header_title')}</S.Title>
        </S.TitleInner>
        <InfoBlock message={t('user.lugas.block_main_text')} />

        <StyledButton
          onClick={handleClose}
          label="user.lugas.block_button"
          buttonType={ButtonType.Secondary}
        />

        <StyledButton
          onClick={(): void => {
            handleClose();
            window.open(getPageURL(PageName.FAQ_LUGAS));
          }}
          label="user.lugas.what_is_lugas"
          buttonType={ButtonType.DarkGhost}
          className="what-is-lugas-btn"
        />
      </S.Content>
    </S.Backdrop>
  );
};

LugasBlockLayer.meta = {
  side: SidebarSide.center,
  key: LUGAS_BLOCK_LAYER,
};

export { LugasBlockLayer as Component };

export default LugasBlockLayer;

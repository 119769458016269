import styled from 'styled-components';

const getInfoSectionMobile = ({ theme }) => ` 
   margin: ${theme.space_20} ${theme.space_10} ${theme.space_10};
`;

const getFooterWrapperMobile = ({ theme }) => `
  width: 100%;
  position: absolute;
  left: 0;
  margin-bottom: ${theme.space_20};
`;

export const FooterWrapper = styled.div`
  ${({ theme }) => (theme.isDesktop ? '' : getFooterWrapperMobile)};
`;

export const InfoSection = styled.div`
  display: grid;
  grid-auto-flow: column;
  align-items: self-start;
  color: ${props => props.theme.highlightWrapperBackground};

  ${({ theme }) => (theme.isDesktop ? '' : getInfoSectionMobile)};
`;

export const InfoIcon = styled.div`
  opacity: 0.5;
`;

const getInfoSectionTextStyles = theme => {
  if (theme.isDesktop) {
    return `
    font-size: ${theme.fontSM};
    margin-bottom: ${theme.space_30};
    `;
  }
  return `
  font-size: ${theme.fontMD};
  `;
};
export const InfoSectionText = styled.div`
  margin-left: ${props => props.theme.space_5};
  font-weight: ${({ theme }) => theme.fontRegular};
  font-stretch: condensed;
  ${({ theme }) => getInfoSectionTextStyles(theme)};
`;

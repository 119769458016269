import React, { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';

import * as S from './BetItem.styled';

const BetItemHeader = (): ReactElement | null => {
  const { t } = useTranslation();
  return (
    <S.ItemHeader>
      <S.DeskIcon />
      <S.DeskDataHeader>{t('bets.date')}</S.DeskDataHeader>
      <S.DeskTitleHeader>{t('bets.bet_type')}</S.DeskTitleHeader>
      <S.DeskBetDetailsHeader>{t('bets.nls_total')}</S.DeskBetDetailsHeader>
      <S.DeskQuoteHeader>{t('bets.odds')}</S.DeskQuoteHeader>
      <S.DeskProfitHeader>{t('common.labels.profit')}</S.DeskProfitHeader>
      <S.DeskStatusHeader>{t('bets.status')}</S.DeskStatusHeader>
      <S.DeskArrow />
    </S.ItemHeader>
  );
};

export default BetItemHeader;

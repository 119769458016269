export interface IBetPackerIcon {
  size: IBetPackerIconSize;
}

export enum IBetPackerIconSize {
  normal = 'normal',
  small = 'small',
}

export interface IBetPackerExplanation {
  toggleExplanationPopup: React.Dispatch<React.SetStateAction<boolean>>;
  hasExplanationPopup: boolean;
}

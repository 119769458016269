import styled from 'styled-components';

import { TextLarge, TextNormal } from '@features/core/styles/text.styled';

export const Root = styled.div`
  background-color: ${props => props.theme.textActive};
  padding: ${props => props.theme.space_20} ${props => props.theme.space_20} 0
    ${props => props.theme.space_10};
  margin-bottom: ${props => props.theme.space_5};
`;

export const UserNameLabel = styled(TextLarge)`
  font-weight: ${({ theme }) => theme.fontBold};
`;

export const ExpandContainer = styled.div`
  display: grid;
  grid-auto-flow: column;
  justify-content: start;
  align-items: center;
  margin-bottom: ${({ theme }) => theme.space_20};
  grid-gap: ${({ theme }) => theme.space_10};
  margin-left: ${({ theme }) => theme.space_5};

  .user-info {
    color: ${({ theme }) => theme.textMain};
    font-size: ${({ theme }) => theme.fontLG};
    text-transform: uppercase;
    position: relative;
  }
`;

export const Avatar = styled(TextNormal)`
  display: grid;
  grid-auto-flow: column;
  justify-content: center;
  align-items: center;
  width: 26px;
  height: 26px;
  border-radius: 50%;
  background-color: ${props => props.theme.accent};
  color: ${props => props.theme.textActive};
  font-weight: ${({ theme }) => theme.fontBold};

  svg {
    width: 16px;
    height: 16px;
  }
`;

export const LinksContainer = styled.div`
  padding: 0 ${({ theme }) => theme.space_40};
`;

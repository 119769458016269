import { ENV, ConfigConstantsT } from '../../common/constants/config';
import convict from '../../../config/convict';
import schema from '../../../config/browser.schema';

export type ConfigValueT = string | boolean | number | string[];

export type ConfigT = {
  [key: string]: ConfigValueT;
};

const env = process.env.NODE_ENV || 'development';

class Config {
  private config = convict(schema);

  constructor() {
    try {
      // eslint-disable-next-line
      this.config.load(require(`../../../config/${env}.json`));
    } catch (e) {
      // eslint-disable-next-line
      this.config.load(require(`../../../config/development.json`));
    }
    this.config.validate({ allowed: 'strict' });
    this.set(ENV, env);
    if (typeof window !== 'undefined') {
      window.config = this.config;
    }
  }

  get(propName?: ConfigConstantsT): ConfigT[keyof ConfigT] | ConfigT {
    if (propName) {
      return this.config.get(propName);
    }

    return this.config.getProperties();
  }

  set(propName: ConfigConstantsT, value: ConfigValueT): void {
    this.config.set(propName, value);
  }
}

export default Config;

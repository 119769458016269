import styled from 'styled-components';

export const OddsComparisonSectionWrapper = styled.div``;

const getSlidersStyles = (isLeft, isActive) => {
  if (isLeft && !isActive) {
    return 'transform: translateX(-100%); opacity: 0; pointer-events: none;';
  }
  if (!isLeft && !isActive) {
    return 'transform: translateX(100%); opacity: 0; pointer-events: none;';
  }
  return 'transform: translateX(0%); opacity: 1;';
};

export const OddsComparisonSliderWrapper = styled.div<{
  isLeft: boolean;
  isActive: boolean;
}>`
  position: absolute;
  ${({ isLeft, isActive }) => getSlidersStyles(isLeft, isActive)};

  transition: transform 0.3s ease-out, opacity 0.3s ease-out;
`;

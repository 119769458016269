import services from '@features/core/services';

import { getApiUrl, StaticCalls } from '@common/api/api';
import { IGameData } from '@common/interfaces';

const { API_CASINO } = StaticCalls;

export default async (): Promise<IGameData> => {
  const url = getApiUrl(API_CASINO);

  try {
    const data = await services.http(url, {
      params: {
        lang: services.domainLang || 'de',
        domain: 'tb.exxs.net',
      },
    });
    return data.data;
  } catch (err) {
    const error = err as string;
    const errorWithResponse = err as { response: string };
    const casinoGameError =
      (errorWithResponse && errorWithResponse.response) || error;
    services.logger.error('fetchGames', casinoGameError);
    throw err;
  }
};

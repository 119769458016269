import { Rule } from 'rc-field-form/lib/interface';

import i18next from '@features/core/translation';
import { ITooltipRule } from '@features/core/form/components/requirementsTooltip/RequirementsTooltip.types';
import Patterns from '@features/core/form/utils/patterns';

import incorrectCharactersValidator from '@common/helpers/formsHelpers/validation/incorrectCharacters';

const passwordPreset = (): Rule[] => [
  {
    required: true,
    message: `${i18next.t('registration.step1.password_required')}`,
  },
  {
    min: 8,
    message: i18next.t('registration.step1.password_min_length'),
  },
  {
    pattern: /[A-Z]/,
    message: i18next.t('registration.step1.password_upper_letter'),
  },
  {
    pattern: Patterns.PASSWORD,
    message: i18next.t('registration.step1.password_error_characters'),
  },
  {
    validator: incorrectCharactersValidator(
      Patterns.INCORRECT_PASSWORD,
      i18next.t('registration.step1.password_error_characters'),
    ),
  },
];

export const passwordTooltipRules = (): ITooltipRule[] => [
  {
    pattern: /^.{8,}$/,
    message: i18next.t('common.change_password.tip_length_8_symbols'),
  },
  {
    pattern: /(?=.*[A-Z])(?=.*[a-z])/,
    message: i18next.t('common.change_password.tip_lowercase_uppercase'),
  },
  {
    pattern: /\d/,
    message: i18next.t('common.change_password.tip_one_digit'),
  },
  {
    pattern: /[_!.$§%&?/()=+*#-]/,
    message: i18next.t('common.change_password.tip_one_special_character'),
  },
];

export default passwordPreset;

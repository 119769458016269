import React from 'react';
import { useTranslation } from 'react-i18next';
import map from 'lodash/map';

import { useServices } from '@features/core/services';
import BetStatusSquare from '@features/bets/components/betStatusSquare/BetStatusSquare';
import MatrixItem from '@features/bets/components/combiMatrix/CombiMatrixItem';

import { roundDown } from '@common/helpers/deviceUtil';
import CombiMatrixModel from '@common/helpers/combinations/combiMatrixModel';
import { IBetDetail } from '@common/interfaces';
import { formatBalance } from '@common/helpers/paymentsHelper/walletsHelper';

import * as S from './CombiMatrix.styled';
import CombiMatrixStatusCode from './CombiMatrix.types';

const CombiMatrix: React.FC<{ bet: IBetDetail }> = (
  props,
): JSX.Element | null => {
  const { logger } = useServices();
  const { t } = useTranslation();
  const { bet } = props;
  const model = new CombiMatrixModel({ bet });

  const drawTable = (): JSX.Element => {
    const combinations = model.getSystemCombination();
    const ticket = model.getTicket();
    const titles = [
      'bets.title_keys.selection_short',
      'bets.title_keys.status',
      'bets.title_keys.odds',
      'bets.title_keys.stake_per_bet',
      'bets.title_keys.profit',
    ];

    return (
      <S.Table>
        <S.TableHeader>
          <S.TableRow>
            {map(titles, (title, index) => (
              <S.TableTitle key={index} isStatus>
                {t(title)}
              </S.TableTitle>
            ))}
          </S.TableRow>
        </S.TableHeader>

        <S.TableBody>
          {map(combinations, (combination, index) => (
            <S.TableRow key={index}>
              <S.TableCell>{index + 1}</S.TableCell>

              <S.TableCell status={model.getLegGroupStatus(combination)}>
                <S.TableItemWrapper>
                  <BetStatusSquare
                    status={model.getLegGroupStatus(combination)}
                    size="16px"
                  />
                </S.TableItemWrapper>
              </S.TableCell>

              <S.TableCell>
                {roundDown(model.getTotalQuoteForLegGroup(combination))}
              </S.TableCell>

              <S.TableCell>
                {formatBalance(ticket.stake, { precision: 2, optional: 2 })}
              </S.TableCell>

              <S.TableCell
                isWon={
                  model.getLegGroupStatus(combination) ===
                  CombiMatrixStatusCode.WON
                }
              >
                {formatBalance(
                  model.getProfitForLegGroup(
                    model.getTotalQuoteForLegGroup(combination),
                  ),
                )}
              </S.TableCell>
            </S.TableRow>
          ))}
        </S.TableBody>
      </S.Table>
    );
  };

  try {
    const combinations = model.getSystemCombination();
    let iteration = 0;
    const tables: JSX.Element[] = [];

    while (combinations.length) {
      tables.push(
        <MatrixItem
          matrix={combinations.splice(0, model.getMatrixLength())}
          iteration={iteration}
          bet={bet}
        />,
      );
      iteration++;
    }
    return (
      <S.TableContainer className="matrix">
        {tables}

        {drawTable()}
      </S.TableContainer>
    );
  } catch (e) {
    logger.log(e as string);
    return null;
  }
};

export default CombiMatrix;

import React from 'react';
import map from 'lodash/map';
import { useTranslation } from 'react-i18next';

import { IOddsComparisonSectionItem, ItemType } from '@common/interfaces';
import { getFormattedItemSubtitle } from '@common/helpers/oddsComparisonHelper/oddsComparisonHelper';

import { Icon } from '@ui/components/icon';

import * as S from './OddsComparisonSectionItem.styled';

const OddsComparisonSectionItem = ({
  title,
  subtitle,
  odds,
  sectionItemType = ItemType.default,
  isFullSection,
  eventDate,
}: IOddsComparisonSectionItem): JSX.Element => {
  const { t } = useTranslation();

  const isHeader = sectionItemType === ItemType.header;

  const generateItemOdds = (oddsList: string[]): JSX.Element[] =>
    map(oddsList, (odd, index) => {
      const isSW = odd === 'swde';
      if (isHeader) {
        return (
          <S.OddsProvider key={index} isSW={isSW} isHeader={isHeader}>
            {isSW ? (
              <Icon name="sw-logo" width="20px" height="20px" isMultiplyAttrs />
            ) : (
              odd
            )}
          </S.OddsProvider>
        );
      }
      return (
        <S.OddsProvider
          key={index}
          isSW={index === 0}
          isHeader={isHeader}
          isWinChance={sectionItemType === ItemType.winChance}
          isFullSection={isFullSection}
        >
          {sectionItemType === ItemType.winChance &&
            index === 0 &&
            isFullSection && <S.WinChanceOddsTriangle />}
          {odd}
        </S.OddsProvider>
      );
    });

  return (
    <S.OddsComparisonItemWrapper
      sectionItemType={sectionItemType}
      isFullSection={isFullSection}
    >
      <S.OddsCompItemLabel
        isHeader={isHeader}
        isWinChance={sectionItemType === ItemType.winChance}
      >
        <S.OddsCompItemTitle isHeader={isHeader}>{title}</S.OddsCompItemTitle>
        {isHeader ? (
          <S.OddsCompStakeValue className="oc-stake-value">
            {`${t('oddsComparison.stake')}${isFullSection ? '*' : ''}: 20€`}
          </S.OddsCompStakeValue>
        ) : (
          <S.OddsCompItemSubtitle>
            {getFormattedItemSubtitle(subtitle, eventDate, isFullSection)}
          </S.OddsCompItemSubtitle>
        )}
      </S.OddsCompItemLabel>
      <S.OddsProviderWrapper>{generateItemOdds(odds)}</S.OddsProviderWrapper>
    </S.OddsComparisonItemWrapper>
  );
};

export default OddsComparisonSectionItem;

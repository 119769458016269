import React from 'react';

import {
  DartsScore,
  GenericScore,
  FootballLarge,
  BaseballScore,
  BasketballScore,
  DefaultSetScore,
  HockeyScoreLarge,
  GenericScoreLastPart,
} from '@features/events/components/scoreboards/scores';
import { IFactoryScoreboardsProps } from '@features/events/components/scoreboards/factoryScoreboards';

import { getSportName } from '@common/helpers/categories/categoriesModel';
import {
  isHockey,
  isBasketball,
  isTennis,
  isVolleyball,
  isFootball,
  isDarts,
  isAmericanFootball,
  isHandball,
  isEsport,
  isBeachVolleyball,
  isBaseBall,
  isRugby,
  isAussierules,
  isAmerican,
} from '@common/helpers/eventsHelper/eventTypeHelper';

import { ScoreboardTypes } from './FactoryScoreboards.types';

export const FactoryScoreboards = (
  props: IFactoryScoreboardsProps,
): React.ReactElement => {
  const { id, event, topCategoryId, type, listType } = props;
  const scoreDefaultProps = {
    id,
    detailView: type === ScoreboardTypes.large,
    listType,
    sportName: getSportName(topCategoryId),
  };

  if (
    isTennis(event) ||
    isVolleyball(event) ||
    isBeachVolleyball(event, topCategoryId)
  ) {
    if (type === ScoreboardTypes.small) {
      return <GenericScoreLastPart {...scoreDefaultProps} />;
    }
    return <DefaultSetScore {...scoreDefaultProps} />;
  }

  if (isEsport(event)) {
    return <DefaultSetScore {...scoreDefaultProps} />;
  }

  if (type === ScoreboardTypes.large) {
    if (isHockey(event)) {
      return <HockeyScoreLarge {...scoreDefaultProps} />;
    }
    if (isFootball(event) || isHandball(event)) {
      return <FootballLarge {...scoreDefaultProps} />;
    }
    if (isBasketball(event)) {
      return <BasketballScore {...scoreDefaultProps} />;
    }
    if (isDarts(event)) {
      return <DartsScore {...scoreDefaultProps} />;
    }
    if (isAmericanFootball(event)) {
      return <DefaultSetScore {...scoreDefaultProps} />;
    }
    if (isBaseBall(event, topCategoryId)) {
      return <BaseballScore {...scoreDefaultProps} />;
    }

    return <GenericScore {...scoreDefaultProps} />;
  }

  if (
    isFootball(event) ||
    isRugby(topCategoryId) ||
    isAmerican(topCategoryId) ||
    isAussierules(topCategoryId) ||
    isHandball(event) ||
    isHockey(event)
  ) {
    return <GenericScore {...scoreDefaultProps} />;
  }

  return <GenericScoreLastPart {...scoreDefaultProps} />;
};

export default FactoryScoreboards;

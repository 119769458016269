import { elementScrollIntoView } from 'seamless-scroll-polyfill';

import { IResponseErrorType } from '@common/interfaces';

const scrollToErrorField = (error: IResponseErrorType): void => {
  if (error?.errorFields?.length) {
    const { errorFields } = error;
    const firstErrorFieldName = errorFields[0].name[0];
    const element = document.querySelector(`#${firstErrorFieldName}`);
    if (element) {
      return elementScrollIntoView(element, {
        behavior: 'smooth',
        block: 'center',
        inline: 'nearest',
      });
    }
  }
  return undefined;
};

export default scrollToErrorField;

import styled from 'styled-components';

// eslint-disable-next-line import/prefer-default-export
export const TimerWrapper = styled.div`
  display: grid;
  grid-auto-flow: column;
  align-items: center;
  color: ${({ theme }) => theme.textMain};

  @media screen and (min-width: 481px) {
    padding-right: ${({ theme }) => theme.space_5};
  }
`;

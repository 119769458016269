import { create } from 'zustand';
import split from 'lodash/split';

import defaultState from './state';
import {
  IChangeRoutePayload,
  IRouterState,
  matchedWebRouteType,
} from './types';

export const useRouterState = create<IRouterState>(() => defaultState);

export const changeRoute = ({
  url,
  history,
  search,
  forceReload,
}: IChangeRoutePayload): void => {
  const routeUrl = url !== undefined ? split(url, '?') : [''];
  const {
    push,
    location,
    location: { pathname },
  } = history;
  const result = routeUrl[0];

  if (result && (result !== pathname || forceReload)) {
    push({
      pathname: result,
      search: search || routeUrl[1],
    });
  }
  useRouterState.setState(() => ({
    location,
  }));
};

export const setCurrentWebRoute = (
  webRoute: matchedWebRouteType | null,
): void => {
  useRouterState.setState(() => ({
    currentWebRoute: webRoute,
  }));
};

import styled from 'styled-components';

import { Icon } from '@ui/components/icon';

export const Content = styled.div`
  position: relative;
  padding: ${({ theme }) => theme.space_30} ${({ theme }) => theme.space_10}
    ${({ theme }) => theme.space_15};
  background-color: ${props => props.theme.textMain};
  color: ${props => props.theme.inputDefaultText};
  font-size: ${props => props.theme.fontMD};
  font-stretch: condensed;
  overflow-y: auto;
  max-width: 500px;

  .block-acc-abort-btn {
    margin-top: ${({ theme }) => theme.space_15};
  }
`;

export const ContentIcon = styled(Icon)`
  position: absolute;
  top: ${({ theme }) => theme.space_10};
  right: ${({ theme }) => theme.space_15};
  transform: translateY(100%);
  width: 12px;
  height: 12px;
  color: inherit;
  cursor: pointer;
`;

export const TitleInner = styled.section`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-bottom: ${({ theme }) => theme.space_15};
`;

export const Title = styled.h2`
  margin: 0;
  font-weight: ${({ theme }) => theme.fontBold};
  font-size: ${props => props.theme.fontH3};
  position: relative;

  svg {
    display: inline-block;
    position: absolute;
    left: 0;
  }
`;

export const ReasonContentTitle = styled.span`
  display: block;
  margin-top: ${({ theme }) => theme.space_5};
  font-weight: ${({ theme }) => theme.fontBold};
`;

export const DurationTitle = styled.div`
  margin: ${({ theme }) => theme.space_30} 0 ${({ theme }) => theme.space_5};
`;

export const DurationPeriod = styled.span`
  font-weight: ${({ theme }) => theme.fontBold};
`;

export const ConfirmContent = styled.div`
  margin: ${({ theme }) => theme.space_30} 0 ${({ theme }) => theme.space_20};
`;

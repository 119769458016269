import React, { useEffect } from 'react';
import isString from 'lodash/isString';

import { scrollTo } from '@common/helpers/scrollHelper';

import * as S from './Error.styled';
import IError from './Error.types';

const ErrorComponent: React.FC<IError> = ({
  message,
  scroll,
  className,
  floatError,
  isFloatErrorArrowLeft,
  isRelativeErrorPosition,
  isInputError,
}) => {
  const errorMessage = isString(message) ? message : message.join(', ');

  useEffect(() => {
    if (scroll) {
      scrollTo(window, {
        top: 0,
        behavior: 'smooth',
      });
    }
  }, []);

  useEffect(() => {
    if (scroll && message) {
      scrollTo(window, {
        top: 0,
        behavior: 'smooth',
      });
    }
  }, [message]);

  if (message) {
    return (
      <S.ErrorComponentWrapper
        floatError={!!floatError}
        className={className || ''}
        isFloatErrorArrowLeft={!!isFloatErrorArrowLeft}
        isRelativeErrorPosition={!!isRelativeErrorPosition}
        dangerouslySetInnerHTML={{
          __html: errorMessage,
        }}
        isInputError={!!isInputError}
      />
    );
  }
  return null;
};

export default ErrorComponent;

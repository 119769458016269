import { AxiosRequestConfig } from 'axios';

import services from '@features/core/services';

import { StaticCalls } from '@common/api/api';
import { apiHandler, MethodType } from '@common/api/apiHandler';
import {
  ICustomError,
  IFetchPocketTagsResponse,
  IRequest,
} from '@common/interfaces';
import { usePaymentsState } from '@common/providers/payments/usePayments';

const { API_POCKETS_TAGS } = StaticCalls;

const fetchPocketTags = async ({
  method,
  params,
}: IRequest): Promise<IFetchPocketTagsResponse & ICustomError> => {
  const token = usePaymentsState.getState().account?.token;
  const accountId = usePaymentsState.getState().account?.pocket_id;

  if (!token || !accountId) {
    services.logger.error(
      `Not possible to perform pocket requests ${method} and ${params}, the account data is not loaded`,
    );
  }

  const options = {
    method,
    ...(method === MethodType.Get
      ? { params: { ...params, accountId, token } }
      : { data: { ...params, accountId, token } }),
  } as AxiosRequestConfig;

  return apiHandler<IFetchPocketTagsResponse>(options, {
    apiUrl: API_POCKETS_TAGS,
    apiData: {
      dynamicLanguage: services.domainLang,
    },
  });
};

export default fetchPocketTags;

import startsWith from 'lodash/startsWith';

const getImageBasePath = (path: string): string => {
  // eslint-disable-next-line
  const isStorybook = Boolean(window?.__STORYBOOK_CLIENT_API__);

  if (isStorybook) {
    return startsWith(path, '/') ? path.slice(1) : path;
  }

  return startsWith(path, '/') ? path : `/${path}`;
};

export default getImageBasePath;

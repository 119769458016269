// eslint-disable-next-line check-file/filename-naming-convention
import assign from 'lodash/assign';

import services from '@features/core/services';

import { ConfigConstantsT } from '@common/constants/config';

export enum configTypes {
  bs = 'bs',
  markets = 'markets',
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const loadConfig = (path: configTypes, key?: ConfigConstantsT): any => {
  const configName = services.config.get(key) || 'default';
  let defaultStateConfig = {};
  let overwriteConfig = {};
  try {
    // eslint-disable-next-line global-require,import/no-dynamic-require
    defaultStateConfig = require(`../../../config/${path}/default.json`);
    // eslint-disable-next-line global-require,import/no-dynamic-require
    overwriteConfig = require(`../../../config/${path}/${configName}.json`);
    assign(defaultStateConfig, overwriteConfig);
  } catch (e) {
    services.logger?.log(String(e));
  }
  return defaultStateConfig;
};

export default loadConfig;

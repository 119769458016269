import React, { useCallback } from 'react';

import {
  PredictionsDiv,
  Label,
  PredictionWrapper,
} from '@features/events/components/markets/mapMarket/MapMarket.styled';
import {
  MarketsLabel,
  SpecialValue,
} from '@features/events/components/markets';
import { Prediction } from '@features/events/components/prediction';
import { useEventsModel } from '@features/events/useEventsModel';

import {
  getAwayPrediction,
  getHomePrediction,
} from '@common/helpers/eventsHelper/predictionModel';
import { getMarketLayout } from '@common/helpers/markets/marketModel';
import { PageType } from '@common/interfaces';
import { useMarketPredictions } from '@common/hooks/useMarketPredictions';

import { IExtraMarketDual } from './ExtraMarketDual.types';

const ExtraMarketDual: React.FC<IExtraMarketDual> = props => {
  const {
    market,
    hasLabel,
    hasSpecialValue,
    listType,
    pageType,
    eventId,
  } = props;

  const { event } = useEventsModel(eventId, listType);
  const predictions = useMarketPredictions(market, listType);

  const getPredictions = useCallback((): JSX.Element => {
    const home = getHomePrediction(market, predictions);
    const away = getAwayPrediction(market, predictions);

    return (
      <>
        {home && (
          <Prediction
            listType={listType}
            pageType={pageType}
            eventId={eventId}
            marketId={market.id}
            predictionId={home.id}
            withLabel={false}
          />
        )}
        {away && (
          <Prediction
            listType={listType}
            pageType={pageType}
            eventId={eventId}
            marketId={market.id}
            predictionId={away.id}
            withLabel={false}
          />
        )}
      </>
    );
  }, [market, predictions]);

  const marketLayout = getMarketLayout(market, predictions);

  return (
    <PredictionsDiv isDetail={pageType === PageType.DETAIL}>
      {(hasLabel || hasSpecialValue) && (
        <Label pageType={pageType}>
          {hasLabel && <MarketsLabel market={market} />}
          <SpecialValue
            market={market}
            hasSpecialValue={hasSpecialValue}
            pageType={pageType}
            event={event}
          />
        </Label>
      )}
      {pageType === PageType.DETAIL ? (
        <PredictionWrapper className={marketLayout}>
          {getPredictions()}
        </PredictionWrapper>
      ) : (
        <div className="live-dual">{getPredictions()}</div>
      )}
    </PredictionsDiv>
  );
};

export default ExtraMarketDual;

import styled from 'styled-components';

// eslint-disable-next-line import/prefer-default-export
export const ConfirmWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 42px;
  grid-auto-flow: column;
  width: 100%;
  grid-gap: ${({ theme }) => theme.space_10};
  justify-items: end;
`;

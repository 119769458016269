import styled from 'styled-components';

export const CheckboxLabel = styled.label`
  display: grid;
  grid-auto-flow: column;
`;

export const RadioTitle = styled.span`
  font-size: ${props => props.theme.fontMD};
  font-weight: ${({ theme }) => theme.fontBold};
  font-stretch: condensed;
  font-style: normal;
  color: ${props => props.theme.textMain};
`;

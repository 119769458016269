import React, { useState } from 'react';
import map from 'lodash/map';

import { CategoryTreeItem } from '@features/categories/categoryTreeItem';

import { getCheckedCategories } from '@common/helpers/categories/categories';
import { ILiveSportCategoriesTreeData } from '@common/interfaces';

import * as S from './CollapsedCategoriesList.styled';

const CollapsedCategoriesList = (props: {
  item: ILiveSportCategoriesTreeData;
  onClick: (item: ILiveSportCategoriesTreeData) => void;
  onSelect: (item: ILiveSportCategoriesTreeData) => void;
  filterData: string;
  isHighlight: boolean;
}): React.ReactElement => {
  const { item, filterData, onClick, onSelect, isHighlight } = props;
  const [showLeaveItems, setShowLeaveItems] = useState(false);
  const selectedCategories: string[] = getCheckedCategories();

  const toggleLeaveItems = (): void =>
    setShowLeaveItems(prevState => !prevState);

  if (!item.count) {
    return <></>;
  }

  return (
    <S.CategoryList>
      <CategoryTreeItem
        key={item.id}
        item={item}
        onClick={onClick}
        onSelect={onSelect}
        onExpand={toggleLeaveItems}
        filter={filterData}
        opened={showLeaveItems}
        selectedItems={selectedCategories}
        isHighlight={isHighlight}
      />

      <S.CategoryItems>
        {showLeaveItems &&
          map(item.children, i => {
            if (!i.count) {
              return null;
            } // exclude categories without events
            if (i.children?.length) {
              return (
                <CollapsedCategoriesList
                  key={i.id}
                  item={i}
                  filterData={filterData}
                  onClick={onClick}
                  onSelect={onSelect}
                  isHighlight={isHighlight}
                />
              );
            }
            return (
              <CategoryTreeItem
                key={i.id}
                item={i}
                onClick={onClick}
                onSelect={onSelect}
                filter={filterData}
                selectedItems={selectedCategories}
              />
            );
          })}
      </S.CategoryItems>
    </S.CategoryList>
  );
};

export default CollapsedCategoriesList;

import { StaticCalls } from '@common/api/api';
import { ICustomError, IPaymentAccountResponse } from '@common/interfaces';
import { apiHandler, MethodType } from '@common/api/apiHandler';

const { API_PAYMENT_ACCOUNT } = StaticCalls;

const fetchPaymentAccount = (): Promise<IPaymentAccountResponse &
  ICustomError> =>
  apiHandler<IPaymentAccountResponse>(
    { method: MethodType.Get },
    {
      apiUrl: API_PAYMENT_ACCOUNT,
    },
  );

export default fetchPaymentAccount;

import isEmpty from 'lodash/isEmpty';

import fetchSetPushSubscription from '@common/api/pushNotifications/fetchSetPushSubscription';
import fetchNotificationSubscriptions from '@common/api/pushNotifications/fetchNotificationSubscriptions';
import fetchPushNotificationStatus from '@common/api/pushNotifications/fetchPushNotificationStatus';
import { ISavePushNotifications } from '@common/providers/pushNotifications/types';
import {
  setChoosedPushSubscription,
  setPushNotificationLoading,
  setPushNotificationSuccess,
  setPushSubscriptions,
} from '@common/providers/pushNotifications/usePushNotifications';

export const savePushNotificationSubscription = async ({
  username,
  status,
  eid,
  cid,
  settings = null,
}: ISavePushNotifications): Promise<void> => {
  setPushNotificationLoading();

  const payload = eid
    ? { username, status, eid, settings }
    : { username, status, cid, settings };

  const response = await fetchSetPushSubscription(payload);

  if (response.data === 1 || response.data) {
    setChoosedPushSubscription(payload);
  }
};

export const getPushNotificationStatus = async ({
  username,
}): Promise<void> => {
  setPushNotificationLoading();
  const status = await fetchPushNotificationStatus({ username });

  if (!isEmpty(status)) {
    setPushNotificationSuccess();
  }
};

export const getPushSubscriptions = async ({
  username,
  target = '',
}): Promise<void> => {
  setPushNotificationLoading();
  const response = await fetchNotificationSubscriptions({ username, target });

  if (!target) {
    setPushSubscriptions({
      type: 'all',
      list: [response.event, response.category],
    });
  }

  if (response.category) {
    setPushSubscriptions({
      type: 'categories',
      list: response.category,
    });
  }

  if (response.event) {
    setPushSubscriptions({
      type: 'events',
      list: response.event,
    });
  }
};

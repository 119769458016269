import styled from 'styled-components';

const getInfoBoldStyles = theme => `font-weight: ${theme.fontBold};`;

const getInformationStyles = (isOasisPage, theme) => {
  return isOasisPage && theme.isDesktop ? `0 0 ${theme.space_5};` : '0;';
};

const Information = styled.div<{
  isOasisPage?: boolean;
  isLimitsPage?: boolean;
}>`
  font-size: smaller;
  color: ${({ isLimitsPage, theme }) =>
    isLimitsPage ? theme.textMain : theme.main};
  /* stylelint-disable-next-line declaration-block-no-duplicate-properties */
  margin: ${({ isOasisPage, theme }) =>
    getInformationStyles(isOasisPage, theme)};

  ul {
    padding: 0;
    margin-left: ${({ theme }) => theme.space_20};
    ${({ isLimitsPage }) => isLimitsPage && 'margin-top: 0;'}
  }

  li {
    padding: ${({ theme }) => theme.space_5} 0;
  }

  a {
    color: ${({ theme }) => theme.main};
    ${({ isOasisPage, theme }) => isOasisPage && getInfoBoldStyles(theme)}
  }
`;

export default Information;

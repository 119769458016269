import { RuleObject } from 'rc-field-form/es/interface';

const notOnlyLetters = async (
  rule: RuleObject,
  value: string,
): Promise<void> => {
  // eslint-disable-next-line
  const pattern = /^[a-zA-Z-.,/\säöüÖÄÜß]*$/;
  if (pattern.test(value)) {
    return Promise.reject(new Error(`${rule.message}`));
  }
  return Promise.resolve();
};

export default notOnlyLetters;

import { useState, useEffect } from 'react';

import {
  getCalculatedLegs,
  getTotalQuotas,
} from '@common/helpers/bettingSlipHelper/bettingSlipModel';
import { IBettingslip } from '@common/interfaces';

const useQuotas = (bettingslip: IBettingslip, showQuotas: boolean): string => {
  const [quotas, setQuotas] = useState('');

  useEffect(() => {
    setTimeout(() => {
      if (showQuotas) {
        const calculatedLegs = getCalculatedLegs(bettingslip);
        setQuotas(getTotalQuotas(calculatedLegs));
      }
    }, 0);
  }, [bettingslip, showQuotas]);

  return quotas;
};

export default useQuotas;

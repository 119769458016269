import React from 'react';
import { useTranslation } from 'react-i18next';

import { CashoutBetPacker } from '@features/cashout/components/cashoutBetPacker';
import BetStatusSquare from '@features/bets/components/betStatusSquare/BetStatusSquare';
import { SuspendReason } from '@features/events/components/susependReason';
import { StatusCode } from '@features/bets/components/betStatusSquare/BetStatusSquare.types';
import { useThemeScheme } from '@features/core/theming/contexTheme';
import { useEventsModel } from '@features/events/useEventsModel';

import { EventListTypes, LiveStatus } from '@common/interfaces';
import isEventSuspended from '@common/helpers/eventsHelper/isEventSuspended';

import { Icon } from '@ui/components/icon';

import * as S from './CashoutSelection.styled';
import { ICashoutSelectionProps } from './CashoutSelection.types';

const CashoutSelection: React.FC<ICashoutSelectionProps> = props => {
  const {
    market_name,
    user_pick,
    odds,
    status = -1,
    letter,
    base_predictions_detail,
    isLocked,
    eventId,
    listType,
  } = props;
  const { t } = useTranslation();
  const isBetPackerMode = !!base_predictions_detail?.length;
  const { event } = useEventsModel(eventId, listType);
  const isSuspendReason = isEventSuspended(event);
  const isSelectionLocked =
    (event.live_status === LiveStatus.suspended || isLocked) &&
    !isSuspendReason;
  const { theme } = useThemeScheme();

  return (
    <>
      {!isBetPackerMode ? (
        <S.CashoutSelectionWrap
          isLocked={isSelectionLocked}
          isSuspended={isSuspendReason}
          isCancelled={status === StatusCode.CANCELLED}
        >
          <BetStatusSquare status={status} />

          <S.MarketBlock
            data-qa="market-block"
            isLocked={isSelectionLocked || isSuspendReason}
          >
            {status === StatusCode.CANCELLED && <b>{t('bets.cancelled')}: </b>}
            <span className="market_name" data-qa="market-name">
              {letter ? `${letter} | ${market_name}` : market_name}
            </span>
            <span className="dash">&nbsp;-&nbsp;</span>
            <b className="user_pick">{user_pick}</b>
          </S.MarketBlock>

          {isSelectionLocked && (
            <S.IconWrapper>
              <Icon
                width="16"
                height="18"
                name="lock-outlined"
                fill={theme.statusError}
              />
            </S.IconWrapper>
          )}

          <SuspendReason
            reasonType={EventListTypes.cashout}
            eventId={eventId}
          />

          <S.AmountSquare
            data-qa="cashout-selection-amount"
            className="cashout-selection-amount"
          >
            <S.UserPick status={status}>
              {odds && parseFloat(odds).toFixed(2)}
            </S.UserPick>
          </S.AmountSquare>
        </S.CashoutSelectionWrap>
      ) : (
        <CashoutBetPacker
          base_predictions_detail={base_predictions_detail}
          status={status}
          odds={odds}
        />
      )}
    </>
  );
};

export default CashoutSelection;

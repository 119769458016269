import {
  IRegisterProviderState,
  IRegisterError,
} from '@common/providers/registration/types';

const defaultState: IRegisterProviderState = {
  success: false,
  error: { submitErrors: [] } as IRegisterError,
  loading: false,
};

export default defaultState;

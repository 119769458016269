// import minMax from '@features/core/form/validators/minMax';
import { Rule } from 'rc-field-form/lib/interface';

import i18n from '@features/core/translation';

import onlyNumbersRule from '@common/helpers/formsHelpers/validation/onlyNumbersRule';

const makeLimitValuePreset = (): Rule[] => [
  {
    validator: async (rule, value) =>
      value ? onlyNumbersRule(rule, value) : Promise.resolve(),
    message: i18n.t('common.presets.only_digits'),
  },
  {
    validator: async (rule, value) =>
      value && value % 1 !== 0
        ? Promise.reject(i18n.t('common.presets.only_non_fractional_numbers'))
        : Promise.resolve(),
    message: i18n.t('common.presets.only_non_fractional_numbers'),
  },
  {
    validator: async (rule, value) =>
      value > 0 || !value
        ? Promise.resolve()
        : Promise.reject(i18n.t('common.presets.limit_value_bigger_than_zero')),
    message: i18n.t('common.presets.limit_value_bigger_than_zero'),
  },
];

export default makeLimitValuePreset;

import styled from 'styled-components';

import { TextLarge } from '@features/core/styles/text.styled';

export const Root = styled.div`
  &:not(:first-child) {
    margin-top: ${({ theme }) => theme.space_10};
  }

  @media screen and (max-width: 481px) {
    &.favorites-sport-container {
      margin-bottom: ${({ theme }) => theme.space_10};
    }
  }
`;

export const Title = styled.div`
  display: grid;
  grid-auto-flow: column;
  align-items: center;
  justify-content: space-between;
  padding: ${({ theme }) => theme.space_10};
  color: ${({ theme }) => theme.textMain};
  font-size: ${props => props.theme.fontLG};
  font-weight: ${({ theme }) => theme.fontBold};
  background-color: ${({ theme }) => theme.primaryBackground};
  cursor: pointer;

  &.favorites-sport {
    background-color: ${({ theme }) => theme.textActive};

    @media screen and (max-width: 481px) {
      padding: 0 0 0 ${({ theme }) => theme.space_10};
      min-height: 40px;
      grid-gap: ${({ theme }) => theme.space_5};
      grid-auto-columns: 1fr 1fr 30px;
    }
  }
`;

export const AllEventsLink = styled(TextLarge)`
  margin-left: 185px;
  color: ${props => props.theme.accent};
  font-weight: ${({ theme }) => theme.fontRegular};
  text-decoration: underline;
  cursor: pointer;
  font-size: ${({ theme }) => !theme.isDesktop && theme.fontMD};
  white-space: nowrap;
`;

export const ChevronWrapper = styled.div`
  @media screen and (max-width: 481px) {
    min-width: 30px;
    justify-self: end;
    display: grid;
    align-content: center;
    justify-content: end;
    height: 100%;
    padding-right: ${({ theme }) => theme.space_10};
  }
`;

import styled from 'styled-components';

import { TextLarge } from '@features/core/styles/text.styled';

import { Icon } from '@ui/components/icon';

export const Backdrop = styled.div``;

export const Content = styled.div`
  position: relative;
  padding: ${({ theme }) => theme.space_30} ${({ theme }) => theme.space_15}
    ${({ theme }) => theme.space_15};
  background-color: ${props => props.theme.textMain};
  color: ${props => props.theme.inputDefaultText};
  font-size: ${props => props.theme.fontMD};
  font-stretch: condensed;
  overflow-y: auto;
  max-width: 500px;

  > div {
    padding-right: ${({ theme }) => theme.space_20};
    font-size: ${props => props.theme.fontMD};
    font-weight: ${({ theme }) => theme.fontRegular};
    padding-left: ${({ theme }) => theme.space_10};
  }

  form {
    .form-input-error {
      margin: ${({ theme }) => theme.space_20} 0;

      input {
        height: 32px;
        padding: ${({ theme }) => theme.space_10};
      }
    }
  }
`;

export const ContentIcon = styled(Icon)`
  position: absolute;
  top: ${({ theme }) => theme.space_10};
  right: ${({ theme }) => theme.space_15};
  transform: translateY(100%);
  width: 12px;
  height: 12px;
  color: inherit;
  cursor: pointer;
`;

export const TitleInner = styled.section`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-bottom: ${({ theme }) => theme.space_15};
`;

export const Title = styled.h2`
  margin: 0;
  font-weight: ${({ theme }) => theme.fontBold};
  text-transform: uppercase;
  font-size: ${props => props.theme.fontMD};
  position: relative;
  padding-left: ${({ theme }) => theme.space_10};

  svg {
    display: inline-block;
    position: absolute;
    left: 0;
  }
`;

export const RadioButtonsBlock = styled.div`
  margin-top: ${({ theme }) => theme.space_20};
`;

export const RadioWrapper = styled.div`
  display: flex;
  align-items: center;
  width: 60px;
  cursor: pointer;
`;

export const LimitsContainer = styled.div``;

export const LimitRow = styled(TextLarge)`
  display: grid;
  grid-auto-flow: column;
  align-items: ${({ theme }) => (theme.isDesktop ? 'center' : 'baseline')};
  justify-content: space-between;
  padding: 0 ${props => props.theme.space_10};
  color: ${props => props.theme.highlightWrapperBackground};
  position: relative;

  .errorIcon {
    top: ${({ theme }) => theme.space_10};
  }

  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  input[type='number'] {
    -moz-appearance: textfield;
  }

  .form-input-error input {
    height: 40px;
    font-weight: ${({ theme }) => theme.fontBold};
    font-size: ${props => props.theme.fontMD};
  }

  .error-message {
    right: 0;
    ${({ theme }) => theme.isDesktop && 'bottom: -30px; width: max-content;'}
  }

  .error-message::before {
    content: '';
    border: 7px solid transparent;
    border-bottom: 7px solid ${({ theme }) => theme.inputDefaultText};
    position: absolute;
    right: 12px;
    top: -14px;
    z-index: 1;
  }

  .error-message::after {
    content: '';
    border: 6px solid transparent;
    border-bottom: 6px solid ${({ theme }) => theme.statusError};
    position: absolute;
    right: 13px;
    top: -10px;
    z-index: 2;
  }

  input + div {
    color: ${props => props.theme.textActive};
  }

  .with-errors input + div {
    color: ${props => props.theme.statusError};
  }
`;

import styled from 'styled-components';

import { footerHeight } from '@features/app/layout/footer';

const marginBottom = `-${footerHeight}px`;

export const Wrapper = styled.div<{ height?: string | null }>`
  height: ${({ height }) => height};

  .livematchtracker_category_page_script {
    height: 100%;

    .srt-base-1,
    .sr-bb,
    .sr-loader__container,
    .sr-lmt-plus__comp-wrapper,
    .sr-lmt-plus__comp,
    .sr-lmt-plus__comp-padding {
      height: 100%;
    }
  }
`;

export const TableWrapper = styled.div<{
  tableHasContent: boolean | null;
  nativeApp?: boolean;
}>`
  visibility: ${({ tableHasContent }) =>
    tableHasContent === null ? 'hidden' : 'visible'};
  display: ${({ tableHasContent }) => tableHasContent === false && 'none'};
  min-height: 160px;
  margin-bottom: ${({ nativeApp }) => nativeApp && marginBottom};

  .sr-bb .sr-lmt-plus__segment.srm-scoreboard.srm-compact {
    display: none;
  }

  .sr-bb .sr-lmt-plus__segment.srm-timeline {
    display: none !important;
  }

  .sr-lmt-plus__segment .srm-scoreboard .srm-compact {
    display: none !important;
  }

  .sr-bb .sr-lmt-plus-tabs__wrapper.srm-no-border {
    display: none !important;
  }

  .sr-bb .sr-lmt-plus-tabs {
    display: none !important;
  }
`;

export const TableTitle = styled.div<{
  nativeApp?: boolean;
}>`
  color: ${({ theme }) => theme.textMain};
  text-transform: ${({ theme }) =>
    theme.isDesktop ? 'capitalize' : 'uppercase'};
  font-weight: ${({ theme }) => theme.fontBold};
  background-color: ${({ theme }) =>
    theme.isDesktop ? theme.primaryBackground : theme.main};
  margin-top: ${({ theme, nativeApp }) => !nativeApp && theme.space_20};
  padding: ${({ theme }) => theme.space_10};
`;

import styled from 'styled-components';

import { getFocusStyles } from '@features/core/form/components/textInput/TextInput.styled';

import { Icon } from '@ui/components/icon';

export const Root = styled.div<{ disabled?: boolean }>`
  position: relative;
  ${({ disabled }) => disabled && 'opacity: 0.5'}

  .state-icon {
    right: ${({ theme }) => theme.space_30};
  }
`;

const selectBorder = props => {
  if (props.withoutBorder) {
    return !props.theme.isDesktop
      ? props.theme.borderPrimary
      : props.theme.border;
  }
  return props.theme.borderPrimary;
};

export const smallSelectStyles = theme => `
  height: 32px;
  padding:  ${theme.space_5}  ${theme.space_10};
  font-size: ${theme.fontMD};
`;

export const withLabelSelectStyles = theme => `
  padding: ${theme.space_10} ${theme.space_10} 0px ${theme.space_15};
  font-size: ${theme.fontMD};
`;

export const Select = styled.select<{
  isSmall?: boolean;
  withoutBorder?: boolean | undefined;
  isError?: boolean;
  withLabel?: boolean;
}>`
  height: 48px;
  width: 100%;
  outline: none;
  appearance: none;
  padding: ${({ theme }) => theme.space_10};
  box-sizing: border-box;
  border-radius: 3px;
  border:${props => selectBorder(props)};
  background-color: ${({ theme }) => theme.highlightWrapperBackground};
  font-size: ${({ theme }) => theme.fontLG};
  cursor: pointer;

  ${({ isSmall, theme }) => isSmall && smallSelectStyles(theme)}
  ${({ withLabel, theme }) => withLabel && withLabelSelectStyles(theme)}

  color: ${({ theme, isError }) =>
    isError ? theme.selectTextColor : theme.inputDefaultText};

  &:focus {
    ${({ theme, isError }) => getFocusStyles(theme, isError)}
  }
  
  option {
    color: ${({ theme }) => theme.inputDefaultText};
  }
`;

export const DropIcon = styled(Icon)`
  position: absolute;
  height: 100%;
  right: ${({ theme }) => theme.space_10};
  display: grid;
  grid-auto-flow: column;
  justify-content: center;
  align-items: center;
  pointer-events: none;
  color: ${({ theme }) => theme.inputDefaultText};
`;

export const FloatingLabel = styled.label`
  position: absolute;
  top: ${({ theme }) => theme.space_5};
  left: ${({ theme }) => theme.space_15};
  color: ${props => props.theme.inputDefaultText};
  opacity: 0.65;
  font-size: ${({ theme }) => theme.fontMD};
  pointer-events: none;
  font-weight: ${({ theme }) => theme.fontRegular};
  transition: 0.3s ease;
`;

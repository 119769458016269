import { scrollTo as scrollToLib, ScrollToCustomOptions } from 'scroll-js';
import {
  elementScrollIntoView as elementScrollIntoViewLib,
  windowScroll,
} from 'seamless-scroll-polyfill';
import { IScrollConfig } from 'seamless-scroll-polyfill/src/scroll-step';

export const scrollTo = (
  el: Element | Window,
  options?: ScrollToCustomOptions,
): void => {
  scrollToLib(el, options);
};

export const elementScrollIntoView = (
  element: Element,
  scrollIntoViewOptions?: ScrollIntoViewOptions,
  config?: IScrollConfig,
): void => {
  elementScrollIntoViewLib(element, scrollIntoViewOptions, config);
};

export const scrollTopWindow = (): void => {
  windowScroll(window, {
    top: 0,
    behavior: 'smooth',
  });
};

import { useUserState } from '@common/providers/user/useUserState';
/**
 * isLoggedIn
 * checks if user is registered in shop;
 *
 * @returns {boolean} isLoggedIn
 */
export default (): boolean => {
  return !!useUserState.getState().loadingState;
};

import debounce from 'lodash/debounce';

import {
  searchCasinoAction,
  searchCategoriesAction,
  searchEventsAction,
} from '@common/providers/search/helper';

export const eventCategorySearch = debounce((value: string): void => {
  searchEventsAction(value);
  searchCategoriesAction(value);
}, 1000);

export const casinoGamesSearch = (value: string): void => {
  searchCasinoAction(value);
};

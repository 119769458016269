import styled from 'styled-components';

import { getBorderColor } from '../radioButton/RadioButton.styled';

const CheckboxDisableStyles = `
   opacity: 0.6;
   cursor: auto;
`;

export const Root = styled.div`
  display: flex;
  align-self: flex-start;
`;

export const LabelWrapper = styled.div<{ isDarkTheme: boolean }>`
  align-self: center;
  color: ${({ theme, isDarkTheme }): string =>
    isDarkTheme ? theme.inputDefaultText : theme.textMain};
`;

export const CheckboxWrapper = styled.label`
  position: relative;
  font-size: 0;
  margin-right: ${({ theme }) => theme.space_10};
`;

export const StyledCheckbox = styled.input<{
  isError: boolean;
  isDarkTheme: boolean;
  disabled: boolean;
  checked: boolean;
  isBlueTheme: boolean;
}>`
  box-sizing: border-box;
  outline: none;
  position: relative;
  width: 20px;
  height: 20px;
  appearance: none;
  padding: 0;
  margin: 0;
  border: 1px solid
    ${({ isError, theme, isDarkTheme, checked, isBlueTheme }) =>
      isError
        ? theme.statusError
        : getBorderColor(theme, isDarkTheme, checked, isBlueTheme)};
  border-radius: 2px;

  ${({ disabled }) => disabled && CheckboxDisableStyles};
`;

export const IconWrapper = styled.div<{
  checked: boolean;
  isDarkTheme: boolean;
  isBlueTheme: boolean;
}>`
  position: absolute;
  top: 0;
  left: 0;
  width: 20px;
  height: 20px;
  background-color: ${({ theme, isDarkTheme, checked, isBlueTheme }) =>
    getBorderColor(theme, isDarkTheme, checked, isBlueTheme)};
  border-color: ${({ theme, isDarkTheme, checked, isBlueTheme }) =>
    getBorderColor(theme, isDarkTheme, checked, isBlueTheme)};
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 2px;
  opacity: ${({ checked }) => (checked ? '1' : '0')};
`;

import services from '@features/core/services';
import { PageName, StaticPages } from '@features/core/routing/linkAliases';

import { IS_NATIVE_VIEW, IS_WEB_VIEW } from '@common/constants/cookie';
import { isMatchWebRoutes } from '@common/providers/router/helper';
import { useRouterState } from '@common/providers/router/useRouterState';

/**
 * isStaticPages
 *
 * @returns {boolean} isStaticPages
 */
export const isStaticPages = (): boolean => {
  const { currentWebRoute } = useRouterState.getState();
  return currentWebRoute?.route && StaticPages[currentWebRoute?.route];
};

/**
 * isEventDetailPages
 *
 * @returns {boolean} isEventDetailPages
 */
export const isEventDetailPages = (): boolean => {
  const { currentWebRoute } = useRouterState.getState();
  return (
    !!currentWebRoute?.queryParams?.events ||
    currentWebRoute?.route === PageName.EVENTS_ITEM
  );
};

/**
 * isAccountPages
 *
 * @param {string} pathname
 * @returns {boolean} isAccountPages
 */
export const isAccountPages = (pathname: string): boolean =>
  (pathname || '').search(/user/) !== -1;

/**
 * isNativeApp
 *
 * @param {string} search
 * @returns {boolean} isNativeApp
 */
export const isNativeApp = (search: string): boolean => {
  return (
    !!services.cookie?.get(IS_NATIVE_VIEW) ||
    ((search || '').search(/inapp=true/) !== -1 &&
      (search || '').search(/cy=true/) === -1)
  );
};

/**
 * isIframe
 *
 * @param {string} search
 * @returns {boolean} isIframe
 */
export const isIframe = (search: string): boolean =>
  (search || '').search(/in_iframe=true/) !== -1;

/**
 * isWebView
 *
 * @param {string} search
 * @returns {boolean} isWebView
 */
export const isWebView = (search: string): boolean => {
  return (
    !!services.cookie.get(IS_WEB_VIEW) ||
    (search || '').search(/web_view=true/) !== -1
  );
};

/**
 * isWithDrawalPage
 *
 * @returns {boolean} isWithDrawalPage
 */
export const isWithDrawalPage = (): boolean => {
  return isMatchWebRoutes([
    PageName.USER_PAYOUT,
    PageName.USER_PAYOUT_BANK_AMOUNT,
    PageName.USER_PAYOUT_SUCCESS,
    PageName.USER_PAYOUT_FAILURE,
    PageName.USER_PAYOUT_PREPARED,
  ]);
};

import replace from 'lodash/replace';
import { Rule } from 'rc-field-form/lib/interface';

import i18next from '@features/core/translation';
import Patterns from '@features/core/form/utils/patterns';

import incorrectCharactersValidator from '@common/helpers/formsHelpers/validation/incorrectCharacters';
import firstLetterRule from '@common/helpers/formsHelpers/validation/firstLetterRule';

const birthPlacePreset = (): Rule[] => [
  {
    required: true,
    message: `${i18next.t('registration.step2.place_of_birth_required')}`,
  },
  {
    min: 2,
    message: replace(
      i18next.t('common.presets.min_length_characters'),
      '@',
      '2',
    ),
  },
  {
    validator: firstLetterRule,
  },
  {
    validator: incorrectCharactersValidator(
      Patterns.INCORRECT_SYMBOLS,
      i18next.t('registration.incorrect_characters'),
    ),
  },
];

export default birthPlacePreset;

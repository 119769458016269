import services from '@features/core/services';

import fetchActiveBonus from '@common/api/user/fetchActiveBonus';
import fetchBonusList from '@common/api/user/fetchBonusList';
import {
  setActiveBonus,
  setBonusList,
  setBonusLoading,
} from '@common/providers/bonus/useBonus';

export const getBonusList = async (): Promise<void> => {
  setBonusLoading();
  try {
    const response = await fetchBonusList();
    if (response) {
      setBonusList(response.result?.bonuses);
    }
  } catch (e) {
    services.logger.error(e as string);
  }
};

export const getActiveBonus = async (): Promise<void> => {
  setBonusLoading();
  try {
    const response = await fetchActiveBonus();
    if (response) {
      setActiveBonus(response.result?.bonus);
    }
  } catch (e) {
    services.logger.log(e as string);
  }
};

/* eslint sonarjs/prefer-immediate-return: 0 */
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import {
  hasBreakPeriod,
  getBreakPeriod,
  getCurrentPeriod,
  isPeriodStarted,
  isFinished,
  getCurrentPeriodID,
  isPenalty,
  isOvertime,
} from '@common/helpers/eventsHelper/scoreboards/timerModel';
import { EventListTypes } from '@common/interfaces';

import {
  IWrappedComponentProps,
  IWithDefaultLogicProps,
} from './WithDefaultLogic.types';

/**
 * inject properties 'message' and 'showTime'. Default timer structure is 'Message</br>Time'.
 * property 'message' signals that timer have special value, like 'Not started', 'End' etc.
 * property 'showTime' signalise when show/hide Time
 *
 */

const withDefaultLogic = (
  WrappedComponent: React.FC<IWrappedComponentProps>,
): ((props) => JSX.Element) => {
  const WithDefaultLogic = (props: IWithDefaultLogicProps): JSX.Element => {
    const { timer, listType, shortTimer, category } = props;
    const { t } = useTranslation();
    const extraProps = useMemo(() => {
      const logic = {
        message: '',
        showTime: true,
      };
      if (hasBreakPeriod(timer)) {
        logic.message = getBreakPeriod(timer, shortTimer);

        logic.showTime = false;
      } else if (isPeriodStarted(timer)) {
        if (isFinished(timer) && listType === EventListTypes.live) {
          logic.message = t('helpers.end');
          logic.showTime = false;
        } else if (isOvertime(timer)) {
          logic.message = getCurrentPeriod(timer, shortTimer, category);
          logic.showTime = true;
        } else if (
          getCurrentPeriodID(timer) === 'HT' ||
          isPenalty(timer) ||
          isFinished(timer)
        ) {
          logic.message = getCurrentPeriod(timer, shortTimer, category);
          logic.showTime = false;
        }
      } else {
        logic.showTime = false;
      }
      return logic;
    }, [
      timer.period_id,
      timer.period_idx,
      timer.period_long,
      timer.period_short,
      timer.running,
    ]);
    return <WrappedComponent {...props} {...extraProps} />;
  };

  return WithDefaultLogic;
};

export default withDefaultLogic;

import React from 'react';

export enum INotificationIcon {
  success = 'success',
  warning = 'warning',
  error = 'error',
  base = 'base',
}

export enum INotificationStyle {
  noFavoriteNotification = 'noFavoriteNotification',
  footerDynamicNotification = 'footerDynamicNotification',
  detailEventNotification = 'detailEventNotification',
  forgotPasswordNotification = 'forgotPasswordNotification',
  bonusSectionNotification = 'bonusSectionNotification',
  registrationSingleNotification = 'registrationSingleNotification',
  paymentApiNotification = 'paymentApiNotification',
  paymentFormNotification = 'paymentFormNotification',
  limitLugasNotification = 'limitLugasNotification',
  documentUploadNotification = 'documentUploadNotification',
  registrationFormNotification = 'registrationFormNotification',
  bonusNotifierNotification = 'bonusNotifierNotification',
  paymentScreenNotification = 'paymentScreenNotification',
  depositLimitNotification = 'depositLimitNotification',
  shopWalletNotification = 'shopWalletNotification',
  selfExclusion = 'selfExclusion',
  lugasHigherLimitInfo = 'lugasHigherLimitInfo',
}

export interface INotificationProps {
  iconType?: keyof typeof INotificationIcon;
  styleType?: keyof typeof INotificationStyle;
  title?: string;
  onClose?(): void;
  withoutIcon?: boolean;
  severalChildren?: boolean;
  children?: string | React.ReactNode;
  additionalText?: string | React.ReactNode;
  closeHandle?: string | React.ReactNode;
}

export type IIconType = 'check-circle' | 'exclamation-circle' | 'info-circle';

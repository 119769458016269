import assign from 'lodash/assign';
import reduce from 'lodash/reduce';

import Route from '@features/core/router/IRoute.types';
import { modules } from '@features/core/modules';

export const langCodes = '(de|en|el|es|tr|ru|fr|sq)';

export const lang = `/:lang${langCodes}`;

const routes: { [key: string]: Route } = {
  ...reduce(modules, (acc, e) => assign(acc, e.routes), {}),
};

export default routes;
export { default as Routes } from '@features/core/router/Routes';

import includes from 'lodash/includes';

import { StaticCalls } from '@common/api/api';
import {
  ICustomError,
  IFetchSetLimitResponse,
  ILimit,
} from '@common/interfaces';
import hasLugasRestriction from '@common/helpers/userHelper/hasLugasRestrictions';
import { apiHandler, MethodType } from '@common/api/apiHandler';
import { setGlobalLimit } from '@common/api/limits/fetchGlobalLimit';
import { ISetLimitPayload } from '@common/providers/limits/types';

const { API_PLAYER_LIMITS } = StaticCalls;

const fetchSetLimit = async ({
  accountId,
  token,
  period,
  amount,
  limitType,
  prevValue,
  isSchufaAccept,
}: ISetLimitPayload): Promise<
  ((IFetchSetLimitResponse | ILimit) & ICustomError) | null
> => {
  if (
    (includes(limitType, 'global') && hasLugasRestriction()) ||
    isSchufaAccept
  ) {
    return setGlobalLimit(amount, prevValue, limitType, isSchufaAccept);
  }

  return apiHandler<IFetchSetLimitResponse>(
    {
      method: MethodType.Put,
      params: {
        accountId,
        token,
        period,
        amount,
      },
    },
    {
      apiUrl: API_PLAYER_LIMITS,
    },
  );
};

export default fetchSetLimit;

import styled from 'styled-components';

import { TextNormal } from '@features/core/styles/text.styled';

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin: 0 ${({ theme }) => theme.space_10} ${({ theme }) => theme.space_10} 0;
  border-radius: 5px;
`;

export const TextWrapper = styled.div``;

export const BetPackerLeftSideWrapper = styled.div`
  display: grid;
  grid-template-columns: 20px 1fr;
`;

export const MarketWrapper = styled.div`
  margin: ${({ theme }) => theme.space_5} 0;
`;
export const MarketType = styled(TextNormal)`
  color: ${props => props.theme.textMain};
`;
export const MarketName = styled.span`
  color: ${props => props.theme.textMain};
  font-weight: ${({ theme }) => theme.fontBold};
`;

export const BetSpecialValue = styled.span`
  margin-left: ${({ theme }) => theme.space_5};
`;

export const LabelWrapper = styled.div`
  display: flex;
  flex-direction: row;
  margin-left: ${({ theme }) => theme.space_10};
`;
export const BetpackerIconWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-right: ${({ theme }) => theme.space_5};
`;

export const BetpackerWrapper = styled.span`
  margin-left: ${({ theme }) => theme.space_5};
  color: ${props => props.theme.textMain};
  font-size: ${props => props.theme.fontMD};
  font-weight: ${({ theme }) => theme.fontBold};
`;

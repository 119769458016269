import services from '@features/core/services';

import { getTestList } from '@common/helpers/deviceUtil';
import { IGetBetsPayload } from '@common/providers/bets/types';
import { DynamicCalls } from '@common/api/api';
import { apiHandler, MethodType } from '@common/api/apiHandler';
import { IUserBetsResponse } from '@common/interfaces';

const { API_BETS, API_FAKE_BETS_UNWRAPED } = DynamicCalls;

/**
 * fetchBets
 * loads all bets
 * @param offset {Number} offset?
 * @param items {Number} how many bets
 * @param maxAgeDays {Number|String} number or meta word "all"
 * @param settled {String} bet status
 * @param token {String}
 * @param session {String}
 * @returns {AxiosPromise<IUserBetsResponse>}
 */
const fetchBetsUnwraped = async ({
  offset = 0,
  items = 20,
  maxAgeDays,
  settled,
}: IGetBetsPayload): Promise<IUserBetsResponse> => {
  const testList = getTestList();

  if (testList) {
    services.logger.info('BETS - USING TESTING LIST', testList);
    return apiHandler<IUserBetsResponse>(
      { method: MethodType.Get },
      {
        apiUrl: API_FAKE_BETS_UNWRAPED,
        apiData: {
          dynamicTestList: testList,
        },
      },
    );
  }
  const params = {
    max_age_days: maxAgeDays,
    rows: items,
    settled,
    offset,
  };

  return apiHandler<IUserBetsResponse>(
    { method: MethodType.Get, params },
    {
      apiUrl: API_BETS,
      apiData: {
        dynamicLanguage: services.domainLang,
      },
    },
  );
};

const fetchBets = fetchBetsUnwraped;

export default fetchBets;

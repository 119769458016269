import styled from 'styled-components';

const getMobileStyles = theme => `
  background-color: ${theme.textActive};
  margin: ${theme.space_10} 0;
`;

const getSearchContainerExtra = theme => `
  ${!theme.isDesktop && getMobileStyles(theme)};

  input {
    height: 34px;
  }
`;

export const SearchContainer = styled.div<{ isHeader?: boolean }>`
  display: grid;
  align-items: start;

  input {
    padding: 0 ${({ theme }) => theme.space_10};
    height: 35px;
    ${({ theme }) =>
      theme.isDesktop && 'font-style: italic; border-radius: 5px;'};
  }

  padding: ${({ theme }) => !theme.isDesktop && theme.space_10};

  ${({ isHeader, theme }) => !isHeader && getSearchContainerExtra(theme)};
`;

export const Title = styled.span`
  color: ${props => props.theme.textMain};
  margin: ${({ theme }) => theme.space_5} 0 ${({ theme }) => theme.space_10};
`;

import React from 'react';
import { useTranslation } from 'react-i18next';
import { useTheme } from 'styled-components';

import { StyledButton } from '@features/events/components/prediction';

import { Icon } from '@ui/components/icon';

const DisabledPrediction = (): React.ReactElement => {
  const { t } = useTranslation();
  const buttonName = t('common.buttons.lock');
  const { selectTextColor } = useTheme();

  return (
    <StyledButton
      className="disabled"
      aria-label={buttonName}
      style={{ justifyContent: 'center' }}
    >
      <Icon
        width="12"
        height="13.6"
        name="lock-outlined"
        fill={selectTextColor}
      />
    </StyledButton>
  );
};

export default DisabledPrediction;

import { ISO_DATE_TEMPLATE } from '@features/core/form/components/dateInput/DateTemplates';

import formatDate from '@common/helpers/timeHelper/localizedFormat';
import convertToTimeZone from '@common/helpers/timeHelper/convertToTimeZone';
import convertDateToTimestamp from '@common/helpers/timeHelper/convertDateToTimestamp';

const addLocaleTimeFromDateString = (
  dateString: string,
): { localeZoneTimestamp: string; localeZoneDate: Date } => {
  const timestamp = convertDateToTimestamp(dateString);
  const date = convertToTimeZone(timestamp);

  return {
    localeZoneTimestamp: formatDate(date, ISO_DATE_TEMPLATE),
    localeZoneDate: date,
  };
};

export default addLocaleTimeFromDateString;

import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Rule } from 'rc-field-form/lib/interface';
import map from 'lodash/map';
import set from 'lodash/set';
import { useTheme } from 'styled-components';

import { LugasDepositInfo } from '@features/registration/components/lugasDepositInfo';
import { RegistrationFormItem } from '@features/registration/components/registrationFormItem';
import { RadioButton } from '@features/core/form/components/radioButton';
import { InfoTooltip } from '@features/core/form/components/infoTooltip';
import services from '@features/core/services';
import { TextNormal } from '@features/core/styles/text.styled';

import { DEPOSIT_LIMITS } from '@common/constants/config';
import makeLimitPerMonthPreset from '@common/helpers/formsHelpers/validation/limitPerMonthPreset';
import makeRegistrationStepConfig from '@common/helpers/formsHelpers/registration/makeRegistrationStepConfig';
import { isDesktopView } from '@common/helpers/deviceUtil';
import preventTypingSpecialSymbols from '@common/helpers/registrationHelper/preventTypingSpecialSymbols';
import {
  removeNumSeparators,
  addNumSeparators,
} from '@common/helpers/formsHelpers/registration/numSeparators';

import { Icon } from '@ui/components/icon';

import TextInput from '../textInput/TextInput';

import * as S from './FormRegisterLimit.styled';
import { IFormRegisterLimit } from './FormRegisterLimit.types';

const MAX_VALUE = services.config.get(DEPOSIT_LIMITS) as number;
const MIN_VALUE = 1;
const MAX_LIMIT = 30000;

const FormRegisterLimit: React.FC<IFormRegisterLimit> = ({
  form,
  hideUseMaxLimitCheckbox,
}) => {
  const [dialogOpen, setDialogOpen] = useState<boolean>(false);
  const [limitsOpen, setLimitsOpen] = useState<boolean>(false);
  const { t } = useTranslation();
  const { iconFillLight } = useTheme();
  const [radio, setRadio] = useState(0);
  const [tooltipOpen, setTooltipOpen] = useState(false);
  const configRules = makeRegistrationStepConfig(form);
  const limitValuePreset = makeLimitPerMonthPreset({
    min: MIN_VALUE,
    max: MAX_VALUE,
    globalErrLimitMsg: 'global_',
    required: true,
  });
  const limitTitle = isDesktopView()
    ? t('registration.step4.limit_section_title_desk')
    : t('registration.step4.limit_section_title');
  const onClickLimitsDialogLinkHandler = (): void => {
    return !isDesktopView()
      ? setDialogOpen(!dialogOpen)
      : setTooltipOpen(!tooltipOpen);
  };

  const limitValidationRules = map(limitValuePreset, (rule: Rule) => {
    set(rule, 'transform', value => removeNumSeparators(value));
    return rule;
  });

  const onChangeLimit = (e): void => {
    if (Number(removeNumSeparators(e.target.value)) > MAX_LIMIT) {
      setTimeout(() => {
        form.setFieldValue(
          'deposit_monthly_limit',
          addNumSeparators(String(MAX_LIMIT)),
        );
      }, 0);
    }
  };

  useEffect(() => {
    if (radio) {
      if (radio === 1 || radio === 2) {
        form.resetFields(['deposit_monthly_limit']);
      }
      setLimitsOpen(radio === 3);
      form.setFieldsValue({
        radiobtn: radio,
      });
      form.validateFields(['radiobtn']);
    }
  }, [radio, form]);

  useEffect(() => {
    const el = document?.getElementById('slidersSection');
    if (el && limitsOpen) {
      el.style.backgroundSize = 'cover';
    }
  }, [document, limitsOpen]);

  return (
    <>
      <S.FormRegisterLimitWrapper>
        <S.LimitsDialogLink
          onClick={onClickLimitsDialogLinkHandler}
          data-qa="register-step4-btn-showLimits"
        >
          <S.LimitTitle>{limitTitle}</S.LimitTitle>
          <Icon
            name="info-circle-solid"
            fill={iconFillLight}
            width="28px"
            height="28px"
          />
        </S.LimitsDialogLink>

        {dialogOpen && !isDesktopView() && (
          <LugasDepositInfo
            title={t('registration.step4.limit.title')}
            text={t('registration.step4.limit.main_text')}
            onClose={(): void => setDialogOpen(false)}
          />
        )}

        {tooltipOpen && isDesktopView() && (
          <>
            <S.ActiveArrow />
            <InfoTooltip
              title={t('registration.step4.limit.title')}
              text={t('registration.step4.limit.main_text')}
              onClose={(): void => setTooltipOpen(false)}
              className="limit-tooltip"
            />
          </>
        )}

        <S.LimitSubTitle
          dangerouslySetInnerHTML={{
            __html: t('registration.step4.limit_section_subtitle'),
          }}
        />

        <S.RadioWrapper onClick={(): void => setRadio(1)}>
          <RegistrationFormItem
            name="radiobtn"
            rules={configRules.radiobtn.rules}
            noErrorMessages
          >
            <RadioButton
              value="provider_limit"
              title={t('registration.step4.limit_checkbox_1')}
              checked={radio === 1}
              isBottomMargin
            />
          </RegistrationFormItem>
        </S.RadioWrapper>

        {!hideUseMaxLimitCheckbox && (
          <S.RadioWrapper
            className="radiobtn"
            onClick={(): void => setRadio(2)}
          >
            <RegistrationFormItem
              name="radiobtn"
              rules={configRules.radiobtn.rules}
              noErrorMessages
            >
              <RadioButton
                value="max_limit"
                title={t('registration.step4.limit_checkbox_2')}
                checked={radio === 2}
                isBottomMargin
              />
            </RegistrationFormItem>
          </S.RadioWrapper>
        )}

        <S.RadioWrapper className="radiobtn" onClick={(): void => setRadio(3)}>
          <RegistrationFormItem
            name="radiobtn"
            rules={configRules.radiobtn.rules}
            noErrorMessages
          >
            <RadioButton
              value="individual_limit"
              title={t('registration.step4.limit_checkbox_3')}
              checked={radio === 3}
              isBottomMargin
            />
          </RegistrationFormItem>
        </S.RadioWrapper>
      </S.FormRegisterLimitWrapper>

      {limitsOpen && (
        <S.LimitsContainer id="limit-container">
          <S.LimitRow>
            <RegistrationFormItem
              name="deposit_monthly_limit"
              initialValue=""
              rules={limitValidationRules}
              isFloatErrorArrowLeft={false}
              normalize={addNumSeparators}
            >
              <TextInput
                maxLength={10}
                autoFocus
                disabled={false}
                placeholder={t('registration.step4.deposit_monthly_limit')}
                data-qa="register-step4-input-limitPerMonth"
                id="deposit_monthly_limit"
                sufix="€"
                inputType="text"
                pattern="[0-9.,]*"
                inputMode="numeric"
                step="10"
                clearInitialValue
                onKeyDown={preventTypingSpecialSymbols}
                onChange={onChangeLimit}
              />
            </RegistrationFormItem>
            <S.InputInfoWrapper>
              <Icon name="info-circle" width="17" height="17" />
              <TextNormal>
                {t('registration.step4.limits_open_info_section')}
              </TextNormal>
            </S.InputInfoWrapper>
          </S.LimitRow>
        </S.LimitsContainer>
      )}
    </>
  );
};

export default FormRegisterLimit;

import replace from 'lodash/replace';
import i18next from 'i18next';

import { StaticCalls } from '@common/api/api';
import {
  ICustomError,
  csrfTokenResponse,
  FetchLoginParams,
  FetchLoginResult,
} from '@common/interfaces';
import { apiHandler, MethodType } from '@common/api/apiHandler';
import { isWebView } from '@common/helpers/links';

const { API_TOKENS, API_LOGIN, API_LOGIN_APP } = StaticCalls;

const fetchLogin = async (
  params: FetchLoginParams,
): Promise<FetchLoginResult & ICustomError> => {
  const { csrfSec, csrfToken } = await apiHandler<csrfTokenResponse>(
    { method: MethodType.Get },
    { apiUrl: API_TOKENS },
  );
  const headers = {
    'CSRF-TOKEN': replace(`${csrfToken}-${btoa('55')}`, '=', ''),
    'CSRF-SEC': csrfSec,
  };

  const data = await apiHandler<FetchLoginResult>(
    {
      method: MethodType.Post,
      headers,
      data: {
        id: 'jsonrpc',
        jsonrpc: '2.0',
        method: 'user.login_confirm',
        params,
        reload: true,
      },
    },
    { apiUrl: isWebView(window.location.search) ? API_LOGIN_APP : API_LOGIN },
  );

  if (parseFloat(data.code || '') === 2349) {
    data.message = `${i18next.t('user.loginPage.err_user')}`;
  }
  if (
    parseFloat(data.code || '') === 2023 ||
    parseFloat(data.code || '') === 404 ||
    parseFloat(data.code || '') === 403
  ) {
    data.message = `${i18next.t('user.loginPage.err_ip')}`;
  }
  return data;
};

export default fetchLogin;

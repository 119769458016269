import React from 'react';
import { useTranslation } from 'react-i18next';
import reduce from 'lodash/reduce';
import includes from 'lodash/includes';
import concat from 'lodash/concat';

import { SelectInput } from '@features/core/form/components/selectInput';

import { getDropDownMarkets } from '@common/helpers/markets/marketTypes';
import { IEvent, PageType, IMarketType } from '@common/interfaces';
import {
  setMarketsKeysItem,
  useEventsListState,
} from '@common/providers/events/eventList/useEventsList';

import * as S from './DropDownMarkets.styled';

const DropDownMarkets = (props: {
  className: string;
  event: IEvent;
  pageType: PageType;
  top_category_id: string;
  sport_header_id: string;
  index: number;
}): React.ReactElement => {
  const {
    className,
    event,
    pageType,
    top_category_id,
    sport_header_id,
    index,
  } = props;
  const { t } = useTranslation();

  const marketsKeysSets = useEventsListState(
    state => state.live.marketsKeysSets,
  );

  const {
    fullTimeMarketKeys,
    halfTimeMarketKeys,
    penaltyMarketKeys,
  } = marketsKeysSets[sport_header_id] || {
    fullTimeMarketKeys: [],
    halfTimeMarketKeys: [],
    penaltyMarketKeys: [],
  };

  const dropDownMarketsList = getDropDownMarkets(
    event,
    pageType,
    top_category_id,
  );

  const handleChange = (e): void => {
    e.preventDefault();
    const { value } = e.target;
    const updated = [...fullTimeMarketKeys];
    const updatedHalftimes = [...halfTimeMarketKeys];
    const updatedPenalty = [...penaltyMarketKeys];
    updated[index] = value;
    if (halfTimeMarketKeys.length) {
      /* `${value}_HT is suitable for current required halfTime dropdown markets.
      probably in future should reworked to something like mapOptionToHalfTimeMarket
       */
      updatedHalftimes[index] = `${value}_HT` as IMarketType;
    }
    setMarketsKeysItem({
      key: sport_header_id,
      val: {
        fullTimeMarketKeys: updated,
        halfTimeMarketKeys: updatedHalftimes,
        penaltyMarketKeys: updatedPenalty,
      },
    });
  };

  const val = fullTimeMarketKeys[`${index}`];
  return (
    <S.SwitcherWrapper className={className}>
      <SelectInput isSmall onChange={handleChange} value={val}>
        {reduce(
          dropDownMarketsList,
          (acc, item) => {
            const alreadyUsed =
              includes(fullTimeMarketKeys, item) && item !== val;
            if (alreadyUsed) {
              return acc;
            }
            return concat(
              acc,
              <option key={item} value={item}>
                {t(`header_market_keys.${item}`)}
              </option>,
            );
          },
          [] as JSX.Element[],
        )}
      </SelectInput>
    </S.SwitcherWrapper>
  );
};

export default DropDownMarkets;

import { RuleObject } from 'rc-field-form/es/interface';

import CustomError from '@features/core/error/error';
import i18next from '@features/core/translation';

import { FieldDecorator } from '@common/interfaces';
import generateUsername from '@common/api/registration/generateUsername';

export const getDocumentUploadFieldsDecorators = (): FieldDecorator => {
  const defaultOptions = {
    validateTrigger: 'onChange',
  };
  return {
    attachment: {
      ...defaultOptions,
      rules: [{ required: true }],
    },
    terms_agree: {
      valuePropName: 'checked',
      rules: [
        {
          validator: (
            rule: RuleObject,
            value: string | null,
            callback: (error?: string) => void,
          ): void => {
            return value ? callback() : callback(rule.message as string);
          },
          message: 'required',
        },
      ],
    },
  };
};

// debounce dont work with promise-like validator so i use callback
export const usedLoginValidator = form => async (
  _,
  __,
  callback,
): Promise<void> => {
  const res = await generateUsername({
    login: form.getFieldValue('login'),
    email: encodeURIComponent(form.getFieldValue('email')),
  });

  if (res instanceof CustomError) {
    callback(res.message);
  }

  if (res?.user_exists) {
    callback(i18next.t('registration.step1.userAlreadyExist'));
  } else {
    callback();
  }
};

import React, { useState } from 'react';
import replace from 'lodash/replace';
import noop from 'lodash/noop';
import Observer from 'react-intersection-observer';
import ProgressiveImage from 'react-progressive-image';

import getImageBasePath from '@common/helpers/getImageBasePath';
import useIsMounted from '@common/hooks/useIsMounted';

import { IImageProps } from './Image.types';

const generateImagePath = (src: string): string => {
  return replace(src, '', '');
};

const styles = {
  loadingImage: {
    width: 'auto',
    display: 'block',
    margin: '0 auto',
  },
};

const Image: React.FC<IImageProps> = props => {
  const {
    threshold = 0,
    src,
    alt = 'image',
    title,
    className,
    role = 'presentation',
    placeholder = getImageBasePath('/images/loading.svg'),
    style = {},
    triggerOnce = true,
    width,
    height,
    delay = 0,
    lazyLoading = true,
    fallbackImage,
    onClick = noop,
  } = props;

  const isMounted = useIsMounted();
  const [isLoadingError, setLoadingError] = useState(false);

  const generatedSrc = generateImagePath(src);
  if (!isMounted) {
    return null;
  }
  if (isLoadingError && fallbackImage) {
    return (
      <div>
        <img
          style={style}
          src={generateImagePath(fallbackImage)}
          alt={alt}
          className={className}
        />
      </div>
    );
  }
  if (isLoadingError && !fallbackImage) {
    return <div dangerouslySetInnerHTML={{ __html: alt || '' }} />;
  }
  if (lazyLoading) {
    return (
      <Observer
        threshold={threshold}
        triggerOnce={triggerOnce}
        rootMargin="100px"
      >
        {({ inView, ref }): React.ReactElement => (
          <div ref={ref}>
            {inView && (
              <ProgressiveImage
                src={generatedSrc}
                placeholder={placeholder || ''}
                delay={delay}
                onError={(): void => setLoadingError(true)}
              >
                {(progressiveSrc, loading): React.ReactElement => (
                  // eslint-disable-next-line
                  <img
                    src={progressiveSrc.slice(-generatedSrc.length)}
                    alt={alt}
                    title={title}
                    role={role}
                    className={className}
                    style={loading ? styles.loadingImage : style}
                    height={height}
                    width={width}
                    onClick={onClick}
                    loading="lazy"
                  />
                )}
              </ProgressiveImage>
            )}
          </div>
        )}
      </Observer>
    );
  }
  return (
    <img
      src={generatedSrc}
      alt={alt}
      title={title}
      role={role}
      className={className}
      style={style}
      height={height}
      width={width}
    />
  );
};

export default Image;

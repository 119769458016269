import styled from 'styled-components';

import { hexToRgba } from '@common/helpers/deviceUtil';

import { Icon } from '@ui/components/icon';

export const SwitcherWrapper = styled.div`
  ${(props): string =>
    props.theme.isDesktop ? 'min-width: 160px;' : 'width: 50%; margin: 0 auto;'}
`;

export const Select = styled.div`
  display: grid;
  grid-auto-flow: row;
  cursor: pointer;
  position: relative;
  box-sizing: border-box;
  height: 32px;
  color: ${({ theme }) => hexToRgba(theme.inputDefaultText, 0.75)};
  border-radius: 3px;
  background-color: ${({ theme }) => theme.highlightWrapperBackground};

  & div {
    background-color: ${({ theme }) => theme.highlightWrapperBackground};
    width: 100%;
    box-sizing: border-box;
  }

  .select {
    border-radius: 3px;
    padding: ${({ theme }) => theme.space_5} ${({ theme }) => theme.space_10};
    align-self: center;
  }

  .list-opened {
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
  }

  .lngList {
    position: absolute;
    top: ${({ theme }) => theme.space_30};
    display: grid;
    grid-auto-flow: row;
    border-bottom-right-radius: 3px;
    border-bottom-left-radius: 3px;

    & #lngBtn {
      padding: ${({ theme }) => theme.space_5} ${({ theme }) => theme.space_10};
      border-bottom-right-radius: 3px;
      border-bottom-left-radius: 3px;
      color: ${({ theme }) =>
        hexToRgba(theme.inputDefaultText, 0.75)} !important;
    }

    & #lngBtn:hover {
      background-color: ${({ theme }) => theme.highlightWrapperBackground};
      color: ${({ theme }) =>
        hexToRgba(theme.inputDefaultText, 0.75)} !important;
    }
  }
`;
export const DropIcon = styled(Icon)`
  position: absolute;
  height: 100%;
  right: ${({ theme }) => theme.space_10};
  top: 0;
  display: grid;
  grid-auto-flow: column;
  justify-content: center;
  align-items: center;
  pointer-events: none;
  color: ${(props): string => props.theme.main};
`;

import styled from 'styled-components';

const getWithBackgroundStyles = (bgColor, theme) =>
  `background: ${bgColor}; padding: ${theme.space_15} ${theme.space_10} ${theme.space_15};`;

// eslint-disable-next-line import/prefer-default-export
export const ButtonContainer = styled.div<{
  withBackground?: boolean;
  disabled?: boolean;
}>`
  ${({ theme }) => !theme.isDesktop && 'margin-bottom:5px;'};
  ${({ theme, withBackground }) =>
    !theme.isDesktop &&
    withBackground &&
    getWithBackgroundStyles(theme.secondary, theme)};
  ${({ theme, withBackground }) =>
    theme.isDesktop &&
    withBackground &&
    getWithBackgroundStyles(theme.textActive, theme)};

  & .button-locked {
    display: grid;
    justify-content: space-between;
    padding: ${({ theme }) => theme.space_5} ${({ theme }) => theme.space_10};
  }

  &.is-waiting {
    button {
      background-color: ${({ theme }) => theme.textMain};
      border: ${({ theme }) => theme.borderPrimary};
      justify-content: center;
      cursor: none;
    }
  }

  &.white-btn {
    display: grid;
    grid-template-columns: 1fr 42px;
    pointer-events: none;
    grid-gap: ${({ theme }) => theme.space_10};

    button:not(.lock-icon-btn) {
      color: ${({ theme }) => theme.main};
      background-color: ${({ theme }) => theme.textMain};
    }

    & .with-icon {
      display: grid;
      grid-gap: 2px;

      span {
        display: block;
        order: 2;
      }

      svg {
        display: block;
        order: 1;
        margin: 0 !important;
      }
    }

    & .button-locked:not(.lock-icon-btn) {
      ${({ disabled }) => disabled && 'opacity: 0.6;'}
    }
  }
`;

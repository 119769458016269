import { useEffect } from 'react';

import services from '@features/core/services';

import { DISABLE_ANIMATION } from '@common/constants/cookie';
import { isDesktopView } from '@common/helpers/deviceUtil';
import { testCPU } from '@common/helpers/appLayoutHelper';

const useDisableAnimation = (): void => {
  useEffect(() => {
    if (!isDesktopView() && !services.cookie.get(DISABLE_ANIMATION)) {
      services.cookie.set(DISABLE_ANIMATION, testCPU() > 0.1 ? '1' : '0', {
        expires: null,
      });
    }
  }, []);
};

export default useDisableAnimation;

import React from 'react';
import { Trans } from 'react-i18next';

import StyledCustomLink from './TransCustomLink.styled';
import ITransCustomLink from './TransCustomLink.types';

const TransCustomLink: React.FC<ITransCustomLink> = props => {
  const { text, href, ariaLabel } = props;

  return (
    <Trans i18nKey={text}>
      <StyledCustomLink isNewBlank href={href} ariaLabel={ariaLabel}>
        link
      </StyledCustomLink>
    </Trans>
  );
};

export default TransCustomLink;

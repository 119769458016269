import styled from 'styled-components';

import { hexToRgba } from '@common/helpers/deviceUtil';

import { Icon } from '@ui/components/icon';

const backdropBg = ({ theme }) =>
  `background-color: ${hexToRgba(theme.textTertiary, 0.5)}`;

export const Backdrop = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  ${({ theme }) => !theme.isDesktop && backdropBg};
  z-index: 101;
  height: 100vh;
  width: 100%;
`;

const mobileStyles = `
box-sizing: border-box;
height: 100vh;
width: 100%;
overscroll-behavior: contain;
overscroll-behavior-y: none;
min-height: -webkit-fill-available;
`;

const getContentDivStyles = ({ theme }) => {
  return theme.isDesktop
    ? `${theme.space_5} ${theme.space_20} 0 0`
    : `${theme.space_5} 0 0 0`;
};

export const Content = styled.div`
  position: absolute;
  height: calc(100% - 45px);
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: ${({ theme }) => theme.space_25} ${props => props.theme.space_10}
    ${({ theme }) => theme.space_20};
  background-color: ${props => props.theme.textMain};
  color: ${props => props.theme.inputDefaultText};
  font-size: ${props => props.theme.fontMD};
  font-stretch: condensed;
  overflow-y: ${({ theme }) => (theme.isDesktop ? 'hidden' : 'scroll')};

  ${({ theme }) => !theme.isDesktop && mobileStyles};

  div {
    margin: 0 0 ${({ theme }) => theme.space_20};
    font-size: ${props => props.theme.fontMD};
    font-weight: ${({ theme }) => theme.fontRegular};
    color: inherit;
    padding: ${getContentDivStyles};
  }
`;

export const ContentIconWrapper = styled.div`
  min-width: 30px;
  min-height: 30px;
  position: absolute;
  top: ${({ theme }) => theme.space_10};
  right: ${({ theme }) => theme.space_15};
  cursor: pointer;
  display: grid;
  justify-content: end;
  align-content: space-evenly;
`;

export const ContentIcon = styled(Icon)`
  width: 12px;
  height: 12px;
  color: inherit;
`;

export const Title = styled.h2`
  margin: 0 0 ${({ theme }) => theme.space_10};
  font-weight: ${({ theme }) => theme.fontBold};
  font-size: inherit;
`;

import React, { useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import map from 'lodash/map';
import keys from 'lodash/keys';
import reduce from 'lodash/reduce';
import forEach from 'lodash/forEach';

import { elementScrollIntoView } from '@common/helpers/scrollHelper';
import {
  detailedEventMarketGroups,
  getType,
  isUnivMarketNotFitForPeriod,
} from '@common/helpers/markets/marketModel';
import { DEFAULT_EVENT_MARKET_TYPE } from '@common/providers/events/liveCategories/state';
import {
  setMarketFilterKey,
  useEventsListState,
} from '@common/providers/events/eventList/useEventsList';

import { IEventMarketsFilter } from './EventMarketsFilter.types';
import * as S from './EventMarketsFilter.styled';

const EventMarketsFilter = ({
  listType,
  sportType,
  marketsToRender,
}: IEventMarketsFilter): JSX.Element => {
  const { t } = useTranslation();

  const currentFilter = useEventsListState(
    state => state[listType].filterMarketKey,
  );
  const currentEl = document.querySelector(`.${currentFilter}`);

  const filteredMarkets = useMemo(() => {
    const filterKeys = keys(detailedEventMarketGroups[sportType]);
    return keys(
      reduce(
        marketsToRender,
        (acc, market) => {
          const type = getType(market);
          forEach(filterKeys, e => {
            if (
              detailedEventMarketGroups[sportType]?.[e]?.[type] &&
              !isUnivMarketNotFitForPeriod(e, market.period_type, type)
            ) {
              acc[e] = [e];
            }
          });
          return acc;
        },
        { [DEFAULT_EVENT_MARKET_TYPE]: true },
      ),
    );
  }, [marketsToRender, sportType]);

  const onSelectMarketType = (
    ev: React.MouseEvent<HTMLDivElement>,
    active: boolean,
    marketGroupName: string,
  ): void => {
    ev.preventDefault();
    ev.stopPropagation();
    setMarketFilterKey({
      listType,
      marketKey: active ? DEFAULT_EVENT_MARKET_TYPE : marketGroupName,
    });
  };

  useEffect(
    () => () =>
      setMarketFilterKey({ listType, marketKey: DEFAULT_EVENT_MARKET_TYPE }),
    [],
  );

  useEffect(() => {
    if (currentEl) {
      elementScrollIntoView(currentEl, {
        behavior: 'smooth',
        block: 'nearest',
        inline: 'nearest',
      });
    }
  }, [currentFilter]);

  return (
    <S.ScrollContainer data-qa="event-details-markets-filter-wrapper">
      {map(filteredMarkets, (marketGroupName: string, index) => {
        const active = marketGroupName === currentFilter;

        return (
          <div
            key={index}
            role="presentation"
            onClick={
              (e): void => onSelectMarketType(e, active, marketGroupName)
              // eslint-disable-next-line react/jsx-curly-newline
            }
            className={marketGroupName}
          >
            <S.StyledButton
              data-market={marketGroupName}
              data-qa={`markets-filter-btn-${marketGroupName}`}
              className={active ? 'selected' : 'not-selected'}
            >
              <S.MarketsLabel>
                {t(`header_market_keys.${marketGroupName}`)}
              </S.MarketsLabel>
            </S.StyledButton>
          </div>
        );
      })}
    </S.ScrollContainer>
  );
};

export default EventMarketsFilter;

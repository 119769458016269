import styled from 'styled-components';

const getCashoutSelectionWrapStyles = ({ theme }) => {
  return `${theme.space_5} 0 ${theme.space_5} ${theme.space_10}`;
};

export const CashoutSelectionWrap = styled.div<{
  isLocked?: boolean;
  isSuspended?: boolean;
  isCancelled?: boolean;
}>`
  display: grid;
  grid-template-columns: 20px 1fr ${({ isLocked, isSuspended }) =>
      isSuspended ? '140px' : (isLocked && '60px') || ''} 50px;
  justify-content: space-between;
  align-items: center;
  border-bottom: ${({ theme }) => theme.border};
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  padding: ${getCashoutSelectionWrapStyles};

  .market_name,
  .user_pick,
  .dash,
  .cashout-selection-amount {
    ${({ isCancelled }) => isCancelled && 'opacity: 0.6;'};
  }
`;

export const AmountSquare = styled.div`
  display: grid;
  grid-auto-flow: column;
  align-items: ${({ theme }) =>
    theme.isDesktop
      ? 'align-items: center; justify-content: center;'
      : 'align-items: end; justify-content: end;'};
  font-weight: ${({ theme }) => theme.fontBold};
  color: ${props => props.theme.textMain};
`;

export const MarketBlock = styled.div<{ isLocked?: boolean }>`
  text-overflow: ellipsis;
  overflow: hidden;
  color: ${props => props.theme.textMain};
  margin-left: ${props => props.theme.space_10};

  ${({ isLocked }) => isLocked && 'opacity: 0.5;'}
`;

export const IconWrapper = styled.div`
  height: 24px;
  width: 24px;
  border-radius: 3px;
  background-color: ${({ theme }) => theme.highlightWrapperBackground};
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const UserPick = styled.div<{ status }>`
  font-weight: ${({ theme }) => theme.fontBold};
  padding: ${({ theme }) => theme.space_5}
    ${({ theme }) => (theme.isDesktop ? theme.space_10 : '0')};
  color: ${({ status, theme }) => (status === 2 ? theme.accent : 'inherit')};
`;

import forEach from 'lodash/forEach';
import includes from 'lodash/includes';
import { SpringValue, useSpring } from '@react-spring/web';
import map from 'lodash/map';
import find from 'lodash/find';

import { colorSchema } from '@features/core/theming/contexTheme';
import services from '@features/core/services';
import { PageName } from '@features/core/routing';

import {
  BETTING_SLIP,
  CATEGORIES_PANEL,
  LUGAS_BLOCK_LAYER,
  LUGAS_LAYER,
  MENU_PANEL,
  REALITY_LAYER,
  SUMMARY_LAYER,
  TERMS_LAYER,
  WALLET_SWITCHER,
  ACCEPT_COOKIE_LAYER,
} from '@common/constants/dialogs';
import {
  EMAIL_NOTICE,
  HOME_NOTICE,
  ONLINE_ACCOUNT_NOTICE,
  RESEND_SUCCESS,
  RETRY_MESSAGE,
  VERIFICATION_NOTICE,
  YEARLY_VERIFICATION_NOTICE,
} from '@common/constants/notifications';
import * as cookies from '@common/constants/cookie';
import { DISABLE_ANIMATION } from '@common/constants/cookie';
import {
  HAS_ANIMATIONS,
  HAS_SMART_APP_BANNER,
  IS_EMBEDDED_SOLUTION,
} from '@common/constants/config';
import {
  getBrowser,
  isDesktopView,
  isIOS,
  Browser,
} from '@common/helpers/deviceUtil';
import { INotification, ISidebar } from '@common/providers/application/types';
import { useAppState } from '@common/providers/application/useAppState';
import { isIframe, isNativeApp, isStaticPages } from '@common/helpers/links';
import { isMatchWebRoutes } from '@common/providers/router/helper';

export const orderedLayers = [
  TERMS_LAYER,
  LUGAS_LAYER,
  LUGAS_BLOCK_LAYER,
  REALITY_LAYER,
  ACCEPT_COOKIE_LAYER,
  WALLET_SWITCHER,
  SUMMARY_LAYER,
  MENU_PANEL,
  BETTING_SLIP,
  CATEGORIES_PANEL,
];

export const orderedNotices = [
  HOME_NOTICE,
  RETRY_MESSAGE,
  ONLINE_ACCOUNT_NOTICE,
  YEARLY_VERIFICATION_NOTICE,
  VERIFICATION_NOTICE,
  EMAIL_NOTICE,
  RESEND_SUCCESS,
];

export const ANIMATED_MENU = 'menu';
export const ANIMATED_BALANCE = 'balance';
export const ANIMATED_BETTING_SLIP = 'betting-slip';
export const ANIMATED_BET_DETAIL = 'bet-detail';
export const ANIMATED_EVENT_DETAIL = 'event-detail';
export const ANIMATED_MENU_PANEL = 'menuPanel';
export const PREVETN_ANIMATION = 'prevent-animation';

/**
 * isAlreadyOpened
 *
 * @param {string} type
 * @param { ISidebar[] | INotification[]} elements
 * @returns {boolean} alreadyOpened
 */
export const isAlreadyOpened = (
  type: string,
  elements: ISidebar[] | INotification[],
): boolean => {
  const types = map(elements, 'type');
  return !!find(types, e => e === type);
};

/**
 * useAnimation
 *
 * @param {any} animationProps
 * @returns {{ [x: string]: SpringValue<number> }} animation
 */
const useAnimation = (animationProps): { [x: string]: SpringValue<number> } => {
  const isDisableAnimation =
    !services.config.get(HAS_ANIMATIONS) ||
    !!parseFloat(services.cookie?.get(DISABLE_ANIMATION));
  return useSpring(isDisableAnimation ? {} : animationProps);
};

/**
 * useAnimatedY
 *
 * @returns {{ [x: string]: SpringValue<number> }} AnimatedY
 */
export const useAnimatedY = (): { [x: string]: SpringValue<number> } => {
  const { showComponent } = useAppState.getState();
  const showComponentsList = [ANIMATED_MENU, ANIMATED_BETTING_SLIP];
  const isShowComponent = includes(showComponentsList, showComponent);
  const isPreventA = showComponent === PREVETN_ANIMATION;
  const animationProps = {
    from: { y: isPreventA ? 0 : 1000 },
    y: isShowComponent || isPreventA ? 0 : 1000,
    config: { duration: 300, friction: 30 },
    overflowY: 'scroll',
  };

  return useAnimation(animationProps);
};

/**
 * useAnimatedX
 *
 * @param {any} existEl
 * @returns {{ [x: string]: SpringValue<number> }} animatedX
 */
export const useAnimatedX = (existEl): { [x: string]: SpringValue<number> } => {
  const { showComponent } = useAppState.getState();
  const animationProps = {
    from: { x: isDesktopView() ? 0 : window.innerWidth },
    x: showComponent === existEl ? window.innerWidth + 900 : 0,
    config: { duration: 300, friction: 30 },
    height: '100%',
    overflowY: isIOS() ? '' : 'scroll',
  };

  return useAnimation(animationProps);
};

/**
 * setEventBackground
 *
 * @param {string} className
 * @param {boolean} even
 * @param {boolean} dark
 */
export const setEventBackground = (
  className: string,
  even?: boolean,
  dark?: boolean,
): void => {
  const eventsElements: NodeList | null = document.querySelectorAll(className);
  const { textActive, secondary, tertiaryBackground } = colorSchema;
  const darkColor = dark ? secondary : textActive;
  const evenColor = even ? darkColor : tertiaryBackground;
  const oddColor = even ? tertiaryBackground : darkColor;

  forEach(eventsElements, (element, index) => {
    (element as HTMLElement).setAttribute(
      'style',
      `background-color: ${index % 2 ? evenColor : oddColor}`,
    );
  });
};

/**
 * shiftElOnDetailsOpen
 *
 * @param  {string} detailId
 * @param {boolean} unsubscribe
 * @param {boolean} isDist
 */
export const shiftElOnDetailsOpen = (
  detailId: string,
  unsubscribe?: boolean,
  isDist?: boolean,
): void => {
  const pageContainer = document?.querySelector('.pageContainer');
  const accountHeader = document?.querySelector('.accountHeader');
  const detailWidth = document.getElementById(detailId)?.clientWidth || 0;
  const right_col = 241;
  const distance = isDist
    ? 0
    : window.innerWidth / 2 - (pageContainer?.clientWidth || 0) / 2 - right_col;
  const margin =
    distance < detailWidth ? detailWidth - distance + right_col : 0;
  const mooved_margin = `margin-right: ${margin}px;`;
  const initial_margin = 'margin-right: initial;';
  const openStyles = detailWidth ? mooved_margin : initial_margin;

  if (pageContainer || accountHeader) {
    if (!unsubscribe) {
      pageContainer?.setAttribute('style', openStyles);
      accountHeader?.setAttribute('style', openStyles);
    } else {
      pageContainer?.setAttribute('style', initial_margin);
      accountHeader?.setAttribute('style', initial_margin);
    }
  }
};

/**
 * testCPU
 *
 * @returns {number} result
 */
export const testCPU = (): number => {
  const d = new Date();
  const amount = 150000000;
  // eslint-disable-next-line no-empty
  for (let i = amount; i > 0; i--) {}
  const newd = new Date();
  const accnewd = Number(
    `${String(newd.getSeconds())}.${String(newd.getMilliseconds())}`,
  );
  const accd = Number(
    `${String(d.getSeconds())}.${String(d.getMilliseconds())}`,
  );
  const di = accnewd - accd;
  return Math.round(di * 1000) / 1000;
};

export const ConditionalWrapper = ({
  condition,
  wrapper,
  children,
}): JSX.Element => {
  return condition ? wrapper(children) : children;
};

const iosAppStoreLink =
  'https://apps.apple.com/de/app/sportwetten-de-sportwetten/id1658516144';
const androidAppStoreLink =
  'https://play.google.com/store/apps/details?id=com.netx.sportwetten&hl=de';

export const getApplicationLink = (item?: string): string => {
  return item === 'ios' || isIOS() ? iosAppStoreLink : androidAppStoreLink;
};

export const hasSmartBanner = (): boolean =>
  (getBrowser() === Browser.IOSSafari || getBrowser() === Browser.Safari) &&
  !!services.config.get(HAS_SMART_APP_BANNER);

export const hasNativeAdv = (isItNativeApp: boolean): boolean => {
  const isAppAdvSession = services.cookie.get(cookies.IS_TOP_ADV_BANNER_CLOSE);

  if (
    isItNativeApp ||
    hasSmartBanner() ||
    !services.config.get(HAS_SMART_APP_BANNER)
  ) {
    return false;
  }

  return !isAppAdvSession;
};

export const getMarketHeight = (marketIndex: string): string => {
  const marketHeight = document.querySelector<HTMLElement>(
    `.market-wrapper-${marketIndex}`,
  )?.offsetHeight;
  return `${marketHeight}px`;
};

export const isIframeView = (): boolean =>
  !!services.config.get(IS_EMBEDDED_SOLUTION);

const hasIframeFooter = (): boolean => {
  return !isIframeView() || (isIframeView() && !isDesktopView());
};

const hasIframeHeader = (): boolean => {
  return !isIframeView() || (isIframeView() && isDesktopView());
};

export const hasHeader = (): boolean =>
  !isIframe(window.location.search) &&
  !isNativeApp(window.location.search) &&
  hasIframeHeader();

export const hasFooter = (isShowFooter: boolean): boolean =>
  !isIframe(window.location.search) &&
  !isNativeApp(window.location.search) &&
  isShowFooter &&
  hasIframeFooter();

export const hasStaticFooter = (): boolean =>
  (isStaticPages() ||
    isMatchWebRoutes(PageName.HOME) ||
    isMatchWebRoutes(PageName.UNTERNEHMEN_PRESSE_DETAIL) ||
    isMatchWebRoutes(PageName.PAYMENT_METHODS) ||
    isMatchWebRoutes(PageName.RESPONSIBILITY) ||
    isMatchWebRoutes(PageName.UNTERNEHMEN_PRESSE)) &&
  !isIframeView();

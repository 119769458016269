import isUndefined from 'lodash/isUndefined';
import isNaN from 'lodash/isNaN';
import isNumber from 'lodash/isNumber';
import findIndex from 'lodash/findIndex';
import reduceRight from 'lodash/reduceRight';
import split from 'lodash/split';
import filter from 'lodash/filter';
import isEmpty from 'lodash/isEmpty';

import {
  ICards,
  IPeriods,
  IScore,
  IScoreInfo,
  IScorePeriod,
} from '@common/interfaces';

//  score.scoreType mapping to game(set) suffix map (1S, 2H, etc). do not to use it directly, use getPeriod instead
export const scoreTypeSuffixMap = {
  'TENNIS/3_SETS': 'S',
  'TENNIS/5_SETS': 'S',
  'ICE_HOCKEY/STANDARD': 'P',
  'SOCCER/STANDARD': 'H',
  'SOCCER/OVERTIME': 'H',
  'AMERICAN_FOOTBALL/OVERTIME': 'Q',
  // 'BASEBALL/STANDARD': complex logic
  'BASKETBALL/10_MIN_OT': 'Q',
  'BASKETBALL/HALVES': 'H',
  'VOLLEYBALL/5_SETS': 'S',
  'GENERIC/3_SETS': 'S',
  // 'DARTS/STANDARD': own logic,
  'ESPORTS/STANDARD': 'M',
  'HANDBALL/STANDARD': 'H',
};

/**
 *
 * @param {ICards} cards
 * @param {boolean} isHome
 * @returns {number} cards
 */
export const getRedCards = (cards?: ICards, isHome?: boolean): number => {
  const redCard = isHome ? cards?.red?.[0] || '0' : cards?.red?.[1] || '0';

  return parseFloat(redCard);
};

/**
 * hasScore
 *
 * @param {string | number | null} score
 * @returns {boolean} hasScore
 */
export const hasScore = (score: string | number | null): boolean => {
  return !isNaN(score) && isNumber(score);
};

/**
 * getScore
 *
 * @param {IScore} score
 * @returns {number[] | string} scores
 */
export const getScore = (score: IScore): number[] | string => {
  return (score && score.score) || [];
};

/**
 * getInfo
 *
 * @param {IScore} score
 * @returns {IScoreInfo | undefined}  info
 */
export const getInfo = (score: IScore): IScoreInfo | undefined => {
  return score && score.info;
};

export const getHome = (score: IScore): number | string => {
  return getScore(score)[0];
};

export const getAway = (score: IScore): number | string => {
  return getScore(score)[1];
};

/**
 * getInning
 *
 * @param {IScore} score
 * @param {string} inning
 * @returns {(number | null)[]} inning
 */
export const getInning = (score: IScore, inning: string): (number | null)[] => {
  const top = score.periods[`${inning}IT`] || [null, null];
  const bottom = score.periods[`${inning}IB`] || [null, null];
  const result = [
    hasScore(top[0]) ? parseFloat(top[0]) : null,
    hasScore(top[1]) ? parseFloat(top[1]) : null,
  ];
  if (result[0] && result[1] && hasScore(bottom[0]) && hasScore(bottom[1])) {
    result[0] += parseFloat(bottom[0]);
    result[1] += parseFloat(bottom[1]);
  }
  return result;
};

/**
 * getHomeInning
 *
 * @param {IScore} score
 * @param {string} inning
 * @returns {number | string | null} homeInning
 */
export const getHomeInning = (
  score: IScore,
  inning: string,
): number | string | null => {
  return inning ? getInning(score, inning)[0] : getHome(score);
};

/**
 * getAwayInning
 *
 * @param {IScore} score
 * @param {string} inning
 * @returns {number | string | null} awayInning
 */
export const getAwayInning = (
  score: IScore,
  inning: string,
): number | string | null => {
  return inning ? getInning(score, inning)[1] : getAway(score);
};

/**
 * getLastInning
 *
 * @param {IScore} score
 * @returns {string} lastInning
 */
export const getLastInning = (score: IScore): string => {
  return String(
    findIndex(
      Array.from({ length: 9 }),
      (i, e) => !score.periods[`${e + 1}IT`],
    ) || 1,
  );
};

/**
 * getLastPart
 *
 * @param {IScore} score
 * @returns {number[]} lastPart
 */
export const getLastPart = (score: IScore): number[] => {
  return reduceRight(
    score.periods,
    (result, value, index) => {
      const nextIndex = `${parseFloat(split(index, '')[0]) + 1}${
        split(index, '')[1]
      }${split(index, '')[2] || ''}`;
      if (
        value &&
        value.length &&
        (!score.periods[nextIndex] || !score.periods[nextIndex].length)
      ) {
        return [+value[0], +value[1]];
      }
      return result;
    },
    [0, 0],
  );
};

/**
 * getLastPeriodHome
 *
 * @param {IScore} score
 * @returns {number} lastPeriodHome
 */
export const getLastPeriodHome = (score: IScore): number => {
  return getLastPart(score)[0];
};

/**
 * getLastPeriodAway
 *
 * @param {IScore} score
 * @returns {number} lastPeriodAway
 */
export const getLastPeriodAway = (score: IScore): number => {
  return getLastPart(score)[1];
};

/**
 * hasGenericScore
 *
 * @param {IScore} score
 * @returns {boolean } hasGenericScore
 */
export const hasGenericScore = (score: IScore): boolean => {
  return !isUndefined(getHome(score)) && !isUndefined(getAway(score));
};

/**
 * getQuarter
 * getQuarter, returns played quater
 *
 * @param {IScore} score
 * @param {number} period
 * @returns {IScorePeriod} scorePeriod
 */
export const getQuarter = (score: IScore, period: number): IScorePeriod => {
  const suffix = scoreTypeSuffixMap[score.score_type];
  const periodName = `${period}${suffix ?? ''}`;

  return score.periods[periodName] || [null, null];
};

/**
 * getHomeQuarter
 * returns home score for particular quater or in general if no period parameter passed
 *
 * @param {IScore} score
 * @param {number} period
 * @returns {string | number | null} score
 */
export const getHomeQuarter = (
  score: IScore,
  period?: number,
): string | number | null => {
  if (period) {
    return getQuarter(score, period)[0];
  }
  return getHome(score);
};

/**
 * getAwayQuarter
 * returns away score for particular quater or in general if no period parameter passed
 *
 * @param {IScore} score
 * @param {number} period
 * @returns {string | number | null} score
 */
export const getAwayQuarter = (
  score: IScore,
  period?: number,
): string | number | null => {
  if (period) {
    return getQuarter(score, period)[1];
  }
  return getAway(score);
};

/**
 * getOT
 * returns overtime scores
 *
 * @param {IScore} score
 * @returns {string | [null, null]} ot
 */
export const getOT = (score: IScore): IScorePeriod => {
  return score.periods.OT || [null, null];
};

/**
 * getPeriods
 * getPeriods, retrieve core period if exists
 *
 * @param {IScore} score
 * @returns {IPeriods | null} periods
 */
export const getPeriods = (score: IScore): IPeriods | null => {
  return score.periods || null;
};

/**
 * getPeriod
 *
 * @param {IScore} score
 * @param {number} period
 * @returns {(string | number | null)[] | string} reiod
 */
export const getPeriod = (
  score: IScore,
  period: number,
): (number | null)[] | string => {
  if (period) {
    const suffix = scoreTypeSuffixMap[score.score_type];
    const periodName = `${period}${suffix ?? ''}`;

    return score.periods[periodName] || [null, null];
  }
  return getScore(score);
};

/**
 * getLastPeriod
 *
 * @param {IScore} score
 * @param {Function} periodSelector
 * @param {number} periodsCount
 * @returns {number} lastPeriod
 */
export const getLastPeriod = (
  score: IScore,
  periodSelector: (score: IScore, n: number) => IScorePeriod,
  periodsCount: number,
): number => {
  for (let i = periodsCount; i > 1; i--) {
    if (filter(periodSelector(score, i), v => v !== null).length) {
      return i;
    }
  }
  return 1;
};

export const getHalf = (score: IScore, period: number): IScorePeriod => {
  return score.periods[`${period}H`] || [null, null];
};

/**
 * getServing
 *
 * @param {IScore} score
 * @param {boolean} isHome
 * @returns {string | undefined} serving
 */
export const getServing = (
  score: IScore,
  isHome: boolean,
): string | undefined => {
  if (!getInfo(score) || isEmpty(getInfo(score))) {
    return '';
  }
  return isHome ? getInfo(score)?.serving?.[0] : getInfo(score)?.serving?.[1];
};

export const getPoints = (score: IScore): (number | string)[] => {
  return getInfo(score)?.points || [0, 0];
};

export const hasPoints = (score: IScore): boolean => {
  return !!getInfo(score)?.points;
};

export const getTotalScore = (score: IScore): number[] =>
  score?.match_score?.match_score || [0, 0];

export const getTotalScoreHome = (score: IScore): number =>
  getTotalScore(score)[0];

export const getTotalScoreAway = (score: IScore): number =>
  getTotalScore(score)[1];

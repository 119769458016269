import React, { useEffect, useRef } from 'react';
import includes from 'lodash/includes';
import split from 'lodash/split';
import { useTheme } from 'styled-components';

import { ODDS_COMPARISON_LAYER } from '@common/constants/dialogs';
import { SidebarSide } from '@common/interfaces';
import { closeSidebar } from '@common/providers/application/useAppState';
import {
  setChoosedSection,
  useOddsComparison,
} from '@common/providers/oddsComparison/useOddsComparison';
import useOnClickOutside from '@common/hooks/useOnClickOutside';

import { Icon } from '@ui/components/icon';

import RenderOddsComparsionSectionList from '../oddsComparisonList/OddsComparisonList';

import * as S from './OddsComparisonLayer.styled';

const OddsComparisonLayer = (): JSX.Element => {
  const oddsComparisonList = useOddsComparison(s => s.oddsComparisonList);
  const choosedSectionIndex = useOddsComparison(s => s.choosedSectionIndex);
  const currentSectionRef = useRef<HTMLDivElement>(null);
  const sectionId = choosedSectionIndex as number;
  const { iconFillDark } = useTheme();

  const toggleSection = (changedIndex: number): void => {
    const currentIndex = sectionId + changedIndex;

    if (oddsComparisonList.length - 1 >= currentIndex && currentIndex >= 0) {
      setChoosedSection(currentIndex);
    }
  };

  useOnClickOutside(currentSectionRef, event => {
    if (
      !includes(
        split(event?.target.closest('div').className, ' '),
        'arrow-wrapper',
      )
    ) {
      closeSidebar(ODDS_COMPARISON_LAYER);
    }
  });

  const onPressHandler = (e: KeyboardEvent): void => {
    if (e.key === 'Enter') {
      e.preventDefault();
    }
    if (e.key === 'ArrowLeft') {
      e.preventDefault();
      toggleSection(-1);
    }
    if (e.key === 'ArrowRight') {
      e.preventDefault();
      toggleSection(+1);
    }
  };

  useEffect(() => {
    document.addEventListener('keydown', onPressHandler);
  }, [choosedSectionIndex]);

  useEffect(() => {
    return () => document.removeEventListener('keydown', onPressHandler);
  }, []);

  return (
    <S.OddsComparisonLayerWrapper>
      {!!choosedSectionIndex && (
        <S.SideArrowWrapper
          className="arrow-wrapper"
          isLeft
          onClick={(): void => toggleSection(-1)}
        >
          <Icon
            name="chevron-thin-left"
            fill={iconFillDark}
            width="12"
            height="12"
          />
        </S.SideArrowWrapper>
      )}
      {RenderOddsComparsionSectionList(
        oddsComparisonList,
        sectionId,
        null,
        true,
        currentSectionRef,
      )}
      {oddsComparisonList.length - 1 > (choosedSectionIndex as number) && (
        <S.SideArrowWrapper
          className="arrow-wrapper"
          onClick={(): void => toggleSection(1)}
        >
          <Icon
            name="chevron-thin-right"
            fill={iconFillDark}
            width="12"
            height="12"
          />
        </S.SideArrowWrapper>
      )}
    </S.OddsComparisonLayerWrapper>
  );
};

export default OddsComparisonLayer;

OddsComparisonLayer.meta = {
  side: SidebarSide.center,
  key: ODDS_COMPARISON_LAYER,
};

import services from '@features/core/services';

import { NOTIFICATION_ENDPOINT } from '@common/constants/config';
import { getApiUrl } from '@common/api/api';
import { apiHandler, MethodType } from '@common/api/apiHandler';

interface FetchPushNotificationStatus {
  username: string;
}

const fetchPushNotificationStatus = async ({
  username,
}: FetchPushNotificationStatus): Promise<void> => {
  const API_PUSH_NOTIFICATION_STATUS = `${services.config.get(
    NOTIFICATION_ENDPOINT,
  )}status/username`;
  const apiUrl = getApiUrl(API_PUSH_NOTIFICATION_STATUS, { username });

  return apiHandler(
    { method: MethodType.Get },
    {
      apiUrl,
    },
  );
};

export default fetchPushNotificationStatus;

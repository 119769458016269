import styled from 'styled-components';

import { TextLarge } from '@features/core/styles/text.styled';

export const ApplicationLinksSectionTitle = styled(TextLarge)`
  color: ${({ theme }) => theme.textMain};
  text-transform: uppercase;
  font-weight: ${({ theme }) => theme.fontBold};
  margin-bottom: ${({ theme }) => theme.space_15};
`;

export const LinksSection = styled.div`
  align-items: center;
  align-content: center;
  justify-items: center;
  width: 100%;
  display: flex;
  justify-content: space-between;
`;

export const ApplicationLinksSection = styled.div`
  background: ${({ theme }) => theme.textActive};
  margin-top: ${({ theme }) => theme.space_15};
  align-items: center;
  align-content: center;
  justify-items: center;
  padding: ${({ theme }) => theme.space_10} ${({ theme }) => theme.space_15};
  display: flex;
  justify-content: space-between;

  .link {
    width: calc(50% - 5px);
    display: inherit;
  }
`;

export const LeftLinkContent = styled.div`
  display: grid;
  grid-auto-flow: row;
  grid-auto-columns: 1fr;
  align-items: center;
  align-content: center;
  justify-items: center;
  justify-content: center;
  width: 240px;
  place-items: flex-start;
`;

export const StyledImage = styled.img`
  width: 115px;
  height: 33px;
`;

import React from 'react';
import { useTranslation } from 'react-i18next';

import { PageName } from '@features/core/routing';

import { ODDS_COMPARISON_LAYER } from '@common/constants/dialogs';
import { isMatchWebRoutes, setLocation } from '@common/providers/router/helper';
import { IOddsComparisonSection, ItemType } from '@common/interfaces';
import {
  getCompletedOddsProviders,
  setSelectionToBS,
} from '@common/helpers/oddsComparisonHelper/oddsComparisonHelper';
import { isDesktopView } from '@common/helpers/deviceUtil';
import {
  closeSidebar,
  openSidebar,
} from '@common/providers/application/useAppState';
import { setChoosedSection } from '@common/providers/oddsComparison/useOddsComparison';

import { DualRingLoader } from '@ui/components/loaders';
import { StyledButton } from '@ui/components/buttons';

import RenderOddsComparsionItemList from '../oddsComparisonItemList/OddsComparisonItemList';

import * as S from './OddsComparisonSection.styled';

const OddsComparisonSection = ({
  sectionEvents,
  sectionIndex,
  isSidebarView,
}: IOddsComparisonSection): JSX.Element => {
  const { t } = useTranslation();
  const isHomePage = isMatchWebRoutes(PageName.HOME);
  const isDesktop = isDesktopView();
  const isFullSection = isSidebarView || (!isHomePage && !isDesktop);
  const availableOdds = getCompletedOddsProviders();

  const handleClick = (): void => {
    if (!isFullSection) {
      setChoosedSection(sectionIndex);
      if (isDesktop) {
        openSidebar({ type: ODDS_COMPARISON_LAYER });
      } else {
        setLocation(PageName.ODDS_COMPARISON);
      }
    } else {
      setSelectionToBS(sectionEvents);
      if (isDesktop) {
        closeSidebar(ODDS_COMPARISON_LAYER);
      }
    }
  };

  return (
    <>
      {!sectionEvents.length ? (
        <DualRingLoader />
      ) : (
        <S.SectionWrapper
          isSidebarView={isSidebarView}
          className={`odds-comparison-section-${sectionIndex}`}
        >
          <S.SectionItemsWrapper isFullSection={isFullSection}>
            <S.OddsComparisonSectionHeader
              title={`${sectionEvents.length}er Kombi`}
              odds={availableOdds}
              sectionItemType={ItemType.header}
              isFullSection={isFullSection}
            />

            {RenderOddsComparsionItemList(sectionEvents, isFullSection)}
          </S.SectionItemsWrapper>
          <S.SectionActionButtonWrapper
            isWidgetView={isHomePage && !isSidebarView}
          >
            <StyledButton
              data-qa="odds-comparison-btn"
              type="submit"
              onClick={handleClick}
              label={
                !isFullSection
                  ? t('oddsComparison.show_bet_button')
                  : t('oddsComparison.add_to_bs')
              }
            />
          </S.SectionActionButtonWrapper>
        </S.SectionWrapper>
      )}
    </>
  );
};

export default OddsComparisonSection;

import { ILiveSportCategoriesTreeData } from '@common/interfaces';

export interface ICategoryTreeItem {
  item: ILiveSportCategoriesTreeData;
  selectedItems?: string[];
  filter?: string;
  onClick: (item: ILiveSportCategoriesTreeData) => void;
  onSelect: (item: ILiveSportCategoriesTreeData) => void;
  onExpand?(): void;
  opened?: boolean;
  isHighlight?: boolean;
}

export enum ICheckboxStatus {
  checked = 'checked',
  unChecked = 'unChecked',
  partChecked = 'partChecked',
}

export type TReturnCheckboxIconName = 'check' | 'minus' | '';

import styled from 'styled-components';

import { InfoBlock } from '@features/profile/components/infoBlock';

export const Content = styled.div`
  background-color: ${({ theme }) => theme.secondary};
  padding-bottom: ${({ theme }) => theme.space_10};

  &.slider-cashout {
    transition: transform 0.9s ease;
  }
`;

export const NoListMessage = styled(InfoBlock)`
  margin-top: 35vh;
  text-align: center;
  font-size: ${({ theme }) => theme.fontXL};
  color: ${({ theme }) => theme.textMain};
`;

export const BonusNotificationWrapper = styled.div`
  color: ${({ theme }) => theme.main};
  margin-bottom: ${({ theme }) => theme.space_5};
`;

import { Rule } from 'rc-field-form/lib/interface';

import i18n from '@features/core/translation';

import makeLimitValuePreset from '@common/helpers/formsHelpers/validation/limitValuePreset';
import minMax from '@common/helpers/formsHelpers/validation/minMax';
import { IMakeLimitPerMonthPreset } from '@common/interfaces';

const makeLimitPerMonthPreset = (data: IMakeLimitPerMonthPreset): Rule[] => {
  const {
    min,
    max,
    globalErrLimitMsg,
    maxLimitInfoText,
    minLimitInfoText,
    required,
  } = data;
  const limitValueRules = makeLimitValuePreset();
  let message;

  if ((maxLimitInfoText || minLimitInfoText) && !globalErrLimitMsg) {
    message = i18n.t((maxLimitInfoText || minLimitInfoText) as string);
  } else {
    message = i18n.t(`common.presets.${globalErrLimitMsg}errors_min_max`);
  }

  return [
    ...limitValueRules,
    {
      validator: minMax,
      message,
      min,
      max,
    },
    {
      required,
      message: i18n.t('common.presets.is_required'),
    },
  ];
};

export default makeLimitPerMonthPreset;

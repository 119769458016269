import React from 'react';

import Information from './InfoBlock.styled';
import { IInfoBlock } from './InfoBlock.types';

const InfoBlock: React.FC<IInfoBlock> = ({
  message,
  style,
  className,
  isOasisPage,
  isLimitsPage,
}) => (
  <Information
    style={style}
    className={className}
    isOasisPage={isOasisPage}
    isLimitsPage={isLimitsPage}
    dangerouslySetInnerHTML={{ __html: message }}
  />
);

export default InfoBlock;

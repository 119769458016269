import map from 'lodash/map';

import CustomError from '@features/core/error/error';
import services from '@features/core/services';

import { HAS_OASIS } from '@common/constants/config';
import fetchLimit from '@common/api/limits/fetchLimit';
import fetchSetLimit from '@common/api/limits/fetchSetLimit';
import fetchSetSelfExclusion from '@common/api/limits/fetchSetSelfExclusion';
import fetchSetSelfExclusionMGA from '@common/api/limits/fetchSetSelfExclusionMGA';
import { apiHandler, MethodType } from '@common/api/apiHandler';
import { ILimit, ILimitHistory } from '@common/interfaces';
import { StaticCalls } from '@common/api/api';
import fetchPocketTags from '@common/api/account/fetchPocketTags';
import { updateUserData } from '@common/providers/user/useUserState';
import {
  getLimitsHistory,
  getLimitsHistoryError,
  getLimitsHistorySuccess,
  getLimitsSuccess,
  setActiveLimits,
  setLimitError,
  setLimitSuccess,
  setSelfExclusionError,
  setSelfExclusionLoading,
  setSelfExclusionSuccess,
} from '@common/providers/limits/useLimits';
import {
  IGetLimitHistoryPayload,
  IGetLimitPayload,
  ISetLimitPayload,
  ISetSelfExclusionPayload,
} from '@common/providers/limits/types';

const { API_LIMITS_HISTORY } = StaticCalls;

export const setSelfExclusion = async (
  payload: ISetSelfExclusionPayload,
): Promise<void> => {
  setSelfExclusionLoading();
  const response = services.config.get(HAS_OASIS)
    ? await fetchSetSelfExclusion(payload)
    : await fetchSetSelfExclusionMGA(payload);

  if (response instanceof CustomError) {
    return setSelfExclusionError(response);
  }

  const selfLocking = await fetchPocketTags({
    method: MethodType.Get,
    params: {
      tag: { key: 'sys.self_exclusion' },
    },
  });
  updateUserData({ selfExclusionTag: selfLocking?.tag?.value });
  return setSelfExclusionSuccess();
};

export const getLimit = async (payload: IGetLimitPayload): Promise<void> => {
  const response = await fetchLimit(payload);

  if (response instanceof CustomError) {
    return setLimitError({
      name: response.name,
      message: response.message,
      data: { limitType: payload.limitType },
    });
  }

  return getLimitsSuccess({
    data: map(response?.data, e => {
      if (e.data.value === 'NULL') {
        return {
          data: {
            ...e.data,
            value: '',
          },
        };
      }
      return e;
    }),
    limitType: payload.limitType,
  });
};

export const getLimitHistory = async (
  params: IGetLimitHistoryPayload,
): Promise<void> => {
  getLimitsHistory();

  const response = await apiHandler<ILimitHistory>(
    { method: MethodType.Get, params },
    {
      apiUrl: API_LIMITS_HISTORY,
    },
  );

  if (response instanceof CustomError) {
    return getLimitsHistoryError(response);
  }
  return getLimitsHistorySuccess(response);
};

export const setLimitAction = async (
  payload: ISetLimitPayload,
): Promise<void> => {
  const response = await fetchSetLimit(payload);

  if (response instanceof CustomError) {
    return setLimitError({
      name: response.message,
      message: response.message,
      data: { limitType: payload.limitType },
    });
  }
  if (!response || !response.data) {
    setActiveLimits('');
    return Promise.resolve();
  }
  if (!response?.data?.data) {
    return setLimitSuccess({
      data: [response as ILimit],
      limitType: payload.limitType,
    });
  }
  return setLimitSuccess({
    data: [response.data as ILimit],
    limitType: payload.limitType,
  });
};

/* eslint-disable lodash/prefer-lodash-typecheck */
import add from 'date-fns/add';

import { ICustomError, ILimit } from '@common/interfaces';
import formatDate from '@common/helpers/timeHelper/localizedFormat';
import { MethodType } from '@common/api/apiHandler';
import apiRpcRequest from '@common/api/common/apiCommonRequest';
import { ISetSelfExclusionPayload } from '@common/providers/limits/types';

const fetchSetSelfExclusion = ({
  period,
  reason,
}: ISetSelfExclusionPayload): Promise<ILimit & ICustomError> => {
  let method = 'user.oasis_short_term_block';
  const isDate = period instanceof Date;
  let endDate: string | null = isDate
    ? (period.getTime() / 1000).toFixed(0)
    : formatDate(
        add(new Date(), {
          months: parseInt(period, 10),
        }),
        't',
      );
  if (period === '24H') {
    method = 'user.oasis_temporary_block';
    endDate = null;
  }
  if (period === '9999Y') {
    method = 'user.oasis_long_term_block';
    endDate = null;
  }

  return apiRpcRequest({
    method: MethodType.Post,
    data: {
      id: 'jsonrpc',
      method,
      params: {
        ...(endDate ? { block_end_date: endDate } : {}),
        reason_code: reason,
      },
    },
  }) as Promise<ILimit & ICustomError>;
};

export default fetchSetSelfExclusion;

import services from '@features/core/services';

import { DynamicCalls } from '@common/api/api';
import { apiHandler, MethodType } from '@common/api/apiHandler';
import {
  ICustomError,
  IGenerateUsernameParams,
  IGenerateUsernameResponse,
} from '@common/interfaces';

const { API_USERNAME } = DynamicCalls;

const generateUsername = ({
  login,
  email,
}: IGenerateUsernameParams): Promise<IGenerateUsernameResponse &
  ICustomError> =>
  apiHandler<IGenerateUsernameResponse>(
    { method: MethodType.Get, params: { login, email } },
    {
      apiUrl: API_USERNAME,
      apiData: {
        dynamicLanguage: services.domainLang,
      },
    },
  );

export default generateUsername;

export enum IEventDetailWidgets {
  LmtAndTable = 'match.lmtPlus',
  LastMatches = 'headToHead.standalone',
}

export enum IEventDetailWidgetTabs {
  lmt = 'live_tracker',
  lastMatches = 'last_matches',
  table = 'table',
  alarms = 'alarms',
  statistics = 'statistics',
}

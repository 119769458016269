import { MethodType } from '@common/api/apiHandler';
import apiRpcRequest from '@common/api/common/apiCommonRequest';
import { IFetchUserInfoApiResponse } from '@common/interfaces';

const fetchUserInfo = async (): Promise<IFetchUserInfoApiResponse> => {
  return apiRpcRequest({
    method: MethodType.Post,
    data: {
      method: 'user.user_info',
    },
  }) as IFetchUserInfoApiResponse;
};

export default fetchUserInfo;

import styled from 'styled-components';

import CustomLink from '@features/core/routing/CustomLink';
import { TextLarge, TextNormal } from '@features/core/styles/text.styled';

export const Root = styled.div`
  padding-left: ${({ theme }) => theme.space_10};
`;
export const IconCell = styled.div`
  width: 20px;
`;

export const NameCell = styled.div`
  padding: ${({ theme }) => theme.space_5} 0 !important;
  width: auto;

  & p:first-child {
    margin-bottom: ${({ theme }) => theme.space_5};
  }
`;

export const CountCell = styled.div`
  display: grid;
  grid-template-columns: 22.5px 22.5px;
  width: 45px;
  background-color: ${props => props.theme.buttonMainTextColor};

  & p,
  div {
    text-align: center;
    color: ${props => props.theme.selectTextColor};
  }

  & p {
    opacity: 0.5;
  }
`;

export const StyledCustomLink = styled(CustomLink)`
  display: grid;
  grid-template-columns: auto 20px 45px;
  border-top: 1px solid ${props => props.theme.buttonMainTextColor};
`;

export const ItemLabel = styled(TextNormal)`
  margin-right: ${({ theme }) => theme.space_10};
  color: ${props => props.theme.selectTextColor};
`;

export const TextBold = styled(TextLarge)`
  font-weight: ${({ theme }) => theme.fontBold};
`;

export enum LiveStatus {
  'open' = 'open',
  'future' = 'future',
  'smart_suspended' = 'smart_suspended',
  'suspended' = 'suspended',
  'disabled' = 'disabled',
  'closed' = 'closed',
}

export type ILiveStatus = keyof typeof LiveStatus;

export default ILiveStatus;

export enum PredictionType {
  'HOME' = 'HOME',
  'DRAW' = 'DRAW',
  'VISITOR' = 'VISITOR',
  'UNDER' = 'UNDER',
  'OVER' = 'OVER',
  'HOME_OR_DRAW' = 'HOME_OR_DRAW',
  'HOME_OR_VISITOR' = 'HOME_OR_VISITOR',
  'DRAW_OR_VISITOR' = 'DRAW_OR_VISITOR',
  'SCORE' = 'SCORE',
  'WINNER' = 'WINNER',
  'NONE' = 'NONE',
  'NO_GOAL' = 'NO_GOAL',
  'GOAL' = 'GOAL',
  'GOALSCORER' = 'GOALSCORER',
}

export type IPredictionType = keyof typeof PredictionType;

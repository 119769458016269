import styled from 'styled-components';

import { TextNormal } from '@features/core/styles/text.styled';
import { footerHeight } from '@features/app/layout/footer';

export const Wrapper = styled.div`
  color: ${({ theme }) => theme.textMain};
  margin-bottom: ${({ theme }) => theme.space_5};
  scroll-margin-bottom: ${({ theme }) =>
    theme.isDesktop ? 0 : footerHeight}px;

  .go-to-details-btn {
    align-self: center;
    font-size: ${props => props.theme.fontSM};
    max-width: 95px;
    height: 22px;
  }

  .cashout-container {
    display: grid;
    grid-auto-flow: column;
    padding-left: ${({ theme }) => theme.space_10};
    padding-right: ${({ theme }) => theme.space_10};
  }

  &:nth-child(odd) {
    background-color: ${({ theme }) => theme.tertiaryBackground};

    .cashout-reverse-bg {
      grid-auto-flow: row;
    }
  }

  &:nth-child(even) {
    background-color: ${({ theme }) => theme.textActive};

    .cashout-reverse-bg {
      grid-auto-flow: row;
    }
  }

  .short-view {
    display: flex;
    justify-content: space-between;
    padding: ${({ theme }) => theme.space_10};
    background: inherit;
    z-index: 5;
    margin-right: auto;
    border-bottom: ${({ theme }) => theme.border};
    font-size: ${props => props.theme.fontSM};

    &.open {
      position: sticky;
      top: 0;

      &:nth-child(odd) {
        background-color: ${({ theme }) => theme.tertiaryBackground};
      }

      &:nth-child(even) {
        background-color: ${({ theme }) => theme.textActive};
      }
    }
  }

  .cashout-selection {
    display: grid;
    justify-content: space-between;
  }

  .slide-inner {
    overflow: hidden;
    transition: max-height 0.4s;
    position: relative;
  }
`;

export const Text = styled.div`
  box-sizing: border-box;
  flex-grow: 1;
  overflow: hidden;
`;

const getOpenButtonMobStyles = ({ theme }) => `
padding: ${theme.space_5} ${theme.space_10};
`;

export const OpenButton = styled.button<{ open: boolean }>`
  display: grid;
  grid-auto-flow: column;
  justify-content: center;
  width: ${({ theme }) => (theme.isDesktop ? 25 : 32)}px;
  ${({ theme }) => !theme.isDesktop && getOpenButtonMobStyles}

  background-color: transparent;
  border: ${({ theme }) => theme.border};
  opacity: ${props => (props.disabled ? 0.6 : 1)};
  align-self: center;
  cursor: pointer;

  ${({ open }) => open && 'svg {transform: rotateX(180deg)}'};

  path,
  svg {
    fill: ${({ theme }) => theme.textMain};
  }
`;

export const LiveEventNamesWrapper = styled.div`
  display: flex;
`;

export const CashoutLive = styled.div`
  color: ${({ theme }) => theme.accent};
  align-items: center;
  display: grid;
  grid-auto-flow: column;
  font-size: ${({ theme }) => theme.fontXS};
  margin-right: ${({ theme }) => theme.space_5};

  svg > path {
    fill: ${({ theme }) => theme.accent};
  }
`;

export const EventsNamesWrapper = styled.span`
  display: block;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  font-size: ${props => props.theme.fontSM};
  max-width: 255px;
`;

export const CashoutTitleWrapper = styled.div<{ open: boolean }>`
  display: grid;
  grid-template-areas: 'item1 . . item2';
  justify-content: space-between;
  margin-bottom: ${props => props.theme.space_5};
  font-weight: ${({ theme }) => theme.fontBold};
  align-items: center;

  & > span:first-child {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    grid-area: ${({ open }) => (open ? '' : 'item1')};
  }

  & > span:last-child {
    grid-area: ${({ open }) => (open ? '' : 'item2')};
  }

  & svg {
    margin-right: ${({ theme }) => theme.space_10};
  }
`;

export const GewinnChanceWrapper = styled.span`
  text-align: right;
  white-space: nowrap;
  ${({ theme }) => theme.isDesktop && 'margin-right:4px;'}
`;

export const ImageWrapper = styled.div`
  display: grid;
  justify-content: center;
  align-items: center;
  justify-items: center;
  cursor: pointer;
  flex-shrink: 0;
  height: 25px;
  width: 25px;
  margin-right: ${({ theme }) => theme.space_10};
`;

export const ImageStyled = styled.img`
  display: block;
  max-width: 100%;
  align-self: center;
  margin-right: ${({ theme }) => theme.space_5};
`;

export const CashoutTitle = styled(TextNormal)<{ isBonus?: boolean }>`
  display: flex;
  ${({ isBonus }) => (isBonus ? 'height: 17px' : '')};
  font-size: ${props => props.theme.fontSM};
  align-items: center;

  & span {
    ${({ isBonus }) => (isBonus ? 'align-self: center;' : '')};
  }
`;

import styled from 'styled-components';

export const getButtonsWrapperDesktopStyles = ({ theme }) => `
  padding: ${theme.space_10} 0px;
  position: relative;
  & > div {
    margin: 0;
  }
  & button {
  min-width: 60px;
  }
  
  @media (min-width: 1600px) {
    padding: ${theme.space_10} ${theme.space_5};
    grid-gap: ${theme.space_10};
  }
`;

export const timeFilterHeight = 64;

export const buttonsWrapperMobileStyles = `
  height: ${timeFilterHeight}px;
  align-content: center;
`;
export const ButtonsWrapper = styled.div<{ isBigScreen: boolean }>`
  display: grid;
  grid-template-columns: ${({ isBigScreen }) =>
    isBigScreen ? '1fr' : 'repeat(3, 1fr)'};
  background-color: ${({ theme }) => theme.textActive};
  ${({ theme }) =>
    theme.isDesktop
      ? getButtonsWrapperDesktopStyles
      : buttonsWrapperMobileStyles};
`;

const getStyledButtonStyles = ({ theme }) => {
  return theme.isDesktop
    ? `@media (max-width: 1600px) {
          margin: 0 ${theme.space_5};
         }`
    : `padding: 0 ${theme.space_5};
         margin: 0 ${theme.space_10};`;
};

export const StyledButton = styled.button<{ active?: boolean }>`
  height: 34px;
  font-size: ${props => props.theme.fontSM};
  white-space: nowrap;
  text-transform: uppercase;
  font-weight: ${({ theme }) => theme.fontBold};
  background-color: ${({ active, theme }) =>
    active ? theme.accent : theme.primaryBackground};
  color: ${({ active, theme }) => (active ? theme.textActive : theme.textMain)};
  cursor: pointer;
  border-radius: 3px;

  & svg {
    margin-right: ${({ theme }) => theme.space_5};
  }

  ${getStyledButtonStyles};
`;

export const StyledSelect = styled.select`
  width: 100%;
  height: 30px;
  border-radius: 3px;
  border: ${({ theme }) => theme.border};
  z-index: 3;
  cursor: pointer;
  text-transform: uppercase;
  background-color: transparent;
  -moz-appearance: none; /* Firefox */
  -webkit-appearance: none; /* Safari and Chrome */
  appearance: none;
  padding-left: ${({ theme }) => theme.space_5};
  outline: 1px solid transparent;
`;

export const SelectInner = styled.div`
  position: relative;
  z-index: 1;
  background-color: ${({ theme }) => theme.highlightWrapperBackground};
  width: 100%;
  overflow: hidden;
  border-radius: 5px;
`;

export const StyledArrow = styled.div`
  position: absolute;
  width: 35px;
  height: 35px;
  background-color: ${props => props.theme.primaryAccentBackground};
  right: -1px;
  top: -1px;
  z-index: -1;

  ::before {
    content: '';
    position: absolute;
    left: calc(50% - 3.5px);
    top: ${({ theme }) => theme.space_10};
    height: 7px;
    width: 7px;
    border-width: 0 0 1px 1px;
    transform: rotate(-45deg);
    border-color: ${({ theme }) => theme.highlightWrapperBackground};
    border-style: solid;
  }
`;

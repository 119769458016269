import React from 'react';

import services from '@features/core/services';

import { HAS_INBOX } from '@common/constants/config';
import { useInboxState } from '@common/providers/inbox/useInbox';

import { IMessageCountProps } from './Inbox.types';
import MessageCountWrapper from './InboxMessageCount.styled';

const InboxMessageCount = ({ pageType }: IMessageCountProps): JSX.Element => {
  const unreadMessages = useInboxState(s => s.unreadMessages);

  return (
    <>
      {services.config.get(HAS_INBOX) ? (
        <MessageCountWrapper
          hasInboxMessage={!!unreadMessages}
          isUserMenu={pageType === 'user-menu'}
          isDesktopProfileIcon={pageType === 'desktop-profile-icon'}
          isMobileProfileIcon={pageType === 'mobile-profile-icon'}
        >
          {unreadMessages}
        </MessageCountWrapper>
      ) : null}
    </>
  );
};

export default InboxMessageCount;

import styled from 'styled-components';

import LanguagesList from '@features/inbox/languages/LanguagesList';

import { isIOS } from '@common/helpers/deviceUtil';

export const Root = styled.div`
  position: relative;
  height: 100%;
  background-color: ${({ theme }) => theme.bodyBackground};
  overflow: auto;
  ${({ theme }) => !theme.isDesktop && isIOS() && 'overscroll-behavior: none;'};
`;

export const CloseIcon = styled.div`
  position: absolute;
  top: ${({ theme }) => theme.space_15};
  right: ${({ theme }) => theme.space_15};
  color: ${({ theme }) => theme.textMain};
  padding: ${({ theme }) => theme.space_5} 0 ${({ theme }) => theme.space_5}
    ${({ theme }) => theme.space_5};
  cursor: pointer;
`;

export const ContentWrapper = styled.div`
  background-color: ${({ theme }) => theme.bodyBackground};
  margin-bottom: ${({ theme }) => theme.space_50};

  .menu-btn-logout {
    margin: ${({ theme }) => theme.space_10};
    padding: ${({ theme }) => theme.space_10};
    width: calc(100% - 20px);
  }
`;

export const StyledLanguagesList = styled(LanguagesList)`
  margin-top: ${({ theme }) => theme.space_5};
`;

import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import {
  PredictionsDiv,
  Label,
  PredictionWrapper,
} from '@features/events/components/markets/mapMarket/MapMarket.styled';
import { DisabledPrediction } from '@features/events/components/disabledPrediction';
import {
  MarketsLabel,
  EmptySpecialValue,
} from '@features/events/components/markets';

import { IMarket, PageType } from '@common/interfaces';

import { labelType, IDisabledMarkets } from './DisabledMarkets.types';

const DisabledMarkets: React.FC<IDisabledMarkets> = props => {
  const { t } = useTranslation();
  const { pageType, hasSpecialValue, predictionType, hasLabel } = props;

  const getPredictions = useCallback(() => {
    return (
      <div className={`live-${predictionType}`}>
        {predictionType === 'dual' ? (
          <>
            <DisabledPrediction />
            <DisabledPrediction />
          </>
        ) : (
          <>
            <DisabledPrediction />
            <DisabledPrediction />
            <DisabledPrediction />
          </>
        )}
      </div>
    );
  }, [hasSpecialValue]);

  return (
    <PredictionsDiv
      onClick={(ev): void => ev.stopPropagation()}
      isDetail={pageType === PageType.DETAIL}
    >
      {(hasLabel || hasSpecialValue || pageType === PageType.DETAIL) && (
        <Label pageType={pageType}>
          {hasLabel && (
            <MarketsLabel
              market={
                {
                  label: t(
                    `header_market_keys.${labelType[predictionType]}WAY`,
                  ),
                } as IMarket
              }
            />
          )}
          {hasSpecialValue && <EmptySpecialValue>-</EmptySpecialValue>}
        </Label>
      )}
      {pageType === PageType.DETAIL ? (
        <PredictionWrapper>{getPredictions()}</PredictionWrapper>
      ) : (
        getPredictions()
      )}
    </PredictionsDiv>
  );
};

export default DisabledMarkets;

/**
 * Hook to return is form submit button disable and tream inputs value
 * @example
 * const { disabled, onFormValueChange, onFormTrim } = useFormDisable({ form: FormInstance, config: FieldsConfig });
 * In <Form onFieldsChange={(_, fields): void => onFormValueChange(fields)} />
 * In <SubmitButton disabled={disabled} />
 * For trim all fields <Form onBlur={onFormTrim} />
 * For trim one field  <Form onBlur={onBlurFieldTrim} />
 * @param form: FormInstance
 * @param config: IRulesConfig
 */
import { FormInstance } from 'rc-field-form';
import { FieldData } from 'rc-field-form/lib/interface';
import forEach from 'lodash/forEach';
import isString from 'lodash/isString';
import { useState } from 'react';

import { IRulesConfig } from '@features/profile/components/forgotPasswordForm/makePasswordRecoveryConfig';

import {
  getIsDisableFormButton,
  setTrimmedField,
} from '@common/helpers/formHelper';

type useForm = {
  disabled: boolean;
  onFormValueChange: (fields: FieldData[], loading?: boolean) => void;
  onFormTrim: () => void;
  onBlurFieldTrim: (e) => void;
};

const useFormDisable = (form: FormInstance, config: IRulesConfig): useForm => {
  const [disabled, setDisabled] = useState(true);
  const [allFields, setAllFields] = useState<FieldData[]>([]);

  const onFormTrim = (): void => {
    forEach(allFields, field => {
      const [fieldName] = field.name as string[];
      setTrimmedField(form, fieldName, field?.value);
    });
  };

  const onBlurFieldTrim = (e): void => {
    const { value } = e.target;
    const { id } = e.target;
    const regExp = /^\s|\s$/;

    if (id && isString(value) && regExp.test(value)) {
      setTrimmedField(form, id, value);
      form.validateFields([id]);
    }
  };

  const onFormValueChange = (fields: FieldData[], loading?: boolean): void => {
    setAllFields(fields);
    setDisabled(getIsDisableFormButton(fields, form, config, loading));
  };

  return { disabled, onFormValueChange, onFormTrim, onBlurFieldTrim };
};

export default useFormDisable;

import { PageType } from '@common/interfaces';

export interface IDisabledMarkets {
  pageType: PageType;
  hasSpecialValue: boolean;
  hasLabel: boolean;
  predictionType: 'dual' | 'triple';
}
export enum labelType {
  dual = 2,
  triple = 3,
}

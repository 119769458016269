export enum IScoreKeyOrdered {
  'PT' = 'PT',
  'OT' = 'OT',
  '3P' = '3P',
  '2P' = '2P',
  '1P' = '1P',
  '2H' = '2H',
  '1H' = '1H',
  '5S' = '5S',
  '4S' = '4S',
  '3S' = '3S',
  '2S' = '2S',
  '1S' = '1S',
  'IN_PROGRESS' = 'IN_PROGRESS',
}

export interface ITimer {
  period_long: string;
  period_short: string;
  elapsed?: number;
  period_idx?: number;
  period_id: string;
  running: number;
  duration?: number | null;
  last_update?: number;
  runningSince?: number;
  eid?: string;
}

export type IPeriods = {
  [key in IScoreKeyOrdered]: (number | string)[] | null;
};

/* eslint-disable @typescript-eslint/explicit-function-return-type */
import { create } from 'zustand';

import { ICustomError, IUser } from '@common/interfaces';
import {
  IUserState,
  userDefaultState,
  UserLoadingState,
} from '@common/providers/user/actions/actionTypes';

export const useUserState = create<IUserState>(() => userDefaultState);

export const setLoggedInStatus = (status: UserLoadingState) =>
  useUserState.setState(() => {
    return { loadingState: status };
  });

export const setLoginError = (payload: ICustomError) =>
  useUserState.setState(() => {
    return {
      loadingState: UserLoadingState.LOGGED_OUT,
      error: payload,
      data: null,
    };
  });

export const authUserSuccess = (payload: IUser | null) =>
  useUserState.setState(() => {
    return {
      loadingState: UserLoadingState.LOGGED_IN,
      switchAccountLoading: false,
      error: null,
      data: payload,
    };
  });

export const authUserError = (payload: ICustomError) =>
  useUserState.setState(() => {
    return {
      loadingState: UserLoadingState.LOGGED_OUT,
      switchAccountLoading: false,
      data: null,
      error: payload,
    };
  });

export const resetUserState = () =>
  useUserState.setState(() => {
    return userDefaultState;
  }, true);

export const resetUserErrors = () =>
  useUserState.setState(() => {
    return { error: null };
  });

export const startSwitchingAccount = () =>
  useUserState.setState(() => {
    return { switchAccountLoading: true };
  });

export const startCreatingOnlineAccount = () =>
  useUserState.setState(() => {
    return { onlineAccountLoading: true };
  });

export const createOnlineAccountSuccess = () =>
  useUserState.setState(() => {
    return { onlineAccountSuccess: true, onlineAccountLoading: false };
  });

export const resetOnlineAccount = () =>
  useUserState.setState(() => {
    return {
      onlineAccountError: '',
      onlineAccountSuccess: false,
      onlineAccountLoading: false,
    };
  });

export const setOnlineAccountError = (payload: string) =>
  useUserState.setState(() => {
    return { onlineAccountError: payload, onlineAccountLoading: false };
  });

export const updateUserData = (payload: Partial<IUser>) =>
  useUserState.setState(state => {
    return { data: { ...(state.data as IUser), ...payload } };
  });

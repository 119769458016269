import {
  AUTO_WITHDRAW,
  BET_LIMIT,
  CROSS_PROVIDER_DEPOSIT_LIMIT,
  DEPOSIT_LIMIT,
  ILimitsProviderState,
  LOSS_LIMIT,
} from '@common/providers/limits/types';

const defaultLimitData = {
  error: null,
  loaded: false,
  data: [],
};

const defaultLimitHistory = {
  loading: false,
  data: null,
  error: null,
};

const defaultSelfExclusionForm = {
  loading: false,
  success: false,
  error: null,
};

const defaultState: ILimitsProviderState = {
  activeLimitsField: '',
  [BET_LIMIT]: defaultLimitData,
  [LOSS_LIMIT]: defaultLimitData,
  [DEPOSIT_LIMIT]: defaultLimitData,
  [AUTO_WITHDRAW]: defaultLimitData,
  [CROSS_PROVIDER_DEPOSIT_LIMIT]: defaultLimitData,
  limitHistory: defaultLimitHistory,
  selfExclusionForm: defaultSelfExclusionForm,
};

export default defaultState;

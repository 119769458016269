import React from 'react';
import map from 'lodash/map';
import reduce from 'lodash/reduce';
import groupBy from 'lodash/groupBy';
import values from 'lodash/values';

import { CashoutEvent } from '@features/cashout/components/cashoutEvent';

import { splitLabel } from '@common/helpers/cashoutHelper';
import { sortByTime } from '@common/helpers/eventsHelper/selectionModel';
import CombiMatrixModel from '@common/helpers/combinations/combiMatrixModel';
import { EventListTypes } from '@common/interfaces';

import * as S from './Selections.styled';
import { ISelections } from './Selections.types';

const Selections: React.FC<ISelections> = props => {
  const {
    selections,
    letters,
    type = 'cashout',
    even,
    betId,
    unavailableSelectionId,
  } = props;
  const grouped = sortByTime(values(groupBy(selections, 'event_id')));
  const result: JSX.Element[] = [];
  CombiMatrixModel?.resetLetter();

  return (
    <S.BetsContainer className="bets-container" data-qa="bets-container">
      {reduce(
        grouped,
        (extendedItem, selectionsGroup) => {
          const { event_id } = selectionsGroup[0];
          const labels = splitLabel(selectionsGroup[0].event_name || '');
          return extendedItem.concat(
            <CashoutEvent
              eventId={event_id}
              status_code={selectionsGroup[0]?.status_code}
              key={event_id}
              home={labels[0]}
              away={labels[1]}
              result={selectionsGroup[0]?.event_result_fulltime}
              bank={selectionsGroup[0]?.bank}
              selections={map(selectionsGroup, selection => ({
                ...selection,
                letter: letters ? CombiMatrixModel.getNextLetter() : '',
              }))}
              listType={type || EventListTypes.cashout}
              evenItem={even}
              betId={betId}
              unavailableSelectionId={unavailableSelectionId}
            />,
          );
        },
        result,
      )}
    </S.BetsContainer>
  );
};

export default Selections;

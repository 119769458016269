import styled from 'styled-components';

import { OddsComparisonSectionItem } from '../oddsComparisonSectionItem';

export const SectionWrapper = styled.div<{ isSidebarView?: boolean }>`
  display: grid;
  min-width: ${({ isSidebarView }) => (isSidebarView ? '410' : '330')}px;
  border-radius: 4px;
  background-color: ${props => props.theme.textActive};
  color: ${props => props.theme.textMain};
  overflow: hidden;
`;

export const SectionItemsWrapper = styled.div<{ isFullSection: boolean }>`
  max-height: ${({ isFullSection }) => (isFullSection ? ' 100%' : '180px')};
`;

export const OddsComparisonSectionHeader = styled(OddsComparisonSectionItem)``;

export const SectionActionButtonWrapper = styled.div<{ isWidgetView }>`
  display: grid;
  grid-template-rows: 1fr;
  width: 100%;
  padding-bottom: ${({ isWidgetView, theme }) =>
    isWidgetView ? theme.space_10 : theme.space_15};
  box-shadow: 0 -5px 5px 0 ${props => props.theme.textActive};
  background-color: ${props => props.theme.textActive};

  button {
    width: 95%;
    justify-self: center;
  }
`;

import React from 'react';
import reduce from 'lodash/reduce';

import {
  MarketsLabel,
  SpecialValue,
} from '@features/events/components/markets';
import {
  PredictionsDiv,
  Label,
  PredictionWrapper,
} from '@features/events/components/markets/mapMarket/MapMarket.styled';
import { Prediction } from '@features/events/components/prediction';
import { useEventsModel } from '@features/events/useEventsModel';

import { getType, getMarketLayout } from '@common/helpers/markets/marketModel';
import { sortPredictionByType } from '@common/helpers/eventsHelper/predictionModel';
import { isDesktopView } from '@common/helpers/deviceUtil';
import { isWinner } from '@common/helpers/eventsHelper/eventStatusHelper';
import { PageType } from '@common/interfaces';
import { useMarketPredictions } from '@common/hooks/useMarketPredictions';

import { IExtraMarketMultiple } from './ExtraMarketMultiple.types';

const ExtraMarketMultiple: React.FC<IExtraMarketMultiple> = props => {
  const {
    market,
    hasLabel,
    hasSpecialValue,
    listType,
    pageType,
    eventId,
  } = props;

  const { event } = useEventsModel(eventId, listType);
  const predictions = useMarketPredictions(market, listType);
  let marketLayout = getMarketLayout(market, predictions);

  // show 4-column layout only for desktop list view, single otherwise
  if (
    marketLayout === 'quarter' &&
    (!isDesktopView || pageType === PageType.DETAIL)
  ) {
    marketLayout = 'single';
  }

  return (
    <PredictionsDiv
      className="live-multiple"
      isDetail={pageType === PageType.DETAIL}
    >
      {!isWinner(event) && (hasLabel || hasSpecialValue) && (
        <Label pageType={pageType}>
          <MarketsLabel market={market} />
          <SpecialValue
            market={market}
            hasSpecialValue={hasSpecialValue}
            pageType={pageType}
          />
        </Label>
      )}
      <PredictionWrapper className={marketLayout}>
        {reduce(
          sortPredictionByType(predictions, getType(market)),
          (children: React.ReactElement[], prediction) => {
            children.push(
              <Prediction
                key={prediction.id}
                listType={listType}
                pageType={pageType}
                eventId={eventId}
                marketId={market.id}
                predictionId={prediction.id}
                withLabel={!!isWinner(event)}
              />,
            );
            return children;
          },
          [],
        )}
      </PredictionWrapper>
    </PredictionsDiv>
  );
};

export default ExtraMarketMultiple;

import isObject from 'lodash/isObject';
import map from 'lodash/map';

import ILoggerConfig from '@packages/logger/core/ILoggerConfig';
import ILogger from '@packages/logger/core/ILogger';

export enum MessageType {
  INFO = 'info',
  ERROR = 'error',
}

export interface IMessage {
  type: MessageType;
  title: string;
  value: string;
}

export default class Logger implements ILogger {
  private config: ILoggerConfig;

  private messages: IMessage[];

  constructor(config: ILoggerConfig) {
    this.config = config;
    this.messages = [] as IMessage[];
  }

  private static serialize(item: string): string {
    return isObject(item) ? JSON.stringify(item) : item;
  }

  setMessage(message: IMessage): void {
    this.messages.push(message);
  }

  getMessages(): IMessage[] {
    return this.messages;
  }

  resetMessages(): void {
    this.messages = [];
  }

  log(...args: string[]): void {
    if (this.config.logToConsole) {
      this.setMessage({
        type: MessageType.ERROR,
        title: 'Message from log',
        value: map(args, Logger.serialize).join(' '),
      });
      // eslint-disable-next-line no-console
      console.log(map(args, Logger.serialize).join(' '));
    }
  }

  error(...args: string[]): void {
    this.log(...args);
  }

  info(...args: string[]): void {
    this.log(...args);
  }

  warn(...args: string[]): void {
    this.log(...args);
  }
}

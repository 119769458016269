import { useEffect, useRef, useState } from 'react';

import deleteTimeout from '@common/helpers/timeoutHelper';
import { TTimeout } from '@common/interfaces';
/**
 * Hook for logic related with form submit
 * with keyboard Enter key
 */
const useFormSubmitWithKeyboard = (): void => {
  let form: HTMLFormElement | null = null;
  const keyPressHandler = (event: KeyboardEvent): void | undefined => {
    if (event.key === 'Enter') {
      const { activeElement } = document;
      // We need to check if we currently focused on virtual input field
      if (
        activeElement &&
        activeElement.tagName === 'INPUT' &&
        activeElement.closest('.virtual_input')
      ) {
        // try to find parent form element to be able to click on submit button
        form = activeElement.closest('form');

        // click on submit button
        if (form) {
          const submitButton: HTMLElement | null = form.querySelector(
            'button[type="submit"]',
          );
          submitButton?.click();
        }

        return;
      }

      // If we don't have an input element ( its hidden by action )
      // then we try to find submit button from current form where that input was been placed
      if (form) {
        const submitButton: HTMLElement | null = form.querySelector(
          'button[type="submit"]',
        );
        submitButton?.click();
        form = null;
      }
    }
  };

  useEffect((): (() => void) => {
    window.addEventListener('keydown', keyPressHandler);

    return () => {
      window.removeEventListener('keydown', keyPressHandler);
    };
  }, []);
};

export const useLongPress = (
  callback,
  ms = 5000,
  unsubscribeCb,
  allowedOnDesk,
): Record<string, () => void> => {
  const [startLongPress, setStartLongPress] = useState(false);
  const timerinterval = useRef<TTimeout>(null);

  useEffect(() => {
    return () => {
      unsubscribeCb();
      deleteTimeout(timerinterval.current);
    };
  }, []);

  useEffect(() => {
    if (startLongPress) {
      callback();
      setStartLongPress(false);
      deleteTimeout(timerinterval.current);
    }
  }, [startLongPress]);

  const timer = (start: boolean): void => {
    if (!start) {
      setStartLongPress(false);
      deleteTimeout(timerinterval.current);
    } else {
      timerinterval.current = setTimeout(setStartLongPress, ms, true);
    }
  };

  return {
    onMouseDown: (): void => allowedOnDesk && timer(true),
    onMouseUp: (): void => allowedOnDesk && timer(false),
    onTouchStart: (): void => timer(true),
    onTouchEnd: (): void => timer(false),
  };
};

export default useFormSubmitWithKeyboard;

import React from 'react';
import { useTranslation } from 'react-i18next';

import { useThemeScheme } from '@features/core/theming/contexTheme';
import CustomLink from '@features/core/routing/CustomLink';
import {
  PageName,
  StaticSlug,
  StaticSlugPageName,
} from '@features/core/routing';
import { useServices } from '@features/core/services';

import { setLocation } from '@common/providers/router/helper';
import { isTabletView } from '@common/helpers/deviceUtil';
import { useUserState } from '@common/providers/user/useUserState';

import { Icon } from '@ui/components/icon';

import LegalAgeWrapper from './LegalAgeIcon.styled';

const LegalAgeIcon = (): JSX.Element => {
  const { t } = useTranslation();
  const { domainLang } = useServices();
  const { theme } = useThemeScheme();
  const isTablet = isTabletView();
  const isLoggedIn = useUserState(s => s.loadingState);

  const iconSize = `${isTablet && isLoggedIn ? '28' : '34'}px`;
  return (
    <LegalAgeWrapper iconSize={iconSize}>
      <CustomLink
        onClick={
          (): void =>
            setLocation(PageName.RESPONSIBILITY, {
              id: StaticSlug[StaticSlugPageName.AGE_WARNING][domainLang],
            })
          // eslint-disable-next-line react/jsx-curly-newline
        }
        ariaLabel={t('common.links.minor_protection')}
        dataQa="btn-eighteen-plus"
      >
        <Icon
          name="legal-age"
          width={iconSize}
          height={iconSize}
          fill={theme.textMain}
        />
      </CustomLink>
    </LegalAgeWrapper>
  );
};

export default LegalAgeIcon;

import styled from 'styled-components';

import { TextLarge, TextSM } from '@features/core/styles/text.styled';

export const WrapperRejected = styled.div`
  background: ${({ theme }) => theme.textMain};
  padding: ${({ theme }) => theme.space_10};
  color: ${({ theme }) => theme.textActive};
  display: grid;
  grid-auto-flow: column;
  grid-gap: ${({ theme }) => theme.space_10};
  justify-content: start;
  align-items: center;
  align-content: center;
`;

export const IconWrapper = styled.div`
  svg path {
    fill: ${({ theme }) => theme.statusError};
  }
`;

export const Content = styled.div`
  display: grid;
  grid-auto-flow: row;
  align-items: center;
  position: relative;
`;

export const Title = styled(TextLarge)`
  font-weight: ${({ theme }) => theme.fontBold};
  font-size: ${props => props.theme.fontSM};
  color: ${({ theme }) => theme.statusError};
`;

export const ErrorMessage = styled(TextSM)`
  margin: ${({ theme }) => theme.space_5} 0 0;
`;

import { useEffect } from 'react';

import services from '@features/core/services';

import { ExtendedDocument } from '@packages/provider/MainProvider.types';

import { APP_REFRESHED } from '@common/constants/cookie';
import {
  autoLoginUser,
  initialize,
  onAfterSleep,
  onBeforeSleep,
  setOutsideAppTime,
} from '@common/helpers/appStateHelper';
import { beat } from '@common/providers/application/useAppState';

const BEAT_INTERVAL = 1000 * 30;

const useVisibilityChange = (): void => {
  const { cookie } = services;
  const doc = document as ExtendedDocument;

  const handleVisibilityChange = (): void => {
    const lastRefreshTime = cookie.get(APP_REFRESHED);
    if (
      document.visibilityState === 'hidden' ||
      doc.hidden ||
      doc.webkitHidden
    ) {
      setOutsideAppTime();
      // User has switched to another tab or rolled up the site
      onBeforeSleep();
    } else {
      // User has come back to the tab or site is visible
      autoLoginUser();
      if (!lastRefreshTime) {
        // 2 minutes after sleep mode has gone
        onAfterSleep();
      }
    }
  };

  useEffect(() => {
    initialize();
    setInterval(() => beat(), BEAT_INTERVAL);

    // will be called when site visibility changes
    document.addEventListener('visibilitychange', handleVisibilityChange);

    return () => {
      document.removeEventListener('visibilitychange', handleVisibilityChange);
    };
  }, []);
};

export default useVisibilityChange;

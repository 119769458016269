import includes from 'lodash/includes';
import some from 'lodash/some';
import filter from 'lodash/filter';

import services from '@features/core/services';
import { loadConfig, configTypes } from '@features/core/loadConfig';

import { EXCLUDE_MARKETS, MARKET_MATRIX } from '@common/constants/config';
import { getCategoryNameById } from '@common/helpers/categories/categories';
import {
  IEvent,
  ICategory,
  PageType,
  IMarket,
  IMarketType,
  MarketType,
  IUser,
} from '@common/interfaces';
import {
  isFieldsHockey,
  isFootball,
  isIceHockey,
  isRealFootball,
} from '@common/helpers/eventsHelper/eventTypeHelper';
import { getSportName } from '@common/helpers/categories/categoriesModel';
import { useAppState } from '@common/providers/application/useAppState';

import { isLiveOrSoon, isOvertime } from '../eventsHelper/eventStatusHelper';

export const {
  desktopHalfTimeMarkets,
  desktopMarketKeys,
  mobileMarketKeys,
  dropDownMarketsKeys,
} = loadConfig(configTypes.markets, MARKET_MATRIX);

export const desktopPenaltyMarkets = {
  football: [
    MarketType.PENALTY_SHOOTOUT,
    'PLACEHOLDER',
    'PLACEHOLDER',
    'PLACEHOLDER',
  ],
  icehockey: [
    MarketType.PENALTY_SHOOTOUT,
    'PLACEHOLDER',
    'PLACEHOLDER',
    'PLACEHOLDER',
  ],
  default: [],
};

const periodTypes = {
  football: 'PERIOD_HALFTIME',
  futsal: 'PERIOD_HALFTIME',
  handball: 'PERIOD_HALFTIME',
  rugby: 'PERIOD_HALFTIME',
  american: 'PERIOD_QUARTER',
  icehockey: 'PERIOD_THIRD',
  fieldshockey: 'PERIOD_HALFTIME',
  basketball: 'PERIOD_QUARTER',
  tennis: 'PERIOD_SET',
  badminton: 'PERIOD_SET',
  tabletennis: 'PERIOD_SET',
  volleyball: 'PERIOD_SET',
  beachvolleyball: 'PERIOD_SET',
  baseball: 'PERIOD_INNING',
};

const notFullTimeMarketsKeysEndings = [
  // '_LIVE',
  '_HT',
  'FIRST_HALFTIME',
  '_FIRST_HALF',
  '_THIRD',
  '_QUARTER',
  '_INNING',
  'SET',
];

export const excludeMarkets = (
  markets: IMarket[],
  user: IUser | null,
  eventType: string,
): IMarket[] => {
  const { affiliateGroup } = user || {};
  if (services.config.get(EXCLUDE_MARKETS)) {
    const { marketsToExclude } = useAppState.getState();
    return filter(markets, market => {
      if (
        affiliateGroup &&
        marketsToExclude[eventType][market?.type]?.allowed_affiliates[
          affiliateGroup
        ]
      ) {
        return true;
      }
      if (
        affiliateGroup &&
        marketsToExclude[eventType][market?.type]?.restricted_affiliates[
          affiliateGroup
        ]
      ) {
        return false;
      }
      return !marketsToExclude[eventType][market?.type]?.disabled;
    });
  }
  return markets;
};

export const isNotFullTime = (currentType: IMarketType): boolean => {
  return some(notFullTimeMarketsKeysEndings, marketKeyEnding =>
    includes(currentType, marketKeyEnding),
  );
};

export const getMarketTypesById = (
  topCategoryId: number,
  pageType: string,
): IMarketType[] => {
  if (!topCategoryId) {
    return mobileMarketKeys[pageType].football;
  }
  const categoryName = getCategoryNameById(topCategoryId);
  return (
    mobileMarketKeys[pageType][categoryName] ||
    mobileMarketKeys[pageType].default
  );
};

export const getHalfTimeMarkets = (topCategoryId: number): [] => {
  const categoryName = getCategoryNameById(topCategoryId);
  return desktopHalfTimeMarkets[categoryName] || desktopHalfTimeMarkets.default;
};

export const getPenaltyMarkets = (topCategoryId: number): [] => {
  const categoryName = getCategoryNameById(topCategoryId);
  return desktopPenaltyMarkets[categoryName] || desktopPenaltyMarkets.default;
};

export const getDesktopMarketsKeys = (
  event: IEvent,
  category: ICategory,
): string[] => {
  const sportType = getSportName(category?.top_category_id);

  if (!isLiveOrSoon(event)) {
    return (
      desktopMarketKeys.sport[sportType] || desktopMarketKeys.sport.default
    );
  }
  return desktopMarketKeys.live[sportType] || desktopMarketKeys.live.default;
};

export const getPeriodTypeForMarket = (
  topCategoryId: number,
  currentType: IMarketType,
  event?: IEvent,
): string => {
  if (event && isOvertime(event)) {
    return 'PERIOD_OVERTIME';
  }
  if (currentType === 'PENALTY_SHOOTOUT') {
    return 'PERIOD_PENALTIES';
  }
  if (isNotFullTime(currentType)) {
    if (
      currentType === 'FIRST_HALFTIME' ||
      currentType === 'OVER_UNDER_FIRST_HALF'
    ) {
      return 'PERIOD_HALFTIME';
    }
    const categoryName = getCategoryNameById(topCategoryId);
    return periodTypes[categoryName];
  }
  return 'PERIOD_FULLTIME';
};

export const getDropDownMarkets = (
  event: IEvent,
  page: PageType,
  top_category_id: string,
): string[] => {
  if (page === 'live') {
    if (isFootball(event)) {
      return dropDownMarketsKeys.football_live;
    }
    if (isIceHockey(top_category_id)) {
      return dropDownMarketsKeys.icehockey_live;
    }
    if (isFieldsHockey(top_category_id)) {
      return dropDownMarketsKeys.fields_hockey_live;
    }
  }
  // eslint-disable-next-line sonarjs/no-collapsible-if
  if (page === PageType.SPORT) {
    if (isFootball(event)) {
      return dropDownMarketsKeys.football_prematch;
    }
  }
  return [];
};

export const hasDropDowns = (
  event: IEvent,
  top_category_id: string,
  pageType: string,
  categories: string[],
): boolean => {
  if (pageType === PageType.HOME || categories.length > 1) {
    return false;
  }
  if (isRealFootball(event, top_category_id)) {
    return true;
  }
  return (
    isLiveOrSoon(event) &&
    pageType === PageType.LIVE &&
    (isIceHockey(top_category_id) || isFieldsHockey(top_category_id))
  );
};

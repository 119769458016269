import {
  IAccount,
  IPayment,
  IPaymentOperation,
  IPaymentPayload,
  IPaymentService,
} from '@common/interfaces';

export interface IPaymentProviderState {
  loading: boolean;
  error: string | null;
  account: IAccount;
  deposit: IPayment;
  withdrawal: IPayment;
  services: IPaymentService[];
  hasPaymentInfoPopup: boolean;
}

export interface IPaymentServicePayload {
  accountId: string;
  token: string;
  operation: IPaymentOperation;
  country?: string;
}

export interface IPaymentRequestPayload extends IPaymentServicePayload {
  success_url?: string;
  failure_url?: string;
  pending_url?: string;
  service: string;
  data: IPaymentPayload;
}

export interface IRequestPaymentSuccess {
  data: IPayment;
  operation: IPaymentOperation;
}

export enum IPaymentStateTypes {
  account = 'account',
  services = 'services',
  deposit = 'deposit',
  withdrawal = 'withdrawal',
}

export interface IFetchPaymentServices {
  services: IPaymentService[];
}

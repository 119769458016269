import DefaultTimerFactory from '@features/events/components/scoreboards/timers/defaultTimerFactory/DefaultTimerFactory';

import { getCurrentPeriod } from '@common/helpers/eventsHelper/scoreboards/timerModel';

const options = [
  ({ timer, shortTimer, category }): string =>
    getCurrentPeriod(timer, shortTimer, category),
];

const GenericPeriods = DefaultTimerFactory(options);

export default GenericPeriods;

import min from 'lodash/min';
import uniq from 'lodash/uniq';
import reduce from 'lodash/reduce';
import maxBy from 'lodash/maxBy';
import minBy from 'lodash/minBy';
import map from 'lodash/map';
import includes from 'lodash/includes';
import forEach from 'lodash/forEach';
import some from 'lodash/some';
import assign from 'lodash/assign';
import replace from 'lodash/replace';
import split from 'lodash/split';

import services from '@features/core/services';
import i18n from '@features/core/translation';

import { TOKEN, SESSION_ID } from '@common/constants/cookie';
import { MAX_BS_EVENTS, MAX_BS_SELECTIONS } from '@common/constants/config';
import {
  ISelection,
  IBettingslip,
  ISubmittedBettingslip,
  IPossibleSizes,
  ICompositesT,
  IBettingslipErrorTags,
} from '@common/interfaces';
import { isDesktopView, fixDecimals } from '@common/helpers/deviceUtil';
import {
  getOdds,
  getRange,
} from '@common/helpers/eventsHelper/predictionModel';
import {
  BetView,
  ISetBettingSlipErrorPayload,
} from '@common/providers/bettingslip/types';
import Combinations from '@common/helpers/combinations/combinations';
import {
  hasFrozenAcccount,
  isYearlyFrozen,
  hasNoVerifiedEmail,
} from '@common/helpers/paymentsHelper/paymentModel';
import {
  getBanks,
  getSelectionsByRegularEvent,
  getSelectionsByBankEvent,
  expandLeg,
  isMultiway,
  getEventsCount,
  getTotalAmount,
  getTaxPercentForSize,
  hasSelections,
  getMinSize,
  countSuspendedSelections,
  validateBanks,
  getLockedSelections,
  rangesIntersects,
  combine,
  getLegsForSize,
  getLegsCount,
} from '@common/helpers/bettingSlipHelper/bettingSlipModel';
import {
  groupByEvent,
  groupByMarket,
} from '@common/helpers/eventsHelper/selectionModel';
import LegGroup from '@common/helpers/combinations/legGroup';
import numeral from '@common/helpers/numeralHelper';
import { IUserWalletType, IUserType } from '@common/interfaces/user/IUser';
import {
  IBettingslipType,
  IBSWalletType,
} from '@common/interfaces/bettingslip/IBettingslip';
import { STATE_REJECTED } from '@common/providers/bettingslip/state';

import {
  calculateQuotenboostSelections,
  hasQuotenboostSelections,
  isQuotenboost,
} from '../eventsHelper/eventStatusHelper';
import {
  formatBalance,
  getActiveWalletBalance,
  hasBonusWallets,
  hasShopEMoneyWallets,
} from '../paymentsHelper/walletsHelper';

const isDesktop = isDesktopView();

/**
 * getLegsCountForSize
 * Return number of possible legs. Usefull to populate possible systems menu.
 *
 * @param {IBettingslip} bettingslip
 * @param {number} size
 * @returns {number} legsCount
 */
export const getLegsCountForSize = (
  bettingslip: IBettingslip,
  size: number,
): number => {
  let legsCount = 0; // This is what we will return.
  const banksCount = getBanks(bettingslip).length; // Banks count.
  // Substract banks from size.
  const deltaSize = size - banksCount;

  // Is multiway or has banks.
  // Multiway: We need to expand legs with more than one selection per event.
  // Banks: We need to separate them and add to all legs.
  if (isMultiway(bettingslip) || banksCount) {
    // No bank events. May be all.
    const selectionsByEvent = getSelectionsByRegularEvent(bettingslip);
    const selectionsByBankEvent = getSelectionsByBankEvent(bettingslip);
    // Multiply selections count of all bank events.
    const banksMultiplier = reduce(
      selectionsByBankEvent,
      (sum, bankEvents) => {
        return sum * bankEvents.length;
      },
      1,
    );

    let legsMultiplier = 1;
    combine(selectionsByEvent, deltaSize, {
      iteratorElement: (leg, idx) => {
        // First element? Reset multiplier.
        if (idx === 0) {
          legsMultiplier = 1;
        }
        // Multiply events selections count according combinations.
        legsMultiplier *= leg.length;
        // Last element? Add to legsCount.
        if (idx === deltaSize - 1) {
          legsCount += legsMultiplier;
        }
        // Return leg.
        // This has no purpose in this case. We are not using returned data.
        // Only doing stuff in iterator.
        return leg;
      },
    });
    // Remember banks.
    legsCount *= banksMultiplier;
    return legsCount;
  }
  // Is not multiway and has no banks.
  // No need to expand. Return combinations length.

  return Combinations.get.call(Combinations, size, getEventsCount(bettingslip))
    .length;
};

/**
 * calculateLegsCount
 * calculateLegsCount, calculates legs
 *
 * @param {IBettingslip} bettingslip
 * @returns {number} count
 */
export const calculateLegsCount = (bettingslip: IBettingslip): number => {
  const counts = map(bettingslip.size, size =>
    getLegsCountForSize(bettingslip, size),
  );
  return reduce(counts, (a, b) => a + b, 0);
};

/**
 * getAmountPerLeg
 * getAmountPerLeg, returns amount per leg
 *
 * @param {IBettingslip} bettingslip
 * @returns {number} amountPerLeg
 */
export const getAmountPerLeg = (bettingslip: IBettingslip): number => {
  const totalAmount = getTotalAmount(bettingslip);
  const legsCount = getLegsCount(bettingslip);
  return totalAmount / legsCount;
};

/**
 * getLegsGroup
 * getLegsGroup, returns legs groups for betting slip
 *
 * @param {IBettingslip} bettingslip
 * @returns {LegGroup[]} legGroup
 */
export const getLegsGroup = (bettingslip: IBettingslip): LegGroup[] => {
  return reduce(
    bettingslip.size,
    (acc: LegGroup[], size: number) => {
      acc.push(
        new LegGroup({
          legs: getLegsForSize(bettingslip, size),
          taxPercent: getTaxPercentForSize(bettingslip, size),
          amountPerLeg: getAmountPerLeg(bettingslip),
        }),
      );
      return acc;
    },
    [],
  );
};

/**
 * getTotalTax
 * getTotalTax, calculates total tax
 *
 * @param {IBettingslip} bettingslip
 * @returns {number} totalTax
 */
export const getTotalTax = (bettingslip: IBettingslip): number => {
  return reduce(
    getLegsGroup(bettingslip),
    (summ, group) => {
      return summ + group.getTotalTax(bettingslip.user.taxMethod);
    },
    0,
  );
};

/**
 * getTotalStake
 * getTotalStake, returns total stake
 *
 * @param {IBettingslip} bettingslip
 * @returns {number} totalStake
 */
export const getTotalStake = (bettingslip: IBettingslip): number => {
  const totalAmount = getTotalAmount(bettingslip);
  const totalTax = getTotalTax(bettingslip);
  const totalStake = totalAmount - totalTax;
  return totalStake || 0;
};

/**
 * getStake
 * getStake, returns stake
 *
 * @param {IBettingslip} bettingslip
 * @returns {number} stake
 */
export const getStake = (bettingslip: IBettingslip): number => {
  const totalStake = getTotalStake(bettingslip);
  const legsCount = getLegsCount(bettingslip);
  return (
    numeral(
      fixDecimals(legsCount ? totalStake / legsCount : 0, { precision: 2 }),
    ).value() || 0
  );
};

/**
 * validate
 * validate, check if betting slip is valid
 *
 * @param {IBettingslip} bettingslip
 * @param {boolean} isPreValidation
 * @returns {ISetBettingSlipErrorPayload | null} error
 */
export const validate = (
  bettingslip: IBettingslip,
  isPreValidation: boolean,
): ISetBettingSlipErrorPayload | null => {
  // Warnings are displayed all time, errors are displayed only when trying to submit.
  // options.warning as true only checks warnings validations.
  // options.warning as false checks all validations.

  if (bettingslip.state === STATE_REJECTED) {
    return {
      message: i18n.t('bettingslip.rejected'),
      data: {
        tag: IBettingslipErrorTags.STATE_REJECTED,
        prevalidate: false,
      },
    };
  }

  if (!bettingslip.user.username && !isPreValidation) {
    return {
      message: i18n.t('bettingslip.login_msg'),
      data: {
        tag: IBettingslipErrorTags.NO_USER,
        prevalidate: false,
      },
    };
  }
  const { selections, betPackerSelections, restoredSelections } = bettingslip;
  // No selections. Not warning, only on submit error. But must be above other validations.
  if (
    !hasSelections(selections, betPackerSelections, restoredSelections) &&
    !isPreValidation
  ) {
    return {
      message: i18n.t('bettingslip.err_addSelection'),
      data: {
        tag: IBettingslipErrorTags.NO_SELECTIONS,
        prevalidate: false,
      },
    };
  }
  // Min size.
  if (getEventsCount(bettingslip) < getMinSize(bettingslip)) {
    const value = String(getMinSize(bettingslip) - getEventsCount(bettingslip));
    return {
      message: `${i18n.t('bettingslip.err_atLeast')} ${value} ${
        parseFloat(value) > 1
          ? i18n.t('bettingslip.err_addBets')
          : i18n.t('bettingslip.err_addBet')
      }`,
      data: {
        tag: IBettingslipErrorTags.MIN_SIZE,
        value,
        prevalidate: true,
      },
    };
  }

  // Selections suspended.
  const count = countSuspendedSelections(bettingslip);
  if (count) {
    return {
      message: i18n.t('bettingslip.err_predLocked'),
      data: {
        tag: IBettingslipErrorTags.SELECTIONS_SUSPENDED,
        prevalidate: true,
        count,
      },
    };
  }
  // Max events or max selections.
  if (
    // Fortuna bet is patent affiliate id 12680
    (getEventsCount(bettingslip) > 10 &&
      includes(bettingslip.user.lineage, '12680')) ||
    getEventsCount(bettingslip) >
      (services.config.get(MAX_BS_EVENTS) as number) ||
    bettingslip.selections.length >
      (services.config.get(MAX_BS_SELECTIONS) as number)
  ) {
    return {
      message: i18n.t('bettingslip.err_manyEvents'),
      data: {
        tag: IBettingslipErrorTags.MAX_EVENTS,
        prevalidate: true,
      },
    };
  }

  // Too Many banks.
  if (validateBanks(bettingslip)) {
    return {
      message: i18n.t('bettingslip.err_manyBanks'),
      data: {
        tag: IBettingslipErrorTags.TOO_MANY_BANKS,
        prevalidate: true,
      },
    };
  }

  if (
    bettingslip.type !== 'single' &&
    getLockedSelections(bettingslip).length
  ) {
    const labels = getLockedSelections(bettingslip);
    const value = `${labels[0].event.label} / ${labels[1].event.label}`;
    const translationLabels = split(value, ' / ');
    return {
      message: replace(
        replace(
          i18n.t('bettingslip.err_lockedCombination'),
          '#EVENT_1#',
          translationLabels[0],
        ),
        '#EVENT_2#',
        translationLabels[1],
      ),
      data: {
        tag: IBettingslipErrorTags.LOCKED_COMBINATIONS,
        value,
        prevalidate: true,
      },
    };
  }

  // Invalid system.
  if (
    (!getLegsCount(bettingslip) ||
      some(
        bettingslip.size,
        size =>
          size > getEventsCount(bettingslip) || size < getMinSize(bettingslip),
      )) &&
    !isPreValidation
  ) {
    return {
      message: i18n.t('bettingslip.err_invSys'),
      data: {
        tag: IBettingslipErrorTags.INVALID_SYSTEM,
        prevalidate: false,
      },
    };
  }

  if (
    some(bettingslip.selections, selection =>
      isQuotenboost(selection.event?.label),
    ) &&
    (bettingslip.user.type !== IUserType.www || bettingslip.type !== 'single')
  ) {
    return {
      message: i18n.t('bettingslip.err_invType'),
      data: {
        tag: IBettingslipErrorTags.INVALID_TYPE,
        prevalidate: false,
      },
    };
  }

  // Min stake.
  const minStake = bettingslip.user.minStake || '0';
  if (
    getStake(bettingslip) + getTotalTax(bettingslip) < parseFloat(minStake) &&
    !isPreValidation
  ) {
    const value = formatBalance(minStake, { reversePresentation: true });
    return {
      message: `${i18n.t('bettingslip.err_minStake')} ${value}`,
      data: {
        tag: IBettingslipErrorTags.MIN_STAKE,
        value,
        prevalidate: false,
      },
    };
  }

  if (hasFrozenAcccount(bettingslip.user) && !isPreValidation) {
    return {
      message: isYearlyFrozen(bettingslip.user)
        ? i18n.t('bettingslip.not_verified_user_yearly')
        : i18n.t('bettingslip.not_verified_user'),
      data: {
        tag: isYearlyFrozen(bettingslip.user)
          ? IBettingslipErrorTags.NOT_VERIFIED_YEARLY
          : IBettingslipErrorTags.NOT_VERIFIED_72_HRS,
        prevalidate: false,
      },
    };
  }

  if (hasNoVerifiedEmail(bettingslip.user) && !isPreValidation) {
    return {
      message: i18n.t('bettingslip.pleaseConfirmYourEmailToContinueBetting'),
      data: {
        tag: IBettingslipErrorTags.NOT_VERIFIED_EMAIL,
        prevalidate: false,
      },
    };
  }

  if (
    bettingslip.walletType !== IBSWalletType.REGULAR &&
    hasQuotenboostSelections(bettingslip.selections) &&
    !isPreValidation
  ) {
    return {
      message: IBSWalletType.SHOP
        ? i18n.t('bettingslip.not_payable_by_shop_money')
        : i18n.t('bettingslip.not_payable_by_bonus_money'),
      data: {
        tag: IBSWalletType.SHOP
          ? IBettingslipErrorTags.NOT_PAYABLE_BY_SHOP_MONEY
          : IBettingslipErrorTags.NOT_PAYABLE_BY_BONUS_MONEY,
        prevalidate: false,
      },
    };
  }

  if (
    calculateQuotenboostSelections(bettingslip.selections) > 1 &&
    !isPreValidation
  ) {
    return {
      message: i18n.t('bettingslip.only_one_boost_in_betslip'),
      data: {
        tag: IBettingslipErrorTags.ONLY_ONE_BOOST_IN_BETSLIP,
        prevalidate: false,
      },
    };
  }

  const bonusWalletBalance = getActiveWalletBalance(IUserWalletType.bonus);

  if (
    hasBonusWallets() &&
    bettingslip.walletType === IBSWalletType.BONUS &&
    (bonusWalletBalance < bettingslip.totalStake || bonusWalletBalance === 0)
  ) {
    return {
      message: i18n.t('bettingslip.not_enough_bonus_money'),
      data: {
        tag: IBettingslipErrorTags.NOT_ENOUGH_BONUS_MONEY,
        prevalidate: false,
      },
    };
  }
  /*   if (bettingslip.error) {
    bettingslip.error.message = i18n.t('bettingslip.error');
    return bettingslip.error;
  } */
  return null;
};

/**
 * getPossibleSizes
 * create an array of possible system combination
 *
 * @param {IBettingslip} bettingslip
 * @returns {IPossibleSizes[]} systems
 */
export const getPossibleSizes = (
  bettingslip: IBettingslip,
): IPossibleSizes[] => {
  const possibleSizes: IPossibleSizes[] = [];
  const eventsCount = getEventsCount(bettingslip);
  const banksCount = getBanks(bettingslip).length;
  const total = eventsCount - banksCount;
  const currentSizes = bettingslip.size;
  // Start sizes at the higher of min selections, 2, or banksCount + 1.
  const start = getMinSize(bettingslip, banksCount + 1);
  // Iterate systems from start to end.
  for (let i = start; i <= eventsCount; i++) {
    const legsCount = getLegsCountForSize(bettingslip, i);
    if (legsCount) {
      const sizeObj: IPossibleSizes = {
        value: i,
        selected: includes(currentSizes, i),
        banksCount,
        size: i - banksCount,
        total,
        legsCount,
      };
      possibleSizes.push(sizeObj);
    }
  }
  return possibleSizes;
};

/**
 * Set best_odds attr in selections.
 * Set just current odds if is not a multiway bet.
 * If bet is multiway, when 2 selections are not possible at the same time,
 *  put current odds at selection with higher odds, and 0 to the other.
 *
 *  @param {IBettingslip} bettingslip
 *  @param {boolean} isBest
 *  @returns {IBettingslip} bestOdds
 */
export const setOdds = (
  bettingslip: IBettingslip,
  isBest = true,
): IBettingslip => {
  // Is multiway.
  let updated: IBettingslip;

  if (isMultiway(bettingslip)) {
    // Setting best odds to 0
    // so it's not calculated at the end
    const { selections } = bettingslip;
    const updatedSelections = map(
      selections,
      (selection): ISelection => ({
        ...selection,
        best_odds: 0,
      }),
    );

    updated = { ...bettingslip, selections: updatedSelections };

    const updatedSelectionsGroupedByEvent = reduce(
      groupByEvent(updated.selections),
      (result: ISelection[], eventSelections) => {
        let maxOddsSelection;
        if (isBest) {
          maxOddsSelection = maxBy(eventSelections, s => getOdds(s));
        } else {
          maxOddsSelection = minBy(eventSelections, s => getOdds(s));
        }

        let maxOdds = getOdds(maxOddsSelection);
        let bestOddsCombinations = [maxOddsSelection];
        // Group selections by market same here, not possible to win both if 2 selections set for one markets
        //  showing highest odds
        const selectionsByMarket = groupByMarket(eventSelections);
        const marketsCount = selectionsByMarket.length;
        // Make combinations of selections grouped by market. Check all possible sizes.
        for (let size = 2; size <= marketsCount; size++) {
          let selectionCombinations: Array<ISelection[]> = [];
          combine(selectionsByMarket, size, {
            iteratorLeg: (leg: Array<ISelection[]>): Array<ISelection[]> => {
              selectionCombinations = selectionCombinations.concat(
                expandLeg(leg),
              );
              return leg;
            },
          });
          for (let i = 0; i < selectionCombinations.length; i++) {
            // Determine if all selections of this combination can be true at the same time.
            const current = selectionCombinations[i];
            let possible = true;
            for (let x = 0; x < current.length; x++) {
              for (let y = x + 1; y < current.length; y++) {
                const r1 = getRange(current[x]);
                const r2 = getRange(current[y]);
                // Ranges must intersect to be possible at the same time.
                if (!rangesIntersects(r1, r2)) {
                  possible = false;
                  break;
                }
              }
              if (!possible) {
                break; // Not possible, break x iteration.
              }
            }
            /* eslint-disable no-continue */
            if (!possible) {
              continue; // Not possible, continue to next i iteration.
            }
            /* eslint-enable no-continue */
            // Sum odds of all selections.
            const odds = reduce(
              current,
              (oddsSum, selection) => oddsSum + getOdds(selection),
              0,
            );

            if (odds > maxOdds && isBest) {
              maxOdds = odds;
              // These are currently the selections that gives the best odds
              bestOddsCombinations = current;
            }
          }
        }
        const returnResult: ISelection[] = result;
        forEach(eventSelections, toUpdate => {
          const updatedVal = toUpdate;
          // eslint-disable-next-line  lodash/prefer-filter
          forEach(bestOddsCombinations, bestOddsSelection => {
            if (bestOddsSelection.id === toUpdate.id) {
              const odds = getOdds(bestOddsSelection);
              if (odds) {
                updatedVal.best_odds = odds;
              }
            }
          });
          returnResult.push(updatedVal);
        });
        return returnResult;
      },
      [],
    );

    updated = { ...updated, selections: updatedSelectionsGroupedByEvent };
  } else {
    const { selections } = bettingslip;
    const updatedSelections = map(
      selections,
      (selection): ISelection => ({
        ...selection,
        best_odds: getOdds(selection),
      }),
    );
    updated = { ...bettingslip, selections: updatedSelections };
  }

  return updated;
};

/**
 * getProfit
 *
 * @param {IBettingslip} bettingslip
 * @param {boolean} maximal
 * @returns {number} profit
 */
export const getProfit = (
  bettingslip: IBettingslip,
  maximal = true,
): number => {
  const bestOdds = setOdds(bettingslip, maximal);
  const stake = getStake(bettingslip);
  const groups = getLegsGroup(bettingslip);

  const payouts = map(groups, (legGroup, index) =>
    LegGroup.getPossiblePayout(
      stake,
      getLegsForSize(bestOdds, bettingslip.size[index]),
      maximal,
    ),
  );

  if (!maximal && groups.length > 1) {
    return min(payouts) as number;
  }

  return reduce(payouts, (a, b) => a + b, 0);
};

/**
 * getBetpackerProfit
 * getBetpackerProfit, returns profit for betPacker mode, based on server odds + current stake
 *
 * @param {IBettingslip} bettingslip
 * @param {boolean} raw
 * @returns {number} profit
 */
export const getBetPackerProfit = (bettingslip, raw): number => {
  const stake = getStake(bettingslip);

  const profit = stake * bettingslip.betPackerOdds;
  return raw ? profit : parseFloat(fixDecimals(profit));
};

/**
 * getCappedProfit
 * Return lower value among possibleProfit and maxPayout setting.
 * We don't pay more than maxPayout!
 *
 * @param {IBettingslip} bettingslip
 * @param {boolean} maximal
 * @returns {number} possible_profit
 */
export const getCappedProfit = (
  bettingslip: IBettingslip,
  maximal: boolean,
): number => {
  const profit: number =
    bettingslip.bsMode === BetView.NORMAL
      ? getProfit(bettingslip, maximal)
      : getBetPackerProfit(bettingslip, true);
  const maxPayout = bettingslip.user.maxPayout || '0';

  return profit > parseFloat(maxPayout) ? parseFloat(maxPayout) : profit;
};

export const serializeBetPacker = (bettingslip): ISubmittedBettingslip => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const params = {} as ISubmittedBettingslip & { composites: any[] };

  params.composites = [
    reduce(
      bettingslip.betPackerSelections,
      (acc, e) =>
        assign(acc, {
          event_id: e.event.id,
          odds: bettingslip.betPackerOdds,
          selections: acc.selections.concat(e.id),
        }),
      { selections: [] },
    ),
  ] as ICompositesT[];

  const totalAmount = (
    numeral(getTotalAmount(bettingslip)).value() || 0
  ).toFixed(2);

  const tokenValue = services.cookie.get(TOKEN) || '';
  const sessionValue = services.cookie.get(SESSION_ID) || '';

  return {
    ...params,
    amount: totalAmount,
    bet_type: IBettingslipType.single,
    tax: getTotalTax(bettingslip).toFixed(2),
    token: tokenValue,
    session: sessionValue,
    lang: services.domainLang,
    is_total_amount: 1,
  };
};

/**
 * serializeBS
 * preparte betting slip for submition
 *
 * @param {IBettingslip} bettingslip
 * @returns {Promise} promise
 */
export const serializeBS = (
  bettingslip: IBettingslip,
): ISubmittedBettingslip => {
  const params = {};
  const bets = bettingslip.selections;
  const selections: string[] = [];
  let id: string;
  if (bettingslip.bsMode === BetView.BETPACKER) {
    return serializeBetPacker(bettingslip);
  }
  forEach(bets, bet => {
    params[`odds_${bet.id}`] = bet.odds && bet.odds.toString();
    if (bet.id !== id) {
      id = bet.id;
      params[`score_event_${id}`] = bet.event?.score?.score;
    }

    if (bet.market && bet.market.special_value) {
      params[`special_value_${bet.id}`] = bet.market.special_value;
    }
    selections.push(bet.id);
  });

  if (bettingslip.type === 'system') {
    forEach(getSelectionsByBankEvent(bettingslip), bankerEventBets => {
      forEach(bankerEventBets, value => {
        params[`bank_${value.id}`] = '1';
      });
    });
  }

  const totalAmount = (
    numeral(getTotalAmount(bettingslip)).value() || 0
  ).toFixed(2);

  return {
    ...params,
    selections: uniq(selections),
    legs_count: getLegsCount(bettingslip),
    leg_size: bettingslip.size,
    possible_profit: String(getCappedProfit(bettingslip, true)),
    amount: totalAmount,
    bet_type: bettingslip.type,
    tax: getTotalTax(bettingslip).toFixed(2),
    token: services.cookie.get(TOKEN),
    session: services.cookie.get(SESSION_ID),
    lang: services.domainLang,
    is_total_amount: 1,
    source_of_bet: isDesktop ? 1 : 2,
    ...(hasBonusWallets()
      ? { use_bonus: bettingslip.walletType === IBSWalletType.BONUS ? 1 : 0 }
      : {}),
    ...(hasShopEMoneyWallets()
      ? {
          use_shop_emoney:
            bettingslip.walletType === IBSWalletType.SHOP ? 1 : 0,
        }
      : {}),
  };
};

const formatValue = (value: number): number => {
  return Math.floor(value * 1000) / 1000;
};

export const calculateStake = (
  bettingslip: IBettingslip,
  currentStake: string,
  isTotal: boolean,
): { totalStake: number; stake: number } => {
  const legsCount = getLegsCount(bettingslip);
  const stake = isTotal
    ? formatValue((numeral(currentStake).value() || 0) / (legsCount || 1))
    : formatValue(numeral(currentStake).value() || 0);
  const totalStake = isTotal
    ? numeral(currentStake).value() || 0
    : formatValue((numeral(currentStake).value() || 0) * (legsCount || 1));
  return {
    totalStake,
    stake,
  };
};

import min from 'lodash/min';
import map from 'lodash/map';
import compact from 'lodash/compact';

import { roundDown } from '@common/helpers/deviceUtil';
import { ISelection } from '@common/interfaces';

/**
 * @class Gambit.Models.BettingSlip.SimpleLegGroup
 */

class LegGroup {
  public legs: Array<ISelection[]>;

  private readonly amountPerLeg: number;

  private readonly taxPercent: number;

  public readonly legsCount: number;

  constructor(attrs: {
    legs: Array<ISelection[]>;
    taxPercent: number;
    amountPerLeg: number;
  }) {
    this.legs = attrs.legs;
    this.amountPerLeg = attrs.amountPerLeg;
    this.taxPercent = attrs.taxPercent;
    this.legsCount = this.legs.length;
  }

  getTotalTax = (taxMethod: string): number => {
    let taxPerLeg = 0;
    if (this.taxPercent > 0) {
      if (taxMethod === 'ADDITIVE') {
        taxPerLeg = (this.amountPerLeg * this.taxPercent) / 100;
      } else if (taxMethod === 'SUBTRACTIVE') {
        const amountWithoutTax =
          (this.amountPerLeg * 100) / (this.taxPercent + 100);
        taxPerLeg = this.amountPerLeg - amountWithoutTax;
      }
    }
    return taxPerLeg * this.legsCount;
  };

  static calculateLeg = (leg: ISelection[]): number => {
    let odds = 1;
    for (let y = 0; y < leg.length; y++) {
      const selection = leg[y];
      odds *= selection.best_odds || 0;
    }
    return odds;
  };

  static getPossiblePayout = (
    stakePerLeg: number,
    legs: ISelection[][],
    isBest = true,
  ): number => {
    let payout = 0;
    if (isBest) {
      for (let x = 0; x < legs.length; x++) {
        const leg = legs[x];
        const odds = LegGroup.calculateLeg(leg);
        payout += odds * stakePerLeg;
      }
    } else {
      payout =
        min(
          compact(map(legs, leg => stakePerLeg * LegGroup.calculateLeg(leg))),
        ) || 0;
    }
    return roundDown(payout, 2);
  };
}

export default LegGroup;

import some from 'lodash/some';
import includes from 'lodash/includes';

import services from '@features/core/services';

import { DEFAULT_WALLET_ID } from '@common/constants/cookie';
import switchUserAccount from '@common/providers/user/actions/switchUserAccount';
import { useWalletsState } from '@common/providers/payments/wallets/useWallets';

const switchWallets = (): void => {
  const wallets = useWalletsState.getState().data;

  const walletId = services.cookie.get(DEFAULT_WALLET_ID);

  const isSameWallets =
    walletId &&
    some(wallets, (wallet): boolean => includes(wallet.account_id, walletId));

  if (walletId) {
    if (isSameWallets) {
      switchUserAccount(walletId);
    } else {
      services.cookie.remove(DEFAULT_WALLET_ID);
    }
  }
};

export default switchWallets;

import head from 'lodash/head';
import some from 'lodash/some';
import reject from 'lodash/reject';

import services from '@features/core/services';
import { IWalletView } from '@features/wallets/walletSwitcher';
import CustomError from '@features/core/error/error';

import {
  HAS_ACCEPTED_GLOBAL,
  HAS_SUMMARY_LAYER,
} from '@common/constants/config';
import {
  DEPOSIT_LIMIT_LAYER,
  LUGAS_ACCEPT_GLOBAL10,
  LUGAS_ACCEPT_GLOBAL30,
  SUMMARY_LAYER,
  TERMS_LAYER,
  WALLET_SWITCHER,
} from '@common/constants/dialogs';
import {
  ACCEPT_TAC_LAYER_COOKIE,
  DEFAULT_WALLET_ID,
  HAS_ACCEPTED_GLOBAL_LIMITS,
  SUMMARY_LAYER_COOKIE,
  SWITCHER_POPUP_CHECKED,
} from '@common/constants/cookie';
import { IFetchPocketTagsResponse } from '@common/interfaces/api/account/IFetchPocketTags';
import { IFetchWallets } from '@common/providers/payments/wallets/types';
import {
  openSidebar,
  useAppState,
} from '@common/providers/application/useAppState';
import { ILimit, IUser } from '@common/interfaces';
import { IUserWalletType } from '@common/interfaces/user/IUser';
import { setWalletView } from '@common/providers/payments/wallets/useWallets';
import { isNativeApp } from '@common/helpers/links';

/**
 * isOpenDialog
 *
 * @param {string} dialog
 * @returns {boolean} isOpenDialog
 */
export const isOpenDialog = (dialog: string): boolean => {
  const { openedSidebars } = useAppState.getState();
  return some(openedSidebars, ['type', dialog]);
};

/**
 * getOpenDialog
 *
 * @returns {string | undefined} openDialog
 */
export const getOpenDialog = (): string | undefined => {
  const { openedSidebars } = useAppState.getState();
  return head(openedSidebars)?.type;
};

export const setDialogs = (params: {
  user: IUser;
  wallets: IFetchWallets;
  monthlyLimit?: ILimit;
  depositLayer: IFetchPocketTagsResponse;
  acceptedLimits?: IFetchPocketTagsResponse;
  hasBoostedLimit?: IFetchPocketTagsResponse;
}): void => {
  const {
    user,
    wallets,
    monthlyLimit,
    depositLayer,
    acceptedLimits,
    hasBoostedLimit,
  } = params;
  const isNative = isNativeApp(window.location.search);
  const isBalanceSwitcherEnabled =
    reject(wallets?.result?.wallets, ['type', IUserWalletType.bonus]).length >
    1;

  if (
    String(user.acceptedTAC) === '0' &&
    !isNative &&
    !services.cookie.get(ACCEPT_TAC_LAYER_COOKIE)
  ) {
    openSidebar({ type: TERMS_LAYER });
  }

  if (
    !services.cookie.get(SWITCHER_POPUP_CHECKED) &&
    isBalanceSwitcherEnabled &&
    !isNative &&
    !services.cookie.get(DEFAULT_WALLET_ID)
  ) {
    openSidebar({ type: WALLET_SWITCHER });
    setWalletView(IWalletView.POPUP_VIEW);
  }

  if (
    !services.cookie.get(SUMMARY_LAYER_COOKIE) &&
    !isNative &&
    services.config.get(HAS_SUMMARY_LAYER)
  ) {
    openSidebar({ type: SUMMARY_LAYER });
  }

  if (
    !(depositLayer instanceof CustomError) &&
    !depositLayer.tag?.value &&
    !isNative &&
    !parseFloat(monthlyLimit?.data.value as string)
  ) {
    openSidebar({ type: DEPOSIT_LIMIT_LAYER });
  }

  if (
    services.config.get(HAS_ACCEPTED_GLOBAL) &&
    !services.cookie.get(HAS_ACCEPTED_GLOBAL_LIMITS) &&
    !isNative &&
    acceptedLimits?.tag?.value === 'pending'
  ) {
    openSidebar({ type: LUGAS_ACCEPT_GLOBAL10 });
  }

  if (
    services.config.get(HAS_ACCEPTED_GLOBAL) &&
    !services.cookie.get(HAS_ACCEPTED_GLOBAL_LIMITS) &&
    !isNative &&
    hasBoostedLimit?.tag?.value === 'verified'
  ) {
    openSidebar({ type: LUGAS_ACCEPT_GLOBAL30 });
  }
};

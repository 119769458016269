export type TReturnIconName = 'check' | 'minus' | 'empty-circle' | 'close' | '';

export interface IBetStatusSquare {
  status: number;
  size?: string;
}

export enum StatusCode {
  OPEN,
  LOST,
  WON,
  CANCELLED,
  MATRIX_OPEN,
}

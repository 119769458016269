import { createWithEqualityFn } from 'zustand/traditional';
import { shallow } from 'zustand/shallow';

import {
  IAVSResponse,
  IAccountState,
  ISetLockAccountPayload,
  ISetSubscriptionsPayload,
  IGdpr,
} from '@common/providers/account/types';
import { ICustomError } from '@common/interfaces';
import defaultState from '@common/providers/account/state';

export const useAccount = createWithEqualityFn<IAccountState>(
  () => defaultState,
  shallow,
);

export const startSettingRecoverPassword = (): void => {
  useAccount.setState(state => ({
    recoverPassword: {
      ...state.recoverPassword,
      loading: true,
    },
  }));
};

export const resetRecoverPassword = (): void => {
  useAccount.setState(() => ({
    recoverPassword: defaultState.recoverPassword,
  }));
};

export const setRecoverPasswordSuccess = (): void => {
  useAccount.setState(() => ({
    recoverPassword: {
      loading: false,
      success: true,
      error: null,
    },
  }));
};

export const setRecoverPasswordError = (payload: ICustomError): void => {
  useAccount.setState(() => ({
    recoverPassword: {
      loading: false,
      success: false,
      error: payload,
    },
  }));
};

export const postBonusCodeError = (
  payload: string | null | undefined,
): void => {
  useAccount.setState(() => ({
    bonusCode: {
      loading: false,
      success: false,
      error: payload,
    },
  }));
};

export const resetBonusCode = (): void => {
  useAccount.setState(() => ({
    bonusCode: {
      loading: false,
      success: false,
      error: null,
      codeType: '',
    },
  }));
};

export const postBonusCode = (): void => {
  useAccount.setState(state => ({
    bonusCode: {
      ...state.bonusCode,
      loading: true,
    },
  }));
};

export const postBonusCodeSuccess = (codeType: string): void => {
  useAccount.setState(() => ({
    bonusCode: {
      loading: false,
      success: true,
      error: null,
      codeType,
    },
  }));
};

export const resetCancelBonusCode = (): void => {
  useAccount.setState(state => ({
    bonusCode: {
      ...state.bonusCode,
      cancelBonusSuccess: false,
      cancelBonusError: null,
    },
  }));
};

export const postCancelBonusCodeSuccess = (): void => {
  useAccount.setState(state => ({
    bonusCode: {
      ...state.bonusCode,
      cancelBonusSuccess: true,
    },
  }));
};

export const postCancelBonusCodeError = (
  payload: string | null | undefined,
): void => {
  useAccount.setState(state => ({
    bonusCode: {
      ...state.bonusCode,
      cancelBonusError: payload,
    },
  }));
};

export const startFetchingAvsToken = (): void => {
  useAccount.setState(state => ({
    avs: {
      ...state.avs,
      loading: true,
    },
  }));
};

export const setAvsToken = ({ partner_uuid, token }: IAVSResponse): void => {
  useAccount.setState(() => ({
    avs: {
      partner_uuid: partner_uuid || '',
      token: token || '',
      loading: false,
    },
  }));
};

export const setSubscriptionsState = (
  subscriptions: ISetSubscriptionsPayload,
): void => {
  useAccount.setState(() => ({
    subscriptions: { ...subscriptions, loading: false },
  }));
};

export const setSubscriptionsError = (error: ICustomError): void => {
  useAccount.setState(state => ({
    subscriptions: {
      ...state.subscriptions,
      loading: false,
      error,
    },
  }));
};

export const resetSubscriptions = (): void => {
  useAccount.setState(() => ({
    subscriptions: defaultState.subscriptions,
  }));
};

export const requestSubscriptions = (): void => {
  useAccount.setState(state => ({
    subscriptions: {
      ...state.subscriptions,
      loading: true,
    },
  }));
};

export const resetAccountState = (): void => {
  useAccount.setState(defaultState);
};

export const setGdprData = (): void => {
  useAccount.setState(() => ({
    gdpr: {
      ...defaultState.gdpr,
      loading: true,
    },
  }));
};

export const getGdprDataSuccess = (data: IGdpr[]): void => {
  useAccount.setState(() => ({
    gdpr: {
      data,
      loading: false,
      success: true,
      error: null,
    },
  }));
};

export const getGdprDataError = (error: ICustomError): void => {
  useAccount.setState(state => ({
    gdpr: {
      ...state.gdpr,
      loading: false,
      success: false,
      error,
    },
  }));
};

export const setLockAccountData = (
  lockAccount: ISetLockAccountPayload,
): void => {
  useAccount.setState(() => ({
    lockAccount,
  }));
};

export const resetLockAccountData = (): void => {
  useAccount.setState(() => ({
    lockAccount: defaultState.lockAccount,
  }));
};

import React from 'react';
import { useTranslation } from 'react-i18next';

import * as S from '@pages/login/Login.styled';

import { InfoBlock } from '@features/profile/components/infoBlock';

import { LIMIT_LOADER_LAYER } from '@common/constants/dialogs';
import { SidebarSide } from '@common/interfaces';

import { Loader } from '@ui/components/inputLoader/InputLoader.styled';

const LimitLoaderLayer = (): React.ReactElement => {
  const { t } = useTranslation();

  return (
    <S.Content className="info-limit">
      <S.ContentInner className="loader-inner">
        <S.DescWrapper>
          <S.Header>{t(`user.lugas.loader.title`)}</S.Header>
          <InfoBlock message={t(`user.lugas.loader.text`)} />
        </S.DescWrapper>
      </S.ContentInner>

      <S.LoaderContainer>
        <Loader />
      </S.LoaderContainer>
    </S.Content>
  );
};

LimitLoaderLayer.meta = {
  side: SidebarSide.center,
  key: LIMIT_LOADER_LAYER,
  props: { type: '' },
};

export { LimitLoaderLayer as Component };

export default LimitLoaderLayer;

import { Rule } from 'rc-field-form/lib/interface';

import i18next from '@features/core/translation';
import Patterns from '@features/core/form/utils/patterns';

const phonePreset = (fieldTranslation: string): Rule[] => [
  {
    required: true,
    message: `${i18next.t(fieldTranslation)}`,
  },
  {
    pattern: Patterns.PHONE,
    message: i18next.t('common.labels.phone_incorrect_characters'),
  },
];

export default phonePreset;

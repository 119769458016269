import React from 'react';

import { ANIMATED_BALANCE } from '@common/helpers/appLayoutHelper';
import { useAppState } from '@common/providers/application/useAppState';
import {
  getActiveWallet,
  formatWalletBalance,
} from '@common/helpers/paymentsHelper/walletsHelper';

import { IBalanceBadgeProps } from './BalanceBadge.types';
import Root from './BalaceBadge.styled';

const BalanceBadge: React.FC<IBalanceBadgeProps> = ({ className }) => {
  const showComponent = useAppState(state => state.showComponent);
  const isAnimation = showComponent === ANIMATED_BALANCE;

  const renderBalance = (): string | null => {
    const activeWallet = getActiveWallet();
    if (activeWallet) {
      return formatWalletBalance(activeWallet, true);
    }

    return null;
  };

  return (
    <Root isAnimation={isAnimation} className={className}>
      {renderBalance()}
    </Root>
  );
};

export default BalanceBadge;

import React, { useEffect, useRef, useState, useLayoutEffect } from 'react';
import { useTranslation } from 'react-i18next';
import map from 'lodash/map';
import FlipMove from 'react-flip-move';
import some from 'lodash/some';

import { CashoutItem } from '@features/cashout/components/cashoutItem';
import services from '@features/core/services';
import SkeletonCashout from '@features/cashout/components/skeleton/SkeletonCashout';

import * as cookies from '@common/constants/cookie';
import { isDesktopView } from '@common/helpers/deviceUtil';
import { sortedBetsSelector } from '@common/helpers/cashoutHelper';
import {
  resetEventsList,
  useEventsListState,
} from '@common/providers/events/eventList/useEventsList';
import { useBets } from '@common/providers/bets/useBets';
import { betsSocketReconnect, getBets } from '@common/providers/bets/helper';
import {
  removeRequestedCashout,
  setCashoutFavorites,
  useCashoutState,
} from '@common/providers/cashout/useCashout';

import {
  INotificationStyle,
  INotificationIcon,
} from '@ui/components/genericNotification/GenericNotification.types';
import { GenericNotification } from '@ui/components/genericNotification';

import * as S from './Cashout.styled';

const isDesktop = isDesktopView();

const Cashout: React.FC = () => {
  const { t } = useTranslation();
  const events = useEventsListState(state => state.cashout.data.events);
  const eventLoading = useEventsListState(stat => stat.cashout.loading);
  const loading = useBets(s => s.loading);
  const betsLoading = useBets(s => s.betsLoading);
  const openBets = useBets(s => s.openBets);
  const cashoutFavorites = useCashoutState(s => s.favorites);
  const [isLoading, setIsLoading] = useState(true);
  const scrollRef = useRef<HTMLDivElement>(null);
  const sorted = sortedBetsSelector(openBets, events, cashoutFavorites);
  const isBonusBetsExist = some(sorted, ['is_bonus', '1']);
  const itemsCount = sorted.length;
  const updateBets = (): void => {
    getBets({
      offset: 0,
      items: 1000,
      maxAgeDays: 365,
      settled: 'unsettled',
      list: 'openBets',
      withEvents: true,
    });
  };

  useEffect(betsSocketReconnect, []);

  useEffect(() => {
    const cashoutFavList = services.cookie.get(
      cookies.FAVORITES_CASHOUT_COOKIE,
    );
    if (cashoutFavList) {
      try {
        setCashoutFavorites(JSON.parse(cashoutFavList));
      } catch (e) {
        services.logger.log(`Not able to parse response ${e}`);
      }
    }
    updateBets();
    return () => {
      resetEventsList('cashout');
      removeRequestedCashout();
    };
  }, []);

  useLayoutEffect(() => {
    setIsLoading(loading || eventLoading || betsLoading);
  }, [isLoading, eventLoading, betsLoading]);

  const renderBonusNotification = (): JSX.Element => {
    return (
      <S.BonusNotificationWrapper>
        <GenericNotification
          iconType={INotificationIcon.base}
          styleType={INotificationStyle.bonusNotifierNotification}
        >
          {t('cashout.bonus_notification')}
        </GenericNotification>
      </S.BonusNotificationWrapper>
    );
  };

  const renderItems = (): JSX.Element => (
    <FlipMove duration={500} typeName="div" staggerDurationBy="30">
      {map(sorted, (item, idx) => (
        <CashoutItem
          key={item.id}
          even={(idx + 1) % 2 === 0}
          betId={item.id}
          parentScrollRef={scrollRef.current}
        />
      ))}
    </FlipMove>
  );

  return (
    <S.Content id="cashout" className={isDesktop ? '' : 'slider-cashout'}>
      <div ref={scrollRef} data-qa="cashout-container">
        <div className="cashout" data-qa="cashout-list">
          {isLoading && <SkeletonCashout skeletonsCount={4} />}
          {!isLoading && (
            <>
              {isBonusBetsExist ? renderBonusNotification() : null}
              {!itemsCount ? (
                <S.NoListMessage message={t('cashout.empty_list_text')} />
              ) : (
                renderItems()
              )}
            </>
          )}
        </div>
      </div>
    </S.Content>
  );
};

export { Cashout as Component };

export default Cashout;

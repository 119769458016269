import services from '@features/core/services';

import { HAS_LUGAS } from '@common/constants/config';
import { useUserState } from '@common/providers/user/useUserState';
import { IUserWalletType } from '@common/interfaces/user/IUser';
import { getActiveWallet } from '@common/helpers/paymentsHelper/walletsHelper';

/**
 * hasLugasRestriction
 *
 * @returns {boolean} hasLugasRestriction
 */
export const hasLugasRestriction = (): boolean => {
  const user = useUserState.getState().data;
  const activeWallet = getActiveWallet();

  return (
    (services.config.get(HAS_LUGAS) as boolean) &&
    user?.countryCode === 'DE' &&
    (activeWallet?.type === IUserWalletType.www ||
      activeWallet?.type === IUserWalletType.shop_www)
  );
};

export default hasLugasRestriction;

import styled from 'styled-components';

import { CheckboxInput } from '@features/core/form/components/checkboxInput';

export const List = styled.ul`
  width: 100%;
  padding: 0;
  margin: 0;
  list-style: none;

  color: ${props => props.theme.textMain};
  background-color: ${props => props.theme.categoryTabBackground};
`;

export const CheckboxWrapper = styled.div`
  align-self: center;
  margin-right: ${({ theme }) => theme.space_10};

  label {
    cursor: pointer;
  }
`;
export const Checkbox = styled(CheckboxInput)`
  display: flex;
`;

export const ListItem = styled.li`
  display: flex;
  align-items: center;
  height: 50px;
  margin: 0;
  list-style: none;
  padding: 0 ${({ theme }) => theme.space_10};
  text-decoration: none;
  grid-auto-flow: column;
`;

export const TitleWrapper = styled.div`
  display: flex;
  align-items: center;
  padding: ${({ theme }) => theme.space_10} 0 ${({ theme }) => theme.space_10}
    ${({ theme }) => theme.space_10};
  background-color: ${props => props.theme.categoryTabBackground};
`;

export const IconHolder = styled.div`
  display: flex;
  align-items: center;
  margin-right: ${({ theme }) => theme.space_10};
  color: ${({ theme }) => theme.highlightWrapperBackground};
`;

const getTitleBoldStyles = theme => `font-weight: ${theme.fontBold};`;

export const Title = styled.span<{ isBold?: boolean }>`
  font-size: ${props => props.theme.fontMD};
  color: ${props => props.theme.textMain};
  ${({ isBold, theme }) => isBold && getTitleBoldStyles(theme)};
`;

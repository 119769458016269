import React from 'react';
import some from 'lodash/some';
import { useTranslation } from 'react-i18next';

import { getFormatDate } from '@common/helpers/betDetailHelper';
import {
  getCashoutSum,
  hasPartialCashout,
  isMaxCashout,
} from '@common/helpers/betsHelper';
import { IBetDetail } from '@common/interfaces';
import { formatBalance } from '@common/helpers/paymentsHelper/walletsHelper';

import * as S from './BetDetail.styled';

const BetDetailList: React.FC<{ bet: IBetDetail }> = ({ bet }) => {
  const { t } = useTranslation();
  const hasPendingCashout = some(bet?.cashouts, ['is_payout_done', 0]);
  const isWon = bet?.status_code === 2;
  const possibleWinnings = t('common.labels.possible_winnings');

  return (
    <S.DetailContainer>
      <S.DetailHeader data-qa="bet-detail">
        {t('bets.bet_details')}
      </S.DetailHeader>
      <S.DetailList>
        <S.DetailListItem userSelect>
          <span>{t('bets.bet_number')}</span>
          <b>{bet.id}</b>
        </S.DetailListItem>
        <S.DetailListItem>
          <span>{t('bets.date')}</span>
          <b>{getFormatDate(bet.created_ts)}</b>
        </S.DetailListItem>
        <S.DetailListItem>
          <span>{t('bets.status')}</span>
          <b>{bet.status}</b>
        </S.DetailListItem>
        {hasPendingCashout && (
          <S.DetailListItem>
            <span>{t('bets.credit')}</span>
            <b>{t('bets.pending')}</b>
          </S.DetailListItem>
        )}
        <S.DetailListItem>
          <span>{t('bets.bet_type')}</span>
          <b>{bet.type || bet.raw_type}</b>
        </S.DetailListItem>
        <S.DetailListItem>
          <span>{t('bets.numberOfBets')}</span>
          <b>
            {+bet.leg_count > 1
              ? `${bet.leg_count} à ${formatBalance(
                  Number(bet.initial_total_stake || 0) /
                    (Number(bet.leg_count) || 1),
                )}`
              : bet.leg_count}
          </b>
        </S.DetailListItem>
        {hasPartialCashout(bet) || isMaxCashout(bet.cashouts) ? (
          <S.DetailListItem>
            <span>{t('bets.initial_bet')}</span>
            <b>{formatBalance(bet.initial_total_stake)}</b>
          </S.DetailListItem>
        ) : (
          <S.DetailListItem>
            <span>{t('bets.nls_bet')}</span>
            <b>{formatBalance(bet.total_stake)}</b>
          </S.DetailListItem>
        )}
        <S.DetailListItem>
          <span>{t('bets.tax_fee')}</span>
          <b>{formatBalance(bet.total_tax)}</b>
        </S.DetailListItem>
        <S.DetailListItem withDivider>
          <span>{t('bets.nls_total')}</span>
          <b>{formatBalance(bet.initial_total_cost)}</b>
          <S.Divider />
        </S.DetailListItem>
        {hasPartialCashout(bet) && (
          <S.DetailListItem>
            <span>{possibleWinnings}</span>
            <b>{formatBalance(bet.initial_user_risk)}</b>
          </S.DetailListItem>
        )}
        {String(bet?.status_code) === '3' && (
          <S.DetailListItem>
            <span>{t('bets.cancelled')}</span>
            <b>{t('helpers.yes')}</b>
          </S.DetailListItem>
        )}

        {!isMaxCashout(bet.cashouts) && (
          <>
            {hasPartialCashout(bet) ? (
              <>
                <S.DetailListItem>
                  <span>{t('bets.current_bet')}</span>
                  <b>{formatBalance(bet.total_stake)}</b>
                </S.DetailListItem>
                <S.DetailListItem>
                  <span>{possibleWinnings}</span>
                  <b>{formatBalance(bet.remaining_risk)}</b>
                </S.DetailListItem>
              </>
            ) : (
              <S.DetailListItem>
                <span>{possibleWinnings}</span>
                <b>{formatBalance(bet.initial_user_risk)}</b>
              </S.DetailListItem>
            )}
            {isWon && (
              <S.DetailListItem>
                <span>{t('bets.betting_profit')}</span>
                <b>{formatBalance(bet.paid_amount)}</b>
              </S.DetailListItem>
            )}
            <S.DetailListItem>
              <span>{t('cashout.cashout_sum')}</span>
              <b>{formatBalance(getCashoutSum(bet.cashouts))}</b>
            </S.DetailListItem>
            {hasPartialCashout(bet) && (
              <>
                <S.DetailListItem>
                  <b>{t('bets.total_profit')}</b>
                  <b>
                    {formatBalance(
                      parseFloat(bet.paid_amount) + getCashoutSum(bet.cashouts),
                    )}
                  </b>
                </S.DetailListItem>
                <S.DoubleDivider />
              </>
            )}
          </>
        )}
      </S.DetailList>
    </S.DetailContainer>
  );
};

export default BetDetailList;

import {
  ICustomError,
  ILimit,
  ILimitData,
  ILimitHistory,
} from '@common/interfaces';

export const BET_LIMIT = 'sys.bet.self_limit';
export const LOSS_LIMIT = 'sys.bet.loss.self_limit';
export const DEPOSIT_LIMIT = 'sys.deposit.self_limit';
export const WALLET_TYPE = 'sys.wallet.type';
export const AUTO_WITHDRAW = 'auto_withdraw';
export const CROSS_PROVIDER_DEPOSIT_LIMIT = 'usr.deposit.global_limit';

export type ILimitType =
  | 'sys.bet.self_limit'
  | 'sys.bet.loss.self_limit'
  | 'sys.deposit.self_limit'
  | 'usr.deposit.global_limit'
  | 'sys.wallet.type'
  | 'auto_withdraw';

export type ILimitHistoryType =
  | 'sys.deposit.self_limit.daily'
  | 'sys.deposit.self_limit.weekly'
  | 'sys.deposit.self_limit.monthly';

export interface IDefaultLimitData {
  loaded: boolean;
  error: {
    actionType: string;
    data: ICustomError<{ limitType: ILimitType }>;
  } | null;

  data: { data: ILimitData }[];
}
export type ILimitsProviderState = {
  activeLimitsField: string;
  [BET_LIMIT]: IDefaultLimitData;
  [LOSS_LIMIT]: IDefaultLimitData;
  [DEPOSIT_LIMIT]: IDefaultLimitData;
  [AUTO_WITHDRAW]: IDefaultLimitData;
  [CROSS_PROVIDER_DEPOSIT_LIMIT]: IDefaultLimitData;
  limitHistory: {
    loading: boolean;
    data: ILimitHistory | null;
    error: ICustomError | null;
  };
  selfExclusionForm: {
    loading: boolean;
    error: ICustomError | null;
    success: boolean;
  };
};

export interface IGetLimitSuccess {
  data: ILimit[];
  limitType: string;
}

export type ILimitError = ICustomError<{ limitType: ILimitType }>;

export interface ISetLimitSuccess {
  limitType: ILimitType;
  data: ILimit[];
}

export interface ISetSelfExclusionPayload {
  period: string | Date;
  reason: string;
}

export interface IGetLimitPayload {
  accountId: string;
  token: string;
  limitType: ILimitType;
}

export interface IGetLimitHistoryPayload {
  accountId: string;
  token: string;
  limitType: ILimitHistoryType;
  period: string;
}

export interface ISetLimitPayload {
  accountId?: string;
  token?: string;
  period?: string;
  amount?: number | string;
  limitType: ILimitType;
  prevValue?: number | string | undefined;
  isSchufaAccept?: boolean;
}

export interface ICreditCheck {
  result: {
    status: string;
    error_message?: string;
  };
  jsonrpc?: string;
}

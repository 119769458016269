import styled from 'styled-components';

import { TextNormal, TextSM } from '@features/core/styles/text.styled';

import { ItemType } from '@common/interfaces';
import { hexToRgba } from '@common/helpers/deviceUtil';

export const OddsComparisonItemWrapper = styled.div<{
  sectionItemType?: keyof typeof ItemType;
  isFullSection?: boolean;
}>`
  background-color: ${({ theme, sectionItemType, isFullSection }) =>
    (isFullSection && sectionItemType === ItemType.header) ||
    (!isFullSection && sectionItemType === ItemType.winChance)
      ? theme.elementNuanceColor
      : theme.textActive};
  display: grid;
  grid-template-columns: 2fr auto;
  align-items: center;
  min-height: 40px;
`;

const getBorderBottom = ({ theme }) =>
  `border-bottom: 1px solid ${hexToRgba(theme.primaryBackground, 0.2)} ;`;

const getHeaderLabelStyles = ({ theme }): string => `
  justify-content: flex-start;
  align-items: center;
  font-weight: ${theme.fontBold};
  align-content: center;
  gap: ${theme.isDesktop ? theme.space_30 : theme.space_15};
`;

const itemLabelStyles = `
  justify-content: center;
  align-items: baseline;
  align-content: start;
  flex-direction: column;
`;

export const OddsCompItemLabel = styled.div<{
  isHeader: boolean;
  isWinChance: boolean;
}>`
  display: flex;
  flex-wrap: wrap;
  height: 100%;
  ${({ isHeader }) => (isHeader ? getHeaderLabelStyles : itemLabelStyles)};

  ${({ isWinChance, isHeader }) =>
    !isWinChance && !isHeader && getBorderBottom};

  & p:not(.oc-stake-value) {
    margin-left: ${({ theme }) => theme.space_10};
  }
`;

export const OddsCompItemTitle = styled(TextNormal)<{ isHeader?: boolean }>`
  font-size: ${({ isHeader, theme }) => isHeader && theme.fontLG};
`;

export const OddsCompItemSubtitle = styled.p`
  font-size: ${props => props.theme.fontXS};
  color: ${({ theme }) => hexToRgba(theme.textMain, 0.6)};
  margin: 0;
`;

export const OddsCompStakeValue = styled(TextSM)`
  display: flex;
  align-items: center;
  color: ${props => props.theme.main};
  height: 10px;
  background-color: ${props => props.theme.textMain};
  padding: ${({ theme }) => theme.space_5};
  border-radius: 2px;
  font-weight: ${({ theme }) => theme.fontBold};
`;

const getSWOddsStyles = (theme, isSW, isFullSection, isWinChance) => {
  if (isSW && (!isFullSection || (isFullSection && !isWinChance))) {
    return `
    background-color: ${theme.primaryBackground};
    font-weight: ${theme.fontBold};
    color: ${isWinChance ? theme.accent : theme.textMain}`;
  }
  if (isSW && isWinChance) {
    return `
    font-weight: ${theme.fontBold};
    color: ${theme.accent}
    `;
  }
  return `
    color: ${theme.textMain}
  `;
};

export const OddsProviderWrapper = styled.div`
  display: flex;
  flex-direction: row;
  height: 100%;
`;

const getOddsProviderBoldStyles = theme => `font-weight: ${theme.fontBold};`;

const getWinChanceMargin = ({ theme }) => `margin: ${theme.space_20} 0;`;

export const OddsProvider = styled.div<{
  isHeader?: boolean;
  isSW?: boolean;
  isWinChance?: boolean;
  isFullSection?: boolean;
}>`
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  ${({ isHeader, theme }) => isHeader && getOddsProviderBoldStyles(theme)};
  box-sizing: border-box;
  width: 65px;
  ${({ isWinChance, isHeader }) =>
    !isWinChance && !isHeader && getBorderBottom};
  ${({ isWinChance, isFullSection }) =>
    isWinChance && isFullSection && getWinChanceMargin};
  ${({ theme, isSW, isWinChance, isFullSection }) =>
    getSWOddsStyles(theme, isSW, isFullSection, isWinChance)}
`;

export const WinChanceOddsTriangle = styled.div`
  position: absolute;
  background-color: transparent;
  margin-bottom: ${({ theme }) => theme.space_50};
  border-left: 34px solid transparent;
  border-right: 34px solid transparent;
  border-top: 8px solid ${props => props.theme.primaryBackground};
`;

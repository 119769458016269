import reduce from 'lodash/reduce';
import forEach from 'lodash/forEach';

import services from '@features/core/services';

import {
  IFavoritesType,
  ILiveSportCategoriesTreeData,
} from '@common/interfaces';
import { useLiveCategories } from '@common/providers/events/liveCategories/useLiveCategories';
import { toggleFavorites } from '@common/providers/favorites/useFavoritesState';

export const getFavoritesData = (
  categoryTree,
  favoritesCategories,
): ILiveSportCategoriesTreeData[] =>
  reduce(
    categoryTree,
    (acc, elem: ILiveSportCategoriesTreeData) => {
      const isIncludes = favoritesCategories[elem.id];

      if (isIncludes) {
        acc.push(elem);
      } else if (elem?.children?.length) {
        acc.push(...getFavoritesData(elem.children, favoritesCategories));
      }

      return acc;
    },
    [] as ILiveSportCategoriesTreeData[],
  );

const checkParentIds = (id, categoryIdMap, favoriteIds): boolean => {
  if (categoryIdMap[id]) {
    const parentId = categoryIdMap[id].parent_id;

    if (favoriteIds[parentId]) {
      return true;
    }
    // temporary solution until the backend changes 0 to null
    if (parentId === '0' || !parentId) {
      return false;
    }
    return checkParentIds(parentId, categoryIdMap, favoriteIds);
  }
  return false;
};

export const isEventFavorite = (eventId, favoritesEvents): boolean =>
  !!favoritesEvents[eventId];

export const isCategoryFavorite = (
  categoryId,
  favoritesCategories: Record<string, string>,
): boolean => {
  if (favoritesCategories[categoryId]) {
    return true;
  }
  const { flatSportCatTree } = useLiveCategories.getState();

  return checkParentIds(categoryId, flatSportCatTree, favoritesCategories);
};

type FavoriteCookieName = 'fav_e' | 'fav_c';

export const getFavoriteData = (
  cookieKey: FavoriteCookieName,
  type: IFavoritesType,
): void => {
  try {
    const favoritesData = services.cookie.get(cookieKey);
    if (favoritesData) {
      const storedFavorites = JSON.parse(favoritesData);

      forEach(storedFavorites, (id: string) => {
        toggleFavorites({ id, type });
      });
    }
  } catch (e) {
    services.logger.log(`Not able to parse cookies for favorites ${String(e)}`);
  }
};

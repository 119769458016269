import React from 'react';

import * as S from './BetPacker.styled';
import { IBetPackerIcon } from './BetPacker.types';

const BetPackerIcon: React.FC<IBetPackerIcon> = ({ size }): JSX.Element => {
  return <S.BetPackerIconWrapper size={size}>BP</S.BetPackerIconWrapper>;
};

export default BetPackerIcon;

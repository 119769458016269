import styled from 'styled-components';

import { getHeaderHeight } from '@features/app/layout/header';

import { isIOS } from '@common/helpers/deviceUtil';

const addProfilePageStyles = (bgColor: string): string => `
  background-color: ${bgColor};

  & .right-col-desktop {
    position: fixed;
    overflow-y:auto;
    right: 0;
    top: ${getHeaderHeight()}px;
    height: calc(100vh - ${getHeaderHeight()}px);
  }
`;

const getIOSStandaloneMinHeight = (minHeight: number): string => {
  return isIOS()
    ? `min-height: calc(${minHeight}px - ${getHeaderHeight()}px - 5px);`
    : '';
};

const GlobalStyles = styled.div<{
  hasFixedProfileMenu: boolean;
  withFocus?: boolean;
  minHeight?: number;
}>`
  font-size: ${({ theme }): string => theme.fontMD};
  user-select: none;
  height: 100%;
  background-color: ${({ theme }): string | undefined => theme.bodyBackground};
  ${({ hasFixedProfileMenu, theme }): string =>
    hasFixedProfileMenu ? addProfilePageStyles(theme.main) : ''};

  @media all and (display-mode: standalone) {
    ${({ minHeight }): string =>
      minHeight ? getIOSStandaloneMinHeight(minHeight) : ''};
  }
`;

export default GlobalStyles;

import React, { useState } from 'react';
import keys from 'lodash/keys';
import isFunction from 'lodash/isFunction';
import forEach from 'lodash/forEach';
import { Field } from 'rc-field-form';

import { ErrorContainer } from '@features/core/form/components/errorContainer';

import { ValidateStatus } from '@common/interfaces';

import { IFormItemProps } from './FormItem.types';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const combineFunctionsInObjects = (obj1, obj2): any => {
  const result = { ...obj1 };
  forEach(keys(obj2), key => {
    if (isFunction(obj1[key]) && isFunction(obj2[key])) {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      result[key] = (...args): any => {
        obj1[key](...args);
        obj2[key](...args);
      };
    } else if (obj2[key] !== undefined) {
      result[key] = obj2[key];
    }
  });

  if (!result.value) {
    result.value = '';
  }

  return result;
};

const FormItem: React.FC<IFormItemProps> = props => {
  const {
    validateTrigger,
    children,
    noErrorMessages,
    floatError,
    isFloatErrorArrowLeft,
    isControlled,
  } = props;
  const [blurred, setBlurred] = useState(false);
  // shows if validation has run at last one time
  const [validationDidRun, setValidationDidRun] = useState(false);
  const [isChanging, setIsChanging] = useState(false);

  const mergedValidateTrigger =
    validateTrigger || (blurred || validationDidRun ? 'onChange' : 'onBlur');
  return (
    <Field {...props} validateTrigger={mergedValidateTrigger} validateFirst>
      {(control, meta, form): React.ReactNode => {
        if (meta.validating && !validationDidRun) {
          setTimeout(() => setValidationDidRun(true));
        }

        let validateStatus = ValidateStatus.none;
        if (
          (validateTrigger === 'onBlur' || validateTrigger === 'none') &&
          isChanging
        ) {
          validateStatus = ValidateStatus.none;
        } else if (meta.errors.length) {
          validateStatus = ValidateStatus.error;
        } else if (meta.validating) {
          validateStatus = ValidateStatus.validating;
        } else if (!control.value) {
          validateStatus = ValidateStatus.none;
        } else if (validationDidRun) {
          validateStatus = ValidateStatus.success;
        }
        const mergedControl = combineFunctionsInObjects(
          !isControlled ? control : {},
          {
            onBlur: () => {
              setBlurred(true);
              setIsChanging(false);
            },
            onChange: () => setIsChanging(true),
            validateStatus,
          },
        );
        if (isFunction(children)) {
          return (
            <ErrorContainer
              floatError={floatError || false}
              errors={noErrorMessages ? [] : meta.errors}
            >
              {children(mergedControl, meta, form, {
                blurred,
                validationDidRun,
              })}
            </ErrorContainer>
          );
        }
        return (
          <ErrorContainer
            floatError={floatError || false}
            isFloatErrorArrowLeft={isFloatErrorArrowLeft || false}
            errors={noErrorMessages ? [] : meta.errors}
          >
            {React.cloneElement(
              children,
              combineFunctionsInObjects(mergedControl, children.props),
            )}
          </ErrorContainer>
        );
      }}
    </Field>
  );
};

export default FormItem;

import styled from 'styled-components';

const floatErrorStyles = (theme, isFloatErrorArrowLeft) => `
  padding: ${theme.space_5} ${theme.space_10};
  border-radius: 1px;
  text-align: left;
  top: calc(100% + 10px);
  width: auto;
  right: 0;
  ${isFloatErrorArrowLeft && 'right: unset;'}

  &:after {
    content: '';
    border: 7px solid transparent; 
    border-bottom: 7px solid ${theme.statusError};
    position: absolute;
    ${isFloatErrorArrowLeft ? 'left: 13px;' : 'right: 13px;'}
    top: -13px;
    z-index: 2;
  }
  
  &:before {
    content: '';
    border: 7px solid transparent; 
    border-bottom: 7px solid ${theme.main};
    position: absolute;
    ${isFloatErrorArrowLeft ? 'left: 12px;' : 'right: 12px;'}
    top: -14px;
    z-index: 1;
  }
`;

const relativeErrorStyles = ({ theme }) => `
    position: relative; 
    margin-top: ${theme.space_10}; 
    min-height: 30px;
`;

const getStyles = props => {
  if (props.isInputError) {
    return `  position: absolute;
    z-index: 5;
    padding: 0 ${props.theme.space_10};
    box-sizing: border-box;
    width: 100%;
    border-bottom-left-radius: 3px;
    border-bottom-right-radius: 3px;
    `;
  }

  return `
  padding: ${props.theme.space_10} ${props.theme.space_20}
  ${props.theme.space_10} ${props.theme.space_10};
  font-stretch: condensed;
  font-weight: ${props.theme.fontBold};
    `;
};
// eslint-disable-next-line import/prefer-default-export
export const ErrorComponentWrapper = styled.p<{
  floatError: boolean;
  isFloatErrorArrowLeft: boolean;
  isRelativeErrorPosition: boolean;
  isInputError: boolean;
}>`
  background-color: ${props => props.theme.statusError};
  color: ${props => props.theme.highlightWrapperBackground};
  margin: 0;
  font-size: ${({ theme, isInputError }) =>
    isInputError ? theme.fontMD : theme.fontSM};

  ${getStyles};

  a {
    text-decoration: underline;
  }

  &.with-errors {
    z-index: -1;
  }

  ${({ isRelativeErrorPosition }) =>
    isRelativeErrorPosition && relativeErrorStyles};

  ${({ floatError, theme, isFloatErrorArrowLeft }) =>
    floatError && floatErrorStyles(theme, isFloatErrorArrowLeft)};
`;

let originalScrollPosition: number | null = 0;

export const disableScroll = (): void => {
  originalScrollPosition = window.scrollY;
  // document.body.style.overflow = 'hidden';
  document.body.style.position = 'fixed';
};

export const enableScroll = (): void => {
  // document.body.style.removeProperty('overflow');
  document.body.style.removeProperty('position');

  if (originalScrollPosition !== null) {
    window.scrollTo(0, originalScrollPosition);
  }
};

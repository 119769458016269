import styled from 'styled-components';

// eslint-disable-next-line import/prefer-default-export
export const Frame = styled.iframe<{ isLoading: boolean }>`
  width: 100%;
  height: ${({ theme }) => (theme.isDesktop ? '50vh' : '35vh')};
  border: ${({ theme }) => theme.border};
  position: relative;
  ${({ isLoading }) => (isLoading ? 'display: none; height: 0px;' : '')};
`;

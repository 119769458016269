import { ICustomError } from '@common/interfaces/responses/IError';

export interface IUserTaxes {
  1: number;
  2: number;
  3: number;
  4: number;
  5?: number;
  6?: number;
  7?: number;
  8?: number;
  9?: number;
  10?: number;
  live: number;
}

export interface IUserInfoTaxes {
  fully_live: string;
  leg_size_1: string;
  leg_size_2: string;
  leg_size_3: string;
  leg_size_4: string;
  leg_size_5?: string;
  leg_size_6?: string;
  leg_size_7?: string;
  leg_size_8?: string;
  leg_size_9?: string;
  leg_size_10?: string;
}

export enum IUserType {
  shop = 'shop',
  www = 'www',
  casino = 'casino',
}

export enum IUserWalletType {
  casino = 'casino',
  www = 'www',
  is_outletcash_only = 'is_outletcash_only',
  shop = 'shop',
  shop_online = 'shop_online',
  shop_www = 'shop_www',
  bonus = 'bonus',
  shop_emoney = 'shop_emoney',
}

export interface IUser {
  liveCasinoEnabled: 1 | 0;
  vivoCasinoEnabled?: 1 | 0;
  acceptedTAC: '1' | '0';
  country_of_birth?: string;
  hasOnlineAccount?: '1' | '0';
  universalCasinoEnabled?: 1 | 0;
  casinoVerificationStatus?: string;
  maidenname?: string;
  deskoVerificationStatus?: string;
  sportsbookId: string;
  countryCode: string;
  is_schleswig_holstein?: '0' | '1';
  iban?: string;
  verifiedIdentity?: '0' | '1';
  minStake: string;
  affiliateID: string;
  ia_affid?: string;
  btag?: string;
  taxAsFee: number;
  taxLabel: string;
  maxPayout: string;
  isCasinoUser?: '0' | '1';
  isKioskUser?: number;
  lineage?: string;
  avsVerificationStatus?: string;
  taxMethod: string;
  casinoEnabled: number;
  cashbackStatus?: '1' | '0';
  cashoutStatus?: '1' | '0';
  place_of_birth?: string;
  pariplayCasinoEnabled?: number;
  betsoftCasinoEnabled?: number;
  affiliateGroup: string;
  wallet_types?: IUserWalletType[];
  barbutEnabled: number;
  verifiedEmail: number;
  selfExclusionStatus?: '0' | '1';
  selfExclusionTag?: string;
  username: string;
  taxes: IUserTaxes;
  accountFlags: IAccountFlags;
  isEmailVerified?: number;
  type: IUserType;
  eventservice: number;
  nationality?: string;
  street_name?: string;
  street_number?: string;
  zip_code?: string;
  city?: string;
  region?: string;
  email?: string;
  currencyCode: string;
  hasCasinoAccount?: '1' | '0';
  unverified_hrs_exceeded?: 1 | 0;
  personal_information_review?: 1 | 0;
  personal_information_status?:
    | 'show_widget'
    | 'wait'
    | 'done'
    | 'show_personal_information';
}

export interface IUserInfo {
  live_casino_enabled: 1 | 0;
  vivo_casino_enabled?: 1 | 0;
  accepted_tac: 1 | 0;
  country_of_birth?: string;
  has_online_account?: 1 | 0;
  universal_casino_enabled?: 1 | 0;
  casino_verification_status?: string;
  maidenname?: string;
  desko_verification_status?: string;
  sportsbook_id: number;
  country_code: string;
  is_schleswig_holstein?: 0 | 1;
  iban?: string;
  verified_identity?: '0' | '1';
  min_stake: string;
  affiliate_id: string;
  show_tax_as_fee: number;
  tax_label: string;
  max_payout: string;
  is_casino_user?: '0' | '1';
  is_kiosk_user?: string;
  lineage?: string;
  avs_verification_status?: string;
  tax_method: string;
  casino_enabled: number;
  cashback_status?: 1 | 0;
  cashout_status?: 1 | 0;
  place_of_birth?: string;
  pariplay_casino_enabled?: number;
  betsoft_casino_enabled?: number;
  affiliate_group: number;
  wallet_types?: IUserWalletType[];
  barbut_enabled: number;
  verified_email_flags: string;
  self_exclusion_status?: 0 | 1;
  selfExclusionTag?: string;
  user_name: string;
  tax_percentages: IUserInfoTaxes;
  account_flags: IAccountFlags;
  is_email_verified?: number;
  user_type: IUserType;
  eventservice: number;
  nationality?: string;
  street_name?: string;
  street_number?: string;
  zip_code?: string;
  city?: string;
  region?: string;
  email?: string;
  currency_code: string;
  has_casino_account?: 1 | 0;
  unverified_hrs_exceeded?: 1 | 0;
  personal_information_review?: 1 | 0;
  personal_information_status?:
    | 'show_widget'
    | 'wait'
    | 'done'
    | 'show_personal_information';
}

export interface IFetchUserInfo<T = unknown> {
  code?: string;
  data: T;
  messages?: string[];
  name: string;
  message?: string;
  result: IUserInfo;
  jsonrpc: string;
}

export type IFetchUserInfoApiResponse = Promise<IFetchUserInfo & ICustomError>;

export enum IAccountFlags {
  DEFAULT_ACCOUNT_FLAG = '0',
  SHOULD_CHANGE_PASSWORD = '64', // user should change the password
}

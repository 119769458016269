import React from 'react';
import { useTranslation } from 'react-i18next';

import { getPageURL, PageName } from '@features/core/routing';
import { InfoBlock } from '@features/profile/components/infoBlock';

import { LUGAS_LAYER } from '@common/constants/dialogs';
import { ButtonType, SidebarSide } from '@common/interfaces';
import fetchPocketTags from '@common/api/account/fetchPocketTags';
import { MethodType } from '@common/api/apiHandler';
import { closeSidebar } from '@common/providers/application/useAppState';

import { StyledButton } from '@ui/components/buttons';

import * as S from './LugasLayer.styled';

const LugasLayer = (): React.ReactElement => {
  const { t } = useTranslation();

  const handleClose = async (): Promise<void> => {
    closeSidebar(LUGAS_LAYER);
    await fetchPocketTags({
      method: MethodType.Post,
      params: {
        tag: { key: 'usr.front.loginlayer', value: String(Date.now()) },
      },
    });
  };

  return (
    <S.Backdrop className="info-limit">
      <S.Content>
        <S.ContentIcon
          className="closeIcon"
          onClick={handleClose}
          name="close"
          fill="currentColor"
        />

        <S.Title>{t('user.lugas.header_title')}</S.Title>

        <InfoBlock message={t('user.lugas.main_text')} />

        <StyledButton
          onClick={(): void => {
            handleClose();
            window.open(getPageURL(PageName.FAQ_LUGAS));
          }}
          label="user.lugas.button"
          buttonType={ButtonType.Secondary}
        />
      </S.Content>
    </S.Backdrop>
  );
};

LugasLayer.meta = {
  side: SidebarSide.center,
  key: LUGAS_LAYER,
};

export { LugasLayer as Component };

export default LugasLayer;

import styled from 'styled-components';

const getStickyStyles = (stickyTopHeight: number) => `
z-index: 6;
top: ${stickyTopHeight}px;
position: sticky;
width: 100%;
`;

// eslint-disable-next-line import/prefer-default-export
export const Wrapper = styled.div<{ stickyTopHeight: number }>`
  .tabs-nav {
    display: grid;
    grid-auto-flow: column;
    grid-auto-columns: 1fr;

    ${({ stickyTopHeight }) =>
      !!stickyTopHeight && getStickyStyles(stickyTopHeight)}
  }
`;

import styled from 'styled-components';

const correctScoreMargin = ({ theme }) =>
  `margin: ${theme.space_5} ${theme.space_5}`;

// eslint-disable-next-line import/prefer-default-export
export const Predictions = styled.div`
  display: grid;
  grid-auto-flow: column;
  grid-auto-columns: 1fr 60%;
  justify-content: center;
  align-items: center;

  .correct-score {
    display: grid;
    grid-auto-flow: column;
    ${({ theme }) => !theme.isDesktop && correctScoreMargin};
    grid-auto-columns: 1fr;
  }

  .correct-score div {
    flex-grow: 1;

    button {
      width: 100%;
      position: relative;
      bottom: 2px;
      margin-top: ${({ theme }) => theme.space_1};
    }

    &:first-child {
      & button:first-child {
        border-top-left-radius: 3px;
      }

      & button:last-child {
        border-bottom-right-radius: 3px;
        border-bottom-left-radius: 3px;
      }
    }

    &:last-child {
      & button:last-child {
        border-bottom-right-radius: 3px;
        border-bottom-left-radius: 3px;
      }
    }
  }

  .correct-score div:nth-of-type(2) {
    margin-left: ${({ theme }) => theme.space_1};
    margin-right: ${({ theme }) => theme.space_1};
  }
`;

import React from 'react';
import trim from 'lodash/trim';
import split from 'lodash/split';
import includes from 'lodash/includes';
import keys from 'lodash/keys';

import { PUSH_NOTIFICATION_LAYER } from '@common/constants/dialogs';
import { useUserState } from '@common/providers/user/useUserState';
import { openSidebar } from '@common/providers/application/useAppState';
import { usePushNotificationsState } from '@common/providers/pushNotifications/usePushNotifications';
import { ISavePushNotifications } from '@common/providers/pushNotifications/types';
import { savePushNotificationSubscription } from '@common/providers/pushNotifications/helper';

import { Icon } from '@ui/components/icon';

import * as S from './EventDetailAlarms.styled';
import { IEventDetailAlarms } from './EventDetailAlarms.types';

const EventDetailAlarms: React.FC<IEventDetailAlarms> = props => {
  const { event, category } = props;
  const subscriptions = usePushNotificationsState(s => s.subscriptions);
  const reasons = usePushNotificationsState(s => s.reasons);
  const isEnabled = usePushNotificationsState(s => s.isEnabled);
  const user = useUserState(state => state.data);

  const categoryLabel =
    category && category.path ? trim(split(category.path, '/').pop()) : '';

  const isEventSubscribed = includes(keys(subscriptions.events), event.id);
  const isCategorySubscribed = includes(
    keys(subscriptions.categories),
    event.category_id,
  );

  const subscribe = (isCategory = false): void => {
    if (!isEnabled) {
      openSidebar({ type: PUSH_NOTIFICATION_LAYER });
      return;
    }

    const payload: ISavePushNotifications = {
      username: user?.username,
    } as ISavePushNotifications;

    if (isCategory) {
      const settings = isCategorySubscribed ? null : reasons;

      payload.cid = category.id;
      payload.settings = settings;
      payload.status = !isCategorySubscribed;

      savePushNotificationSubscription({
        ...payload,
      } as ISavePushNotifications);
    } else {
      const settings = isEventSubscribed ? null : reasons;

      payload.eid = event.id;
      payload.settings = settings;
      payload.status = !isEventSubscribed;

      savePushNotificationSubscription({
        ...payload,
      } as ISavePushNotifications);
    }
  };

  return (
    <>
      <S.TitleWrapper>
        <S.IconHolder>
          <Icon name="bell" width="16" height="16" />
        </S.IconHolder>
        <S.Title isBold>Toralarm Einstellungen</S.Title>
      </S.TitleWrapper>
      <S.List>
        <S.ListItem
          onClick={async (): Promise<void> => {
            subscribe();
          }}
        >
          <S.CheckboxWrapper>
            <S.Checkbox checked={isEventSubscribed} />
          </S.CheckboxWrapper>
          <S.Title>{event.label}</S.Title>
        </S.ListItem>
        <S.ListItem
          onClick={async (): Promise<void> => {
            subscribe(true);
          }}
        >
          <S.CheckboxWrapper>
            <S.Checkbox checked={isCategorySubscribed} />
          </S.CheckboxWrapper>
          <S.Title>{categoryLabel}</S.Title>
        </S.ListItem>
      </S.List>
    </>
  );
};

export default EventDetailAlarms;

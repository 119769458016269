import React from 'react';

import { ButtonType } from '@common/interfaces';

import { StyledButton } from '@ui/components/buttons';

import * as S from './UserFormButtonsWrapper.styled';
import { IUserFormButtons } from './UserFormButtonsWrapper.types';

const UserFormButtonsWrapper: React.FC<IUserFormButtons> = ({
  disabled,
  onSubmit,
  onAbort,
  submitTitle,
  buttonsQa,
  className,
  cancelTitle,
}) => {
  return (
    <S.FormButtons className={className || ''}>
      <StyledButton
        onClick={onAbort}
        label={cancelTitle || 'common.labels.abort'}
        className="button abort-btn"
        dataQa={`abort-${buttonsQa}`}
        buttonType={ButtonType.LightGhost}
      />
      <StyledButton
        className="button submit-btn"
        onClick={onSubmit}
        disabled={disabled}
        dataQa={`accept-${buttonsQa}`}
        label={submitTitle}
      />
    </S.FormButtons>
  );
};

export default UserFormButtonsWrapper;

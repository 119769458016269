import styled from 'styled-components';

// eslint-disable-next-line import/prefer-default-export
export const NotificationWrapper = styled.div`
  padding: ${({ theme }) => theme.space_10} 0;

  & div {
    padding-top: ${({ theme }) => theme.space_5};
  }
`;

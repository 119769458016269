import services from '@features/core/services';
import client from '@features/core/graphqlClient';
import CustomError, {
  convertResponseToError,
} from '@features/core/error/error';

import {
  HAS_GRAPHQL_CACHE,
  HAS_RESULTS,
  LIVE_COUNTERS,
} from '@common/constants/config';
import {
  ICustomError,
  ILiveSportCategoriesTreeData,
  IResponseError,
} from '@common/interfaces';

export const fetchSportCategoriesTree = async ({
  filter = 'all',
  categoriesToSearch = 'categories',
  lang = services.domainLang || 'de',
}): Promise<ILiveSportCategoriesTreeData[]> => {
  const hasResults = services.config.get(HAS_RESULTS);

  try {
    // 'count_24h' is needed for quick links
    let fragment = '';
    if (categoriesToSearch === 'mergedCategories') {
      fragment = `
      id
      parent_id
      count
      ${hasResults ? 'top_category_id' : ''}
      count_24h
      count_live
      ${services.config.get(LIVE_COUNTERS) ? 'count_live_now' : ''}
      count_today
      ${hasResults ? 'count_today_coming_live' : ''}
      count_3d
      count_3h @include(if: ${filter === '3h'})
      count_today @include(if: ${filter === 'today'})
      count_3d @include(if: ${filter === '3d'})
      count_1w @include(if: ${filter === '1w'})
      ${filter === '1_days_ago' ? hasResults && 'count_1_days_ago' : ''}
      ${filter === '2_days_ago' ? hasResults && 'count_2_days_ago' : ''}
      ${filter === '3_days_ago' ? hasResults && 'count_3_days_ago' : ''}
      ${filter === '4_days_ago' ? hasResults && 'count_4_days_ago' : ''}
      ${filter === '5_days_ago' ? hasResults && 'count_5_days_ago' : ''}
      ${filter === '6_days_ago' ? hasResults && 'count_6_days_ago' : ''}
      ${filter === '7_days_ago' ? hasResults && 'count_7_days_ago' : ''}
      ${filter === '1_days_forward' ? hasResults && 'count_1_days_forward' : ''}
      ${
        filter === '1_days_forward'
          ? hasResults && 'count_1_days_forward_live'
          : ''
      }
      ${filter === '2_days_forward' ? hasResults && 'count_2_days_forward' : ''}
      ${
        filter === '2_days_forward'
          ? hasResults && 'count_2_days_forward_live'
          : ''
      }
      ${filter === '3_days_forward' ? hasResults && 'count_3_days_forward' : ''}
      ${
        filter === '3_days_forward'
          ? hasResults && 'count_3_days_forward_live'
          : ''
      }
      ${filter === '4_days_forward' ? hasResults && 'count_4_days_forward' : ''}
      ${
        filter === '4_days_forward'
          ? hasResults && 'count_4_days_forward_live'
          : ''
      }
      ${filter === '5_days_forward' ? hasResults && 'count_5_days_forward' : ''}
      ${
        filter === '5_days_forward'
          ? hasResults && 'count_5_days_forward_live'
          : ''
      }
      ${filter === '6_days_forward' ? hasResults && 'count_6_days_forward' : ''}
      ${
        filter === '6_days_forward'
          ? hasResults && 'count_6_days_forward_live'
          : ''
      }
      ${filter === '7_days_forward' ? hasResults && 'count_7_days_forward' : ''}
      ${
        filter === '7_days_forward'
          ? hasResults && 'count_7_days_forward_live'
          : ''
      }
      detail
      path
      level
      cid
      label
      is_only_longterm
      is_only_regular
      mergedTree
  `;
    } else {
      fragment = `
      id
      parent_id
      count
      ${hasResults ? 'top_category_id' : ''}
      count_24h
      count_live
      ${services.config.get(LIVE_COUNTERS) ? 'count_live_now' : ''}
      count_today
      ${hasResults ? 'count_today_coming_live' : ''}
      count_3d
      count_3h @include(if: ${filter === '3h'})
      count_today @include(if: ${filter === 'today'})
      count_3d @include(if: ${filter === '3d'})
      count_1w @include(if: ${filter === '1w'})
      ${filter === '1_days_forward' ? hasResults && 'count_1_days_forward' : ''}
      ${
        filter === '1_days_forward'
          ? hasResults && 'count_1_days_forward_live'
          : ''
      }
      ${filter === '2_days_forward' ? hasResults && 'count_2_days_forward' : ''}
      ${
        filter === '2_days_forward'
          ? hasResults && 'count_2_days_forward_live'
          : ''
      }
      ${filter === '3_days_forward' ? hasResults && 'count_3_days_forward' : ''}
      ${
        filter === '3_days_forward'
          ? hasResults && 'count_3_days_forward_live'
          : ''
      }
      ${filter === '4_days_forward' ? hasResults && 'count_4_days_forward' : ''}
      ${
        filter === '4_days_forward'
          ? hasResults && 'count_4_days_forward_live'
          : ''
      }
      ${filter === '5_days_forward' ? hasResults && 'count_5_days_forward' : ''}
      ${
        filter === '5_days_forward'
          ? hasResults && 'count_5_days_forward_live'
          : ''
      }
      ${filter === '6_days_forward' ? hasResults && 'count_6_days_forward' : ''}
      ${
        filter === '6_days_forward'
          ? hasResults && 'count_6_days_forward_live'
          : ''
      }
      ${filter === '7_days_forward' ? hasResults && 'count_7_days_forward' : ''}
      ${
        filter === '7_days_forward'
          ? hasResults && 'count_7_days_forward_live'
          : ''
      }
      detail
      path
      level
      cid
      label
      is_only_longterm
      is_only_regular
  `;
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const { data }: any = await client.request({
      query: `{
  ${categoriesToSearch}(lang: "${lang}", filter: "${filter}") {
    ${services.config.get(HAS_GRAPHQL_CACHE) ? 'not_filtered' : ''}
    categories {
      ${fragment}
      children {
          ${fragment}
        children {
            ${fragment}
          children {
              ${fragment}
            children {
               ${fragment}
              children {
                ${fragment}
              }
            }
          }
        }
      }
    }
  }
}`,
    });
    return data[categoriesToSearch].categories;
  } catch (error) {
    const customAxiosError = error as IResponseError;
    return new CustomError({
      message: convertResponseToError(customAxiosError.response?.data),
      data: customAxiosError.response?.data,
    }) as ILiveSportCategoriesTreeData[] & ICustomError;
  }
};

export default fetchSportCategoriesTree;

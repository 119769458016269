import find from 'lodash/find';
import includes from 'lodash/includes';

import { IMarket, ISelection } from '@common/interfaces';

const isBetPackerMarketActive = (
  betPackerSelections: ISelection[],
  betPackerPredictions: string[],
  market: IMarket,
): boolean => {
  const isMarketDisabled = find(market.predictions, prediction =>
    includes(betPackerPredictions, prediction),
  );
  let isPredictionSelected;
  if (betPackerSelections) {
    isPredictionSelected = find(betPackerSelections, selection =>
      includes(selection.market.id, market.id),
    );
  }

  return isMarketDisabled || isPredictionSelected;
};

export default isBetPackerMarketActive;

import DefaultTimerFactory from '@features/events/components/scoreboards/timers/defaultTimerFactory/DefaultTimerFactory';

import {
  getCurrentPeriod,
  formatTimeWithAdditionMinutes,
  getElapsedTime,
  getDuration,
} from '@common/helpers/eventsHelper/scoreboards/timerModel';

const options = [
  ({ timer, showTime, shortTimer, category }): string =>
    showTime
      ? formatTimeWithAdditionMinutes(getElapsedTime(timer), getDuration(timer))
      : getCurrentPeriod(timer, shortTimer, category),
];

const GenericTimer = DefaultTimerFactory(options);

export default GenericTimer;

import assign from 'lodash/assign';
import keys from 'lodash/keys';
import reduce from 'lodash/reduce';
import filter from 'lodash/filter';
import toPairs from 'lodash/toPairs';
import split from 'lodash/split';

import { ICategoriesToExclude } from '@common/interfaces/helpers/ICategoriesHelper';

const categoriesKeys: Record<string, Record<string, boolean>> = {
  football: {
    1791: true,
    5966: true,
    22674: true,
    2857: true,
  },
  icehockey: {
    1792: true, // ice hockey
  },
  fieldshockey: {
    1796: true, // fields hockey
  },
  tennis: {
    1793: true,
  },
  basketball: {
    1795: true,
  },
  basketball3x3: {
    27046: true,
  },
  handball: {
    1794: true,
    2859: true,
  },
  american: {
    1802: true,
  },
  darts: {
    1799: true,
  },
  futsal: {
    3700: true,
  },
  tabletennis: {
    8724: true,
    2874: true,
  },
  badminton: {
    8878: true,
    8873: true,
    8879: true,
  },
  volleyball: {
    1797: true,
  },
  beachvolleyball: {
    8239: true,
  },
  snooker: {
    1801: true,
  },
  rugby: {
    1803: true,
  },
  aussierules: {
    1804: true,
  },
  baseball: {
    1805: true,
  },
  bowls: {
    11888: true,
  },
  esport: {
    14328: true,
  },
  chess: {
    22304: true,
  },
  beachsoccer: {
    3920: true,
  },
  entertainment: {
    2716: true,
  },
  waterball: {
    1798: true,
  },
  box: {
    1800: true,
  },
  bandy: {
    1807: true,
  },
  golf: {
    1808: true,
    17714: true,
  },
  motorsport: {
    1809: true,
  },
  cycling: {
    1810: true,
  },
  horseriding: {
    1806: true,
  },
  wintersport: {
    1811: true,
  },
  pesapallo: {
    1812: true,
  },
  curling: {
    3934: true,
  },
  floorball: {
    3430: true,
  },
  olympia: {
    5918: true,
    2849: true,
  },
  minisoccer: {
    6275: true,
  },
  squash: {
    14128: true,
  },
  cricket: {
    12377: true,
  },
  mixedmartialarts: {
    14514: true,
  },
  biathlon: {
    3466: true,
  },
  formula1: {
    3640: true,
  },
  esoccer: {
    22384: true,
  },
  ebasketball: {
    22691: true,
  },
  eicehockey: {
    22751: true,
  },
  etennis: {
    22806: true,
  },
  skialpin: {
    3442: true,
  },
  skispringen: {
    4227: true,
  },
  athletics: {
    5764: true,
  },
  langlauf: {
    4099: true,
  },
};

export const getCategoryNameById = (id: number): string => {
  const result = filter(toPairs(categoriesKeys), item => item[1][id]);
  return result.length ? result[0][0] : '';
};

export const namedCategories: { [key: number]: string } = reduce(
  keys(categoriesKeys),
  (acc: { [key: number]: string }, el: string) =>
    assign(acc, {
      ...reduce(
        keys(categoriesKeys[el]),
        (sum: { [key: number]: string }, id: string) =>
          assign(sum, { [id]: el }),
        {},
      ),
    }),
  {},
);

export const SPORT_WEIGHTS: Record<number, number> = {
  1791: 1, // Football
  5966: 2, // Women Football
  22384: 3, // eSoccer
  22674: 4, // Simulated Reality (SR)
  1793: 5, // Tennis
  1792: 10, // IceHockey
  1796: 60, // Field Hockey
  1795: 15, // Basketball
  1794: 20, // Handball
  1802: 25, // American Football
  1799: 30, // Darts
  3700: 35, // Futsal
  8724: 40, // Tischtennis
  8878: 45, // Badminton
  1797: 50, // Volleyball
  8239: 55, // Beach Volleyball
  1801: 60, // Snooker
  1803: 65, // Rugby
  1804: 70, // Aussie Rules
  1805: 75, // Baseball
  11888: 80, // Bowls
  14328: 85, // eSports
  22304: 90, // Chess
};

// Removing categories from filters
export const categoriesToExclude: Record<
  number,
  keyof typeof ICategoriesToExclude | boolean
> = {
  5966: 'WomensFootball',
  6275: 'Hallenfussball',
  3920: true,
  2857: true,
  2859: true,
  2852: true,
};

export const amateurLeagues: Record<number, boolean> = reduce(
  [
    9614,
    6574,
    7500,
    8535,
    7343,
    6575,
    1936,
    7800,
    3173,
    7746,
    7695,
    8052,
    3247,
    1938,
    4576,
    1937,
    3680,
    8042,
    7860,
    2197,
    1940,
    7894,
    5048,
    5455,
    3696,
    7797,
    7798,
    7367,
    7360,
    7469,
    5443,
    4911,
    5450,
    5257,
    4972,
    5122,
    2813,
    3619,
    3174,
    3620,
    3645,
    4781,
    4712,
    4787,
    9144,
    5229,
    5228,
    6638,
    6639,
    6288,
    6291,
    6289,
    9145,
    6688,
    9135,
    6290,
    6292,
    6687,
    9016,
    9017,
    3406,
    7067,
    9884,
    9883,
    12548,
    4222,
    4220,
    4219,
    4216,
    4221,
    4217,
    4218,
    4687,
    4686,
    11018,
    11017,
    10539,
    5074,
    10448,
    10422,
    10421,
    10409,
    10226,
    10224,
    10225,
    10227,
    10223,
    10214,
    10215,
    3861,
    3861,
    10216,
    10217,
    10218,
    10219,
    10220,
    10221,
    2214,
    2213,
    2218,
    2217,
    2216,
    2215,
    10050,
    3645,
    9686,
    9533,
    9413,
    9413,
    9387,
    7388,
    9294,
    5187,
    9257,
    9215,
    4697,
    8987,
    8985,
    8866,
    6535,
    8396,
    8350,
    8316,
    8297,
    8296,
    8276,
    7774,
    7748,
    7679,
    7664,
    7665,
    7421,
    7391,
    7388,
    7060,
    6872,
    6847,
    6817,
    6744,
    6598,
    6578,
    6535,
    6510,
    6278,
    4405,
    4086,
    5242,
    5243,
    5241,
    5240,
    5201,
    5188,
    8953,
    5074,
    10220,
    10214,
    4787,
    10219,
    4781,
    4712,
    3861,
    3861,
    10221,
    10227,
    4683,
    4697,
    4685,
    4684,
    4687,
    4686,
    4656,
    4447,
    4405,
    4402,
    4222,
    4220,
    4221,
    4216,
    4217,
    4218,
    4219,
    4086,
    10217,
    10226,
    10225,
    10223,
    10216,
    10224,
    10218,
    3861,
    10215,
    2738,
    2737,
    2306,
    2218,
    2217,
    2216,
    2215,
    2213,
    2214,
    5470,
    4923,
    4880,
    2792,
    2791,
    7188,
    5385,
    7135,
    4392,
    4393,
    3465,
    10784,
    10783,
    5803,
    6333,
    6506,
    5211,
    5210,
    5213,
    5212,
    2716,
    8051,
  ],
  (acc: Record<number, boolean>, e: number) => assign(acc, { [e]: true }),
  {},
);

export const getCheckedCategories = (): string[] => {
  const queryCategories: string | null = new URLSearchParams(
    window.location.search,
  ).get('categories');

  return queryCategories ? split(queryCategories, ',') : [];
};

export const ExpandableWithTable = {
  6108: true, // Euro 2024
  5566: true, // Copa America
};

export default categoriesKeys;

import styled from 'styled-components';

import { TextNormal } from '@features/core/styles/text.styled';

export const Content = styled.div`
  position: relative;
  background-color: ${props => props.theme.textMain};
  color: ${props => props.theme.inputDefaultText};
  font-size: ${props => props.theme.fontMD};
  font-stretch: condensed;
  overflow-y: auto;
`;

export const IconWrapper = styled.div`
  position: absolute;
  z-index: 1;
  top: ${({ theme }) => theme.space_15};
  right: ${({ theme }) => theme.space_15};
`;

export const InfoSection = styled.div`
  padding: ${({ theme }) => theme.space_20} ${({ theme }) => theme.space_25} 0;
`;

export const InfoText = styled.p`
  color: ${props => props.theme.main};
  margin-bottom: ${({ theme }) => theme.space_15};
`;

export const Footer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: ${({ theme }) => theme.space_20} ${({ theme }) => theme.space_40};
  background: ${props => props.theme.highlightsHeaderBackground};
`;

export const ButtonWrapper = styled.div`
  margin-bottom: ${({ theme }) => theme.space_15};
  width: 100%;
`;

export const TransparentButtonWrapper = styled.div`
  cursor: pointer;
`;

export const LaterText = styled(TextNormal)`
  color: ${({ theme }) => theme.highlightWrapperBackground};
  text-transform: uppercase;
  text-decoration: underline ${({ theme }) => theme.highlightWrapperBackground};
  display: inline-block;
`;

export const BoldText = styled(TextNormal)`
  display: inline-block;
  font-weight: ${({ theme }) => theme.fontBold};
`;

export const Title = styled(TextNormal)`
  text-transform: uppercase;
  font-weight: ${({ theme }) => theme.fontBold};
`;

export interface IFavoritesButton {
  eventId?: string;
  categoryId?: string;
  parentId?: string;
  favoritesType: string;
  setIsActionModalOpen?: (isOpen: boolean) => void;
  disabledHandler?: boolean;
}

enum FavoritesButtonType {
  home = 'home',
  sport = 'sport',
  live = 'live',
}

export default FavoritesButtonType;

import max from 'lodash/max';
import min from 'lodash/min';
import reduce from 'lodash/reduce';
import floor from 'lodash/floor';
import forEach from 'lodash/forEach';
import map from 'lodash/map';
import times from 'lodash/times';
import constant from 'lodash/constant';
import concat from 'lodash/concat';

import services from '@features/core/services';
import { PageName } from '@features/core/routing/linkAliases';

import {
  isDesktopView,
  isLsXLScreenWidth,
  isXXLScreenWidth,
} from '@common/helpers/deviceUtil';
import { isPenalty } from '@common/helpers/eventsHelper/scoreboards/timerModel';
import {
  IBetDetail,
  IEvent,
  ICategory,
  IMarketType,
  MarketType,
  PageType,
  IMarketsKeysSets,
} from '@common/interfaces';
import {
  isLiveCurrent,
  isLiveOrSoon,
  isWinner,
} from '@common/helpers/eventsHelper/eventStatusHelper';
import {
  getDesktopMarketsKeys,
  getHalfTimeMarkets,
  getPenaltyMarkets,
} from '@common/helpers/markets/marketTypes';
import { shouldBeWrappedInCategoryHolder } from '@common/helpers/eventsHelper/eventLabelHelper';
import {
  isCategoryFavorite,
  isEventFavorite,
} from '@common/helpers/favoritesHelper';
import { isMatchWebRoutes } from '@common/providers/router/helper';
import { ICMSContentOfAffiliate } from '@common/providers/application/types';

const MIN_COUNT = 1;
const MAX_COUNT = 4;

export const MINIMAL_MARKET_WIDTH = 108;

export const getMarketsLimit = (containerWidth: number): number => {
  if (!containerWidth) {
    return MIN_COUNT;
  }
  if (!isDesktopView()) {
    return MIN_COUNT;
  }

  let marketContainerWidth = containerWidth;

  //  const eventDetailSidebar = document.getElementById('event-detail-wrap');
  //  marketContainerWidth -= eventDetailSidebar?.clientWidth || 0;

  const eventRowTitle = document.querySelector('.event-item-title');
  marketContainerWidth -= eventRowTitle?.clientWidth || 0;

  const limit = marketContainerWidth / MINIMAL_MARKET_WIDTH;
  return min([MAX_COUNT, max([MIN_COUNT, floor(limit, 0)])]) || MIN_COUNT;
};

/**
 * getMarketsForResponse
 *
 * @param {string[]} markets
 * @returns {string[]} marketKeys
 */
export const getMarketsForResponse = (markets: string[] = []): string[] => {
  const isHomePage = isMatchWebRoutes(PageName.HOME);
  const isSportPage = isMatchWebRoutes(PageName.SPORT);
  const isCategory = isMatchWebRoutes(PageName.EVENTS_CATEGORY);
  const isLivePages = isMatchWebRoutes(PageName.LIVE);

  if (isLivePages && !!markets.length && isLsXLScreenWidth) {
    return markets.slice(0, -1);
  }

  if (isHomePage || isSportPage || isCategory) {
    if (isLsXLScreenWidth) {
      // 1280 -  1600
      return markets.slice(0, -2);
    }
    if (isXXLScreenWidth) {
      // 1600 - 1920
      return markets.slice(0, -1);
    }
  }

  return markets;
};

export const getBetLink = (
  bet: IBetDetail,
  userAffiliateParams?: ICMSContentOfAffiliate,
): string => {
  const selections: Array<object> = reduce(
    bet.selections,
    (acc: Array<object>, item) => {
      acc.push({
        eid: item.event_id,
        pid: item.prediction_id,
        mid: item.market_id,
      });
      return acc;
    },
    [],
  );
  const data = {
    banks: [],
    size: bet.leg_sizes,
    totalStake: Number(bet.total_cost),
    type: bet.type,
    restoredSelections: selections,
  };

  const link = JSON.stringify({
    ...data,
  });
  const encodedString = btoa(link); // Base64 encode the String
  const langStr = services.domainLang === 'en' ? '/en' : '';
  const baseUrl = `${window.location.protocol}//${window.location.host}`;
  const marketingParameters = reduce(
    ['btag', 'ia_affid'],
    (acc, key) =>
      concat(
        acc,
        userAffiliateParams && userAffiliateParams[key]
          ? `&${key}=${userAffiliateParams[key]}`
          : '',
      ).join(''),
    '',
  );
  return `${baseUrl}${langStr}?import_bettingslip=${encodedString}${marketingParameters}`;
};

/**
 * getMarketsKeys
 *
 * @param {IEvent} event
 * @param {string} sportHeaderId
 * @param {IMarketsKeysSets} marketsKeysSets
 * @param {boolean} checkSpecial
 * @param {boolean} checkWinner
 * @returns {string[]} marketKeys
 */
export const getMarketsKeys = (
  event: IEvent,
  sportHeaderId: string,
  marketsKeysSets: IMarketsKeysSets,
  checkSpecial?: boolean,
  checkWinner?: boolean,
): IMarketType[] => {
  const fullTimeMarketKeys = marketsKeysSets[sportHeaderId]?.fullTimeMarketKeys;
  const penaltyMarketKeys = marketsKeysSets[sportHeaderId]?.penaltyMarketKeys;

  if (!!checkWinner && isWinner(event)) {
    return [MarketType.WINNER_CHAMPIONSHIP];
  }

  if (!!checkSpecial && isPenalty(event.timer)) {
    return penaltyMarketKeys;
  }

  return fullTimeMarketKeys;
};

const setMarketsKeys = (
  sport_header_id,
  event,
  categories,
  setInitialMarketsKeysSets,
): void => {
  const topCategoryIdForMarkets = parseInt(
    categories[event.category_id]?.top_category_id,
    10,
  );
  const marketKeys = getDesktopMarketsKeys(
    event,
    categories[event.category_id],
  );

  const fullTimeMarketKeys = getMarketsForResponse(
    map(times(4, constant('PLACEHOLDER')), (e, j) => marketKeys[j] || e),
  );

  const halfTimeMarketKeys = getMarketsForResponse(
    getHalfTimeMarkets(topCategoryIdForMarkets),
  );

  const penaltyMarketKeys = getMarketsForResponse(
    getPenaltyMarkets(topCategoryIdForMarkets),
  );

  setInitialMarketsKeysSets(prevState => ({
    ...prevState,
    [sport_header_id]: {
      fullTimeMarketKeys,
      halfTimeMarketKeys,
      penaltyMarketKeys,
    },
  }));
};

export const generateCategoriesList = ({
  eventsData,
  sortedEventIds,
  favoritesEvents,
  favoritesCategories,
  categories,
  highlightedCategoryWeights,
  setInitialMarketsKeysSets,
  pageType,
}): {
  event: IEvent;
  category: ICategory;
  delimiterType: string | null;
  sport_header_id: string;
  showCategory: boolean;
  showHighlightedCategory: boolean;
  eventIds: string[];
}[] => {
  const isLive = pageType === PageType.LIVE;
  const isSport = pageType === PageType.SPORT;
  let eventIds: string[] = [];
  const categoriesList: {
    event: IEvent;
    category: ICategory;
    delimiterType: string | null;
    sport_header_id: string;
    showCategory: boolean;
    showHighlightedCategory: boolean;
    eventIds: string[];
  }[] = [];
  let prevSportType = '';
  let prevHighlightedSportType = '';
  let favoriteItem = false;
  let delimiterType: string | null = null;
  let isPenaltyEvent = false;
  let prevPenalty = false;
  let prevDelimiterType = '';

  if (!sortedEventIds.length) {
    return [];
  }

  let prev_sport_header_id = '';
  let prevStartingTime = false;

  // On mobile highlights render events as single list
  forEach(sortedEventIds, (eventId, i: number) => {
    const event = eventsData?.[eventId];
    if (!event) {
      return;
    }

    favoriteItem =
      isLiveOrSoon(event) &&
      isLive &&
      (isEventFavorite(event.id, favoritesEvents) ||
        isCategoryFavorite(event.category_id, favoritesCategories));

    isPenaltyEvent = isPenalty(event.timer);

    const topCategoryId = categories[event.category_id]?.top_category_id;
    const isHighlighted =
      !!highlightedCategoryWeights[event.category_id] && isLiveOrSoon(event);
    const isStartingTime = isLiveOrSoon(event);
    const sport_header_id = `${topCategoryId}_${isStartingTime}_${isHighlighted}`;

    if (prev_sport_header_id !== sport_header_id) {
      setMarketsKeys(
        sport_header_id,
        event,
        categories,
        setInitialMarketsKeysSets,
      );
      prev_sport_header_id = sport_header_id;
    }
    const nextEventId = sortedEventIds[i + 1] as string;
    const nextEvent = eventsData[nextEventId] || ({} as IEvent);
    const isNextEventStartingTime = isLiveOrSoon(nextEvent);
    const nextCategoryId = `${nextEvent.category_id}_${isLiveCurrent(
      nextEvent,
    )}`;
    const isNextEventPenalty = isPenalty(nextEvent.timer);
    const categoryId = `${event.category_id}_${isLiveCurrent(event)}`;
    const sportType = sport_header_id;
    let highlightedSportType;

    const nextFavoriteItem =
      isEventFavorite(nextEvent?.id, favoritesEvents) ||
      isCategoryFavorite(
        categories[nextEvent?.category_id]?.id,
        favoritesCategories,
      );

    if (isHighlighted && (isLive || isSport) && isStartingTime) {
      highlightedSportType = categories[event.category_id]?.top_category_id;
    }

    delimiterType = null;

    if (i === 0 && favoriteItem && isLive) {
      delimiterType = 'common.labels.my_events';
      prevDelimiterType = prevDelimiterType || delimiterType;
    }

    const shouldBeShownCategory =
      !!delimiterType ||
      shouldBeWrappedInCategoryHolder(prevSportType, sportType) ||
      (isStartingTime !== prevStartingTime && !favoriteItem);

    if (
      shouldBeWrappedInCategoryHolder(categoryId, nextCategoryId) ||
      (favoriteItem && !nextFavoriteItem) ||
      // (favoriteItem && categoryId !== nextCategoryId && !isInCategoryPage) ||
      (isPenaltyEvent && prevPenalty && !isNextEventPenalty) ||
      (event.category_id === nextEvent.category_id &&
        isStartingTime &&
        !isNextEventStartingTime)
    ) {
      const shouldBeShownHighlightedCategory =
        isStartingTime &&
        shouldBeWrappedInCategoryHolder(
          prevHighlightedSportType,
          highlightedSportType,
        );

      eventIds.push(event.id);
      categoriesList.push({
        category: categories[event.category_id],
        event,
        sport_header_id,
        showCategory: shouldBeShownCategory,
        showHighlightedCategory: shouldBeShownHighlightedCategory,
        eventIds,
        delimiterType: prevDelimiterType || delimiterType,
      });

      if (
        isLive &&
        isStartingTime &&
        !isNextEventStartingTime &&
        !favoriteItem
      ) {
        prevDelimiterType = 'common.labels.soon';
      } else if (favoriteItem && !nextFavoriteItem) {
        prevDelimiterType = 'common.labels.all_games';
      } else {
        prevDelimiterType = '';
      }

      prevSportType = sportType;
      prevHighlightedSportType = highlightedSportType;
      eventIds = [];
    } else {
      eventIds.push(event.id);
    }
    prevStartingTime = isStartingTime;
    prevPenalty = isPenaltyEvent;
  });

  return categoriesList;
};

export interface IPayment {
  link?: {
    proceed?: {
      href: string;
    };
  };
  deposit?: {
    amount?: string;
    status: string;
    id?: string;
    pocket_id?: string;
    wallet_id?: string;
    fields?: { value: string; name: string }[];
  };
  withdrawal?: {
    status: string;
  };
}

export enum IPaymentOperation {
  DEPOSIT = 'deposit',
  WITHDRAWAL = 'withdrawal',
}

export enum IPaymentBalanceOperation {
  CASHBACKS = 'cashbacks',
  BETTINGSLIP = 'bettingslip',
}

export enum IPaymentOperationResult {
  SUCCESS = 'success',
  PREPARED = 'prepared',
  FAIL = 'fail',
  NOT_ALLOWED = 'not-allowed',
  NOT_CONFIRMED = 'not-confirmed',
  NOT_VERIFIED = 'not-verified',
  ERROR = 'error',
  NO_ONLINE_ACCOUNT = 'no-online-account',
}

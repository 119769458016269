import groupBy from 'lodash/groupBy';
import reduce from 'lodash/reduce';
import sortBy from 'lodash/sortBy';
import find from 'lodash/find';
import every from 'lodash/every';
import values from 'lodash/values';
import parseISO from 'date-fns/parseISO';

import { ISelection, IBetSelection } from '@common/interfaces';
import { isLiveCurrent } from '@common/helpers/eventsHelper/eventStatusHelper';

/**
 * sortByTime
 *
 * @param {IBetSelection[][]} selections
 * @returns {IBetSelection[][]} grouped
 */
export const sortByTime = (
  selections: IBetSelection[][],
): IBetSelection[][] => {
  return sortBy(selections, selection => {
    return selection[0].event_date_safe_server
      ? parseISO(selection[0].event_date_safe_server)
      : selection;
  });
};

/**
 * groupByEvent
 *
 * @param {ISelection[]} selections
 * @returns {ISelection[]} allSelections
 */
export const groupByEvent = (selections: ISelection[]): ISelection[][] => {
  return values(
    groupBy(selections, selection => selection.event && selection.event.id),
  );
};

/**
 * groupByMarket
 *
 * @param {ISelection[]} selections
 * @returns {ISelection[][]} selectionsByMarket
 */
export const groupByMarket = (selections: ISelection[]): ISelection[][] => {
  const groupedSelection = groupBy(
    selections,
    selection => selection?.market?.id,
  );
  return reduce(
    groupedSelection,
    (acc: ISelection[][], value: ISelection[]) => {
      acc.push(value);
      return acc;
    },
    [],
  );
};

/**
 * groupByCategory
 *
 * @param {ISelection[]} selections
 * @returns {Record<string, ISelection[]>} selectionsByCategories
 */
export const groupByCategory = (
  selections: ISelection[],
): Record<string, ISelection[]> => {
  return groupBy(
    selections,
    selection => selection.category && selection.category.id,
  );
};

export const isFullyLive = (selections: ISelection[]): boolean => {
  return every(
    selections,
    selection => selection.event && isLiveCurrent(selection.event),
  );
};

/**
 * getSelectionByEventId
 *
 * @param {ISelection[]} selections
 * @param {string} id
 * @returns {ISelection | undefined} selectionByEventId
 */
export const getSelectionByEventId = (
  selections: ISelection[],
  id: string,
): ISelection | undefined => {
  return find(selections, selection => selection?.event?.id === id);
};

import { RuleObject } from 'rc-field-form/lib/interface';

const patternRuleFactory = (pattern: RegExp) => async (
  rule: RuleObject,
  value: string,
): Promise<void> =>
  pattern.test(value)
    ? Promise.resolve()
    : Promise.reject(new Error(`${rule.message}`));

export default patternRuleFactory;

import { useEffect, useState, useRef, useCallback } from 'react';

import { useCountDownType } from '@common/hooks/useCountDown.types';

const DEFAULT_COUNT_DOWN_TIME = 5;
const COUNTDOWN_INTERVAL = 1000;

const useCountDown = (
  expireTime: number = DEFAULT_COUNT_DOWN_TIME,
): useCountDownType => {
  const [countdown, setCountdown] = useState<number>(expireTime);
  const timeoutRef = useRef<ReturnType<typeof setTimeout> | null>(null);
  const intervalRef = useRef<ReturnType<typeof setInterval> | null>(null);

  const clearTimers = (): void => {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }
    if (intervalRef.current) {
      clearInterval(intervalRef.current);
    }
  };

  const countdownHandler = useCallback(() => {
    setCountdown(prev => prev - 1);
  }, []);

  const startCountDown = useCallback(
    (cb: () => void, delay: number = expireTime) => {
      const delayTimer = +`${delay || expireTime}100`;

      clearTimers();
      setCountdown(delay);

      intervalRef.current = setInterval(countdownHandler, COUNTDOWN_INTERVAL);
      timeoutRef.current = setTimeout(() => {
        if (intervalRef.current) {
          clearInterval(intervalRef.current);
        }
        cb();
      }, delayTimer);
    },
    [countdownHandler, expireTime],
  );

  useEffect(() => (): void => clearTimers(), []);

  return { countdown, startCountDown };
};

export default useCountDown;

import styled from 'styled-components';

import { TextLarge } from '@features/core/styles/text.styled';

export const Wrapper = styled.div`
  display: grid;
  grid-auto-flow: row;
  justify-content: center;
  align-items: center;
  justify-items: center;
  height: calc(100vh - 233px);
  position: relative;
  margin: ${({ theme }) => theme.space_50};
  box-sizing: border-box;
  grid-template-rows: 1fr 1fr;

  .bracket {
    border-top: 2px solid ${props => props.theme.loaderPrimaryBracket};
    border-left: 2px solid ${props => props.theme.loaderPrimaryBracket};
    border-bottom: 2px solid transparent;
    border-right: 2px solid transparent;
    border-radius: 50%;
  }

  .bracketSecond {
    border-bottom: 2px solid ${props => props.theme.loaderSecondaryBracket};
    border-right: 2px solid ${props => props.theme.loaderSecondaryBracket};
    border-top: 2px solid transparent;
    border-left: 2px solid transparent;
    border-radius: 50%;
  }

  .bracket,
  .bracketSecond {
    width: 106px;
    height: 106px;
    position: absolute;
    transform: rotate(45deg);
    z-index: 10;
  }
`;

export const Text = styled(TextLarge)`
  position: absolute;
  top: 50%;
  left: 51%;
  transform: translate(-50%, -50%);
  font-size: ${({ theme }) => theme.fontLG};
  color: ${props => props.theme.loaderText};
  z-index: 21;
`;

export const Loader = styled.div`
  display: block;
  width: 110px;
  height: 110px;
  margin: ${({ theme }) => theme.space_60};
  border-radius: 100%;
  animation: spin 1s linear infinite;
  box-sizing: border-box;

  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }

    100% {
      transform: rotate(360deg);
    }
  }
`;

export const LoaderContainer = styled.div`
  position: relative;
  margin-top: auto;
`;

export const OverlayOne = styled.div<{ backgroundColor: string }>`
  height: 22px;
  position: absolute;
  top: 50%;
  left: -1px;
  right: 95px;
  transform: translateY(-50%);
  z-index: 20;
  background: ${({ backgroundColor, theme }) =>
    backgroundColor || theme.loaderPrimaryBackground};
`;

export const OverlayTwo = styled.div<{ backgroundColor: string }>`
  height: 22px;
  position: absolute;
  top: 50%;
  left: 99px;
  right: -1px;
  transform: translateY(-50%);
  z-index: 20;
  background: ${({ backgroundColor }) =>
    backgroundColor || (props => props.theme.loaderPrimaryBackground)};
`;
export const Shadow = styled.div`
  height: 10px;
  opacity: 0.25;
  border: solid 1px ${({ theme }) => theme.loaderShadow};
  background-color: ${({ theme }) => theme.loaderShadow};
  border-radius: 50%;
  width: 50px;
  margin-bottom: auto;
`;

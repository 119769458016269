import some from 'lodash/some';
import filter from 'lodash/filter';
import invokeMap from 'lodash/invokeMap';
import findIndex from 'lodash/findIndex';

import services from '@features/core/services';

import { IAppEvents, IListener, IListenerInfo } from './AppEvents.types';

export const postEventToNative = (type, body): void => {
  if (window.ReactNativeWebView?.postMessage) {
    window.ReactNativeWebView.postMessage(JSON.stringify({ type, body }));
  }
};

const createAppEvents = (): IAppEvents => {
  const listeners: IListenerInfo[] = [];

  const addEventListener = (eventType: string, listener: IListener): void => {
    if (!some(listeners, ['listener', listener])) {
      listeners.push({
        eventType,
        listener,
      });
    }
  };

  const removeEventListener = (listener: IListener): void => {
    const listenerIndex = findIndex(listeners, ['listener', listener]);
    if (listenerIndex !== -1) {
      listeners.splice(listenerIndex, 1);
    }
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const emitEvent = <E>(type: string, event: E, proxied?: boolean): any[] => {
    services.logger.log(`Event: ${type}, body: ${event}`);
    if (!proxied) {
      postEventToNative(type, event);
    }
    return invokeMap(filter(listeners, ['eventType', type]), 'listener', event);
  };

  return {
    addEventListener,
    removeEventListener,
    emitEvent,
  };
};

export default createAppEvents;

import React from 'react';
import map from 'lodash/map';

import OddsComparisonSliderSection from '@features/oddsComparison/components/oddsComparisonSliderSection/OddsComparisonSliderSection';
import { PageName } from '@features/core/routing';

import { IOddsComparisonData } from '@common/interfaces';
import { isDesktopView } from '@common/helpers/deviceUtil';
import { validateOddsComparisonData } from '@common/helpers/oddsComparisonHelper/oddsComparisonHelper';
import { isMatchWebRoutes } from '@common/providers/router/helper';

import { OddsComparisonSection } from '../oddsComparisonSection';

import * as S from './OddsComparisonList.styled';

const RenderOddsComparsionSectionList = (
  sectionListData: IOddsComparisonData[][],
  choosedSectionId?: number | null,
  sectionsCount?: number | null,
  isSidebar?: boolean,
  currentSectionRef?: React.RefObject<HTMLDivElement>,
): (JSX.Element | null)[] => {
  const isHomePage = isMatchWebRoutes(PageName.HOME);
  const isDesktop = isDesktopView();
  const sectionId = choosedSectionId as number;
  const leftSlide = sectionId - 1;

  return map(sectionListData, (sectionEvents, index) => {
    const validatedSectionEvents = validateOddsComparisonData(sectionEvents);
    const activeSection = sectionId === index;
    const sliderSections =
      leftSlide === index || activeSection || sectionId + 1 === index;
    if (isSidebar && sliderSections) {
      return (
        <S.OddsComparisonSliderWrapper
          isLeft={leftSlide === index}
          isActive={activeSection}
          ref={(activeSection && currentSectionRef) || null}
          key={index}
        >
          <OddsComparisonSliderSection
            sectionIndex={index}
            sectionEvents={validatedSectionEvents}
            currentSections={sliderSections}
          />
        </S.OddsComparisonSliderWrapper>
      );
    }
    if (
      ((isHomePage && !isDesktop && index < (sectionsCount as number)) ||
        isDesktop) &&
      !isSidebar
    ) {
      return (
        <OddsComparisonSection
          sectionIndex={index}
          sectionEvents={validatedSectionEvents}
          key={index}
        />
      );
    }
    if (!isHomePage) {
      return (
        <OddsComparisonSliderSection
          key={index}
          sectionIndex={index}
          sectionEvents={validatedSectionEvents}
        />
      );
    }
    return null;
  });
};

export default RenderOddsComparsionSectionList;

import map from 'lodash/map';
import filter from 'lodash/filter';

import { DATE_TEMPLATE } from '@features/core/form/components/dateInput/DateTemplates';
import { PageName } from '@features/core/routing';
import { TReturnIconName } from '@features/bets/components/betStatusSquare/BetStatusSquare.types';
import services from '@features/core/services';

import { USE_NEW_SCAN_BET_API } from '@common/constants/config';
import {
  IBetDetail,
  IBettingslipType,
  IRestoreSelection,
  StatusCode,
} from '@common/interfaces';
import formatDate from '@common/helpers/timeHelper/localizedFormat';
import { setSelectedBetId, useBets } from '@common/providers/bets/useBets';
import { useEventsListState } from '@common/providers/events/eventList/useEventsList';
import { restoreBettingslipFromList } from '@common/helpers/bettingSlipHelper/bettingSlipModel';
import { setBettingSlipData } from '@common/providers/bettingslip/useBettingSlip';
import { isMatchWebRoutes, setLocation } from '@common/providers/router/helper';
import { isDesktopView } from '@common/helpers/deviceUtil';

const normalizeBetDetailResponse = (type, response): IBetDetail[] => {
  if (type === 'scan-bet' && !services.config.get(USE_NEW_SCAN_BET_API)) {
    return [response];
  }
  return [response.bet_detail];
};

export const handleReUseBet = (bet: IBetDetail): void => {
  const isDesktop = isDesktopView();
  const loading = useBets.getState().detailLoading;
  const eventLoading = useEventsListState.getState().cashout.loading;
  const list = useEventsListState.getState().cashout.data;
  const reUseBetDisabled =
    eventLoading ||
    restoreBettingslipFromList(
      (bet?.selections as unknown) as IRestoreSelection[],
      list,
    ).selections.length === 0;

  if (loading || reUseBetDisabled) {
    return;
  }
  setBettingSlipData({
    ...restoreBettingslipFromList(
      (bet.selections as unknown) as IRestoreSelection[],
      list,
    ),
    type: bet.raw_type,
    ...(bet.raw_type === IBettingslipType.system
      ? { size: bet.leg_sizes }
      : {}),
    totalStake: parseFloat(bet.initial_total_cost),
    banks: map(filter(bet?.selections, ['bank', 1]), selection =>
      parseFloat(selection.event_id),
    ),
  });

  if (isDesktop) {
    setSelectedBetId('');
    setLocation(
      isMatchWebRoutes(PageName.HOME) ? PageName.HOME : PageName.LIVE,
      {},
      { forceReload: true },
    );
  } else {
    setLocation(PageName.BETTING_SLIP);
  }
};

export const getFormatDate = (date: number, withSeparate = false): string =>
  formatDate(
    parseInt(`${date || 0}`, 10) * 1000,
    `${DATE_TEMPLATE} ${withSeparate ? '-' : ''} HH:mm:ss`,
  );

export const getIconNameByStatus = (status_code): TReturnIconName => {
  switch (status_code) {
    case StatusCode.WON:
      return 'check';
    case StatusCode.CANCELLED:
      return 'minus';
    case StatusCode.OPEN:
      return 'empty-circle';
    case StatusCode.LOST:
      return 'close';
    default:
      return '';
  }
};

export default normalizeBetDetailResponse;

import React, { useEffect, useState, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, matchPath } from 'react-router-dom';
import includes from 'lodash/includes';

import { TimeFilters } from '@features/categories/timeFilters';
import { CategoriesSwitcherMobile } from '@features/categories/categoriesSwitcherMobile';
import { CategoriesSwitcherDesktop } from '@features/categories/categoriesSwitcherDesktop';
import { CategoriesList } from '@features/categories/categoriesList';
import { PageName } from '@features/core/routing/linkAliases';
import { categorySwitcherHeight } from '@features/categories/categoriesSwitcherMobile/CategoriesSwitcherMobile.styled';
import { timeFilterHeight } from '@features/categories/timeFilters/TimeFilters.styled';
import { CollapsibleContainer } from '@features/categories/categoryCollapsibleContainer';

import { isDesktopView, isLScreenWidth } from '@common/helpers/deviceUtil';
import { ILiveSportCategoriesTreeData, PageType } from '@common/interfaces';
import {
  excludeCategoriesTreeFromFilter,
  getFilter,
  getTimeSwitcherText,
} from '@common/helpers/categories/categoriesModel';
import {
  DEFAULT_SELECTED_CATEGORIES,
  DEFAULT_SELECTED_CATEGORY_TYPE_TAB,
  DEFAULT_SELECTED_SPORT_CATEGORY,
} from '@common/providers/events/liveCategories/state';
import {
  setLocation,
  appendLanguage,
  isMatchWebRoutes,
} from '@common/providers/router/helper';
import { scrollTopWindow } from '@common/helpers/scrollHelper';
import {
  setDefaultLiveCategory,
  setSelectedCategoryTypeTab,
  setSportCategoriesFilter,
  toggleSportCategory,
  useLiveCategories,
} from '@common/providers/events/liveCategories/useLiveCategories';
import { getCategoriesTree } from '@common/providers/events/liveCategories/helper';
import { useAppState } from '@common/providers/application/useAppState';

import { TabItem } from '@ui/components/tabs/tabItem';
import { Tabs, Tab } from '@ui/components/tabs';
import { Icon } from '@ui/components/icon';
import DualRingLoader from '@ui/components/loaders/DualRingLoader';

import { StyledIcon } from './CategoriesPanel.styled';
import * as S from './CategoriesPanel.styled';
import ICategoriesTabs from './CategoriesPanel.types';

const isDesktop = isDesktopView();
const regularCategoriesTab: ICategoriesTabs = 'regularCategories';

const CategoriesPanel: React.FC = () => {
  const { t } = useTranslation();
  const { pathname } = useLocation();
  const [timeFilter, setTimeFilter] = useState(false);
  const topLayoutHeight = useAppState(state => state.topLayoutHeight);
  const filter = useLiveCategories(s => s.sportCategoriesTree.filter);
  const categoriesTree = useLiveCategories(s => s.sportCategoriesTree.data);
  const tabLoading = useLiveCategories(
    s => s.highlightsMeta.highlightedEvents.loading,
  );
  const selectedSportCategory = useLiveCategories(s => s.selectedSportCategory);
  const selectedCategoryTypeTab = useLiveCategories(
    s => s.selectedCategoryTypeTab,
  );

  const match = matchPath<{ categoryId: 'string' }>(pathname, {
    path: appendLanguage('/events/category/:categoryId'),
  });
  const categoryId = match?.params.categoryId || '';
  const isHome = isMatchWebRoutes(PageName.HOME);
  const filterCategories: ILiveSportCategoriesTreeData[] = useMemo(
    () => excludeCategoriesTreeFromFilter(categoriesTree),
    [categoriesTree],
  );
  const isTimeFilterVisible =
    timeFilter && selectedCategoryTypeTab !== 'longTermCategories';

  const timeSwitcherHandler = (): void => {
    setTimeFilter(!timeFilter);
  };

  useEffect(() => {
    if (isHome && isDesktop) {
      setSportCategoriesFilter({ filter: '' });
      setSelectedCategoryTypeTab({
        selectedCategoryTypeTab: DEFAULT_SELECTED_CATEGORY_TYPE_TAB,
      });
    }
  }, [pathname]);

  useEffect(() => {
    setDefaultLiveCategory({ category: DEFAULT_SELECTED_CATEGORIES[0] });
    if (isDesktop) {
      toggleSportCategory({ category: categoryId });
    }
    setSportCategoriesFilter({ filter: getFilter(window.location.href) });

    return () => {
      setSportCategoriesFilter({ filter: '' });
      getCategoriesTree({ filter: '' });
      setDefaultLiveCategory({ category: '' });
      toggleSportCategory({ category: DEFAULT_SELECTED_SPORT_CATEGORY[0] });
      setSelectedCategoryTypeTab({
        selectedCategoryTypeTab: regularCategoriesTab,
      });
    };
  }, []);

  useEffect(() => {
    if (filter || filter === '') {
      getCategoriesTree({ filter });
    }
  }, [filter]);

  const onChangeTabHandler = (tab: string): void => {
    setSelectedCategoryTypeTab({ selectedCategoryTypeTab: tab });

    if (!isDesktop && selectedCategoryTypeTab !== tab) {
      setSportCategoriesFilter({ filter: '' });
      toggleSportCategory({ category: DEFAULT_SELECTED_SPORT_CATEGORY[0] });
      scrollTopWindow();
    }

    if (
      isDesktop &&
      includes(window.location.pathname, 'category') &&
      filter !== ''
    ) {
      setSportCategoriesFilter({ filter: '' });
      setLocation(PageName.EVENTS_CATEGORY, {
        categoryId,
        filter: 'all',
      });
    }
  };

  const getTabsNavTop = (): number => {
    const fullFilterHeight =
      categorySwitcherHeight + (isTimeFilterVisible ? timeFilterHeight : 0);
    return topLayoutHeight + fullFilterHeight;
  };

  const renderCategoriesSwitcherDesktop = (
    longTerm = false,
  ): React.ReactElement => {
    return (
      <CategoriesSwitcherDesktop
        items={filterCategories}
        selectedCategoryId={selectedSportCategory}
        pageType={PageType.CATEGORIES}
      >
        <CategoriesList isLongTerm={longTerm} />
      </CategoriesSwitcherDesktop>
    );
  };

  return (
    <S.Root>
      {!isDesktop && (
        <S.FiltersWrap data-qa="filters-wrap" topLayoutHeight={topLayoutHeight}>
          <S.MainFilterWrap timeFilter={timeFilter}>
            {selectedCategoryTypeTab !== 'longTermCategories' && (
              <S.TimeSwitcherWrapper>
                <S.TimeSwitcher
                  onClick={timeSwitcherHandler}
                  timeFilter={timeFilter}
                  data-qa="time-filter-switcher"
                >
                  <StyledIcon name="clock" width="18" height="18" />

                  <span>{getTimeSwitcherText(filter)}</span>

                  <Icon
                    name={timeFilter ? 'chevron-up' : 'chevron-down'}
                    width="12"
                    height="12"
                  />
                </S.TimeSwitcher>
              </S.TimeSwitcherWrapper>
            )}

            <CategoriesSwitcherMobile
              items={filterCategories}
              selectedCategoryId={selectedSportCategory}
              pageType={PageType.CATEGORIES}
            />
          </S.MainFilterWrap>
          {timeFilter && selectedCategoryTypeTab !== 'longTermCategories' && (
            <TimeFilters
              currentFilter={filter}
              onChange={timeSwitcherHandler}
            />
          )}
        </S.FiltersWrap>
      )}

      <Tabs
        defaultActiveTab={
          isDesktop ? regularCategoriesTab : selectedCategoryTypeTab
        }
        cookieTabActive={
          isDesktop ? regularCategoriesTab : selectedCategoryTypeTab
        }
        TabTitleComponent={TabItem}
        className="categories-tabs"
        contentClassName="tabs-content"
        onChange={onChangeTabHandler}
        stickyTopHeight={!isDesktop ? getTabsNavTop() : 0}
      >
        <Tab
          tabIndex="regularCategories"
          title={t('categoriesPage.regularBets_tab_title')}
          dataQa="tab-categories-regularCategories"
        >
          <>
            {!isDesktop &&
              (tabLoading ? (
                <S.LoadingWrapper>
                  <DualRingLoader />
                </S.LoadingWrapper>
              ) : (
                <>
                  <CategoriesList
                    isHighlights
                    isFavorite
                    hasFavoritesInfoView
                  />
                  <CategoriesList />
                </>
              ))}
            {isDesktop && (
              <>
                <TimeFilters currentFilter={filter} />

                <CategoriesList isHighlights isFavorite hasFavoritesInfoView />

                <S.CategoriesWrapper>
                  <CollapsibleContainer title={t('events.all_categories')}>
                    {renderCategoriesSwitcherDesktop(false)}
                  </CollapsibleContainer>
                </S.CategoriesWrapper>
              </>
            )}
          </>
        </Tab>
        <Tab
          tabIndex="longTermCategories"
          title={
            isLScreenWidth
              ? t('categoriesPage.longTermBets_tab_title_short')
              : t('categoriesPage.longTermBets_tab_title')
          }
          dataQa="tab-categories-longTermCategories"
        >
          <>
            {!isDesktop && (
              <>
                <CategoriesList
                  isFavorite
                  isHighlights
                  isLongTerm
                  hasFavoritesInfoView
                />
                <CategoriesList isLongTerm />
              </>
            )}
            {isDesktop && (
              <S.LongTermWrapper>
                <CategoriesList isHighlights isLongTerm isFavorite />

                <S.CategoriesWrapper>
                  <CollapsibleContainer title={t('events.all_categories')}>
                    {renderCategoriesSwitcherDesktop(true)}
                  </CollapsibleContainer>
                </S.CategoriesWrapper>
              </S.LongTermWrapper>
            )}
          </>
        </Tab>
      </Tabs>
    </S.Root>
  );
};

export default CategoriesPanel;

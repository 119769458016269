/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint sonarjs/prefer-immediate-return: 0 */
import React from 'react';
import map from 'lodash/map';
import compact from 'lodash/compact';
import split from 'lodash/split';

import {
  withDefaultLogic,
  IWithTimerModelProps,
  withTimerModel,
  DefaultTimerView,
} from '@features/events/components/scoreboards/timers';

import { getCategoryNameById } from '@common/helpers/categories/categories';
import { useAppState } from '@common/providers/application/useAppState';

import { Props } from './DefaultTimerFactory.types';

type Options = ((props) => string)[];

const defaultTimerFactoryUnwrapped = (
  options: Options,
): ((props: Props) => React.ReactElement) => {
  const DefaultTimerFactory = (props: Props): React.ReactElement => {
    // beat update component every 30sec, coz timers do not update on their own
    const beat = useAppState(s => s.beat);
    const { message, showTime, listType, category } = props;

    let rows: string[];

    if (message && showTime) {
      rows = [`${message}`, `${compact(map(options, item => item(props)))}`];
    } else if (message) {
      rows = [message];
    } else {
      rows = split(map(options, item => item(props))[0], '\n');

      // rows = compact(map(options, item => item(props)));
    }
    return (
      <DefaultTimerView
        rows={rows}
        listType={listType}
        className={getCategoryNameById(Number(category?.top_category_id))}
      />
    );
  };

  return DefaultTimerFactory;
};

const defaultTimerFactory = (
  options: Options,
): ((props: IWithTimerModelProps) => React.ReactElement) =>
  withTimerModel(withDefaultLogic(defaultTimerFactoryUnwrapped(options)));

export { defaultTimerFactoryUnwrapped };

export default defaultTimerFactory;

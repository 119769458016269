import services from '@features/core/services';

import { IPaymentMethod } from '@common/interfaces';

export const getMockShopMoneyService = (): Record<
  string,
  Record<string, string | Record<string, string | Record<string, string>>>
> => ({
  service: {
    label: services.domainLang === 'de' ? 'Kundenkarte' : 'Customer card',
    id: IPaymentMethod.SHOP_MONEY,
    deposit: {
      status: 'enabled',
      limit: {
        min: '0.01',
        max: '1000.00',
      },
      fee: '0.00',
      term: 'Immediately',
    },
    withdrawal: {
      fee: '0.00',
      term: '2-5 Banking days',
      limit: {
        min: '0.01',
        max: '1000.00',
      },
      status: 'enabled',
    },
  },
});

export default getMockShopMoneyService;

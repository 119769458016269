import isDST from '@common/helpers/timeHelper/isDST';

/**
 * convertGermanTimeToUTC0
 * Function takes German timestamp UTC+2 (summer) or UTC+1 (winter) depends on DST and convert to UTC0
 *
 * @param {number} timestamp
 * @returns {number} timestamp
 */
export default (timestamp: number): number => {
  return isDST() ? timestamp - 2000 * 60 * 60 : timestamp - 1000 * 60 * 60;
};

import React, { useState, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';

import services from '@features/core/services';

import {
  resetSearchResult,
  setSearchStatus,
} from '@common/providers/search/useSearch';
import useOnClickOutside from '@common/hooks/useOnClickOutside';

import { SearchInput } from '@ui/components/searchInput';

import * as S from './Search.styled';
import { ISearch } from './Search.types';

const MIN_SEARCH_LENGTH = 2;

const Search: React.FC<ISearch> = ({
  value,
  isHeader,
  setValue,
  searchResultRef,
  onSearch,
  title,
}) => {
  const { t } = useTranslation();
  const [prevValue, setPrevValue] = useState('');
  const inputRef = useRef<HTMLDivElement>(null);
  const isDesktop = services.isDesktopView;

  const handleChange = (event): void => {
    setPrevValue(value);
    setValue(event.target.value.replaceAll('"', ''));
  };

  const checkRestrictedChar = (charCode: number): boolean => {
    // charCode === 34 is " symbol, needed to prevent graphql error
    return charCode === 34;
  };

  const cancelSearch = (): void => {
    setPrevValue(value);
    setValue('');
  };

  const onOutsideClick = (e): void => {
    if (
      searchResultRef.current?.contains(e.target) ||
      inputRef.current?.contains(e.target)
    ) {
      return;
    }
    cancelSearch();
    resetSearchResult();
    setSearchStatus(false);
  };

  useOnClickOutside(inputRef, onOutsideClick);

  useEffect(() => {
    if (value.length >= MIN_SEARCH_LENGTH) {
      onSearch(value);
      setSearchStatus(true);
    }

    if (prevValue.length > value.length && value.length < MIN_SEARCH_LENGTH) {
      resetSearchResult();
      setSearchStatus(false);
    }
  }, [value]);

  useEffect(() => {
    return () => {
      document.removeEventListener('click', onOutsideClick, false);
      resetSearchResult();
    };
  }, []);

  return (
    <S.SearchContainer isHeader={isHeader}>
      {!isHeader && !isDesktop && <S.Title>{t(title)}</S.Title>}

      <SearchInput
        inputRef={inputRef}
        value={value}
        onChange={handleChange}
        onCancel={cancelSearch}
        checkRestrictedChar={checkRestrictedChar}
      />
    </S.SearchContainer>
  );
};

export default Search;

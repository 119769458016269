export interface IOddsComparisonData {
  event_id: string;
  type: string;
  fav: string;
  swde: string;
  Tipico: string;
  HpyBet?: string;
  CashPoint?: string;
  Bet3000?: string;
  eventdate: string;
  cats: string;
  isTopCat: string;
  label: string;
  event_code: string;
  category_id: string;
  mid: string;
  pid: string;
}

export interface IOddsComparisonSectionItem {
  title: string;
  subtitle?: string;
  odds: string[];
  sectionItemType?: keyof typeof ItemType;
  isFullSection: boolean;
  eventDate?: string;
}

export interface IOddsComparisonSection {
  sectionEvents: IOddsComparisonData[];
  sectionIndex: number;
  isSidebarView?: boolean;
}

export enum ItemType {
  header = 'header',
  winChance = 'winChance',
  default = 'default',
}

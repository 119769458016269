import React from 'react';
import { useTranslation } from 'react-i18next';

import { closeSidebars } from '@common/providers/application/useAppState';

import { StyledButton } from '@ui/components/buttons';
import { ButtonType } from '@ui/components/buttons/Button.styled';

import { OddsComparisonSection } from '../oddsComparisonSection';

import * as S from './OddsComparisonSliderSection.styled';
import { IOddsComparisonSlider } from './OddsComparisonSliderSection.types';

const OddsComparisonSliderSection: React.FC<IOddsComparisonSlider> = ({
  sectionEvents,
  sectionIndex,
  currentSections,
}): JSX.Element => {
  const { t } = useTranslation();

  return (
    <S.OddsCompSectionSliderWrapper>
      <OddsComparisonSection
        sectionIndex={sectionIndex}
        sectionEvents={sectionEvents}
        isSidebarView={currentSections}
      />
      {currentSections && (
        <StyledButton
          onClick={closeSidebars}
          label="oddsComparison.close"
          buttonType={ButtonType.LightGhost}
          className="odds-comparison-close-btn"
        />
      )}
      <S.DescriptionLabel>
        {t('oddsComparison.description_label')}
      </S.DescriptionLabel>
      <S.DescriptionBlock>
        <S.StarSection>*</S.StarSection>
        <S.DescriptionText>
          {t('oddsComparison.description_text')}
        </S.DescriptionText>
      </S.DescriptionBlock>
    </S.OddsCompSectionSliderWrapper>
  );
};

export default OddsComparisonSliderSection;

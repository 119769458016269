import services from '@features/core/services';
import client from '@features/core/graphqlClient';
import CustomError, {
  convertResponseToError,
} from '@features/core/error/error';

import {
  ICustomError,
  IResponseError,
  ISearchCategories,
  ISearchCategoriesResponse,
} from '@common/interfaces';

export default async ({
  lang = services.domainLang,
  label,
}): Promise<{ id: string; label: string; top_category_id: string }[]> => {
  try {
    const response = (await client.request({
      query: `
{
  searchCategories(lang: "${lang}", label: "${label}") {
    id
    label
    top_category_id
  }
}
`,
    })) as ISearchCategoriesResponse;

    return response.data.searchCategories;
  } catch (error) {
    const customAxiosError = error as IResponseError;
    return new CustomError({
      message: convertResponseToError(customAxiosError.response?.data),
      data: customAxiosError.response?.data,
    }) as ISearchCategories & ICustomError;
  }
};

import styled from 'styled-components';

import { Icon } from '@ui/components/icon';

export const categorySwitcherHeight = 85;

export const Root = styled.div`
  display: grid;
  grid-auto-flow: column;
  align-content: center;
  background-color: ${({ theme }) => theme.secondary};
  overflow: auto hidden;
  grid-auto-columns: min-content;
  ${({ theme }) => !theme.isDesktop && 'z-index: 5; position: relative;'}
  height: ${categorySwitcherHeight}px
`;

export const Item = styled.div``;

export const ItemButton = styled.button`
  display: grid;
  grid-auto-flow: row;
  min-width: 70px;
  justify-items: center;
  align-items: center;
  padding: ${({ theme }) => theme.space_10};
  color: ${({ theme }) => theme.textMain};

  &.selected {
    color: ${({ theme }) => theme.accent};
  }
`;

export const ItemIcon = styled(Icon)``;

export const ItemTitle = styled.span<{
  active?: boolean;
}>`
  display: inline-block;
  margin-top: ${({ theme }) => theme.space_10};
  white-space: nowrap;
  font-size: ${({ theme }) => theme.fontMD};
  font-weight: ${({ active }) => (active ? '700' : '400')};
`;

export const EventCount = styled.div`
  min-height: 10px;
  width: 100%;
  font-size: ${props => props.theme.fontXS};
  text-align: right;
  color: ${({ theme }) => theme.buttonMainDisabledTextColor};
`;

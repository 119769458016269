import { create } from 'zustand';

import defaultState from '@common/providers/casino/state';
import { ICasinoState, ISportsSummary } from '@common/providers/casino/types';
import { ICustomError, IGameData } from '@common/interfaces';

export const useCasinoState = create<ICasinoState>(() => defaultState);

export const getSummary = (type): void => {
  useCasinoState.setState(state => ({
    [type]: {
      ...state[type],
      loading: true,
    },
  }));
};

export const setSummary = (type: string, data: ISportsSummary): void => {
  useCasinoState.setState(state => ({
    [type]: {
      ...state[type],
      loading: false,
      data,
    },
  }));
};

export const resetSummary = (type: string): void => {
  useCasinoState.setState(state => ({
    [type]: {
      ...state[type],
      loading: true,
      data: {} as ISportsSummary,
    },
  }));
};

export const getCasinoGames = (): void => {
  useCasinoState.setState(state => ({
    casino_games: {
      ...state.casino_games,
      loading: true,
    },
  }));
};

export const setCasinoGames = (data: IGameData): void => {
  useCasinoState.setState(state => ({
    casino_games: {
      ...state.casino_games,
      loading: false,
      data,
    },
  }));
};

export const setCasinoGamesError = (error: ICustomError): void => {
  useCasinoState.setState(state => ({
    casino_games: {
      ...state.casino_games,
      loading: false,
      error,
    },
  }));
};

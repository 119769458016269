import services from '@features/core/services';
import CustomError from '@features/core/error/error';

import fetchGames from '@common/api/casino/fetchGames';
import { ICustomError } from '@common/interfaces';
import { IFetchSportsSummary } from '@common/providers/casino/types';
import { MethodType } from '@common/api/apiHandler';
import apiRpcRequest from '@common/api/common/apiCommonRequest';
import {
  getCasinoGames,
  setCasinoGames,
  setCasinoGamesError,
  setSummary,
} from '@common/providers/casino/useCasino';

export const getSummaryAction = async (type: string): Promise<void> => {
  const params = {
    method: `user.${type}`,
  };

  const response = (await apiRpcRequest({
    method: MethodType.Get,
    params,
  })) as IFetchSportsSummary;
  if (response instanceof CustomError) {
    services.logger.error('fetchSummary', response.message);
  } else {
    setSummary(type, { result: { ...response.result } });
  }
};

export const getGames = async (): Promise<void> => {
  getCasinoGames();
  try {
    const data = await fetchGames();
    setCasinoGames(data);
  } catch (error) {
    setCasinoGamesError(error as ICustomError<unknown>);
  }
};

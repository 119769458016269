import React from 'react';

import { ICommonScoreProps } from '@features/events/components/scoreboards/factoryScoreboards';
import { ScoreboardHoc } from '@features/events/components/scoreboards/scores';
import DefaultScoreFactory from '@features/events/components/scoreboards/scores/common/defaultScoreFactory/DefaultScoreFactory';

import {
  getPeriod,
  getPoints,
  hasPoints,
  getLastPeriod,
  getTotalScore,
} from '@common/helpers/eventsHelper/scoreboards/scoreBoardModel';
import { IScore } from '@common/interfaces';
import { parseEndedScore } from '@common/helpers/eventsHelper/eventLabelHelper';

const DefaultScoreSet = (
  props: ICommonScoreProps,
): React.ReactElement | null => {
  const { score, detailView, sportName } = props;
  const lastPeriod = getLastPeriod(score, getPeriod, 5);
  const getTotal = score.match_score ? getTotalScore : parseEndedScore;
  const getPeriod2 = detailView // show all sets in detail view and last one otherwise
    ? getPeriod
    : (s: IScore): string | (number | null)[] => getPeriod(s, lastPeriod);
  const getOT = hasPoints(score) ? getPoints : undefined; // do not show 0,0 points column

  return (
    <DefaultScoreFactory
      score={score}
      lastPeriod={detailView ? lastPeriod : 1}
      periodsCount={lastPeriod}
      className={`score ${sportName}`}
      getTotal={getTotal}
      getPeriod={getPeriod2}
      getOT={getOT}
    />
  );
};

export default ScoreboardHoc(DefaultScoreSet);

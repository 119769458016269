import styled from 'styled-components';

export const OddsComparisonLayerWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-items: center;
  width: 100%;
  justify-content: center;
  height: 100%;
`;

export const SideArrowWrapper = styled.div<{ isLeft?: boolean }>`
  position: absolute;
  z-index: 10;
  display: grid;
  justify-content: center;
  align-items: center;
  background-color: ${({ theme }) => theme.highlightWrapperBackground};
  opacity: 0.65;
  border-radius: 3px;
  width: 36px;
  height: 36px;
  cursor: pointer;

  ${({ isLeft }) =>
    isLeft ? 'left: calc(50vw - 260px)' : 'right: calc(50vw - 260px)'};

  &:hover {
    opacity: 1;
  }
`;

export const OddsComparisonSliderWrapper = styled.div`
  display: grid;
  position: relative;
  width: 570px;
  height: 100%;
  justify-items: center;
  align-items: center;
`;

export const OddsCompLayoutSectionWrapper = styled.div<{
  isLeft?: boolean;
  isActive?: boolean;
}>`
  position: absolute;
`;

import filter from 'lodash/filter';
import { isWithinInterval } from 'date-fns';
import endOfDay from 'date-fns/endOfDay';
import startOfDay from 'date-fns/startOfDay';

import { ITransaction } from '@common/interfaces';

const filterTransactionsByDate = (
  transactions: ITransaction[],
  dateMin: string,
  dateMax: string,
): ITransaction[] =>
  filter(transactions, it =>
    isWithinInterval(it.localeZoneDate, {
      start: startOfDay(new Date(dateMin)),
      end: endOfDay(new Date(dateMax)),
    }),
  );

export default filterTransactionsByDate;

import React from 'react';
import { useTheme } from 'styled-components';

import { ValidateStatus } from '@features/core/form/interfaces/validateStatus';

import * as S from './inputStateIcon.styled';
import IInputStateIcon from './inputStateIcon.types';

const InputStateIcon = (props: IInputStateIcon): JSX.Element => {
  const { state } = props;
  const { statusSuccess, statusError } = useTheme();

  const name =
    state === ValidateStatus.success ? 'check-circle' : 'info-circle';
  const fill = state === ValidateStatus.success ? statusSuccess : statusError;

  return (
    <S.StateIcon
      className="state-icon"
      name={name}
      fill={fill}
      width="20"
      height="20"
    />
  );
};

export default InputStateIcon;

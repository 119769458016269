import services from '@features/core/services';
import CustomError from '@features/core/error/error';

import { BetEvents } from '@packages/events/appEvents';

import { ICashoutRequest, IQueudCashout } from '@common/interfaces';
import { IPostCashoutPayload } from '@common/providers/cashout/types';
import { MethodType } from '@common/api/apiHandler';
import apiRpcRequest from '@common/api/common/apiCommonRequest';

type apiResponse = IPostCashoutPayload & CustomError;

export const postCashout = async (
  payload: ICashoutRequest | IQueudCashout,
): Promise<IPostCashoutPayload & CustomError> => {
  const body =
    !('queued' in payload) || !payload.queued
      ? {
          id: 1,
          method: 'cashout.cashout',
          params: payload,
        }
      : {
          id: 1,
          method: 'cashout.get_queue_status',
          params: payload,
        };

  /* if (payload.queued) {
    console.log('queued true');
    return {};
  }
  return {
    result: {
      cashout_detail: {
        cashout_id: "321321332"
      },
      detail: {},
      is_accepted: 0,
      is_queued: 1,
      is_rejected: 0,
      queue_delay: 20
    }
  }; */

  const response = (await apiRpcRequest({
    method: MethodType.Post,
    data: body,
  })) as IPostCashoutPayload;

  if (response instanceof CustomError) {
    return response;
  }

  if (
    response.result?.is_accepted &&
    !parseFloat(<string>response.result?.cashout_detail?.remaining_stake)
  ) {
    services.events.emitEvent(BetEvents.REMOVE_BET, payload.bet_id);
  }
  if (response.result?.is_accepted) {
    services.events.emitEvent(BetEvents.PLACE_CASHOUT, payload.bet_id);
  }
  return response as apiResponse;
};

export default postCashout;

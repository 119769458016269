import { GraphQLClient } from 'graphql-hooks';
import 'cross-fetch/polyfill';

import services from '@features/core/services';

import { GRAPH_URL } from '@common/constants/config';

const client = new GraphQLClient({
  url: services.config.get(GRAPH_URL) as string,
});

export default client;

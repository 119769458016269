import { MethodType } from '@common/api/apiHandler';
import apiRpcRequest from '@common/api/common/apiCommonRequest';
import {
  IGetNewPasswordPayload,
  IGetNewPasswordApiResponse,
} from '@common/providers/account/types';

const fetchRecoverPassword = (
  payload: IGetNewPasswordPayload,
): IGetNewPasswordApiResponse => {
  const { recovery_token, password, password2 } = payload;

  const body = {
    params: {
      recovery_token,
      password,
      password2,
    },
    method: 'user.account_recovery',
  };

  return apiRpcRequest({
    method: MethodType.Post,
    data: body,
  }) as IGetNewPasswordApiResponse;
};

export default fetchRecoverPassword;

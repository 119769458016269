import React from 'react';
import includes from 'lodash/includes';

import {
  SingleIcon,
  CollectionIcon,
  IIconProps,
} from '@ui/components/icon/index';

const Icon: React.FC<IIconProps> = (props: IIconProps) => {
  const { name } = props;

  return includes(name || '', ',') ? (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <CollectionIcon {...props} />
  ) : (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <SingleIcon {...props} />
  );
};

Icon.defaultProps = {
  width: '20',
  height: '20',
  fill: 'currentColor',
};

export default Icon;

import styled from 'styled-components';

import { getHeaderHeight } from '@features/app/layout/header';

const getHeight = (topNotifHeight, isFooter, switchAccountLoading) => {
  const footerHeight = isFooter ? 68 : 0;
  return `calc(100vh - ${
    switchAccountLoading ? '0' : topNotifHeight
  }px - ${footerHeight}px - ${getHeaderHeight()}px)`;
};

export const Container = styled.div<{
  isTopNotification: number;
  isFooter: boolean;
  switchAccountLoading?: boolean;
}>`
  width: 100%;
  position: relative;
  background-color: ${({ theme }) => (theme.isDesktop ? '' : theme.secondary)};
  opacity: 0.9;
  max-height: ${({ isTopNotification, isFooter, switchAccountLoading }) =>
    getHeight(isTopNotification, isFooter, switchAccountLoading)};
  overflow: hidden;

  & #DualRingLoader {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    margin: 0;
    grid-template-rows: 1fr min-content;
  }
`;

export const LoaderContainer = styled.div`
  width: 100%;
  height: 100%;
`;

export const Children = styled.div<{ childShouldHaveOpacity?: boolean }>`
  ${({ childShouldHaveOpacity }) =>
    childShouldHaveOpacity ? 'opacity: 0.1' : 'visibility: hidden'};
  pointer-events: none;
`;

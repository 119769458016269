import { MethodType } from '@common/api/apiHandler';
import apiRpcRequest from '@common/api/common/apiCommonRequest';
import { ICustomError } from '@common/interfaces/responses/IError';
import {
  IGetIframeBetValidationPayload,
  IIframeBetValidation,
} from '@common/providers/account/types';

/**
 * fetchIframeBetValidation
 * get iframe bet validation
 * @param player_id {String} customer nickname
 * @param affiliate_id {Number|String} affiliate id
 * @param signature {String} user signature
 * @returns {AxiosPromise<IIframeBetValidation>}
 */

type apiResponse = Promise<IIframeBetValidation & ICustomError>;

const fetchIframeBetValidation = async ({
  player_id,
  affiliate_id,
  signature,
}: IGetIframeBetValidationPayload): Promise<apiResponse> => {
  const body = {
    method: 'user.validate',
    params: {
      player_id,
      affiliate_id,
      signature,
    },
  };

  return apiRpcRequest({ method: MethodType.Post, data: body }) as apiResponse;
};

export default fetchIframeBetValidation;

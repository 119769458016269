import styled from 'styled-components';

import CustomLink from '@features/core/routing/CustomLink';
import { TextLarge } from '@features/core/styles/text.styled';

export const Root = styled.div`
  display: grid;
  grid-auto-flow: row;
`;

export const StyledCustomLink = styled(CustomLink)`
  padding: ${props => props.theme.space_10} 0 ${props => props.theme.space_10}
    ${props => props.theme.space_10};
  display: grid;
  grid-auto-flow: column;
  align-items: center;
  text-decoration: none;
  background-color: ${props => props.theme.textActive};

  &.active-item {
    font-weight: ${({ theme }) => theme.fontBold};
  }

  &.active-item p,
  &.active-item div {
    font-weight: ${({ theme }) => theme.fontBold};
  }
`;

export const ItemLabel = styled(TextLarge)`
  margin-left: ${({ theme }) => theme.space_40};
  color: ${props => props.theme.textPrimary};
  text-transform: uppercase;
`;

export const ScannerIcon = styled.img`
  width: 20px;
`;

const getScannerWrapperLoggedStyles = (isLogged, theme) => {
  return isLogged ? `0 0 ${theme.space_5} 0` : `${theme.space_5} 0`;
};

export const ScannerWrapper = styled(TextLarge)<{ isLoggedUser: boolean }>`
  display: flex;
  background: ${({ theme }) => theme.tertiaryBackground};
  padding: ${({ theme }) => theme.space_15} ${({ theme }) => theme.space_15}
    ${({ theme }) => theme.space_15} ${({ theme }) => theme.space_20};
  color: ${({ theme }) => theme.textMain};
  gap: ${({ theme }) => theme.space_10};
  align-items: center;
  text-transform: uppercase;
  margin: ${({ isLoggedUser, theme }) =>
    getScannerWrapperLoggedStyles(isLoggedUser, theme)};
`;

export const EmptySpace = styled.div`
  height: 45px;
  background: ${({ theme }) => theme.tertiaryBackground};
`;

import { FormInstance, Rule } from 'rc-field-form/lib/interface';

import i18next from '@features/core/translation';

const samePasswordPreset = (form: FormInstance): Rule[] => [
  {
    required: true,
    validator: async (rule, value): Promise<void> => {
      if (form.getFieldValue('password') !== value) {
        throw new Error(i18next.t('registration.step2.same_password'));
      }
    },
  },
];

export default samePasswordPreset;

import styled from 'styled-components';

import { TextNormal } from '@features/core/styles/text.styled';

// eslint-disable-next-line import/prefer-default-export
export const Scoreboard = styled(TextNormal)`
  margin-left: ${({ theme }) => theme.space_10};
  display: grid;
  grid-auto-flow: row;
  padding: ${({ theme }) => theme.space_5} ${({ theme }) => theme.space_10};
  color: ${props => props.theme.textMain};
  font-weight: ${({ theme }) => theme.fontBold};
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;

  span:first-child {
    margin-bottom: ${({ theme }) => theme.space_5};
  }
`;

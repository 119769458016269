import React, { MouseEvent } from 'react';
import { useHistory } from 'react-router-dom';
import reduce from 'lodash/reduce';
import filter from 'lodash/filter';

import { PageName } from '@features/core/routing';
import { InboxMessageCount } from '@features/inbox/inboxMessageCount';

import { MENU_PANEL } from '@common/constants/dialogs';
import {
  ANIMATED_MENU,
  ANIMATED_MENU_PANEL,
} from '@common/helpers/appLayoutHelper';
import isMainUserId from '@common/helpers/userHelper/isMainUserId';
import {
  closeSidebar,
  toggleAnimatedComponent,
} from '@common/providers/application/useAppState';
import { scrollTopWindow } from '@common/helpers/scrollHelper';

import * as S from './UserLinks.styled';
import { IUserLinks } from './UserLinks.types';

const UserLinks: React.FC<IUserLinks> = ({ links }) => {
  const { replace, location } = useHistory();

  const getLinks = (): IUserLinks['links'] => {
    if (!isMainUserId()) {
      return filter(
        links,
        ({ location: linkLocation }) =>
          linkLocation !== PageName.USER_CHANGE_PASSWORD,
      );
    }

    return links;
  };

  const handleClickLogout = (isLogout: boolean): void => {
    if (isLogout) {
      replace({
        pathname: location.pathname,
      });
    }
  };

  return (
    <>
      <S.Root onClick={(e: MouseEvent): void => e.stopPropagation()}>
        {reduce(
          getLinks(),
          (acc, item) =>
            acc.concat(
              <S.StyledCustomLink
                href={item.location}
                onClick={(event: MouseEvent): void => {
                  toggleAnimatedComponent(ANIMATED_MENU_PANEL);
                  setTimeout(() => {
                    closeSidebar(MENU_PANEL);
                    toggleAnimatedComponent(ANIMATED_MENU);
                    scrollTopWindow();
                    if (item.onClick) {
                      event.preventDefault();
                      item.onClick();
                    }
                  }, 300);
                }}
                isSoftCompare={
                  item.key === 'deposit' || item.key === 'withdrals'
                }
                key={item.key}
                activeClassName="active-item"
                dataQa={`menu-btn-${item.key}`}
              >
                <S.ItemLabel
                  onClick={(): void => handleClickLogout(item.key === 'logout')}
                  className={`user-section-link${
                    item.key === 'logout' ? ' logout' : ''
                  }`}
                >
                  {item.title}
                </S.ItemLabel>
                {item.key === 'my-inbox' && (
                  <InboxMessageCount pageType="user-menu" />
                )}
              </S.StyledCustomLink>,
            ),
          [] as JSX.Element[],
        )}
      </S.Root>
    </>
  );
};

export default UserLinks;

import includes from 'lodash/includes';
import toLower from 'lodash/toLower';
import endsWith from 'lodash/endsWith';
import find from 'lodash/find';
import filter from 'lodash/filter';

import {
  ICategory,
  IEvent,
  IMarketType,
  MarketType,
  ILiveSportCategoriesTreeData,
  LiveStatus,
} from '@common/interfaces';
import { useBettingSlip } from '@common/providers/bettingslip/useBettingSlip';
import { ISelection } from '@common/interfaces/prediction/IPrediction';

import { isRealFootball } from './eventTypeHelper';
import { isPenalty } from './scoreboards/timerModel';

/**
 * getLiveStatus
 *
 * @param {IEvent} event
 * @returns {string} event status
 */
export const getLiveStatus = (event?: IEvent): string => {
  return event ? event.live_status : '';
};

/**
 * isLiveDisabled
 * check event status
 *
 * @param {IEvent} event
 * @returns {boolean} isLiveDisabled
 */
export const isLiveDisabled = (event?: IEvent): boolean => {
  return getLiveStatus(event) === LiveStatus.disabled;
};

/**
 * isLive
 * check event status
 *
 * @param {IEvent} event
 * @returns {boolean} isLive
 */
export const isLive = (event: IEvent): boolean => {
  return !isLiveDisabled(event);
};
/**
 * isLiveFuture
 * check event status
 *
 * @param {IEvent} event
 * @returns {boolean} isLiveFuture
 */
export const isLiveFuture = (event?: IEvent): boolean => {
  return getLiveStatus(event) === LiveStatus.future;
};

/**
 * isLiveCurrent
 *
 * @param {IEvent} event
 * @returns {boolean} isLiveCurrent
 */
export const isLiveCurrent = (event: IEvent): boolean => {
  return !isLiveDisabled(event) && !isLiveFuture(event);
};

export const isOvertime = (event: IEvent): boolean => {
  return endsWith(event.timer?.period_id, 'OT');
};

export const isWinner = (event: IEvent): string | boolean | undefined => {
  //  if we have soccer event and it does not have second team it's  a winner event
  return (
    (event && !includes(event.label, ' - ')) ||
    (event && /winner|top \d|grand prix/.test(toLower(event.label_en)))
  );
};

export const isEndedEvent = (event: IEvent): boolean => {
  return !!event?.endedEvent || event.timer?.period_id === 'FINISHED';
};

/**
 * isLiveOrSoon
 * check if event is live or starts in 15 min
 *
 * @param {IEvent} event
 * @returns {boolean} isLiveOrSoon
 */
export const isLiveOrSoon = (event: IEvent): boolean => {
  const tsNow = new Date().getTime();
  const startDate = parseInt(String(event.expires_ts), 10) * 1000;
  const soonOffset = 15 * 60 * 1000; // 15 min
  const isLiveOrSoonByStatus =
    event.live_status === LiveStatus.open ||
    event.live_status === LiveStatus.suspended;
  return (
    !isLiveDisabled(event) &&
    (tsNow >= startDate - soonOffset || isLiveOrSoonByStatus)
  );
};

export const isEndedWithLmt = (event: IEvent): string | boolean | undefined => {
  return event.endedEvent && event.betradar_id;
};

/**
 * isLongTerm
 *
 * @param {ILiveSportCategoriesTreeData | ICategory} categorie
 * @returns {boolean} isLongTerm
 */
export const isLongTerm = (
  categorie: ILiveSportCategoriesTreeData | ICategory,
): boolean => {
  return (
    includes(categorie?.path, 'Langzeitwetten') ||
    includes(categorie?.path, 'Outrights')
  );
};

export const isPowerkombi = (categoryPath: string): boolean => {
  return (
    includes(categoryPath, 'Powerkombi') || includes(categoryPath, 'Powercombi')
  );
};

export const isQuotenboost = (eventLabel: string): boolean =>
  includes(eventLabel, 'Boost') &&
  toLower(useBettingSlip.getState().user?.username) !== 'nilz';

/**
 * hasQuotenboostSelections
 * checks if bettingslip selections include QuotenBoost events
 *
 * @param {ISelection[]} selections
 * @returns {boolean} hasQuotenboostSelections
 */
export const hasQuotenboostSelections = (selections: ISelection[]): boolean => {
  return !!find(selections, selection => isQuotenboost(selection.event?.label));
};

/**
 * calculateQuotenboostSelections
 * number of bettingslip selections that include QuotenBoost events
 *
 * @param {ISelection[]} selections
 * @returns {number} calculateQuotenboostSelections
 */
export const calculateQuotenboostSelections = (
  selections: ISelection[],
): number => filter(selections, sel => isQuotenboost(sel?.event?.label)).length;

/**
 * hasHalfTimeMarkets
 * checks if event has halftime markets
 *
 * @param {IEvent} event
 * @param {string} top_category_id
 * @returns {boolean} hasHalfTimeMarkets
 */
export const hasHalfTimeMarkets = (
  event: IEvent,
  top_category_id: string,
): boolean => {
  const period_idx = event.timer?.period_idx || 0;

  // we show first haftime only for soccer for not started and 1st period
  const isOnlyFirstHTShow = (): boolean => {
    return period_idx <= 1 && isRealFootball(event, top_category_id);
  };

  return isLiveOrSoon(event) && !isOvertime(event) && isOnlyFirstHTShow();
};
/**
 * getMarketType
 * getMarketType checks if market is overtime or penalty and returns the marketType for events
 *
 * @param {IEvent} event {Object}
 * @param {string} marketType
 * @returns {string} marketType
 */
export const getMarketType = (
  event: IEvent,
  marketType: IMarketType,
): IMarketType => {
  if (marketType === MarketType['N-WAY'] && event && event.timer) {
    if (isOvertime(event)) {
      return MarketType['3WAY'];
    }
    if (isPenalty(event.timer)) {
      return MarketType.PENALTY_SHOOTOUT;
    }
  }
  return marketType;
};

import { ITransaction } from '@common/interfaces';

import { ITransactionsState } from './types';

const defaultState: ITransactionsState = {
  data: [] as ITransaction[],
  totalPages: 0,
  currentPage: 0,
  loading: false,
  type: 'all',
  offset: 0,
  max_age_days: 90,
  selectedTransactionId: '',
};

export default defaultState;

import { createWithEqualityFn } from 'zustand/traditional';
import { shallow } from 'zustand/shallow';

import { getMockShopMoneyService } from '@features/payments/components/paymentForm/mock/mockData';

import {
  IPaymentProviderState,
  IPaymentStateTypes,
  IRequestPaymentSuccess,
} from '@common/providers/payments/types';
import defaultState from '@common/providers/payments/state';
import {
  IAccount,
  IPaymentOperation,
  IPaymentService,
} from '@common/interfaces';
import { setManualLimits } from '@common/helpers/paymentsHelper/paymentModel';
import {
  hasShopWallets,
  hasShopEMoneyWallets,
} from '@common/helpers/paymentsHelper/walletsHelper';

export const usePaymentsState = createWithEqualityFn<IPaymentProviderState>(
  () => defaultState,
  shallow,
);

export const setPaymentLoading = (): void => {
  usePaymentsState.setState(() => ({
    loading: true,
  }));
};

export const setPaymentAccountSuccess = (account: IAccount): void => {
  usePaymentsState.setState(() => ({
    loading: false,
    error: null,
    account,
  }));
};

export const setPaymentError = (
  error: string,
  definedState?: keyof typeof IPaymentStateTypes,
): void => {
  usePaymentsState.setState(() => ({
    loading: false,
    error,
    ...(definedState ? { [definedState]: defaultState[definedState] } : {}),
  }));
};

export const setPaymentServicesSuccess = (
  services: IPaymentService[],
  operation,
): void => {
  // todo temporary
  if (
    (hasShopWallets() && operation === IPaymentOperation.DEPOSIT) ||
    (hasShopEMoneyWallets() && operation === IPaymentOperation.WITHDRAWAL)
  ) {
    services.push((getMockShopMoneyService() as unknown) as IPaymentService);
  }

  usePaymentsState.setState(() => ({
    loading: false,
    error: null,
    services: setManualLimits(services),
  }));
};

export const requestPaymentSuccess = ({
  data,
  operation,
}: IRequestPaymentSuccess): void => {
  usePaymentsState.setState(state => {
    return {
      ...state,
      loading: false,
      error: null,
      [operation]: data,
    };
  });
};

export const resetDefinedPaymentsState = (
  definedState: keyof typeof IPaymentStateTypes,
): void => {
  usePaymentsState.setState(() => ({
    loading: false,
    error: null,
    [definedState]: defaultState[definedState],
  }));
};

export const togglePaymentInfoPopup = (hasPaymentInfoPopup: boolean): void => {
  usePaymentsState.setState(() => ({
    hasPaymentInfoPopup,
  }));
};

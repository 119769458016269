import styled from 'styled-components';

import { TextXLarge } from '@features/core/styles/text.styled';

const rootExtraStyles = theme =>
  `
  display: grid; 
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr; 
  grid-template-rows: 48px 48px; 
  gap: ${theme.space_1} ${theme.space_1};
  grid-template-areas: 
    ". . . . . ."
    ". . . . . ."; 
  padding: ${theme.space_5} 0;
  background-color: ${theme.appBg};
`;

export const Root = styled(TextXLarge)<{ hasPaymentInfoPopup: boolean }>`
  width: 100%;
  color: ${props => props.theme.textPrimary};
  background-color: ${props => props.theme.secondary};
  position: relative;

  &::before,
  &::after {
    content: '';
    position: absolute;
    left: 0;
    right: 0;
    height: 3px;
    background: ${({ theme, hasPaymentInfoPopup }) =>
      !theme.isDesktop && !hasPaymentInfoPopup && theme.primaryBackground};
  }

  &::before {
    top: 0;
  }

  &::after {
    bottom: 0;
  }

  ${({ theme }) => rootExtraStyles(theme)}
`;

export const Button = styled.button`
  display: grid;
  justify-content: center;
  align-items: center;
  height: 48px;
  cursor: pointer;
  width: 100%;
  background-color: ${props => props.theme.primaryBackground};

  &.inverted {
    background-color: ${props => props.theme.main};
  }
`;

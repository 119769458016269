import React from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import { useTheme } from 'styled-components';

import { isDesktopView } from '@common/helpers/deviceUtil';
import { isAccountPages } from '@common/helpers/links';

import * as S from './DualRingLoader.styled';
import IDualRingLoaderProps from './DualRingLoader.types';

const isDesktop = isDesktopView();

const DualRingLoader = ({
  backgroundColor = '',
  isLight = false,
}: IDualRingLoaderProps): React.ReactElement => {
  const { t } = useTranslation();
  const { pathname } = useLocation();
  const theme: {
    loaderSecondaryBackground: string;
    loaderBackground: string;
  } = useTheme() as {
    loaderSecondaryBackground: string;
    loaderBackground: string;
  };
  const isUserPages = isAccountPages(pathname);
  const background = (): string => {
    if (isDesktop && isUserPages) {
      return theme.loaderBackground;
    }

    if (isDesktop && isLight) {
      return theme.loaderSecondaryBackground;
    }
    return '';
  };

  return (
    <S.Wrapper id="DualRingLoader">
      <S.LoaderContainer>
        <S.Loader>
          <div className="bracket" />
          <div className="bracketSecond" />
          <S.OverlayOne backgroundColor={backgroundColor || background()} />
          <S.OverlayTwo backgroundColor={backgroundColor || background()} />
        </S.Loader>
        <S.Text>{t('common.labels.loader_text')}</S.Text>
      </S.LoaderContainer>
      <S.Shadow />
    </S.Wrapper>
  );
};

export default DualRingLoader;

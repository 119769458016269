import React, { useEffect, useRef, useState } from 'react';
import forEach from 'lodash/forEach';

interface ElementSize {
  width: number;
  height: number;
}

const useElementSize = (): {
  elementRef: React.MutableRefObject<HTMLDivElement | null>;
  elementSize: ElementSize;
} => {
  const [elementSize, setElementSize] = useState<ElementSize>({
    width: 0,
    height: 0,
  });
  const elementRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    const resizeObserver = new ResizeObserver(entries => {
      requestAnimationFrame(() => {
        forEach(entries, entry => {
          const { width, height } = entry.contentRect;
          setElementSize({ width, height });
        });
      });
    });

    if (elementRef.current) {
      resizeObserver.observe(elementRef.current);
    }

    return () => {
      resizeObserver.disconnect();
    };
  }, []);

  return { elementRef, elementSize };
};

export default useElementSize;

import React from 'react';

import { GenericNotification } from '@ui/components/genericNotification';
import { INotificationStyle } from '@ui/components/genericNotification/GenericNotification.types';

import { IMessage } from './NoFavoriteView.types';
import * as S from './NoFavoriteView.styled';

const NoFavoriteView = ({ message }: IMessage): JSX.Element => (
  <S.NoFavoriteTextWrapper>
    <GenericNotification styleType={INotificationStyle.noFavoriteNotification}>
      <S.NoFavoriteText
        dangerouslySetInnerHTML={{
          __html: message,
        }}
      />
    </GenericNotification>
  </S.NoFavoriteTextWrapper>
);

export default NoFavoriteView;

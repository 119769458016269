import React from 'react';

import * as S from './SectionTitle.styled';
import { ISectionTitleProps } from './SectionTitle.types';

const SectionTitle: React.FC<ISectionTitleProps> = props => {
  const {
    iconName,
    iconViewBox,
    text,
    children,
    isLight,
    styles,
    isAccountSummary,
  } = props;
  return (
    <S.SubtitleWrapper
      style={styles}
      isLight={isLight}
      isAccountSummary={isAccountSummary}
    >
      {iconName && (
        <S.IconWrapper>
          <S.IconStyled
            width="12px"
            height="12px"
            viewBox={iconViewBox}
            name={iconName}
          />
        </S.IconWrapper>
      )}
      <S.LabelBlock>{text}</S.LabelBlock>
      {children}
    </S.SubtitleWrapper>
  );
};

export default SectionTitle;

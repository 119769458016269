import React from 'react';
import map from 'lodash/map';
import keys from 'lodash/keys';
import reduce from 'lodash/reduce';
import { useTranslation } from 'react-i18next';

import { IOddsComparisonData, ItemType } from '@common/interfaces';
import {
  getWinChance,
  getItemLabel,
  getCompletedOddsProviders,
} from '@common/helpers/oddsComparisonHelper/oddsComparisonHelper';

import { OddsComparisonSectionItem } from '../oddsComparisonSectionItem';

import OddsComparisonWinChance from './OddsComparisonItemList.styled';

const RenderOddsComparsionItemList = (
  eventList: IOddsComparisonData[],
  isFullSection: boolean,
): JSX.Element[] => {
  const { t } = useTranslation();
  const availableOdds = getCompletedOddsProviders();

  return reduce(
    eventList,
    (acc, elem, index) => {
      const odds = map(availableOdds, provider => elem[provider]);
      const favTeam = getItemLabel(elem.label, elem.type);

      if (!isFullSection && index === 0) {
        acc.push(
          <OddsComparisonWinChance
            key={index}
            sectionItemType={ItemType.winChance}
            title={t('oddsComparison.win_chance')}
            odds={getWinChance(eventList)}
            isFullSection={isFullSection}
          />,
        );
      }
      acc.push(
        <OddsComparisonSectionItem
          key={elem.event_id}
          title={favTeam}
          subtitle={elem.label}
          odds={odds}
          eventDate={elem.eventdate}
          isFullSection={isFullSection}
        />,
      );
      if (isFullSection && index === keys(eventList).length - 1) {
        acc.push(
          <OddsComparisonWinChance
            key={index}
            sectionItemType={ItemType.winChance}
            title={t('oddsComparison.win_chance')}
            odds={getWinChance(eventList)}
            isFullSection={isFullSection}
          />,
        );
      }
      return acc;
    },
    [] as JSX.Element[],
  );
};

export default RenderOddsComparsionItemList;

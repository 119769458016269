/* eslint-disable @typescript-eslint/explicit-function-return-type */
import { create } from 'zustand';

import services from '@features/core/services';

import { CommonEvents } from '@packages/events/appEvents';

import { RETRY_MESSAGE } from '@common/constants/notifications';
import {
  WalletsActions,
  defaultState,
} from '@common/providers/payments/wallets/state';
import {
  IErrorPayload,
  IGetWalletsRequestPayload,
  IGetWalletsSuccessPayload,
  IWalletViewPayload,
  IWalletsState,
} from '@common/providers/payments/wallets/types';
import { addNotification } from '@common/providers/application/useAppState';
import { getWallets } from '@common/providers/payments/wallets/helpers';

export const useWalletsState = create<IWalletsState>(() => defaultState);

export const startGettingWallets = () => {
  useWalletsState.setState(() => ({
    loading: true,
    error: undefined,
  }));
};

export const setWalletView = (payload: IWalletViewPayload) => {
  useWalletsState.setState(() => ({
    view: payload,
  }));
};

export const setWalletsData = (payload: IGetWalletsSuccessPayload) => {
  services.events.emitEvent(CommonEvents.SET_WALLETS_DATA, { payload });
  useWalletsState.setState(() => ({
    loading: false,
    error: undefined,
    data: payload || [],
  }));
};

export const setWalletsError = (payload: IErrorPayload) => {
  useWalletsState.setState(state => {
    addNotification({
      action: WalletsActions.GET_WALLETS,
      type: RETRY_MESSAGE,
      callback: getWallets,
    });
    return { ...state, loading: false, error: payload, data: [] };
  });
};

export const startWalletsRequestLoading = () => {
  useWalletsState.setState(state => ({
    ...state,
    request: {
      ...state.request,
      loading: true,
      error: undefined,
      success: false,
    },
  }));
};

export const setWalletsRequestError = (payload: IErrorPayload) => {
  useWalletsState.setState(state => ({
    ...state,
    request: {
      ...state.request,
      loading: false,
      error: payload,
      success: false,
    },
  }));
};

export const setWalletsRequestData = (payload: IGetWalletsRequestPayload) => {
  useWalletsState.setState(state => ({
    ...state,
    request: {
      ...state.request,
      data: payload || [],
      loading: false,
      error: undefined,
      success: false,
    },
  }));
};

export const setWalletsRequestSuccess = () => {
  useWalletsState.setState(state => ({
    ...state,
    request: {
      ...state.request,
      loading: true,
      error: undefined,
      success: true,
    },
  }));
};

export const resetWalletsState = () => {
  useWalletsState.setState(defaultState);
};

import styled from 'styled-components';

export const Root = styled.div`
  position: relative;
  width: 620px;
  height: ${({ theme }) => (theme.isDesktop ? 'main-content' : '100%')};
  overflow-y: scroll;
  background-color: ${({ theme }) => theme.primaryAccentBackground};
`;

const getPaddingFormWrapStyles = ({ theme }) => {
  return theme.isDesktop
    ? `${theme.space_25} ${theme.space_40} ${theme.space_25};`
    : `${theme.space_30} ${theme.space_10} ${theme.space_10} ${theme.space_10};`;
};

export const FormWrap = styled.div`
  padding: ${getPaddingFormWrapStyles};
  color: ${({ theme }) => theme.textMain};
`;

export const ContentWrapper = styled.div`
  display: grid;
  grid-auto-flow: row;
  align-items: center;
  justify-content: center;
  padding: ${({ theme }) => theme.space_40} ${({ theme }) => theme.space_30};
  background-color: ${({ theme }) => theme.textMain};
`;

export const SupportContent = styled.p`
  margin-top: ${({ theme }) =>
    theme.isDesktop ? theme.space_40 : theme.space_15};

  & .link {
    color: ${({ theme }) => theme.textLink};
    text-decoration: underline;
    cursor: pointer;
  }
`;

export const ContentTitle = styled.p`
  width: 100%;
  text-transform: uppercase;
  color: ${({ theme }) => theme.main};
  height: 38px;
  margin: 0 ${({ theme }) => theme.space_5} ${({ theme }) => theme.space_20} 0;
  font-size: ${({ theme }) => theme.fontH1};
  font-weight: ${({ theme }) => theme.fontBold};
`;

export const ImageWrapper = styled.div`
  display: grid;
  justify-items: center;
`;

export const ImageStyled = styled.img`
  display: block;
  max-width: 70%;
  align-self: center;
`;

export const ContentAddress = styled.p`
  width: 100%;
  color: ${({ theme }) => theme.main};
  height: 24px;
  margin: ${({ theme }) => theme.space_15} 0 0;
  font-size: ${props => props.theme.fontH3};
  font-weight: ${({ theme }) => theme.fontBold};
  text-align: center;
`;

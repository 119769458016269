import formatDate from 'date-fns/format';
import isString from 'lodash/isString';
import parseISO from 'date-fns/parseISO';
import de from 'date-fns/locale/de';

import services from '@features/core/services';

const locales = {
  de,
};

/**
 *
 * @param {Date | number | string} date
 * @param {string} formatStr
 * @returns {string} formatted
 */
const format = (date: Date | string | number, formatStr: string): string => {
  try {
    return formatDate(isString(date) ? parseISO(date) : date, formatStr, {
      locale: locales[services.domainLang],
    });
  } catch (e) {
    return formatDate(new Date(), formatStr, {
      locale: locales[services.domainLang],
    });
  }
};

export default format;

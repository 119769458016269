import styled from 'styled-components';

import { SectionTitle } from '@features/payments/components/sectionTitle';
import { TextNormal } from '@features/core/styles/text.styled';

import { hexToRgba } from '@common/helpers/deviceUtil';

export const SortedList = styled.div`
  margin-top: 10px;
`;

export const SortedListTitle = styled(SectionTitle)`
  padding: ${({ theme }) => theme.space_10};
  height: 30px;
`;

export const EmptyListMessage = styled.td`
  text-align: center;
`;

// TransactionItem styles
const accountSummaryStyles = theme => `
  background-color: ${theme.highlightWrapperBackground};
  &:nth-child(even) {
    background-color: ${theme.buttonMainTextColor};
  }

`;

export const Container = styled.div<{
  isAccountSummary?: boolean;
  isEven?: boolean;
}>`
  padding: ${({ theme }) => theme.space_15} 0;
  width: 100%;
  display: grid;
  grid-auto-flow: column;
  grid-template-columns: 1fr;
  justify-content: center;
  border-bottom: ${({ theme }) => theme.border};
  background-color: ${({ theme, isEven }) =>
    isEven ? theme.elementNuanceColor : theme.textActive};

  &.active {
    border-left: 3px solid ${({ theme }) => theme.accent};
    padding-left: ${({ theme }) => theme.space_5};
    box-shadow: 1px 2px 5px 5px
      ${({ theme }) => hexToRgba(theme.textTertiary, 0.4)};
  }

  ${({ isAccountSummary, theme }) =>
    isAccountSummary && accountSummaryStyles(theme)};
`;

export const ColIcon = styled.div<{
  hasStorno?: boolean;
}>`
  position: relative;
  display: grid;
  grid-auto-flow: column;
  justify-content: center;
  align-items: center;
  width: 30px;
  font-weight: ${({ theme }) => theme.fontBold};
  ${({ hasStorno }) => hasStorno && 'text-decoration: line-through;'};
  padding-left: ${({ theme }) => theme.space_10};
`;

export const ColTitle = styled.div<{
  isAccountSummary?: boolean;
  hasStorno?: boolean;
}>`
  color: ${({ theme, isAccountSummary }) =>
    isAccountSummary ? theme.main : theme.textMain};
  white-space: normal;
  overflow: hidden;
  padding-left: ${({ theme }) => theme.space_10};
  display: grid;
  grid-auto-flow: ${({ theme, isAccountSummary }) =>
    !theme.isDesktop || isAccountSummary ? 'row' : 'column'};
  justify-content: ${({ theme }) => (theme.isDesktop ? 'start' : 'center')};
  text-decoration: ${({ hasStorno }) => hasStorno && 'line-through'};
  align-items: center;

  .label {
    padding-left: ${({ isAccountSummary }) =>
      isAccountSummary ? '0' : 'auto'};
    color: ${({ theme, isAccountSummary }) => isAccountSummary && theme.main};
  }

  .hours {
    font-size: ${({ theme }) => theme.fontSM};
    color: ${({ theme, isAccountSummary }) =>
      isAccountSummary ? theme.main : theme.textMain};
  }
`;

const screenSize = window.screen.width;
const getDescriptionWidth = (
  isAccountSummary: boolean,
  isDesktop: boolean,
): string =>
  (isAccountSummary && isDesktop && '240px') ||
  `calc(${screenSize}px - ${isAccountSummary ? 160 : 120}px)`;

export const ColTitleWrapper = styled.span<{ isAccountSummary: boolean }>`
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  padding-left: ${({ theme }) => theme.isDesktop && theme.space_5};
  font-size: ${({ theme }) => theme.fontMD};
  font-weight: ${({ theme }) => theme.fontBold};
  color: ${({ theme }) => theme.textMain};
  max-width: ${({ theme, isAccountSummary }) =>
    getDescriptionWidth(isAccountSummary, !!theme.isDesktop)};
`;

export const ColAmount = styled.div<{
  hasStorno?: boolean;
  amountColor: string;
}>`
  padding-right: ${({ theme }) => theme.space_10};
  display: grid;
  grid-auto-flow: ${({ theme }) => (!theme.isDesktop ? 'row' : 'column')};
  justify-content: center;
  align-items: center;
  font-size: ${({ theme }) => theme.fontMD};
  font-weight: ${({ theme }) => theme.fontBold};
  color: ${({ amountColor }) => amountColor};
  text-decoration: ${({ hasStorno }) => hasStorno && 'line-through'};
`;

export const Sum = styled.span`
  font-size: ${props => props.theme.fontMD};
`;

export const Wrapper = styled.div`
  display: grid;
  grid-auto-flow: column;
  justify-content: start;
  align-items: center;
  justify-items: start;
`;

const getSvgColTitleStyles = (theme, isAccountSummary) => {
  return theme.isDesktop && !isAccountSummary
    ? `margin-left: ${theme.space_5}; margin-right: ${theme.space_5};`
    : `margin-right: ${theme.space_10};`;
};

export const ColTitleLabel = styled.div<{ isAccountSummary?: boolean }>`
  display: flex;
  padding-left: ${({ theme, isAccountSummary }) =>
    theme.isDesktop && !isAccountSummary && theme.space_20};

  & svg {
    ${({ theme, isAccountSummary }) =>
      getSvgColTitleStyles(theme, isAccountSummary)};
  }
`;

const getPendingTransactionTitleStyles = (isPendingTitle, theme) => {
  return theme.isDesktop
    ? (isPendingTitle &&
        `${theme.space_20} ${theme.space_10} ${theme.space_10} 0;`) ||
        `${theme.space_30} 0 ${theme.space_10} 0;`
    : (isPendingTitle && theme.space_10) ||
        `${theme.space_20} ${theme.space_10} ${theme.space_10};`;
};

export const PendingTransactionTitle = styled(TextNormal)<{
  isPendingTitle?: boolean;
}>`
  color: ${props => props.theme.textMain};
  font-size: ${props => props.theme.fontH3};
  margin: ${({ theme, isPendingTitle }) =>
    getPendingTransactionTitleStyles(isPendingTitle, theme)};
  font-weight: ${({ theme }) => theme.fontBold};
`;

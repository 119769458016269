import styled from 'styled-components';

import { TextSM } from '@features/core/styles/text.styled';

import { hexToRgba } from '@common/helpers/deviceUtil';

export const OddsCompSectionSliderWrapper = styled.div`
  display: grid;
  align-items: center;
  background-color: ${({ theme }) => theme.textActive};
  max-width: 410px;
  border-radius: 4px;

  .odds-comparison-close-btn {
    width: 95%;
    z-index: 10;
    margin-bottom: ${({ theme }) => theme.space_15};
    justify-self: center;
  }
`;

export const DescriptionLabel = styled(TextSM)`
  color: ${({ theme }) => theme.textMain};
  margin: 0 ${({ theme }) => theme.space_10} ${({ theme }) => theme.space_5};
`;

export const DescriptionBlock = styled.div`
  display: grid;
  grid-template-columns: auto 1fr;
  margin: 0 ${({ theme }) => theme.space_10} ${({ theme }) => theme.space_15};
  color: ${({ theme }) => hexToRgba(theme.textMain, 0.6)};
`;

export const DescriptionText = styled(TextSM)`
  margin-left: ${({ theme }) => theme.space_5};
`;

export const StarSection = styled(TextSM)``;

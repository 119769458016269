import { ICustomError } from '@common/interfaces';

export interface INews {
  active?: string;
  background?: string;
  background_img_id?: string;
  bric_story?: null;
  content?: string;
  created_on?: string;
  dpaFileName?: null;
  heading?: string;
  id?: string;
  image_caption?: string;
  is_fixed?: string;
  news_category?: string;
  news_url?: string;
  published_date?: string;
  restriction_date?: string;
  restriction_date_start?: string;
  slug?: string;
  sort?: string;
  status?: string;
  story_id?: string;
  subheading1?: string;
  subheading2?: string;
  summary?: string;
  type?: string;
  version?: string;
}

export interface ISectionState {
  loading: boolean;
  error: ICustomError | null;
  data: INews[];
  categories?: string[];
}

export interface INewsDetailState {
  loading: boolean;
  error: ICustomError | null;
  data: INews | null;
}

export interface INewsPressState {
  news: ISectionState;
  press: ISectionState;
  currentDetail: INewsDetailState;
}

export enum INewsDefineStates {
  news = 'news',
  press = 'press',
  currentDetail = 'currentDetail',
}

// sample
// {
//     active: "1"
//     background: "/cdn2/files/large/news-a-2252-nbaarticle.jpg"
//     background_img_id: "0588c0c6ad68088"
//     bric_story: null
//     content: "some content"
//     created_on: "2021-10-22 10:06:58"
//     dpaFileName: null
//     heading: "Alles auf Anfang"
//     id: "54097"
//     image_caption: null
//     is_fixed: "0"
//     news_category: null
//     news_url: "Alles_auf_Anfang"
//     published_date: null
//     restriction_date: null
//     restriction_date_start: null
//     slug: "Alles_auf_Anfang"
//     sort: "1"
//     status: "live"
//     story_id: "9177"
//     subheading1: "NBA Saison 2021/2022"
//     subheading2: null
//     summary: ""
//     type: "sports"
//     version: "1634890196"
// }

/* eslint-disable no-await-in-loop */
import services from '@features/core/services';

import { LANGUAGE, SESSION_ID, TOKEN, USER_ID } from '@common/constants/cookie';
import {
  LIMIT_LAYER_CREDIT_CHECK_REJECT,
  LIMIT_LOADER_LAYER,
} from '@common/constants/dialogs';
import { MethodType } from '@common/api/apiHandler';
import apiRpcRequest from '@common/api/common/apiCommonRequest';
import {
  closeSidebar,
  openSidebar,
} from '@common/providers/application/useAppState';
import { ICreditCheck } from '@common/providers/limits/types';
import { setLimitAction } from '@common/providers/limits/helper';

const fetchCreditCheck = async (data): Promise<ICreditCheck> => {
  const tokenValue = services.cookie.get(TOKEN) || '';
  const sessionValue = services.cookie.get(SESSION_ID) || '';
  const userIdValue = services.cookie.get(USER_ID) || '';
  const langValue = services.cookie.get(LANGUAGE) || '';
  const payload = {
    session_id: sessionValue,
    lang: langValue,
    user_id: userIdValue,
    secret_token: tokenValue,
    amount: data.value,
    username: data.username || '',
  };
  const response = await apiRpcRequest({
    method: MethodType.Post,
    data: {
      method: 'user.update_schufa_credit_limit',
      params: payload,
    },
  });
  return response as ICreditCheck;
};

const repeatedCreditCheck = async (
  value,
  prevValue,
  limitType,
  username,
): Promise<void> => {
  let retries = 0;
  openSidebar({ type: LIMIT_LOADER_LAYER });
  const fetchWithRetry = async (): Promise<ICreditCheck> => {
    return fetchCreditCheck({ value, username });
  };

  while (retries < 10) {
    const response = await fetchWithRetry();
    if (response.result.status !== 'pending') {
      if (response.result.status === 'successful') {
        setLimitAction({
          limitType,
          prevValue,
          isSchufaAccept: true,
          amount: value,
        });
      } else {
        closeSidebar(LIMIT_LOADER_LAYER);
        openSidebar({ type: LIMIT_LAYER_CREDIT_CHECK_REJECT });
      }
      break;
    }

    await new Promise(resolve => setTimeout(resolve, 500));
    retries += 1;
  }
};

export default repeatedCreditCheck;

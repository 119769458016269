import { RuleObject } from 'rc-field-form/es/interface';
import replace from 'lodash/replace';
import isUndefined from 'lodash/isUndefined';

const minLengthRule = async (
  rule: RuleObject,
  value: string,
): Promise<void> => {
  if (
    !isUndefined(rule.min) &&
    !isUndefined(rule.max) &&
    (parseFloat(value) < rule.min || parseFloat(value) > rule.max)
  ) {
    return Promise.reject(
      new Error(
        replace(
          replace(`${rule.message}`, '{0}', `${rule.min}`),
          '{1}',
          `${rule.max}`,
        ),
      ),
    );
  }
  return Promise.resolve();
};

export default minLengthRule;

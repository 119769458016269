/* eslint sonarjs/prefer-immediate-return: 0 */
import React from 'react';

import { useEventsModel } from '@features/events/useEventsModel';

import {
  IWrappedComponentProps,
  IWithTimerModelProps,
} from './WithTimerModel.types';

const withTimerModel = (
  WrappedComponent: React.FC<IWrappedComponentProps>,
): ((props) => React.ReactElement) => {
  const WithTimerModel = (props: IWithTimerModelProps): React.ReactElement => {
    const { id, listType } = props;
    const { event, category } = useEventsModel(id, listType);
    if (!event || !event.timer) {
      return <></>;
    }
    return (
      <WrappedComponent {...props} category={category} timer={event.timer} />
    );
  };

  return WithTimerModel;
};

export default withTimerModel;

import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useTheme } from 'styled-components';

import { Icon } from '@ui/components/icon';
import { LiveChatScript } from '@ui/components/liveChat';

import { ILiveChatButton } from './LiveChatButton.types';
import * as S from './LiveChatButton.styled';

const LiveChatButton: React.FC<ILiveChatButton> = ({
  id,
  styles,
  contentStyles,
  iconStyles,
  clearDummyElement,
  hasIcon = true,
  children = <></>,
  customView,
}) => {
  const [loadedScript, setLoadScript] = useState(false);
  const { t } = useTranslation();
  const { iconFillLight } = useTheme();

  const liveChatHandler = (): void => {
    setLoadScript(true);
    if (window.liveChatFunction) {
      window.liveChatFunction();
    }
  };

  const el = document.getElementById('live_chat_button_dummy');

  useEffect(() => {
    if (el && clearDummyElement) {
      el.innerHTML = '';
    }
  }, [el]);

  return (
    <S.Block
      id={id}
      className="live_chat_button"
      data-qa="live-chat-button"
      aria-label={t('common.buttons.chat')}
      style={styles}
      onClick={liveChatHandler}
    >
      {loadedScript && <LiveChatScript />}
      {customView || (
        <S.LiveChatContent className="live-chat-content" style={contentStyles}>
          {hasIcon && (
            <Icon
              style={iconStyles}
              name="livechat"
              width="40"
              height="40"
              fill={iconFillLight}
              isMultiplyAttrs
            />
          )}
          <span>{children}</span>
        </S.LiveChatContent>
      )}
    </S.Block>
  );
};

export default LiveChatButton;

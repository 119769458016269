import convertGermanTimeToUTC0 from '@common/helpers/timeHelper/convertGermanTimeToUTC0';

/**
 * convertToTimeZone
 * Function takes timestamp of German Time and converting it to current user timezone formatted date
 *
 * @param {number} timestamp
 * @returns {Date} date
 */
export default (timestamp: number): Date => {
  const timestampGMT0 = convertGermanTimeToUTC0(timestamp);
  const timestampTimeZoneOffset = new Date().getTimezoneOffset() * 1000 * 60; // if positive value GMT-N else GMT+N
  return new Date(timestampGMT0 - timestampTimeZoneOffset);
};

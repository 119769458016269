import React from 'react';

import { DisabledMarkets } from '@features/events/components/markets';

import { IDisabledMarketTriple } from './DisabledMarketTriple.types';

const DisabledMarketTriple: React.FC<IDisabledMarketTriple> = props => {
  const { pageType, hasSpecialValue, hasLabel } = props;

  return (
    <DisabledMarkets
      hasLabel={hasLabel}
      pageType={pageType}
      hasSpecialValue={hasSpecialValue}
      predictionType="triple"
    />
  );
};

export default DisabledMarketTriple;

import reduce from 'lodash/reduce';
import { TFunction } from 'i18next';

import { DATE_TEMPLATE } from '@features/core/form/components/dateInput/DateTemplates';

import { ITransaction } from '@common/interfaces/transaction/ITransaction';
import formatDate from '@common/helpers/timeHelper/localizedFormat';

export type ITransactionFlatListItem = {
  type: string;
  flatListItem: string | ITransaction;
  key: string;
};

const getGroupedTransactions = (
  data: ITransaction[],
  t: TFunction,
): ITransactionFlatListItem[] => {
  const pendingIdentifier = t('cashout.pending_cashout');

  const initial: ITransactionFlatListItem[] = [];
  if (data[0].description === pendingIdentifier) {
    const pendingTitle: ITransactionFlatListItem = {
      type: 'group_title',
      flatListItem: t(`transactions.pending_transactions`),
      key: '000',
    };
    initial.push(pendingTitle);
  }
  let closedTitleWasSet = false;
  return reduce(
    data,
    (acc, el: ITransaction, ind) => {
      const prevElement = acc[acc.length - 1]?.flatListItem as ITransaction;
      if (
        !closedTitleWasSet &&
        acc[0]?.flatListItem === t(`transactions.pending_transactions`) &&
        el.description !== pendingIdentifier &&
        el.description !== prevElement?.description
      ) {
        acc.push({
          type: 'group_title',
          flatListItem: t(`transactions.closed_transactions`),
          key: `${ind}-t`,
        });
        closedTitleWasSet = true;
      }
      if (el.localeZoneTimestamp !== prevElement?.localeZoneTimestamp) {
        acc.push({
          type: 'date',
          flatListItem: formatDate(
            new Date(el.localeZoneTimestamp),
            DATE_TEMPLATE,
          ),
          key: `${ind}-d`,
        });
      }
      return acc.concat({
        type: 'transaction',
        flatListItem: el,
        key: ind.toString(),
      });
    },
    initial,
  );
};

export default getGroupedTransactions;

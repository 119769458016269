import React from 'react';

import {
  BallPossession,
  FactoryCards,
} from '@features/events/components/scoreboards';

import {
  getHomeLabel,
  getAwayLabel,
} from '@common/helpers/eventsHelper/eventLabelHelper';
import { isWinner } from '@common/helpers/eventsHelper/eventStatusHelper';

import * as S from './EventDetailLabel.styled';
import { IEventDetailLabel } from './EventDetailLabel.types';

const EventDetailLabel: React.FC<IEventDetailLabel> = props => {
  const { event, category, listType } = props;

  if (isWinner(event)) {
    return (
      <S.LabelWrapper>
        <S.WinnerLabel>{getHomeLabel(event.label || '')}</S.WinnerLabel>
      </S.LabelWrapper>
    );
  }

  return (
    <S.LabelWrapper className="team">
      <S.Label>
        <FactoryCards event_id={event.id} listType={listType} isHome />
        <S.BallPossessionWrapper>
          {category && (
            <BallPossession
              id={event.id}
              listType={listType}
              team="home"
              iconSize={10}
              topCategoryId={category.top_category_id}
            />
          )}
        </S.BallPossessionWrapper>
        <S.Title>{getHomeLabel(event.label || '')}</S.Title>
      </S.Label>
      <S.Label>
        <FactoryCards event_id={event.id} listType={listType} isHome={false} />
        <S.BallPossessionWrapper>
          {category && (
            <BallPossession
              id={event.id}
              listType={listType}
              team="away"
              iconSize={10}
              topCategoryId={category.top_category_id}
            />
          )}
        </S.BallPossessionWrapper>
        <S.Title>{getAwayLabel(event.label || '')}</S.Title>
      </S.Label>
    </S.LabelWrapper>
  );
};

export default EventDetailLabel;

import services from '@features/core/services';

import { DynamicCalls } from '@common/api/api';
import { apiHandler, MethodType } from '@common/api/apiHandler';
import { ICheckEmailResponse, ICustomError } from '@common/interfaces';

const { API_CHECK_EMAIL } = DynamicCalls;

const checkEmail = (
  email: string,
): Promise<ICheckEmailResponse & ICustomError> => {
  return apiHandler<ICheckEmailResponse>(
    { method: MethodType.Get, params: { email, skip_checking: 'birthday' } },
    {
      apiUrl: API_CHECK_EMAIL,
      apiData: {
        dynamicLanguage: services.domainLang,
      },
    },
  );
};

export default checkEmail;

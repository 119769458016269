import React, { useEffect } from 'react';
import reduce from 'lodash/reduce';
import includes from 'lodash/includes';

import { PageName } from '@features/core/routing/linkAliases';
import services from '@features/core/services';

import { LIVE_COUNTERS } from '@common/constants/config';
import { elementScrollIntoView } from '@common/helpers/scrollHelper';
import { setLocation } from '@common/providers/router/helper';
import { PageType } from '@common/interfaces';
import {
  DEFAULT_MARKET_TYPE,
  DEFAULT_SELECTED_CATEGORY_TYPE_TAB,
} from '@common/providers/events/liveCategories/state';
import {
  addCategoryAbbreviations,
  deUmlautNormal,
  getSportName,
} from '@common/helpers/categories/categoriesModel';
import { resetEventsList } from '@common/providers/events/eventList/useEventsList';
import {
  selectMarketType,
  toggleLiveCategory,
  toggleSportCategory,
  useLiveCategories,
} from '@common/providers/events/liveCategories/useLiveCategories';

import * as S from './CategoriesSwitcherDesktop.styled';
import ICategoriesSwitcherDesktopProps from './CategoriesSwitcherDesktop.types';

const CategoriesSwitcherDesktop: React.FC<ICategoriesSwitcherDesktopProps> = props => {
  const { items, selectedCategoryId, pageType, children = null } = props;
  const selectedCategoryTypeTab = useLiveCategories(
    s => s.selectedCategoryTypeTab,
  );
  const isCategoriesPage = PageType.CATEGORIES === pageType;
  const isLivePage = PageType.LIVE === pageType;

  const onItemClick = (categoryId: string): void => {
    const viewScrollEl = document.getElementById(`${categoryId}`);
    if (isCategoriesPage) {
      if (selectedCategoryId[0] === categoryId) {
        toggleSportCategory({ category: '' });
      } else {
        toggleSportCategory({ category: categoryId });
        if (viewScrollEl) {
          setTimeout(() => {
            elementScrollIntoView(viewScrollEl, {
              behavior: 'smooth',
              block: 'nearest',
              inline: 'nearest',
            });
          }, 100);
        }
      }
    }

    if (isLivePage) {
      resetEventsList('live');
      toggleLiveCategory({ category: categoryId });
      selectMarketType({ market: DEFAULT_MARKET_TYPE });
      setLocation(PageName.LIVE);
    }
  };

  useEffect(() => {
    return () => {
      if (isLivePage) {
        toggleLiveCategory({ category: '' });
      }
    };
  }, []);

  const renderItemTitle = (active: boolean, sportName: string): JSX.Element => {
    return (
      <S.ItemTitle active={active}>{deUmlautNormal(sportName)}</S.ItemTitle>
    );
  };

  return (
    <S.Root isLivePage={isLivePage} data-qa="category-filter-wrapper">
      {reduce(
        items,
        (acc, item) => {
          const active = includes(selectedCategoryId, item.id);
          const sportName = addCategoryAbbreviations(item.detail);

          if (
            (selectedCategoryTypeTab !== DEFAULT_SELECTED_CATEGORY_TYPE_TAB &&
              item.is_only_regular) ||
            (selectedCategoryTypeTab === DEFAULT_SELECTED_CATEGORY_TYPE_TAB &&
              item.is_only_longterm)
          ) {
            return acc;
          }

          acc.push(
            <S.Item isLivePage={isLivePage} key={item.id}>
              <S.ItemButton
                onClick={(): void => onItemClick(item.id)}
                data-qa={`category-filter-btn-${item.id}`}
                isLivePage={isLivePage}
                className={active ? 'selected' : 'not-selected'}
                id={item.id}
              >
                {isLivePage && services.config.get(LIVE_COUNTERS) && (
                  <S.EventCount active={active}>
                    {item.count_live_now}
                  </S.EventCount>
                )}
                <S.GridColumnWrapper
                  isLivePage={
                    isLivePage &&
                    (services.config.get(LIVE_COUNTERS) as boolean)
                  }
                >
                  <S.ItemIcon
                    name={getSportName(item.id)}
                    width="18"
                    height="18"
                  />
                  {isCategoriesPage && renderItemTitle(active, sportName)}

                  {isLivePage && active && renderItemTitle(active, sportName)}
                </S.GridColumnWrapper>
              </S.ItemButton>
              {active && <S.ItemChildren>{children}</S.ItemChildren>}
            </S.Item>,
          );

          return acc;
        },
        [] as JSX.Element[],
      )}
    </S.Root>
  );
};

export default CategoriesSwitcherDesktop;

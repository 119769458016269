import styled from 'styled-components';

import services from '@features/core/services';

import { IS_TERMINAL } from '@common/constants/config';

const isTerminal = services.config.get(IS_TERMINAL);

const terminalOverrides = `
  display: flex;
  flex-direction: column;
`;

export const Tab = styled.div`
  #cashout {
    padding: 0;
  }

  .vertical-scroll {
    @media not screen {
      height: auto !important;
    }
  }

  .loader {
    height: auto !important;
    padding: ${({ theme }) => theme.space_20} 0;
  }

  margin-bottom: ${({ theme }) => (isTerminal ? 0 : theme.space_40)};
  height: ${isTerminal ? '100%' : 'auto'};
  ${isTerminal && terminalOverrides};
`;
export const TabItemList = styled.div<{ tabsCount: number }>`
  display: grid;
  grid-template-columns: repeat(${({ tabsCount }) => tabsCount}, 1fr);
  grid-auto-flow: column;
  justify-content: space-between;
  position: sticky;
  top: 0;
  z-index: 10;
`;
export const TabItem = styled.div<{ disabled: boolean; active: boolean }>`
  pointer-events: ${({ disabled }) => (disabled ? 'none' : 'auto')};
  background: ${({ theme, active }) =>
    active ? theme.tabActiveBackground : theme.tabBackground};
`;

export const TabHeader = styled.header<{ active: boolean; disabled: boolean }>`
  cursor: pointer;
  color: ${({ theme, active }) =>
    active ? theme.elementNuanceColor : theme.textMain};
  padding: 0 ${({ theme }) => theme.space_10};
  height: 40px;
  font-size: ${props => props.theme.fontLG};
  font-weight: ${({ active, theme }) =>
    !active ? theme.fontRegular : theme.fontBold};
  text-transform: uppercase;
  font-stretch: condensed;
  display: grid;
  align-items: center;
  justify-content: center;
  opacity: ${({ theme, disabled }) => (disabled ? theme.disabledOption : 1)};
`;
export const TabContent = styled.div`
  ${isTerminal && 'height: 100%'}
`;
export const BetDetailWrapper = styled.div`
  max-width: 400px;
  height: 100%;

  & > div {
    overflow-y: hidden !important;
  }

  & #bet_detail {
    height: calc(100vh - 80px);
  }
`;

import React, { memo } from 'react';
import { useLocation, matchPath } from 'react-router-dom';
import reduce from 'lodash/reduce';
import styled from 'styled-components';

import { ROUTERS_MAPPER } from '@features/core/routing/routerMapper';
import { useServices } from '@features/core/services';

const urlsList = [
  {
    urlsList: ROUTERS_MAPPER.USER_LIMITS,
    className: 'user-limits',
  },
  {
    urlsList: ROUTERS_MAPPER.USER_LIMITS_LOCK,
    className: 'user-limits-lock',
  },
  {
    urlsList: ROUTERS_MAPPER.USER_VERIFICATION,
    className: 'user-verification',
  },
  {
    urlsList: ROUTERS_MAPPER.USER_DELETE_ACCOUNT,
    className: 'user-delete-account',
  },
  {
    urlsList: ROUTERS_MAPPER.USER_BETS,
    className: 'user-myBets',
  },
  {
    urlsList: ROUTERS_MAPPER.USER_OVERVIEW,
    className: 'bets-list',
  },
  {
    urlsList: ROUTERS_MAPPER.USER_PAYIN,
    className: 'paymentContainer',
  },
  {
    urlsList: ROUTERS_MAPPER.USER_PAYOUT,
    className: 'paymentContainer',
  },
  {
    urlsList: ROUTERS_MAPPER.USER_PROFILE,
    className: 'user-profile',
  },
  {
    urlsList: ROUTERS_MAPPER.USER_CHANGE_PASSWORD,
    className: '-change-password',
  },
  {
    urlsList: ROUTERS_MAPPER.USER_NOTIFICATIONS,
    className: 'user-notifications',
  },
  {
    urlsList: ROUTERS_MAPPER.USER_BONUSES,
    className: 'user-bonuses',
  },
  {
    urlsList: ROUTERS_MAPPER.USER_INBOX,
    className: 'user-inbox',
  },
  {
    urlsList: ROUTERS_MAPPER.PAYMENT_METHODS,
    className: 'payment-methods',
  },
];

const addFixedPositionInLimitPage = (): string => `
  &.user-limits,
  &.user-limits-lock,
  &.user-myBets,
  &.bets-list,
  &.paymentContainer,
  &.user-bonuses,
  &.user-profile-change-password,
  &.user-notifications,
  &.user-inbox,
  &.user-profile + .main-container .right-col-desktop,
  &.user-profile {
    position: fixed;
    top: 60px;
    right: 0;
    height: 100vh;
  }
`;

const GradientArea = styled.div`
  position: absolute;
  z-index: 0;
  height: ${({ theme }): string => (theme.isDesktop ? '100%' : '1635px')};
  width: 100%;
  top: 0;
  left: 0;

  &.user-limits,
  &.user-limits-lock,
  &.user-myBets,
  &.bets-list,
  &.paymentContainer,
  &.user-bonuses,
  &.user-profile-change-password,
  &.user-notifications,
  &.change-Password,
  &.user-inbox,
  &.user-profile + .main-container {
    background-color: transparent;
  }

  ${window.innerWidth > 2200 ? addFixedPositionInLimitPage() : ''}

  &.user-limits,
  &.user-limits-lock,
  &.user-verification,
  &.user-delete-account,
  &.user-myBets,
  &.bets-list,
  &.paymentContainer,
  &.user-bonuses,
  &.user-profile-change-password,
  &.user-notifications,
  &.changePassword,
  &.user-inbox,
  &.payment-methods,
  &.user-profile {
    display: block;
    ${(props): string => props.theme.gradientBgStyles}
  }
`;

const GradientElement = (): JSX.Element | null => {
  const { domainLang } = useServices();
  const { pathname } = useLocation();
  const gradientName = reduce(
    urlsList,
    (acc, route) =>
      `${acc}${
        matchPath(pathname, route.urlsList[domainLang]) ? route.className : ''
      }`,
    '',
  );

  if (!gradientName) {
    return null;
  }

  return <GradientArea className={gradientName} />;
};

export default memo(GradientElement);

import isString from 'lodash/isString';
import isNumber from 'lodash/isNumber';
import isArray from 'lodash/isArray';

import services from '@features/core/services';
import i18n from '@features/core/translation';

import { IResponseError, ICustomError } from '@common/interfaces';

export const NEWORK_ERROR = 'Network Error';

export const convertResponseToError = (error: IResponseError): string => {
  if (error instanceof TypeError) {
    return i18n.t('common.labels.defaultError');
  }
  if (isString(error?.message)) {
    return error.message;
  }
  if (
    isArray(error?.message) &&
    isString(error?.message[0]) &&
    !error?.message?.message
  ) {
    return error.message[0];
  }
  if (error?.message && !isString(error.message)) {
    return error.message.message;
  }
  if (!isNumber(error?.error) && error?.error?.msg) {
    return error?.error?.msg;
  }
  if (!isNumber(error?.error) && error?.error?.userMessages[0]) {
    return error.error.userMessages[0];
  }
  if (!isNumber(error?.error) && error?.error?.message) {
    return error.error.message;
  }
  if (error?.userMessages) {
    return error.userMessages;
  }
  if (error?.msg) {
    return error.msg;
  }
  if (isString(error)) {
    let err;
    try {
      err = JSON.parse(error);
      return err?.error?.msg || err?.error?.code;
    } catch (e) {
      services.logger.error(e as string);
    }
  }
  return i18n.t('common.labels.error');
};

class CustomError<T = unknown> extends Error implements ICustomError<T> {
  name: string;

  message: string;

  messages?: string[];

  data?: T;

  code?: string;

  constructor({
    message,
    messages,
    data,
    code,
  }: {
    message: string;
    messages?: string[];
    data?: T;
    code?: string;
  }) {
    super(`Error with message: "${message}"`);

    this.name = 'CustomError';
    this.message = message;
    this.messages = messages;
    this.data = data;
    this.code = code;
    Object.setPrototypeOf(this, CustomError.prototype);
  }

  toString(): string {
    return this.message;
  }
}

export default CustomError;

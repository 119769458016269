import React, { useEffect } from 'react';
import Form from 'rc-field-form';
import keys from 'lodash/keys';
import { useTranslation } from 'react-i18next';
import { FieldData } from 'rc-field-form/lib/interface';

import FormLayout from '@features/registration/components/FormLayout.styled';
import { RegistrationFormItem } from '@features/registration/components/registrationFormItem';
import { RegistrationStepSubmitWrapper } from '@features/registration/components/registrationStepSubmitWrapper';
import {
  getStaticPageURL,
  PageName,
  StaticSlugPageName,
} from '@features/core/routing/linkAliases';
import { CheckboxInput } from '@features/core/form/components/checkboxInput';
import { FormRegisterLimit } from '@features/core/form/components/formRegisterLimit';

import makeRegistrationStepConfig from '@common/helpers/formsHelpers/registration/makeRegistrationStepConfig';
import { IResponseErrorType, IStepProps } from '@common/interfaces';
import { isDesktopView } from '@common/helpers/deviceUtil';
import scrollToErrorField from '@common/helpers/registrationHelper/scrollToErrorField';
import { ConditionalWrapper } from '@common/helpers/appLayoutHelper';
import { SINGLE } from '@common/helpers/registrationHelper/registrationHelpers';
import { resetFieldErrors } from '@common/helpers/formHelper';
import useFormDisable from '@common/hooks/useFormDisable';

import * as S from './Step4.styled';

// this is temp solution
// TODO:
// 1. if virtual input visually looks same as text input then implement it on top of the text input
//    if no make it be controlled component
// 2. make virtual input work with rc-ford-field
// 3. dont implement your castom validation, use rc-ford-field

const isDesktop = isDesktopView();

const Step4: React.FC<IStepProps> = ({
  onSubmit,
  loading,
  renderStepContainer,
  singleForm,
  registrationType,
  stepForm,
}) => {
  const form = singleForm || stepForm;
  const { t } = useTranslation();
  const isSingleForm = registrationType === SINGLE;
  const configRules = makeRegistrationStepConfig(form);
  const { disabled, onFormValueChange } = useFormDisable(form, configRules);

  useEffect(() => {
    return () => form.resetFields(['radiobtn', 'deposit_monthly_limit']);
  }, []);

  const handleSubmit = async (event): Promise<void> => {
    event.preventDefault();

    try {
      await form.validateFields();
      if (onSubmit) {
        onSubmit(4);
      }
    } catch (e) {
      const error = e as IResponseErrorType;
      scrollToErrorField(error);
    }
  };

  const termsConditionsLink = getStaticPageURL(
    PageName.INFORMATION,
    StaticSlugPageName.TERMS,
  );
  const privacyPolicyLink = getStaticPageURL(
    PageName.INFORMATION,
    StaticSlugPageName.PRIVACY_POLICY,
  );

  const onFieldsChangeHandler = (_, fields: FieldData[]): void => {
    onFormValueChange(fields, loading);
  };

  const onValuesChangeHandler = (changedValues): void => {
    const changedFieldName = keys(changedValues)[0];
    resetFieldErrors(changedFieldName, form);
  };

  return (
    <ConditionalWrapper
      condition={!isSingleForm}
      wrapper={(children): JSX.Element => (
        <Form
          name="step4"
          form={form}
          onFieldsChange={onFieldsChangeHandler}
          onValuesChange={onValuesChangeHandler}
        >
          {children}
        </Form>
      )}
    >
      <FormLayout.GridBox className={isSingleForm ? 'single' : 'stepper'}>
        <div>
          <FormLayout.Panel>
            {isDesktop && renderStepContainer && renderStepContainer()}

            <FormLayout.TopTextHeader>
              {t('registration.step4.step_title')}
            </FormLayout.TopTextHeader>

            <FormLayout.TopText>
              {t('registration.step4.top_text')}
            </FormLayout.TopText>

            <FormLayout.TextSCHUFA>
              {t('registration.step4.SCHUFA')}
            </FormLayout.TextSCHUFA>

            <FormLayout.TextSCHUFAInfo>
              {t('registration.step4.SCHUFA_information')}
            </FormLayout.TextSCHUFAInfo>
          </FormLayout.Panel>

          <FormLayout.Panel id="terms_of_service">
            <FormLayout.TextSubHeader>
              {t('registration.step4.general_terms')}
            </FormLayout.TextSubHeader>

            <S.CheckboxLabel htmlFor="terms_agree">
              <RegistrationFormItem
                name="terms_agree"
                initialValue={false}
                valuePropName="checked"
                rules={configRules.terms_agree.rules}
                form={form}
                noErrorMessages
              >
                <CheckboxInput
                  className="cancel-mobile-focus"
                  id="terms_agree"
                  data-qa="register-step4-checkbox-termsAgree"
                  name="terms_agree"
                  tabIndex={-1}
                  label="registration.step4.accept_terms_text"
                  linkHref={termsConditionsLink}
                  ariaLabel={t('common.links.terms_of_service')}
                />
              </RegistrationFormItem>
            </S.CheckboxLabel>
          </FormLayout.Panel>

          <FormLayout.Panel id="privacy_policy">
            <FormLayout.TextSubHeader>
              {t('registration.step4.privacy_agree')}
            </FormLayout.TextSubHeader>

            <S.CheckboxLabel htmlFor="privacy_policy_accepted">
              <RegistrationFormItem
                name="privacy_policy_accepted"
                initialValue={false}
                valuePropName="checked"
                rules={configRules.privacy_policy_accepted.rules}
                form={form}
                noErrorMessages
              >
                <CheckboxInput
                  className="cancel-mobile-focus"
                  id="privacy_policy_accepted"
                  data-qa="register-step4-checkbox-privacyPolicyAccepted"
                  name="privacy_policy_accepted"
                  tabIndex={-1}
                  linkHref={privacyPolicyLink}
                  label="registration.step4.accept_privacy_policy_text"
                  ariaLabel={t('common.links.privacy_policy')}
                />
              </RegistrationFormItem>
            </S.CheckboxLabel>
          </FormLayout.Panel>

          <FormLayout.Panel id="limits" className="limits">
            <FormRegisterLimit form={form} hideUseMaxLimitCheckbox />
            {!isSingleForm && (
              <RegistrationStepSubmitWrapper
                onSubmit={handleSubmit}
                submitText="registration.step4.create_account"
                disabled={loading || disabled}
                dataQa="register-step4-btn-continue"
                isLoader={loading}
                tabIndex={0}
              />
            )}
          </FormLayout.Panel>
        </div>
      </FormLayout.GridBox>
    </ConditionalWrapper>
  );
};

export default Step4;

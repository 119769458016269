import styled from 'styled-components';

// eslint-disable-next-line import/prefer-default-export
export const Predictions = styled.div`
  display: grid;
  grid-auto-flow: column;
  justify-content: center;
  align-items: center;

  button {
    width: 100%;
  }
`;

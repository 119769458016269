import styled from 'styled-components';

const activeTabStyles = ({ theme }) => `
  color: ${theme.textActiveTabColor};
  font-weight: ${theme.fontBold};
  background-color: ${theme.accent};
`;

// eslint-disable-next-line import/prefer-default-export
export const Tab = styled.button<{ isDisabled?: boolean; isActive?: boolean }>`
  cursor: pointer;
  border: ${({ theme }) => theme.border};
  border-radius: 0;
  margin: 0;
  min-height: 40px;
  position: relative;
  color: ${({ theme }) => theme.textTabColor};
  background-color: ${({ theme }) => theme.highlightsHeaderBackground};
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  font-size: ${({ theme }) => theme.fontLG};
  ${({ isDisabled }) => isDisabled && 'opacity: .35;'};
  padding: 0 ${({ theme }) => theme.space_10};
  text-transform: uppercase;

  ${({ isActive }) => isActive && activeTabStyles};
`;

/* eslint-disable lodash/prefer-lodash-typecheck */
import replace from 'lodash/replace';

import services from '@features/core/services';

import { SESSION_ID, TOKEN } from '@common/constants/cookie';
import { MethodType } from '@common/api/apiHandler';
import { ISelfExclusionResult } from '@common/interfaces/limits/ISelfExclusionResult';
import apiRpcRequest from '@common/api/common/apiCommonRequest';
import { ISetSelfExclusionPayload } from '@common/providers/limits/types';

const fetchSetSelfExclusionMGA = async ({
  period,
  reason,
}: ISetSelfExclusionPayload): Promise<ISelfExclusionResult> => {
  const method = 'user.set_self_exclusion';
  const isInfinite = period === '9999Y';

  const isDate = period instanceof Date;
  const periodData = isDate ? 'day' : 'month';

  const getDaysDifference = (): string => {
    const todayMs = 86400000;
    const targetDate = new Date(period).getTime() + todayMs;
    const currentDate = new Date().getTime() - todayMs;
    const timeDifference = targetDate - currentDate;
    return String(Math.floor(timeDifference / (1000 * 60 * 60 * 24)));
  };

  const numberData = isDate ? getDaysDifference() : replace(period, /\D/g, '');

  const headers = {
    Cookie: `SESSION_ID=${services.cookie.get(SESSION_ID)}`,
  };

  const config = {
    method: MethodType.Post,
    data: {
      method,
      params: {
        ...(isInfinite ? {} : { period: periodData, number: numberData }),
        reason,
        token: services.cookie.get(TOKEN),
      },
    },
    headers,
  };

  return apiRpcRequest(config) as Promise<ISelfExclusionResult>;
};

export default fetchSetSelfExclusionMGA;

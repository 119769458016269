import isEqual from 'lodash/isEqual';
import reduce from 'lodash/reduce';
import assign from 'lodash/assign';

import services from '@features/core/services';

import { HAS_SUSPEND_REASON } from '@common/constants/config';
import {
  EventListTypes,
  IEvent,
  ISuspendedIntervals,
  IuEventBetStop,
} from '@common/interfaces';
import { clearEventSuspendReason } from '@common/providers/events/eventList/useEventsList';
import { clearBSsuspendReason } from '@common/providers/bettingslip/useBettingSlip';

import { isIceHockey, isRealFootball } from './eventsHelper/eventTypeHelper';

const suspendedIntervals: ISuspendedIntervals = {};

const shouldAddReason = (
  event: IEvent,
  update: IuEventBetStop,
  topCategoryId: string,
): boolean => {
  if (!services.config.get(HAS_SUSPEND_REASON)) {
    return false;
  }

  const reason = event?.suspend_reason;
  const timer = event?.suspended_timer;
  const reasons = {
    1: 'POSSIBLE_GOAL',
    2: 'POSSIBLE_RED_CARD',
    8: 'POSSIBLE_PENALTY',
    4: 'POSSIBLE_GOAL_HOME',
    5: 'POSSIBLE_GOAL_AWAY',
    6: 'POSSIBLE_RED_CARD_HOME',
    7: 'POSSIBLE_RED_CARD_AWAY',
    9: 'POSSIBLE_PENALTY_HOME',
    10: 'POSSIBLE_PENALTY_AWAY',
    74: 'POSSIBLE_VIDEO_ASSISTANT_REFEREE',
    84: 'POSSIBLE_VIDEO_ASSISTANT_REFEREE_HOME',
    89: 'POSSIBLE_VIDEO_ASSISTANT_REFEREE_AWAY',
  };
  const convertedReason = reasons[update.data.suspend_reason_id];

  if (
    (!isRealFootball(event, topCategoryId) && !isIceHockey(topCategoryId)) ||
    !convertedReason
  ) {
    return false;
  }
  //  checks if suspended reason is fresh and valid
  return (
    !timer ||
    timer < new Date().getTime() - 60 * 1000 ||
    // or it's different reason and still valid ?
    !isEqual(reason, update)
  );
};

const handleInterval = (eventId, updateInstance, listType): void => {
  const key = `${listType}${eventId}`;
  if (suspendedIntervals[key]?.timeoutId) {
    clearTimeout(suspendedIntervals[key]?.timeoutId as NodeJS.Timeout);
    suspendedIntervals[key] = null;
  }

  if (listType === EventListTypes.bettingslip) {
    suspendedIntervals[key] = {
      timeoutId: setTimeout(() => {
        clearBSsuspendReason({
          eventId,
        });
      }, 60 * 1000),
      suspend_reason: updateInstance,
      suspended_timer: new Date().getTime(),
    };
  } else {
    suspendedIntervals[key] = {
      timeoutId: setTimeout(() => {
        clearEventSuspendReason({
          eventId,
          listType,
        });
      }, 60 * 1000),
      suspend_reason: updateInstance,
      suspended_timer: new Date().getTime(),
    };
  }
};

/**
 * addSuspendedReason
 * add suspended reason to event if 60 seconds is not pass
 *
 * @param {Record<number, IEvent>} events
 * @returns {Record<number, IEvent>} addSuspendedReason
 */
export const addSuspendedReason = (
  events: Record<number, IEvent>,
): Record<number, IEvent> => {
  return reduce(
    suspendedIntervals,
    (acc, value, key) => {
      const event = events[key];
      if (event) {
        return assign(acc, {
          [key]: assign(event, {
            suspend_reason: suspendedIntervals[key]?.suspend_reason,
            suspended_timer: suspendedIntervals[key]?.suspended_timer,
          }),
        });
      }
      return acc;
    },
    events,
  );
};

export const handleUpdateReason = (
  event,
  update,
  updateInstance,
  topCategoryId,
  listType,
): IEvent => {
  if (shouldAddReason(event, update, topCategoryId)) {
    handleInterval(event.id, updateInstance, listType);
    return {
      ...event,
      suspend_reason: updateInstance,
      suspended_timer: new Date().getTime(),
    };
  }
  return event;
};

const defaultState = {
  recoverPassword: {
    loading: false,
    success: false,
    error: null,
  },
  avs: {
    token: '',
    partner_uuid: '',
    loading: false,
  },
  subscriptions: {
    verified_email_flags: false,
    loading: false,
    success: false,
    error: null,
  },
  gdpr: {
    data: [],
    error: null,
    loading: false,
    success: false,
  },
  bonusCode: {
    loading: false,
    success: false,
    error: null,
  },
  lockAccount: {
    reason: '',
    period: '',
    isCustom: false,
  },
  iframeBetValidation: {
    data: {
      secret_token: '',
      user_id: '',
      login_success: 0,
      session_id: '',
      login: '',
    },
    error: null,
    loading: false,
  },
};

export default defaultState;

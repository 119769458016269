import { create } from 'zustand';

import defaultState from '@common/providers/registration/state';
import {
  IRegisterProviderState,
  IRegisterError,
} from '@common/providers/registration/types';

export const useRegisterState = create<IRegisterProviderState>(
  () => defaultState,
);

export const setRegisterSuccess = (success: boolean): void => {
  useRegisterState.setState(() => ({
    success,
  }));
};

export const setRegisterError = (error: IRegisterError): void => {
  useRegisterState.setState(() => ({
    error,
  }));
};

export const setRegisterLoading = (loading: boolean): void => {
  useRegisterState.setState(() => ({
    loading,
  }));
};

import keys from 'lodash/keys';

import categoriesKeys from '@common/helpers/categories/categories';
import { ILiveCategories, MarketType } from '@common/interfaces';

export const DEFAULT_MARKET_TYPE = MarketType['N-WAY'];
export const DEFAULT_EVENT_MARKET_TYPE = 'FILTER_ALL';
export const DEFAULT_SELECTED_CATEGORIES = [''];
export const DEFAULT_SELECTED_SPORT_CATEGORY = ['1791'];
export const DEFAULT_SELECTED_CATEGORY_TYPE_TAB = 'regularCategories';

export const defaultState: ILiveCategories = {
  data: [],
  showTestList: false,
  selectedCategories: DEFAULT_SELECTED_CATEGORIES,
  selectedSportCategory: DEFAULT_SELECTED_SPORT_CATEGORY,
  marketTypeChecked: DEFAULT_MARKET_TYPE,
  sportCategoriesTree: {
    loading: false,
    error: null,
    data: [],
    schedule: { dates: [] },
    results: [],
    selectedPath: [],
    selectedCategoryEvents: {},
    filter: '',
    filterCategory: String(keys(categoriesKeys.football)[0]),
    showPanel: false,
  },
  flatSportCatTree: {},
  highlightsMeta: {
    highlightedEvents: {
      data: {
        events: [],
      },
      loading: false,
      error: null,
    },
    categoryWeight: {
      live: {},
      longterm: {},
      sports: {},
      loading: true,
      error: null,
    },
  },
  selectedCategoryTypeTab: DEFAULT_SELECTED_CATEGORY_TYPE_TAB,
};

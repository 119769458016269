import React from 'react';

import { useEventsModel } from '@features/events/useEventsModel';

import { getRedCards } from '@common/helpers/eventsHelper/scoreboards/scoreBoardModel';
import { isFootball } from '@common/helpers/eventsHelper/eventTypeHelper';
import { EventListTypes } from '@common/interfaces';

import * as S from './FactoryCards.styled';
import { IFactory } from './FactoryCards.types';

const FactoryCards = ({
  event_id,
  listType,
  isHome,
}: IFactory): JSX.Element | null => {
  const { event } = useEventsModel(event_id, listType);

  if (
    isFootball(event) &&
    event?.cards &&
    getRedCards(event?.cards, isHome) > 0
  ) {
    switch (listType) {
      case EventListTypes.detail || EventListTypes.cashout: {
        return (
          <S.RedCardWrapper>
            <S.RedCard>{getRedCards(event.cards, isHome)}</S.RedCard>
          </S.RedCardWrapper>
        );
      }
      case EventListTypes.bettingslip: {
        return (
          <S.StyledRedCard>{getRedCards(event.cards, isHome)}</S.StyledRedCard>
        );
      }
      default: {
        return (
          <S.LiveCards className="red">
            <span>{getRedCards(event.cards, isHome)}</span>
          </S.LiveCards>
        );
      }
    }
  }
  return null;
};

export default FactoryCards;

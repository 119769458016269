import services from '@features/core/services';

import {
  ICMSContent,
  ICMSContentBodyJson,
} from '@common/providers/application/types';
import { apiHandler, MethodType } from '@common/api/apiHandler';
import { DynamicCalls } from '@common/api/api';

const { API_STATIC_PAGE_CONTENT } = DynamicCalls;

export const getAffiliateParams = async (): Promise<ICMSContentBodyJson> => {
  const partnersResponse = await apiHandler<ICMSContent>(
    { method: MethodType.Get },
    {
      apiUrl: API_STATIC_PAGE_CONTENT,
      apiData: {
        dynamicLanguage: 'de',
        dynamicType: 'partners-register-params',
      },
    },
  );

  let partners = {} as ICMSContentBodyJson;
  try {
    partners = JSON.parse(partnersResponse.body as string);
  } catch (e) {
    services.logger?.log(String(e));
  }

  return partners;
};

export default getAffiliateParams;

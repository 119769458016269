export enum BetEvents {
  PLACE_BET = 'PLACE_BET',
  PLACE_CASHOUT = 'PLACE_CASHOUT',
  REMOVE_BET = 'REMOVE_BET',
  GET_BETS = 'GET_BETS',
  REMOVE_OPEN_BETS = 'REMOVE_OPEN_BETS',
}

export enum CommonEvents {
  NAVIGATION_STATE_CHANGE = 'NAVIGATION_STATE_CHANGE',
  NATIVE_COPY_TO_CLIPBOARD = 'NATIVE_COPY_TO_CLIPBOARD',
  NATIVE_SET_CONFIG = 'NATIVE_SET_CONFIG',
  SET_COOKIE = 'SET_COOKIE',
  SET_NATIVE_COOKIE = 'SET_NATIVE_COOKIE',
  SUCCESS_REGISTRATION = 'SUCCESS_REGISTRATION',
  SET_WALLETS_DATA = 'SET_WALLETS_DATA',
  TABLE_HEIGHT = 'TABLE_HEIGHT',
}

export enum PaymentsEvents {
  ACCOUNT_UPDATE = 'ACCOUNT_UPDATE',
  LIMITS_UPDATE = 'LIMITS_UPDATE',
}

export enum UserEvents {
  LOGIN = 'LOGIN',
  AUTH = 'AUTH',
  LOGOUT = 'LOGOUT',
  NATIVE_LOGOUT = 'NATIVE_LOGOUT',
  REQUEST_WEB_AUTH = 'REQUEST_WEB_AUTH',
  REQUEST_AUTOLOGIN = 'REQUEST_AUTOLOGIN',
  REQUEST_FACE_ID = 'REQUEST_FACE_ID',
}

export enum MarketingEvents {
  PAGE_VIEW = 'PAGE_VIEW',
  REGISTRATION_STEP = 'REGISTRATION_STEP',
  USER_LOGIN = 'USER_LOGIN',
  SELECTION = 'SELECTION',
  DETAIL_EVENT_VIEW = 'DETAIL_EVENT_VIEW',
  BET = 'BET',
  DEPOSIT = 'DEPOSIT',
  WITHDRAWAL = 'WITHDRAWAL',
}

export type CustomEvents =
  | BetEvents
  | CommonEvents
  | PaymentsEvents
  | UserEvents
  | MarketingEvents;

export default {
  ...BetEvents,
  ...CommonEvents,
  ...PaymentsEvents,
  ...UserEvents,
  ...MarketingEvents,
};

import React, { useState } from 'react';
import map from 'lodash/map';
import range from 'lodash/range';
import fromPairs from 'lodash/fromPairs';

import * as S from './Accordion.styled';
import IAccordion from './Accordion.types';

const Accordion = (props: IAccordion): React.ReactElement => {
  const { children, id, elementIndex, groupLength = 2, hiddenContent } = props;

  const initialArrowsState = fromPairs(
    map(range(1, groupLength), num => [num.toString(), false]),
  );

  const [isArrowInfoOpen, setArrowInfoOpen] = useState(initialArrowsState);

  const onArrowClickHandler = (index: number): void => {
    setArrowInfoOpen(prevState => ({
      ...prevState,
      [index]: !prevState[index],
    }));
  };

  return (
    <S.MainInfoContentItem key={elementIndex + id} id={id}>
      <S.ItemContentWrapper>
        {children}
        <S.ItemArrow onClick={(): void => onArrowClickHandler(elementIndex)}>
          <i
            className={`arrow-${isArrowInfoOpen[elementIndex] ? 'up' : 'down'}`}
          />
        </S.ItemArrow>
      </S.ItemContentWrapper>
      <div
        className={`info-content info-${
          isArrowInfoOpen[elementIndex] ? 'up' : 'down'
        }`}
      >
        <div className="border" />
        <S.ItemContentArrowInfo>{hiddenContent}</S.ItemContentArrowInfo>
      </div>
    </S.MainInfoContentItem>
  );
};

export default Accordion;

import services from '@features/core/services';

import { HAS_WALLET_REQUEST } from '@common/constants/config';
import { TOKEN } from '@common/constants/cookie';
import { IUser } from '@common/interfaces/user/IUser';
import { IFetchWallets } from '@common/providers/payments/wallets/types';
import apiRpcRequest from '@common/api/common/apiCommonRequest';
import { MethodType } from '@common/api/apiHandler';
import { getWalletsRequest } from '@common/providers/payments/wallets/helpers';
import { setWalletsData } from '@common/providers/payments/wallets/useWallets';

const authWallets = async (user: IUser): Promise<IFetchWallets> => {
  const wallets = (await apiRpcRequest({
    method: MethodType.Get,
    params: { method: 'user.get_wallets' },
  })) as IFetchWallets;

  if (services.config.get(HAS_WALLET_REQUEST)) {
    getWalletsRequest(user);
  }

  if (services.cookie.get(TOKEN)) {
    setWalletsData(wallets?.result?.wallets);
  }
  return wallets;
};

export default authWallets;

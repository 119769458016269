import styled from 'styled-components';

export const CashoutLabel = styled.span`
  margin-right: auto;
  color: ${({ theme }) => theme.highlightWrapperBackground};
`;

export const VirtualInputWrapper = styled.div`
  .virtual_input {
    p {
      font-weight: ${({ theme }) => theme.fontBold};
      margin-bottom: ${({ theme }) => theme.space_1};
    }
  }
`;

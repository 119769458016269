import services from '@features/core/services';

import { DynamicCalls } from '@common/api/api';
import { getTestList } from '@common/helpers/deviceUtil';
import {
  IGetBetDetailsPayload,
  ISetBetDetailPayload,
} from '@common/providers/bets/types';
import { apiHandler, MethodType } from '@common/api/apiHandler';
import { ICustomError } from '@common/interfaces';

/**
 * fetchBetDetails
 * fetches single bet from the server
 * @param bet_id {String} bet id
 * @param scan {Boolean} is it scan bet which require no token endpoint?
 * @returns {AxiosPromise<ISetBetDetailPayload>}
 */

const {
  API_FAKE_BETS,
  API_BET_DETAILS,
  API_SCANNED_BET_DETAILS,
} = DynamicCalls;

const fetchBetDetails = ({
  bet_id,
  barcode,
}: IGetBetDetailsPayload): Promise<ISetBetDetailPayload & ICustomError> => {
  const testList = getTestList();
  if (testList) {
    services.logger.info('BETS - USING TESTING LIST', testList);
    return apiHandler<ISetBetDetailPayload>(
      { method: MethodType.Get },
      {
        apiUrl: API_FAKE_BETS,
        apiData: {
          dynamicTestList: testList,
          dynamicBetId: bet_id,
        },
      },
    );
  }
  const params = {
    bet_id: barcode || bet_id,
    scan: !!barcode,
  };
  return apiHandler<ISetBetDetailPayload>(
    { method: MethodType.Get, params },
    {
      apiUrl: barcode ? API_SCANNED_BET_DETAILS : API_BET_DETAILS,
      apiData: {
        dynamicLanguage: services.domainLang,
      },
    },
  );
};

export default fetchBetDetails;

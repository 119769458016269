import React from 'react';

import FormItem from '@features/core/form/components/FormItem';
import { IFormItemProps } from '@features/core/form/components/FormItem.types';
import { PageName } from '@features/core/routing/linkAliases';

import submitErrorsValidator from '@common/helpers/registrationHelper/submitErrorsValidator';
import { getInitSingleFormDate } from '@common/helpers/registrationHelper/registrationHelpers';
import { useRegisterState } from '@common/providers/registration/useRegistration';
import { isMatchWebRoutes } from '@common/providers/router/helper';

import * as S from './RegistrationFormItem.styled';

const RegistrationFormItem: React.FC<IFormItemProps> = props => {
  const { rules, name = '', initialValue, form } = props;
  const initialData = form?.getFieldValue(name);
  const submitErrors = useRegisterState(s => s.error.submitErrors);
  const initialSingleData = getInitSingleFormDate();
  const isSingleForm = isMatchWebRoutes(PageName.FULL_REGISTER);
  const disable = isSingleForm ? initialSingleData[name] : false;

  const newRules = [
    ...(rules || []),
    {
      validator: submitErrorsValidator(submitErrors, name),
    },
  ];

  return (
    <S.FormWrapper disabled={disable}>
      <FormItem
        floatError
        isFloatErrorArrowLeft
        validateTrigger="onBlur"
        {...props}
        rules={newRules}
        //  eslint-disable-next-line
        initialValue={initialData || initialValue}
      />
    </S.FormWrapper>
  );
};

export default RegistrationFormItem;

import React, { useMemo } from 'react';

import { getLabel } from '@common/helpers/markets/marketModel';

import * as S from './MarketsLabel.styled';
import { IMarketsLabel } from './MarketsLabel.types';

const MarketsLabel: React.FC<IMarketsLabel> = props => {
  const { market } = props;
  const label = useMemo(() => getLabel(market), [market]);

  return <S.MarketsLabelWrapper>{label}</S.MarketsLabelWrapper>;
};

export default MarketsLabel;

import React from 'react';
import { useTranslation } from 'react-i18next';

import { PageName } from '@features/core/routing/linkAliases';

import { isMatchWebRoutes } from '@common/providers/router/helper';

import { Icon } from '@ui/components/icon';

import * as S from './SearchInput.styled';
import { ISearchInput } from './SearchInput.types';

const SearchInput: React.FC<ISearchInput> = props => {
  const { inputRef, value, onChange, onCancel, ...rest } = props;
  const { t } = useTranslation();

  const searchPlaceholder = isMatchWebRoutes(PageName.LIVE)
    ? t('common.labels.search_live')
    : t('common.labels.search');

  const cancel = (): void => {
    if (value) {
      onCancel();
    }
  };

  return (
    <S.InputContainer
      ref={inputRef}
      className="search-container"
      data-qa="search-container"
    >
      <S.StyledTextInput
        type="text"
        name="search"
        autocomplete="off"
        inputClassName="input-class boldInput"
        placeholder={searchPlaceholder}
        {...rest}
        value={value}
        onChange={onChange}
        data-qa="home-input-search"
        maxLength={20}
      />
      <S.SearchIcon hasValue={!!value} onClick={cancel}>
        <Icon name={value ? 'close' : 'search'} width="10" height="10" />
      </S.SearchIcon>
    </S.InputContainer>
  );
};

export default SearchInput;

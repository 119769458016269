import styled from 'styled-components';

const NotStartedInner = styled.div`
  max-width: ${({ theme }) => theme.isDesktop && 'fit-content'};

  @media screen and (min-width: 481px) {
    max-width: ${({ theme }) => !theme.isDesktop && '50px'};
  }

  @media screen and (max-width: 481px) {
    & .not-started-timer {
      min-width: 45px;
      grid-template-columns: min-content;
    }
  }
`;

export default NotStartedInner;

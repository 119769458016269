//  import includes from 'lodash/includes';
import map from 'lodash/map';

import services from '@features/core/services';

import { ENV } from '@common/constants/config';
import { hash } from '@common/helpers/deviceUtil';
import { useUserState } from '@common/providers/user/useUserState';
import {
  IMarket,
  IEvent,
  ICategory,
  EventListTypes,
  PredictionType,
  MarketType,
  LiveStatus,
} from '@common/interfaces';
import { getSportName } from '@common/helpers/categories/categoriesModel';
import {
  getAwayLabel,
  getHomeLabel,
} from '@common/helpers/eventsHelper/eventLabelHelper';
import {
  filterMarkets,
  query,
  getMarkets,
} from '@common/helpers/markets/marketModel';
import { getPeriodTypeForMarket } from '@common/helpers/markets/marketTypes';
import { useEventsListState } from '@common/providers/events/eventList/useEventsList';
import {
  getAwayPrediction,
  getHomePrediction,
  getDrawPrediction,
} from '@common/helpers/eventsHelper/predictionModel';
import { getActiveWallet } from '@common/helpers/paymentsHelper/walletsHelper';
import { IUserWalletType } from '@common/interfaces/user/IUser';

export enum GTMEvent {
  REGISTRATION = 'Register',
  DEPOSIT = 'Deposit',
  WITHDRAWAL = 'Withdrawal',
  USER = 'User',
  BET = 'Bets',
  consent_status = 'consent_status',
}

export enum FbEvent {
  CompleteRegistration = 'CompleteRegistration',
  PageView = 'PageView',
}

export enum SportRadarEvent {
  REGISTRATION = 'track.user.registration',
  LOGIN = 'track.user.login',
  DEPOSIT = 'track.betting.deposit',
  BET = 'track.betting.betPlaced',
  SELECTION = 'srt.betslip',
  DETAIL_EVENT_VIEW = 'srt.retargeting',
}

export const addGTagEvent = (event: {
  event: GTMEvent;
  event_parameter_1?: string;
  event_parameter_2?: string;
  event_parameter_3?: string;
}): void => {
  if (services.config.get(ENV) !== 'development') {
    window.dataLayer.push(event);
  }
};

export const addFBEvent = (event: FbEvent): void => {
  if (
    // eslint-disable-next-line lodash/prefer-lodash-typecheck
    typeof window.fbq === 'function' &&
    services.config.get(ENV) !== 'development'
  ) {
    window.fbq('track', event);
  }
};

export const addBetRadarEvent = async (event: {
  event: SportRadarEvent;
  payload: Record<string, unknown>;
}): Promise<void> => {
  const user = useUserState.getState().data;
  const activeWallet = getActiveWallet();
  if (
    // eslint-disable-next-line lodash/prefer-lodash-typecheck
    typeof window.srtmCommands !== 'undefined' &&
    services.config.get(ENV) !== 'development' &&
    (activeWallet?.type === IUserWalletType.www || !user?.username)
    //  (includes(user?.lineage, '99999') || !user?.username)
  ) {
    window.srtmCommands.push(event);
  }
};

export const onRegistrationStep = async ({
  step,
}: {
  step: string | number;
}): Promise<void> => {
  const eventStep =
    step === 'success' ? 'register_success' : `registerstep_${step}`;

  if (step !== 'success') {
    addGTagEvent({
      event: eventStep as GTMEvent,
    });
  }
  if (eventStep === 'registerstep_1') {
    addBetRadarEvent({
      event: SportRadarEvent.REGISTRATION,
      payload: { action: 'start' },
    });
  }

  if (step === 'success') {
    const user = useUserState.getState().data;
    const userId = await hash(user?.username || '');
    addFBEvent(FbEvent.CompleteRegistration);
    addBetRadarEvent({
      event: SportRadarEvent.REGISTRATION,
      payload: { action: 'complete', userId },
    });
  }

  addGTagEvent({
    event: GTMEvent.REGISTRATION,
    event_parameter_1: 'User registration',
    event_parameter_2: eventStep,
  });
};

export const onPageView = async (): Promise<void> => {
  addFBEvent(FbEvent.PageView);
};

export const onLogin = async (): Promise<void> => {
  const user = useUserState.getState().data;
  const userId = await hash(user?.username || '');
  addGTagEvent({
    event: GTMEvent.USER,
    event_parameter_1: 'User login',
  });
  addBetRadarEvent({
    event: SportRadarEvent.LOGIN,
    payload: { action: 'complete', userId },
  });
};

export const onBet = async (payload: { amount: string }): Promise<void> => {
  const user = useUserState.getState().data;
  const userId = await hash(user?.username || '');
  addGTagEvent({
    event: GTMEvent.BET,
    event_parameter_1: 'Slip confirmation',
    event_parameter_2: payload.amount,
  });
  addBetRadarEvent({
    event: SportRadarEvent.BET,
    payload: {
      userId,
      transactionId: '',
      amount: payload.amount,
      currency: 'EUR',
    },
  });
};

export const onDeposit = async (payload: {
  service: string;
  amount: string;
}): Promise<void> => {
  const user = useUserState.getState().data;
  const userId = await hash(user?.username || '');
  addGTagEvent({
    event: GTMEvent.DEPOSIT,
    event_parameter_1: 'Deposit successful',
    event_parameter_2: payload.service,
    event_parameter_3: payload.amount,
  });
  addBetRadarEvent({
    event: SportRadarEvent.DEPOSIT,
    payload: {
      action: 'created',
      userId,
      transactionId: '',
      amount: payload.amount,
      currency: 'EUR',
    },
  });
};

export const onWithdrawal = async (payload: {
  service: string;
  amount: string;
}): Promise<void> => {
  addGTagEvent({
    event: GTMEvent.WITHDRAWAL,
    event_parameter_1: 'Withdrawal successful',
    event_parameter_2: payload.service,
    event_parameter_3: payload.amount,
  });
};

export const getEventPayload = (payload: {
  event: IEvent;
  category: ICategory;
  market?: IMarket;
  odds: string;
  type: PredictionType;
  listType: keyof typeof EventListTypes;
}): {
  match_id: string;
  team_home_name: string;
  team_away_name: string;
  scheduled: string;
  tournament_name: string;
  sport: string;
  odds_home: string;
  odds_draw: string;
  odds_away: string;
  language: string;
  status: string;
} => {
  const sprot = getSportName(payload.category.top_category_id);
  const list = useEventsListState.getState();
  const periodType = getPeriodTypeForMarket(
    parseInt(payload.category.top_category_id, 10),
    MarketType['N-WAY'],
    payload.event,
  );
  const markets = getMarkets(
    list[payload.listType]?.data?.markets || [],
    payload.event.markets,
  );
  const marketsByType = query({
    type: MarketType['N-WAY'],
    event: payload.event,
    markets,
    periodType,
  }) as IMarket[];

  const nWay = filterMarkets({
    markets: marketsByType,
    liveStatus: payload.event?.live_status,
    type: MarketType['N-WAY'],
    predictions: list[payload.listType]?.data?.predictions,
    isDesktop: true,
  })[0];
  const predictions = map(
    nWay?.predictions || {},
    e => list[payload.listType]?.data?.predictions[e],
  );
  // https://marketing-cloud.sportradar.com/support/solutions/articles/77000538662-tracking-specifications
  return {
    match_id: payload.event.id,
    team_home_name: getHomeLabel(payload.event.label),
    team_away_name: getAwayLabel(payload.event.label),
    scheduled: payload.event.expires_ts
      ? new Date((payload.event.expires_ts as number) * 1000).toISOString()
      : new Date().toISOString(),
    tournament_name: payload.category.path,
    // TODO: here we need to adapt a bit our sport type
    sport: sprot === 'football' ? 'soccer' : sprot,
    odds_home: getHomePrediction(nWay, predictions)?.odds || '',
    odds_draw: getDrawPrediction(nWay, predictions)?.odds || '',
    odds_away: getAwayPrediction(nWay, predictions)?.odds || '',
    language: services.domainLang,
    // same with statuses, they expect not what we have
    status:
      payload.event?.live_status === LiveStatus.open ? 'live' : 'not_started',
  };
};

export const onSelection = (payload: {
  event: IEvent;
  category: ICategory;
  market?: IMarket;
  odds: string;
  type: PredictionType;
  listType: keyof typeof EventListTypes;
}): void => {
  addBetRadarEvent({
    event: SportRadarEvent.SELECTION,
    payload: {
      sportsEvent: getEventPayload(payload),
    },
  });
};

export const onDetailEventView = (payload: {
  event: IEvent;
  category: ICategory;
  market?: IMarket;
  odds: string;
  type: PredictionType;
  listType: keyof typeof EventListTypes;
}): void => {
  addBetRadarEvent({
    event: SportRadarEvent.DETAIL_EVENT_VIEW,
    payload: {
      sportsEvent: getEventPayload(payload),
    },
  });
};

import categoriesKeys from '@common/helpers/categories/categories';
import { IEvent } from '@common/interfaces';

import { getScoreType } from './eventLabelHelper';

/**
 * isAmericanFootball
 *
 * @param {IEvent} event
 * @returns {boolean} isAmericanFootball
 */
export const isAmericanFootball = (event: IEvent): boolean => {
  return (
    event &&
    (getScoreType(event).search('AMERICAN_FOOTBALL') > -1 ||
      event?.sport === 'American Football')
  );
};

/**
 * isFootball
 *
 * @param {IEvent} event
 * @returns {boolean} isFootball
 */
export const isFootball = (event: IEvent): boolean => {
  return (
    event &&
    (getScoreType(event).search('SOCCER') > -1 ||
      getScoreType(event).search('GENERIC/HALVES') > -1 ||
      event.sport === 'Football' ||
      event.sport === 'Soccer')
  );
};

/**
 * isDarts
 *
 * @param {IEvent} event
 * @returns {boolean} isDarts
 */
export const isDarts = (event: IEvent): boolean => {
  return (
    event &&
    (getScoreType(event).search('DARTS') > -1 ||
      ('sport' in event ? event.sport === 'Darts' : false))
  );
};

/**
 * isTennis
 *
 * @param {IEvent} event
 * @returns {boolean} isTennis
 */
export const isTennis = (event: IEvent): boolean => {
  return (
    event &&
    (getScoreType(event).search('TENNIS') > -1 || event.sport === 'Tennis')
  );
};

/**
 * isBadminton
 *
 * @param {IEvent} event
 * @param {string} top_category
 * @returns {boolean} isBadminton
 */
export const isBadminton = (event: IEvent, top_category: string): boolean => {
  const isBadmintonByEvent = event.topcatid
    ? categoriesKeys.badminton[event.topcatid]
    : false;
  return top_category
    ? categoriesKeys.badminton[top_category]
    : isBadmintonByEvent;
};

/**
 * isTableTennis
 *
 * @param {IEvent} event
 * @param {number} top_category
 * @returns {boolean} isTableTennis
 */
export const isTableTennis = (event: IEvent, top_category: string): boolean => {
  return categoriesKeys.tabletennis[top_category];
};

/**
 * isBeachVolleyball
 *
 * @param {IEvent} event
 * @param {string} top_category
 * @returns {boolean} isBeachVolleyball
 */
export const isBeachVolleyball = (
  event: IEvent,
  top_category: string,
): boolean => {
  return categoriesKeys.beachvolleyball[top_category];
};

/**
 * isVolleyball
 *
 * @param {IEvent} event
 * @returns {boolean} isVolleyball
 */
export const isVolleyball = (event: IEvent): boolean => {
  return (
    getScoreType(event).search('VOLLEYBALL') > -1 ||
    event?.sport === 'Volleyball'
  );
};

/**
 * isHockey
 *
 * @param {IEvent} event
 * @returns {boolean} isHockey
 */
export const isHockey = (event: IEvent): boolean => {
  return (
    event &&
    (getScoreType(event).search('HOCKEY') > -1 ||
      event.sport === 'Icehockey' ||
      event.sport === 'Feldshockey')
  );
};

/**
 * isRealFootball
 *
 * @param {IEvent} event
 * @param {string} top_category
 * @returns {boolean} isRealFootball
 */
export const isRealFootball = (
  event: IEvent,
  top_category: string,
): boolean => {
  return (
    isFootball(event) &&
    (categoriesKeys.football[top_category] ||
      categoriesKeys.esoccer[top_category])
  );
};

/**
 * isIceHockey
 *
 * @param {number} top_category
 * @returns {boolean} isIceHockey
 */
export const isIceHockey = (top_category: string): boolean => {
  return categoriesKeys.icehockey[top_category];
};

/**
 * isFieldsHockey
 *
 * @param {number} top_category
 * @returns {boolean} isFieldsHockey
 */
export const isFieldsHockey = (top_category: string): boolean => {
  return categoriesKeys.fieldshockey[top_category];
};

/**
 * isHandball
 *
 * @param {IEvent} event
 * @returns {boolean} isHandball
 */
export const isHandball = (event: IEvent): boolean => {
  return (
    event &&
    (getScoreType(event).search('HANDBALL') > -1 || event.sport === 'Handball')
  );
};

/**
 * isGeneric
 *
 * @param {IEvent} event
 * @returns {boolean} isGeneric
 */
export const isGeneric = (event: IEvent): boolean => {
  return event && getScoreType(event) === 'GENERIC/HALVES';
};

/**
 * isGolf
 *
 * @param {IEvent} event
 * @param {number} top_category
 * @returns {boolean} isGolf
 */
export const isGolf = (event: IEvent, top_category: string): boolean => {
  return isGeneric(event) && categoriesKeys.golf[top_category];
};

/**
 * isBasketball
 *
 * @param {IEvent} event
 * @returns {boolean} isBasketball
 */
export const isBasketball = (event: IEvent): boolean => {
  return (
    event &&
    (getScoreType(event).search('BASKETBALL') > -1 ||
      ('sport' in event ? event.sport === 'Basketball' : false))
  );
};

/**
 * isNcaaBasketball
 *
 * @param {IEvent} event
 * @returns {boolean} isNcaaBasketball
 */
export const isNcaaBasketball = (event: IEvent): boolean => {
  return event && getScoreType(event).search('BASKETBALL/HALVES') > -1;
};

/**
 * isRugby
 *
 * @param {number} top_category
 * @returns {boolean} isRugby
 */
export const isRugby = (top_category: string): boolean => {
  return categoriesKeys.rugby[top_category];
};

/**
 * isAmerican
 *
 * @param {number} top_category
 * @returns {boolean} isAmerican
 */
export const isAmerican = (top_category: string): boolean => {
  return categoriesKeys.american[top_category];
};

/**
 * isAussierules
 *
 * @param {number} top_category
 * @returns {boolean} isAussierules
 */
export const isAussierules = (top_category: string): boolean => {
  return categoriesKeys.aussierules[top_category];
};

/**
 * isSnooker
 *
 * @param {IEvent} event
 * @param {number} top_category
 * @returns {boolean} isSnooker
 */
export const isSnooker = (event: IEvent, top_category: string): boolean => {
  return isGeneric(event) && categoriesKeys.snooker[top_category];
};

/**
 * isTDF
 *
 * @param {IEvent} event
 * @returns {boolean} isTDF
 */
export const isTDF = (event: IEvent): boolean => {
  return event && getScoreType(event) === 'CYCLING/TOUR_DE_FRANCE';
};

/**
 * isEsport
 *
 * @param {IEvent} event
 * @returns {boolean} isEsport
 */
export const isEsport = (event: IEvent): boolean => {
  return getScoreType(event).search('ESPORTS') > -1;
};

/**
 * isBaseBall
 *
 * @param {IEvent} event
 * @param {number} top_category
 * @returns {boolean} isBaseBall
 */
export const isBaseBall = (event: IEvent, top_category: string): boolean => {
  return categoriesKeys.baseball[top_category];
};

/**
 * isFutsal
 *
 * @param {IEvent} event
 * @param {number} top_category
 * @returns {boolean} isFutsal
 */
export const isFutsal = (event: IEvent, top_category: string): boolean => {
  return categoriesKeys.futsal[top_category];
};

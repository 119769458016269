import numeral from 'numeral';

export const maxNumeralFormattingNumberLength = 22;
export const FIXED_TWO_DECIMAL_FORMAT = '0,0.00';
export const FIXED_TWO_OPT_3RD_FORMAT = '0,0.00[0]';
export const getFormattedAmount = (value: number | string): string => {
  return numeral(value).format(FIXED_TWO_DECIMAL_FORMAT);
};

const numeralLocaleConfig = {
  delimiters: { thousands: ',', decimal: '.' },
  abbreviations: {
    thousand: 'k',
    million: 'm',
    billion: 'b',
    trillion: 't',
  },
  ordinal: (n): string => n,
  currency: { symbol: '€' },
};

// Note: all except DE are the same for our needs
numeral.register('locale', 'de', {
  ...numeralLocaleConfig,
  delimiters: { thousands: '.', decimal: ',' },
});

numeral.register('locale', 'es', numeralLocaleConfig);
numeral.register('locale', 'tr', numeralLocaleConfig);
numeral.register('locale', 'ru', numeralLocaleConfig);
numeral.register('locale', 'el', numeralLocaleConfig);
numeral.register('locale', 'fr', numeralLocaleConfig);
numeral.register('locale', 'sq', numeralLocaleConfig);

export default numeral;

import styled from 'styled-components';

import { TextNormal } from '@features/core/styles/text.styled';

const getUserMenuDisplay = props => `
  margin-left: 100%;
  margin-right: -20px;
  height: 20px;
  width: 20px;
  background-color: ${props.theme.primaryAccentBackground};

`;

const getDesktopProfileDisplay = props => `
  margin-left: -15px;
  margin-top: -30px;
  height: 20px;
  width: 20px;
  background-color: ${props.theme.tertiaryBackground};

`;

const getMobileProfileDisplay = props => `
  margin-left: -10px;
  margin-top: -20px;
  height: 15px;
  width: 15px;
  background-color: ${props.theme.tertiaryBackground};
  font-size: ${props.theme.fontSM};
`;

const MessageCountWrapper = styled(TextNormal)<{
  hasInboxMessage: boolean;
  isUserMenu: boolean;
  isDesktopProfileIcon: boolean;
  isMobileProfileIcon: boolean;
}>`
  ${({ isUserMenu }) => isUserMenu && getUserMenuDisplay}

  ${({ isDesktopProfileIcon }) =>
    isDesktopProfileIcon && getDesktopProfileDisplay}

  ${({ isMobileProfileIcon }) => isMobileProfileIcon && getMobileProfileDisplay}
  
  display: grid;
  color: ${({ theme }) => theme.textMain};
  align-items: center;
  text-align: center;
  font-weight: ${({ theme }) => theme.fontBold};
  border-radius: 50%;
  visibility: ${({ hasInboxMessage }) =>
    hasInboxMessage ? 'visible' : 'hidden'};
  z-index: 1;
`;

export default MessageCountWrapper;

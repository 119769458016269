import React from 'react';
import filter from 'lodash/filter';
import map from 'lodash/map';

import { getEventShortName } from '@common/helpers/eventsHelper/eventLabelHelper';

import { IEventsNames } from './EventsNames.types';

const EventsNames = ({ selections }: IEventsNames): React.ReactElement => {
  const eventsNames = map(selections, 'event_name');
  const uniqueNames = filter(
    eventsNames,
    (name, index) => eventsNames.indexOf(name) === index,
  );

  const result =
    uniqueNames.length > 1
      ? map(
          uniqueNames,
          name => name && getEventShortName(name, uniqueNames.length > 1),
        ).join(' | ')
      : uniqueNames[0];

  return <>{result}</>;
};

export default EventsNames;

import reduce from 'lodash/reduce';
import assign from 'lodash/assign';
import capitalize from 'lodash/capitalize';
import toLower from 'lodash/toLower';
import padStart from 'lodash/padStart';
import includes from 'lodash/includes';
import split from 'lodash/split';
import isBefore from 'date-fns/isBefore';
import startOfDay from 'date-fns/startOfDay';
import setDay from 'date-fns/setDay';
import endOfDay from 'date-fns/endOfDay';
import getDay from 'date-fns/getDay';
import addDays from 'date-fns/addDays';
import keys from 'lodash/keys';

import services from '@features/core/services';
import i18n from '@features/core/translation';

import { CUSTOM_TIME_ZONE } from '@common/constants/cookie';
import convertToTimeZone from '@common/helpers/timeHelper/convertToTimeZone';
import convertUnixToDate from '@common/helpers/timeHelper/convertUnixToDate';
import categoriesKeys from '@common/helpers/categories/categories';
import { getTopID } from '@common/helpers/categories/categoriesModel';
import { isDesktopView, isLScreenWidth } from '@common/helpers/deviceUtil';
import {
  ICategory,
  ITimer,
  IEvent,
  EventTimeFilters,
} from '@common/interfaces';
import formatDate from '@common/helpers/timeHelper/localizedFormat';
import convertGermanTimeToUTC0 from '@common/helpers/timeHelper/convertGermanTimeToUTC0';

const isDesktop = isDesktopView();

export const isRunning = (timer: ITimer): boolean => {
  return timer && timer.running === 1;
};

/**
 * getElapsedTime
 * getElapsedTime, calculate time for timer
 *
 * @param {ITimer} timer
 * @returns {number} elapsedTime
 */
export const getElapsedTime = (timer: ITimer): number => {
  let totalSeconds = timer.elapsed || 0;
  if (isRunning(timer) && timer.runningSince) {
    totalSeconds += Math.floor(
      (new Date().getTime() - timer.runningSince) / 1000,
    );
  }
  return totalSeconds;
};

/**
 * getDuration
 * getDuration, returns duration for game
 *
 * @param {ITimer} timer
 * @returns {number} duration
 */
export const getDuration = (timer: ITimer): number => {
  // Period duration in minutes.
  return timer.duration || 0;
};

/**
 * isPeriodNotStarted
 * check if event is not started
 *
 * @param {ITimer} timer
 * @returns {boolean} isPeriodNotStarted
 */
export const isPeriodNotStarted = (timer: ITimer | undefined): boolean => {
  return !timer || (timer && timer?.period_id === 'NOT_STARTED');
};

export const hasBreakPeriod = (timer: ITimer): boolean => {
  const period = (timer && timer.period_long) || '';
  return (
    includes(toLower(period), 'pause') || includes(toLower(period), 'break')
  );
};

export const getCurrentPeriodID = (timer: ITimer): string => {
  return timer && timer.period_id;
};

export const getCurrentPausePeriod = (period_idx: number): string => {
  return String(period_idx / 2 + 1);
};

/**
 * isPeriodInterrupted
 * checks if event is interrupted
 *
 * @param {ITimer} timer
 * @returns {boolean} isPeriodInterrupted
 */
export const isPeriodInterrupted = (timer: ITimer): boolean => {
  return timer && timer.period_id === 'INTERRUPTED';
};

export const getPausePeriod = (timer: ITimer): string => {
  const periodId = (getCurrentPeriodID(timer) || '').match(/\d+/);
  return (periodId && periodId[0]) || '';
};

export const getBreakPeriod = (timer: ITimer, short = false): string => {
  const isEN = services.domainLang === 'en';
  const ending = isEN ? 'th' : '.';
  const pausePeriod = getPausePeriod(timer);

  if (short) {
    return `${pausePeriod}. ${timer.period_long.charAt(0)}`;
  }
  return `${pausePeriod}${pausePeriod && ending} ${timer.period_long}`;
};

/**
 * isPenalty
 * check if after match penalties in game
 *
 * @param {ITimer} timer
 * @returns {boolean} isPenalty
 */
export const isPenalty = (timer?: ITimer): boolean => {
  return timer?.period_id === 'PT';
};

/**
 * isOvertime
 * check if overtime is started
 *
 * @param {ITimer} timer
 * @returns {boolean} isOvertime
 */
export const isOvertime = (timer: ITimer): boolean => {
  return timer?.period_id === '1H_OT' || timer?.period_id === '2H_OT';
};

/**
 * getCurrentPeriod
 * getCurrentPeriod, gets current period
 *
 * @param {ITimer} timer
 * @param {boolean} shortTimer
 * @param {ICategory} category
 * @returns {string} period
 */
export const getCurrentPeriod = (
  timer: ITimer,
  shortTimer = false,
  category: ICategory,
): string => {
  const lang = services.domainLang;
  const HALF_KEY = 'short.half';
  const PENALTY_KEY =
    isDesktop && !isLScreenWidth ? 'helpers.penalty_large' : 'helpers.penalty';
  const OVERTIME_KEY = !shortTimer ? 'helpers.overtime' : 'helpers.OT';

  const period = shortTimer ? timer.period_short : timer.period_long;
  const topCategoryId = getTopID(category);
  const isIceHockey = Boolean(
    categoriesKeys.icehockey[topCategoryId] ||
      categoriesKeys.fieldshockey[topCategoryId],
  );
  const set = i18n.t('header_market_keys.SET');
  const third = i18n.t('header_market_keys.PERIOD_THIRD');
  const quarter = i18n.t('header_market_keys.PERIOD_QUARTER');

  const helpersNumber1 = i18n.t('helpers.numbers.1');
  const helpersNumber2 = i18n.t('helpers.numbers.2');
  const helpersNumber3 = i18n.t('helpers.numbers.3');
  const helpersNumber4 = i18n.t('helpers.numbers.4');

  const shortParts = {
    '1Q': shortTimer ? '1/4' : `${helpersNumber1} ${quarter}`,
    '2Q': shortTimer ? '2/4' : `${helpersNumber2} ${quarter}`,
    '3Q': shortTimer ? '3/4' : `${helpersNumber3} ${quarter}`,
    '4Q': shortTimer ? '4/4' : `${helpersNumber4} ${quarter}`,
    '1P': shortTimer ? '1/3' : `${helpersNumber1} ${third}`,
    '2P': shortTimer ? '2/3' : `${helpersNumber2} ${third}`,
    '3P': shortTimer ? '3/3' : `${helpersNumber3} ${third}`,
    '1S': shortTimer ? '1S' : `${helpersNumber1} ${set}`,
    '2S': shortTimer ? '2S' : `${helpersNumber2} ${set}`,
    '3S': shortTimer ? '3S' : `${helpersNumber3} ${set}`,
    '4S': shortTimer ? '4S' : `${helpersNumber4} ${set}`,
    '5S': shortTimer ? '5S' : `${i18n.t('helpers.numbers.5')} ${set}`,
    '1H': `1${lang === 'de' ? '.' : ''} ${i18n.t(HALF_KEY)}`,
    '2H': `2${lang === 'de' ? '.' : ''} ${i18n.t(HALF_KEY)}`,
    EIB: i18n.t('helpers.extra_in_bottom'),
    EIT: i18n.t('helpers.extra_in_top'),
    ...reduce(
      Array.from({ length: 9 }, (v, i) => i + 1),
      (acc, el) => {
        const firstPart =
          lang === 'de'
            ? `${el}. ${capitalize(i18n.t('helpers.inning'))}`
            : `${capitalize(i18n.t('helpers.inning'))} ${el}`;

        return assign(acc, {
          [`${el}IT`]: shortTimer
            ? `${el}In. ${i18n.t('helpers.top')}`
            : `${firstPart} \n ${capitalize(i18n.t('helpers.top'))}`,
          [`${el}IB`]: shortTimer
            ? `${el}In. ${i18n.t('helpers.bottom')}`
            : `${firstPart} \n ${capitalize(i18n.t('helpers.bottom'))}`,
        });
      },
      {},
    ),
  };
  if (shortParts[timer.period_short]) {
    return shortParts[timer.period_short];
  }
  if (isOvertime(timer)) {
    return i18n.t(OVERTIME_KEY);
  }
  if (timer.period_id === 'PT' && isIceHockey) {
    return i18n.t('helpers.penalty_icehockey');
  }
  if (isPenalty(timer)) {
    return i18n.t(PENALTY_KEY);
  }
  if (timer.period_id === 'HT') {
    return i18n.t(HALF_KEY);
  }
  if (hasBreakPeriod(timer)) {
    return getBreakPeriod(timer, shortTimer);
  }
  if (timer.period_id === 'FINISHED') {
    return i18n.t('helpers.end');
  }
  if (period === '--') {
    // is it darts?
    return timer.period_long;
  }
  if (isPeriodInterrupted(timer)) {
    return i18n.t('helpers.interrupted');
  }
  if (includes(period, 'in prog')) {
    // is it darts?
    return i18n.t('short.live');
  }
  return period;
};

/**
 * isPeriodStarted
 * checkes if period is started
 *
 * @param {ITimer} timer
 * @returns {boolean} isPeriodStarted
 */
export const isPeriodStarted = (timer: ITimer): boolean => {
  return !isPeriodNotStarted(timer);
};

/**
 * isFinished
 * checkes if event has finished timer
 *
 * @param {ITimer} timer
 * @returns {boolean} isFinished
 */
export const isFinished = (timer: ITimer): boolean => {
  return timer && getCurrentPeriodID(timer) === 'FINISHED';
};

/**
 * 'mm`+mm`' format
 *
 * @param {number} time
 * @param {number} duration
 * @returns {string} time
 */
export const formatTimeWithAdditionMinutes = (
  time: number,
  duration: number,
): string => {
  const minutes = Math.floor(time / 60);
  return !duration || minutes <= duration
    ? `${padStart(String(minutes), 2, '0')}'`
    : `${padStart(String(duration), 2, '0')}'+${minutes - duration}'`;
};

const setTimezoneFromCookie = (
  date: Date,
  gmtInt: number,
  type: 'expires_ts' | 'expires',
): string => {
  if (type === 'expires_ts') {
    const dateWithCustomTZ = date.setHours(
      date.getHours() + gmtInt + date.getTimezoneOffset() / 60,
    );
    return formatDate(dateWithCustomTZ, 'HH:mm');
  }
  if (type === 'expires') {
    const customTimestampTimezoneOffset = -gmtInt * 60 * 60 * 1000;
    const timestampGMT0 = convertGermanTimeToUTC0(date.getTime());
    const dateWithCustomTZ = new Date(
      timestampGMT0 - customTimestampTimezoneOffset,
    );
    return formatDate(dateWithCustomTZ, 'HH:mm');
  }
  return '';
};

/**
 * getTime
 * returns event expire
 *
 * @param {IEvent} event
 * @returns {string} time
 */
export const getTime = (event: IEvent): string => {
  const timezoneFromCookie = services.cookie.get(CUSTOM_TIME_ZONE);

  if (event.expires_ts) {
    const date = convertUnixToDate(parseInt(String(event.expires_ts), 10));
    if (timezoneFromCookie) {
      return setTimezoneFromCookie(
        date,
        parseInt(timezoneFromCookie, 10),
        'expires_ts',
      );
    }
    return formatDate(date, 'HH:mm');
  }
  if (event.expires) {
    const date = new Date();
    date.setHours(parseFloat(split(event.expires, ':')[0]));
    date.setMinutes(parseFloat(split(event.expires, ':')[1]));
    if (timezoneFromCookie) {
      return setTimezoneFromCookie(
        date,
        parseInt(timezoneFromCookie, 10),
        'expires',
      );
    }
    return formatDate(convertToTimeZone(date.getTime()), 'HH:mm');
  }
  return '';
};

/**
 * getDateLabelTranslation
 * returns label for event
 *
 * @param {number}  day_num
 * @param {boolean} short
 * @returns {string} labelTranslation
 */
export const getDateLabelTranslation = (
  day_num = 7,
  short?: boolean,
): string => {
  const format = short ? 'iiiiii' : 'iiii';
  return formatDate(setDay(new Date(), day_num), format);
};

/**
 * getLocalDateTime
 * returns localized date
 *
 * @param {IEvent} event
 * @returns {Date} date
 */
export const getLocalDateTime = (event: IEvent): Date => {
  return convertUnixToDate(parseInt(String(event.expires_ts), 10));
};

/**
 * check if event 7 days after current date
 * if so we use date format as dd.mm, otherwise dddd
 * e.g 19.02 or Wednesday
 *
 * @param {string | number | undefined} expires_ts
 * @returns {boolean} nextWeekDate
 */
export const nextWeekDate = (
  expires_ts: string | number | undefined,
): boolean => {
  if (!expires_ts) {
    return true;
  }
  let then = endOfDay(new Date());
  then = addDays(then, 6);
  return isBefore(convertUnixToDate(+expires_ts), then);
};

/**
 * getDateLabel
 * returns date label
 *
 * @param {string | number | undefined} expires_ts
 * @param {boolean} short
 * @returns {string} dateLabel
 */
export const getDateLabel = (
  expires_ts: string | number | undefined,
  short?: boolean,
): string => {
  if (!expires_ts) {
    return i18n.t('helpers.today');
  }
  const date = convertUnixToDate(parseFloat(String(expires_ts)));
  let ret = '';
  let then = endOfDay(new Date());

  if (isBefore(date, startOfDay(new Date()))) {
    return '';
  }
  if (isBefore(date, then)) {
    ret = i18n.t('helpers.today');
  } else {
    then = addDays(then, 1);
    if (isBefore(date, then)) {
      ret = short ? i18n.t('short.tomorrow') : i18n.t('helpers.tomorrow');
    } else if (nextWeekDate(expires_ts)) {
      ret = getDateLabelTranslation(getDay(date), short);
    } else {
      ret = formatDate(date, 'dd.MM');
    }
  }
  return ret;
};

/**
 * getDate
 * returns date
 *
 * @param {IEvent} event
 * @param {boolean} short
 * @returns {string} date
 */
export const getDate = (event: IEvent, short = true): string => {
  const dateLabel = getDateLabel(event.expires_ts, short);
  const dateDefault = split(event.date || '', ' ')[1] || '';

  if (dateLabel) {
    return dateLabel;
  }

  if (event.endedEvent) {
    return dateDefault;
  }
  return dateLabel || dateDefault;
};

/**
 * startTimers
 * start calculating elapsing time from the moment when event left event service
 *
 * @param {Record<string, IEvent>} events
 * @returns {Record<string, IEvent>} startTimers
 */
export const startTimers = (
  events: Record<number, IEvent>,
): Record<number, IEvent> => {
  return reduce(
    keys(events || {}),
    (acc, key) => {
      const event = events[key];
      if (parseInt(event?.timer?.running, 10) === 1) {
        return assign(acc, {
          [key]: assign(event, {
            timer: assign(event.timer, {
              runningSince: new Date().getTime(),
            }),
          }),
        });
      }
      return assign(acc, { [key]: event });
    },
    {},
  );
};

/**
 * filterToTimestamp
 * convert filter string to timestamp
 * used in sport list, should fetch events with time restrictions
 * after user click filters
 *
 * @param {keyof typeof EventTimeFilters} filterDate
 * @returns {number | null} date_to
 */
export const filterToTimestamp = (
  filterDate: keyof typeof EventTimeFilters,
): number | null => {
  /* eslint-disable no-else-return */
  let date_to: number | null = null;
  if (filterDate === '1w') {
    const date = new Date();
    date.setHours(date.getHours() + 24 * 7, date.getMinutes(), 0, 0);
    date_to = Math.floor(date.getTime() / 1000);
  } else if (filterDate === '3d') {
    const date = new Date();
    date.setHours(date.getHours() + 24 * 3, date.getMinutes(), 0, 0);
    date_to = Math.floor(date.getTime() / 1000);
  } else if (filterDate === 'next24hrs' || filterDate === '24h') {
    const date = new Date();
    date.setHours(date.getHours() + 24 * 1, date.getMinutes(), 0, 0);
    date_to = Math.floor(date.getTime() / 1000);
  } else if (filterDate === 'next3hrs' || filterDate === '3h') {
    const date = new Date();
    date.setHours(date.getHours() + 3 * 1, date.getMinutes(), 0, 0);
    date_to = Math.floor(date.getTime() / 1000);
  } else if (filterDate === 'today') {
    const date = new Date();
    date.setHours(23, 59, 59, 999);
    date_to = Math.floor((date.getTime() + 1000) / 1000);
  }

  return date_to;
  /* eslint-enable no-else-return */
};
